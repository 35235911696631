import React from "react";
import AvisoService from "../../services/AvisoService";
import CrudList from "../../components/forms/custom/CrudList";
import BtnButton from "../../components/forms/elements/BtnButton";
import { useNavigate, useParams } from "react-router-dom";

class ListaAviso extends CrudList {

    constructor(props) {
        super(props);

        this.handleClickFlag = this.handleClickFlag.bind(this);

        this.state.crud = {
            service: AvisoService,
            labels: {
                title: 'Lista de Aviso(s)',
                btnNewRegister: 'Aviso',
            },
            keyItem: 'idAviso',
            urlForm: '/CadAviso',
            itensFilter: ["idAviso", "nome", "celRemetenteWhatsapp", "cdTemplateId"],
            searchFormSubmit: false
        };

        this.state.container = false;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickFlag(item, flag) {
        var data = {
            flag: flag
        };
        AvisoService.updateFlag(item.idAviso, data, () => {
            this.onList();
        })
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md"> Código </th>
                <th className="col-md-6"> Nome </th>
                <th className="col-md-2"> Remetente </th>
                <th className="col-md-2"> Template ID </th>
                <th className="col-md text-center text-nowrap"> Status? </th>
                <th className="col-md text-center text-nowrap"> Whatsapp? </th>
                <th className="col-md text-center text-nowrap"> Template? </th>
                <th className="col-md text-center text-nowrap"> Sistema? </th>
                <th className="col-md text-center text-nowrap"> E-mail? </th>
                <th className="col-md text-center text-nowrap"> Bloqueado? </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center ">{item.idAviso}</td>
                <td>{item.nome}</td>
                <td>{item.ddiRemetenteWhatsapp} {item.celRemetenteWhatsapp}</td>
                <td>{item.cdTemplateId}</td>
                <td className="text-center">
                    <BtnButton onClick={e => this.handleClickFlag(item, 'flAtivo')} className={`fs-7 pt-0 pb-0 ${Boolean(item.flAtivo) ? 'btn-primary' : 'btn-danger'}`}>
                        {Boolean(item.flAtivo) ? "ATIVO" : "INATIVO"}
                    </BtnButton>
                </td>
                <td className="text-center">
                    <BtnButton onClick={e => this.handleClickFlag(item, 'flWhatsapp')} className={`fs-7 pt-0 pb-0 ${Boolean(item.flWhatsapp) ? 'btn-success' : 'btn-outline-secondary'}`}>
                        {Boolean(item.flWhatsapp) ? "SIM" : "NÃO"}
                    </BtnButton>
                </td>
                <td className="text-center">
                    <BtnButton onClick={e => this.handleClickFlag(item, 'flTemplate')} className={`fs-7 pt-0 pb-0 ${Boolean(item.flTemplate) ? 'btn-success' : 'btn-outline-secondary'}`}>
                        {Boolean(item.flTemplate) ? "SIM" : "NÃO"}
                    </BtnButton>
                </td>
                <td className="text-center">
                    <BtnButton onClick={e => this.handleClickFlag(item, 'flSistema')} className={`fs-7 pt-0 pb-0 ${Boolean(item.flSistema) ? 'btn-success' : 'btn-outline-secondary'}`}>
                        {Boolean(item.flSistema) ? "SIM" : "NÃO"}
                    </BtnButton>
                </td>
                <td className="text-center">
                    <BtnButton onClick={e => this.handleClickFlag(item, 'flEmail')} className={`fs-7 pt-0 pb-0 ${Boolean(item.flEmail) ? 'btn-success' : 'btn-outline-secondary'}`}>
                        {Boolean(item.flEmail) ? "SIM" : "NÃO"}
                    </BtnButton>
                </td>
                <td className="text-center">
                    <BtnButton onClick={e => this.handleClickFlag(item, 'flBloqueado')} className={`fs-7 pt-0 pb-0 ${Boolean(item.flBloqueado) ? 'btn-outline-secondary' : 'btn-success'}`}>
                        {Boolean(item.flBloqueado) ? "SIM" : "NÃO"}
                    </BtnButton>
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <ListaAviso {...props} navigate={navigate} params={params} />
}

export default With