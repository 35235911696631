import PlanoPagamentoService from "../../services/PlanoPagamentoService";
import SelectPlanoPagamento from "./SelectPlanoPagamento";

export default class SelectPlanoPagamentoByUsuario extends SelectPlanoPagamento {

    componentDidUpdate(nextProps) {
        if (nextProps.idPlanoRecorrencia !== this.props.idPlanoRecorrencia) {
            this.getOptions();
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        PlanoPagamentoService.getForComboByUsuario((response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}