import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectEquipamento from "../../components/app/SelectEquipamento";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import TextArea from "../../components/forms/elements/TextArea";
import RepresentanteEquipamentoService from "../../services/RepresentanteEquipamentoService";
import InputDate from "../../components/forms/elements/InputDate";
import EquipamentoService from "../../services/EquipamentoService";
import Util from "../../utils/Util";
import LiberacaoService from "../../services/LiberacaoService";

class FormRepresentanteEquipamento extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeLiberacoes = this.handleChangeLiberacoes.bind(this);
        this.handleChangeLiberacoesAll = this.handleChangeLiberacoesAll.bind(this);

        this.handleChangeEquipamento = this.handleChangeEquipamento.bind(this);
        this.onEdit = this.onEdit.bind(this);

        this.state.model = {
            idRepresentanteEquipamento: props.idRepresentanteEquipamento || 0,
            idRepresentante: props.idRepresentante || 0,
            idEquipamento: "",
            dsObservacao: "",
            dsChave: "",
            nrSerie : "",
            dtCompra : "",
            dtPrimeiraAtualizacao : "",
            dtUltimaAtualizacao : "",
            flAtivo : 1,
            flControlaAtualizacao : 1,
            representanteEquipamentoAtualizacao : [],
            representanteEquipamentoLiberacao : []
        };

        this.state.equipamento = {};
        this.state.liberacoes = [];

        this.state.isCard = true;

        this.state.crud = {
            service: RepresentanteEquipamentoService,
            labels: {
                title: 'Equipamentos',
            },
            urlList: '/',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onEdit(id) {
        super.onEdit(id, () => { 
            this.handleChangeEquipamento(null);
        })
    }

    handleChangeLiberacoes(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.representanteEquipamentoLiberacao.push(item);
                return state;
            });
        } else {
            var index = this.state.model.representanteEquipamentoLiberacao.findIndex(i => parseInt(i.idLiberacao) === parseInt(item.idLiberacao));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.representanteEquipamentoLiberacao.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeLiberacoesAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.representanteEquipamentoLiberacao = [];

                this.state.liberacoes.forEach(function (item) {
                    state.model.representanteEquipamentoLiberacao.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.representanteEquipamentoLiberacao = [];
                return state;
            });
        }
    }

    handleChangeEquipamento(e) {
        if (parseInt(this.state.model.idEquipamento) > 0) {
            EquipamentoService.getOneById(this.state.model.idEquipamento, (equipamento) => {
                this.setState((state) => {
                    state.equipamento = equipamento;                    
                    return state;
                });
            });

            LiberacaoService.listByEquipamento(this.state.model.idEquipamento, (response) => {
                this.setState({ liberacoes: response });
            });
        }else{
            this.setState({ liberacoes: [] });
        }
    }

    getClassStatus(idOrdemStatus) {
        switch (idOrdemStatus) {
            case 1:
                return "text-bg-warning";
            case 2:
                return "text-bg-success";
            case 3:
                return "text-bg-danger";
            case 4:
                return "text-bg-primary";
            case 5:
                return "text-bg-dark";
            default:
                return "text-bg-light";
        }
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-equipamento-tab" data-bs-toggle="tab" data-bs-target="#nav-equipamento" type="button" role="tab" aria-controls="nav-equipamento" aria-selected="true">
                        Equipamento
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-liberacao-tab" data-bs-toggle="tab" data-bs-target="#nav-liberacao" type="button" role="tab" aria-controls="nav-liberacao" aria-selected="true">
                        Liberações
                    </button>
                </li>
                {this.state.model.representanteEquipamentoAtualizacao.length > 0 ?
                    <li className="nav-item">
                        <button className="nav-link" id="nav-atualizacoes-tab" data-bs-toggle="tab" data-bs-target="#nav-atualizacoes" type="button" role="tab" aria-controls="nav-atualizacoes" aria-selected="false">
                            Atualizações ({this.state.model.representanteEquipamentoAtualizacao.length})
                        </button>
                    </li>               
                : <></>}
            </ul>
        );
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-equipamento" role="tabpanel" aria-labelledby="nav-equipamento-tab">
                    <div className="row mb-3 g-3">                    
                        {parseInt(this.state.model.idRepresentanteEquipamento) ===  0 ? 
                            <SelectEquipamento label="Equipamento" required placeholder="Selecione um Equipamento" name="idEquipamento" md={12} value={this.state.model.idEquipamento} onChange={e => this.handleChange(e, this.handleChangeEquipamento)}/>
                        :
                            <InputText label="Equipamento" md={12} value={this.state.model.nomeEquipamento} disabled={true} opcionalLabel={false}/>
                        }                        
                        <TextArea label="Observações" md={12} value={this.state.model.dsObservacao} name="dsObservacao" onChange={this.handleChange} opcionalLabel={false}/>
                        
                        <InputText label="Chave do Equipamento" md={6} value={this.state.model.dsChave} name="dsChave" onChange={this.handleChange} maxLength={250} />
                        <InputText label="Número de Série" md={6} value={this.state.model.nrSerie} name="nrSerie" onChange={this.handleChange} maxLength={250} />
                        
                        <InputDate md={3} label="Data de Compra" value={this.state.model.dtCompra} name="dtCompra" onChange={this.handleChange} />
                        {parseInt(this.state.model.idRepresentanteEquipamento) ===  0 && Boolean(this.state.equipamento?.flTemAtualizacao) ? 
                            <InputDate md={3} label="Atualizado em:" value={this.state.model.dtPrimeiraAtualizacao} name="dtPrimeiraAtualizacao" onChange={this.handleChange} disabled={parseInt(this.state.model.idRepresentanteEquipamento) !==  0} required={parseInt(this.state.model.idRepresentanteEquipamento) ===  0} opcionalLabel={false}
                                max={new Date((new Date()) - ((new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)}
                            />
                        : <></>}

                        {parseInt(this.state.model.idRepresentanteEquipamento) >  0 && Boolean(this.state.equipamento?.flTemAtualizacao) ?
                            <InputDate md={3} label="Atualizado em:" value={this.state.model.dtUltimaAtualizacao} name="dtUltimaAtualizacao" disabled={true}  opcionalLabel={false}/>
                        : <></>}
                    </div>

                    <div className="row mx-1 mb-3">
                        <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                        {Boolean(this.state.equipamento?.flTemAtualizacao) ? 
                            <InputCheckSwitch label="Controla atualização?" name="flControlaAtualizacao" checked={Boolean(this.state.model.flControlaAtualizacao)} onChange={this.handleChange} />
                        : <></>}
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-atualizacoes" role="tabpanel" aria-labelledby="nav-atualizacoes-tab">
                    <div className="table-responsive">
                        <table className="table table-sm table-striped">
                            <caption className="text-end me-3">
                                {this.state.model.representanteEquipamentoAtualizacao.length} Registro(s)                                        
                            </caption>
                            <thead className="table-light">
                                <tr>
                                    <th className="text-center d-none d-md-table-cell"> # </th>
                                    <th className="col-md-3"> Representante </th>
                                    <th className="col-md-5"> Equipamento </th>
                                    <th className="col-md-1 text-center"> Previsão </th>
                                    
                                    <th className="col-md-3 text-center"> Ordem </th>

                                    <th className="col-md text-center"> Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.model.representanteEquipamentoAtualizacao.map((item, index) => (
                                    <tr key={index} className="tr-borderless">
                                        <td className="align-middle text-center d-none d-md-table-cell">{item.idRepresentanteEquipamentoAtualizacao}</td>
                                        <td className="align-middle">{item.nomeRepresentante}</td>
                                        <td>
                                                <strong>{item.nomeEquipamento}</strong>
                                                <br/>{item.dsMarcaEquipamento}
                                                <br/>{item.dsModeloEquipamento}
                                                <br/><span className="badge bg-primary">atualização a cada {item.nrFrequenciaAtualizacao} mes(es)</span>
                                        </td>
                                        <td className="align-middle text-center"> {Util.date2Br(item.dtPrevista)}</td>
                                        <td className="align-middle border-start">
                                            {parseInt(item.idOrdem) > 0 ?
                                            <>
                                                <span className="rounded-pill badge w-100 fs-7 text-bg-secondary"> #{item.idOrdem} </span>
                                                <br/>{Util.date2Br(item.dataOrdem)} {item.horaOrdem}
                                                <br/>{item.nomeCategoriaProdutoServico}
                                                <br/>{item.nomeTipoProdutoServico}
                                            </>  : 
                                            <>
                                                Ordem não criada
                                            </>
                                            }
                                        </td>
                                        <td className="align-middle text-center">
                                            <span className={`rounded-pill badge w-100 fs-7 ${this.getClassStatus(item.idOrdemStatus)}`}> {item.nomeOrdemStatus} </span>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-liberacao" role="tabpanel" aria-labelledby="nav-liberacao-tab">
                    <h6> Liberações </h6>
                    <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeLiberacoesAll} checked={this.state.model.representanteEquipamentoLiberacao.length === this.state.liberacoes.length} />
                    <div className="row mb-3">
                        {this.state.liberacoes.map((item) => (
                            <div key={item.idLiberacao} className="col-md-6">
                                <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.representanteEquipamentoLiberacao.findIndex(i => parseInt(i.idLiberacao) === parseInt(item.idLiberacao)) !== -1} onChange={(e) => { this.handleChangeLiberacoes(e, item) }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormRepresentanteEquipamento {...props} navigate={navigate} params={params} />
}

export default With