import RepresentanteService from "../../services/RepresentanteService";
import SelectRepresentante from "./SelectRepresentante";

export default class SelectRepresentanteByUsuario extends SelectRepresentante {

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        var ativo = this.props.ativo === undefined ? null : this.props.ativo;

        RepresentanteService.getForComboByUsuario(ativo, (response) => {
            if (!this.state.isComponentMounted) return;
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });

        });
    }

    render() {
        return super.render();
    }
}