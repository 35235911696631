import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ContratoService from "../../services/ContratoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import TextArea from "../../components/forms/elements/TextArea";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import BtnButton from "../../components/forms/elements/BtnButton";
import FormSelect from "../../components/forms/base/FormSelect";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputNumber from "../../components/forms/elements/InputNumber";
import Util from "../../utils/Util";
import Config from "../../Config";

class FormContrato extends CrudForm {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.getBtns = this.getBtns.bind(this);
        this.handleChangeTexto = this.handleChangeTexto.bind(this);
        this.handleClickVerContrato = this.handleClickVerContrato.bind(this);

        this.state.model = {
            idContrato: "",
            nome: "",
            dsTexto : "",
            contratoCampo : []
        };

        this.state.crud = {
            service: ContratoService,
            labels: {
                title: 'Contrato',
            },
            urlList: '/LstContrato',
        };

        this.state.cdTipos = [
            { value: "BOOLEAN", label: "Boleano", flSize: false, flJson: false },
            { value: "VARCHAR", label: "Resposta Curta", flSize: true, flJson: false },
            { value: "TEXT", label: "Parágrafo", flSize: true, flJson: false },
            { value: "INTEGER", label: "Número Inteiro", flSize: false, flJson: false },
            { value: "DECIMAL", label: "Número Decimal", flSize: true, flJson: false },
            { value: "DROP_DOWN", label: "Lista Suspensa", flSize: false, flJson: true },
            { value: "CHECKBOX", label: "Caixas de Seleção", flSize: false, flJson: true },
            { value: "DATE", label: "Data", flSize: false, flJson: false },
            { value: "TIME", label: "Hora", flSize: false, flJson: false },
            
            { value: "CPF", label: "CPF", flSize: false, flJson: false },
            { value: "CNPJ", label: "CNPJ", flSize: false, flJson: false },
            { value: "TELEFONE", label: "TELEFONE", flSize: false, flJson: false },
            { value: "CEP", label: "CEP", flSize: false, flJson: false },
        ];

        this.state.isCard = true;
        this.state.container = false;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeTexto(){
        var campos = Array.from(this.state.model.dsTexto.matchAll(/\[\[([a-zA-Z0-9_-]+)\]\]/g));
        this.setState(state =>{

            var contratoCampo = [];

            campos.forEach(item =>{
                var cdCampo = item[1];
                var index = state.model.contratoCampo.findIndex(i => i.cdCampo === cdCampo);

                if(contratoCampo.findIndex(i => i.cdCampo === cdCampo) === -1){
                    if(index === -1){
                        contratoCampo.push({
                            cdCampo : cdCampo,
                            dsTitulo : cdCampo,
                            cdTipo : "VARCHAR",
                            flObrigatorio : true,
                            dsPadrao : "",
                            nrTamanho : 100,
                            dsJson : ""
                        });
                    }else{
                        contratoCampo.push(state.model.contratoCampo[index]);
                    }
                }
            });

            state.model.contratoCampo = contratoCampo;
            return state;
        });
    }

    onSubmit(e) {

        if (e?.nativeEvent?.submitter?.id === "btnSalvarContinuar") {
            super.onSubmit(e, (response) => {
                if (this.state.model.idContrato) {
                    this.getById(this.state.model.idContrato)
                } else {
                    this.props.navigate('/CadContrato/' + response.data.idContrato);
                    this.getById(response.data.idContrato)
                }
                return;
            });
            return;
        }
        
        super.onSubmit(e);
    }

    handleClickVerContrato(){
        var urlRelatorio = "relatorio/contrato-001";
        Util.openNewWindow(Config.getUrlApi() + urlRelatorio, {idContrato : this.state.model.idContrato});        
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnSubmit className="btn-primary" id="btnSalvarFechar"> Salvar e Fechar </BtnSubmit>
                {this.state.model.idContrato ?  
                <>
                <BtnSubmit className="btn-outline-primary" id="btnSalvarContinuar"> Salvar e Continuar </BtnSubmit> 
                    <BtnButton  className="btn-outline-danger" onClick={this.handleClickVerContrato}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                            <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                            <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                        </svg> Ver Contrato
                    </BtnButton>
                </>: <></>}
                <div className="vr"></div>
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
                
            </div>);
    }

    getCardHeader() {
        var locationHash = this.props.location.hash;

        if(locationHash === ""){
            locationHash = "#nav-contrato";
        }

        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <Link to={'#nav-contrato'} className={`nav-link ${locationHash === "#nav-contrato" ? "active" : ""}`}>
                        Contrato
                    </Link>
                </li>                
                <li className="nav-item">
                    <Link to={'#nav-campo'} className={`nav-link ${locationHash === "#nav-campo" ? "active" : ""}`}>
                        Campos
                    </Link>
                </li>                
            </ul>
        );
    }

    getRenderFields() {
        var locationHash = this.props.location.hash;

        if(locationHash === ""){
            locationHash = "#nav-contrato";
        }

        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className={`tab-pane fade ${locationHash === "#nav-contrato" ? "show active" : ""}`} id="nav-contrato" role="tabpanel" aria-labelledby="nav-contrato-tab">
                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={3} value={this.state.model.idContrato} name="idContrato" readOnly opcionalLabel={false}/>
                        <InputText label="Nome do Contrato" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                        <TextArea label="Texto" md={12} value={this.state.model.dsTexto} name="dsTexto" onChange={e => this.handleChange(e, this.handleChangeTexto)} style={{height:"400px"}}/>
                    </div>
                </div>
                <div className={`tab-pane fade ${locationHash === "#nav-campo" ? "show active" : ""}`} id="nav-campo" role="tabpanel" aria-labelledby="nav-campo-tab">
                    <div className="table-responsive">
                        <table className="table table-sm caption-top">
                            <thead className="table-light">
                                <tr>
                                    <th className="col-2"> Campo </th>
                                    <th className="col-2"> Título </th>
                                    <th className="col-2"> Tipo </th>                                    
                                    <th className="col"> Obrig. </th>
                                    <th className="col-2"> Padrão </th>
                                    <th className="col"> Tamanho </th>
                                    <th className="col-4"> Dados </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.model.contratoCampo.map((item, index) => (
                                    <tr key={index} className="tr-borderless">
                                        <td>{item.cdCampo}</td>
                                        <td>
                                            <InputText value={item.dsTitulo} required name="dsTitulo" onChange={e => this.handleChange(e, false, this.state.model.contratoCampo[index])} />
                                        </td>
                                        <td>
                                            <FormSelect name="cdTipo" value={item.cdTipo} options={this.state.cdTipos} onChange={e => this.handleChange(e, false, this.state.model.contratoCampo[index])} opcionalLabel={false} />
                                        </td>
                                        <td className="text-center">
                                            <InputCheckSwitch label="" name="flObrigatorio" checked={Boolean(item.flObrigatorio)} onChange={e => this.handleChange(e, false, this.state.model.contratoCampo[index])} />
                                        </td>
                                        <td>
                                            <InputText value={item.dsPadrao} name="dsPadrao" onChange={e => this.handleChange(e, false, this.state.model.contratoCampo[index])} />
                                        </td>
                                        <td>
                                            <InputNumber disabled={!(this.state.cdTipos.find(i => item.cdTipo === i.value)?.flSize)} required={(this.state.cdTipos.find(i => item.cdTipo === i.value)?.flSize)} value={item.nrTamanho} name="nrTamanho" onChange={e => this.handleChange(e, false, this.state.model.contratoCampo[index])} />
                                        </td>
                                        <td>
                                        {this.state.cdTipos.find(i => item.cdTipo === i.value)?.flJson ?
                                            <TextArea className="bg-dark text-white" style={{ height: "250px", fontFamily: "Courier New" }} required={true} name="dsJson" value={this.state.model.dsJson} onChange={e => this.handleChange(e, false, this.state.model.contratoCampo[index])} />
                                        : <></>}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <FormContrato {...props} navigate={navigate} params={params}  location={location}/>
}

export default With