import Http from "../controllers/Http";

export default class ExtratoService {

    static urlBase = "/extrato/";

    static getExtrato(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getSaldo(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'saldo', data)
            .then(fnSucess)
            .catch(fnError);
    }
}
