import Version from "./Version";

export default class Config {

    static versao = Version.version;

    static timeValidateLogged = 60; //segundos
    static timeRefreshToken = 60 * 15; //segundos    
    static timeGetNotification = 60; //segundos
    static timeGetVersao = 60 * 15; //segundos
    static timeListUsuarioOnline = 60; //segundos
    static timeListContatosAtivos = 60; //segundos
    static timeGetMural = (60 * 5); //5 minutos
    static clientIdGoogleAuth = '608595942001-msc454qbi2kgbbpu1qm5clk2tugqt8pe.apps.googleusercontent.com';

    static default = {
        layoutFormControl: "grid", //floating || horizontal || grid
        placeHolderDefaultLabelValue: true,
        opcionalLabelDefaultNotRequired: true
    };

    static getAmbient() {
        var ambient = "";
        if (process.env.REACT_APP_ENV) {
            ambient = process.env.REACT_APP_ENV;
        } else {
            ambient = process.env.NODE_ENV;
        }
        return ambient.trim();
    }

    static getUrlApi(ambient = null) {

        if(!ambient){
            ambient = this.getAmbient();
        }

        switch (ambient) {
            case "development":
                return "//localhost/api/";
            case "homologation":
                return "//localhost/api/";
            case "production":
                return "//busca.bdmperformance.com.br/api/";
            default:
                return "";
        }
    }

    static getUrlArquivo(ambient = null) {

        if(!ambient){
            ambient = this.getAmbient();
        }

        switch (ambient) {
            case "development":
                return "//localhost/api/";
            case "homologation":
                return "//localhost/api/";
            case "production":
                return "//arquivo.bdmperformance.com.br/api/";
            default:
                return "";
        }
    }
}