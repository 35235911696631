import { Component } from "react";
import EventBus from "../../controllers/EventBus";
import Modal from "../../controllers/Modal";
import ModalForm from "../modal/ModalForm";

export default class ModalContainer extends Component {

    constructor(props) {
        super(props);

        this.addModal = this.addModal.bind(this);
        this.delModal = this.delModal.bind(this);

        this.state = {
            modals: []
        };
    }

    componentDidMount() {
        EventBus.on("modal-add", (config) => {
            this.addModal(config);
        });

        EventBus.on("modal-del", () => {
            this.delModal();
        });
    }

    addModal(config) {
        this.setState((state) => {
            var element = {
                config: config,
                index: Modal.getInstance().addModal()
            };
            state.modals.push(element);
            return state;
        });
    }

    delModal() {
        this.setState((state) => {
            Modal.getInstance().delModal();
            state.modals.pop();
            return state;
        });
    }

    render() {
        return (
            <>
                {this.state.modals.map((element, index) => (
                    <ModalForm key={index} {...element.config} onDelete={this.delModal} zIndex={element.index} />
                ))}
            </>
        );
    }
}
