import React from "react";
import CrudForm from "../../components/forms/custom/CrudForm";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import Util from "../../utils/Util";
import Config from "../../Config";
import InputDate from "../../components/forms/elements/InputDate";
import SelectContatoTipoByUsuario from "../../components/app/SelectContatoTipoByUsuario";
import SelectContatoStatusByContatoTipo from "../../components/app/SelectContatoStatusByContatoTipo";
import SelectContatoOrigem from "../../components/app/SelectContatoOrigem";
import SelectContatoMotivoByContatoStatus from "../../components/app/SelectContatoMotivoByContatoStatus";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";

export default class FormRelatorioContato001 extends CrudForm {

    constructor(props) {
        super(props);

        this.state.isModal = true;

        this.urlRelatorio = "relatorio/contato-001";

        /*var tzoffset = (new Date()).getTimezoneOffset() * 60000;

        var dtInsertEnd = new Date();
        dtInsertEnd = new Date(dtInsertEnd - tzoffset).toISOString().slice(0, 10);

        var dtInsertStart = new Date();
        dtInsertStart.setDate(dtInsertStart.getDate() - 7);
        dtInsertStart = new Date(dtInsertStart - tzoffset).toISOString().slice(0, 10);*/

        this.state.model = {
            dtInsertStart: this.props.dtInsertStart !== undefined ? this.props.dtInsertStart : "",
            dtInsertEnd: this.props.dtInsertEnd !== undefined ? this.props.dtInsertEnd : "",
            idContatoOrigem: this.props.idContatoOrigem !== undefined ? this.props.idContatoOrigem : "",
            idContatoTipo: this.props.idContatoTipo !== undefined ? this.props.idContatoTipo : "",
            idContatoStatus: this.props.idContatoStatus !== undefined ? this.props.idContatoStatus : "",
            idContatoMotivo: this.props.idContatoMotivo !== undefined ? this.props.idContatoMotivo : "",
            flSomentePendente: this.props.flSomentePendente !== undefined ? this.props.flSomentePendente : false,
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(e) {
        Util.openNewWindow(Config.getUrlApi() + this.urlRelatorio, this.state.model);
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputDate md={6} label="Data Inicial" value={this.state.model.dtInsertStart} name="dtInsertStart" onChange={this.handleChange} />
                    <InputDate md={6} label="Data Final" value={this.state.model.dtInsertEnd} name="dtInsertEnd" onChange={this.handleChange} min={this.state.model.dtInsertStart} />
                    <SelectContatoOrigem showBtns={false} label="Origem" md={12} value={this.state.model.idContatoOrigem} placeholder="Selecione a Origem" required={false} name="idContatoOrigem" onChange={this.handleChange} />
                    <SelectContatoTipoByUsuario showBtns={false} label="Tipo do Contato" md={12} placeholder="Selecione o Tipo" name="idContatoTipo" value={this.state.model.idContatoTipo} onChange={this.handleChange} />
                    <SelectContatoStatusByContatoTipo showBtns={false} idContatoTipo={this.state.model.idContatoTipo} ativo={1} label="Nome do Status" placeholder="Selecione o Status" name="idContatoStatus" md={12} value={this.state.model.idContatoStatus} onChange={this.handleChange} />
                    <SelectContatoMotivoByContatoStatus showBtns={false} label="Nome do Motivo" idContatoStatus={this.state.model.idContatoStatus} placeholder="Selecione o Motivo" name="idContatoMotivo" md={12} value={this.state.model.idContatoMotivo} onChange={this.handleChange} />
                </div>
                <div className="row mx-1 mb-3">
                    <InputCheckSwitch label="Somente pendente de atendimento?" name="flSomentePendente" checked={Boolean(this.state.model.flSomentePendente)} onChange={this.handleChange} />
                </div>
            </>
        );
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="ms-auto btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
                <div className="vr"></div>
                <BtnSubmit className="btn-primary"> Emitir Relatório </BtnSubmit>
            </div>);
    }

    render() {
        return super.render();
    }
}