import React from "react";
import CrudForm from "../../components/forms/custom/CrudForm";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import SelectCategoriaProdutoServicoByUsuario from "../../components/app/SelectCategoriaProdutoServicoByUsuario";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import Util from "../../utils/Util";
import Config from "../../Config";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputNumber from "../../components/forms/elements/InputNumber";

export default class FormRelatorioProduto003 extends CrudForm {

    constructor(props) {
        super(props);

        this.state.isModal = true;

        this.urlRelatorio = "relatorio/produto-003";

        this.state.model = {
            idCategoriaProdutoServico: "",
            idTipoProdutoServico: "",
            flProdutoSemEstoque: false,
            flEstoqueAbaixoMinino: true,
            flPercentualMinimo: false,
            orientation: "L",
            destination: "I",
            output: "PDF",
            flMarcaDagua: true,
            vlPercentualMinimoInicial: 0,
            vlPercentualMinimoFinal: 100,
        };

        this.state.orientation = [
            { value: 'P', label: 'Vertical' },
            { value: 'L', label: 'Horizontal' },
        ]

        this.state.destination = [
            { value: 'I', label: 'Tela' },
            { value: 'D', label: 'Download' },
        ]

        this.state.output = [
            { value: 'PDF', label: 'PDF' },
            { value: 'XLS', label: 'XLS' },
        ]

        this.state.filtroTipo = [
            { value: "T", label: "Todos" },
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ]
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(e) {
        Util.openNewWindow(Config.getUrlApi() + this.urlRelatorio, this.state.model);
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <SelectCategoriaProdutoServicoByUsuario showBtnNewForm={false} md={12} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={this.handleChange} />
                    <SelectTipoProdutoServicoByCategoria showBtnNewForm={false} md={12} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} disabled={!this.state.model.idCategoriaProdutoServico} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={this.handleChange} />
                </div>
                <div className="row px-3 mb-3">
                    <InputCheckSwitch label="Somente produtos com estoque abaixo do mínimo" name="flEstoqueAbaixoMinino" checked={Boolean(this.state.model.flEstoqueAbaixoMinino)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Somente produtos sem estoque" name="flProdutoSemEstoque" checked={Boolean(this.state.model.flProdutoSemEstoque)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Mostrar marca d'água" name="flMarcaDagua" checked={Boolean(this.state.model.flMarcaDagua)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Filtrar Percentual para Chegar ao Mínimo" name="flPercentualMinimo" checked={Boolean(this.state.model.flPercentualMinimo)} onChange={this.handleChange} />
                </div>
                {Boolean(this.state.model.flPercentualMinimo) ?
                    <div className="row mb-3">
                        <InputNumber required md={3} label="Percentual Inicial" name="vlPercentualMinimoInicial" value={this.state.model.vlPercentualMinimoInicial} onChange={this.handleChange} />
                        <InputNumber required md={3} label="Percentual Final" name="vlPercentualMinimoFinal" value={this.state.model.vlPercentualMinimoFinal} onChange={this.handleChange} />
                    </div>
                    : <></>}
                <div className="row mb-3">
                    <BtnGroup md={4} sizing="sm" className="w-100" name="orientation" value={this.state.model.orientation} label="Disposição da Página" options={this.state.orientation} onChange={this.handleChange} required />
                    <BtnGroup md={4} sizing="sm" className="w-100" name="destination" value={this.state.model.destination} label="Destino do Relatório" options={this.state.destination} onChange={this.handleChange} required />
                    <BtnGroup md={4} sizing="sm" className="w-100" name="output" value={this.state.model.output} label="Formato de Saída" options={this.state.output} onChange={this.handleChange} required />
                </div>
            </>
        );
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="ms-auto btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
                <div className="vr"></div>
                <BtnSubmit className="btn-primary"> Emitir Relatório </BtnSubmit>
            </div>);
    }

    render() {
        return super.render();
    }
}