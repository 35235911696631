import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import MuralService from "../../services/MuralService";
import CrudForm from "../../components/forms/custom/CrudForm";
import TextArea from "../../components/forms/elements/TextArea";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import UsuarioService from "../../services/UsuarioService";
import Util from "../../utils/Util";
import SelectPerfil from "../../components/app/SelectPerfil";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputFile from "../../components/forms/elements/InputFile";
import Config from "../../Config";
import ArquivoService from "../../services/ArquivoService";

class FormMural extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeMuralUsuario = this.handleChangeMuralUsuario.bind(this);
        this.handleChangeMuralUsuarioAll = this.handleChangeMuralUsuarioAll.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleDeleteItemArquivo = this.handleDeleteItemArquivo.bind(this);
        this.getUsuarios = this.getUsuarios.bind(this);

        this.state.isCard = true;

        this.state.model = {
            idMural: "",
            dsTitulo: "",
            dsMensagem: "Exemplo de Texto Normal\n*Exemplo de Texto Negrito*\n_Exemplo de Texto Itálico_\n~Exemplo de Texto Tachado~\n`Exemplo de Texto Code`\n_http://exemplodelink.com_",
            flImportante: false,
            flAtivo: true,
            muralUsuario: [],
            muralArquivo: [],
        };

        this.state.usuarios = [];
        this.state.flResultado = true;

        this.state.filtroUsuario = {
            flStatus: "A",
            idPerfil: "",
            idRepresentante: ""
        };

        this.state.filtroStatus = [
            { value: "T", label: "Todos" },
            { value: "A", label: "Ativos" },
            { value: "I", label: "Inativo" },
        ];

        this.state.crud = {
            service: MuralService,
            labels: {
                title: 'Mural',
            },
            urlList: '/LstMural',
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getUsuarios();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getMuralUsuario(item) {
        return this.state.model.muralUsuario.find(i => parseInt(i.idUsuario) === parseInt(item.idUsuario));
    }

    getUsuarios() {
        UsuarioService.listForList(this.state.filtroUsuario, response => {
            this.setState({ usuarios: response });
        });
    }

    handleChangeMuralUsuario(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.muralUsuario.push(item);
                return state;
            });
        } else {
            var index = this.state.model.muralUsuario.findIndex(i => parseInt(i.idUsuario) === parseInt(item.idUsuario));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.muralUsuario.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeMuralUsuarioAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.muralUsuario = [];

                this.state.usuarios.forEach(function (item) {
                    state.model.muralUsuario.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.muralUsuario = [];
                return state;
            });
        }
    }

    handleFileChange(event) {
        var files = event.target?.files;
        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                ArquivoService.upload(files[i], (response) => {
                    this.setState((state) => {
                        state.model.muralArquivo.push(response.data);
                        return state;
                    });
                });
            }
        }
    }

    handleDeleteItemArquivo(index, item) {
        this.setState((state) => {
            state.model.muralArquivo.splice(index, 1);
            return state;
        });
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-dados-tab" data-bs-toggle="tab" data-bs-target="#nav-dados" type="button" role="tab" aria-controls="nav-dados" aria-selected="true">
                        Dados
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-usuarios-tab" data-bs-toggle="tab" data-bs-target="#nav-usuarios" type="button" role="tab" aria-controls="nav-usuarios" aria-selected="false">
                        Usuários
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-arquivos-tab" data-bs-toggle="tab" data-bs-target="#nav-arquivos" type="button" role="tab" aria-controls="nav-arquivos" aria-selected="false">
                        Anexo
                    </button>
                </li>
            </ul>);
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">
                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={3} value={this.state.model.idMural} name="idMural" readOnly opcionalLabel={false} />
                        <InputText label="Título" autoFocus={true} required md={12} value={this.state.model.dsTitulo} name="dsTitulo" onChange={this.handleChange} maxLength={100} />
                        <TextArea label="Mensagem" required md={12} value={this.state.model.dsMensagem} name="dsMensagem" onChange={this.handleChange} style={{ height: "300px" }} />

                        {Boolean(this.state.flResultado) ?
                            <div className="col-lg-12" style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "20em" }} >
                                <div className="bg-light rounded border border-ligth p-2" dangerouslySetInnerHTML={{ __html: Util.htmlFormatWhatsapp(this.state.model.dsMensagem) }} style={{ whiteSpace: "pre-wrap" }} />
                            </div>
                            : <></>
                        }
                    </div>
                    <div className="row mx-1 mb-3">
                        <InputCheckSwitch label="Ver Resultado da Mensagem" name="flResultado" checked={Boolean(this.state.flResultado)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Marcar Mensagem como Importante?" name="flImportante" checked={Boolean(this.state.model.flImportante)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-usuarios" role="tabpanel" aria-labelledby="nav-usuarios-tab">
                    <div className="row mb-3">
                        <SelectPerfil label="Nome do Perfil" showBtnNewForm={false} placeholder="Selecione o Perfil" name="idPerfil" md={5} value={this.state.filtroUsuario.idPerfil} onChange={(e) => { this.handleChange(e, this.getUsuarios, this.state.filtroUsuario) }} opcionalLabel={false} />
                        <SelectRepresentanteByUsuario showBtnNewForm={false} md={5} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.filtroUsuario.idRepresentante} onChange={(e) => { this.handleChange(e, this.getUsuarios, this.state.filtroUsuario) }} opcionalLabel={false} />
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flStatus" value={this.state.filtroUsuario.flStatus} label="Status" options={this.state.filtroStatus} onChange={(e) => { this.handleChange(e, this.getUsuarios, this.state.filtroUsuario) }} opcionalLabel={false} />
                    </div>

                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-center">
                                </th>
                                <th className="text-center">
                                    <InputCheckSwitch name="todos" onChange={this.handleChangeMuralUsuarioAll} checked={this.state.model.muralUsuario.length >= this.state.usuarios.length} />
                                </th>
                                <th className="text-center d-none d-md-table-cell"> Código </th>
                                <th className="col-md-4"> Nome </th>
                                <th className="col-md-4"> Perfil </th>
                                <th className="text-center d-none d-md-table-cell"> Último Acesso </th>
                                <th className="text-center d-none d-md-table-cell"> Leitura </th>
                                <th className="text-center d-none d-md-table-cell"> Ativo? </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.usuarios.map((item, index) => {
                                const muralUsuario = this.state.model.muralUsuario.find(i => parseInt(i.idUsuario) === parseInt(item.idUsuario));

                                return (
                                    <tr key={index}>
                                        <td className="text-center">
                                            {Boolean(muralUsuario?.flLeitura) ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-eye-fill text-primary" viewBox="0 0 16 16">
                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                </svg> :
                                                <></>
                                            }
                                        </td>
                                        <td className="text-center">
                                            <InputCheckSwitch name="usuario" disabled={Boolean(muralUsuario?.flLeitura)} checked={this.state.model.muralUsuario.findIndex(i => parseInt(i.idUsuario) === parseInt(item.idUsuario)) !== -1} onChange={(e) => { this.handleChangeMuralUsuario(e, item) }} />
                                        </td>
                                        <td className="text-center d-none d-md-table-cell">{item.idUsuario}</td>
                                        <td>{item.nome}</td>
                                        <td>{item.nomePerfil}</td>
                                        <td className="text-center d-none d-md-table-cell text-nowrap">
                                            <small>{(item.dataAcesso ? Util.date2Br(item.dataAcesso) + " " + item.horaAcesso : "")}</small>
                                        </td>
                                        <td className="text-center d-none d-md-table-cell text-nowrap">
                                            {
                                                Boolean(muralUsuario?.flLeitura) ?
                                                    <span className="badge bg-primary">{Util.date2Br(muralUsuario.dataLeitura)} {muralUsuario.horaLeitura}</span>
                                                    : <></>
                                            }
                                        </td>
                                        <td className="text-center d-none d-md-table-cell">
                                            {item.ativo ? "SIM" : "NÃO"}
                                        </td>
                                    </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="tab-pane fade" id="nav-arquivos" role="tabpanel" aria-labelledby="nav-arquivos-tab">
                    <div className="row mb-3 g-3">
                        <InputFile label="Selecione o arquivo" name="arquivo" multiple onChange={(event) => { this.handleFileChange(event) }} accept="*/*" />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col"> Tipo </th>
                                        <th className="col-4"> Nome </th>
                                        <th className="col-6"> Arquivo </th>
                                        <th className="col-2 text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.muralArquivo.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td className="text-center">
                                                {Util.getIconeTipo(Util.getTipoArquivo(item.tipoArquivo), 32, 32)}
                                            </td>
                                            <td>
                                                {item.nomeArquivo}
                                            </td>
                                            <td className="text-center">
                                                {Util.getTipoArquivo(item.tipoArquivo) === "IMAGEM" ?
                                                    <img style={{ maxHeight: "150px" }} src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uid} className="img-fluid img-thumbnail" alt="..." />
                                                    : <></>
                                                }
                                            </td>
                                            <td className="align-middle text-center text-nowrap">
                                                <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteItemArquivo(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg> Excluir Arquivo
                                                </button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormMural {...props} navigate={navigate} params={params} />
}

export default With