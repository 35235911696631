import Config from "../Config";
import Auth from "../controllers/Auth";
import Http from "../controllers/Http";

export default class VersaoService {

    static urlBase = "/versao/";

    static verificaVersao(fnSucess = () => { }, fnError = () => { }) {
        if (Auth.isAuthenticated()) {
            Http.getInstanceAppJson({ showLoadding: false })
                .post(this.urlBase + 'verificaVersao', {
                    versao: Config.versao
                })
                .then(fnSucess)
                .catch(fnError);
        }
    }
}
