import Http from "../controllers/Http";

export default class ParametroService {

    static urlBase = "/parametro/";

    static getForCombo(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forCombo')
            .then(fnSucess)
            .catch(fnError);
    }

    static list(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase)
            .then(fnSucess)
            .catch(fnError);
    }

    static listByModulo(idModulo, cdChave, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byModulo/' + idModulo + '/' + cdChave)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static update(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static save(data, fnSucess = () => { }, fnError = () => { }) {
        if (data.idParametro) {
            this.update(data.idParametro, data, fnSucess, fnError);
        } else {
            this.create(data, fnSucess, fnError);
        }
    }
}
