import React from "react";
import PaisService from "../../services/PaisService";
import CrudList from "../../components/forms/custom/CrudList";
import { useNavigate, useParams } from "react-router-dom";

class ListaPais extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: PaisService,
            labels: {
                title: 'Lista de País(es)',
                btnNewRegister: 'País',
            },
            keyItem: 'idPais',
            urlForm: '/CadPais',
            itensFilter: ["idPais", "nome", "sigla", "ddi"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-6"> Nome </th>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Sigla </th>
                <th className="col-md-2 text-center d-none d-md-table-cell"> DDI </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idPais}</td>
                <td>{item.nome}</td>
                <td className="text-center d-none d-md-table-cell">{item.sigla}</td>
                <td className="text-center d-none d-md-table-cell">{item.ddi}</td>
            </>);
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <ListaPais {...props} navigate={navigate} params={params} />
}

export default With