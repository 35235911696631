import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ContatoMotivoService from "../../services/ContatoMotivoService.js";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import SelectContatoTipo from "../../components/app/SelectContatoTipo";
import SelectContatoStatusByContatoTipo from "../../components/app/SelectContatoStatusByContatoTipo";

class FormContatoMotivo extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idContatoMotivo: "",
            nome: "",
            idContatoTipo: "",
            idContatoStatus: "",
            flAtivo: 1,
            flMotivoInicial: 0,
            flMotivoVisualizado: 0,
            flFinalizado: 0,
        };

        this.state.crud = {
            service: ContatoMotivoService,
            labels: {
                title: 'Motivo',
            },
            urlList: '/LstContatoMotivo',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idContatoMotivo} name="idContatoMotivo" readOnly opcionalLabel={false} />
                    <InputText label="Nome do Motivo" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                </div>
                <div className="row mb-3 g-3">
                    <SelectContatoTipo label="Tipo do Contato" md={6} placeholder="Selecione o Tipo" name="idContatoTipo" value={this.state.model.idContatoTipo} onChange={this.handleChange} opcionalLabel={false} />
                    <SelectContatoStatusByContatoTipo idContatoTipo={this.state.model.idContatoTipo} label="Nome do Status" required placeholder="Selecione o Status" name="idContatoStatus" md={6} value={this.state.model.idContatoStatus} onChange={this.handleChange} />
                </div>
                <div className={`row mx-1 mb-3 g-1`}>
                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                    <InputCheckSwitch label="É o motivo Padrão Inicial?" name="flMotivoInicial" checked={Boolean(this.state.model.flMotivoInicial)} onChange={this.handleChange} />
                    <InputCheckSwitch label="É o motivo Padrão de Visualizado?" name="flMotivoVisualizado" checked={Boolean(this.state.model.flMotivoVisualizado)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Contato Finalizado?" name="flFinalizado" checked={Boolean(this.state.model.flFinalizado)} onChange={this.handleChange} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormContatoMotivo {...props} navigate={navigate} params={params} />
}

export default With