import React from "react";
import ProprietarioService from "../../services/ProprietarioService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaProprietario extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ProprietarioService,
            labels: {
                title: 'Lista de Proprietario(s)',
                btnNewRegister: 'Proprietario',
            },
            keyItem: 'idProprietario',
            urlForm: '/CadProprietario',
            itensFilter: ["idProprietario", "nome"],
            searchFormSubmit: false
        };

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-7"> Nome </th>
                <th className="col-md-3"> Tipo </th>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Representantes </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-centerd-none d-md-table-cell">{item.idProprietario}</td>
                <td>{item.nome}</td>
                <td>{this.state.tipoPessoa.find(tp => tp.value === item.cdTipoPessoa)?.label}</td>
                <td className="text-center d-none d-md-table-cell">{item.nrRepresentanteAtivos}</td>
            </>);
    }

    render() {
        return super.render();
    }
}