import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import FaturaService from "../../services/FaturaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import BtnButton from "../../components/forms/elements/BtnButton";
import EventBus from "../../controllers/EventBus";
import BaixaService from "../../services/BaixaService";
import Permissao from "../../controllers/Permissao";
import FormOrdem from "../Ordem/FormOrdem"
import FormBaixa from "../Baixa/FormBaixa"
import FormHistoricoBaixa from "../Baixa/FormHistoricoBaixa"
import FormHistoricoTransacao from "../Baixa/FormHistoricoTransacao"
import FormCobrancaVencimento from "./FormCobrancaVencimento"
import FormCobrancaAgendamento from "./FormCobrancaAgendamento"
import FormCobrancaTipoBaixa from "./FormCobrancaTipoBaixa"
import FormRelatorioFatura001 from "../Relatorio/FormRelatorioFatura001";
import FormRelatorioFatura002 from "../Relatorio/FormRelatorioFatura002";
import FormFaturaPessoa from "./FormFaturaPessoa";
import FormEmbalagem from "./FormEmbalagem";
import FormWhatsapp from "../Whatsapp/FormWhatsapp";
import FaturaNotaFiscalService from "../../services/FaturaNotaFiscalService";
import MensagemService from "../../services/MensagemService";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import MostraValor from "../../components/app/MostraValor";
import InputValorReais from "../../components/forms/custom/InputValorReais";
import CobrancaService from "../../services/CobrancaService";

class FormFatura extends CrudForm {

    constructor(props) {
        super(props);

        this.estornar = this.estornar.bind(this);
        this.handleEstornarBaixa = this.handleEstornarBaixa.bind(this);
        this.handleImprimirFatura = this.handleImprimirFatura.bind(this);
        this.handleImprimirEtiqueta = this.handleImprimirEtiqueta.bind(this);
        this.handleClickBaixar = this.handleClickBaixar.bind(this);
        this.handleClickAlterarVencimento = this.handleClickAlterarVencimento.bind(this);
        this.handleClickRegistrarAgendamento = this.handleClickRegistrarAgendamento.bind(this);
        this.handleClickCancelarAgendamento = this.handleClickCancelarAgendamento.bind(this);
        this.handleClickAlterarTipoBaixa = this.handleClickAlterarTipoBaixa.bind(this);
        this.handleClickCriarNotaFiscal = this.handleClickCriarNotaFiscal.bind(this);
        this.handleClickEnviarNotaFiscal = this.handleClickEnviarNotaFiscal.bind(this);
        this.handleClickCapturarNotaFiscal = this.handleClickCapturarNotaFiscal.bind(this);
        this.handleClickEstornarNotaFiscal = this.handleClickEstornarNotaFiscal.bind(this);
        this.handleClickEstornarFatura = this.handleClickEstornarFatura.bind(this);
        this.handleClickEditarRastreio = this.handleClickEditarRastreio.bind(this);

        this.state.model = {
            idFatura: "",
            nomeProprietario: "",
            anoReferencia: "",
            mesReferencia: "",
            vlFatura: 0,
            dtVencimento: "",
            dtPagamento: "",
            flVencido: false,
            flPago: false,
            faturaPessoa: {
                nome: ""
            },
            faturaNotaFiscal: [],
            faturaOrdem: [],
            faturaRepresentante: [],
            cobranca: [],
            baixa: [],
            faturaTransporte: [],
            faturaEmbalagem: [],
            flProduto : false,
            flServico : false
        };

        this.state.container = false;

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];

        this.state.crud = {
            service: FaturaService,
            keyName : "idFatura",
            labels: {
                title: 'Fatura',
            },
            urlList: '/LstFatura',
        };
        

        //this.props.setSearchParams("#nav-pessoa-tab")
        //this.props.navigate('#my-fancy-hash');
    }

    componentDidMount() {
        super.componentDidMount();

        if (!this.props.params?.id && this.props.idFatura) {
            this.onEdit(this.props.idFatura);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickTab(){

    }


    handleOpenModalFatura(ordem) {
        EventBus.dispatch("modal-add", {
            title: 'Dados da Ordem',
            sizeModal: 'modal-xl',
            fullScreen : true,
            config: {
                isModal: true,
                idOrdem: ordem.idOrdem
            },
            form: FormOrdem,
            onClose: () => {
            }
        });
    }

    handleEstornarBaixa(item) {

        EventBus.dispatch("modal-confirmacao-open", {
            id: item.idBaixa,
            config: {
                title: "Estornar Baixa?",
                message: <>Deseja realmente estornar a baixa: <strong>{item.idBaixa} </strong>?</>,
                btnConfirmText: "Sim, estornar a baixa",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: this.estornar
            }
        })
    }

    estornar(item) {
        BaixaService.estornar(item.id, (response) => {
            this.getById();
        });
    }

    handleImprimirFatura() {
        EventBus.dispatch("modal-add", {
            title: 'Impressão da Fatura',
            sizeModal: 'modal-lg',
            scrollable: false,
            config: {
                isModal: true,
                idFaturas: [this.state.model.idFatura]
            },
            form: FormRelatorioFatura001
        });
    }

    handleImprimirEtiqueta() {
        EventBus.dispatch("modal-add", {
            title: 'Impressão da Etiqueta',
            sizeModal: 'modal-lg',
            scrollable: false,
            config: {
                isModal: true,
                idFaturas: [this.state.model.idFatura]
            },
            form: FormRelatorioFatura002
        });
    }

    handleClickBaixar(item) {
        EventBus.dispatch("modal-add", {
            title: 'Baixa Manual - Cobrança: ' + item.idCobranca,
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idFatura: item.idFatura,
                idCobranca: item.idCobranca
            },
            form: FormBaixa,
            onClose: () => {

            },
            onSave: () => {
                this.getById();
            }
        });
    }

    handleClickHistoricoBaixa(item) {
        EventBus.dispatch("modal-add", {
            title: 'Histórico de Baixas',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idFatura: item.idFatura,
                idCobranca: item.idCobranca
            },
            form: FormHistoricoBaixa,
            onClose: () => {
                this.getById();
            },
            onSave: () => {
                this.getById();
            }
        });
    }

    handleClickHistoricoTransacao(item) {
        EventBus.dispatch("modal-add", {
            title: 'Histórico de Transações',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idCobranca: item.idCobranca
            },
            form: FormHistoricoTransacao,
            onClose: () => {
            },
            onSave: () => {
            }
        });
    }

    handleClickAlterarVencimento(item) {
        EventBus.dispatch("modal-add", {
            title: 'Alteração de Cobrança',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idCobranca: item.idCobranca
            },
            form: FormCobrancaVencimento,
            onClose: () => {

            },
            onSave: () => {
                this.getById();
            }
        });
    }

    handleClickRegistrarAgendamento(item) {
        EventBus.dispatch("modal-add", {
            title: 'Registrar Agendamento',
            sizeModal: 'modal-lg',
            config: {
                isModal: true,
                idCobranca: item.idCobranca
            },
            form: FormCobrancaAgendamento,
            onClose: () => {

            },
            onSave: () => {
                this.getById();
            }
        });
    }

    handleClickCancelarAgendamento(item) {

        EventBus.dispatch("modal-confirmacao-open", {
            config: {
                title: "Exclusão do agendamento?",
                message: <>Confirma exclusão do agendamento?</>,
                btnConfirmText: "Sim, cancelar o agendamento",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: () => {
                    CobrancaService.deleteAgendamento(item.idCobranca, (response) => {
                        this.getById();
                    });
                }
            }
        })


    }

    handleClickAlterarTipoBaixa(item) {
        EventBus.dispatch("modal-add", {
            title: 'Alteração de Cobrança',
            sizeModal: 'modal-lg',
            config: {
                isModal: true,
                idCobranca: item.idCobranca
            },
            form: FormCobrancaTipoBaixa,
            onClose: () => {

            },
            onSave: () => {
                this.getById();
            }
        });
    }

    handleClickEstornarNotaFiscal(faturaNotaFiscal) {
        EventBus.dispatch("modal-confirmacao-open", {
            config: {
                title: "Estornar nota fiscal?",
                message: <>Confirmar estorno da nota fiscal?</>,
                btnConfirmText: "Sim, enviar a nota fiscal",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: () => {
                    FaturaNotaFiscalService.estornarNotaFiscal(faturaNotaFiscal.idFaturaNotaFiscal, (response) => {
                        this.getById();
                    });
                }
            }
        })
    }

    handleClickEnviarNotaFiscal(faturaNotaFiscal) {
        EventBus.dispatch("modal-confirmacao-open", {
            config: {
                title: "Enviar nota fiscal para a receita?",
                message: <>Confirmar envio da nota fiscal para a receita?</>,
                btnConfirmText: "Sim, enviar a nota fiscal",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: () => {
                    FaturaNotaFiscalService.enviarNotaFiscal(faturaNotaFiscal.idFaturaNotaFiscal, e => this.handleClickCapturarNotaFiscal(faturaNotaFiscal), e => this.handleClickCapturarNotaFiscal(faturaNotaFiscal));
                }
            }
        })
    }

    handleClickCapturarNotaFiscal(faturaNotaFiscal) {
        FaturaNotaFiscalService.capturarNotaFiscal(faturaNotaFiscal.idFaturaNotaFiscal, (response) => {
            this.getById();
        });
    }

    handleClickCriarNotaFiscal(cdTipo) {

        EventBus.dispatch("modal-add", {
            title: 'Dados da Pessoa',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idFatura: this.state.model.idFatura
            },
            form: FormFaturaPessoa,
            onClose: () => {

            },
            onSave: () => {
                FaturaNotaFiscalService.criarNotaFiscal(this.state.model.idFatura, cdTipo, (response) => {
                    FaturaNotaFiscalService.capturarNotaFiscal(response.idFaturaNotaFiscal, (response) => {
                        this.getById();
                    }, () => {
                        this.getById();
                    });
                });
            }
        });
    }

    handleClickAEnviaWhatsapp(item) {

        MensagemService.getMensagemByCobranca("WHATSAPP", item.idCobranca, (responseMensagem) => {
            this.setState(state => {
                EventBus.dispatch("modal-add", {
                    title: 'Envio de Whatsapp',
                    sizeModal: 'modal-lg',
                    config: {
                        isModal: true,
                        ddi: this.state.model.faturaPessoa?.ddi,
                        celular: this.state.model.faturaPessoa?.celular,
                        mensagem: responseMensagem.mensagem
                    },
                    form: FormWhatsapp,
                    onClose: () => {

                    },
                    onSave: () => {
                        this.getById();
                    }
                });
            });
        });
    }

    handleClickEstornarFatura() {
        EventBus.dispatch("modal-fatura-open", {
            id: this.state.model.idFatura,
            config: {
                title: "Estornar Fatura?",
                message: <>Deseja realmente estornar a fatura: <strong>{this.state.model.idFatura} </strong>?</>,
                btnConfirmText: "Sim, estornar a fatura",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: (item) => {
                    FaturaService.estornar(item.id, (response) => {
                        if (this.state.model.faturaOrdem.length === 1) {
                            var idOrdem = this.state.model.faturaOrdem[0].idOrdem
                            this.props.navigate('/CadOrdem/' + idOrdem);
                        } else {
                            this.props.navigate('/LstFatura');
                        }
                    });
                }
            }
        })
    }

    handleClickEditarRastreio(item) {
        this.setState(state => {
            EventBus.dispatch("modal-add", {
                title: 'Alteração de Dados de Rastreio',
                sizeModal: 'modal-lg',
                config: {
                    isModal: true,
                    idFaturaEmbalagem: item.idFaturaEmbalagem
                },
                form: FormEmbalagem,
                onClose: () => {

                },
                onSave: () => {
                    this.getById();
                }
            });
        });
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Voltar </BtnButton>

                <div className="btn-group dropup ms-auto">
                    <button type="button" className="btn btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Mais Ações
                    </button>
                    <ul className="dropdown-menu">
                        {Permissao.getInstance().getPermissao(53) ?
                            <li>
                                <button type="button" className="dropdown-item" to="#" onClick={this.handleImprimirFatura}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                    </svg> Imprimir Fatura
                                </button>
                            </li> : <></>
                        }
                        {Permissao.getInstance().getPermissao(81) && this.state.model.faturaNotaFiscal.filter(i => parseInt(i.flEstornada) === 0).length !== 0 ?
                            <li>
                                <button type="button" className="dropdown-item" to="#" onClick={this.handleImprimirEtiqueta}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg> Imprimir Etiqueta de Transporte
                                </button>
                            </li> : <></>
                        }

                        {Boolean(this.state.model.flProduto) && Permissao.getInstance().getPermissao(60) && this.state.model.faturaNotaFiscal.filter(i => parseInt(i.flEstornada) === 0 && i.cdTipo === "P").length === 0 ?
                            <li>
                                <button type="button" className="dropdown-item" to="#" onClick={(e) => { this.handleClickCriarNotaFiscal("P") }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bank2" viewBox="0 0 16 16">
                                        <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                                    </svg> Gerar Nota Fiscal de Produto
                                </button>
                            </li>
                            : <></>
                        }

                        {Boolean(this.state.model.flServico) && Permissao.getInstance().getPermissao(60) && this.state.model.faturaNotaFiscal.filter(i => parseInt(i.flEstornada) === 0 && i.cdTipo === "S").length === 0 ?
                            <li>
                                <button type="button" className="dropdown-item" to="#" onClick={(e) => { this.handleClickCriarNotaFiscal("S") }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bank2" viewBox="0 0 16 16">
                                        <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                                    </svg> Gerar Nota Fiscal de Serviço
                                </button>
                            </li>
                            : <></>
                        }
                        {Permissao.getInstance().getPermissao(29) ?
                            <li>
                                <button type="button" className={`dropdown-item ${this.state.model.baixa.length > 0 ? "disabled" : ""}`} onClick={this.handleClickEstornarFatura}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-x" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M6.146 6.146a.5.5 0 0 1 .708 0L8 7.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 8l1.147 1.146a.5.5 0 0 1-.708.708L8 8.707 6.854 9.854a.5.5 0 0 1-.708-.708L7.293 8 6.146 6.854a.5.5 0 0 1 0-.708z" />
                                        <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                                        <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                                    </svg> Estornar Fatura
                                </button>
                            </li>
                            : <></>
                        }
                    </ul>
                </div>


                {/*
                    Permissao.getInstance().getPermissao(51) ?
                        <BtnButton className="btn-outline-danger" onClick={this.handleImprimirFatura}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                            </svg> Imprimir
                        </BtnButton> : <></>*/
                }

                {
                    /*Permissao.getInstance().getPermissao(81) && this.state.model.faturaNotaFiscal.filter(i => parseInt(i.flEstornada) === 0).length !== 0 ?
                        <BtnButton className="btn-outline-primary" onClick={this.handleImprimirEtiqueta}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                                <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                            </svg> Imprimir Etiqueta de Transporte
                        </BtnButton> : <></>*/
                }

                {
                    /*Permissao.getInstance().getPermissao(60) && this.state.model.faturaNotaFiscal.filter(i => parseInt(i.flEstornada) === 0).length === 0 ?
                        <BtnButton className="btn-outline-success" onClick={(e) => { this.handleClickCriarNotaFiscal() }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bank2" viewBox="0 0 16 16">
                                <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                            </svg> Gerar Nota Fiscal
                        </BtnButton>
                        : <></>*/
                }

            </div >
        );
    }

    getStatus(item) {
        if (item.flPago) {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-success"> Pago </span>
        }

        if (item.flVencido) {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-danger"> Vencido </span>
        }

        return <span className="rounded-pill badge w-100 fs-7 text-bg-warning"> Aberto </span>
    }

    getStatusNF(dsSituacao) {
        if (dsSituacao === "Cancelada" || dsSituacao === "Estornada") {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-danger"> {dsSituacao} </span>
        } else if (dsSituacao === "Emitida DANFE") {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-primary"> {dsSituacao} </span>
        } else if (dsSituacao === "Autorizada") {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-success"> {dsSituacao} </span>
        } else if (dsSituacao === "Pendente") {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-warning"> {dsSituacao} </span>
        }

        return <span className="rounded-pill badge w-100 fs-7 text-bg-secondary">{dsSituacao}</span>
    }

    getRenderFields() {        

        var locationHash = this.props.location.hash;

        if(locationHash === ""){
            locationHash = "#nav-cobrancas";
        }

        return (
            <>
                <div className="row mb-3 g-2">
                    <InputText label="Código" md={2} value={this.state.model.idFatura} name="idFatura" readOnly />
                    <InputText label="Cliente" md={7} value={this.state.model?.faturaPessoa?.nome} readOnly opcionalLabel={false} />
                    <MostraValor label="Valor da Fatura" format="InputText" lg={3} className="text-center" readOnly opcionalLabel={false}>
                        <InputValorReais label="Valor da Fatura" value={parseFloat(this.state.model.vlFatura)} md={3} readOnly opcionalLabel={false} />
                    </MostraValor>
                </div>

                <div className="card mb-3">
                    <div className="card-header">
                        <ul className="nav nav-tabs card-header-tabs">
                            <li className="nav-item">                                
                                <Link to={'#nav-cobrancas'} className={`nav-link ${locationHash === "#nav-cobrancas" ? "active" : ""}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-coin" viewBox="0 0 16 16">
                                        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                                    </svg> Cobranças
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'#nav-pessoa'} className={`nav-link ${locationHash === "#nav-pessoa" ? "active" : ""}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                    </svg> Pessoa
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'#nav-ordens'} className={`nav-link ${locationHash === "#nav-ordens" ? "active" : ""}`} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg> Ordens
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'#nav-representantes'} className={`nav-link ${locationHash === "#nav-representantes" ? "active" : ""}`} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                                        <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z" />
                                        <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                                    </svg> Representantes
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'#nav-baixas'} className={`nav-link ${locationHash === "#nav-baixas" ? "active" : ""}`} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-exchange" viewBox="0 0 16 16">
                                        <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
                                    </svg> Baixas
                                </Link>
                            </li>
                            <li className="nav-item">                                
                                <Link to={'#nav-nota-fiscal'} className={`nav-link ${locationHash === "#nav-nota-fiscal" ? "active" : ""}`} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bank2" viewBox="0 0 16 16">
                                        <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                                    </svg> Nota Fiscal
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'#nav-transporte'} className={`nav-link ${locationHash === "#nav-transporte" ? "active" : ""} ${this.state.model.faturaTransporte.length > 0 ? '' : 'disabled'}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                                        <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg> Transporte
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="card-body">

                        <div className="tab-content mx-2 my-3" id="nav-tabContent">
                            <div className={`tab-pane fade ${locationHash === "#nav-cobrancas" ? "show active" : ""}`} id="nav-cobrancas" role="tabpanel" aria-labelledby="nav-cobrancas-tab">
                                <div className="table-responsive-lg">
                                    <table className="table table-sm table-striped table-bordered">
                                        <thead className="table-light">
                                            <tr>
                                                <th scope="col" className="text-center d-none d-md-table-cell">Cobrança</th>
                                                <th scope="col" className="text-center">Parcela</th>
                                                <th scope="col" className="text-center">Status</th>
                                                <th scope="col" className="text-center d-none d-md-table-cell">Opções de Pagamento</th>
                                                <th scope="col" className="text-center">Vencimento</th>
                                                <th scope="col" className="text-center d-none d-md-table-cell">Pagamento</th>
                                                <th scope="col" className="text-center d-none d-md-table-cell">Agendamento</th>
                                                <th scope="col" className="text-end">Cobrança</th>
                                                <th scope="col" className="text-end d-none d-md-table-cell">Pago</th>
                                                <th scope="col" className="text-end d-none d-md-table-cell">Desconto</th>
                                                <th scope="col" className="text-end">Aberto</th>
                                                <th scope="col" className="text-center">Acordo</th>
                                                <th scope="col" className="text-center">&nbsp;</th>
                                                <th scope="col" className="text-center">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.model.cobranca.map((cobranca, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="tr-borderless text-start">
                                                        <td className="align-middle text-center d-none d-md-table-cell">
                                                            {cobranca.idCobranca}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {cobranca.nrParcela}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {this.getStatus(cobranca)}
                                                        </td>
                                                        <td className="d-none d-md-table-cell  d-none d-md-table-cell">
                                                            {cobranca.cobrancaTipoBaixa.map((tipoBaixa, indexTipoBaixa) => (
                                                                <span key={indexTipoBaixa} className="badge text-bg-info mx-1 rounded-pill bg-opacity-50 text-dark fw-semibold">
                                                                    {tipoBaixa.nomeTipoBaixa}
                                                                </span>
                                                            ))}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {Util.date2Br(cobranca.dtVencimento)}
                                                        </td>
                                                        <td className="align-middle text-center d-none d-md-table-cell">
                                                            {Util.date2Br(cobranca.dtPagamento)}
                                                        </td>
                                                        <td className="align-middle text-center d-none d-md-table-cell">
                                                            {Util.date2Br(cobranca.dtAgendamento)}
                                                            <div className="fs-8">{cobranca.nomeUsuarioAgendamento}</div>
                                                        </td>
                                                        <td className="align-middle text-end text-end text-primary fw-bold">
                                                            <MostraValor>{Util.floatToReais(cobranca.vlCobranca)}</MostraValor>
                                                        </td>
                                                        <td className="align-middle text-end text-success fw-bold d-none d-md-table-cell">
                                                            <MostraValor>{Util.floatToReais(cobranca.vlBaixa)}</MostraValor>
                                                        </td>
                                                        <td className="align-middle text-end text-danger fw-bold d-none d-md-table-cell">
                                                            <MostraValor>{Util.floatToReais(cobranca.vlDesconto)}</MostraValor>
                                                        </td>
                                                        <td className={`align-middle text-end fw-bold ${(cobranca.vlAberto) > 0 ? "text-danger" : ""}`}>
                                                            <MostraValor>{Util.floatToReais(cobranca.vlAberto)}</MostraValor>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {cobranca.idAcordo} - {Util.date2Br(cobranca.dataInsertAcordo)}
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {parseInt(cobranca.flPago) === 0 && cobranca.flTemCobrancaTipoBaixa ?
                                                                <Link className="btn btn-success text-nowrap" to={'/pagamento/' + cobranca.uid} rel="noopener noreferrer" target="_blank">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-qr-code" viewBox="0 0 16 16">
                                                                        <path d="M2 2h2v2H2V2Z" />
                                                                        <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                                                                        <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                                                                        <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z" />
                                                                        <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
                                                                    </svg> Pagar
                                                                </Link>
                                                                : <></>
                                                            }
                                                        </td>
                                                        <td className="align-middl text-center">
                                                            {Permissao.getInstance().getPermissoes([87, 86, 32, 31, 61]) ?
                                                                <div className="dropdown dropstart">
                                                                    <button className="btn btn-outline-dark " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                        </svg>
                                                                    </button>
                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                                                        {Permissao.getInstance().getPermissao(87) ?
                                                                            <button type="button" className="dropdown-item" onClick={() => { this.handleClickHistoricoBaixa(cobranca) }} disabled={parseFloat(cobranca.vlBaixa) === 0}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
                                                                                    <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                                                                    <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                                                                    <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                                                                </svg> Histórico de Pagamentos
                                                                            </button> : <></>
                                                                        }

                                                                        {Permissao.getInstance().getPermissao(86) ?
                                                                            <button type="button" className="dropdown-item" onClick={() => { this.handleClickHistoricoTransacao(cobranca) }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                                                    <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                                                </svg> Histórico de Transações
                                                                            </button> : <></>
                                                                        }

                                                                        {Permissao.getInstance().getPermissao(32) ?
                                                                            <button type="button" className="dropdown-item" onClick={() => { this.handleClickBaixar(cobranca) }} disabled={!(parseFloat(cobranca.vlAberto) > 0)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-exchange" viewBox="0 0 16 16">
                                                                                    <path d="M0 5a5.002 5.002 0 0 0 4.027 4.905 6.46 6.46 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05c0-.046 0-.093.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.46 3.46 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98c-.003.046-.003.097-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5zm16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0zm-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787H8.25zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674l.077.018z" />
                                                                                </svg> Baixar Manualmente
                                                                            </button>
                                                                            : <></>
                                                                        }

                                                                        {Permissao.getInstance().getPermissao(31) ?
                                                                            <button type="button" disabled={parseFloat(cobranca.vlBaixa) > 0} className="dropdown-item" onClick={() => { this.handleClickAlterarVencimento(cobranca) }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar2-week" viewBox="0 0 16 16">
                                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
                                                                                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
                                                                                </svg> Alterar Vencimento
                                                                            </button>
                                                                            : <></>
                                                                        }

                                                                        {Permissao.getInstance().getPermissao(31) ?
                                                                            <button type="button" disabled={parseFloat(cobranca.vlBaixa) > 0} className="dropdown-item" onClick={() => { this.handleClickAlterarTipoBaixa(cobranca) }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-dollar" viewBox="0 0 16 16">
                                                                                    <path d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718H4zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73l.348.086z" />
                                                                                </svg> Alterar Forma de Pagamento
                                                                            </button>
                                                                            : <></>
                                                                        }

                                                                        {Permissao.getInstance().getPermissao(61) ?
                                                                            <button type="button" disabled={parseFloat(cobranca.vlBaixa) > 0} className="dropdown-item" onClick={() => { this.handleClickAEnviaWhatsapp(cobranca) }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                                                                                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                                                                </svg> Enviar Whatsapp
                                                                            </button>
                                                                            : <></>
                                                                        }

                                                                        {Permissao.getInstance().getPermissao(115) && !cobranca.dtAgendamento ?
                                                                            <button type="button" disabled={parseFloat(cobranca.vlAberto) === 0} className="dropdown-item" onClick={() => { this.handleClickRegistrarAgendamento(cobranca) }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-check" viewBox="0 0 16 16">
                                                                                    <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                                                                                </svg> Registrar Agendamento {cobranca.dtAgendamento?.toString().length}
                                                                            </button>
                                                                            : <></>
                                                                        }

                                                                        {Permissao.getInstance().getPermissao(115) && cobranca.dtAgendamento?.toString().length > 0 ?
                                                                            <button type="button" disabled={parseFloat(cobranca.vlAberto) === 0} className="dropdown-item" onClick={() => { this.handleClickCancelarAgendamento(cobranca) }}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar2-x" viewBox="0 0 16 16">
                                                                                    <path d="M6.146 8.146a.5.5 0 0 1 .708 0L8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 0 1 0-.708z" />
                                                                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z" />
                                                                                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z" />
                                                                                </svg> Cancelar Agendamento
                                                                            </button>
                                                                            : <></>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                : <></>
                                                            }
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${locationHash === "#nav-pessoa" ? "show active" : ""}`} id="nav-pessoa" role="tabpanel" aria-labelledby="nav-pessoa-tab">

                                <div className="fw-bold mb-3 border-dark border-bottom">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                    </svg> Dados do Cliente
                                </div>

                                <div className="row g-2">
                                    <InputText disabled label="Tipo" lg={2} value={this.state.tipoPessoa.find(i => i.value === this.state.model?.faturaPessoa?.cdTipoPessoa)?.label} opcionalLabel={false} />
                                    <InputText disabled label={this.state.model?.faturaPessoa?.cdTipoPessoa === "J" ? "Razão Social" : "Nome completo"} lg={4} value={this.state.model?.faturaPessoa?.nome} opcionalLabel={false} />
                                    {this.state.model?.faturaPessoa?.cdTipoPessoa === "F" ? <InputText disabled label="CPF" lg={3} name="cpf" value={this.state.model?.faturaPessoa?.cpf ? this.state.model?.faturaPessoa?.cpf : ""} opcionalLabel={false} /> : <></>}
                                    {this.state.model?.faturaPessoa?.cdTipoPessoa === "J" ?
                                        <>
                                            <InputText disabled label="CNPJ" lg={3} name="cnpj" value={this.state.model?.faturaPessoa?.cnpj ? this.state.model?.faturaPessoa?.cnpj : ""} opcionalLabel={false} />
                                            <InputText disabled label="Inscrição Estadual" lg={3} value={this.state.model?.faturaPessoa?.inscricaoEstadual} opcionalLabel={false} />
                                        </> : <></>}
                                </div>

                                <div className="row g-2 pt-2">
                                    <InputText disabled label="DDI" lg={2} value={this.state.model?.faturaPessoa?.ddi} opcionalLabel={false} />
                                    <InputText disabled label="Celular" lg={5} value={this.state.model?.faturaPessoa?.celular} opcionalLabel={false} />
                                    <InputText disabled label="E-mail" lg={5} value={this.state.model?.faturaPessoa?.email} opcionalLabel={false} />
                                </div>

                                <div className="fw-bold mb-3 border-dark border-bottom pt-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mailbox" viewBox="0 0 16 16">
                                        <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                                        <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
                                    </svg> Dados de Endereço
                                </div>

                                <div className="row mb-3 g-3">
                                    <InputText disabled label="CEP" lg={3} value={this.state.model?.faturaPessoa?.cep} opcionalLabel={false} />
                                    <InputText disabled label="Logradouro" lg={6} value={this.state.model?.faturaPessoa?.endereco} opcionalLabel={false} />
                                    <InputText disabled label="Número" lg={3} value={this.state.model?.faturaPessoa?.numero} opcionalLabel={false} />

                                    <InputText disabled label="Complemento" lg={6} value={this.state.model?.faturaPessoa?.complemento} opcionalLabel={false} />
                                    <InputText disabled label="Bairro" lg={6} value={this.state.model?.faturaPessoa?.bairro} opcionalLabel={false} />

                                    <InputText disabled lg={6} label="Estado" value={this.state.model?.faturaPessoa?.nomeEstado} opcionalLabel={false} />
                                    <InputText disabled lg={6} label="Cidade" value={this.state.model?.faturaPessoa?.nomeCidade} opcionalLabel={false} />
                                </div>
                            </div>
                            <div className={`tab-pane fade ${locationHash === "#nav-ordens" ? "show active" : ""}`} id="nav-ordens" role="tabpanel" aria-labelledby="nav-ordens-tab">
                                <div className="table-responsive">
                                    <table className="table table-sm table-striped">
                                        <thead className="table-light">
                                            <tr>
                                                <th> ID </th>
                                                <th className="col-md-4 d-none d-md-table-cell"> Tipo </th>
                                                <th className="col-md-3"> Representante </th>
                                                <th className="col-md-4 d-none d-md-table-cell"> Cliente / Veículo </th>
                                                <th className="col-md-1 d-none d-md-table-cell text-end"> Valor </th>
                                                <th className="text-end">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.model.faturaOrdem.map((item, index) => (
                                                <tr key={index} className="tr-borderless">
                                                    <td className="align-middle text-center">
                                                        <span className="rounded-pill badge w-100 fs-7 text-bg-secondary"> #{item.idOrdem} </span>
                                                    </td>
                                                    <td className="text-start align-middle d-none d-md-table-cell">
                                                        {Util.date2Br(item.dataOrdem)} {item.horaOrdem} | {Util.date2Br(item.dataUpdate)} {item.horaUpdate}<br />
                                                        {item.nomeTipoProdutoServico}
                                                    </td>
                                                    <td className="text-start align-middle">
                                                        <span className="fw-semibold">{item.nomeRepresentante}</span><br />
                                                        <span className="fst-italic">{item.nomeUsuario}</span>
                                                    </td>
                                                    <td className="text-start align-middle d-none d-md-table-cell">
                                                        <span className="text-uppercase fw-semibold">{item.ordemClienteNome}</span>
                                                        {item.flDadosVeiculo ? <><br />{item.ordemVeiculoMarcaNome} / {item.ordemVeiculoModelo} / {item.ordemVeiculoPlaca}</> : <></>}
                                                    </td>
                                                    <td className="align-middle d-none d-md-table-cell text-end fw-bold text-primary">
                                                        <MostraValor>{Util.floatToReais(item.vlOrdem)}</MostraValor>
                                                    </td>
                                                    <td className="align-middle">
                                                        <BtnButton className="btn-primary text-nowrap" onClick={(e) => { this.handleOpenModalFatura(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                            </svg> Ver Ordem
                                                        </BtnButton>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${locationHash === "#nav-representantes" ? "show active" : ""}`} id="nav-representantes" role="tabpanel" aria-labelledby="nav-representantes-tab">
                                <div className="table-responsive">
                                    <table className="table table-sm table-striped">
                                        <thead className="table-light">
                                            <tr>
                                                <th className="col-md-10 text-start"> Representante </th>
                                                <th className="col-md-2 d-none d-md-table-cell text-end"> Valor </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.model.faturaRepresentante.map((item, index) => (
                                                <tr key={index} className="tr-borderless">
                                                    <td className="text-start align-middle">
                                                        {item.nomeRepresentante}
                                                    </td>
                                                    <td className="align-middle d-none d-md-table-cell text-end fw-bold text-primary">
                                                        <MostraValor>{Util.floatToReais(item.vlOrdem)}</MostraValor>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${locationHash === "#nav-baixas" ? "show active" : ""}`} id="nav-baixas" role="tabpanel" aria-labelledby="nav-baixas-tab">
                                {this.state.model.baixa.length > 0 ?
                                    <div className="table-responsive">
                                        <table className="table table-sm table-striped">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="text-center"> Código </th>
                                                    <th className="col-1 text-center"> Cobrança </th>
                                                    <th className="col-2 text-center"> Tipo Baixa </th>
                                                    <th className="col-2 text-center"> Data Baixa </th>
                                                    <th className="col-2 text-center"> Data Pagamento </th>
                                                    <th className="col-4"> Usuário </th>
                                                    <th className="col-1 text-end"> Baixa </th>
                                                    <th className="col-1 text-end"> Desconto </th>
                                                    <th className="text-end">&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.model.baixa.map((item, index) => (
                                                    <tr key={index} className="tr-borderless">
                                                        <td className="align-middle text-center"> #{item.idBaixa} </td>
                                                        <td className="align-middle text-center"> {item.idCobranca} </td>
                                                        <td className="align-middle text-center"> {item.nomeTipoBaixa} </td>
                                                        <td className="align-middle text-center"> {Util.date2Br(item.dataBaixa)} </td>
                                                        <td className="align-middle text-center"> {Util.date2Br(item.dtPagamento)} </td>
                                                        <td className="align-middle"> {item.nomeUsuario} </td>
                                                        <td className="align-middle text-end fw-bold text-success">
                                                            <MostraValor>{Util.floatToReais(item.vlBaixa)}</MostraValor>
                                                        </td>
                                                        <td className="align-middle text-end fw-bold text-danger">
                                                            <MostraValor>{Util.floatToReais(item.vlDesconto)}</MostraValor>
                                                        </td>
                                                        <td>
                                                            {Permissao.getInstance().getPermissao(34) ?
                                                                <BtnButton className="btn-outline-danger text-nowrap" onClick={(e) => { this.handleEstornarBaixa(item) }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eraser-fill" viewBox="0 0 16 16">
                                                                        <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
                                                                    </svg> Estornar
                                                                </BtnButton>
                                                                : <></>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="alert alert-warning" role="alert">
                                        Não há <strong>baixas</strong> registradas para essa fatura!
                                    </div>
                                }
                            </div>
                            <div className={`tab-pane fade ${locationHash === "#nav-nota-fiscal" ? "show active" : ""}`} id="nav-nota-fiscal" role="tabpanel" aria-labelledby="nav-nota-fiscal-tab">
                                {this.state.model.faturaNotaFiscal.length > 0 ?
                                    <div className="table-responsive-lg">
                                        <table className="table table-sm table-striped">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="text-center"> Código </th>
                                                    <th className="text-center"> Tipo </th>
                                                    <th className="col-2 text-center"> Número </th>
                                                    <th className="col-2 text-center"> Série </th>
                                                    <th className="col text-center"> Enviada? </th>
                                                    <th className="col text-center"> Estornada? </th>
                                                    <th className="col-2 text-center"> Data Geração </th>
                                                    <th className="col-2 text-center"> Data Envio </th>
                                                    <th className="col-2 text-center"> Data Estorno </th>
                                                    <th className="col-2 text-center"> Situação </th>
                                                    <th className="text-end">&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.model.faturaNotaFiscal.map((item, index) => (
                                                    <tr key={index} className="tr-borderless">
                                                        <td className="align-middle text-center"> {item.idFaturaNotaFiscal} </td>
                                                        <td className="align-middle text-center">                                                             
                                                                {item.cdTipo === "P" ?
                                                                    <span className="rounded-pill badge w-100 fs-8 text-bg-warning">PRODUTO</span> :
                                                                    <span className="rounded-pill badge w-100 fs-8 text-bg-primary">SERVIÇO</span>
                                                                }                                                            
                                                         </td>
                                                        <td className="align-middle text-center"> {item.nrNumero} </td>
                                                        <td className="align-middle text-center"> {item.nrSerie} </td>

                                                        <td className="align-middle text-center">
                                                            {parseInt(item.flEnviada) === 1 ?
                                                                <span className="badge text-bg-success"> SIM </span> :
                                                                <span className="badge text-bg-danger"> NÃO </span>
                                                            }
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            {parseInt(item.flEstornada) === 1 ?
                                                                <span className="badge text-bg-danger"> SIM </span> :
                                                                <span className="badge text-bg-success"> NÃO </span>
                                                            }
                                                        </td>
                                                        <td className="align-middle text-center"> {Util.date2Br(item.dataGeracao)} {item.horaGeracao} </td>
                                                        <td className="align-middle text-center"> {Util.date2Br(item.dataEnvio)} {item.horaEnvio} </td>
                                                        <td className="align-middle text-center"> {Util.date2Br(item.dataEstorno)} {item.horaEstorno} </td>
                                                        <td className="align-middle text-center"> <strong>{this.getStatusNF(item.dsSituacao)}</strong></td>

                                                        <td className="align-middle text-center d-flex gap-2">

                                                            <a href={item.urlPdf} target="_blank" className={`btn btn-outline-danger ${item.urlPdf ? "" : "disabled"}`} rel="noopener noreferrer">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                                                    <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                                                </svg>
                                                            </a>

                                                            <div className="dropdown dropstart">
                                                                <button className="btn btn-outline-dark " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                    </svg>
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <button type="button" className="dropdown-item" onClick={() => { this.handleClickCapturarNotaFiscal(item) }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-down" viewBox="0 0 16 16">
                                                                            <path fillRule="evenodd" d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1h-2z" />
                                                                            <path fillRule="evenodd" d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                                                                        </svg> Capturar
                                                                    </button>

                                                                    {Permissao.getInstance().getPermissao(60) ?
                                                                        <button type="button" className={`dropdown-item ${parseInt(item.flEnviada) === 0 && parseInt(item.flEstornada) === 0 ? "" : "disabled"}`} onClick={() => { this.handleClickEnviarNotaFiscal(item) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
                                                                                <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                                                                            </svg> Enviar para {item.cdTipo === "P" ? "Receita" : "Prefeitura"}
                                                                        </button>
                                                                        : <></>
                                                                    }

                                                                    {Permissao.getInstance().getPermissao(77) ?
                                                                        <button type="button" className={`dropdown-item ${parseInt(item.flEnviada) === 0 && parseInt(item.flEstornada) === 0 ? "text-danger" : "disabled"}`} onClick={() => { this.handleClickEstornarNotaFiscal(item) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eraser-fill" viewBox="0 0 16 16">
                                                                                <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
                                                                            </svg> Estornar Nota Fiscal
                                                                        </button>
                                                                        : <></>
                                                                    }

                                                                    <a href={item.urlNfse} rel="noopener noreferrer" target="_blank" className={`dropdown-item ${item.urlNfse ? "" : "disabled"}`}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                                                        </svg> Visualizar NFSe
                                                                    </a>
                                                                    <a href={item.urlPdf} rel="noopener noreferrer" target="_blank" className={`dropdown-item ${item.urlPdf ? "" : "disabled"}`}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                                                        </svg> PDF da Nota Fiscal
                                                                    </a>
                                                                    <a href={item.urlDanfe} rel="noopener noreferrer" target="_blank" className={`dropdown-item ${item.urlDanfe ? "" : "disabled"}`}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-medical" viewBox="0 0 16 16">
                                                                            <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z" />
                                                                            <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                                                        </svg> Visualizar Danfe
                                                                    </a>
                                                                    <a href={item.urlXml} rel="noopener noreferrer" target="_blank" className={`dropdown-item ${item.urlXml ? "" : "disabled"}`}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-xml" viewBox="0 0 16 16">
                                                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z" />
                                                                        </svg> Visualizar XML
                                                                    </a>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="alert alert-warning" role="alert">
                                        Não há <strong>nota fiscal</strong> registrada para essa fatura!
                                    </div>
                                }
                            </div>

                            <div className={`tab-pane fade ${locationHash === "#nav-transporte" ? "show active" : ""}`} id="nav-transporte" role="tabpanel" aria-labelledby="nav-transporte-tab">

                                <h6 className="text-start border-bottom border-primary text-primary"> Transportadora  </h6>

                                <div className="table-responsive">
                                    <table className="table table-sm table-striped">
                                        <thead className="table-light">
                                            <tr>
                                                <th className="col-md-5 text-start"> Transportadora </th>
                                                <th className="col-md-4 text-start"> Modalidade </th>
                                                <th className="col-md-1 d-none d-md-table-cell text-end"> Valor </th>
                                                <th className="col-md-1 d-none d-md-table-cell text-end"> Peso Bruto </th>
                                                <th className="col-md-1 d-none d-md-table-cell text-end"> Peso Líquido </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.model.faturaTransporte.map((item, index) => (
                                                <tr key={index} className="tr-borderless">
                                                    <td className="text-start align-middle">
                                                        {item.nomeFornecedor}
                                                    </td>
                                                    <td className="text-start align-middle">
                                                        {item.nomeModalidadeFrete}
                                                    </td>
                                                    <td className="align-middle d-none d-md-table-cell text-end fw-bold text-primary">
                                                        <MostraValor>{Util.floatToReais(item.vlTransporte)}</MostraValor>
                                                    </td>
                                                    <td className="align-middle d-none d-md-table-cell text-end fw-bold text-primary">
                                                        {item.vlPesoBruto > 0 ? Util.floatToPeso(item.vlPesoBruto) + ' KG' : ''}
                                                    </td>
                                                    <td className="align-middle d-none d-md-table-cell text-end fw-bold text-primary">
                                                        {item.vlPesoLiquido > 0 ? Util.floatToPeso(item.vlPesoLiquido) + ' KG' : ''}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <h6 className="text-start border-bottom border-primary text-primary pt-3"> Embalagens  </h6>

                                <div className="table-responsive">
                                    <table className="table table-sm table-striped">
                                        <thead className="table-light">
                                            <tr>
                                                <th className="col-md-3 text-start"> Embalagem </th>
                                                <th className="col-md-1 text-center"> Quantidade </th>
                                                <th className="col-md-2 text-start"> Fornecedor </th>
                                                <th className="col-md-2 text-start"> Serviço </th>
                                                <th className="col-md-2 text-end"> Valor </th>
                                                <th className="col-md-2 text-end"> Rastreio </th>
                                                <th className="col-md-2 text-end"> &nbsp; </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.model.faturaEmbalagem.map((item, index) => (
                                                <tr key={index} className="tr-borderless">
                                                    <td className="text-start">
                                                        {item.nomeProdutoServico}
                                                    </td>
                                                    <td className="text-center">
                                                        {Util.getValueDecimal(item.nrQuantidade, item.nrCasasDecimais)}
                                                    </td>
                                                    <td className="text-start">
                                                        {item.nomeFornecedor}
                                                    </td>
                                                    <td className="text-start">
                                                        {item.nomeFornecedorServico}
                                                    </td>
                                                    <td className="text-end fw-bold text-primary">
                                                        <MostraValor>{Util.floatToReais(item.vlTransporte)}</MostraValor>
                                                    </td>
                                                    <td className="text-end fw-bold text-primary">
                                                        <a rel="noopener noreferrer" target="_blank" href={`${item.urlRastreamento}`} className="">{item.cdRastreamento}</a>
                                                    </td>
                                                    <td>
                                                        {Permissao.getInstance().getPermissoes([85]) ?
                                                            <div className="dropdown dropstart">
                                                                <button className="btn btn-outline-dark " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                    </svg>
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    {Permissao.getInstance().getPermissao(85) ?
                                                                        <button type="button" className="dropdown-item" onClick={() => { this.handleClickEditarRastreio(item) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                                                                                <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                                            </svg> Editar dados de Rastreio
                                                                        </button>
                                                                        : <></>
                                                                    }
                                                                </ul>
                                                            </div>
                                                            : <></>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <ModalConfirmacao id="modal-fatura" />
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <FormFatura {...props} navigate={navigate} params={params} location={location}/>
}

export default With