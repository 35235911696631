import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import BtnButton from "../../components/forms/elements/BtnButton";


class FormEnderecoSelecao extends CrudForm {

    constructor(props) {
        super(props);
        this.handleSelectEndereco = this.handleSelectEndereco.bind(this);
        this.state.model = {
            enderecos : props?.enderecos || []
        };        
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleSelectEndereco(item){
        this.onSave(item);
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">              
                {this.state.showBtnCancelar ? <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton> : <></>}
            </div>);
    }

    getRenderFields() {
        return (
                <div className="table-responsive">
                    <table className="table table-sm caption-top">
                        <thead className="table-light">
                            <tr>
                                <th className="col-1"> Tipo </th>
                                <th className="col-3"> Cidade </th>
                                <th className="col-3"> Endereço </th>
                                <th className="col-2"> Bairro </th>
                                <th className="col-1"> CEP </th>
                                <th className="col-2"> Complemento </th>
                                <th className="text-end">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.model.enderecos.map((item, index) => (
                                <tr key={index} className="tr-borderless">
                                    <td>{item.nomeTipoEndereco}</td>
                                    <td>{item.nomeCidade}, {item.nomeEstado}, {item.nomePais}</td>
                                    <td>{item.endereco}, {item.numero}</td>
                                    <td>{item.bairro}</td>
                                    <td>{item.cep}</td>
                                    <td>{item.complemento}</td>
                                    <td className="text-end d-flex flex-nowrap gap-2">
                                        <BtnButton type="button" className="btn-sm btn-outline-primary text-nowrap" onClick={() => { this.handleSelectEndereco(item) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-circle" viewBox="0 0 16 16">
                                                <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
                                                <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
                                            </svg> Selecionar
                                        </BtnButton>                                        
                                    </td>

                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormEnderecoSelecao {...props} navigate={navigate} params={params} />
}

export default With