import Http from "../controllers/Http";

export default class RepresentanteDocumentoService {

    static urlBase = "/representanteDocumento/";

    static listAllForRepresentante(idRepresentante, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byRepresentante/' + idRepresentante)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static update(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + id, data)
            .then(fnSucess)
            .catch(fnError);
    }
}
