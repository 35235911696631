import Http from "../controllers/Http";

export default class FaturaNotaFiscalService {

    static urlBase = "/faturaNotaFiscal/";

    static criarNotaFiscal(idFatura, cdTipo, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "criar/" + cdTipo +  "/"  + idFatura)
            .then(fnSucess)
            .catch(fnError);
    }

    static enviarNotaFiscal(idFaturaNotaFiscal, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "enviar/" + idFaturaNotaFiscal)
            .then(fnSucess)
            .catch(fnError);
    }

    static capturarNotaFiscal(idFaturaNotaFiscal, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "capturar/" + idFaturaNotaFiscal)
            .then(fnSucess)
            .catch(fnError);
    }

    static estornarNotaFiscal(idFaturaNotaFiscal, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "estornar/" + idFaturaNotaFiscal)
            .then(fnSucess)
            .catch(fnError);
    }
}
