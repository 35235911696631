import React from "react";
import ModuloService from "../../services/ModuloService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaModulo extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ModuloService,
            labels: {
                title: 'Lista de Modulo(s)',
                btnNewRegister: 'Modulo',
            },
            keyItem: 'idModulo',
            urlForm: '/CadModulo',
            itensFilter: ["idModulo", "nome", "nomeGrupoModulo"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-6"> Nome </th>
                <th className="col-md-4"> Grupo </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idModulo}</td>
                <td>{item.nome}</td>
                <td>{item.nomeGrupoModulo}</td>
            </>);
    }

    render() {
        return super.render();
    }
}