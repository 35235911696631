import React from "react";
import ReactJson from 'react-json-view'
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import BaixaService from "../../services/BaixaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import CobrancaService from "../../services/CobrancaService";
import GridItem from "../../components/forms/elements/GridItem";
import TransacaoService from "../../services/TransacaoService";
import MostraValor from "../../components/app/MostraValor";
import BtnButton from "../../components/forms/elements/BtnButton";
import Permissao from "../../controllers/Permissao";

class FormHistoricoTransacao extends CrudForm {

    constructor(props) {
        super(props);

        this.getById = this.getById.bind(this);
        this.handleClickExpirar = this.handleClickExpirar.bind(this);

        this.state.cobranca = {
            idCobranca: "",
            vlCobranca: 0,
            vlPago: 0,
            vlAberto: 0,
            vlBaixa: 0,
            vlDesconto: 0,
            idTipoBaixa: "",
            dtPagamento: "",
            dsObservacao: "",
            cobranca: {
                nomeProprietario: "",
                dtVencimento: ""
            }
        };

        this.state.transacao = [];

        this.state.showBtnSalvar = false;
        this.state.showBtnCancelar = false;

        this.state.crud = {
            service: BaixaService,
            labels: {
                title: 'Baixa',
            },
            urlList: '/LstBaixa',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    getById() {
        if (this.props.idCobranca) {
            CobrancaService.getOneById(this.props.idCobranca, (data) => {
                var cobranca = {};
                cobranca.idCobranca = data.idCobranca;
                cobranca.cobranca = data;
                cobranca.vlCobranca = data.vlCobranca;
                cobranca.vlPago = data.vlPago;
                cobranca.vlAberto = data.vlAberto;
                this.setState({ cobranca: cobranca });
            });

            TransacaoService.getAllByCobranca(this.props.idCobranca, data => {
                this.setState({ transacao: data });
            });
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickExpirar(item) {
        TransacaoService.expirar(item.idTransacao, data => {
            this.getById();
        });
    }


    getRenderFields() {
        return (
            <>
                <h6 className="border-bottom border-secondary text-secondary"> Dados da Cobrança  </h6>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={1} value={this.state.cobranca.idCobranca} name="idCobranca" disabled={true} required={true} />
                    <MostraValor format="InputText" label="Valor da Cobrança" md={3} disabled={true} opcionalLabel={false}>
                        <InputText className="text-end text-primary fw-bold" label="Valor da Cobrança" md={3} value={Util.floatToReais(this.state.model.vlCobranca)} disabled={true} opcionalLabel={false} />
                    </MostraValor>

                    <MostraValor format="InputText" label="Valor Pago" md={3} disabled={true} opcionalLabel={false}>
                        <InputText className="text-end text-success fw-bold" label="Valor Pago" md={3} value={Util.floatToReais(this.state.model.vlPago)} disabled={true} opcionalLabel={false} />
                    </MostraValor>

                    <MostraValor format="InputText" label="Valor à Pagar" md={3} disabled={true} opcionalLabel={false}>
                        <InputText className="text-end text-danger fw-bold" label="Valor à Pagar" md={3} value={Util.floatToReais(this.state.model.vlAberto)} disabled={true} opcionalLabel={false} />
                    </MostraValor>
                    <InputText label="Data de Vencimento" md={2} value={Util.date2Br(this.state.cobranca.cobranca.dtVencimento)} disabled={true} required={true} />
                </div>

                <h6 className="border-bottom border-secondary text-secondary"> Histórico das Baixas  </h6>

                <div className="mb-3">
                    <ol className="list-group list-group-numbered">
                        {this.state.transacao.map((item, index) => (
                            <li className={`list-group-item d-flex gap-3 justify-content-between align-items-start ${index % 2 === 0 ? "bg-light " : ""}`} key={index}>
                                <div className="ms-2 w-100">
                                    <div className="row d-flex g-2">
                                        <GridItem sizing="sm" label="Código" md={1} value={item.idTransacao} />
                                        <GridItem sizing="sm" label="Data da Transação" md={2} value={Util.date2Br(item.dataTransacao) + " " + (item.horaTransacao)} />
                                        <GridItem className="text-primary fw-bold" sizing="sm" label="Canal de Pagamento" md={4} value={item.nomeConta} />
                                        <GridItem sizing="sm" label="Tipo da Baixa" md={3} value={item.nomeTipoBaixa} />
                                        <GridItem sizing="sm" label="Efetivada?" className={`text-center fw-bold ${Boolean(item.flEfetivada) ? 'text-success' : 'text-danger'}`} md={1} value={Boolean(item.flEfetivada) ? "SIM" : "NÃO"} />
                                        <GridItem sizing="sm" label="Expirada?" className={`text-center fw-bold ${Boolean(item.flExpirada) ? 'text-danger' : 'text-success'}`} md={1} value={Boolean(item.flExpirada) ? "SIM" : "NÃO"} />

                                        <MostraValor format="GridItem" label="Valor Aberto" md={2} sizing="sm" disabled={true} opcionalLabel={false}>
                                            <GridItem className="text-success fw-bold" sizing="sm" label="Valor Aberto" md={2} value={Util.floatToReais(item.vlAberto)} />
                                        </MostraValor>

                                        <MostraValor format="GridItem" label="Valor Multa" md={2} sizing="sm" disabled={true} opcionalLabel={false}>
                                            <GridItem className="text-success fw-bold" sizing="sm" label="Valor Multa" md={2} value={Util.floatToReais(item.vlMulta)} />
                                        </MostraValor>

                                        <MostraValor format="GridItem" label="Valor Juros" md={2} sizing="sm" disabled={true} opcionalLabel={false}>
                                            <GridItem className="text-success fw-bold" sizing="sm" label="Valor Juros" md={2} value={Util.floatToReais(item.vlJuros)} />
                                        </MostraValor>

                                        <MostraValor format="GridItem" label="Valor Adicional" md={2} sizing="sm" disabled={true} opcionalLabel={false}>
                                            <GridItem className="text-success fw-bold" sizing="sm" label="Valor Adicional" md={2} value={Util.floatToReais(item.vlAdicional)} />
                                        </MostraValor>

                                        <MostraValor format="GridItem" label="Valor da Transação" md={2} sizing="sm" disabled={true} opcionalLabel={false}>
                                            <GridItem className="text-success fw-bold" sizing="sm" label="Valor da Transação" md={2} value={Util.floatToReais(item.vlTransacao)} />
                                        </MostraValor>

                                        <MostraValor format="GridItem" label="Valor Aberto" md={2} sizing="sm" disabled={true} opcionalLabel={false}>
                                            <GridItem sizing="sm" label="Data Retorno" md={2} value={item.dataRetorno ? Util.date2Br(item.dataRetorno) + " " + (item.horaRetorno) : ""} />
                                        </MostraValor>

                                        {item.dsRetorno ?
                                            <div className="col-md-12">
                                                <ReactJson theme="monokai" src={JSON.parse(item.dsRetorno)} collapsed={true} />
                                            </div>
                                            : <></>}

                                        {Permissao.getInstance().getPermissao(93) && !Boolean(item.flEfetivada) && !Boolean(item.flExpirada) ?
                                            <div className="d-grid gap-2 d-md-flex">
                                                <BtnButton className="btn-outline-danger btn-sm" onClick={e => { this.handleClickExpirar(item) }}> Expirar Transação </BtnButton>
                                            </div>
                                            : <></>}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormHistoricoTransacao {...props} navigate={navigate} params={params} />
}

export default With