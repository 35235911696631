import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/signin.css';
import './assets/css/app.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './assets/css/sidebars.css';

ReactDOM.render(<App />, document.getElementById('root'));