import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputPassword from "../../components/forms/elements/InputPassword";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import LoginService from "../../services/LoginService";
import Auth from "../../controllers/Auth";
import InputText from "../../components/forms/elements/InputText";

class FormSenhaConfirmacao extends CrudForm {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state.model = {
            usuario: Auth.getUsuarioDataToken(),
            senha: ""
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event) {
        LoginService.login(this.state.model, (response) => {
            this.onSave();
        });
    }

    getBtns() {
        return (
            <div className="d-grid gap-2">
                <BtnSubmit className="btn-primary btn-block"> Confirmar Senha </BtnSubmit>
            </div>);
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Usuário" layout="floating" md={12} value={this.state.model.usuario} disabled={true} opcionalLabel={false} />
                    <InputPassword label="Digite sua senha" layout="floating" autoFocus={true} required md={12} value={this.state.model.senha} name="senha" onChange={this.handleChange} maxLength={100} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormSenhaConfirmacao {...props} navigate={navigate} params={params} />
}

export default With