import Permissao from "../../controllers/Permissao";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import Util from "../../utils/Util";
import MyComponent from "../custom/MyComponent";
import MostraValor from "./MostraValor";

export default class ListGroupProdutoServicoByTipoUsuarioOrdemTipo extends MyComponent {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            options : [],
            busca : ""
        };
    }

    componentDidUpdate(nextProps) {        
        if (nextProps.idTipoProdutoServico !== this.props.idTipoProdutoServico) {
            this.getOptions();
        }
    }

    componentDidMount() {
        super.componentDidMount()
    }

    componentWillUnmount() {
        super.componentWillUnmount()
    }

    getOptions(callBack) {
        if (this.props.idTipoProdutoServico) {
            ProdutoServicoService.getForComboByTipoUsuarioOrdemTipo(this.props.idTipoProdutoServico, this.props.idOrdemTipo, (response) => {
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();                        
                    }
                });
            });
        } else {
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    isDisabled(item) {
        return parseInt(item.flAtivo) === 0 || (parseInt(item.flControlaEstoque) === 1 && parseInt(item.flEstoqueNegativo) === 0 && parseFloat(item.vlEstoque) <= 0) ? true : false;
    }

    onClickItem(e, item){
        if(Boolean(this.props?.disabled)){
            return;
        }
        item.idProdutoServico = item.value;
        this.props?.onClickItem(item);
    }

    getData() {
        return this.state.options.filter(f =>
            Util.searchTextInObject(f, ["value", "label"], this.state.busca)
        );
    }

    render() {
        return (
            <div className="h-100">
                <div className="col-md-12 input-group mb-3">
                    <span className="input-group-text" id="addon-wrapping">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </span>
                    <input disabled={Boolean(this.props?.disabled)} type="text" className="form-control" autoComplete="off" name="busca" placeholder="Digite sua busca" value={this.state.busca} onChange={this.handleChange}/>
                </div>
                <ul className="list-group overflow-auto" style={{maxHeight : "450px"}}>
                    {this.getData().map((item, index) => {

                        let isDisabled = this.isDisabled(item);

                        return (
                            <li key={index} className={`list-group-item ${isDisabled ? "bg-danger-subtle" : "list-group-item-action pointer"}`} aria-current="true" onClick={e => {if(!isDisabled) {this.onClickItem(e, item)}}}>
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1 fs-6">{item.label}</h5>
                                    <small className="fw-bold text-success">
                                        {<MostraValor>{Permissao.getInstance().getPermissao(22) ? Util.floatToReais(item.vlPreco) : "OCULTO"}</MostraValor>}
                                    </small>
                                </div>
                                {/*<p className="mb-1">Código: {item.value}</p>*/}
                                {parseInt(item.flControlaEstoque) === 1 ? <small>Estoque: {parseFloat(item.vlEstoque)}</small> : <></>}
                            </li>);
                    })}
                </ul>
            </div>
        );
    }
}