import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import EventBus from "../../controllers/EventBus";
import Permissao from "../../controllers/Permissao";
import FormRelatorioContato001 from "./FormRelatorioContato001";
import FormRelatorioContato002 from "./FormRelatorioContato002";
import FormRelatorioOrdem002 from "./FormRelatorioOrdem002";
import FormRelatorioProduto001 from "./FormRelatorioProduto001";
import FormRelatorioProduto002 from "./FormRelatorioProduto002";
import FormRelatorioProprietario001 from "./FormRelatorioProprietario001";
import FormRelatorioProprietario002 from "./FormRelatorioProprietario002";
import FormRelatorioProprietario003 from "./FormRelatorioProprietario003";
import FormRelatorioFinanceiro001 from "./FormRelatorioFinanceiro001";
import FormRelatorioFinanceiro002 from "./FormRelatorioFinanceiro002";
import FormRelatorioFinanceiro003 from "./FormRelatorioFinanceiro003";
import FormRelatorioProduto003 from "./FormRelatorioProduto003";
export default class HomeRelatorio extends MyComponent {

    constructor(props) {
        super(props);

        this.state = {
            relatorios: [
                {
                    label: 'Financeiro',
                    itens: [
                        {
                            label: 'Relatório de contas a receber',
                            descricao: 'Relatório com a lista de contas a receber',
                            permissao: 97,
                            sizeModal : "modal-lg",
                            form: FormRelatorioFinanceiro001,
                            types: ["pdf", "xls"]
                        },
                        {
                            label: 'Relatório de contas a pagar',
                            descricao: 'Relatório com a lista de contas a pagar',
                            permissao: 99,
                            sizeModal : "modal-lg",
                            form: FormRelatorioFinanceiro002,
                            types: ["pdf", "xls"]
                        },
                        {
                            label: 'Relatório de contas recebidas',
                            descricao: 'Relatório com a lista movimentação de recebimentos',
                            permissao: 101,
                            sizeModal : "modal-lg",
                            form: FormRelatorioFinanceiro003,
                            types: ["pdf", "xls"]
                        }
                    ]
                },
                {
                    label: 'Ordem',
                    itens: [
                        {
                            label: 'Relatório de ordens registradas',
                            descricao: 'Relatório de ordens de serviços e produtos',
                            permissao: 72,
                            sizeModal : "modal-lg",
                            form: FormRelatorioOrdem002,
                            types: ["pdf", "xls"]
                        }
                    ]
                },
                {
                    label: 'Produto',
                    itens: [
                        {
                            label: 'Relatório de produtos cadastrados, preços e estoque',
                            descricao: 'Relatório com a lista de produtos cadatrados, preços e estoque',
                            permissao: 46,
                            sizeModal : "modal-lg",
                            form: FormRelatorioProduto001,
                            types: ["pdf", "xls"]
                        },
                        {
                            label: 'Relatório de preços de produto por tipo de ordem',
                            descricao: 'Relatório com a lista de produtos cadatrados e preços por tipo de ordem',
                            permissao: 69,
                            sizeModal : "modal-lg",
                            form: FormRelatorioProduto002,
                            types: ["pdf", "xls"]
                        },
                        {
                            label: 'Relatório de produtos para compra',
                            descricao: 'Relatório com a lista de produtos abaixo do estoque mínimo',
                            permissao: 107,
                            sizeModal : "modal-lg",
                            form: FormRelatorioProduto003,
                            types: ["pdf", "xls"]
                        }
                    ]
                },
                {
                    label: 'Proprietário',
                    itens: [
                        {
                            label: 'Relatório de Proprietários',
                            descricao: 'Relatório de Proprietários, Representantes e Usuários vínculados',
                            permissao: 58,
                            sizeModal : "modal-lg",
                            form: FormRelatorioProprietario001,
                            types: ["pdf", "xls"]
                        },
                        {
                            label: 'Relatório de Plano de Pagamento por Proprietário',
                            descricao: 'Relatório de Proprietários e seus respectivos planos de pagamento',
                            permissao: 131,
                            sizeModal : "modal-lg",
                            form: FormRelatorioProprietario003,
                            types: ["pdf", "xls"]
                        },
                        {
                            label: 'Lista de Distribuição de E-mail',
                            descricao: 'Relatório com e-mails dos proprietários',
                            permissao: 63,
                            sizeModal : "modal-lg",
                            form: FormRelatorioProprietario002,
                            types: ["xls"]
                        }
                    ]
                },
                {
                    label: 'Contato',
                    itens: [
                        {
                            label: 'Relatório de contatos cadastrados',
                            descricao: 'Relatórios dos contatos registrados no site e contatos pelas redes sociais',
                            permissao: 47,
                            sizeModal : "modal-lg",
                            form: FormRelatorioContato001,
                            types: ["xls"]
                        },
                        {
                            label: 'Relatório de contatos x ordens de serviço / remap',
                            descricao: 'Relatórios dos contatos registrados no site e contatos pelas redes sociais e ordens de Remap',
                            permissao: 71,
                            sizeModal : "modal-lg",
                            form: FormRelatorioContato002,
                            types: ["pdf", "xls"]
                        }
                    ]
                }
            ]
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickRelatorio(title, form, sizeModal = 'modal-lg') {
        EventBus.dispatch("modal-add", {
            title: title,
            sizeModal: sizeModal,
            form: form
        });
    }

    render() {
        return (
            <div className="container">
                <div className="py-5">
                    <h5> Relatórios </h5><hr />
                    <div className="accordion" id="accordionFlushExample">
                        {this.state.relatorios.map((relatorio, indexRelatorio) => (
                            <React.Fragment key={indexRelatorio}>
                                {Permissao.getInstance().getPermissoes(relatorio.itens.map(i => i.permissao)) ?
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={`flush-heading-${indexRelatorio}`}>
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${indexRelatorio}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                                {relatorio.label}
                                            </button>
                                        </h2>
                                        <div id={`flush-collapse-${indexRelatorio}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading-${indexRelatorio}`}>
                                            <div className="accordion-body">
                                                <ol className="list-group list-group-numbered">
                                                    {relatorio.itens.map((item, indexItem) => (
                                                        <React.Fragment key={indexItem}>
                                                            {Permissao.getInstance().getPermissao(item.permissao) ?
                                                                <li className="list-group-item list-group-item-action d-flex justify-content-between align-items-start" onClick={e => this.handleClickRelatorio(item.label, item.form, item.sizeModal)}>
                                                                    <div className="ms-2 me-auto" role="button">
                                                                        <div className="fw-bold">{item.label}</div>
                                                                        <i>{item.descricao}</i>
                                                                    </div>

                                                                    {item.types.map((type, indexType) => (
                                                                        <React.Fragment key={indexType}>
                                                                            {type === "pdf" ?
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-filetype-pdf text-danger" viewBox="0 0 16 16">
                                                                                        <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                                                                    </svg>
                                                                                </span>
                                                                                : <></>
                                                                            }
                                                                            {type === "xls" ?
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-filetype-xls text-success" viewBox="0 0 16 16">
                                                                                        <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z" />
                                                                                    </svg>
                                                                                </span>
                                                                                : <></>
                                                                            }
                                                                        </React.Fragment>
                                                                    ))}


                                                                </li> : <></>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                                }
                            </React.Fragment>
                        ))}

                    </div>
                </div>
            </div>
        );
    }
}