import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import InputColor from "../../components/forms/elements/InputColor";
import ContatoStatusService from "../../services/ContatoStatusService.js";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import SelectContatoTipo from "../../components/app/SelectContatoTipo";

class FormContatoStatus extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idContatoStatus: "",
            idContatoTipo: "",
            nome: "",
            flAtivo: 1,
            flValido: 1,
            flFechouVenda: 0,
            cdBgColor: "#000000",
            cdTxColor: "#FFFFFF"
        };

        this.state.crud = {
            service: ContatoStatusService,
            labels: {
                title: 'Status',
            },
            urlList: '/LstContatoStatus',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={4} value={this.state.model.idContatoStatus} name="idContatoStatus" readOnly opcionalLabel={false} />
                    <SelectContatoTipo disabled={parseInt(this.state.model.idContatoStatus) > 0} label="Tipo do Contato" md={6} required={true} placeholder="Selecione o Tipo" name="idContatoTipo" value={this.state.model.idContatoTipo} onChange={this.handleChange} />
                    <InputText label="Nome do Status" autoFocus={true} required md={8} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <InputColor label="Fundo" autoFocus={true} required md={1} value={this.state.model.cdBgColor} name="cdBgColor" onChange={this.handleChange} maxLength={100} />
                    <InputColor label="Texto" autoFocus={true} required md={1} value={this.state.model.cdTxColor} name="cdTxColor" onChange={this.handleChange} maxLength={100} />
                </div>
                <div className={`row mx-1 mb-3 g-1`}>
                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Válido?" name="flValido" checked={Boolean(this.state.model.flValido)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Fechou Venda?" name="flFechouVenda" checked={Boolean(this.state.model.flFechouVenda)} onChange={this.handleChange} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormContatoStatus {...props} navigate={navigate} params={params} />
}

export default With