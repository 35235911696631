import React from "react";
import CategoriaBaixaService from "../../services/CategoriaBaixaService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaCategoriaBaixa extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: CategoriaBaixaService,
            labels: {
                title: 'Lista de Categoria Baixa(s)',
                btnNewRegister: 'Categoria Baixa',
            },
            keyItem: 'idCategoriaBaixa',
            urlForm: '/CadCategoriaBaixa',
            itensFilter: ["idCategoriaBaixa", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-10"> Nome </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idCategoriaBaixa}</td>
                <td>{item.nome}</td>
            </>);
    }

    render() {
        return super.render();
    }
}