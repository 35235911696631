
import axios from "axios";
import Auth from "./Auth";
import EventBus from "../controllers/EventBus";
import { toast } from "react-toastify";
import Config from "../Config";
import Version from "../Version";

export default class Http {

    static getInstanceAppJson(configRequest) {

        configRequest = configRequest !== undefined ? configRequest : {};
        configRequest.showLoadding = configRequest.showLoadding !== undefined ? configRequest.showLoadding : true;
        configRequest.messageLoadding = configRequest.messageLoadding !== undefined ? configRequest.messageLoadding : false;
        configRequest.timeout = configRequest.timeout !== undefined ? configRequest.timeout : 30;
        configRequest.apiUrlBase = configRequest.apiUrlBase !== undefined ? configRequest.apiUrlBase : Config.getUrlApi();

        var instance = axios.create({
            baseURL: configRequest.apiUrlBase,
            timeout: (configRequest.timeout * 1000),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-App-Version': Version.version
            },
            /*transformRequest: [function (data, headers) {
                return data;
            }],*/
            transformResponse: [function (data) {
                return JSON.parse(data);
            }],
            /*validateStatus: function (status) {
                if (status == 403) {
                    Auth.logout();
                }
                return status < 500;
            }*/
        });

        var authorization = Auth.getToken();
        instance.defaults.headers.common['authorization'] = authorization ? authorization : "";
        //instance.defaults.headers.common['X-App-Version'] = "1.22";

        instance.interceptors.request.use(function (config) {
            if (configRequest.showLoadding) {
                EventBus.dispatch("loading-add", { config: config, message: configRequest.messageLoadding });
            }
            EventBus.dispatch("request-success", config);
            return config;
        }, function (error) {            
            if (configRequest.showLoadding) {
                EventBus.dispatch("loading-add", { error: error, message: configRequest.messageLoadding });
            }
            EventBus.dispatch("request-error", error);

            if (error.response?.status) {
                EventBus.dispatch("request-error-" + error.response?.status, error);
            }
            return Promise.reject(error);
        });

        instance.interceptors.response.use(function (response) {
            if (configRequest.showLoadding) {
                EventBus.dispatch("loading-del", { response: response, message: configRequest.messageLoadding });
            }
            EventBus.dispatch("response-success", response);

            if (response.data?.message) {
                toast.success(response.data.message);
            }

            return response.data;
        }, function (error) {

            if (configRequest.showLoadding) {
                EventBus.dispatch("loading-del", { error: error, message: configRequest.messageLoadding });
            }

            EventBus.dispatch("response-error", error);

            if (error.response?.status === 408 || error?.code === 'ECONNABORTED') {
                EventBus.dispatch("response-error-timeout", {
                    timeout: configRequest.timeout
                });
            } else if (error.response?.status) {
                EventBus.dispatch("response-error-" + error.response?.status, error);
            } else {
                EventBus.dispatch("response-error-not-status");
            }

            if (error.response?.data?.message) {
                toast.error(error.response.data.message);
            }

            return Promise.reject(error);
        });

        return instance;
    }
}