import React from "react";
import ContratoStatusService from "../../services/ContratoStatusService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaContratoStatus extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ContratoStatusService,
            labels: {
                title: 'Lista de Status de Contrato',
                btnNewRegister: 'Status',
            },
            keyItem: 'idContratoStatus',
            urlForm: '/CadContratoStatus',
            itensFilter: ["idContratoStatus", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-10"> Nome </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idContratoStatus}</td>
                <td>{item.nome}</td>
            </>);
    }

    render() {
        return super.render();
    }
}