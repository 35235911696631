import RepresentanteService from "../../services/RepresentanteService";
import FormSelectSearchModal from "../forms/base/FormSelectSearchModal";
import FormRepresentante from "../../pages/Representante/FormRepresentante";
import Permissao from "../../controllers/Permissao";

export default class SelectRepresentante extends FormSelectSearchModal {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);

        this.state.showBtns = this.props.showBtns === undefined ? true : this.props.showBtns;
        this.state.showBtnSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
        this.state.showBtnNewForm = this.state.showBtns && ((this.props.showBtnNewForm === undefined ? true : false) && Permissao.getInstance().getPermissao(8));
        this.state.showBtnNewSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptions(() => {
            if (this.dispachChangeDidMount && this.props.value) {
                this.handleChange({ target: { value: this.props.value, name: this.props.name } });
            }
        });

        this.state.sizeModal = "modal-xl";
        this.state.headers = [
            { key: "value", label: "Código", className: 'text-center d-none d-md-table-cell' },
            { key: "label", label: "Representante" },
            { key: "nomeCidade", label: "Cidade", className: 'd-none d-md-table-cell' },
            { key: "nomeEstado", label: "Estado", className: 'd-none d-md-table-cell' },
            { key: "nomePais", label: "País", className: 'd-none d-md-table-cell' },
            { key: "dsAtivo", label: "Ativo?", className: 'text-center d-none d-md-table-cell' }
        ];
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    /*isDisabled(item) {
        return parseInt(item.ativo) === 0 ? true : super.isDisabled(item);
    }*/
    
    getClassName(item) {
        return parseInt(item.ativo) === 0 ? 'table-danger' : '';
    }

    getOptionLabel(item){
        return item.label + (parseInt(item.ativo) === 0 ? " [inativo]" : "");
    }

    getOptionClass(item){
        return parseInt(item.ativo) === 0 ? "bg-danger-subtle" : '';
    }

    getConfigFormNew() {
        return {
            title: 'Cadastro de Representante',
            sizeModal: 'modal-xl',
            config: {
                isModal: true
            },
            form: FormRepresentante,
            onSave: (response) => {
                this.getOptions(() => {
                    if (response.data?.idRepresentante) {
                        this.handleOpenSearch(null, response.data?.idRepresentante);
                    } else {
                        this.handleOpenSearch();
                    }
                });
            },
            onClose: () => {
                //this.handleOpenSearch();
            }
        };
    };

    getOptions(callBack) {
        var ativo = this.props.ativo === undefined ? null : this.props.ativo;

        RepresentanteService.getForCombo(ativo, (response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}