import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import RepresentanteEquipamentoAtualizacaoService from "../../services/RepresentanteEquipamentoAtualizacaoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputNumber from "../../components/forms/elements/InputNumber";

class FormRepresentanteEquipamentoAtualizacao extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idRepresentanteEquipamentoAtualizacao: "",
            nome: "",
            dsMarca: "",
            dsModelo: "",
            flAtivo : true,
            flTemAtualizacao : false,
            nrFrequenciaAtualizacao : 12
        };

        this.state.crud = {
            service: RepresentanteEquipamentoAtualizacaoService,
            labels: {
                title: 'RepresentanteEquipamentoAtualizacao',
            },
            urlList: '/LstRepresentanteEquipamentoAtualizacao',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <>            
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idRepresentanteEquipamentoAtualizacao} name="idRepresentanteEquipamentoAtualizacao" readOnly opcionalLabel={false}/>
                    <InputText label="Nome do RepresentanteEquipamentoAtualizacao" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <InputText label="Marca do RepresentanteEquipamentoAtualizacao" required md={6} value={this.state.model.dsMarca} name="dsMarca" onChange={this.handleChange} maxLength={100} />
                    <InputText label="Modelo do RepresentanteEquipamentoAtualizacao" required md={6} value={this.state.model.dsModelo} name="dsModelo" onChange={this.handleChange} maxLength={100} />
                    
                </div>
                <div className="row mb-3 g-2">
                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Tem Atualização?" name="flTemAtualizacao" checked={Boolean(this.state.model.flTemAtualizacao)} onChange={this.handleChange} />
                    {Boolean(this.state.model.flTemAtualizacao) ? 
                        <InputNumber md={3} label='Frequência da Atualização em meses' value={this.state.model.nrFrequenciaAtualizacao} name="nrFrequenciaAtualizacao" onChange={this.handleChange} required={true}/>
                    : <></>}
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormRepresentanteEquipamentoAtualizacao {...props} navigate={navigate} params={params} />
}

export default With