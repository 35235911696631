import React from "react";
import FormField from "./FormField";

export default class FormTreeView extends FormField {

    constructor(props) {
        super(props);

        this.handleClickItem = this.handleClickItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeOpened = this.changeOpened.bind(this);
        this.state.options = this.props.options || [];
        this.state.selectFolder = this.props.selectFolder === undefined ? false : this.props.selectFolder;
        this.state.selectElement = this.props.selectElement === undefined ? true : this.props.selectElement;

    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    changeOpened(item, opened = true) {
        item.forEach((option, index) => {
            item[index].opened = opened;
            if (item.options?.length > 0) {
                item[index].options = this.changeOpened(option.options);
            }
        });
        return item;
    }


    handleClickItem(state) {
        state.opened = state.opened === undefined ? true : !state.opened;
        this.setState({ state });
    }

    handleChange(item) {
        if (!this.state.selectFolder && item.options.length > 0) {
            return false;
        }

        if (this.props.name && item?.value && item.value) {
            var event = {
                target: {
                    name: this.props.name,
                    value: item.value?.toString() === this.props?.value.toString() ? "" : item.value
                }
            };

            super.handleChange(event);
            if (this.props.onChange) {
                this.props.onChange(event)
            }
        }
    }


    renderOptions(item, nivel = 0) {
        return (
            <ul className={`${nivel === 0 ? "parent" : "child"}`}>
                {item?.map((option, index) => (
                    <React.Fragment key={index}>
                        {option.options?.length > 0 ?
                            <li className="element-with-child">
                                <button type="button" className="btn btn-link p-0 m-0" onClick={e => { this.handleClickItem(option) }}>
                                    {option.opened ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg>
                                    }
                                </button>
                                <span className={`element-label ${option.value.toString() === this.props.value.toString() ? 'element-selected' : ''}`} onClick={e => { this.handleChange(option) }}>
                                    {option.label}
                                </span>
                                {option.opened ? this.renderOptions(option.options, nivel++) : <></>}
                            </li>
                            :
                            <li className="element-with-child" onClick={e => this.handleChange(option)}>
                                <span className={`element-label ${option.value.toString() === this.props.value.toString() ? 'element-selected' : ''}`}>
                                    {option.label}
                                </span>
                            </li>
                        }
                    </React.Fragment>
                ))}
            </ul>
        );
    }

    render() {
        var render = (
            <input type="text" ref={this.state.ref} className="form-control d-none" defaultValue={this.props.value} required={this.props.required} />
        );

        return (
            <div className="tree-view" {...this.getProps(this.props)}>
                {this.renderOptions(this.props.options)}
                <div>
                    {this.getRenderFormControl(render)}
                </div>
            </div>);

    }
}