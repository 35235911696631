import React from "react";
import EventBus from "../../controllers/EventBus";
import Modal from "../../controllers/Modal";
import Util from "../../utils/Util";
import MyComponent from "../custom/MyComponent";
import FormControl from "../forms/base/FormControl";
import BtnButton from "../forms/elements/BtnButton";
import BtnSubmit from "../forms/elements/BtnSubmit";

export default class ModalSearch extends MyComponent {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleNew = this.handleNew.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.getResultFilter = this.getResultFilter.bind(this);
        this.getIndexByKey = this.getIndexByKey.bind(this);
        this.getItemByValue = this.getItemByValue.bind(this);
        this.handleKeyUpModal = this.handleKeyUpModal.bind(this);

        this.state = {
            element: {},
            show: false,
            showBtnNew: false,
            confirm: props.confirm || (() => { }),
            new: props.new || (() => { }),
            close: props.close || (() => { }),
            disabled: props.disabled || (() => { return false; }),
            getClassName: props.getClassName || (() => { return ''; }),

            EventBusOpen: props.id ? props.id + "-open" : "modal-busca-open",

            options: this.props.options || [],
            headers: this.props.headers || [
                { key: "value", label: "Valor", className: 'text-center' },
                { key: "label", label: "Descrição" }
            ],

            sizeModal: this.props.sizeModal || "", //modal-sm | modal-lg | modal-xl | "" = none = Medium
            fullScreen: this.props.fullScreen || false,

            keyName: this.props.keyName || "value",
            keyLabel: this.props.keyLabel || "label",

            busca: "",
            refBusca: React.createRef(),
            refSelected: React.createRef(),
            model: {
                value: "",
                selected: {}
            },
            zIndex: 0
        };
    }

    componentDidMount() {
        super.componentDidMount();

        EventBus.on(this.state.EventBusOpen, (element) => {

            if (element.config) {
                this.setState((state) => {
                    Object.keys(element.config).forEach(key => {
                        if (element.config[key]) {
                            state[key] = element.config[key];
                        }
                    });
                    return state;
                }, () => {

                    this.setState({ show: true, busca: "", zIndex: Modal.getInstance().addModal() }, () => {
                        this.handleClick(element.value, true, () => {
                            this.state.refBusca.current.focus();
                        });
                    });
                });
            }
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        EventBus.remove(this.state.EventBusOpen);
    }

    getIndexByKey(keyValue) {
        if (keyValue) {
            return this.getResultFilter().findIndex(item => item[this.state.keyName].toString() === keyValue.toString());
        }
        return -1;
    }

    getItemByValue(keyValue) {
        if (keyValue) {
            var result = this.getResultFilter().find(item => item[this.state.keyName].toString() === keyValue.toString());
            if (result) {
                return result;
            }
        }
        return {};
    }

    handleClick(value, focus = false, callBack = false) {
        var event = {
            target: {
                name: "value",
                value: value
            }
        };
        super.handleChange(event);
        this.setModel({ selected: this.getItemByValue(value) }, () => {
            if (focus && this.state.refSelected.current) {
                this.state.refSelected.current.focus();
            }
            if (callBack) {
                callBack(value);
            }
        });

    }

    handleSelectItem(event) {

    }

    handleKeyUp(event) {

        var result = this.getResultFilter();
        var index = -1;
        var indexSet = -1;

        if (event.keyCode === 40) {
            index = this.getIndexByKey(this.state.model.value);

            indexSet = result.findIndex((item, ix) => {
                if (!item.disabled && ix > index) {
                    return item;
                }
                return -1;
            });

            if (indexSet !== -1) {
                this.handleClick(result[indexSet][this.state.keyName], true);
            } else if (this.state.refSelected.current) {
                this.state.refSelected.current.focus();
            }
        } else if (event.keyCode === 38) {

            index = this.getIndexByKey(this.state.model.value);
            index = index === -1 ? result.length : index;

            indexSet = -1;
            for (var i = (result.length - 1); i > 0; i--) {
                if (!result[i].disabled && index > i) {
                    indexSet = i;
                    break;
                }
            }

            if (indexSet !== -1) {
                this.handleClick(result[indexSet][this.state.keyName], true);
            } else if (this.state.refSelected.current) {
                this.state.refSelected.current.focus();
            }
        } else {
            if (result.length > 0) {
                this.handleClick(result[0][this.state.keyName]);
            }
        }
    }

    handleClose() {
        this.setState({ show: false, busca: "" });
        this.state.close();
        Modal.getInstance().delModal();
    }

    handleConfirm() {
        this.setState({ show: false, busca: "" }, () => {
            this.state.confirm(this.state.model.value);
            Modal.getInstance().delModal();
        });
    }

    handleNew() {
        this.handleClose();
        this.state.new();
    }

    onSubmit(event) {
        this.handleConfirm();
    }

    getResultFilter() {
        return this.state.options.filter(f =>
            Util.searchTextInObject(f, this.state.headers.map(element => element.key), this.state.busca)
        );
    }

    handleKeyUpModal(event) {
        if (event.keyCode === 27) {
            this.handleClose(event);
        }
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit} noValidate className={`needs-validation form-modal-search ${this.state.show ? "" : "d-none"} ${this.state.validated ? "was-validated" : ""}`} onKeyUp={this.handleKeyUpModal}>
                <div style={{ zIndex: 1000 + (this.state.zIndex * 2) }} className={`fade modal-backdrop show`}> </div>
                <div style={{ zIndex: 1001 + (this.state.zIndex * 2) }} className={`modal ${this.state.show ? "d-block" : "d-none"}`} id="exampleModal" tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="false" aria-modal="true">
                    <div className={`modal-dialog p-5 modal-dialog-scrollable ${this.state.sizeModal} ${this.state.fullScreen ? 'modal-fullscreen' : ''}`}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="w-100">
                                    <FormControl opcionalLabel={false} layout="grid" label="Busca" placeholder={this.state.model.selected[this.state.keyLabel] ? this.state.model.selected[this.state.keyLabel] : ""} refElement={this.state.refBusca} onKeyUp={this.handleKeyUp} autoComplete="off" name="busca" onChange={this.handleChange} value={this.state.busca} />
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleClose}></button>
                            </div>
                            <div className={`modal-busca-primary modal-body p-2`}>
                                <table className="table table-hover table-sm">
                                    <thead>
                                        <tr>
                                            {this.state.headers.map((header, index) => (
                                                <th className={header.className ? header.className : ''} key={index}> {header.label} </th>
                                            ))}
                                            <th> # </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.getResultFilter().map((option, indexData, arrFilter) => (
                                            <tr onClick={() => { if (!option.disabled) { this.handleClick(option[this.state.keyName], true) } }}
                                                key={option[this.state.keyName]}
                                                className={`${this.state.model.value.toString() === option[this.state.keyName].toString() ? 'table-primary' : ''} ${option.className}`}>
                                                {this.state.headers.map((header, indexHeader) => (
                                                    <td className={header.className ? header.className : ''} key={indexHeader}>
                                                        {header.fnData ? header.fnData(option[header.key], option) : option[header.key]}
                                                    </td>
                                                ))}
                                                <td>
                                                    {option.disabled ? <></> :
                                                        <input
                                                            ref={this.state.model.value.toString() === option[this.state.keyName].toString() ? this.state.refSelected : null}
                                                            className="form-check-input" type="radio" name="value"
                                                            checked={this.state.model.value.toString() === option[this.state.keyName].toString()}
                                                            value={option[this.state.keyName]}
                                                            onChange={this.handleChange}
                                                            disabled={option.disabled}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="modal-footer">
                                {
                                    this.state.showBtnNew ?
                                        <button type="button" className={`btn btn-outline-primary`} onClick={this.handleNew}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                            </svg> Novo
                                        </button>
                                        : ""
                                }
                                <BtnButton className="btn-outline-secondary ms-auto" onClick={this.handleClose}> Cancelar </BtnButton>
                                <BtnSubmit className="btn-primary" disabled={this.state.model.value ? false : true}> Selecionar </BtnSubmit>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    }
}