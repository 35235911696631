import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MyComponent from "../../components/custom/MyComponent";
import EventBus from "../../controllers/EventBus";
import CidadeService from "../../services/CidadeService";
import PaisService from "../../services/PaisService";
import RepresentanteService from "../../services/RepresentanteService";
import Util from "../../utils/Util";
import ModalCopy from "./ModalCopy";
import FormContato from "../../pages/Contato/FormContato";
import Permissao from "../../controllers/Permissao";

class BuscaRepresentante extends MyComponent {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChangeCidade = this.handleChangeCidade.bind(this);
        this.handleClickTableCidade = this.handleClickTableCidade.bind(this);
        this.handleClickNovaBusca = this.handleClickNovaBusca.bind(this);
        this.handleClickCopy = this.handleClickCopy.bind(this);
        this.handleClickCopyRepresentante = this.handleClickCopyRepresentante.bind(this);
        this.handleClickContato = this.handleClickContato.bind(this);
        this.toScroll = this.toScroll.bind(this);
        this.clear = this.clear.bind(this);
        this.handleChangePais = this.handleChangePais.bind(this);

        this.state = {
            paises: [],
            cidades: [],
            representantes: [],
            model: {
                idPais: 1,
                nomeCidade: "",
                cidade: {}
            },
            validated: false,
            submited: false,
            copying: [],
            formatView: "card"
        };

        this.references = {
            refResult: React.createRef(),
            refBusca: React.createRef(),
            refInputBusca: React.createRef()
        }


    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.location?.state?.busca) {
            this.setModel({ nomeCidade: this.props.location.state.busca }, () => {
                this.onSubmit();
            });
        }

        PaisService.getForCombo((response) => {
            this.setState({ paises: response });
        });

        this.getStateCache();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        EventBus.remove("open-busca-de-representante");
    }

    clear(clearBusca = true) {
        this.setState({ cidades: [], representantes: [], submited: false });
        this.setModel({ cidade: {}, });

        if (clearBusca) {
            this.setModel({ nomeCidade: "" });
            this.references.refInputBusca.current.focus();
        }
    }

    onSubmit() {
        this.clear(false);
        CidadeService.listSearch({
            idPais: this.state.model.idPais,
            nomeCidade: this.state.model.nomeCidade.trim()
        }, (response) => {
            this.setState({ cidades: response, submited: true });
        });
    }

    handleClickCopy(text) {
        if (Util.copyClipboard(text)) {
            toast.success('Dados copiado para a área de transferência!');
        } else {
            toast.error('Erro ao copiar, por favor copie manualmente!');
        }
    }

    handleClickCopyRepresentante(item, modal = false) {
        RepresentanteService.getCopyText(item.idRepresentante, (response) => {
            if (modal) {
                EventBus.dispatch("modal-copy-open", response.text);
            } else {
                if (Util.copyClipboard(response.text)) {
                    toast.success('Dados copiado para a área de transferência!');

                    var copying = this.state.copying;
                    copying.push(item);
                    this.setState(copying);

                    setTimeout(() => {
                        var copying = this.state.copying;
                        copying.shift();
                        this.setState(copying);
                    }, 2000);

                } else {
                    toast.error('Erro ao copiar, por favor copie manualmente!');
                    EventBus.dispatch("modal-copy-open", response.text);
                }


            }
        });
    }

    handleClickContato(representante, idCidade) {

        EventBus.dispatch("modal-add", {
            form: FormContato,
            title: 'Registro de Contato',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idRepresentante: representante.idRepresentante,
                idCidade: idCidade
            }
        });
    }

    handleChangePais(event) {
        this.clear();
    }

    handleChangeCidade(event) {
        let cidade = this.state.cidades.find(c => c.idCidade === event.target.value);

        this.setState({ representantes: [] });
        RepresentanteService.listByLocalizacao({
            latitude: cidade.latitude,
            longitude: cidade.longitude
        }, (response) => {
            this.setState({ representantes: response });
        });
    }

    handleClickTableCidade(cidade) {
        this.setModel({ cidade: cidade });
        this.setState({ representantes: [] });
        RepresentanteService.listByLocalizacao({
            latitude: cidade.latitude,
            longitude: cidade.longitude
        }, (response) => {
            this.setState({ representantes: response });
            this.toScroll(this.references.refResult);
        });
    }

    toScroll(ref) {
        setTimeout(() => {
            ref.current.scrollIntoView();
        }, 300);
    }

    handleClickNovaBusca() {
        this.toScroll(this.references.refBusca);

        setTimeout(() => {
            this.clear();
        }, 900);
    }

    render() {
        return (
            <div className="container p-3 my-2">
                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation w-100 ${this.state.validated ? "was-validated" : ""}`}>
                    <h4 ref={this.references.refBusca} className="mb-3">Busca de Representantes</h4>

                    <div className="row mb-3 g-3">

                        <div className="col-md-12 mb-3">
                            <label className="form-label">País</label>
                            <div>
                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    {this.state.paises.map((item) => (
                                        <React.Fragment key={item.value}>
                                            <input
                                                className="btn-check"
                                                autoComplete="off"
                                                name="idPais"
                                                id={`radio-${item.value}`}
                                                value={item.value}
                                                type="radio"
                                                checked={parseInt(this.state.model.idPais) === parseInt(item.value)}
                                                onClick={this.handleChangePais}
                                                onChange={this.handleChange} />
                                            <label className="btn btn-outline-primary" htmlFor={`radio-${item.value}`}>{item.label}</label>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 input-group mb-3">
                            <span className="input-group-text" id="addon-wrapping">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                            <input ref={this.references.refInputBusca} type="text" className="form-control" autoFocus autoComplete="off" name="nomeCidade" required="required" placeholder="Digite o nome de uma cidade" value={this.state.model.nomeCidade} onChange={this.handleChange} minLength={3} />
                            <button className="btn btn-outline-primary" type="submit"> Buscar </button>
                        </div>

                        {this.state.submited && this.state.cidades.length === 0 ?
                            <h6 className="text-center">
                                Nenhuma cidade encontrada!
                            </h6>
                            : <></>
                        }

                        <div className={`${this.state.cidades.length > 0 ? "" : "d-none"}`}>
                            <h6>Selecione uma cidade:</h6>
                            <div className="list-group table-busca-cidades mx-1">

                                <ol className="list-group list-group-numbered">
                                    {this.state.cidades.map((item, index) => (
                                        <li key={item.idCidade} onClick={() => this.handleClickTableCidade(item)}
                                            className={`list-group-item d-flex justify-content-between align-items-start ${parseInt(this.state.model.cidade.idCidade) === parseInt(item.idCidade) ? "active" : ""}`}
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{item.nome}</div>
                                                {item.nomeEstado}
                                            </div>
                                            <span className="badge bg-primary rounded-pill">{item.siglaEstado}</span>
                                        </li>
                                    ))}
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className={`text-end fs-6 mb-3 ${this.state.cidades.length > 0 ? "" : "d-none"}`}>
                        <strong>{this.state.cidades.length} cidades(s) encontrada(s)</strong>
                    </div>

                    <hr ref={this.references.refResult} />

                    <div className={`py-4 ${this.state.representantes.length > 0 ? "" : "d-none"}`}>

                        <div className="row pb-3">
                            <div className="col">
                                <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                                    <input
                                        className="btn-check"
                                        autoComplete="off"
                                        name="formatView"
                                        id="radio-card"
                                        value="card"
                                        type="radio"
                                        checked={this.state.formatView === "card"}
                                        onChange={this.handleChange} />
                                    <label className="btn btn-outline-primary" htmlFor="radio-card">Card</label>

                                    <input
                                        className="btn-check"
                                        autoComplete="off"
                                        name="formatView"
                                        id="radio-table"
                                        value="table"
                                        type="radio"
                                        checked={this.state.formatView === "table"}
                                        onChange={this.handleChange} />
                                    <label className="btn btn-outline-primary" htmlFor="radio-table">Tabela</label>
                                </div>
                            </div>
                            <div className="col text-end">
                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.handleClickNovaBusca}> Nova Busca </button>
                            </div>
                        </div>

                        <h4 className="mb-2 text-center">
                            Representantes de <strong> {this.state.model.cidade.nome} - {this.state.model.cidade.siglaEstado} </strong>
                        </h4>

                        <div className="mb-3 text-center">
                            <a href={`http://maps.google.com/?q=${this.state.model.cidade.latitude},${this.state.model.cidade.longitude}`} rel="noopener noreferrer" target="_blank" className="btn btn-outline-success btn-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-fill" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
                                </svg> Ver Localização no Maps
                            </a>
                        </div>

                        <div className={`table-responsive ${this.state.formatView === "table" ? "" : "d-none"}`}>
                            <table className="table table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th className="col-4">Empresa</th>
                                        <th className="col-3">Cidade</th>
                                        <th className="col-2">Whatsapp</th>
                                        <th className="col-2 text-end">Distância</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.representantes.map((item) => (
                                        <tr key={item.idRepresentante}>
                                            <td>{item.empresa}</td>
                                            <td>
                                                <span className="fw-bolder">{item.nomeCidade} - {item.nomeEstado}</span>
                                                <div className="fst-italic"><small>{item.endereco}</small></div>
                                            </td>
                                            <td>
                                                <small className="cursor-copy" onClick={() => { this.handleClickCopy("+" + item.ddi + item.telefone) }}>{"+" + item.ddi + item.telefone}</small> <br />
                                            </td>
                                            <td className="text-end">
                                                <span className="badge bg-primary">{item.distanciaEmKm} km</span>
                                            </td>
                                            <td className="d-flex flex-nowrap">
                                                <button type="button" variant="link" className={`btn btn-sm ms-2 ${Permissao.getInstance().getPermissao(12) ? '' : 'd-none'}`} onClick={() => this.handleClickContato(item, this.state.model.cidade.idCidade)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
                                                        <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                                        <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                                                    </svg>
                                                </button>
                                                <button type="button" variant="link" className="btn btn-sm ms-2" onClick={() => this.handleClickCopyRepresentante(item, true)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                                                    </svg>
                                                </button>
                                                <button type="button" variant="link" className="btn btn-sm ms-2" onClick={() => this.handleClickCopyRepresentante(item)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`${this.state.copying.indexOf(item) !== -1 ? "d-none" : ""} bi bi-clipboard`} viewBox="0 0 16 16">
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`${this.state.copying.indexOf(item) !== -1 ? "" : "d-none"} bi bi-clipboard-check-fill`} viewBox="0 0 16 16">
                                                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div className={`row g-3 ${this.state.formatView === "card" ? "" : "d-none"}`}>
                            {this.state.representantes.map((item) => (
                                <div className="col-md-4" key={item.idRepresentante}>
                                    <div className="card border-secondary card-representante h-100">
                                        <div className="card-body">
                                            <h5 className="card-title">{item.empresa}</h5>
                                            <h6 className="card-subtitle mb-2 text-muted">
                                                {item.nomeCidade} - {item.nomeEstado}
                                            </h6>
                                            <p className="card-text">
                                                <strong>Endereço: </strong>{item.endereco}<br />
                                                <strong>Telefone: </strong>
                                                <span className="cursor-copy" onClick={() => { this.handleClickCopy("+" + item.ddi + item.telefone) }}>{"+" + item.ddi + item.telefone}</span>
                                            </p>
                                        </div>
                                        <div className="card-footer">
                                            <div className="float-start p-1">
                                                <small className="text-muted ">Distância: <strong>{item.distanciaEmKm} km </strong></small>
                                            </div>
                                            <div className="w-100 text-end">
                                                <button type="button" variant="link" className={`btn btn-sm ms-2 ${Permissao.getInstance().getPermissao(12) ? '' : 'd-none'}`} onClick={() => this.handleClickContato(item, this.state.model.cidade.idCidade)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
                                                        <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                                        <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                                                    </svg>
                                                </button>
                                                <button type="button" variant="link" className="btn btn-sm ms-2" onClick={() => this.handleClickCopyRepresentante(item, true)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                                                    </svg>
                                                </button>
                                                <button type="button" variant="link" className="btn btn-sm ms-2" onClick={() => this.handleClickCopyRepresentante(item)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`${this.state.copying.indexOf(item) !== -1 ? "d-none" : ""} bi bi-clipboard`} viewBox="0 0 16 16">
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`${this.state.copying.indexOf(item) !== -1 ? "" : "d-none"} bi bi-clipboard-check-fill`} viewBox="0 0 16 16">
                                                        <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z" />
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <ModalCopy id="modal-copy" />

                </form >
            </div>

        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <BuscaRepresentante {...props} navigate={navigate} params={params} location={location} />
}

export default With

/*


<Link className="btn btn-sm ms-2" to={`/CadContato/${item.idRepresentante}/${this.state.model.cidade.idCidade}`}>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
        <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
        <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
    </svg>
</Link>


*/