import React from "react";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectDDI from "../../components/app/SelectDDI";
import InputCpf from "../../components/forms/custom/InputCpf";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import InputEmail from "../../components/forms/elements/InputEmail";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import InputCep from "../../components/forms/custom/InputCep";
import { useNavigate, useParams } from "react-router-dom";
import FornecedorService from "../../services/FornecedorService";
import SelectPais from "../../components/app/SelectPais";
import AreaAtuacaoService from "../../services/AreaAtuacaoService";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import Util from "../../utils/Util";
import EnderecoService from "../../services/EnderecoService";

class FormFornecedor extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);

        this.handleChangeAreaAtuacao = this.handleChangeAreaAtuacao.bind(this);
        this.handleChangeAreaAtuacaoAll = this.handleChangeAreaAtuacaoAll.bind(this);
        this.handleChangeCep = this.handleChangeCep.bind(this);

        this.state.isCard = true;

        this.state.model = {
            idFornecedor: "",

            ddi: "55",
            telefone: "",
            nome: "",
            razaoSocial: "",
            nomeFantasia: "",
            email: "",
            cdTipoPessoa: "F",
            cpf: "",
            cnpj: "",

            idPais: 1,
            idEstado: "",
            idCidade: "",
            cep: "",
            bairro: "",
            endereco: "",
            numero: "",
            complemento: "",

            flTemRastreamento: false,

            fornecedorAreaAtuacao: []
        };

        this.state.areaAtuacoes = [];

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];

        this.state.crud = {
            service: FornecedorService,
            labels: {
                title: 'Fornecedor',
            },
            urlList: '/LstFornecedor',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        AreaAtuacaoService.list((response) => {
            this.setState({ areaAtuacoes: response });
        });
    }


    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeAreaAtuacao(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.fornecedorAreaAtuacao.push(item);
                return state;
            });
        } else {
            var index = this.state.model.fornecedorAreaAtuacao.findIndex(i => parseInt(i.idAreaAtuacao) === parseInt(item.idAreaAtuacao));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.fornecedorAreaAtuacao.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeAreaAtuacaoAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.fornecedorAreaAtuacao = [];

                this.state.areaAtuacoes.forEach(function (item) {
                    state.model.fornecedorAreaAtuacao.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.fornecedorAreaAtuacao = [];
                return state;
            });
        }
    }

    handleChangeCep() {
        if (Util.stringToNumber(this.state.model.cep).length === 8) {
            EnderecoService.getOneByCep(this.state.model.cep, (response) => {
                this.setState((state) => {
                    state.model.endereco = response?.logradouro ? response.logradouro : "";
                    state.model.bairro = response?.bairro ? response.bairro : "";
                    state.model.complemento = response?.complemento ? response.complemento : "";
                    state.model.numero = "";

                    if (response?.idEstado) {
                        state.model.idEstado = response.idEstado;
                    }

                    if (response?.idCidade) {
                        state.model.idCidade = response.idCidade;
                    }

                    return state;
                });
            });

        }
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-dados-tab" data-bs-toggle="tab" data-bs-target="#nav-dados" type="button" role="tab" aria-controls="nav-dados" aria-selected="true">
                        Dados do Fornecedor
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-area-atuacao-tab" data-bs-toggle="tab" data-bs-target="#nav-area-atuacao" type="button" role="tab" aria-controls="nav-area-atuacao" aria-selected="false">
                        Área de Atuação
                    </button>
                </li>
            </ul>);
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">

                    <h6 className="border-bottom border-secondary pb-1"> Dados Cadastrais </h6>

                    <div className="row mb-3 g-3">
                        <InputText label="Nome" required lg={5} value={this.state.model.nome} name="nome" onChange={this.handleChange} />
                        <BtnGroup required={true} lg={4} className="w-100" name="cdTipoPessoa" value={this.state.model.cdTipoPessoa} label="Tipo Pessoa" options={this.state.tipoPessoa} onChange={this.handleChange} />

                        {this.state.model.cdTipoPessoa === "F" ? <InputCpf label="CPF" lg={3} name="cpf" value={this.state.model.cpf ? this.state.model.cpf : ""} onChange={this.handleChange} /> : <></>}
                        {this.state.model.cdTipoPessoa === "J" ? <InputCnpj label="CNPJ" lg={3} name="cnpj" value={this.state.model.cnpj ? this.state.model.cnpj : ""} onChange={this.handleChange} /> : <></>}

                        <InputText label="Razão Social" lg={6} value={this.state.model.razaoSocial} name="razaoSocial" onChange={this.handleChange} />
                        <InputText label="Nome Fantasia" lg={6} value={this.state.model.nomeFantasia} name="nomeFantasia" onChange={this.handleChange} />

                        <SelectDDI showBtns={false} lg={2} label="DDI" required placeholder="Selecione" name="ddi" value={this.state.model.ddi} onChange={this.handleChange} />
                        {this.state.model.ddi.toString() === "55" ?
                            <InputTelCelular label="Telefone" lg={3} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} />
                            :
                            <InputText label="Telefone" lg={3} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} />
                        }
                        <InputEmail label="E-mail" lg={7} name="email" value={this.state.model.email} onChange={this.handleChange} />
                    </div>

                    <h6 className="border-bottom border-secondary pb-1"> Endereço do Fornecedor </h6>

                    <div className="row mb-3 g-3">
                        <InputCep label="CEP" lg={3} value={this.state.model.cep} name="cep" onChange={e => this.handleChange(e, this.handleChangeCep)} />
                        <InputText label="Logradouro" lg={6} value={this.state.model.endereco} name="endereco" onChange={this.handleChange} maxLength={100} />
                        <InputText label="Número" lg={3} value={this.state.model.numero} name="numero" onChange={this.handleChange} maxLength={100} />

                        <InputText label="Complemento" lg={6} value={this.state.model.complemento} name="complemento" onChange={this.handleChange} maxLength={100} />
                        <InputText label="Bairro" lg={6} value={this.state.model.bairro} name="bairro" onChange={this.handleChange} maxLength={150} />

                        <SelectPais label="Nome do País" required placeholder="Selecione o País" name="idPais" lg={4} value={this.state.model.idPais} onChange={this.handleChange} />
                        <SelectEstadoByPais disabled={!this.state.model.idPais} showBtnNewForm={false} sizing="md" lg={4} required idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" onChange={this.handleChange} />
                        <SelectCidadeByEstado showBtnNewForm={false} sizing="md" lg={4} required idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" onChange={this.handleChange} />
                    </div>

                    <div className="row mx-1 mb-3">
                        <InputCheckSwitch label="Tem Rastreamento?" name="flTemRastreamento" checked={Boolean(this.state.model.flTemRastreamento)} onChange={this.handleChange} />
                    </div>

                </div>
                <div className="tab-pane fade show" id="nav-area-atuacao" role="tabpanel" aria-labelledby="nav-area-atuacao-tab">
                    <h6> Áreas Vínculadas </h6>
                    <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeAreaAtuacaoAll} checked={this.state.model.fornecedorAreaAtuacao.length === this.state.areaAtuacoes.length} />
                    <div className="row mb-3">
                        {this.state.areaAtuacoes.map((item) => (
                            <div key={item.idAreaAtuacao} className="col-md-12">
                                <InputCheckSwitch label={item.nome} name="area" checked={this.state.model.fornecedorAreaAtuacao.findIndex(i => parseInt(i.idAreaAtuacao) === parseInt(item.idAreaAtuacao)) !== -1} onChange={(e) => { this.handleChangeAreaAtuacao(e, item) }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div >

        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormFornecedor {...props} navigate={navigate} params={params} />
}

export default With