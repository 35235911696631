import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import BtnButton from "../../components/forms/elements/BtnButton";

class FormAviso extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            cobrancas: this.props.cobrancas || []
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Fechar </BtnButton>
            </div>);
    }

    toFatura(cobranca) {
        this.handleClose();
        this.props.navigate('/CadFatura/' + cobranca.idFatura);
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                {this.state.model.cobrancas.map((cobranca, index) => (
                    <React.Fragment key={index}>
                        {parseInt(cobranca.flVencido) === 1 ?
                            <div className="alert alert-danger" role="alert">
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg><strong> ATENÇÃO</strong> A sua cobrança número <strong>{cobranca.idCobranca}</strong> referente a fatura <strong>{cobranca.idFatura}</strong> no valor de <strong> {Util.floatToReais(cobranca.vlCobranca)} </strong> venceu há <strong>{cobranca.nrDias} dia(s)</strong>, no dia <strong>{Util.date2Br(cobranca.dtVencimento)}</strong>, regularize sua pendência para não perder seu acesso ao sistema!
                                </p>
                                <p>
                                    Clique <BtnButton onClick={() => { this.toFatura(cobranca) }} className="btn-danger btn-sm">aqui</BtnButton> para acessar os detalhes desta cobrança!
                                </p>
                                <hr />
                                <p className="mb-0"><small>Obs: Caso já tenha realizado o pagamento, aguarde o périodo de processamento da baixa bancária ou baixa manual pela BDM Performance.</small></p>
                            </div>
                            :
                            <div className="alert alert-warning" role="alert">
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                    </svg><strong> ATENÇÃO</strong> A sua cobrança número <strong>{cobranca.idCobranca}</strong> no valor de <strong> {Util.floatToReais(cobranca.vlCobranca)} </strong> vencerá dia <strong>{Util.date2Br(cobranca.dtVencimento)}</strong>, daqui há <strong>{cobranca.nrDias} dia(s)</strong>! Mantenha suas faturas / cobranças em dia para não pagar juros e multa.
                                </p>
                                <p>
                                    Clique <BtnButton onClick={() => { this.toFatura(cobranca) }} className="btn-warning btn-sm">aqui</BtnButton> para acessar os detalhes desta cobrança!
                                </p>
                                <hr />
                                <p className="mb-0"><small>Obs: Caso já tenha realizado o pagamento, aguarde o périodo de processamento da baixa bancária ou baixa manual pela BDM Performance.</small></p>
                            </div>
                        }

                    </React.Fragment>
                ))
                }
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormAviso {...props} navigate={navigate} params={params} />
}

export default With