import React from "react";
import ServicoContatoService from "../../services/ServicoContatoService";
import CrudList from "../../components/forms/custom/CrudList";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import EventBus from "../../controllers/EventBus";

export default class ListaServicoContato extends CrudList {

    constructor(props) {
        super(props);

        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.getData = this.getData.bind(this);

        this.state.crud = {
            service: ServicoContatoService,
            labels: {
                title: 'Lista de Serviços(s)',
                btnNewRegister: 'Serviço',
            },
            keyItem: 'idServicoContato',
            urlForm: '/CadServicoContato',
            itensFilter: ["idServicoContato", "nmServicoContato", "flAtivo", "nrPrioridade"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleStatus(item) {
        EventBus.dispatch("modal-confirmacao-open", {
            id: item.idServicoContato,
            config: {
                title: "Alterar o Status?",
                message:
                    <span>
                        Deseja <span className={`badge bg-${item.flAtivo ? "danger" : "success"}`}>{item.flAtivo ? "DESATIVAR" : "ATIVAR"}</span>  o serviço <strong>{item.nmServicoContato}</strong>?
                    </span>,
                description: item.flAtivo ? "Esse serviço será inativado!" : "Esse serviço será ativado!",
                btnConfirmText: "Sim, alterar o status",
                style: item.flAtivo ? "danger" : "success",
                confirm: this.onChangeStatus
            }
        });
    }

    onChangeStatus(item) {
        this.state.crud.service.setStatus(item.id, (response) => {
            this.onList();
        });
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-6"> Nome </th>
                <th className="col-md-2 d-none d-md-table-cell"> Ativo ? </th>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Prioridade </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idServicoContato}</td>
                <td>{item.nmServicoContato}</td>
                <td className="text-center d-none d-md-table-cell">
                    <InputCheckSwitch name="ativo" checked={Boolean(item.flAtivo)} onChange={(e) => { this.handleStatus(item) }} />
                </td>
                <td className="text-center d-none d-md-table-cell">{item.nrPrioridade}</td>
            </>);
    }

    render() {
        return super.render();
    }
}