import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import SelectPais from "../../components/app/SelectPais";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import InputNumber from "../../components/forms/elements/InputNumber";
import CidadeService from "../../services/CidadeService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectRepresentante from "../../components/app/SelectRepresentante";

class FormCidade extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idCidade: "",
            idPais: "",
            idEstado: "",
            nome: "",
            latitude: "",
            longitude: "",
            capital: false,
            idIbge: "",
            ddd: "",
            fusoHorario: "",
            idRepresentante : ""
        };

        this.state.crud = {
            service: CidadeService,
            labels: {
                title: 'Cidade',
            },
            urlList: '/LstCidade',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.idPais && this.props.idEstado) {
            this.setModel({ idPais: this.props.idPais, idEstado: this.props.idEstado });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={3} value={this.state.model.idCidade} name="idCidade" readOnly opcionalLabel={false} />
                <SelectPais label="Nome do País" autoFocus={true} required placeholder="Selecione o País" name="idPais" md={9} value={this.state.model.idPais} onChange={this.handleChange} />
                <SelectEstadoByPais required idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" md={12} onChange={this.handleChange} />
                <InputText label="Nome da Cidade" required md={12} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />

                <InputNumber label="Latitude" required md={6} value={this.state.model.latitude} name="latitude" onChange={this.handleChange} />
                <InputNumber label="Longitude" required md={6} value={this.state.model.longitude} name="longitude" onChange={this.handleChange} />

                <SelectRepresentante md={12} label="Representante" placeholder="Selecione o Representante da Abrangência" name="idRepresentante" value={this.state.model.idRepresentante} onChange={this.handleChange} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCidade {...props} navigate={navigate} params={params} />
}

export default With