import React from "react";
import ProdutoServicoInventarioService from "../../services/ProdutoServicoInventarioService";
import CrudList from "../../components/forms/custom/CrudList";
import Util from "../../utils/Util";

export default class ListaProdutoServicoInventario extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ProdutoServicoInventarioService,
            labels: {
                title: 'Lista de Inventário(s)',
                btnNewRegister: 'Inventário',
            },
            keyItem: 'idProdutoServicoInventario',
            urlForm: '/CadProdutoServicoInventario',
            itensFilter: ["idProdutoServicoInventario", "nomeProduto", "nomeUsuario"],
            searchFormSubmit: false
        };

        this.state.container = false;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Data </th>
                <th className="col-md-3"> Produto </th>
                <th className="col-md-3"> Usuário </th>
                <th className="col-md-2 text-center"> Estoque Atual</th>
                <th className="col-md-2 text-center"> Estoque Antigo </th>
                <th className="col-md-2 text-center"> Inventário </th>
                <th className="text-center"> UN </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell text-nowrap">{item.idProdutoServicoInventario}</td>
                <td className="text-center d-none d-md-table-cell">{Util.date2Br(item.dataInventario)} {item.horaInventario}</td>
                <td>{item.nomeProduto}</td>
                <td>{item.nomeUsuario}</td>
                <td className="text-center">{parseFloat(item.vlEstoqueAtual).toFixed(item.nrCasasDecimais)}</td>
                <td className="text-center">{parseFloat(item.vlEstoque).toFixed(item.nrCasasDecimais)}</td>
                <td className="text-center">{parseFloat(item.nrQuantidade).toFixed(item.nrCasasDecimais)}</td>
                <td className="text-center">{item.siglaUnidadeMedida}</td>
            </>);
    }

    getButtons(item) {
        return (<></>);
    }

    render() {
        return super.render();
    }
}