import React from "react";
import PlanoPagamentoService from "../../services/PlanoPagamentoService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaPlanoPagamento extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: PlanoPagamentoService,
            labels: {
                title: 'Lista de Plano(s) de Pagamento(s)',
                btnNewRegister: 'Plano de Pagamento',
            },
            keyItem: 'idPlanoPagamento',
            urlForm: '/CadPlanoPagamento',
            itensFilter: ["idPlanoPagamento", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-9"> Nome </th>
                <th className="col-md-3"> Recorrência </th>
                <th className="col-md text-center"> Parcelas </th>
                <th className="col-md text-center"> Início </th>
                <th className="col-md text-center"> Intervalo </th>
                <th className="col-md text-center"> Proprietário? </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idPlanoPagamento}</td>
                <td>{item.nome}</td>
                <td>{item.nomePlanoRecorrencia}</td>
                <td className="text-center">{item.nrParcelas}</td>
                <td className="text-center">{item.nrDiasIniciar}</td>
                <td className="text-center">{item.nrDiasIntervalo}</td>
                <td className="text-center">
                    {Boolean(item.flProprietarioSeleciona) ? <span className="badge bg-success">SIM</span> : <span className="badge bg-danger">NÃO</span>}
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}