import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MyComponent from "../../components/custom/MyComponent";
import BtnButton from "../../components/forms/elements/BtnButton";
import Util from "../../utils/Util";
import { Chart } from "react-google-charts";
import BlocoService from "../../services/BlocoService";
import EventBus from "../../controllers/EventBus";
import FormBloco from "../Bloco/FormBloco";
import Permissao from "../../controllers/Permissao";
import MostraValor from "../../components/app/MostraValor";
import InputDate from "../../components/forms/elements/InputDate";
import InputMonth from "../../components/forms/elements/InputMonth";
import InputNumber from "../../components/forms/elements/InputNumber";
import Select from "../../components/forms/elements/Select";
import Config from "../../Config";

class Bloco extends MyComponent {

    constructor(props) {
        super(props);

        this.getDadosBloco = this.getDadosBloco.bind(this);
        this.handleClickEditBloco = this.handleClickEditBloco.bind(this);
        this.handleClickExpandirBloco = this.handleClickExpandirBloco.bind(this);
        this.handleClickDeleteBloco = this.handleClickDeleteBloco.bind(this);
        this.addTimeoutBloco = this.addTimeoutBloco.bind(this);
        this.delTimeoutBloco = this.delTimeoutBloco.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickView = this.handleClickView.bind(this);

        /*this.cache = {
            active: true,
            id: "cache-bloco-" + (props.idBloco !== undefined ? props.idBloco : ""),
            clear: true,
            state: {
                model: {
                    vlStart: true,
                    vlEnd: true,
                    idRepresentante: true
                }
            }
        };*/

        this.state = {
            model: {
                idBloco: props.idBloco !== undefined ? props.idBloco : "",
                nome: "",
                dsDetalhe: "",
                dsCabecalho: "",
                dsTitulo: "",
                nrTempoAtualizar: "",
                cdTipo: "number",
                cdModelo: "",
                dsUrl: "",
                nrTamanhoColuna: 3,
                nrOrdem: "",
                flAgrupar: false,
                flAtivo: true,
                dsComando: "",
                dsConfiguracao: {},

                cdTipoFiltro : "PD",
                nrIntervaloFiltro: 0,
                
                vlStart : "",
                vlEnd : "",
                idRepresentante : props.idRepresentante !== undefined ? props.idRepresentante : "",
            },
        };

        this.state.filtroNrDia = [
            { value: "1", label: "1 dia" },
            { value: "5", label: "5 dias"},
            { value: "30", label: "1 mês"},
            { value: "180", label: "6 meses"},
            { value: "365", label: "1 ano"},            
            { value: "0", label: "MAX"},
        ];

        this.timeout = null;

        this.state.refForm = React.createRef();
    }

    componentDidMount() {
        super.componentDidMount();
        this.getBloco();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.delTimeoutBloco();
    }

    addTimeoutBloco() {
        if (this.props.autoUpdate === undefined || Boolean(this.props.autoUpdate)) {
            var nrTempoAtualizar = this.state.model.nrTempoAtualizar !== undefined && this.state.model.nrTempoAtualizar ? parseInt(this.state.model.nrTempoAtualizar) : 60;
            this.timeout = setTimeout(() => {
                this.getDadosBloco();
            }, (1000 * nrTempoAtualizar));
        }
    }

    delTimeoutBloco() {
        clearTimeout(this.timeout);
    }

    onSubmit(event) {
        this.setStateCache();
        this.getDadosBloco();
    }

    getBloco() {
        if (parseInt(this.state.model.idBloco) > 0) {
            BlocoService.getOneByUserId(this.state.model.idBloco, response =>{
                this.setState(state=>{
                    state.model = {
                        ...state.model,
                        ...response
                    }

                    if(this.props.vlStart !== undefined){
                        state.model.vlStart = this.props.vlStart;
                        state.model.vlEnd = this.props.vlEnd !== undefined ? this.props.vlEnd : "";
                    }else{

                        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
                        var dtNow = new Date();
                        var dtEnd = new Date(dtNow - tzoffset);
                        var dtStart = new Date();
                        dtStart = new Date(dtStart - tzoffset);
                        
                        switch(state.model.cdTipoFiltro){
                            case "CD":
                                dtStart.setDate(dtStart.getDate() - state.model.nrIntervaloFiltro);
                                state.model.vlStart = Util.dateFormatYYYYMMDD(dtStart);
                                break;
                            case "CM":
                                dtStart.setDate(1);
                                dtStart.setMonth(dtStart.getMonth() - state.model.nrIntervaloFiltro);
                                state.model.vlStart = Util.dateFormatYYYYMM(dtStart);
                                break;
                            case "CA":
                                state.model.vlStart = dtStart.getFullYear() - state.model.nrIntervaloFiltro;
                                break;
                            case "ID":
                                dtStart.setDate(dtStart.getDate() - state.model.nrIntervaloFiltro);
                                state.model.vlStart = Util.dateFormatYYYYMMDD(dtStart);
                                state.model.vlEnd = Util.dateFormatYYYYMMDD(dtEnd);
                                break;
                            case "IM":
                                dtStart.setDate(1);
                                dtStart.setMonth(dtStart.getMonth() - state.model.nrIntervaloFiltro);
                                state.model.vlStart = Util.dateFormatYYYYMM(dtStart);
                                state.model.vlEnd = Util.dateFormatYYYYMM(dtEnd);
                                break;
                            case "IA":
                                state.model.vlStart = dtStart.getFullYear() - state.model.nrIntervaloFiltro;
                                state.model.vlEnd = dtStart.getFullYear();
                                break;
                            default:
                                state.model.vlStart = "";
                                state.model.vlEnd = "";
                        }
                    }

                    return state;
                }, this.getDadosBloco);
            });
        }
    }

    getDadosBloco() {
        if (parseInt(this.state.model.idBloco) > 0) {
            if (this.props.play === undefined || Boolean(this.props.play)) {
                this.delTimeoutBloco();

                this.setState(state => {
                    state.model.flAtualizando = true;
                    state.model.flErro = false;
                    return state;
                });

                BlocoService.getDados(this.state.model.idBloco, this.state.model ,(response) => {
                    this.setState(state => {

                        if(response.cdTipo === "grafico" && response.cdModelo === "Calendar"){

                            var content = [                                
                                [
                                    { type: "date", id: "Date",},
                                    { type: "number", id: "Valor"},
                                ]
                            ];
                            
                            response.content.forEach(function(element, index){
                                if(index > 0){
                                    content.push([new Date(parseInt(element[0]), parseInt(element[1])-1, parseInt(element[2])), element[3]]);
                                }
                            });

                            response.content = content;
                        }

                        response.dataAtualizacao = new Date();
                        state.model = {
                            ...state.model,
                            ...response
                        };
                        state.model.flAtualizando = false;
                        state.model.flErro = false;
                        return state;
                    }, () => {
                        if (Boolean(this.state.model.flAtivo)) {
                            this.addTimeoutBloco();
                        }
                        if (this.props.fnCallback !== undefined) {
                            this.props.fnCallback(this.state.model);
                        }
                    });
                }, () => {
                    this.setState(state => {
                        state.model.flAtualizando = false;
                        state.model.flErro = true;
                        return state;
                    }, () => {
                        this.addTimeoutBloco();
                        if (this.props.fnCallback !== undefined) {
                            this.props.fnCallback(this.state.model);
                        }
                    })
                });
            }
        }
    }

    handleClickEditBloco() {
        EventBus.dispatch("modal-add", {
            title: 'Edição do Bloco',
            sizeModal: 'modal-xl',
            fullScreen: true,
            scrollable: false,
            config: {
                isModal: true,
                id: this.state.model.idBloco
            },
            form: FormBloco,
            onSave: (response) => {
                this.getDadosBloco();
            }
        });
    }

    handleClickExpandirBloco() {
        EventBus.dispatch("modal-add", {
            title: 'Visualização do Bloco',
            sizeModal: 'modal-xl',
            fullScreen: true,
            scrollable: false,
            config: {
                isModal: true,
                idBloco: this.state.model.idBloco,
                expandir: true,
                filterable : this.props.filterable,
                vlStart : this.state.model.vlStart,
                vlEnd : this.state.model.vlEnd,
            },
            form: Bloco,
            onSave: (response) => {                
            }
        });
    }

    handleClickDeleteBloco(bloco) {
        EventBus.dispatch("modal-confirmacao-open", {
            id: this.state.model.idBloco,
            config: {
                title: "Excluir Ordem?",
                message: <>Deseja realmente excluir o bloco selecionado?</>,
                btnConfirmText: "Sim, excluir o bloco",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: () => {
                    BlocoService.delete(this.state.model.idBloco, () => {
                        this.setState(state => {
                            state.model.flAtivo = false;
                            return state;
                        }, this.delTimeoutBloco)
                    });
                }
            }
        })
    }

    handleClickView(output){
        var urlRelatorio = "relatorio/bloco-001";

        var model = {
            orientation: "P",
            destination: "I",
            output : output,
            flMarcaDagua: true,
        };

        model = {...this.state.model, ...model};
        Util.openNewWindow(Config.getUrlApi() + urlRelatorio, model);
    }

    fnFormat(fn, value) {
        switch (fn) {
            case "fnValorReais":
                return Util.floatToReais(value);
            default:
                return value;
        }

    }

    submitForm() {
        this.state.refForm.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    };

    render() {
        var pgBarColor = ["#ed561b", "#50B432", "#058DC7", "#990099", "#DC3912", "#FF9900", "#3366CC", "#109618", "#ED7D31", "#70AD47", "#9E480E", "#000000"];
        var renderBody = <></>;
        var renderTitle = <></>;
        var renderFooter = <></>;

        if (Boolean(this.state.model.flAtivo)) {
            if (this.state.model.progressBar !== undefined) {
                renderFooter =
                    <div className="card-footer bg-white border-dark">
                        <div className="mb-1 d-flex flex-row gap-1 flex-wrap">
                            {this.state.model.progressBar?.map((itemPgBar, indexPgBar) => (
                                <div key={indexPgBar} className="d-flex flex-row align-items-center gap-1 ">
                                    <span style={{ height: "8px", width: "8px", backgroundColor: pgBarColor[indexPgBar] }}>&nbsp;</span>
                                    <span className={`fs-7 fw-normal text-nowrap`}>
                                        {itemPgBar.label}
                                        {parseInt(itemPgBar.value) <= 5 ? " [" + itemPgBar.value + "%]" : ""}
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className="progress" style={{ height: "20px" }}>
                            {this.state.model.progressBar?.map((itemPgBar, indexPgBar) => (
                                <div key={indexPgBar} className={`progress-bar fw-bold`} role="progressbar" aria-label="Segment one" style={{ width: itemPgBar.value + "%", backgroundColor: pgBarColor[indexPgBar] }} aria-valuenow="15" aria-valuemin="0" aria-valuemax="100">
                                    {parseInt(itemPgBar.value) > 5 ? itemPgBar.value + "%" : ""}
                                </div>
                            ))}
                        </div>
                    </div>;
            }

            renderTitle = this.state.model.dsTitulo ? <div className="card-title text-center fs-5">
                {this.state.model.dsTitulo}
            </div> : <></>;

            var isValor = this.state.model.dsConfiguracao?.isValor !== undefined ? this.state.model.dsConfiguracao?.isValor : false;
            var msgOculto = <></>;

            switch (this.state.model.cdTipo) {
                case "number":
                    var content = "";

                    if (this.state.model.content !== undefined) {
                        content = this.state.model.dsConfiguracao?.fnFormat !== undefined ? this.fnFormat(this.state.model.dsConfiguracao.fnFormat, this.state.model.content) : this.state.model.content;
                    }
                    content = content.toString();

                    var className = this.state.model.dsConfiguracao?.className !== undefined ? this.state.model.dsConfiguracao?.className : "display-1 fw-bold text-nowrap";


                    renderTitle = this.state.model.dsTitulo ? <div className="card-title text-center fs-2">{this.state.model.dsTitulo}</div> : <></>;
                    renderBody =
                        <div className={`card-text text-center`}>
                            <span className={`align-middle ${className}`}>
                                {isValor ? <MostraValor>{content}</MostraValor> : content}
                            </span>
                        </div>;
                    break;

                case "list-group-numbered":
                    renderBody =
                        <ol className="list-group list-group-numbered">
                            {this.state.model.content.map((col, indexCol) => {

                                var value = false;
                                if (col.value !== undefined) {
                                    value = col.fnFormat !== undefined ? this.fnFormat(col.fnFormat, col.value) : col.value;
                                    value = isValor ? <MostraValor>{value}</MostraValor> : value;
                                }

                                return (
                                    <li key={indexCol} className="list-group-item d-flex justify-content-between align-items-start pt-1 pb-1">
                                        <div className="ms-2 me-auto">
                                            {col.label ? <div className="fw-bold"> {col.label}</div> : <></>}
                                            {col.title}
                                        </div>
                                        {col.value !== undefined ?
                                            <span className="badge bg-primary rounded-pill">
                                                {value}
                                            </span> : <></>}
                                    </li>);
                            })}
                        </ol>;
                    break;

                case "list-group-custom":
                    renderBody =
                        <div className="list-group">
                            {this.state.model.content.map((col, indexCol) => {
                                var value = false;
                                if (col.value !== undefined) {
                                    value = col.fnFormat !== undefined ? this.fnFormat(col.fnFormat, col.value) : col.value;
                                    value = isValor ? <MostraValor>{value}</MostraValor> : value;
                                }

                                return (
                                    <div key={indexCol} className="list-group-item">
                                        <div className="d-flex w-100 justify-content-between">
                                            {col.label ? <h5 className="mb-1">{col.label}</h5> : <></>}
                                            {col.value !== undefined ?
                                                <small className="text-muted">
                                                    {value}
                                                </small> : <></>}
                                        </div>
                                        {col.title ? <p className="mb-1">{col.title}</p> : <></>}
                                        {col.subtitle ? <small>{col.subtitle}</small> : <></>}
                                    </div>);
                            })}
                        </div>;

                    renderBody = isValor ? <MostraValor>{renderBody}</MostraValor> : renderBody;

                    break;

                case "table":

                    renderBody =
                        <div className="overflow-auto" style={{ maxHeight: (this.props.expandir !== undefined && Boolean(this.props.expandir) ? 400 : 220) + "px", minHeight: "150px" }}>
                            <table className="table table-striped table-sm fs-5">
                                <thead>
                                    <tr>
                                        {this.state.model?.dsConfiguracao?.tableHeader?.map((colHeader, indexColHeader) => (
                                            <th key={indexColHeader} className={`col ps-2 pe-2 ${colHeader.className !== undefined ? colHeader.className : ""}`}>{colHeader.label}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.content?.map((colBody, indexColBody) => (
                                        <tr key={indexColBody}>
                                            {this.state.model.dsConfiguracao?.tableHeader.map((colHeader, indexColHeader) => (
                                                <td key={indexColHeader} className={`col ps-2 pe-2 pt-0 pb-0 ${colHeader.className !== undefined ? colHeader.className : ""}`}>
                                                    {colHeader.fnFormat !== undefined ? this.fnFormat(colHeader.fnFormat, colBody[colHeader.value]) : colBody[colHeader.value]}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>;

                    msgOculto = (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "220px", minHeight: "150px" }}>
                            <div className="text-danger fw-bold fs-2">OCULTO</div>
                        </div>);
                    renderBody = isValor ? <MostraValor value={msgOculto}>{renderBody}</MostraValor> : renderBody;

                    break;

                case "grafico":

                    renderBody =
                        <Chart
                            chartLanguage="pt-br"
                            chartType={this.state.model.cdModelo}
                            width="100%"
                            height={`${this.props.expandir !== undefined && Boolean(this.props.expandir) ? "100%" : "250px"}`}
                            data={this.state.model.content}
                            options={this.state.model.dsConfiguracao}
                            formatters={this.state.model.dsConfiguracao?.formatters !== undefined ? this.state.model.dsConfiguracao.formatters : []}
                        />;

                    msgOculto = (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "250px", minHeight: "150px" }}>
                            <div className="text-danger fw-bold fs-2">OCULTO</div>
                        </div>);
                    renderBody = isValor ? <MostraValor value={msgOculto}>{renderBody}</MostraValor> : renderBody;

                    break;

                case "iframe":
                    renderBody =
                        <iframe
                            title={this.state.model.dsTitulo}
                            width="100%"
                            height={`${this.props.expandir !== undefined && Boolean(this.props.expandir) ? "100%" : "550px"}`}
                            src={this.state.model.dsUrl}></iframe>;
                    break;

                default:
                    renderBody = <></>;
            }

            return (
                <div className={`${this.props.expandir !== undefined && Boolean(this.props.expandir) ? "col-lg-12 h-100" : "col-lg-" + this.state.model?.nrTamanhoColuna}`}>
                    <div className="card shadow-lg h-100 border-dark">
                        <div className={`card-header d-flex flex-nowrap pt-0 pb-0 border-dark ${this.state.model.flErro !== undefined && Boolean(this.state.model.flErro) ? "text-bg-danger" : "text-bg-light"}`}>
                            {this.state.model.flAtualizando !== undefined && Boolean(this.state.model.flAtualizando) ?
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">...</span>
                                </div> : <></>
                            }
                            {!(this.props.play === undefined || Boolean(this.props?.play)) ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pause-fill" viewBox="0 0 16 16">
                                    <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
                                </svg>
                                : <></>
                            }
                            {this.state.model.dsCabecalho}

                            <div className="d-flex ms-auto me-0">

                                {this.state.model.cdTipo === "grafico" ? 
                                <>

                                    <BtnButton className="pb-0 pt-0 ps-0 btn-link btn-sm text-danger" onClick={e=> this.handleClickView("PDF")}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                            <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                            <path fillRule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                        </svg>
                                    </BtnButton>

                                    <BtnButton className="pb-0 pt-0 ps-0 btn-link btn-sm text-success" onClick={e=> this.handleClickView("XLS")}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-spreadsheet-fill" viewBox="0 0 16 16">
                                            <path d="M6 12v-2h3v2H6z"/>
                                            <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM3 9h10v1h-3v2h3v1h-3v2H9v-2H6v2H5v-2H3v-1h2v-2H3V9z"/>
                                        </svg>
                                    </BtnButton>
                                </>
                                : <></>}

                                {Permissao.getInstance().getPermissao(96) ? 
                                    <>
                                        <BtnButton className="pb-0 pt-0 ps-0 btn-link btn-sm text-dark" onClick={e => this.getBloco()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                            </svg>
                                        </BtnButton>

                                        <BtnButton className="pb-0 pt-0 ps-0 btn-link btn-sm text-dark" onClick={e => this.handleClickEditBloco()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </BtnButton>

                                        <BtnButton className="pb-0 pt-0 ps-0 btn-link text-danger btn-sm" onClick={e => this.handleClickDeleteBloco()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </BtnButton>
                                    </>

                                : <></>}

                                {!Boolean(this.props.expandir) ?
                                    <BtnButton className="pb-0 pt-0 ps-0 pe-0 btn-sm btn-link text-dark" onClick={this.handleClickExpandirBloco}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-arrows-fullscreen" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z" />
                                        </svg>
                                    </BtnButton>
                                    : <></>}
                            </div>
                        </div>
                        <div className="card-body p-0" align="center">
                            <form ref={ref => this.state.refForm = ref} onSubmit={this.handleSubmit} noValidate className={`pb-3 needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                                { Boolean(this.state.model.cdTipoFiltro === "CD") && Boolean(this.props?.filterable)? 
                                    <div className="row ps-3 pe-3" align="left">
                                        <InputDate md={6} sizing="sm" label="Data" value={this.state.model.vlStart} name="vlStart" onChange={(e) => { this.handleChange(e, this.submitForm) }} required={true} />                                    
                                    </div>
                                :<></>}
                                { Boolean(this.state.model.cdTipoFiltro === "CM") && Boolean(this.props?.filterable)? 
                                    <div className="row ps-3 pe-3" align="left">
                                        <InputMonth md={6} sizing="sm" label="Mês" value={this.state.model.vlStart} name="vlStart" onChange={(e) => { this.handleChange(e, this.submitForm) }} required={true} />
                                    </div>
                                :<></>}
                                { Boolean(this.state.model.cdTipoFiltro === "CA") && Boolean(this.props?.filterable)? 
                                    <div className="row ps-3 pe-3" align="left">
                                        <InputNumber md={6} sizing="sm" label="Ano" value={this.state.model.vlStart} name="vlStart" onChange={(e) => { this.handleChange(e, this.submitForm) }} required={true} />                                    
                                    </div>
                                :<></>}

                                { Boolean(this.state.model.cdTipoFiltro === "ID") && Boolean(this.props?.filterable)? 
                                    <div className="row ps-3 pe-3" align="left">
                                        <InputDate md={6} sizing="sm" label="Data Inicial" value={this.state.model.vlStart} name="vlStart" onChange={(e) => { this.handleChange(e, this.submitForm) }} required={true} />
                                        <InputDate md={6} sizing="sm" label="Data Final" value={this.state.model.vlEnd} name="vlEnd" onChange={(e) => { this.handleChange(e, this.submitForm) }} min={this.state.model.vlStart} required={true} />
                                    </div>
                                :<></>}
                                { Boolean(this.state.model.cdTipoFiltro === "IM") && Boolean(this.props?.filterable)? 
                                    <div className="row ps-3 pe-3" align="left">
                                        <InputMonth md={6} sizing="sm" label="Mês Inicial" value={this.state.model.vlStart} name="vlStart" onChange={(e) => { this.handleChange(e, this.submitForm) }} required={true} />
                                        <InputMonth md={6} sizing="sm" label="Mês Final" value={this.state.model.vlEnd} name="vlEnd" onChange={(e) => { this.handleChange(e, this.submitForm) }} min={this.state.model.vlStart} required={true} />
                                    </div>
                                :<></>}
                                { Boolean(this.state.model.cdTipoFiltro === "IA") && Boolean(this.props?.filterable)? 
                                    <div className="row ps-3 pe-3" align="left">
                                        <InputNumber md={6} sizing="sm" label="Ano Inicial" value={this.state.model.vlStart} name="vlStart" onChange={(e) => { this.handleChange(e, this.submitForm) }} required={true} />
                                        <InputNumber md={6} sizing="sm" label="Ano Final" value={this.state.model.vlEnd} name="vlEnd" onChange={(e) => { this.handleChange(e, this.submitForm) }} min={this.state.model.vlStart} required={true} />
                                    </div>
                                :<></>}
                                { Boolean(this.state.model.cdTipoFiltro === "ND") && Boolean(this.props?.filterable)? 
                                    <div className="row ps-3 pe-3" align="left">
                                        <Select md={12} sizing="sm" className="w-100" name="vlStart" value={this.state.model.vlStart} label="Número de Dias" options={this.state.filtroNrDia} onChange={(e) => { this.handleChange(e, this.submitForm) }} required={true} />
                                    </div>
                                :<></>}

                                { Boolean(this.state.model.cdTipoFiltro === "N") && Boolean(this.props?.filterable)? 
                                    <div className="alert alert-warning pt-1 pb-1" role="alert">
                                        Nenhum filtro configurado!
                                    </div>
                                :<></>}
                            </form>
                            {renderTitle}
                            {renderBody}
                        </div>

                        {renderFooter}

                        <div className="fs-9 text-secondary text-center">
                            {this.state.model.dataAtualizacao !== undefined ?
                                "atualizado em " +
                                this.state.model.dataAtualizacao.getDate() + "/" + (this.state.model.dataAtualizacao.getMonth() + 1) + "/" + this.state.model.dataAtualizacao.getFullYear() + " " +
                                this.state.model.dataAtualizacao.getHours() + ":" + this.state.model.dataAtualizacao.getMinutes()
                                : ""}
                        </div>
                    </div>
                </div>);
        } else {
            return (<></>);
        }
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <Bloco {...props} navigate={navigate} params={params} location={location} />
}

export default With