import { Component } from "react";
import Util from "../../../utils/Util";
import FormMask from "../base/FormMask";

export default class InputCpf extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleChange(event) {
        if (event.target.value && !Util.CPFIsValid(event.target.value)) {
            event.target.setCustomValidity("CPF Inválido!");
        } else {
            event.target.setCustomValidity("");
        }

        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        return (
            <FormMask {...this.props}
                onChange={this.handleChange}
                mask="999.999.999-99"
                pattern="\d{3}.\d{3}.\d{3}-\d{2}"
                placeholder="999.999.999-99"
            />);
    }
}