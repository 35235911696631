import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import TipoProdutoServicoService from "../../services/TipoProdutoServicoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import ArquivoService from "../../services/ArquivoService";
import InputFile from "../../components/forms/elements/InputFile";
import Config from "../../Config";
import CategoriaProdutoServicoService from "../../services/CategoriaProdutoServicoService";
import SelectCategoriaProdutoServico from "../../components/app/SelectCategoriaProdutoServico";
import BtnButton from "../../components/forms/elements/BtnButton";

class FormTipoProdutoServico extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeTipoProdutoServicos = this.handleChangeTipoProdutoServicos.bind(this);
        this.handleChangeTipoProdutoServicosAll = this.handleChangeTipoProdutoServicosAll.bind(this);
        this.handleChangeCdTipo = this.handleChangeCdTipo.bind(this);

        this.handleClickUpItem = this.handleClickUpItem.bind(this);
        this.handleClickDownItem = this.handleClickDownItem.bind(this);

        this.state.model = {
            idTipoProdutoServico: "",
            idCategoriaProdutoServico: "",
            nome: "",
            flDadosVeiculo: false,
            flDadosVeiculoPlaca: false,            
            flDadosEndereco: false,
            flFaturaAutomatica: false,
            flRemap: false,
            flRanking: true,
            flPermiteOutroTipo : false,
            cdTipo: "P",
            tipoProdutoServicoArquivo: [],
            tipoProdutoServicoLiberacao : []
        };

        this.state.crud = {
            service: TipoProdutoServicoService,
            labels: {
                title: 'Tipo de Produto / Serviço',
            },
            urlList: '/LstTipoProdutoServico',
        };

        this.state.tipos = [
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ];

        this.state.tipoProdutoServicos = [];
        this.state.categoriaProdutoServicos = [];

        this.state.isCard = true;
    }

    componentDidMount() {
        super.componentDidMount();

        CategoriaProdutoServicoService.list((response) => {
            this.setState({ categoriaProdutoServicos: response });
        });

        TipoProdutoServicoService.list((response) => {
            this.setState({ tipoProdutoServicos: response });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeTipoProdutoServicos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.tipoProdutoServicoLiberacao.push({
                    idTipoProdutoServicoPermitido : item.idTipoProdutoServico
                });
                return state;
            });
        } else {
            var index = this.state.model.tipoProdutoServicoLiberacao.findIndex(i => parseInt(i.idTipoProdutoServicoPermitido) === parseInt(item.idTipoProdutoServico));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.tipoProdutoServicoLiberacao.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeTipoProdutoServicosAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.tipoProdutoServicoLiberacao = [];

                this.state.tipoProdutoServicos.forEach(function (item) {
                    state.model.tipoProdutoServicoLiberacao.push({
                        idTipoProdutoServicoPermitido : item.idTipoProdutoServico
                    });
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.tipoProdutoServicoLiberacao = [];
                return state;
            });
        }
    }

    handleFileChange(event) {
        var files = event.target?.files;
        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                ArquivoService.upload(files[i], (response) => {
                    this.setState((state) => {
                        state.model.tipoProdutoServicoArquivo.push(response.data);
                        return state;
                    });
                });
            }
        }
    }

    handleDeleteItemArquivo(index, item) {
        this.setState((state) => {
            state.model.tipoProdutoServicoArquivo.splice(index, 1);
            return state;
        });
    }

    handleClickUpItem(index) {
        this.setState(state => {            
            var item1 = this.state.model.tipoProdutoServicoArquivo[index];
            var item2 = this.state.model.tipoProdutoServicoArquivo[index - 1];

            this.state.model.tipoProdutoServicoArquivo[index] = item2;
            this.state.model.tipoProdutoServicoArquivo[index - 1] = item1;

            return state;
        })
    }

    handleClickDownItem(index) {
        this.setState(state => {
            var item1 = this.state.model.tipoProdutoServicoArquivo[index];
            var item2 = this.state.model.tipoProdutoServicoArquivo[index + 1];

            this.state.model.tipoProdutoServicoArquivo[index] = item2;
            this.state.model.tipoProdutoServicoArquivo[index + 1] = item1;

            return state;
        })
    }

    handleChangeCdTipo() {
        this.setState((state) => {
            state.model.tipoProdutoServicoLiberacao = [];
            return state;
        });
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-dados-tab" data-bs-toggle="tab" data-bs-target="#nav-dados" type="button" role="tab" aria-controls="nav-dados" aria-selected="true">
                        Dados do Tipo
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-fotos-tab" data-bs-toggle="tab" data-bs-target="#nav-fotos" type="button" role="tab" aria-controls="nav-fotos" aria-selected="false">
                        Fotos
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-liberacao-tab" data-bs-toggle="tab" data-bs-target="#nav-liberacao" type="button" role="tab" aria-controls="nav-liberacao" aria-selected="false">
                        Liberação
                    </button>
                </li>
            </ul>
        );
    }

    getTipoByCategoria(idCategoriaProdutoServico) {
        return this.state.tipoProdutoServicos.filter(i => parseInt(idCategoriaProdutoServico) === parseInt(i.idCategoriaProdutoServico) && this.state.model.cdTipo === i.cdTipo && parseInt(this.state.model.idTipoProdutoServico) !== parseInt(i.idTipoProdutoServico));
    }


    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">
                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={3} value={this.state.model.idTipoProdutoServico} name="idTipoProdutoServico" readOnly opcionalLabel={false} />
                        <BtnGroup required={true} md={4} sizing="sm" className="w-100" name="cdTipo" value={this.state.model.cdTipo} label="Tipo do Produto / Serviço" options={this.state.tipos} onChange={e => this.handleChange(e, this.handleChangeCdTipo)} />
                    </div>
                    <div className="row mb-3 g-3">
                        <InputText label="Nome do Tipo de Produto / Serviço" autoFocus={true} required md={6} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                        <SelectCategoriaProdutoServico required={true} md={6} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={(e) => { this.handleChange(e) }} />
                    </div>
                    <h6 className="border-secondary border-bottom pb-2"> Na Ordem: </h6>
                    <div className="row mb-3 g-1">
                        <InputCheckSwitch sizing="md" label="Necessário Dados do Veículo?" name="flDadosVeiculo" checked={Boolean(this.state.model.flDadosVeiculo)} onChange={this.handleChange} />
                        {Boolean(this.state.model.flDadosVeiculo) ?
                            <InputCheckSwitch sizing="md" label="Necessário Dados da Placa do Veículo?" name="flDadosVeiculoPlaca" checked={Boolean(this.state.model.flDadosVeiculoPlaca)} onChange={this.handleChange} />
                            : <></>}                        
                        <InputCheckSwitch sizing="md" label="Necessário Dados de Endereço?" name="flDadosEndereco" checked={Boolean(this.state.model.flDadosEndereco)} onChange={this.handleChange} />
                        <InputCheckSwitch sizing="md" label="Faturamento Automático?" name="flFaturaAutomatica" checked={Boolean(this.state.model.flFaturaAutomatica)} onChange={this.handleChange} />
                        <InputCheckSwitch sizing="md" label="É Remap?" name="flRemap" checked={Boolean(this.state.model.flRemap)} onChange={this.handleChange} />
                        <InputCheckSwitch sizing="md" label="Aparece no Ranking?" name="flRanking" checked={Boolean(this.state.model.flRanking)} onChange={this.handleChange} />
                        <InputCheckSwitch sizing="md" label="Permite Outros Tipos de Produtos na Ordem?" name="flPermiteOutroTipo" checked={Boolean(this.state.model.flPermiteOutroTipo)} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-fotos" role="tabpanel" aria-labelledby="nav-fotos-tab">
                    <div className="row mb-3 g-3">
                        <InputFile label="Selecione o arquivo" name="arquivo" multiple onChange={(event) => { this.handleFileChange(event) }} accept="image/*" />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col-10"> Imagem </th>
                                        <th className="col-2 text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.tipoProdutoServicoArquivo.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td>
                                                <img style={{ maxHeight: "150px" }} src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uid} className="img-fluid img-thumbnail" alt="..." />
                                            </td>
                                            <td className="align-middle text-center text-nowrap">
                                                <BtnButton onClick={e => this.handleClickUpItem(index)} className="btn-primary btn-lateral rounded-pill btn-sm" disabled={index === 0}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                                    </svg>
                                                </BtnButton>
                                                <BtnButton onClick={e => this.handleClickDownItem(index)} className="btn-primary btn-lateral rounded-pill btn-sm ms-1" disabled={(index === (this.state.model.tipoProdutoServicoArquivo.length -1))}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </BtnButton>
                                                <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteItemArquivo(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg> Excluir Imagem
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-liberacao" role="tabpanel" aria-labelledby="nav-liberacao-tab">
                    <h6> Abaixo, os tipos liberados que podem receber os produtos do tipo <strong>{this.state.model.nome}</strong> na ordem!</h6>

                    <div className="mb-3 row">
                        {this.state.categoriaProdutoServicos.map((categoriaProdutoServico, indexCategoriaProdutoServico) => {
                            var itens = this.getTipoByCategoria(categoriaProdutoServico.idCategoriaProdutoServico);

                            if(itens.length > 0 ){
                                return (
                                    <div className="col-md-6 mt-2 pe-3" key={indexCategoriaProdutoServico}>
                                        <div className="card" key={categoriaProdutoServico.idCategoriaProdutoServico}>
                                            <div className="card-header fw-bold">
                                                {categoriaProdutoServico.nome}
                                            </div>
                                            <div className="card-body">
                                                {this.getTipoByCategoria(categoriaProdutoServico.idCategoriaProdutoServico).map((item) => (
                                                    <div key={item.idTipoProdutoServico}>
                                                        <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.tipoProdutoServicoLiberacao.findIndex(i => parseInt(i.idTipoProdutoServicoPermitido) === parseInt(item.idTipoProdutoServico)) !== -1} onChange={(e) => { this.handleChangeTipoProdutoServicos(e, item) }} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>);
                            }else{
                                return <React.Fragment key={indexCategoriaProdutoServico}></React.Fragment>
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormTipoProdutoServico {...props} navigate={navigate} params={params} />
}

export default With