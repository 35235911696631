import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectCategoriaProdutoServico from "../../components/app/SelectCategoriaProdutoServico";
import InputValorReais from "../../components/forms/custom/InputValorReais";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import SelectUnidadeMedida from "../../components/app/SelectUnidadeMedida";
import InputFile from "../../components/forms/elements/InputFile";
import ArquivoService from "../../services/ArquivoService";
import Config from "../../Config";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import InputDate from "../../components/forms/elements/InputDate";
import InputQuantidade from "../../components/forms/custom/InputQuantidade";
import ProdutoServicoClassificacaoService from "../../services/ProdutoServicoClassificacaoService";
import Util from "../../utils/Util";
import FormTreeView from "../../components/forms/base/FormTreeView";
import OrdemTipoService from "../../services/OrdemTipoService";
import SelectOrdemTipo from "../../components/app/SelectOrdemTipo";
import InputPeso from "../../components/forms/custom/InputPeso";
import TextArea from "../../components/forms/elements/TextArea";
import FormFotoCopiar from "./FormFotoCopiar";
import EventBus from "../../controllers/EventBus";
import SelectPromocaoCampanha from "../../components/app/SelectPromocaoCampanha";
import InputNumber from "../../components/forms/elements/InputNumber";
import SelectProdutoServicoTipoObjeto from "../../components/app/SelectProdutoServicoTipoObjeto";
import ProdutoServicoTipoObjetoService from "../../services/ProdutoServicoTipoObjetoService";
import UnidadeMedidaService from "../../services/UnidadeMedidaService";
import BtnButton from "../../components/forms/elements/BtnButton";

class FormProdutoServico extends CrudForm {

    constructor(props) {
        super(props);

        this.handleDeleteItemArquivo = this.handleDeleteItemArquivo.bind(this);
        this.handleReplicarItemArquivo = this.handleReplicarItemArquivo.bind(this);
        this.getListaProdutoServicoClassificacao = this.getListaProdutoServicoClassificacao.bind(this);
        this.getListaOrdemTipo = this.getListaOrdemTipo.bind(this);

        this.handleChangeUnidadeMedida = this.handleChangeUnidadeMedida.bind(this);
        this.handleAddItemPromocao = this.handleAddItemPromocao.bind(this);
        this.handleDeleteItemPromocao = this.handleDeleteItemPromocao.bind(this);
        this.handleChangeEmbalagem = this.handleChangeEmbalagem.bind(this);
        this.handleChangeTipoObjeto = this.handleChangeTipoObjeto.bind(this);

        this.handleClickUpItem = this.handleClickUpItem.bind(this);
        this.handleClickDownItem = this.handleClickDownItem.bind(this);

        this.state.isCard = true;
        this.state.container = false;

        this.state.model = {
            idProdutoServico: "",
            nome: "",
            dsEspecificacao: "",
            dsObservacaoCompra: "",
            idTipoProdutoServico: "",
            idCategoriaProdutoServico: "",
            idUnidadeMedida: "",
            flAtivo: true,
            flAgrupador: false,
            flControlaEstoque: false,
            flControlaEstoqueMinimo: false,
            flEstoqueNegativo: false,
            flSuporte: false,
            idProdutoServicoClassificacao: "",
            produtoServicoArquivo: [],
            produtoServicoPromocao: [],
            produtoServicoPreco: [],
            tipoProdutoServico: {},
            vlPesoBruto: 0,
            vlPesoLiquido: 0,
            vlLargura: 0,
            vlAltura: 0,
            vlComprimento: 0,
            vlDiametro: 0,
            vlEstoqueMinimo: 0,
            nrNcm: "",
            flNotaFiscal: true,
            flRanking: true,
            flEmbalagem: false,
            flPrecoRevenda: false,            
            idProdutoServicoTipoObjeto: "",

            flEcommerceTray: false,            
            cdReferenciaEcommerceTray : "",
        };

        this.state.produtoServicoTipoObjeto = {
            flLargura: false,
            flAltura: false,
            flComprimento: false,
            flDiametro: false
        };

        this.state.unidadeMedida = {
            nrCasasDecimais: 0,
            sigla: ""
        };

        this.state.produtoServicoClassificacao = [];
        this.state.ordemTipo = [];

        this.state.crud = {
            service: ProdutoServicoService,
            keyName: "idProdutoServico",
            labels: {
                title: 'Produto / Serviço',
            },
            urlList: '/LstProdutoServico',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        this.getListaProdutoServicoClassificacao();
        this.getListaOrdemTipo();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onEdit(id) {
        super.onEdit(id, () => {

        });
    }

    handleChangeUnidadeMedida(event) {        
        if (this.state.model.idUnidadeMedida) {
            UnidadeMedidaService.getOneById(this.state.model.idUnidadeMedida, (response) => {
                this.setState((state) => {
                    state.unidadeMedida = response;
                    return state;
                });
            });
        }
    }

    getPrecoByOrdemTipo(idOrdemTipo) {
        var item = this.state.model.produtoServicoPreco.find(item => parseInt(item.idOrdemTipo) === parseInt(idOrdemTipo));
        return item && item.vlPreco ? item.vlPreco : 0;
    }

    getListaProdutoServicoClassificacao() {
        ProdutoServicoClassificacaoService.listForTree((response) => {
            this.setState({ produtoServicoClassificacao: Util.changePropertyRecursive(response, "opened", true, "options") });
        });
    }

    getListaOrdemTipo() {
        if (!this.props.params?.id) {
            OrdemTipoService.getForCombo((response) => {
                this.setState((state) => {
                    response.forEach(item => {
                        state.model.produtoServicoPreco.push({
                            idOrdemTipo: item.value,
                            nomeOrdemTipo: item.label,
                            vlPreco: 0,
                            flAtivo: false
                        });
                        return state;
                    });
                });
            });
        }
    }

    handleFileChange(event) {
        var files = event.target?.files;
        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                ArquivoService.upload(files[i], (response) => {
                    this.setState((state) => {
                        state.model.produtoServicoArquivo.push(response.data);
                        return state;
                    });
                });
            }
        }
    }

    handleDeleteItemArquivo(index, item) {
        this.setState((state) => {
            state.model.produtoServicoArquivo.splice(index, 1);
            return state;
        });
    }

    handleClickUpItem(index) {
        this.setState(state => {            
            var item1 = this.state.model.produtoServicoArquivo[index];
            var item2 = this.state.model.produtoServicoArquivo[index - 1];

            this.state.model.produtoServicoArquivo[index] = item2;
            this.state.model.produtoServicoArquivo[index - 1] = item1;

            return state;
        })
    }

    handleClickDownItem(index) {
        this.setState(state => {
            var item1 = this.state.model.produtoServicoArquivo[index];
            var item2 = this.state.model.produtoServicoArquivo[index + 1];

            this.state.model.produtoServicoArquivo[index] = item2;
            this.state.model.produtoServicoArquivo[index + 1] = item1;

            return state;
        })
    }

    handleReplicarItemArquivo(index, item) {

        EventBus.dispatch("modal-add", {
            title: 'Replicar Foto do Produto / Serviço',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idArquivo: item.idArquivo,
                idProdutoServico: this.state.model.idProdutoServico,
            },
            form: FormFotoCopiar,
            onSave: (response) => {

            },
            onClose: () => {
            }
        });

        /*this.setState((state) => {
            state.model.produtoServicoArquivo.splice(index, 1);
            return state;
        });*/
    }

    handleAddItemPromocao() {
        this.setState((state) => {
            state.model.produtoServicoPromocao.push({
                idOrdemTipo: "",
                idPromocaoCampanha: "",
                dtInicial: "",
                dtFinal: "",
                nrQuantidadeInicial: 0,
                nrQuantidadeFinal: 0,
                vlDesconto: 0,
                flSemDataFinal: false,
                flSemNrQuantidadeFinal: false
            });
            return state;
        });
    }

    handleDeleteItemPromocao(index, item) {
        this.setState((state) => {
            state.model.produtoServicoPromocao.splice(index, 1);
            return state;
        });
    }

    handleChangeEmbalagem() {
        this.setState((state) => {
            state.model.flNotaFiscal = this.state.model.flEmbalagem ? false : state.model.flNotaFiscal;
            return state;
        });
    }

    handleChangeTipoObjeto() {
        ProdutoServicoTipoObjetoService.getOneById(this.state.model.idProdutoServicoTipoObjeto, response => {
            this.setState((state) => {
                state.produtoServicoTipoObjeto = response;
                return state;
            });
        });
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-produto-tab" data-bs-toggle="tab" data-bs-target="#nav-produto" type="button" role="tab" aria-controls="nav-produto" aria-selected="true">
                        Produto
                    </button>
                </li>
                
                {!Boolean(this.state.model.flAgrupador) ? 
                    <li className="nav-item">
                        <button className="nav-link" id="nav-precos-tab" data-bs-toggle="tab" data-bs-target="#nav-precos" type="button" role="tab" aria-controls="nav-precos" aria-selected="false">
                            Preços
                        </button>
                    </li> : <></>}
                
                <li className="nav-item">
                    <button className="nav-link" id="nav-fotos-tab" data-bs-toggle="tab" data-bs-target="#nav-fotos" type="button" role="tab" aria-controls="nav-fotos" aria-selected="false">
                        Fotos
                    </button>
                </li>
                
                {!Boolean(this.state.model.flAgrupador) ? 
                <li className="nav-item">
                    <button className="nav-link" id="nav-promocao-tab" data-bs-toggle="tab" data-bs-target="#nav-promocao" type="button" role="tab" aria-controls="nav-promocao" aria-selected="false">
                        Promoções
                    </button>
                </li> : <></>}

                {!Boolean(this.state.model.flAgrupador) ? 
                <li className="nav-item">
                    <button className="nav-link" id="nav-classificacao-tab" data-bs-toggle="tab" data-bs-target="#nav-classificacao" type="button" role="tab" aria-controls="nav-classificacao" aria-selected="false">
                        Classificação
                    </button>
                </li> : <></>}

                {(this.state.model.flEcommerceTray) ? 
                <li className="nav-item">
                    <button className="nav-link" id="nav-ecommerce-tray-tab" data-bs-toggle="tab" data-bs-target="#nav-ecommerce-tray" type="button" role="tab" aria-controls="nav-ecommerce-tray" aria-selected="false">
                        Ecommerce Tray
                    </button>
                </li> : <></>}
                
            </ul>
        );
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-produto" role="tabpanel" aria-labelledby="nav-produto-tab">
                    
                    {(!this.state.model.idProdutoServico) ?
                        <div className="row mx-1 mb-3">
                            <InputCheckSwitch label="Esse item é um agrupador?" name="flAgrupador" checked={Boolean(this.state.model.flAgrupador)} onChange={this.handleChange} />
                        </div>
                    : <></>}

                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={3} value={this.state.model.idProdutoServico} name="idProdutoServico" readOnly opcionalLabel={false} />
                        <InputText label="Nome do Produto / Serviço" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={200} />
                        <TextArea label="Especificações" md={12} value={this.state.model.dsEspecificacao} name="dsEspecificacao" onChange={this.handleChange} />
                        <TextArea label="Observações de Compra" md={12} value={this.state.model.dsObservacaoCompra} name="dsObservacaoCompra" onChange={this.handleChange} />
                        <SelectCategoriaProdutoServico md={4} label="Categoria de Produto" required placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={this.handleChange} />
                        <SelectTipoProdutoServicoByCategoria idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} md={5} label="Tipo de Produto" required placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={this.handleChange} />
                        <SelectUnidadeMedida md={3} label="Unidade de Medida" required placeholder="Selecione a Unidade de Medida" name="idUnidadeMedida" value={this.state.model.idUnidadeMedida} onChange={(e) => { this.handleChange(e, this.handleChangeUnidadeMedida) }} />
                    </div>

                    {!Boolean(this.state.model.flAgrupador) ? 
                        <div className="row mb-3 g-3">
                            <InputPeso className="text-end" md={2} label='Peso Bruto em KG' value={this.state.model.vlPesoBruto} name="vlPesoBruto" onChange={this.handleChange} />
                            <InputPeso className="text-end" md={2} label='Peso Líquido em KG' value={this.state.model.vlPesoLiquido} name="vlPesoLiquido" onChange={this.handleChange} />

                            <InputNumber md={2} label='Largura (cm)' value={this.state.model.vlLargura} name="vlLargura" onChange={this.handleChange} required={this.state.produtoServicoTipoObjeto.flLargura} />
                            <InputNumber md={2} label='Altura (cm)' value={this.state.model.vlAltura} name="vlAltura" onChange={this.handleChange} required={this.state.produtoServicoTipoObjeto.flAltura} />
                            <InputNumber md={2} label='Comprimento (cm)' value={this.state.model.vlComprimento} name="vlComprimento" onChange={this.handleChange} required={this.state.produtoServicoTipoObjeto.flComprimento} />
                            <InputNumber md={2} label='Diâmetro (cm)' value={this.state.model.vlDiametro} name="vlDiametro" onChange={this.handleChange} required={this.state.produtoServicoTipoObjeto.flDiametro} />

                            <InputText label="Classificação Fiscal" md={2} value={this.state.model.nrNcm} name="nrNcm" onChange={this.handleChange} maxLength={100} />
                            {Boolean(this.state.model.flEmbalagem) ?
                                <SelectProdutoServicoTipoObjeto required={true} md={6} label="Tipo da Objeto" placeholder="Selecione o Tipo de Objeto" name="idProdutoServicoTipoObjeto" value={this.state.model.idProdutoServicoTipoObjeto} onChange={e => this.handleChange(e, this.handleChangeTipoObjeto)} />
                                : <></>}

                            {Boolean(this.state.model.flControlaEstoque) ?
                                <InputQuantidade required label="Estoque Mínimo" md={2} className="text-center" placeholder="Digite o estoque mínimo" value={this.state.model.vlEstoqueMinimo} name="vlEstoqueMinimo" onChange={this.handleChange} decimalScale={this.state.unidadeMedida.nrCasasDecimais} unidadeMedida={this.state.unidadeMedida.sigla} />
                                : <></>}
                        </div>
                    : <></>}

                    <div className="row mx-1 mb-3">
                        <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />                        
                        
                        {!Boolean(this.state.model.flAgrupador) ? 
                        <>
                            <InputCheckSwitch label="Controla Estoque?" name="flControlaEstoque" checked={Boolean(this.state.model.flControlaEstoque)} onChange={this.handleChange} />
                            {Boolean(this.state.model.flControlaEstoque) ?
                                <InputCheckSwitch label="Controla Estoque Mínimo?" name="flControlaEstoqueMinimo" checked={Boolean(this.state.model.flControlaEstoqueMinimo)} onChange={this.handleChange} />
                                : <></>}
                            <InputCheckSwitch label="Estoque Negativo?" name="flEstoqueNegativo" checked={Boolean(this.state.model.flEstoqueNegativo)} onChange={this.handleChange} />
                            <InputCheckSwitch label="É suporte?" name="flSuporte" checked={Boolean(this.state.model.flSuporte)} onChange={this.handleChange} />
                            <InputCheckSwitch label="É uma Embalagem?" name="flEmbalagem" checked={Boolean(this.state.model.flEmbalagem)} onChange={e => (this.handleChange(e, this.handleChangeEmbalagem))} />
                            <InputCheckSwitch label="Emitir Nota Fiscal?" name="flNotaFiscal" disabled={this.state.model.flEmbalagem} checked={Boolean(this.state.model.flNotaFiscal)} onChange={this.handleChange} />
                            <InputCheckSwitch sizing="md" label="Aparece no Ranking?" name="flRanking" checked={Boolean(this.state.model.flRanking)} onChange={this.handleChange} />
                            <InputCheckSwitch label="Obrigatório Inserir Preço de Revenda na Ordem?" name="flPrecoRevenda" checked={Boolean(this.state.model.flPrecoRevenda)} onChange={this.handleChange} />
                            <InputCheckSwitch label="Disponível no Ecommerce da Tray?" name="flEcommerceTray" checked={Boolean(this.state.model.flEcommerceTray)} onChange={this.handleChange} />
                            </>
                        : <></>}
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-precos" role="tabpanel" aria-labelledby="nav-precos-tab">
                    {this.state.model.produtoServicoPreco.map((item, index) => (
                        <div key={index} className="row mb-3 g-3">
                            <InputText disabled={true} md={6} label="Destino da Ordem" value={item.nomeOrdemTipo} opcionalLabel={false} />
                            {item.flAtivo ?
                                <InputValorReais className="text-end" md={3} label={`Preço para ${item.nomeOrdemTipo}`} required={true} value={item.vlPreco} name="vlPreco" onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPreco[index]) }} />
                                : <></>}
                            <div className="col-md-3 pt-4">
                                <InputCheckSwitch label="Venda para esse tipo?" name="flAtivo" checked={Boolean(item.flAtivo)} onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPreco[index]) }} />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="tab-pane fade" id="nav-fotos" role="tabpanel" aria-labelledby="nav-fotos-tab">
                    <div className="row mb-3 g-3">
                        <InputFile label="Selecione o arquivo" name="arquivo" multiple onChange={(event) => { this.handleFileChange(event) }} accept="image/*" />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col-10"> Imagem </th>
                                        <th className="col-2 text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.produtoServicoArquivo.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td>
                                                <img style={{ maxHeight: "150px" }} src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uid} className="img-fluid img-thumbnail" alt="..." />
                                            </td>
                                            <td className="align-middle text-center text-nowrap">
                                                <BtnButton onClick={e => this.handleClickUpItem(index)} className="btn-primary btn-lateral rounded-pill btn-sm" disabled={index === 0}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                                    </svg>
                                                </BtnButton>
                                                <BtnButton onClick={e => this.handleClickDownItem(index)} className="btn-primary btn-lateral rounded-pill btn-sm ms-1" disabled={(index === (this.state.model.produtoServicoArquivo.length -1))}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </BtnButton>
                                                <button disabled={!item.idProdutoServicoArquivo} type="button" className="btn btn-sm ms-2 btn-outline-info" onClick={() => { this.handleReplicarItemArquivo(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                    </svg> Replicar Imagem
                                                </button>
                                                <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteItemArquivo(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg> Excluir Imagem
                                                </button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-promocao" role="tabpanel" aria-labelledby="nav-promocao-tab">

                    <div className="text-end mb-2">
                        <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddItemPromocao}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg> Adicionar Promoção
                        </button>
                    </div>

                    <ol className="list-group list-group-numbered">
                        {this.state.model.produtoServicoPromocao.map((item, index) => (
                            <li className={`list-group-item d-flex justify-content-between align-items-start ${index % 2 === 0 ? "bg-light " : ""}`} key={index}>
                                <div className="ms-2 w-100">

                                    <div className="fw-bold">
                                        {item.flAtivo ? <span className="badge bg-success">Ativo</span> : <></>}
                                        {item.flFuturo ? <span className="badge bg-primary">Futuro</span> : <></>}
                                        {item.flPassado ? <span className="badge bg-danger">Inativo</span> : <></>}
                                    </div>

                                    <div className="row g-3">
                                        <SelectPromocaoCampanha label="Campanha" md={5} required placeholder="Selecione a Campanha" name="idPromocaoCampanha" value={item.idPromocaoCampanha} onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPromocao[index]) }} />
                                        <SelectOrdemTipo label="Destino" md={5} showBtns={false} required placeholder="Selecione o Destino" name="idOrdemTipo" value={item.idOrdemTipo} onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPromocao[index]) }} />
                                        <InputValorReais md={2} label="Valor do Produto" className="text-end" value={this.getPrecoByOrdemTipo(item.idOrdemTipo)} opcionalLabel={false} disabled />

                                        <InputDate label="Data Inicial" md={2} required value={item.dtInicial} name="dtInicial" opcionalLabel={false} onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPromocao[index]) }} />
                                        <InputDate md={2} label="Data Final" value={Boolean(item.flSemDataFinal) ? "" : item.dtFinal} name="dtFinal" disabled={Boolean(item.flSemDataFinal)} required={!Boolean(item.flSemDataFinal)} opcionalLabel={false} onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPromocao[index]) }} />

                                        <InputQuantidade md={2} label="Quantidade Inicial" className="text-center" placeholder="Digite a quantidade" required value={item.nrQuantidadeInicial} name="nrQuantidadeInicial" onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPromocao[index]) }} decimalScale={this.state.unidadeMedida.nrCasasDecimais} unidadeMedida={this.state.unidadeMedida.sigla} />
                                        <InputQuantidade md={2} label="Quantidade Final" className="text-center" placeholder="Digite a quantidade" disabled={Boolean(item.flSemNrQuantidadeFinal)} required={!Boolean(item.flSemNrQuantidadeFinal)} value={Boolean(item.flSemNrQuantidadeFinal) ? "" : item.nrQuantidadeFinal} name="nrQuantidadeFinal" onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPromocao[index]) }} decimalScale={this.state.unidadeMedida.nrCasasDecimais} unidadeMedida={this.state.unidadeMedida.sigla} />

                                        <InputValorReais md={2} label="Valor do Desconto" className="text-end" required value={item.vlDesconto} name="vlDesconto" onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPromocao[index]) }} />

                                        <InputValorReais md={2} label="Valor com Desconto" className="text-end text-success fw-bold" value={this.getPrecoByOrdemTipo(item.idOrdemTipo) - item.vlDesconto} opcionalLabel={false} disabled />

                                        <div className={`row mx-2 mb-3 g-1`}>
                                            <InputCheckSwitch label="Sem Limite de Data Final" name="flSemDataFinal" checked={Boolean(item.flSemDataFinal)} onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPromocao[index]) }} />
                                            <InputCheckSwitch label="Sem Limite de Quantidade Final" name="flSemNrQuantidadeFinal" checked={Boolean(item.flSemNrQuantidadeFinal)} onChange={(e) => { this.handleChange(e, false, this.state.model.produtoServicoPromocao[index]) }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="d-none d-lg-table-cell">
                                    <div className="text-center">
                                        <button type="button" className="btn btn-link text-danger" onClick={() => { this.handleDeleteItemPromocao(index, item) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>
                <div className="tab-pane fade" id="nav-classificacao" role="tabpanel" aria-labelledby="nav-classificacao-tab">
                    <FormTreeView selectFolder={false} options={this.state.produtoServicoClassificacao} value={this.state.model.idProdutoServicoClassificacao} name="idProdutoServicoClassificacao" onChange={this.handleChange} />
                </div>

                <div className="tab-pane fade" id="nav-ecommerce-tray" role="tabpanel" aria-labelledby="nav-ecommerce-tray-tab">                    
                    <div className="row mb-3 g-3">
                        <InputText label="Código de Referência" md={3} value={this.state.model.cdReferenciaEcommerceTray} name="cdReferenciaEcommerceTray" onChange={this.handleChange} maxLength={100} />
                    </div>
                </div>
            </div >

        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormProdutoServico {...props} navigate={navigate} params={params} />
}

export default With