import Http from "../controllers/Http";

export default class RepresentanteService {

    static urlBase = "/representante/";

    static getForCombo(ativo, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forCombo/' + (ativo === 1 || ativo === 0 ? ativo : ""))
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboByUsuario(ativo, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboByUsuario/' + (ativo === 1 || ativo === 0 ? ativo : ""))
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboByProprietario(idProprietario, ativo, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboByProprietario/' + idProprietario + '/' + (ativo === 1 || ativo === 0 ? ativo : ""))
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboByProprietarioAndUsuario(idProprietario, ativo, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboByProprietarioAndUsuario/' + idProprietario + '/' + (ativo === 1 || ativo === 0 ? ativo : ""))
            .then(fnSucess)
            .catch(fnError);
    }

    static list(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase)
            .then(fnSucess)
            .catch(fnError);
    }

    static listForList(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'list', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static listByLocalizacao(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byLocalizacao', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByIdByUsuario(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byUsuario/' + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByLocalizacaoCidade(idCidade, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byLocalizacaoCidade/' + idCidade)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static update(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static setStatus(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'setStatus/' + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getCopyText(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'getTextCopy/' + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static save(data, fnSucess = () => { }, fnError = () => { }) {
        if (data.idRepresentante) {
            this.update(data.idRepresentante, data, fnSucess, fnError);
        } else {
            this.create(data, fnSucess, fnError);
        }
    }
}
