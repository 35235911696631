import React from "react";
import BancoService from "../../services/BancoService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaBanco extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: BancoService,
            labels: {
                title: 'Lista de Banco(s)',
                btnNewRegister: 'Banco',
            },
            keyItem: 'idBanco',
            urlForm: '/CadBanco',
            itensFilter: ["idBanco", "nome", "nrBanco"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-3"> Número </th>
                <th className="col-md-7"> Nome </th>
                
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idBanco}</td>
                <td>{item.nrBanco}</td>
                <td>{item.nome}</td>
            </>);
    }

    render() {
        return super.render();
    }
}