import EstadoService from "../../services/EstadoService";
import FormSelectSearchModal from "../forms/base/FormSelectSearchModal";
import FormEstado from "../../pages/Estado/FormEstado";
import Permissao from "../../controllers/Permissao";

export default class SelectEstadoByPais extends FormSelectSearchModal {

    constructor(props) {
        super(props);

        this.getOptions = this.getOptions.bind(this);
        this.receivEventDispach = this.receivEventDispach.bind(this);
        this.state.showBtns = this.props.showBtns === undefined ? true : this.props.showBtns;
        this.state.showBtnSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
        this.state.showBtnNewForm = this.state.showBtns && ((this.props.showBtnNewForm === undefined ? true : false) && Permissao.getInstance().getPermissao(5));
        this.state.showBtnNewSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);

        this.state.formNewConfig = {
            title: 'Cadastro de Estado',
            sizeModal: 'modal-xl',
            config: {
                isModal: true
            },
            form: FormEstado,
            onSave: (response) => {
                this.getOptions(() => {
                    if (response.data?.idEstado) {
                        this.handleSetValue(response.data?.idEstado);
                        this.setState({ value: response.data.idEstado }, () => {
                            this.handleOpenSearch();
                        });
                    } else {
                        this.handleOpenSearch();
                    }
                });
            },
            onClose: () => {
                //this.handleOpenSearch();
            }
        };
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.idPais !== this.props.idPais) {
            this.getOptions();
        }
    }

    componentDidMount() {

        super.componentDidMount();

        this.getOptions();

        if (this.dispachChangeDidMount && this.props.value) {
            this.handleChange({ target: { value: this.props.value, name: this.props.name } });
        }

        this.state.headers = [
            { key: "value", label: "Código", className: 'text-center d-none d-md-table-cell' },
            { key: "label", label: "Nome do Estado" },
            { key: "sigla", label: "Sigla", className: 'text-center' },
        ];
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getConfigFormNew() {
        return {
            title: 'Cadastro de Estado',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idPais: this.props.idPais
            },
            form: FormEstado,
            onSave: (response) => {
                this.getOptions(() => {
                    if (response.data?.idEstado) {
                        this.handleOpenSearch(null, response.data?.idEstado);
                    } else {
                        this.handleOpenSearch();
                    }
                });
            },
            onClose: () => {
                //this.handleOpenSearch();
            }
        };
    };


    /*receivEventDispach(event, data) {
        this.state.idPais = data.idPais;
        this.getOptions();
    }*/

    getOptions(callBack) {
        if (this.props.idPais) {
            EstadoService.getForComboByPais(this.props.idPais, (response) => {
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();
                    }
                });
            });
        } else {
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    render() {
        return super.render();
    }
}