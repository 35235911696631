import React from "react";
import ExtratoService from "../../services/ExtratoService";
import Util from "../../utils/Util";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import SelectConta from "../../components/app/SelectConta";
import InputDate from "../../components/forms/elements/InputDate";
import MyComponent from "../../components/custom/MyComponent";

export default class ListaExtrato extends MyComponent {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLista = this.getLista.bind(this);
        this.onClear = this.onClear.bind(this);
        this.getInitState = this.getInitState.bind(this);
        this.state = this.getInitState(props);
    }

    getInitState(props) {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;

        var dtFinal = new Date();
        dtFinal = new Date(dtFinal - tzoffset).toISOString().slice(0, 10);

        var dtInicial = new Date();
        dtInicial.setDate(dtInicial.getDate() - 30);
        dtInicial = new Date(dtInicial - tzoffset).toISOString().slice(0, 10);

        return {
            data: [],
            saldo: 0,
            model: {
                busca: "",
                idConta: "",
                cdOperacao: "T",
                dtInicial: dtInicial,
                dtFinal: dtFinal,
            },
            expand: true,
            filtroOperacao: [
                { value: "T", label: "Todos" },
                { value: "C", label: "Crédito" },
                { value: "D", label: "Débito" },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }


    getLista() {
        this.setState({ data: [] });
        ExtratoService.getExtrato(this.state.model, (response) => {
            this.setState({ data: response });
        });

        ExtratoService.getSaldo(this.state.model, (response) => {
            this.setState({ saldo: response.valor });
        });
    }

    onClear() {
        this.setState(this.getInitState(), () => {
            //this.getLista();
        });
    }

    onSubmit(event) {
        this.getLista();
    }


    getClassNameTipoOperacao(item) {
        if (item.tipoOperacao === "C") {
            return "text-success";
        } else if (item.tipoOperacao === "D") {
            return "text-danger";
        } else {
            return "text-secondary";
        }
    }

    render() {
        return (
            <div className="p-3 my-2 w-100">
                <div className="hstack gap-3 mb-3">
                    <h4 className="mb-3 w-100">Extrato da Conta Corrente</h4>
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row mb-3">
                        <SelectConta label="Conta" required={true} placeholder="Selecione a Conta" name="idConta" md={5} value={this.state.model.idConta} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} showBtns={false} />
                        <InputDate md={2} required={true} label="Data Inicial" value={this.state.model.dtInicial} name="dtInicial" onChange={this.handleChange} opcionalLabel={false} />
                        <InputDate md={2} required={true} label="Data Final" value={this.state.model.dtFinal} name="dtFinal" onChange={this.handleChange} min={this.state.model.dtInicial} opcionalLabel={false} />
                        <BtnGroup md={3} className="w-100" name="cdOperacao" value={this.state.model.cdOperacao} label="Status" options={this.state.filtroOperacao} onChange={this.handleChange} opcionalLabel={false} />
                    </div>
                    <div className="row mb-3">
                        <div className="col d-flex align-items-end bd-highlight">
                            <button className="ms-auto btn btn-outline-primary mx-2 text-nowrap" type="submit"> Buscar </button>
                            <button type="button" className="btn btn-outline-danger text-nowrap" onClick={this.onClear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar
                            </button>
                        </div>
                    </div>
                </form>

                <div className="table-responsive">
                    <table className="table table-sm table-hover caption-top">
                        <caption className="text-end">
                            {this.state.data.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th colSpan={7} className="col-md-1 text-end fs-6">
                                    SALDO: <span className={`badge fs-6 ${this.state.saldo < 0 ? 'text-bg-danger' : 'text-bg-success'}`}> {Util.floatToReais(this.state.saldo)} </span>
                                </th>
                            </tr>
                            <tr>
                                <th className="text-center"> Data </th>
                                <th className="col-md-2 text-center"> Método </th>
                                <th className="col-md-3"> Título </th>
                                <th className="col-md-7"> Descrição </th>
                                <th className="text-end text-nowrap"> Valor Transação </th>
                                <th className="text-end text-nowrap"> Valor Taxas </th>
                                <th className="text-end text-nowrap"> Valor Líquido </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.filter(f => {
                                if (this.state.model.cdOperacao !== "T") {
                                    return f.tipoOperacao === this.state.model.cdOperacao;
                                } else {
                                    return f;
                                }
                            }
                            ).map((item) => (
                                <tr key={item.idContato} className={this.getClassNameTipoOperacao(item)}>
                                    <td className="text-center">{Util.date2Br(item.dataEntrada)}</td>
                                    <td className="text-center">{item.metodo}</td>
                                    <td>{item.titulo}</td>
                                    <td>{item.descricao}</td>
                                    <td className="text-end text-nowrap">{Util.floatToReais(item.vlTransacao)}</td>
                                    <td className="text-end text-nowrap">{Util.floatToReais(item.vlTaxa)}</td>
                                    <td className="text-end fw-bold text-nowrap">{item.tipoOperacao === "D" ? "- " : ""}{Util.floatToReais(item.vlLiquido)}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div >
        )
    }
}