import React from "react";
import ParametroService from "../../services/ParametroService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaParametro extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ParametroService,
            labels: {
                title: 'Lista de Parâmetro(s)',
                btnNewRegister: 'Parametro',
            },
            keyItem: 'idParametro',
            urlForm: '/CadParametro',
            itensFilter: ["idParametro", "nome"],
            searchFormSubmit: false
        };

        this.state.container = false;

        this.state.cdTipos = [
            { value: "BOOLEAN", label: "Boleano", flSize: false, flJson: false },
            { value: "VARCHAR", label: "Resposta Curta", flSize: true, flJson: false },
            { value: "TEXT", label: "Parágrafo", flSize: true, flJson: false },
            { value: "INTEGER", label: "Número Inteiro", flSize: false, flJson: false },
            { value: "DECIMAL", label: "Número Decimal", flSize: true, flJson: false },
            { value: "DROP_DOWN", label: "Lista Suspensa", flSize: false, flJson: true },
            { value: "CHECKBOX", label: "Caixas de Seleção", flSize: false, flJson: true },
            { value: "DATE", label: "Data", flSize: false, flJson: false },
            { value: "TIME", label: "Hora", flSize: false, flJson: false }
        ];
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-4"> Nome </th>
                <th className="col-md-3"> Grupo do Módulo </th>
                <th className="col-md-3"> Módulo </th>
                <th className="col-md-2"> Tipo </th>
                <th className="text-center"> Tamanho </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idParametro}</td>
                <td>{item.nome}</td>
                <td>{item.nomeGrupoModulo}</td>
                <td>{item.nomeModulo}</td>
                <td>
                    {this.state.cdTipos.find(i => item.cdTipo === i.value)?.label}
                </td>
                <td className="text-center">
                    {this.state.cdTipos.find(i => item.cdTipo === i.value)?.flSize ? item.nrTamanho : ""}
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}