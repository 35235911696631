import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ParametroValorService from "../../services/ParametroValorService";
import CrudForm from "../../components/forms/custom/CrudForm";
import ParametroService from "../../services/ParametroService";
import InputNumber from "../../components/forms/elements/InputNumber";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputDecimal from "../../components/forms/elements/InputDecimal";
import TextArea from "../../components/forms/elements/TextArea";
import FormSelect from "../../components/forms/base/FormSelect";
import InputDate from "../../components/forms/elements/InputDate";
import InputTime from "../../components/forms/elements/InputTime";

class FormParametroValor extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeField = this.handleChangeField.bind(this);

        this.state.model = {
            idModulo: this.props.idModulo,
            cdChave: this.props.cdChave,
            valores: []
        };

        this.state.crud = {
            service: ParametroValorService,
            labels: {
                title: 'Parâmetro',
            },
            urlList: '/LstParametroValor',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        ParametroService.listByModulo(this.state.model.idModulo, this.state.model.cdChave, (response) => {
            this.setState(state => {
                state.model.valores = response;
                return state;
            });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeField(e, parametro, index, value = false) {
        switch (parametro.cdTipo) {
            case "BOOLEAN":
                this.setState(state => {
                    state.model.valores[index].dsValor = e.target.checked;
                    return state;
                });
                break;

            case "CHECKBOX":
                this.setState(state => {
                    var valores = state.model.valores[index].dsValor;
                    valores = valores ? valores : "[]";
                    valores = JSON.parse(valores);

                    if (e.target.checked) {
                        valores.push(value);
                    } else {
                        var indexOf = valores.findIndex(i => value === i);
                        if (indexOf !== -1) {
                            valores.splice(indexOf, 1);
                        }
                    }
                    state.model.valores[index].dsValor = JSON.stringify(valores);
                    return state;
                });
                break;

            default:
                this.setState(state => {
                    state.model.valores[index].dsValor = e.target.value;
                    return state;
                });
        }
    }

    getField(parametro, index) {
        switch (parametro.cdTipo) {
            case "VARCHAR":
                return <InputText required={true} md={9} label={parametro.nome} value={this.state.model.valores[index].dsValor ? this.state.model.valores[index].dsValor : ""} onChange={e => { this.handleChangeField(e, parametro, index) }} maxLength={parametro.nrTamanho} />

            case "TEXT":
                return <TextArea required={true} md={9} label={parametro.nome} value={this.state.model.valores[index].dsValor ? this.state.model.valores[index].dsValor : ""} onChange={e => { this.handleChangeField(e, parametro, index) }} maxLength={parametro.nrTamanho} />

            case "INTEGER":
                return <InputNumber required={true} md={9} label={parametro.nome} value={this.state.model.valores[index].dsValor ? this.state.model.valores[index].dsValor : ""} onChange={e => { this.handleChangeField(e, parametro, index) }} />

            case "DATE":
                return <InputDate required={true} md={9} label={parametro.nome} value={this.state.model.valores[index].dsValor ? this.state.model.valores[index].dsValor : ""} onChange={e => { this.handleChangeField(e, parametro, index) }} />

            case "TIME":
                return <InputTime required={true} md={9} label={parametro.nome} value={this.state.model.valores[index].dsValor ? this.state.model.valores[index].dsValor : ""} onChange={e => { this.handleChangeField(e, parametro, index) }} />

            case "DECIMAL":
                return <InputDecimal required={true} md={9} label={parametro.nome} value={this.state.model.valores[index].dsValor ? this.state.model.valores[index].dsValor : ""} onChange={e => { this.handleChangeField(e, parametro, index) }} decimalScale={parseInt(parametro.nrTamanho)} />

            case "BOOLEAN":
                return (
                    <div className="col-md-6">
                        <InputCheckSwitch label={parametro.nome} checked={Boolean(this.state.model.valores[index].dsValor)} onChange={e => { this.handleChangeField(e, parametro, index) }} />
                    </div>
                );

            case "DROP_DOWN":
                return <FormSelect required={true} md={9} placeholder="Selecione um item" label={parametro.nome} value={this.state.model.valores[index].dsValor ? this.state.model.valores[index].dsValor : ""} options={JSON.parse(parametro.dsJson)} onChange={e => { this.handleChangeField(e, parametro, index) }} />

            case "CHECKBOX":
                return (
                    <div className="col-md-9">
                        {JSON.parse(parametro.dsJson).map((item, indexCheck) => (
                            <div className="row" key={indexCheck}>
                                <InputCheckSwitch label={item.label} checked={Boolean((JSON.parse(this.state.model.valores[index].dsValor)?.find(value => value === item.value)))} onChange={e => { this.handleChangeField(e, parametro, index, item.value) }} />
                            </div>
                        ))}
                    </div>
                );

            default:
                return <InputText required={true} md={9} label={parametro.nome} value={this.state.model.valores[index].dsValor ? this.state.model.valores[index].dsValor : ""} onChange={e => { this.handleChangeField(e, parametro, index) }} maxLength={parametro.nrTamanho} />

        }
    }

    getRenderFields() {
        return (
            <div>
                <h4 className="pb-3"> Parâmetros </h4>
                {this.state.model.valores.map((parametro, index) => (
                    <div key={index} className="row mb-3 g-3 border-bottom border-secondary pb-3">
                        <div className="col-3">
                            <InputCheckSwitch label="Preencher Valor?" name="flTemValor" checked={Boolean(this.state.model.valores[index].flTemValor)} onChange={e => this.handleChange(e, false, this.state.model.valores[index])} />
                        </div>
                        {Boolean(this.state.model.valores[index].flTemValor) ? this.getField(parametro, index) : <div className="col-md-9">{parametro.nome}</div>}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormParametroValor {...props} navigate={navigate} params={params} />
}

export default With