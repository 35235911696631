import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import CategoriaProdutoServicoService from "../../services/CategoriaProdutoServicoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputFile from "../../components/forms/elements/InputFile";
import Config from "../../Config";
import ArquivoService from "../../services/ArquivoService";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnButton from "../../components/forms/elements/BtnButton";

class FormCategoriaProdutoServico extends CrudForm {

    constructor(props) {
        super(props);

        this.handleClickUpItem = this.handleClickUpItem.bind(this);
        this.handleClickDownItem = this.handleClickDownItem.bind(this);

        this.state.model = {
            idCategoriaProdutoServico: "",
            nome: "",
            flRanking: true,
            categoriaProdutoServicoArquivo: []
        };

        this.state.crud = {
            service: CategoriaProdutoServicoService,
            labels: {
                title: 'Categoria',
            },
            urlList: '/LstCategoriaProdutoServico',
        };

        this.state.isCard = true;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleFileChange(event) {
        var files = event.target?.files;
        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                ArquivoService.upload(files[i], (response) => {
                    this.setState((state) => {
                        state.model.categoriaProdutoServicoArquivo.push(response.data);
                        return state;
                    });
                });
            }
        }
    }

    handleDeleteItemArquivo(index, item) {
        this.setState((state) => {
            state.model.categoriaProdutoServicoArquivo.splice(index, 1);
            return state;
        });
    }

    handleClickUpItem(index) {
        this.setState(state => {            
            var item1 = this.state.model.categoriaProdutoServicoArquivo[index];
            var item2 = this.state.model.categoriaProdutoServicoArquivo[index - 1];

            this.state.model.categoriaProdutoServicoArquivo[index] = item2;
            this.state.model.categoriaProdutoServicoArquivo[index - 1] = item1;

            return state;
        })
    }

    handleClickDownItem(index) {
        this.setState(state => {
            var item1 = this.state.model.categoriaProdutoServicoArquivo[index];
            var item2 = this.state.model.categoriaProdutoServicoArquivo[index + 1];

            this.state.model.categoriaProdutoServicoArquivo[index] = item2;
            this.state.model.categoriaProdutoServicoArquivo[index + 1] = item1;

            return state;
        })
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-dados-tab" data-bs-toggle="tab" data-bs-target="#nav-dados" type="button" role="tab" aria-controls="nav-dados" aria-selected="true">
                        Dados Categoria
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-fotos-tab" data-bs-toggle="tab" data-bs-target="#nav-fotos" type="button" role="tab" aria-controls="nav-fotos" aria-selected="false">
                        Fotos
                    </button>
                </li>
            </ul>
        );
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">
                    <div className="row mb-3 g-3">
                        <div className="row mb-3 g-3">
                            <InputText label="Código" md={3} value={this.state.model.idCategoriaProdutoServico} name="idCategoriaProdutoServico" readOnly opcionalLabel={false} />
                            <InputText label="Nome da Categoria" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                        </div>
                        <div className="row mb-3 g-1">
                            <InputCheckSwitch sizing="md" label="Aparece no Ranking?" name="flRanking" checked={Boolean(this.state.model.flRanking)} onChange={this.handleChange} />
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-fotos" role="tabpanel" aria-labelledby="nav-fotos-tab">
                    <div className="row mb-3 g-3">
                        <InputFile label="Selecione o arquivo" name="arquivo" multiple onChange={(event) => { this.handleFileChange(event) }} accept="image/*" />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col-10"> Imagem </th>
                                        <th className="col-2 text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.categoriaProdutoServicoArquivo.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td>
                                                <img style={{ maxHeight: "150px" }} src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uid} className="img-fluid img-thumbnail" alt="..." />
                                            </td>
                                            <td className="align-middle text-center text-nowrap">
                                                <BtnButton onClick={e => this.handleClickUpItem(index)} className="btn-primary btn-lateral rounded-pill btn-sm" disabled={index === 0}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                                    </svg>
                                                </BtnButton>
                                                <BtnButton onClick={e => this.handleClickDownItem(index)} className="btn-primary btn-lateral rounded-pill btn-sm ms-1" disabled={(index === (this.state.model.categoriaProdutoServicoArquivo.length -1))}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                                    </svg>
                                                </BtnButton>
                                                <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteItemArquivo(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg> Excluir Imagem
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCategoriaProdutoServico {...props} navigate={navigate} params={params} />
}

export default With