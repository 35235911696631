import React from "react";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectDDI from "../../components/app/SelectDDI";
import InputCpf from "../../components/forms/custom/InputCpf";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import InputEmail from "../../components/forms/elements/InputEmail";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import InputCep from "../../components/forms/custom/InputCep";
import { useNavigate, useParams } from "react-router-dom";
import FaturaService from "../../services/FaturaService";
import EnderecoService from "../../services/EnderecoService";
import Util from "../../utils/Util";

class FormFaturaPessoa extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.getFaturaPessoa = this.getFaturaPessoa.bind(this);
        this.handleChangeCep = this.handleChangeCep.bind(this);

        this.state.model = {
            idFatura: this.props.idFatura,

            ddi: "55",
            celular: "",
            nome: "",
            email: "",
            cdTipoPessoa: "F",
            cpf: "",
            cnpj: "",
            inscricaoEstadual: "",

            idPais: 1,
            idEstado: "",
            idCidade: "",
            cep: "",
            bairro: "",
            endereco: "",
            numero: "",
            complemento: ""
        };

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getFaturaPessoa();
    }

    getFaturaPessoa() {
        FaturaService.getOneById(this.props.idFatura, (response) => {
            this.setModel(response.faturaPessoa);
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event) {
        FaturaService.salvarPessoa(this.state.model.idFatura, this.state.model, (response) => {
            this.onSave(response);
        });
    }

    handleChangeCep() {
        if (Util.stringToNumber(this.state.model.cep).length === 8) {
            EnderecoService.getOneByCep(this.state.model.cep, (response) => {
                this.setState((state) => {
                    state.model.endereco = response?.logradouro ? response.logradouro : "";
                    state.model.bairro = response?.bairro ? response.bairro : "";
                    state.model.complemento = response?.complemento ? response.complemento : "";
                    state.model.numero = "";

                    if (response?.idEstado) {
                        state.model.idEstado = response.idEstado;
                    }

                    if (response?.idCidade) {
                        state.model.idCidade = response.idCidade;
                    }

                    return state;
                });
            });

        }
    }

    getRenderFields() {
        return (
            <div className="mb-3">
                <div className="card mt-4 border-secondary">
                    <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">
                        Dados do Pagador
                    </div>
                    <div className="card-body">
                        <div className="row mb-3 g-3">
                            <BtnGroup required={true} lg={4} className="w-100" name="cdTipoPessoa" value={this.state.model.cdTipoPessoa} label="Tipo Pessoa" options={this.state.tipoPessoa} onChange={this.handleChange} />

                            {this.state.model.cdTipoPessoa === "F" ? <InputCpf label="CPF" required={true} lg={3} name="cpf" value={this.state.model.cpf ? this.state.model.cpf : ""} onChange={this.handleChange} /> : <></>}
                            {this.state.model.cdTipoPessoa === "J" ? <InputCnpj label="CNPJ" required={true} lg={3} name="cnpj" value={this.state.model.cnpj ? this.state.model.cnpj : ""} onChange={this.handleChange} /> : <></>}

                            <InputText label={this.state.model.cdTipoPessoa === "J" ? "Razão Social" : "Nome completo"} required lg={5} value={this.state.model.nome} name="nome" onChange={this.handleChange} />

                            {this.state.model.cdTipoPessoa === "J" ?
                                <InputText label="Inscrição Estadual" md={3} value={this.state.model.inscricaoEstadual} name="inscricaoEstadual" onChange={this.handleChange} maxLength={150} />
                                : <></>}

                            <SelectDDI showBtns={false} lg={2} label="DDI" required placeholder="Selecione" name="ddi" value={this.state.model.ddi} onChange={this.handleChange} />
                            {this.state.model.ddi.toString() === "55" ?
                                <InputTelCelular label="Celular" required lg={3} value={this.state.model.celular} name="celular" onChange={this.handleChange} />
                                :
                                <InputText label="Celular" required lg={3} value={this.state.model.celular} name="celular" onChange={this.handleChange} />
                            }

                            <InputEmail label="E-mail" lg={4} name="email" value={this.state.model.email} onChange={this.handleChange} required={true} />
                        </div>
                    </div>
                </div>

                <div className="card mt-4 border-secondary">
                    <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">
                        Endereço do Titular
                    </div>
                    <div className="card-body">
                        <div className="row mb-3 g-3">
                            <InputCep label="CEP" lg={3} value={this.state.model.cep} name="cep" onChange={e => this.handleChange(e, this.handleChangeCep)} required={true} />
                            <InputText label="Logradouro" lg={6} value={this.state.model.endereco} name="endereco" onChange={this.handleChange} maxLength={100} required={true} />
                            <InputText label="Número" lg={3} value={this.state.model.numero} name="numero" onChange={this.handleChange} maxLength={100} required={true} />

                            <InputText label="Complemento" lg={6} value={this.state.model.complemento} name="complemento" onChange={this.handleChange} maxLength={100} />
                            <InputText label="Bairro" lg={6} value={this.state.model.bairro} name="bairro" onChange={this.handleChange} maxLength={150} required={true} />

                            <SelectEstadoByPais showBtnNewForm={false} sizing="md" lg={6} required idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" onChange={this.handleChange} />
                            <SelectCidadeByEstado showBtnNewForm={false} sizing="md" lg={6} required idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" onChange={this.handleChange} />
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormFaturaPessoa {...props} navigate={navigate} params={params} />
}

export default With