import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MyComponent from "../../components/custom/MyComponent";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import InputDate from "../../components/forms/elements/InputDate";
import TextArea from "../../components/forms/elements/TextArea";
import MonitorService from "../../services/MonitorService";
import Util from "../../utils/Util";

class ListaMonitor extends MyComponent {

    constructor(props) {
        super(props);

        this.getLista = this.getLista.bind(this);
        this.getListaMerge = this.getListaMerge.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.handleClickViewConteudo = this.handleClickViewConteudo.bind(this);
        this.handleClickViewTrace = this.handleClickViewTrace.bind(this);
        this.handleClickOcultar = this.handleClickOcultar.bind(this);
        this.handleChangeDtLog = this.handleChangeDtLog.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtLog = new Date();
        dtLog = new Date(dtLog - tzoffset).toISOString().slice(0, 10);

        this.state = {
            data: [],
            model: {
                dtLog: dtLog,
                idLogErroMax: 0,
                idLogErroMin: null,
                rowCount: 20,
            }
        };

        this.timeout = null;
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearTimeout(this.timeout);
    }

    getLista(flLoading = true) {
        clearTimeout(this.timeout);

        MonitorService.list(flLoading, this.state.model, (response) => {
            this.setState(state => {
                for (var i = (response.length - 1); i >= 0; i--) {
                    response[i].flOculto = false;
                    response[i].flViewConteudo = false;
                    response[i].flViewTrace = false;
                    state.data.unshift(response[i]);
                    state.model.idLogErroMax = parseInt(response[i].idLogErro > state.model.idLogErroMax ? response[i].idLogErro : state.model.idLogErroMax);
                    state.model.idLogErroMin = parseInt(state.model.idLogErroMin === null || response[i].idLogErro < state.model.idLogErroMin ? response[i].idLogErro : state.model.idLogErroMin);
                }
                return state;
            }, () => {
                this.timeout = setTimeout(() => { this.getLista(false) }, 5000);
            })
        }, () => {
            this.timeout = setTimeout(() => { this.getLista(false) }, 5000);
        });
    }

    getListaMerge() {
        MonitorService.listMerge(this.state.model, (response) => {

            if (response.length === 0) {
                toast.warning("Nenhum registro encontrado!");
            } else {
                this.setState(state => {
                    for (var i = 0; i < response.length; i++) {
                        response[i].flOculto = false;
                        response[i].flViewConteudo = false;
                        response[i].flViewTrace = false;
                        state.data.push(response[i]);
                        state.model.idLogErroMax = parseInt(response[i].idLogErro > state.model.idLogErroMax ? response[i].idLogErro : state.model.idLogErroMax);
                        state.model.idLogErroMin = parseInt(state.model.idLogErroMin === null || response[i].idLogErro < state.model.idLogErroMin ? response[i].idLogErro : state.model.idLogErroMin);
                    }
                    return state;
                });
            }
        });
    }

    handleClickMaisRegistros() {
        this.getListaMerge();
    }

    handleChangeDtLog() {
        this.setState(state => {
            state.data = [];
            state.model.idLogErroMax = 0;
            return state;
        }, this.getLista);
    }

    handleClickViewConteudo(index) {
        this.setState(state => {
            state.data[index].flViewConteudo = !state.data[index].flViewConteudo;
            return state;
        });
    }

    handleClickViewTrace(index) {
        this.setState(state => {
            state.data[index].flViewTrace = !state.data[index].flViewTrace;
            return state;
        });
    }

    handleClickOcultar(index) {
        this.setState(state => {
            state.data.filter((i, ix) => ix >= index).forEach(element => {
                element.flOculto = true;
            });
            return state;
        });
    }

    onSubmit(event) {
        this.setState(state => {
            state.data = [];
            state.model.idLogErroMax = 0;
            return state;
        }, this.getLista);
    }

    render() {
        return (
            <div className="m-0 p-0 p-2">
                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row">
                        <InputDate md={3} sizing="sm" label="Data Log" value={this.state.model.dtLog} name="dtLog" onChange={(e) => { this.handleChange(e) }} required={true} />

                        <div className="col-2 pt-4">
                            <BtnSubmit className="btn-primary btn-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg> Buscar
                            </BtnSubmit>
                            <Link to="/" className="btn btn-outline-primary btn-sm m-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                </form>
                <div className="table-responsive h-100">
                    <table className="table fs-6">
                        <thead>
                            <tr>
                                <th></th>
                                <th className="col" scope="col">Mensagem</th>
                                <th></th>
                                <th></th>
                                <th className="col" scope="col">Local</th>
                                <th className="col" scope="col">Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.filter(i => !Boolean(i.flOculto)).map((mensagem, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td>
                                            {mensagem.idLogErro}
                                            <br />
                                            <BtnButton className="btn-link btn-sm p-0" onClick={e => { this.handleClickOcultar(index) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                                    <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                                </svg>
                                            </BtnButton>
                                        </td>
                                        <td>
                                            <div className="text-dark fs-7">
                                                {Util.date2Br(mensagem.dataLog)} {mensagem.horaLog} - <strong>{mensagem.nomeUsuario}</strong>
                                            </div>
                                            <div className="badge bg-danger">{mensagem.cdTipo}</div>
                                            <div className="text-danger">{mensagem.dsMensagem}</div>
                                        </td>
                                        <td>
                                            {mensagem.dsConteudo.length > 0 ?
                                                <BtnButton className="btn-primary btn-sm" onClick={e => { this.handleClickViewConteudo(index) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-body-text" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 0 .5Zm0 2A.5.5 0 0 1 .5 2h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm9 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-9 2A.5.5 0 0 1 .5 4h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Zm5 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm7 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Zm-12 2A.5.5 0 0 1 .5 6h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5Zm8 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-8 2A.5.5 0 0 1 .5 8h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm7 0a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm-7 2a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Z" />
                                                    </svg>
                                                </BtnButton>
                                                : <></>}
                                        </td>
                                        <td>
                                            {mensagem.dsTrace.length > 0 ?
                                                <BtnButton className="btn-primary btn-sm" onClick={e => { this.handleClickViewTrace(index) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-braces-asterisk" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M1.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C2.25 2 1.49 2.759 1.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6ZM14.886 7.9v.164c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456v-1.3c-1.114 0-1.49-.362-1.49-1.456V4.352C14.51 2.759 13.75 2 12.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6ZM7.5 11.5V9.207l-1.621 1.621-.707-.707L6.792 8.5H4.5v-1h2.293L5.172 5.879l.707-.707L7.5 6.792V4.5h1v2.293l1.621-1.621.707.707L9.208 7.5H11.5v1H9.207l1.621 1.621-.707.707L8.5 9.208V11.5h-1Z" />
                                                    </svg>
                                                </BtnButton>
                                                : <></>}
                                        </td>
                                        <td className="fs-7">
                                            <strong>URL:</strong> {mensagem.dsUrl}<br />
                                            <strong>CLASSE:</strong> {mensagem.dsClasse}
                                        </td>
                                        <td className="fs-7">
                                            {mensagem.cdStatus}
                                        </td>
                                    </tr>

                                    {mensagem.flViewConteudo ?
                                        <tr>
                                            <td colSpan={5}>
                                                <TextArea className="bg-dark text-bg-dark p-2 m-2 w-100" style={{ height: "400px", fontFamily: "Courier New" }} value={mensagem.dsConteudo.toString()} />
                                            </td>
                                        </tr>
                                        : <></>}

                                    {mensagem.flViewTrace ?
                                        <tr>
                                            <td colSpan={5}>
                                                <div className="bg-dark text-bg-dark p-2 m-2 w-100" style={{ overflow: "auto", height: "400px", fontFamily: "Courier New" }}>
                                                    <pre>{JSON.stringify(JSON.parse(mensagem.dsTrace), undefined, 2)}</pre>
                                                </div>
                                            </td>
                                        </tr>
                                        : <></>}

                                </React.Fragment>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="text-center pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>

            </div>
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <ListaMonitor {...props} navigate={navigate} params={params} location={location} />
}

export default With