import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import MyComponent from "../../components/custom/MyComponent";
import BtnButton from "../../components/forms/elements/BtnButton";
import PainelService from "../../services/PainelService";
import Progressbar from "./ProgressBar";
import Bloco from "./Bloco";
import FormSenhaConfirmacao from "../Login/FormSenhaConfirmacao";
import EventBus from "../../controllers/EventBus";
import Storage from "../../controllers/Storage";
import Auth from "../../controllers/Auth";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";

class Painel extends MyComponent {

    constructor(props) {
        super(props);

        this.getPainel = this.getPainel.bind(this);
        this.fnCallback = this.fnCallback.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
        this.handleClickPlayPause = this.handleClickPlayPause.bind(this);        
        this.handleClickFilter = this.handleClickFilter.bind(this);
        this.handleClickTempoUpdate = this.handleClickTempoUpdate.bind(this);
        this.handleClickVerValor = this.handleClickVerValor.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            usuario: Auth.getDataToken(),
            nrTempoAlterarTab: 5,
            indexPainelActive: 0,
            data: [],            
            filterable: false,
            play: true,
            flVerValor: Storage.getVisualizacaoValor(),            
            idRepresentante : ""
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getPainel();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getPainel() {
        this.setState({ data: [], indexPainelActive: 0 }, () => {
            PainelService.getPainel(this.props.params?.id, (response) => {
                response[0].blocos[0].flMostrar = true;
                this.setState({ data: response })//, this.fnCallback(0, 0));
            });
        });
    }

    handleChangeTab(index) {
        if (index === undefined) {
            if (this.state.data.length > 0) {
                index = this.state.indexPainelActive === (this.state.data.length - 1) ? 0 : this.state.indexPainelActive + 1;
            }
        }

        if (index !== undefined) {
            this.setState({ indexPainelActive: index });
        }
    }

    handleClickTempoUpdate() {
        this.setState(state => {
            switch (parseInt(state.nrTempoAlterarTab)) {
                case 0:
                    state.nrTempoAlterarTab = 1;
                    break;
                case 1:
                    state.nrTempoAlterarTab = 5;
                    break;

                case 5:
                    state.nrTempoAlterarTab = 10;
                    break;

                case 10:
                    state.nrTempoAlterarTab = 30;
                    break;

                case 30:
                    state.nrTempoAlterarTab = 60;
                    break;

                default:
                    state.nrTempoAlterarTab = 0;
            }
            return state;
        });
    }

    handleClickPlayPause() {
        this.setState(state => {
            state.play = !Boolean(state.play);
            return state;
        });
    }

    handleClickFilter() {
        this.setState({ filterable: !this.state.filterable });
    }

    handleClickVerValor() {

        if (!Boolean(this.state.flVerValor) && Boolean(parseInt(this.state.usuario.flExigirSenhaVerValor))) {

            EventBus.dispatch("modal-add", {
                title: 'Confirmação de Senha',
                sizeModal: 'modal-md',
                config: {
                    isModal: true
                },
                form: FormSenhaConfirmacao,
                onClose: (response) => {
                },
                onSave: (response) => {
                    this.setState({ flVerValor: Storage.uptVisualizacaoValor() });
                }
            });
        } else {
            this.setState({ flVerValor: Storage.uptVisualizacaoValor() });
        }
    }

    fnCallback(indexPainel, indexBloco) {

        if ((indexBloco + 1) < (this.state.data[indexPainel].blocos.length)) {
            indexBloco++;

            this.setState(state => {
                state.data[indexPainel].blocos[indexBloco].flMostrar = true;
                return state;
            });
        } else if ((indexPainel + 1) < (this.state.data.length)) {
            indexPainel++;
            indexBloco = 0;

            this.setState(state => {
                if (state.data[indexPainel].blocos.length > 0) {
                    state.data[indexPainel].blocos[indexBloco].flMostrar = true;
                }
                return state;
            });
        }
    }

    render() {
        return (
            <div className="m-0 p-3 d-flex">

                <nav className="pe-2">
                    <div className="nav nav-pills flex-column" id="nav-tab" role="tablist">
                        {this.state.data.map((group, indexPainel) => (
                            <button key={indexPainel} className={`nav-link p-0 ${parseInt(indexPainel) === this.state.indexPainelActive ? "active" : ""}`} onClick={e => this.handleChangeTab(indexPainel)}>
                                {indexPainel + 1}
                            </button>
                        ))}
                    </div>

                    <hr className="hr" />

                    <div>

                        <BtnButton className="btn-outline-primary btn-sm m-2" onClick={this.handleClickVerValor}>
                            {Boolean(this.state.flVerValor) ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash-fill text-danger" viewBox="0 0 16 16">
                                    <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                    <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                </svg>
                            }
                        </BtnButton>

                    </div>
                    <div>
                        <BtnButton className="btn-outline-primary btn-sm m-2" onClick={this.handleClickPlayPause}>
                            {Boolean(this.state.play) ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pause-fill" viewBox="0 0 16 16">
                                    <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-play-fill" viewBox="0 0 16 16">
                                    <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                                </svg>
                            }
                        </BtnButton>
                    </div>

                    <div>
                        <BtnButton className="btn-outline-primary btn-sm m-2" onClick={this.getPainel}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                            </svg>
                        </BtnButton>
                    </div>

                    <div>
                        <BtnButton className={`${Boolean(this.state.filterable) ? 'btn-primary' : 'btn-outline-primary'} btn-sm m-2`} onClick={this.handleClickFilter}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
                            </svg>
                        </BtnButton>
                    </div>

                    <BtnButton className="btn-outline-primary btn-sm m-2" onClick={this.handleClickTempoUpdate}>
                        {parseInt(this.state.nrTempoAlterarTab) > 0 ?
                            <div style={{ width: "16px" }}>{this.state.nrTempoAlterarTab}</div> :
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-slash-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.646-2.646a.5.5 0 0 0-.708-.708l-6 6a.5.5 0 0 0 .708.708l6-6z" />
                            </svg>}
                    </BtnButton>


                    <div>
                        <Link to="/" className="btn btn-outline-primary btn-sm m-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                        </Link>
                    </div>
                </nav>

                <div className="tab-content w-100">

                    {Boolean(this.state.filterable) ? 
                        <div className="row mb-3">
                            <SelectRepresentanteByUsuario showBtnNewForm={false} md={6} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.idRepresentante} onChange={e => this.handleChange(e, this.getPainel)} opcionalLabel={false} />
                        </div>
                    : <></>}

                    {this.state.data.map((painel, indexPainel) => (
                        <div key={indexPainel} className={`tab-pane fade ${parseInt(indexPainel) === this.state.indexPainelActive ? "show active" : ""}`} role="tabpanel">
                            <div className="row g-3">
                                {painel.blocos.map((bloco, indexBloco) => {
                                    if (Boolean(bloco?.flMostrar)) {
                                        return (<Bloco idBloco={bloco.idBloco} filterable={this.state.filterable} play={this.state.play} key={indexBloco} idRepresentante={this.state.idRepresentante} fnCallback={b => this.fnCallback(indexPainel, indexBloco)} />)
                                    } else {
                                        return (<React.Fragment key={indexBloco}></React.Fragment>);
                                    }
                                })}

                            </div>
                        </div>
                    ))}

                </div>

                <div className="fixed-bottom">
                    <Progressbar play={parseInt(this.state.nrTempoAlterarTab) > 0} timeChange={(this.state.nrTempoAlterarTab * 60)} callback={this.handleChangeTab} />
                </div>

            </div >
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <Painel {...props} navigate={navigate} params={params} location={location} />
}

export default With