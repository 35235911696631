import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import InputColor from "../../components/forms/elements/InputColor";
import PromocaoCampanhaService from "../../services/PromocaoCampanhaService.js";
import CrudForm from "../../components/forms/custom/CrudForm";

class FormPromocaoCampanha extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idPromocaoCampanha: "",
            nome: "",
            cdBgColor: "#000000",
            cdTxColor: "#FFFFFF"
        };

        this.state.crud = {
            service: PromocaoCampanhaService,
            labels: {
                title: 'Campanha',
            },
            urlList: '/LstPromocaoCampanha',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idPromocaoCampanha} name="idPromocaoCampanha" readOnly opcionalLabel={false} />
                    <InputText label="Nome do Status" autoFocus={true} required md={6} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <InputColor label="Fundo" autoFocus={true} required md={1} value={this.state.model.cdBgColor} name="cdBgColor" onChange={this.handleChange} maxLength={100} />
                    <InputColor label="Texto" autoFocus={true} required md={1} value={this.state.model.cdTxColor} name="cdTxColor" onChange={this.handleChange} maxLength={100} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormPromocaoCampanha {...props} navigate={navigate} params={params} />
}

export default With