import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import BaixaService from "../../services/BaixaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import CobrancaService from "../../services/CobrancaService";
import Permissao from "../../controllers/Permissao";
import EventBus from "../../controllers/EventBus";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import BtnButton from "../../components/forms/elements/BtnButton";
import GridItem from "../../components/forms/elements/GridItem";
import MostraValor from "../../components/app/MostraValor";

class FormHistoricoBaixa extends CrudForm {

    constructor(props) {
        super(props);

        this.getById = this.getById.bind(this);
        this.estornar = this.estornar.bind(this);
        this.handleEstornarBaixa = this.handleEstornarBaixa.bind(this);

        this.state.model = {
            idCobranca: "",
            vlCobranca: 0,
            vlPago: 0,
            vlAberto: 0,
            vlBaixa: 0,
            vlDesconto: 0,
            idTipoBaixa: "",
            dtPagamento: "",
            dsObservacao: "",
            cobranca: {
                nomeProprietario: "",
                dtVencimento: "",
                baixa: []
            }
        };

        this.state.showBtnSalvar = false;
        this.state.showBtnCancelar = false;

        this.state.crud = {
            service: BaixaService,
            labels: {
                title: 'Baixa',
            },
            urlList: '/LstBaixa',
        };
    }

    componentDidMount() {
        super.componentDidMount();        
    }

    getById() {
        if (this.props.idCobranca) {
            CobrancaService.getOneById(this.props.idCobranca, (data) => {
                var model = {};
                model.idCobranca = data.idCobranca;
                model.cobranca = data;
                model.vlCobranca = data.vlCobranca;
                model.vlPago = data.vlPago;
                model.vlAberto = data.vlAberto;
                this.setModel(model);

            });
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleEstornarBaixa(item) {

        EventBus.dispatch("modal-baixa-open", {
            id: item.idBaixa,
            config: {
                title: "Estornar Baixa?",
                message: <>Deseja realmente estornar a baixa: <strong>{item.idBaixa} </strong>?</>,
                btnConfirmText: "Sim, estornar a baixa",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: this.estornar
            }
        })
    }

    estornar(item) {
        BaixaService.estornar(item.id, (response) => {
            this.getById();
        });
    }

    getRenderFields() {
        return (
            <>
                <h6 className="border-bottom border-secondary text-secondary"> Dados da Cobrança  </h6>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={1} value={this.state.model.idCobranca} name="idCobranca" disabled={true} required={true} />

                    <MostraValor format="InputText" label="Valor da Cobrança" md={3} disabled={true} opcionalLabel={false}>
                        <InputText className="text-end text-primary fw-bold" label="Valor da Cobrança" md={3} value={Util.floatToReais(this.state.model.vlCobranca)} disabled={true} opcionalLabel={false} />
                    </MostraValor>

                    <MostraValor format="InputText" label="Valor Pago" md={3} disabled={true} opcionalLabel={false}>
                        <InputText className="text-end text-success fw-bold" label="Valor Pago" md={3} value={Util.floatToReais(this.state.model.vlPago)} disabled={true} opcionalLabel={false} />
                    </MostraValor>

                    <MostraValor format="InputText" label="Valor à Pagar" md={3} disabled={true} opcionalLabel={false}>
                        <InputText className="text-end text-danger fw-bold" label="Valor à Pagar" md={3} value={Util.floatToReais(this.state.model.vlAberto)} disabled={true} opcionalLabel={false} />
                    </MostraValor>

                    <InputText label="Data de Vencimento" md={2} value={Util.date2Br(this.state.model.cobranca.dtVencimento)} disabled={true} required={true} />
                </div>

                <h6 className="border-bottom border-secondary text-secondary"> Histórico das Baixas  </h6>

                <div className="mb-3">
                    <ol className="list-group list-group-numbered">
                        {this.state.model.cobranca.baixa.map((item, index) => (
                            <li className={`list-group-item d-flex gap-3 justify-content-between align-items-start ${index % 2 === 0 ? "bg-light " : ""}`} key={index}>
                                <div className="ms-2 w-100">
                                    <div className="row d-flex g-2">
                                        <GridItem sizing="sm" label="Código" md={2} value={item.idBaixa} />
                                        <GridItem sizing="sm" label="Cobrança" md={2} value={item.idCobranca} />
                                        <GridItem sizing="sm" label="Tipo da Baixa" md={4} value={item.nomeTipoBaixa} />
                                        <GridItem sizing="sm" label="Data da Baixa" md={2} value={Util.date2Br(item.dataBaixa)} />
                                        <GridItem sizing="sm" label="Data do Pagamento" md={2} value={Util.date2Br(item.dtPagamento)} />

                                        <GridItem sizing="sm" label="Usuário" md={4} value={item.nomeUsuario} />
                                        <GridItem className="text-primary fw-bold" sizing="sm" label="Canal de Pagamento" md={4} value={item.nomeConta} />

                                        <MostraValor format="GridItem" label="Valor da Baixa" md={2} sizing="sm" disabled={true} opcionalLabel={false}>
                                            <GridItem className="text-success fw-bold" sizing="sm" label="Valor da Baixa" md={2} value={Util.floatToReais(item.vlBaixa)} />
                                        </MostraValor>

                                        <MostraValor format="GridItem" label="Valor do Desconto" md={2} sizing="sm" disabled={true} opcionalLabel={false}>
                                            <GridItem className="text-danger fw-bold" sizing="sm" label="Valor do Desconto" md={2} value={Util.floatToReais(item.vlDesconto)} />
                                        </MostraValor>


                                        {item.dsObservacao ?
                                            <GridItem label="Observação" sizing="sm" md={12} value={item.dsObservacao} />
                                            : <></>}
                                    </div>
                                    <div className="text-end pt-2">
                                        {Permissao.getInstance().getPermissao(34) ?
                                            <BtnButton className="btn-sm btn-outline-danger text-nowrap" onClick={(e) => { this.handleEstornarBaixa(item) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eraser-fill" viewBox="0 0 16 16">
                                                    <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
                                                </svg> Estornar
                                            </BtnButton>
                                            : <></>
                                        }
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>

                <ModalConfirmacao id="modal-baixa" />
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormHistoricoBaixa {...props} navigate={navigate} params={params} />
}

export default With