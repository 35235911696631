import React from "react";
import FormField from "./FormField";
import Util from "../../../utils/Util";

export default class FormDropDownCheckBox extends FormField {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeBusca = this.handleChangeBusca.bind(this);
        this.handleChangeItem = this.handleChangeItem.bind(this);
        this.handleChangeAll = this.handleChangeAll.bind(this);
        this.handleClickDrop = this.handleClickDrop.bind(this);

        this.state.options = this.props.options || [];
        this.state.value = null;
        this.state.busca = "";
        this.refBusca = React.createRef();
        this.rowCols = 1;

        this.state.name = this.props.name !== undefined ? this.props.name : "multipleCheckBox" + (Math.random(10000) * 10000).toFixed(0);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickDrop(event) {
        if(this.props.showFieldSearch !== undefined){
            this.refBusca.current.focus();
        }        
        this.setState({busca : ""});
    }

    handleChangeAll(e) {
        if (this.props.handleChangeAll !== undefined) {            
            var optionsFiltered = this.getItensFiltered(false);
            var optionsSelecteds = this.getItensSelects();
            optionsSelecteds.concat(optionsFiltered);
            this.props.handleChangeAll(e.target.checked, optionsFiltered);
        }
    }

    handleChangeItem(e, item) {
        if (this.props.handleChangeItem !== undefined) {
            this.props.handleChangeItem(e.target.checked, item);
        }
    }

    handleChangeBusca(event) {
        this.setState({busca : event.target.value});
    }

    handleChange(event) {
        super.handleChange(event);
        if (this.props.onChange) {
            this.props.onChange(event)
        }

        if (this.props.onChangeItem) {
            var item = this.state.options.find(item => item.value.toString() === event?.target?.value.toString());
            this.props.onChangeItem(item ? item : {})
        }
    }

    isDisabled(f) {
        if (this.props.disabledValues) {
            return this.props.disabledValues.indexOf(f?.value) !== -1;
        }
        return false;
    }

    getOptionLabel(item){
        return item?.label;
    }

    getItensSelects(){
        return this.state.options.filter(item =>
            this.props.values.findIndex(i => parseInt(i) === parseInt(item.value) && !this.isDisabled(item)) !== -1
        );
    }

    getItensSelectsFiltered(isDisabled = true){
        return this.getItensFiltered(isDisabled).filter(item =>
            this.props.values.findIndex(i => parseInt(i) === parseInt(item.value) && !this.isDisabled(item)) !== -1
        );
    }

    getItensFiltered(isDisabled = true){
        var itens = this.state.options.filter(f =>
            Util.searchTextInObject(f, ["value", "label"] , this.state.busca)
        );

        if(this.props.disabledValues && !isDisabled){
            itens = itens.filter(f => !this.isDisabled(f));
        }

        return itens;
    }

    getRowCols(){
        if(this.props.rowCols !== undefined){
            return this.props.rowCols;
        }

        return this.rowCols
    }

    render() {

        var className = `btn btn-menu-dropdown-checkbox dropdown-toggle w-100 text-start ${this.props.className ? this.props.className : ""} ${this.props.sizing ? "btn-" + this.props.sizing : ""}`;

        var options = this.getItensFiltered();
        var selecteds = this.getItensSelectsFiltered(false);

        var render =
            <div className="dropdown">
                
                <button type="button" {...this.getProps(this.props)} className={className} data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" onClick={this.handleClickDrop}>

                    {this.props.placeholder && this.props.values.length === 0 ? <>{this.props.placeholder}</> : <></>}

                    {selecteds.map((item, index) => (
                        <React.Fragment key={index}>{item.label}, </React.Fragment>
                    ))}
                </button>

                <div className="dropdown-menu p-3 w-100">

                    {this.props.showFieldSearch !== undefined ? 
                        <input className="form-control form-control-sm mb-1" type="text" sizing="sm" onChange={this.handleChangeBusca} value={this.state.busca} ref={this.refBusca}/>
                    : <></>}

                    {this.props.handleChangeAll !== undefined && options.length > 0 ? 
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                checked={selecteds.length === this.getItensFiltered(false).length}
                                id={`${this.props.name}000`}
                                onChange={e => this.handleChangeAll(e)}
                            />
                            <label htmlFor={`${this.props.name}000`} className="form-check-label fw-bold user-select-none">Selecionar Todos</label>
                        </div>
                    : <></>}
                    
                    <div className={`row row-cols-${this.getRowCols()} scrollable-menu`}>
                        {options.map((option, index) => (
                            <div className="col" key={index}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id={`${this.props.name}${index}`} value={option.value}
                                        disabled={this.props.disabledValues && this.isDisabled(option)}
                                        checked={this.props.values !== undefined ? this.props.values.findIndex(i => parseInt(i) === parseInt(option.value)) !== -1 : false}
                                        onChange={e => this.handleChangeItem(e, option)}
                                    />
                                    <label className="form-check-label user-select-none" htmlFor={`${this.props.name}${index}`}>{this.getOptionLabel(option)}</label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>;

        return this.getRenderFormControl(render);
    }
}