import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ModuloService from "../../services/ModuloService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectGrupoModulo from "../../components/app/SelectGrupoModulo";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import PerfilService from "../../services/PerfilService";

class FormModulo extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangePerfil = this.handleChangePerfil.bind(this);
        this.handleChangePerfilAll = this.handleChangePerfilAll.bind(this);

        this.state.isCard = true;

        this.state.model = {
            idModulo: "",
            nome: "",
            idGrupoModulo: "",
            flBloqueiaDebito: false,
            moduloPerfil: []
        };

        this.state.crud = {
            service: ModuloService,
            labels: {
                title: 'Modulo',
            },
            urlList: '/LstModulo',
        };

        this.state.perfis = [];
    }

    componentDidMount() {
        super.componentDidMount();

        PerfilService.list((response) => {
            this.setState({ perfis: response });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangePerfil(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.moduloPerfil.push(item);
                return state;
            });
        } else {
            var index = this.state.model.moduloPerfil.findIndex(i => parseInt(i.idPerfil) === parseInt(item.idPerfil));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.moduloPerfil.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangePerfilAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.moduloPerfil = [];

                this.state.perfis.forEach(function (item) {
                    state.model.moduloPerfil.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.moduloPerfil = [];
                return state;
            });
        }
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-dados-tab" data-bs-toggle="tab" data-bs-target="#nav-dados" type="button" role="tab" aria-controls="nav-dados" aria-selected="true">
                        Dados
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-perfil-tab" data-bs-toggle="tab" data-bs-target="#nav-perfil" type="button" role="tab" aria-controls="nav-perfil" aria-selected="false">
                        Perfil Liberado
                    </button>
                </li>
            </ul>);
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">
                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={3} value={this.state.model.idModulo} name="idModulo" readOnly opcionalLabel={false} />
                    </div>
                    <div className="row mb-3 g-3">
                        <InputText label="Nome do Módulo" autoFocus={true} required md={6} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                        <SelectGrupoModulo label="Nome do Grupo" required placeholder="Selecione o Grupo" name="idGrupoModulo" md={6} value={this.state.model.idGrupoModulo} onChange={this.handleChange} />
                    </div>
                    <div className="row mx-1 mb-3 g-1">
                        <InputCheckSwitch label="Bloqueia quando tiver débito?" name="flBloqueiaDebito" checked={Boolean(this.state.model.flBloqueiaDebito)} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-perfil" role="tabpanel" aria-labelledby="nav-perfil-tab">
                    <table className="table table-sm table-hover caption-top">
                        <caption className="text-end">
                            {this.state.perfis.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th>
                                    <InputCheckSwitch name="todos" onChange={this.handleChangePerfilAll} checked={this.state.model.moduloPerfil.length === this.state.perfis.length} />
                                </th>
                                <th className="col-md-12"> Nome do Perfil </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.perfis.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <InputCheckSwitch name="perfil" id={`ch-perfil-${item.idPerfil}`} checked={this.state.model.moduloPerfil.findIndex(i => parseInt(i.idPerfil) === parseInt(item.idPerfil)) !== -1} onChange={(e) => { this.handleChangePerfil(e, item) }} />
                                    </td>
                                    <td>{item.nome} </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormModulo {...props} navigate={navigate} params={params} />
}

export default With