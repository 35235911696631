import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ModeloService from "../../services/ModeloService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectTransmissao from "../../components/app/SelectTransmissao";
import SelectCombustivel from "../../components/app/SelectCombustivel";
import InputNumber from "../../components/forms/elements/InputNumber";
import SelectMarca from "../../components/app/SelectMarca";

class FormModelo extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idModelo: "",
            nome: "",
            dsModelo: "",
            nrAno: "",
            idMarca: "",
            idTransmissao: "",
            idCombustivel: "",
            dsPotencia: "",
            dsMotor: "",

        };

        this.state.crud = {
            service: ModeloService,
            labels: {
                title: 'Modelo',
            },
            urlList: '/LstModelo',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={2} value={this.state.model.idModelo} name="idModelo" readOnly opcionalLabel={false} />
                <InputText label="Nome do Modelo" autoFocus={true} required md={5} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                <InputText label="Modelo" md={3} value={this.state.model.dsModelo} name="dsModelo" onChange={this.handleChange} maxLength={100} />
                <InputNumber className="text-center" label="Ano" placeholder="9999" required md={2} value={this.state.model.nrAno} name="nrAno" onChange={this.handleChange} min={1900} step={1} />
                <SelectMarca md={4} label="Marca" placeholder="Selecione a Marca" name="idMarca" value={this.state.model.idMarca} onChange={this.handleChange} required />
                <SelectCombustivel showBtns={false} md={4} label="Combustível" required placeholder="Selecione o Combustível" name="idCombustivel" value={this.state.model.idCombustivel} onChange={this.handleChange} />
                <SelectTransmissao showBtns={false} md={4} label="Transmissão" placeholder="Selecione a Transmissão" name="idTransmissao" value={this.state.model.idTransmissao} onChange={this.handleChange} />
                <InputText label="Potência" md={6} value={this.state.model.dsPotencia} name="dsPotencia" onChange={this.handleChange} maxLength={100} />
                <InputText label="Motor" md={6} value={this.state.model.dsMotor} name="dsMotor" onChange={this.handleChange} maxLength={100} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormModelo {...props} navigate={navigate} params={params} />
}

export default With