import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProdutoServicoEntradaService from "../../services/ProdutoServicoEntradaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectProdutoServicoByTipoAgrupador from "../../components/app/SelectProdutoServicoByTipoAgrupador";
import ProdutoServicoService from "../../services/ProdutoServicoService";

class FormAgrupar extends CrudForm {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state.model = {
            idTipoProdutoServico: this.props.idTipoProdutoServico !== undefined ? this.props.idTipoProdutoServico : "",
            idProdutoServicoPai: "",
            itens : this.props.itens !== undefined ? this.props.itens : []
        };

        this.state.crud = {
            service: ProdutoServicoEntradaService,
            labels: {
                title: 'Entrada',
            },
            urlList: '/LstProdutoServicoEntrada',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event, fnCallBack = false, flContinue = false) {
        ProdutoServicoService.agrupar(this.state.model, (response) => {
            this.onSave(response);            
        });
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <SelectProdutoServicoByTipoAgrupador idTipoProdutoServico={this.state.model.idTipoProdutoServico} required={true} md={12} label="Agrupador PAI" placeholder="Selecione o Produto" name="idProdutoServicoPai" value={this.state.model.idProdutoServicoPai} onChange={this.handleChange} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormAgrupar {...props} navigate={navigate} params={params} />
}

export default With