import { Component } from "react";
import FormMask from "../base/FormMask";

export default class InputPlaca extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        return (
            <FormMask {...this.props}
                style={{ textTransform: "uppercase" }}
                onChange={this.handleChange}
                mask="aaa-9*99"
                pattern="[a-zA-Z]{3}-\d{1}[a-zA-Z0-9]{1}\d{2}"
                placeholder="BDM-9X99"
            />);
    }
}