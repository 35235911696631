import ModuloService from "../../services/ModuloService";
import FormSelectSearchModal from "../forms/base/FormSelectSearchModal";
import FormModulo from "../../pages/Modulo/FormModulo";
import Permissao from "../../controllers/Permissao";

export default class SelectModulo extends FormSelectSearchModal {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);

        this.state.showBtns = this.props.showBtns === undefined ? true : this.props.showBtns;
        this.state.showBtnSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
        this.state.showBtnNewForm = this.state.showBtns && ((this.props.showBtnNewForm === undefined ? true : false) && Permissao.getInstance().getPermissao(3));
        this.state.showBtnNewSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
    }

    getConfigFormNew() {
        return {
            title: 'Cadastro de Módulo',
            sizeModal: 'modal-xl',
            config: {
                isModal: true
            },
            form: FormModulo,
            onSave: (response) => {
                this.getOptions(() => {
                    if (response.data?.idModulo) {
                        this.handleOpenSearch(null, response.data?.idModulo);
                    } else {
                        this.handleOpenSearch();
                    }
                });
            },
            onClose: () => {
                //this.handleOpenSearch();
            }
        };
    };

    componentDidMount() {
        super.componentDidMount();
        this.getOptions(() => {
            if (this.dispachChangeDidMount && this.props.value) {
                this.handleChange({ target: { value: this.props.value, name: this.props.name } });
            }
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        ModuloService.getForCombo((response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}