import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import DashboardService from "../../services/DashboardService";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import IsMobile from "is-mobile";
import Util from "../../utils/Util";
import MostraValor from "../../components/app/MostraValor";
import Permissao from "../../controllers/Permissao";

export default class Top10Ranking extends MyComponent {

    constructor(props) {
        super(props);

        this.getLista = this.getLista.bind(this);
        this.handleClickVoltar = this.handleClickVoltar.bind(this);
        this.handleClickAvancar = this.handleClickAvancar.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            model: {
                flFiltroCdTipo: "S",
                flFiltroCdQtde : "FULL",
                maxMes: IsMobile() ? 1 : 4,
                anoFinal: (new Date()).getFullYear(),
                mesFinal: (new Date()).getMonth() + 1
            },
            dataAbsoluto: [],
            dataValor: [],

            arrAbsoluto : [],
            arrValor : [],
        };


        this.filtroTipo = [
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ];

        this.filtroQtde = [
            { value: "T10", label: "Top 10" },
            { value: "FULL", label: "Geral" },
        ];

        this.arrTop10 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        this.arrMaxMes = [{ value: 1, label: 1 }, { value: 3, label: 3 }, { value: 4, label: 4 }, { value: 6, label: 6 }, { value: 12, label: 12 }]
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    podeVerValorRankingReal() {
        return Permissao.getInstance().getPermissao(127);
    }

    podeVerValorRankingAbsoluto() {
        return true;
    }

    getLista() {
        DashboardService.getTop10RankingAbsoluto(this.state.model, (response) => {
            
            var arrAbsoluto = [];

            if(this.state.model.flFiltroCdQtde === "FULL"){
                var maxLength = 0;            
                response.forEach(element => {
                    maxLength = element.dados.length-1 > maxLength ? element.dados.length-1 : maxLength;
                });

                for(var i=0;i<maxLength;i++){
                    arrAbsoluto.push(i);
                }
            }else{
                arrAbsoluto = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            }

            this.setState({ dataAbsoluto: response, arrAbsoluto : arrAbsoluto });
        });

        DashboardService.getTop10RankingValor(this.state.model, (response) => {

            var arrValor = [];
            if(this.state.model.flFiltroCdQtde === "FULL"){
                var maxLength = 0;            
                response.forEach(element => {
                    maxLength = element.dados.length-1 > maxLength ? element.dados.length-1 : maxLength;
                });

                for(var i=0;i<maxLength;i++){
                    arrValor.push(i);
                }
            }else{
                arrValor = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
            }

            this.setState({ dataValor: response, arrValor : arrValor });
        });
    }

    handleClickVoltar(event) {
        this.setState((state) => {
            state.model.mesFinal = state.model.mesFinal - 1;
            if (state.model.mesFinal === 0) {
                state.model.mesFinal = 12;
                state.model.anoFinal = state.model.anoFinal - 1;
            }
        }, () => {
            this.getLista();
        })
    }

    handleClickAvancar(event) {
        this.setState((state) => {
            state.model.mesFinal = state.model.mesFinal + 1;
            if (state.model.mesFinal === 13) {
                state.model.mesFinal = 1;
                state.model.anoFinal = state.model.anoFinal + 1;
            }
        }, () => {
            this.getLista();
        })
    }

    getHeaderAno() {
        var cells = [];
        var arrAnos = this.state.dataAbsoluto.map(item => item.ano).filter((value, index, self) => self.indexOf(value) === index);
        arrAnos.forEach(ano => {
            let colSpan = this.state.dataAbsoluto.filter(d => d.ano === ano).length;
            cells.push(<th className="text-center" key={ano} colSpan={(2 * colSpan)}> {ano}</th>);
        });

        return <>{cells}</>;
    }

    getDadosRepresentante(mes, indexLinha, dashboard) {

        var posicao = <></>;

        if (mes.dados[indexLinha]) {

            if (mes.dados[indexLinha]?.statusPosicao === "N") {
                posicao = (<></>)
            } else if (mes.dados[indexLinha]?.statusPosicao === "U") {
                posicao = (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill text-success fw-bold" viewBox="0 0 16 16">
                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                        </svg>
                    </>);
            } else if (mes.dados[indexLinha]?.statusPosicao === "D") {
                posicao = (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill text-danger fw-bold" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                    </>);
            } else if (mes.dados[indexLinha]?.statusPosicao === "I") {
                posicao = (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stop-fill text-primary" viewBox="0 0 16 16">
                            <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z" />
                        </svg>
                    </>);
            }
        }

        if (dashboard === "absoluto") {
            if(this.podeVerValorRankingAbsoluto()){
                return (<>
                    <td className={`${mes.dados[indexLinha]?.flRepresentanteUsuario ? "fw-bold" : ""}`}> {mes.dados[indexLinha]?.nomeRepresentante} {posicao}</td>
                    <td className={`text-center`}> {mes.dados[indexLinha]?.quantidade} </td>
                </>);
            }else{
                return (<>
                    <td colSpan={2} className={`${mes.dados[indexLinha]?.flRepresentanteUsuario ? "fw-bold" : ""}`}> {mes.dados[indexLinha]?.nomeRepresentante} {posicao}</td>
                </>);
            }
        }

        if (dashboard === "valor") {
            if(this.podeVerValorRankingReal()){
                return (<>
                    <td className={`${mes.dados[indexLinha]?.flRepresentanteUsuario ? "fw-bold" : ""}`}> {mes.dados[indexLinha]?.nomeRepresentante} {posicao}</td>
                    <td className={`text-center`}> <MostraValor>{Util.floatToReais(mes.dados[indexLinha]?.valor)}</MostraValor></td>
                </>);
            }else{
                return (<>
                    <td colSpan={2} className={`${mes.dados[indexLinha]?.flRepresentanteUsuario ? "fw-bold" : ""}`}> {mes.dados[indexLinha]?.nomeRepresentante} {posicao}</td>
                </>);
            }
        }
    }

    render() {
        return (
            <>
                <div className="hstack mb-3">
                    <div className="d-grid gap-1 d-flex flex-nowrap">
                        <BtnButton className="btn-outline-primary" onClick={this.handleClickVoltar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg>
                        </BtnButton>
                        <BtnButton className="btn-outline-primary" onClick={this.handleClickAvancar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </BtnButton>
                    </div>
                    <div className="ms-auto d-flex flex-nowrap">
                        <BtnGroup className="w-100" name="flFiltroCdQtde" value={this.state.model.flFiltroCdQtde} options={this.filtroQtde} onChange={(e) => { this.handleChange(e, this.getLista) }} opcionalLabel={false} />

                        <BtnGroup className="w-100 mx-2" name="flFiltroCdTipo" value={this.state.model.flFiltroCdTipo} options={this.filtroTipo} onChange={(e) => { this.handleChange(e, this.getLista) }} opcionalLabel={false} />

                        <div className="ms-3 me-2">
                            <BtnButton className="btn-outline-primary" onClick={this.getLista}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg>
                            </BtnButton>
                        </div>

                        <BtnGroup md={3} className="w-100" name="maxMes" value={this.state.model.maxMes} options={this.arrMaxMes} onChange={e => this.handleChange(e, this.getLista)} opcionalLabel={false} />
                    </div>
                </div>

                <h5 className="border-bottom border-dark p-2 mb-4"> Ranking por Valor Real </h5>

                <div className="table-responsive">
                    <table className="table table-striped table-bordered border-success table-sm table-hover" style={{ fontSize: "14px" }}>
                        <thead>
                            <tr>
                                <th className="text-center align-middle" rowSpan={2}>#</th>
                                {this.getHeaderAno()}
                            </tr>
                            <tr>
                                {this.state.dataValor.map((mes, indexMes) => (
                                    <th className={`col-${parseInt(12 / this.state.model.maxMes)} text-center`} key={indexMes} colSpan={2}>
                                        {mes.nomeMes}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.arrAbsoluto.map((indexLinha) => (
                                <tr key={indexLinha}>
                                    <td className="text-end fw-bold">
                                        {(indexLinha) + 1}º
                                    </td>
                                    {this.state.dataValor.map((mes, indexMes) => (
                                        <React.Fragment key={indexMes}>
                                            {this.getDadosRepresentante(mes, indexLinha, "valor")}
                                        </React.Fragment>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <h5 className="border-bottom border-dark p-2 mb-4"> Ranking por Quantidade Absoluto </h5>

                <div className="table-responsive">
                    <table className="table table-striped table-bordered border-success table-sm table-hover" style={{ fontSize: "14px" }}>
                        <thead>
                            <tr>
                                <th className="text-center align-middle" rowSpan={2}>#</th>
                                {this.getHeaderAno()}
                            </tr>
                            <tr>
                                {this.state.dataAbsoluto.map((mes, indexMes) => (
                                    <th className={`col-${parseInt(12 / this.state.model.maxMes)} text-center`} key={indexMes} colSpan={2}>
                                        {mes.nomeMes}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.arrValor.map((indexLinha) => (
                                <tr key={indexLinha}>
                                    <td className="text-end fw-bold">
                                        {(indexLinha) + 1}º
                                    </td>
                                    {this.state.dataAbsoluto.map((mes, indexMes) => (
                                        <React.Fragment key={indexMes}>
                                            {this.getDadosRepresentante(mes, indexLinha, "absoluto")}
                                        </React.Fragment>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}