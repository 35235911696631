import ContatoStatusService from "../../services/ContatoStatusService";
import SelectContatoStatus from "./SelectContatoStatus";

export default class SelectContatoStatusByContatoTipo extends SelectContatoStatus {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.idContatoTipo !== this.props.idContatoTipo) {
            this.getOptions();
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        var ativo = this.props.ativo === undefined ? null : this.props.ativo;

        if (this.props.idContatoTipo) {
            ContatoStatusService.getForComboByContatoTipo(this.props.idContatoTipo, ativo, (response) => {
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();
                    }
                });
            });
        } else {
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    render() {
        return super.render();
    }
}