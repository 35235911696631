import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import UnidadeMedidaService from "../../services/UnidadeMedidaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputNumber from "../../components/forms/elements/InputNumber";

class FormUnidadeMedida extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idUnidadeMedida: "",
            nome: "",
            nrCasasDecimais: 0,
            sigla: ""
        };

        this.state.crud = {
            service: UnidadeMedidaService,
            labels: {
                title: 'Unidade de Medida',
            },
            urlList: '/LstUnidadeMedida',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idUnidadeMedida} name="idUnidadeMedida" readOnly opcionalLabel={false}/>
                </div>
                <div className="row mb-3 g-3">
                    <InputText label="Nome da Unidade de Medida" autoFocus={true} required md={12} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <InputNumber label="Casas Decimais" placeholder="99" required md={6} value={this.state.model.nrCasasDecimais} name="nrCasasDecimais" onChange={this.handleChange} min={0} max={8} step={1} />
                    <InputText label="Sigla" required md={6} value={this.state.model.sigla} name="sigla" onChange={this.handleChange} maxLength={100} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormUnidadeMedida {...props} navigate={navigate} params={params} />
}

export default With