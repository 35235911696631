import React from "react";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import TransacaoService from "../../services/TransacaoService";
import InputCartaoNumero from "../../components/forms/custom/InputCartaoNumero";
import InputCartaoValidade from "../../components/forms/custom/InputCartaoValidade";
import InputCartaoCVV from "../../components/forms/custom/InputCartaoCVV";
import { Link } from "react-router-dom";
import ModalInfo from "../../components/modal/ModalInfo";
import EventBus from "../../controllers/EventBus";

export default class FormCartao extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.getTransacao = this.getTransacao.bind(this);
        this.getStatusTransacao = this.getStatusTransacao.bind(this);
        this.startControle = this.startControle.bind(this);
        this.setTempoDecorrido = this.setTempoDecorrido.bind(this);
        this.checkVisitorId = this.checkVisitorId.bind(this);

        this.state.model = {
            uidTransacao: this.props.params?.uid,
            visitorId: window.visitorID === undefined ? "" : window.visitorID,
            ccName: "",
            ccNumber: "",
            ccCvv: "",
            ccExpiration: "",
            ccType: "",

            ddi: "55",            
            celular: "",
            nome: "",
            email: "",
            cdTipoPessoa: "F",
            cpf: "",
            cnpj: "",

            idPais: 1,
            idEstado: "",
            idCidade: "",
            cep: "",
            bairro: "",
            endereco: "",
            numero: "",
            complemento: ""
        };

        this.state.transacao = {
            vlTransacao: 0,
            nrParcela: 1
        };

        this.state.controle = {
            inverval: null,
            decorrido: 0
        };

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" }
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getTransacao();
        this.checkVisitorId();
    }

    getTransacao() {
        TransacaoService.getOneByUid(this.props.params?.uid, (response) => {
            this.setState({ transacao: response });

            try {
                if (response.faturaPessoa) {
                    this.setState(state => {
                        state.model.ddi = response.faturaPessoa.ddi ? response.faturaPessoa.ddi : state.model.ddi;
                        state.model.celular = response.faturaPessoa.telefone ? response.faturaPessoa.telefone : state.model.celular;
                        state.model.nome = response.faturaPessoa.nome ? response.faturaPessoa.nome : state.model.nome;
                        state.model.email = response.faturaPessoa.email ? response.faturaPessoa.email : state.model.email;
                        state.model.cdTipoPessoa = response.faturaPessoa.cdTipoPessoa ? response.faturaPessoa.cdTipoPessoa : state.model.cdTipoPessoa;
                        state.model.cpf = response.faturaPessoa.cpf ? response.faturaPessoa.cpf : state.model.cpf;
                        state.model.cnpj = response.faturaPessoa.cnpj ? response.faturaPessoa.cnpj : state.model.cnpj;
                        state.model.idPais = response.faturaPessoa.idPais ? response.faturaPessoa.idPais : state.model.idPais;
                        state.model.idEstado = response.faturaPessoa.idEstado ? response.faturaPessoa.idEstado : state.model.idEstado;
                        state.model.idCidade = response.faturaPessoa.idCidade ? response.faturaPessoa.idCidade : state.model.idCidade;
                        state.model.cep = response.faturaPessoa.cep ? response.faturaPessoa.cep : state.model.cep;
                        state.model.bairro = response.faturaPessoa.bairro ? response.faturaPessoa.bairro : state.model.bairro;
                        state.model.endereco = response.faturaPessoa.endereco ? response.faturaPessoa.endereco : state.model.endereco;
                        state.model.numero = response.faturaPessoa.numero ? response.faturaPessoa.numero : state.model.numero;
                        state.model.complemento = response.faturaPessoa.complemento ? response.faturaPessoa.complemento : state.model.complemento;
                        return state;
                    });
                }
            } catch (error) {
            }
        });
    }

    checkVisitorId() {
        if (!this.state.model.visitorId) {
            EventBus.dispatch("modal-info-cartao-open", {
                config: {
                    title: "Algo deu errado!",
                    message: "Ocorreu um erro no processo de transação com o Antifraude!",
                    description: "Se o erro persistir, feche o navegador e tente novamente",
                    style: "danger",
                    confirm: () => {
                        this.toPagamento();
                    }
                },
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.state.controle.inverval);
    }

    getBtns() {
        return (<></>);
    }

    getStatusTransacao() {
        TransacaoService.getStatus(this.props.params?.uid, (response) => {
            if (parseInt(response.flEfetivada) === 1) {
                this.toPagamento()
            }
        });
    }

    startControle() {
        var tempo = 60 * 1000 * 5; // 5 Minutos
        var decorrido = tempo;

        this.setState(state => {
            state.controle.interval = setInterval(() => {
                if (decorrido <= 0) {
                    clearInterval(this.state.controle.interval);
                    this.toPagamento()
                    return;
                }

                if (decorrido % 5000 === 0) {
                    this.getStatusTransacao();
                }

                decorrido -= 1000;
                this.setTempoDecorrido(decorrido);
            }, 1000);
            return state;
        });
    }

    setTempoDecorrido(decorrido) {
        this.setState(state => {
            state.controle.decorrido = decorrido;
            return state;
        });
    }

    onSubmit(event) {
        TransacaoService.pagarCartao(this.props.params?.uid, this.state.model, (response) => {

            EventBus.dispatch("modal-info-cartao-open", {
                //id: item.idBaixa,
                config: {
                    title: "Tudo certo!",
                    message: response.message,
                    description: response.description,
                    style: "success",
                    confirm: () => {
                        this.toPagamento();
                    }
                }
            });

        }, (error) => {
            EventBus.dispatch("modal-info-cartao-open", {
                //id: item.idBaixa,
                config: {
                    title: "Algo deu errado!",
                    message: error?.response?.data?.message,
                    description: error?.response?.data?.description,
                    style: "danger"
                },

            });
        });
    }

    getRenderFields() {
        return (
            <div className="col-xl-8 mx-auto">
                <header>
                    <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                        <a href="https://bdmperformance.com.br/" className="d-flex align-items-center text-dark text-decoration-none">
                            <img src="./logo-preto.png" alt="Logo BDM" />
                        </a>

                        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://www.instagram.com/bdmperformance/">Nosso Instagram</a>
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://bdmperformance.com.br/">Nosso Site</a>
                        </nav>
                    </div>

                    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                        <h1 className="display-5 fw-normal">Pagamento com cartão de {this.state.model.ccType === 'D' ? 'débito' : 'crédito'}</h1>
                        <p className="fs-5 text-muted">
                            Pagamento da transação através do cartão de <strong>{this.state.model.ccType === 'D' ? 'débito' : 'crédito'}</strong>, no valor de <strong>{Util.floatToReais(this.state.transacao?.vlTransacao)}</strong>
                            <strong>{this.state.model.ccType === 'C' && parseInt(this.state.transacao?.nrParcela) > 1 ? " parcelado em " + this.state.transacao?.nrParcela + " vezes" : ""}</strong>
                            <strong>{this.state.model.ccType === 'C' && parseInt(this.state.transacao?.nrParcela) === 1 ? " à vista" : ""}</strong>.
                        </p>
                        <div className="text-center text-decoration-none">
                            <Link to={`/pagamento/${this.state.transacao.uidCobranca}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                </svg> Escolher outra forma de pagamento
                            </Link>
                        </div>
                    </div>
                </header>

                <div className="card mt-4 border-secondary">
                    <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">
                        Dados do Cartão
                    </div>
                    <div className="card-body">
                        <div className="row mb-3 g-3">
                            <InputText label="Nome Impresso no Cartão" required={true} placeholder="Exatamente como no cartão" name="ccName" id="cc-name" lg={12} value={this.state.model.ccName} onChange={this.handleChange} />
                            <InputCartaoNumero label="Número do cartão" required={true} name="ccNumber" id="cc-number" lg={6} value={this.state.model.ccNumber} onChange={this.handleChange} />
                            <InputCartaoValidade label="Validade" required={true} name="ccExpiration" id="cc-expiration" lg={3} value={this.state.model.ccExpiration} onChange={this.handleChange} />
                            <InputCartaoCVV label="CVV" required={true} name="ccCvv" id="cc-cvv" lg={3} value={this.state.model.ccCvv} onChange={this.handleChange} />
                        </div>
                    </div>
                </div>

                <div className="hstack gap-2 mt-4 mb-4">
                    <BtnSubmit className="btn-primary"> Confirmar Pagamento </BtnSubmit>
                    <Link to={`/pagamento/${this.state.transacao.uidCobranca}`} className="btn btn-outline-secondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                        </svg> Voltar
                    </Link>
                </div>

                <ModalInfo id="modal-info-cartao" />
            </div>

        );
    }

    render() {
        return super.render();
    }
}