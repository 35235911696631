import React from "react";
import CrudForm from "../../components/forms/custom/CrudForm";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import Util from "../../utils/Util";
import Config from "../../Config";
export default class FormRelatorioFatura002 extends CrudForm {

    constructor(props) {
        super(props);

        this.state.isModal = true;

        this.urlRelatorio = "relatorio/fatura-002";

        this.state.model = {
            idFaturas: props.idFaturas,
            destination: "I",
        };

        this.state.orientation = [
            { value: 'P', label: 'Vertical' },
            { value: 'L', label: 'Horizontal' },
        ]

        this.state.destination = [
            { value: 'I', label: 'Tela' },
            { value: 'D', label: 'Download' },
        ]
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(e) {
        Util.openNewWindow(Config.getUrlApi() + this.urlRelatorio, this.state.model);
    }

    getRenderFields() {
        return (
            <>
                <div className="row px-3 mb-3">
                </div>
            </>
        );
    }

    getBtns() {
        return (
            <div className="hstack gap-2">
                <BtnButton className="ms-auto btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
                <div className="vr"></div>
                <BtnSubmit className="btn-primary"> Emitir Etiquetas </BtnSubmit>
            </div>);
    }

    render() {
        return super.render();
    }
}