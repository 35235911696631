import React, { Component } from "react";
import EventBus from "../../controllers/EventBus";

export default class ModalForm extends Component {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleKeyUpModal = this.handleKeyUpModal.bind(this);

        this.state = {
            zIndex: props.zIndex || 0,
            show: true,
            title: props.title || "",
            onSave: props.onSave || (() => { }),
            onClose: props.onClose || (() => { }),
            sizeModal: props.sizeModal ? props.sizeModal : "", //modal-sm | modal-lg | modal-xl | "" = none = Medium
            scrollable: props.scrollable !== undefined ? props.scrollable : true,
            fullScreen: props.fullScreen ? props.fullScreen : false,
            showBtnClose: props.showBtnClose === undefined ? true : props.showBtnClose,

            form: props.form || null,
            config: props.config || {}
        };
    }

    componentDidMount() {

    }

    componentWillUnmount() {
    }

    handleClose(event) {
        this.setState({ show: false, form: null });
        this.state.onClose(event);
        EventBus.dispatch("modal-del");
    }

    handleSave(event) {
        this.setState({ show: false, form: null });
        this.state.onSave(event);
        EventBus.dispatch("modal-del");
    }

    handleKeyUpModal(event) {
        if (event.keyCode === 27) {
            this.handleClose(event);
        }
    }

    render() {
        var FormModal = this.state.form;
        return (
            <>
                <div style={{ zIndex: 1000 + (this.state.zIndex * 2) }} className={`fade modal-backdrop show ${this.state.show ? "" : "d-none"}`}> </div>
                <div style={{ zIndex: 1001 + (this.state.zIndex * 2) }} className={`modal m-1 ${this.state.show ? "d-block" : "d-none"}`} tabIndex="-1"
                    ref={this.state.ref}
                    onKeyUp={this.handleKeyUpModal}
                    aria-hidden="false" aria-modal="true">
                    <div className={`modal-dialog pe-4 ${this.state.scrollable ? 'modal-dialog-scrollable' : ''} ${this.state.sizeModal} ${this.state.fullScreen ? 'modal-fullscreen p-3' : ''}`}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {this.state.title}
                                </h5>
                                {this.state.showBtnClose ? <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleClose}></button> : <></>}
                            </div>
                            <div className="modal-body p-3 pt-0">
                                {FormModal ? <FormModal {...this.state.config} onSave={this.handleSave} onClose={this.handleClose} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
