import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import Util from "../../utils/Util";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import EventBus from "../../controllers/EventBus";
import FormFoto from "./FormFoto";
import FormOrdem from "../Ordem/FormOrdem";
import FormProdutoServico from "../ProdutoServico/FormProdutoServico";
import SelectCategoriaProdutoServicoByUsuario from "../../components/app/SelectCategoriaProdutoServicoByUsuario";
import SelectOrdemTipoUsuario from "../../components/app/SelectOrdemTipoUsuario";
import Config from "../../Config";
import BtnButton from "../../components/forms/elements/BtnButton";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import { toast } from "react-toastify";
import ModalInfo from "../../components/modal/ModalInfo";
import Permissao from "../../controllers/Permissao";
import ListGroupProdutoServicoAgrupador from "../../components/app/ListGroupProdutoServicoAgrupador";

export default class ListaLoja extends MyComponent {

    constructor(props) {
        super(props);

        this.clear = this.clear.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLista = this.getLista.bind(this);
        this.handleOpenModalFoto = this.handleOpenModalFoto.bind(this);
        this.handleAddCarrinho = this.handleAddCarrinho.bind(this);
        this.handleDelCarrinho = this.handleDelCarrinho.bind(this);
        this.getBtnAdicionarItem = this.getBtnAdicionarItem.bind(this);
        this.handleOpenModalOrdem = this.handleOpenModalOrdem.bind(this);
        this.getInitModel = this.getInitModel.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.handleClickEditProduto = this.handleClickEditProduto.bind(this);
        this.handleClickItemProdutoServicoPai = this.handleClickItemProdutoServicoPai.bind(this);
        this.podeAgruparItem = this.podeAgruparItem.bind(this);
        this.handleClickAgrupar = this.handleClickAgrupar.bind(this);

        this.state = {
            data: [],
            model: this.getInitModel(),
            carrinho: []
        };

        this.state.filtroTipo = [
            { value: "T", label: "Todos" },
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ]

        this.state.filtroFormatView = [
            { value: "C", label: "Card" },
            { value: "T", label: "Tabela" }
        ]
    }

    getInitModel(props) {
        return {
            idOrdemTipo: "1",
            idTipoProdutoServico: "",
            idCategoriaProdutoServico: "",
            flProdutoEmPromocao: false,
            flMaisVendidoCategoriaProdutoServico: false,
            flNovoLoja : false,
            flFiltroCdTipo: "T",
            busca: "",
            cdFormatView: "C",
            pagina: 0,
            rowCount: 40,      
            produtoServicoPai : {

            }
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    clear() {
        this.setModel(this.getInitModel(), () => {
            this.getLista();
        });
    }

    getLista(merge = false) {

        if (merge) {
            ProdutoServicoService.listLoja(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                ProdutoServicoService.listLoja(this.state.model, (response) => {
                    this.setState({ data: response });
                });
            });
        }
    }

    onSubmit(event) {
        this.getLista();
    }

    handleOpenModalFoto(item) {
        EventBus.dispatch("modal-add", {
            title: 'Fotos do Produto',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                idProdutoServico: item.idProdutoServico
            },
            form: FormFoto
        });
    }

    getPrecoValor(item) {

        if(Boolean(item.flAgrupador) && item?.produtoServicoFilho?.length > 0){
            let itemMin = item?.produtoServicoFilho.reduce(function (acc, i) {
                return ( acc === undefined || i.vlPreco < acc.vlPreco ? i : acc );
            });

            return (
                <>
                    <span className="fs-7">
                        A partir de &nbsp;
                    </span>
                    <span className="fs-4">
                        {this.getPartPrecoValor(Util.floatToReais(itemMin.vlPreco))}
                    </span>
                    <span className="fs-7">
                        ,{this.getPartPrecoDecimal(Util.floatToReais(itemMin.vlPreco))}
                    </span>
                </>
            );    
        }

        return (
            <>
                <span className="fs-4">
                    {this.getPartPrecoValor(Util.floatToReais(item.vlPreco))}
                </span>
                <span className="fs-7">
                    ,{this.getPartPrecoDecimal(Util.floatToReais(item.vlPreco))}
                </span>
            </>
        );    
    }

    getPartPrecoValor(vlPreco) {
        var retorno = vlPreco.toString().split(",");
        return retorno[0];
    }

    getPartPrecoDecimal(vlPreco) {
        var retorno = vlPreco.toString().split(",");
        return retorno[1];
    }

    getValueEstoque(item) {
        if (item.flControlaEstoque) {
            var vlEstoque = parseFloat(item.vlEstoque).toFixed(item.nrCasasDecimais);
            if (item.vlEstoque === 0 || item.vlEstoque < 0) {
                return (<>indisponível</>);
            } else {
                return (<>{Util.formatEstoque(vlEstoque)} disponíveis</>);
            }
        }
        return (<></>);
    }

    handleClickAgrupar(item) {
        var model = {
            idProdutoServicoPai: this.state.model.produtoServicoPai.idProdutoServico,
            itens : [item.idProdutoServico]
        };
        
        ProdutoServicoService.agrupar(model, (response) => {
            this.getLista();
        });
    }

    podeAgruparItem(item) {        
        return parseInt(item?.idTipoProdutoServico) === parseInt(this.state.model.produtoServicoPai?.idTipoProdutoServico);
    }

    getBtnAdicionarItem(item) {

        if(Boolean(item.flAgrupador)){
            return (
                <div className="dropdown">
                    <button className="btn btn-success btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-x" viewBox="0 0 16 16">
                            <path d="M7.354 5.646a.5.5 0 1 0-.708.708L7.793 7.5 6.646 8.646a.5.5 0 1 0 .708.708L8.5 8.207l1.146 1.147a.5.5 0 0 0 .708-.708L9.207 7.5l1.147-1.146a.5.5 0 0 0-.708-.708L8.5 6.793 7.354 5.646z" />
                            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg> Adicionar &nbsp;
                    </button>
                    <ul className="dropdown-menu">
                        {item.produtoServicoFilho.map((pr, ix) => {
                            let disabled = this.state.carrinho.find(i => parseInt(i.idProdutoServico) === parseInt(pr.idProdutoServico));

                            return (
                                <li key={ix}>
                                    <button type="button" className="dropdown-item" onClick={() => { this.handleAddCarrinho(pr) }} disabled={disabled}>
                                        {pr.nome} - <strong>{Util.floatToReais(pr.vlPreco)}</strong>
                                    </button>
                                </li>
                            );
                        })}
                        
                    </ul>
                </div>
            );
        }
        

        if (item.flControlaEstoque && parseFloat(item.vlEstoque) <= 0) {
            return (
                <BtnButton className="btn-outline-secondary btn-sm" disabled={true}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-plus" viewBox="0 0 16 16">
                        <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg> Adicionar
                </BtnButton>);
        }

        var itemCarrinho = null;

        itemCarrinho = this.state.carrinho.find(i => parseInt(i.idProdutoServico) === parseInt(item.idProdutoServico));
        if (itemCarrinho) {
            return (
                <BtnButton className="btn-outline-secondary btn-sm" disabled={true}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-check" viewBox="0 0 16 16">
                        <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg> Adicionado
                </BtnButton>);
        }

        itemCarrinho = this.state.carrinho.find(i => parseInt(i.idTipoProdutoServico) !== parseInt(item.idTipoProdutoServico));

        if (itemCarrinho) {
            return (
                <BtnButton className="btn-warning btn-sm" onClick={this.handleAvisoTipoProdutoServico}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-x" viewBox="0 0 16 16">
                        <path d="M7.354 5.646a.5.5 0 1 0-.708.708L7.793 7.5 6.646 8.646a.5.5 0 1 0 .708.708L8.5 8.207l1.146 1.147a.5.5 0 0 0 .708-.708L9.207 7.5l1.147-1.146a.5.5 0 0 0-.708-.708L8.5 6.793 7.354 5.646z" />
                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg> Adicionar
                </BtnButton>);
        }

        return (
            <BtnButton className="btn-primary btn-sm" onClick={e => { this.handleAddCarrinho(item) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-plus" viewBox="0 0 16 16">
                    <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg> Adicionar
            </BtnButton>
        );
        
    }

    handleAvisoTipoProdutoServico() {
        EventBus.dispatch("modal-info-aviso-open", {
            config: {
                title: "Atenção!",
                message: "Esse produto é de uma categoria diferente dos demais que já estão no carrinho!",
                description: "Para adicionar esse produto, é necessário finalizar a ordem dos produtos que já estão no carrinho ou remover todo os produtos do carrinho.",
                style: "warning",
                confirm: () => {

                }
            },
        });
    }

    handleAddCarrinho(item) {
        this.setState(state => {
            var existe = state.carrinho.find(i => parseInt(i.idProdutoServico) === parseInt(item.idProdutoServico));
            if (!existe) {
                state.carrinho.push(item);
            }
            return state;
        }, () => {
            toast.success("Produto adicionado no carrinho!");
        });
    }

    handleDelCarrinho(index) {
        this.setState(state => {
            state.carrinho.splice(index, 1);
            return state;
        }, () => {
            toast.warning("Produto removido no carrinho!");
        });
    }

    handleOpenModalOrdem() {

        EventBus.dispatch("modal-add", {
            title: 'Nova Ordem',
            sizeModal: 'modal-xl',
            fullScreen : true,
            config: {
                isModal: true,
                idOrdemTipo: this.state.model.idOrdemTipo,
                ordemCarrinho: this.state.carrinho
            },
            form: FormOrdem,
            onClose: () => {
            }
        });
    }

    handleClickEditProduto(item) {
        EventBus.dispatch("modal-add", {
            title: 'Edição do Item',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                id: item.idProdutoServico
            },
            form: FormProdutoServico,
            onSave: (response) => {
                this.getLista();
            },
        });
    }

    handleClickItemProdutoServicoPai(item){
        this.setModel({produtoServicoPai : item});
    }

    render() {
        return (
            <div className="container-fluid p-4 my-2 py-2 w-100 mt-3 mb-3">

                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100 text-center">
                        Loja BDM Performance
                    </h4>
                </div>

                <div className="row">
                    <div className="col-md-3 col-lg-3 col-xl-3 pe-3">
                        {this.state.carrinho.length > 0 ?
                            <div className="card mb-3">
                                <div className="card-header">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart4" viewBox="0 0 16 16">
                                        <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                                    </svg> Carrinho <spam className="fs-8 text-end text-secondary">({this.state.carrinho.length})</spam>
                                </div>
                                <ul className="list-group list-group-flush">
                                    {this.state.carrinho.map((item, indexCarrinho) => (
                                        <li className="list-group-item p-1" key={indexCarrinho}>
                                            <div className="hstack gap-1">
                                                <div>
                                                    <spam className="fs-8 text-end text-secondary align-middle">{indexCarrinho + 1}. </spam>&nbsp;
                                                    <spam className="fs-7">{item.nome}</spam>
                                                </div>
                                                <div className="ms-auto">
                                                    <BtnButton className="btn-link text-danger p-0" onClick={e => { this.handleDelCarrinho(indexCarrinho) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-dash" viewBox="0 0 16 16">
                                                            <path d="M6.5 7a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4z" />
                                                            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                        </svg>
                                                    </BtnButton>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <div className="card-footer bg-transparent text-end">
                                    <BtnButton className="btn-outline-primary btn-sm" onClick={this.handleOpenModalOrdem}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-check" viewBox="0 0 16 16">
                                            <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                            <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                        </svg> Abrir Ordem
                                    </BtnButton>
                                </div>
                            </div>
                            : <></>
                        }

                        <div className="card">
                            <div className="card-header">
                                Busca Avançada
                            </div>
                            <div className="card-body">

                                <div className="row pb-2">
                                    <BtnGroup md={12} sizing="sm" className="w-100" name="flFiltroCdTipo" value={this.state.model.flFiltroCdTipo} options={this.state.filtroTipo} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                                </div>
                                <div className="row px-3 mb-1">
                                    <InputCheckSwitch sizing="sm" label="Produtos novos na loja" name="flNovoLoja" checked={Boolean(this.state.model.flNovoLoja)} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                                    <InputCheckSwitch sizing="sm" label="Produtos em promoção" name="flProdutoEmPromocao" checked={Boolean(this.state.model.flProdutoEmPromocao)} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                                    <InputCheckSwitch sizing="sm" label="Produtos mais vendidos" name="flMaisVendidoCategoriaProdutoServico" checked={Boolean(this.state.model.flMaisVendidoCategoriaProdutoServico)} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                                </div>
                                <div className="row mb-3 gap-2">
                                    <SelectOrdemTipoUsuario sizing="sm" showBtnNewForm={false} md={12} label="Preço para:" required placeholder="Selecione o tipo de Cliente" name="idOrdemTipo" value={this.state.model.idOrdemTipo} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                                    <SelectCategoriaProdutoServicoByUsuario sizing="sm" opcionalLabel={false} showBtnNewForm={false} md={12} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                                    <SelectTipoProdutoServicoByCategoria sizing="sm" opcionalLabel={false} showBtnNewForm={false} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} md={12} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                                </div>
                                <div className="text-end">
                                    <button type="button" className="btn btn-link text-danger fs-7" onClick={this.clear}>
                                        Limpar Busca
                                    </button>
                                </div>
                            </div>
                        </div>

                        {Permissao.getInstance().getPermissao(15) ?
                            <div className="card mt-3">
                                <div className="card-header">
                                    Agrupadores
                                </div>
                                <div className="card-body">
                                    <ListGroupProdutoServicoAgrupador idProdutoServico={this.state.model.produtoServicoPai?.idProdutoServico} onClickItem={this.handleClickItemProdutoServicoPai}/>
                                </div>
                            </div>
                        :  <></>}

                    </div>
                    <div className="col-md-9 col-lg-9 col-xl-9">

                        <div>
                            <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                                <div className="col-md-12 input-group mb-3">
                                    <input type="text" className="form-control form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite a sua busca" value={this.state.model.busca} onChange={this.handleChange} />
                                    <button className="btn btn-outline-primary btn-sm" type="submit"> Buscar </button>
                                </div>
                            </form>
                        </div>

                        <div className="hstack pb-2">
                            <div className="text-secondary">
                                {this.state.data.length} Registro(s)
                            </div>
                            {/*<div className="ms-auto">
                                <BtnGroup md={3} sizing="sm" className="w-100" name="cdFormatView" value={this.state.model.cdFormatView} options={this.state.filtroFormatView} onChange={this.handleChange} opcionalLabel={false} />
                            </div>*/}
                        </div>

                        {this.state.model.cdFormatView === "C" ?

                            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-3">
                                {this.state.data.map((item, indexProduto) => (
                                    <div className="col" key={indexProduto}>
                                        <div className="card shadow-sm h-100">
                                            <div className="card-img-top border-bottom border-secondary">
                                                <div id={`carouselFotosControls${indexProduto}`} className="carousel slide" data-bs-ride="carousel">
                                                    {item.produtoServicoArquivos.length > 0 ?
                                                        <div className="carousel-inner">
                                                            {item.produtoServicoArquivos.map((itemFoto, indexFoto) => (
                                                                <div key={indexFoto} className={`carousel-item ${indexFoto === 0 ? "active" : ""}`}>
                                                                    <img width="100%" height="280" src={Config.getUrlArquivo() + "public/arquivo/inline/" + itemFoto.uid} className="d-block w-100" alt="..." />
                                                                </div>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div className="carousel-inner">
                                                            <div className="carousel-item active">
                                                                <img width="100%" height="280" src="loja-sem-foto.webp" className="d-block w-100" alt="..." />
                                                            </div>
                                                        </div>
                                                    }

                                                    {item.produtoServicoArquivos.length > 1 ?
                                                        <React.Fragment>
                                                            <button className="carousel-control-prev" type="button" data-bs-target={`#carouselFotosControls${indexProduto}`} data-bs-slide="prev">
                                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Previous</span>
                                                            </button>
                                                            <button className="carousel-control-next" type="button" data-bs-target={`#carouselFotosControls${indexProduto}`} data-bs-slide="next">
                                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Next</span>
                                                            </button>
                                                        </React.Fragment>
                                                        : <></>}

                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="card-text">
                                                    {Boolean(item.flNovoLoja) ?
                                                        <div className="badge rounded-pill text-bg-warning fs-7 fw-bold"> NOVO NA LOJA</div>
                                                    :<></>}
                                                    <div>
                                                        {this.getPrecoValor(item)}
                                                    </div>
                                                    <div className="fs-7 fw-bold">{item.nomeTipoProdutoServico}</div>
                                                    <div>{item.nome}</div>
                                                    {item.nrRankingCategoriaProdutoServico > 0 ?
                                                        <div className="pt-1 pb-1 mb-2">
                                                            <div className="badge text-bg-orange fs-8 fw-bold"> MAIS VENDIDO </div>
                                                            <div className="ms-1 fs-8 text-primary">{item.nrRankingCategoriaProdutoServico}º em {item.nomeCategoriaProdutoServico}</div>
                                                        </div>
                                                        : <></>
                                                    }
                                                    <div className="mt-2">
                                                        {item.produtoServicoPromocao.map((promocao, indexPromocao) => (
                                                            <div>
                                                                <span className="badge p-2 pt-1 pb-1 fs-7 fw-bold" style={{ backgroundColor: promocao.cdBgColor, color: promocao.cdTxColor }}> {promocao.nomePromocaoCampanha} </span>
                                                                <div className="text-dark p-1 fs-7" role="alert" key={indexPromocao} style={{ borderTop: `3px solid ${promocao.cdBgColor}` }}>
                                                                    a partir de <strong>{Util.formatEstoque(promocao.nrQuantidadeInicial)} {item.nomeUnidadeMedida.toLowerCase()}</strong>
                                                                    {!promocao.flSemNrQuantidadeFinal ? <> <strong>até {Util.formatEstoque(promocao.nrQuantidadeFinal)} {item.nomeUnidadeMedida.toLowerCase()}</strong></> : <></>}
                                                                    , desconto de <strong>{Util.floatToReais(promocao.vlDesconto)}</strong> a cada {item.nomeUnidadeMedida.toLowerCase()}
                                                                    {!promocao.flSemDataFinal ? <> válido até dia <strong>{Util.date2Br(promocao.dtFinal)}</strong></> : <></>}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    {/*<small className="text-muted"> {this.getValueEstoque(item)} </small>*/}
                                                    {this.getBtnAdicionarItem(item)}
                                                    {Permissao.getInstance().getPermissao(15) ? 
                                                        <>
                                                        {this.state.model.produtoServicoPai?.idProdutoServico && !item.idProdutoServicoPai && !Boolean(item.flAgrupador) && this.podeAgruparItem(item) ? 
                                                            <BtnButton className="btn-outline-primary btn-sm" onClick={e => this.handleClickAgrupar(item)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection" viewBox="0 0 16 16">
                                                                    <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z"/>
                                                                </svg>
                                                            </BtnButton> : <></>}

                                                        <BtnButton className="btn-warning btn-sm" onClick={e => this.handleClickEditProduto(item)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                            </svg>
                                                        </BtnButton>
                                                        </>
                                                    : <></>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            :
                            <div className="table-responsive-sm">
                                <table className="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th className="col-md-4"> Categoria / Tipo </th>
                                            <th className="col-md-8"> Produto </th>
                                            <th className="col text-center"> Foto </th>
                                            <th className="col text-end"> Estoque </th>
                                            <th className="col text-end"> Preço </th>
                                            <th className="col text-end"> # </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.data.map((item, index) => (
                                            <tr key={index}>
                                                <td className="align-middle">
                                                    <strong>{item.nomeCategoriaProdutoServico}</strong>
                                                    <br />
                                                    {item.nomeTipoProdutoServico}
                                                </td>
                                                <td className="align-middle">{item.nome}</td>
                                                <td className="text-center">
                                                    {
                                                        item.produtoServicoArquivos.length > 0 ?
                                                            <button className="btn btn-sm btn-outline-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { this.handleOpenModalFoto(item) }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-fill" viewBox="0 0 16 16">
                                                                    <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                                    <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                                                                </svg>
                                                            </button>
                                                            : <></>
                                                    }
                                                </td>
                                                <td className="text-end text-nowrap"> {this.getValueEstoque(item)}</td>
                                                <td className="text-end fw-bold">{Util.floatToReais(item.vlPreco)}</td>
                                                <td>
                                                    {this.getBtnAdicionarItem(item)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }


                        <div className="text-center pt-5 pb-5">
                            {this.state.data.length > 0 ?
                                <div className="d-grid col-md-6 mx-auto">
                                    <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                        </svg> Carregar Mais Registros
                                    </BtnButton>
                                </div>
                                : <></>
                            }
                        </div>

                    </div>
                </div>
                <ModalInfo id="modal-info-aviso" />
            </div>
        )
    }
}