import Http from "../controllers/Http";

export default class UsuarioService {

    static urlBase = "/usuario/";

    static getForCombo(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forCombo')
            .then(fnSucess)
            .catch(fnError);
    }

    static list(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase)
            .then(fnSucess)
            .catch(fnError);
    }

    static listForList(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'list', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static listForAniversariante(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'aniversariante')
            .then(fnSucess)
            .catch(fnError);
    }

    static listForOnline(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson({ showLoadding: false })
            .get(this.urlBase + 'online')
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByUsuario(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byUsuario')
            .then(fnSucess)
            .catch(fnError);
    }

    static updateByUsuario(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byUsuario', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByUpdate(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byUpdate')
            .then(fnSucess)
            .catch(fnError);
    }

    static getUsuarioExists(usuario, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'getUsuarioExists/' + usuario)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static update(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static setStatus(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'setStatus/' + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static updateSenha(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'updateSenha', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static save(data, fnSucess = () => { }, fnError = () => { }) {
        if (data.idUsuario) {
            this.update(data.idUsuario, data, fnSucess, fnError);
        } else {
            this.create(data, fnSucess, fnError);
        }
    }

    static listForOrdem(idRepresentante, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forOrdem/' + idRepresentante)
            .then(fnSucess)
            .catch(fnError);
    }
}
