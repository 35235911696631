import Http from "../controllers/Http";

export default class CobrancaService {

    static urlBase = "/cobranca/";

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByUid(uid, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get('/public' + this.urlBase + uid)
            .then(fnSucess)
            .catch(fnError);
    }

    static getAllByFatura(idFatura, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byFatura/' + idFatura)
            .then(fnSucess)
            .catch(fnError);
    }

    static listAviso(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + "listAviso")
            .then(fnSucess)
            .catch(fnError);
    }

    static listBloqueio(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + "listBloqueio")
            .then(fnSucess)
            .catch(fnError);
    }

    static createByEstorno(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byEstono/' + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static createAgendamento(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'agendamento/' + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static deleteAgendamento(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'agendamento/cancelar/' + id, {})
            .then(fnSucess)
            .catch(fnError);
    }

    static updateTipoBaixa(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byTipoBaixa/' + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getEncargosByDtPagamento(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'encargosByDtPagamento/' + id, data)
            .then(fnSucess)
            .catch(fnError);
    }
}
