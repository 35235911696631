import React from "react";
import FormSelect from "./FormSelect";
import EventBus from "../../../controllers/EventBus";

export default class FormSelectSearchModal extends FormSelect {

    constructor(props) {
        super(props);

        this.handleSetValue = this.handleSetValue.bind(this);
        this.handleOpenSearch = this.handleOpenSearch.bind(this);
        this.handleOpenModalAddNew = this.handleOpenModalAddNew.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.getRenderInputGroupRigth = this.getRenderInputGroupRigth.bind(this);
        this.getRenderInputGroupLeft = this.getRenderInputGroupLeft.bind(this);
        this.getConfigFormNew = this.getConfigFormNew.bind(this);

        this.state.headers = [
            { key: "value", label: "Valor", className: 'text-center' },
            { key: "label", label: "Descrição" }
        ];
        this.state.keyName = this.props.keyName || "value";
        this.state.keyLabel = this.props.keyLabel || "label";

        this.state.formNewConfig = null;

        this.state.showBtnSearch = true;
        this.state.showBtnNewForm = false;
        this.state.showBtnNewSearch = false;

        this.refBtnSearch = React.createRef();
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);        
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleSetValue(value) {
        var event = {
            target: {
                name: this.props.name,
                value: value,
                type: "select"
            }
        };
        super.handleChange(event);
        //this.props.onChange(event);

        if (this.refBtnSearch?.current) {
            this.refBtnSearch.current.focus();
        }
    }

    handleOpenModalAddNew(event) {
        EventBus.dispatch("modal-add", this.getConfigFormNew());
    }

    handleOpenSearch(event, value = "") {

        this.state.options = this.state.options.filter(f => {
            f.disabled = this.isDisabled ? this.isDisabled(f) : false;
            f.className = this.getClassName ? this.getClassName(f) : "";
            return f;
        });

        if (!value) {
            value = this.state.value ? this.state.value : ""
        }

        EventBus.dispatch("modal-busca-open", {
            value: value,
            config: {
                options: this.state.options,
                headers: this.state.headers,
                keyName: this.state.keyName,
                keyLabel: this.state.keyLabel,
                sizeModal: this.state.sizeModal,
                fullScreen: this.state.fullScreen !== undefined ? this.state.fullScreen : false,
                showBtnNew: this.state.showBtnNewSearch,
                confirm: this.handleSelectItem,
                new: this.handleOpenModalAddNew
            }
        })
    }

    handleSelectItem(value) {
        this.handleSetValue(value);
    }

    getRenderInputGroupRigth() {
        return (<>
            {this.state.showBtnSearch ?
                <button className={`btn btn-outline-secondary ${this.props.sizing ? "btn-" + this.props.sizing : ""}`} type="button" ref={this.refBtnSearch} onClick={this.handleOpenSearch} disabled={this.state.options.length === 0 || this.props.disabled}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                </button>
                : ""
            }
            {
                this.state.showBtnNewForm ?
                    <button disabled={this.props.disabled} className={`btn btn-outline-primary ${this.props.sizing ? "btn-" + this.props.sizing : ""}`} type="button" ref={this.refBtnSearch} onClick={this.handleOpenModalAddNew}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg>
                    </button> : ""
            }
        </>);
    }

    getRenderInputGroupLeft() {
        return (<></>);
    }

    getConfigFormNew() {
        return null;
    }

    render() {
        return super.render();
    }
}