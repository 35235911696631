import FormControl from "../base/FormControl";

export default class InputNumber extends FormControl {

    constructor(props) {
        super(props);
        this.state.type = "number";
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return super.render();
    }
}