import React from "react";
import FornecedorService from "../../services/FornecedorService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaFornecedor extends CrudList {

    constructor(props) {
        super(props);

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];

        this.state.crud = {
            service: FornecedorService,
            labels: {
                title: 'Lista de Fornecedor(es)',
                btnNewRegister: 'Fornecedor',
            },
            keyItem: 'idFornecedor',
            urlForm: '/CadFornecedor',
            itensFilter: ["idFornecedor", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-4"> Nome </th>
                <th className="col-md-2"> Tipo </th>
                <th className="col-md-4"> Cidade </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idFornecedor}</td>
                <td>{item.nome}</td>
                <td>{this.state.tipoPessoa.find(tp => tp.value === item.cdTipoPessoa)?.label}</td>
                <td>{item.nomeCidade} - {item.siglaUf} - {item.nomePais}</td>
            </>);
    }

    render() {
        return super.render();
    }
}