import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import BaixaService from "../../services/BaixaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import CobrancaService from "../../services/CobrancaService";
import InputDate from "../../components/forms/elements/InputDate";
import BtnButton from "../../components/forms/elements/BtnButton";

class FormCobrancaVencimento extends CrudForm {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.handleClickDMais = this.handleClickDMais.bind(this);

        this.getById = this.getById.bind(this);

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtVencimento = new Date();
        dtVencimento.setDate(dtVencimento.getDate() + 1);
        dtVencimento = new Date(dtVencimento - tzoffset).toISOString().slice(0, 10);

        this.state.model = {
            idCobranca: "",
            dtVencimento: dtVencimento,
            vlCobranca: 0
        };

        this.state.cobranca = {
            idCobranca: "",
            vlCobranca: 0,
            vlPago: 0,
            vlAberto: 0,
            vlBaixa: 0,
            vlDesconto: 0,
            idTipoBaixa: "",
            dtVencimento: "",
        }

        this.state.crud = {
            service: BaixaService,
            labels: {
                title: 'Baixa',
            },
            urlList: '/LstBaixa',
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getById();
    }

    getById() {
        if (this.props.idCobranca) {
            CobrancaService.getOneById(this.props.idCobranca, (data) => {
                this.setState({ cobranca: data });
                this.setState(state => {
                    state.model.idCobranca = data.idCobranca;
                    state.model.vlCobranca = data.vlCobranca;
                    state.model.dtVencimento = data.dtVencimento;
                    return state;
                });
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickDMais(dias) {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtVencimento = new Date();
        dtVencimento.setDate(dtVencimento.getDate() + dias);

        this.setState((state) => {
            state.model.dtVencimento = new Date(dtVencimento - tzoffset).toISOString().slice(0, 10);
            return state;
        });
    }

    onSubmit() {
        CobrancaService.createByEstorno(this.props.idCobranca, this.state.model, (response) => {
            this.onSave(response);
        });
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.cobranca.idCobranca} name="idCobranca" disabled required={true} />
                    <InputText className="text-end text-primary fw-bold" label="Valor da Cobrança" md={3} value={Util.floatToReais(this.state.cobranca.vlCobranca)} disabled opcionalLabel={false} />
                    <InputText className="text-end text-success fw-bold" label="Valor Pago" md={3} value={Util.floatToReais(this.state.cobranca.vlPago)} disabled opcionalLabel={false} />
                    <InputText className="text-end text-danger fw-bold" label="Valor à Pagar" md={3} value={Util.floatToReais(this.state.cobranca.vlAberto)} disabled opcionalLabel={false} />

                    <InputText label="Data de Vencimento" md={3} value={Util.date2Br(this.state.cobranca.dtVencimento)} disabled required={true} />

                    <div className="col-md-6 text-center align-middle pt-4">
                        <BtnButton className="btn-success fw-bold btn-sm pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(1) }}> D+1 </BtnButton>
                        <BtnButton className="btn-success fw-bold btn-sm pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(2) }}> D+2 </BtnButton>
                        <BtnButton className="btn-warning fw-bold btn-sm pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(5) }}> D+5 </BtnButton>
                        <BtnButton className="btn-warning fw-bold btn-sm pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(10) }}> D+10 </BtnButton>
                        <BtnButton className="btn-danger fw-bold btn-sm pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(15) }}> D+15 </BtnButton>
                        <BtnButton className="btn-danger fw-bold btn-sm pt-0 pb-0 mx-2" onClick={(e) => { this.handleClickDMais(30) }}> D+30 </BtnButton>
                    </div>
                    <InputDate md={3} required label="Novo Vencimento" value={this.state.model.dtVencimento} name="dtVencimento" onChange={this.handleChange} autoFocus={true} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCobrancaVencimento {...props} navigate={navigate} params={params} />
}

export default With