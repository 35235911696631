import React from "react";
import ContatoMotivoService from "../../services/ContatoMotivoService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaContatoMotivo extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ContatoMotivoService,
            labels: {
                title: 'Lista de Motivo(s)',
                btnNewRegister: 'Motivo',
            },
            keyItem: 'idContatoMotivo',
            urlForm: '/CadContatoMotivo',
            itensFilter: ["idContatoMotivo", "nome", "nomeContatoTipo", "nomeContatoStatus"],
            searchFormSubmit: false
        };

        this.state.container = false;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-4"> Nome do Tipo </th>
                <th className="col-md-4"> Nome do Status </th>
                <th className="col-md-4"> Nome do Motivo</th>
                <th className="col text-center"> Status </th>
                <th className="col text-center"> Inicial? </th>
                <th className="col text-center"> Visualizado? </th>
                <th className="col text-center"> Finalizado? </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idContatoMotivo}</td>
                <td>{item.nomeContatoTipo}</td>
                <td>{item.nomeContatoStatus}</td>
                <td>{item.nome}</td>
                <td className="text-center">
                    {parseInt(item.flAtivo) === 1 ?
                        <span className="badge w-100 text-bg-success">ATIVO</span> :
                        <span className="badge w-100 text-bg-danger">INATIVO</span>
                    }
                </td>
                <td className="text-center">
                    {parseInt(item.flMotivoInicial) === 1 ?
                        <span className="badge w-100 text-bg-success">SIM</span> :
                        <span className="badge w-100 text-bg-danger">NÃO</span>
                    }
                </td>
                <td className="text-center">
                    {parseInt(item.flMotivoVisualizado) === 1 ?
                        <span className="badge w-100 text-bg-success">SIM</span> :
                        <span className="badge w-100 text-bg-danger">NÃO</span>
                    }
                </td>
                <td className="text-center">
                    {parseInt(item.flFinalizado) === 1 ?
                        <span className="badge w-100 text-bg-success">SIM</span> :
                        <span className="badge w-100 text-bg-danger">NÃO</span>
                    }
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}