import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import SelectDDI from "../../components/app/SelectDDI";
import TextArea from "../../components/forms/elements/TextArea";
import Util from "../../utils/Util";
import WhatsappService from "../../services/WhatsappService";

class FormWhatsapp extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            ddi: this.props.ddi !== undefined ? this.props.ddi : "55",
            celular: this.props.celular !== undefined ? this.props.celular : "",
            mensagem: this.props.mensagem !== undefined ? this.props.mensagem : "",
        };

        this.state.crud = {
            service: WhatsappService,
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div>
                <div className="row mb-3 g-2">
                    <SelectDDI sizing="sm" showBtns={false} lg={4} label="DDI" required placeholder="Selecione" name="ddi" value={this.state.model.ddi} onChange={this.handleChange} />
                    {this.state.model.ddi.toString() === "55" ?
                        <InputTelCelular sizing="sm" label="Celular" required lg={8} value={this.state.model.celular} name="celular" onChange={this.handleChange} />
                        :
                        <InputText label="Celular" required lg={8} value={this.state.model.celular} name="celular" onChange={this.handleChange} />
                    }
                    <TextArea className="fs-7 lh-sm " lg={12} label="Mensagem" required={true} value={this.state.model.mensagem} name="mensagem" onChange={this.handleChange} style={{ height: "120px" }} />
                    <div className="col-lg-12">
                        <div className="bg-success bg-opacity-25 fs-7 lh-sm rounded border border-success p-3" dangerouslySetInnerHTML={{ __html: Util.htmlFormatWhatsapp(this.state.model.mensagem) }} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormWhatsapp {...props} navigate={navigate} params={params} />
}

export default With