import ProprietarioService from "../../services/ProprietarioService";
import SelectProprietario from "./SelectProprietario";

export default class SelectProprietarioByUsuario extends SelectProprietario {

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        ProprietarioService.getForComboByUsuario((response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}