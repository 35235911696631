import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import PerfilService from "../../services/PerfilService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import ModuloService from "../../services/ModuloService";
import GrupoModuloService from "../../services/GrupoModuloService";
import OrdemTipoService from "../../services/OrdemTipoService";
import ContatoTipoService from "../../services/ContatoTipoService";
import CategoriaProdutoServicoService from "../../services/CategoriaProdutoServicoService";
import Util from "../../utils/Util";
import AvisoService from "../../services/AvisoService";

class FormPerfil extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeModulos = this.handleChangeModulos.bind(this);
        this.handleChangeModulosAll = this.handleChangeModulosAll.bind(this);

        this.handleChangeCategoriaProdutoServicos = this.handleChangeCategoriaProdutoServicos.bind(this);
        this.handleChangeCategoriaProdutoServicosAll = this.handleChangeCategoriaProdutoServicosAll.bind(this);

        this.handleChangeOrdemTipos = this.handleChangeOrdemTipos.bind(this);
        this.handleChangeOrdemTiposAll = this.handleChangeOrdemTiposAll.bind(this);

        this.handleChangeContatoTipos = this.handleChangeContatoTipos.bind(this);
        this.handleChangeContatoTiposAll = this.handleChangeContatoTiposAll.bind(this);

        this.handleChangeAvisos = this.handleChangeAvisos.bind(this);
        this.handleChangeAvisosAll = this.handleChangeAvisosAll.bind(this);

        this.state.isCard = true;

        this.state.model = {
            idPerfil: "",
            nome: "",
            flBloqueiaDebito: false,
            flRepresentante: false,
            perfilModulo: [],
            perfilCategoriaProdutoServico: [],
            perfilOrdemTipo: [],
            perfilUsuario: [],
            perfilContatoTipo: [],
            perfilAviso : []
        };

        this.state.crud = {
            service: PerfilService,
            labels: {
                title: 'Perfil',
            },
            urlList: '/LstPerfil',
        };

        this.state.grupoModulos = [];
        this.state.modulos = [];
        this.state.categoriaProdutoServicos = [];
        this.state.ordemTipos = [];
        this.state.contatoTipos = [];
        this.state.avisos = [];
    }

    componentDidMount() {
        super.componentDidMount();

        ModuloService.list((response) => {
            this.setState({ modulos: response });
        });

        CategoriaProdutoServicoService.list((response) => {
            this.setState({ categoriaProdutoServicos: response });
        });

        GrupoModuloService.list((response) => {
            this.setState({ grupoModulos: response });
        });

        OrdemTipoService.list((response) => {
            this.setState({ ordemTipos: response });
        });

        ContatoTipoService.list((response) => {
            this.setState({ contatoTipos: response });
        });

        AvisoService.list((response) => {
            this.setState({ avisos: response });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeModulos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilModulo.push(item);
                return state;
            });
        } else {
            var index = this.state.model.perfilModulo.findIndex(i => parseInt(i.idModulo) === parseInt(item.idModulo));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.perfilModulo.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeModulosAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilModulo = [];

                this.state.modulos.forEach(function (item) {
                    state.model.perfilModulo.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.perfilModulo = [];
                return state;
            });
        }
    }

    handleChangeCategoriaProdutoServicos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilCategoriaProdutoServico.push(item);
                return state;
            });
        } else {
            var index = this.state.model.perfilCategoriaProdutoServico.findIndex(i => parseInt(i.idCategoriaProdutoServico) === parseInt(item.idCategoriaProdutoServico));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.perfilCategoriaProdutoServico.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeCategoriaProdutoServicosAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilCategoriaProdutoServico = [];

                this.state.categoriaProdutoServicos.forEach(function (item) {
                    state.model.perfilCategoriaProdutoServico.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.perfilCategoriaProdutoServico = [];
                return state;
            });
        }
    }


    handleChangeOrdemTipos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilOrdemTipo.push(item);
                return state;
            });
        } else {
            var index = this.state.model.perfilOrdemTipo.findIndex(i => parseInt(i.idOrdemTipo) === parseInt(item.idOrdemTipo));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.perfilOrdemTipo.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeOrdemTiposAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilOrdemTipo = [];

                this.state.ordemTipos.forEach(function (item) {
                    state.model.perfilOrdemTipo.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.perfilOrdemTipo = [];
                return state;
            });
        }
    }

    handleChangeContatoTipos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilContatoTipo.push(item);
                return state;
            });
        } else {
            var index = this.state.model.perfilContatoTipo.findIndex(i => parseInt(i.idContatoTipo) === parseInt(item.idContatoTipo));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.perfilContatoTipo.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeContatoTiposAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilContatoTipo = [];

                this.state.contatoTipos.forEach(function (item) {
                    state.model.perfilContatoTipo.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.perfilContatoTipo = [];
                return state;
            });
        }
    }

    handleChangeAvisos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilAviso.push(item);
                return state;
            });
        } else {
            var index = this.state.model.perfilAviso.findIndex(i => parseInt(i.idAviso) === parseInt(item.idAviso));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.perfilAviso.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeAvisosAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.perfilAviso = [];

                this.state.avisos.forEach(function (item) {
                    state.model.perfilAviso.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.perfilAviso = [];
                return state;
            });
        }
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-perfil-tab" data-bs-toggle="tab" data-bs-target="#nav-perfil" type="button" role="tab" aria-controls="nav-perfil" aria-selected="true">
                        Perfil
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-modulos-tab" data-bs-toggle="tab" data-bs-target="#nav-modulos" type="button" role="tab" aria-controls="nav-modulos" aria-selected="false">
                        Módulos
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-categorias-tab" data-bs-toggle="tab" data-bs-target="#nav-categorias" type="button" role="tab" aria-controls="nav-categorias" aria-selected="false">
                        Categorias
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-ordem-tipo-tab" data-bs-toggle="tab" data-bs-target="#nav-ordem-tipo" type="button" role="tab" aria-controls="nav-ordem-tipo" aria-selected="false">
                        Tipo de Ordem
                    </button>
                </li>

                <li className="nav-item">
                    <button className="nav-link" id="nav-contato-tipo-tab" data-bs-toggle="tab" data-bs-target="#nav-contato-tipo" type="button" role="tab" aria-controls="nav-contato-tipo" aria-selected="false">
                        Tipos de Contato
                    </button>
                </li>

                <li className="nav-item">
                    <button className="nav-link" id="nav-avisos-tipo-tab" data-bs-toggle="tab" data-bs-target="#nav-avisos-tipo" type="button" role="tab" aria-controls="nav-avisos-tipo" aria-selected="false">
                        Avisos
                    </button>
                </li>

                <li className="nav-item">
                    <button className={`nav-link ${this.state.model.perfilUsuario.length === 0 ? "disabled" : ""}`} id="nav-usuarios-tab" data-bs-toggle="tab" data-bs-target="#nav-usuarios" type="button" role="tab" aria-controls="nav-usuarios" aria-selected="false">
                        Usuários
                    </button>
                </li>
            </ul>);
    }

    getModuloByIdGrupoModulo(idGrupoModulo) {
        return this.state.modulos.filter(i => parseInt(idGrupoModulo) === parseInt(i.idGrupoModulo));
    }

    getRenderFields() {
        return (
            <>
                <div className="tab-content mx-2 my-3" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-perfil" role="tabpanel" aria-labelledby="nav-perfil-tab">
                        <div className="row mb-3 g-3">
                            <InputText label="Código" md={3} value={this.state.model.idPerfil} name="idPerfil" readOnly opcionalLabel={false} />
                        </div>
                        <div className="row mb-3 g-3">
                            <InputText label="Nome do Perfil" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                        </div>
                        <div className="row mx-1 mb-3 g-1">
                            <InputCheckSwitch label="Bloqueia quando tiver débito?" name="flBloqueiaDebito" checked={Boolean(this.state.model.flBloqueiaDebito)} onChange={this.handleChange} />
                            <InputCheckSwitch label="Perfil de Representante?" name="flRepresentante" checked={Boolean(this.state.model.flRepresentante)} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-modulos" role="tabpanel" aria-labelledby="nav-modulos-tab">

                        <div className="hstack gap-3">
                            <div>
                                <h5>Módulos Liberados</h5>
                            </div>
                            <div className="ms-auto">
                                <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeModulosAll} checked={this.state.model.perfilModulo.length === this.state.modulos.length} />
                            </div>
                        </div>

                        <hr />

                        <div className="mb-3 row">
                            {this.state.grupoModulos.map((grupoModulo, indexGrupoModulo) => (
                                <div className="col-md-6 mt-2 pe-3" key={indexGrupoModulo}>
                                    <div className="card" key={grupoModulo.idGrupoModulo}>
                                        <div className="card-header fw-bold">
                                            {grupoModulo.nome}
                                        </div>
                                        <div className="card-body">
                                            {this.getModuloByIdGrupoModulo(grupoModulo.idGrupoModulo).map((item) => (
                                                <div key={item.idModulo}>
                                                    <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.perfilModulo.findIndex(i => parseInt(i.idModulo) === parseInt(item.idModulo)) !== -1} onChange={(e) => { this.handleChangeModulos(e, item) }} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-categorias" role="tabpanel" aria-labelledby="nav-categorias-tab">
                        <h6> Categorias Liberadas </h6>
                        <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeCategoriaProdutoServicosAll} checked={this.state.model.perfilCategoriaProdutoServico.length === this.state.categoriaProdutoServicos.length} />
                        <div className="row mb-3">
                            {this.state.categoriaProdutoServicos.map((item) => (
                                <div key={item.idCategoriaProdutoServico} className="col-md-6">
                                    <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.perfilCategoriaProdutoServico.findIndex(i => parseInt(i.idCategoriaProdutoServico) === parseInt(item.idCategoriaProdutoServico)) !== -1} onChange={(e) => { this.handleChangeCategoriaProdutoServicos(e, item) }} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="nav-ordem-tipo" role="tabpanel" aria-labelledby="nav-ordem-tipo-tab">
                        <h6> Tipos de Ordens </h6>
                        <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeOrdemTiposAll} checked={this.state.model.perfilOrdemTipo.length === this.state.ordemTipos.length} />
                        <div className="row mb-3">
                            {this.state.ordemTipos.map((item) => (
                                <div key={item.idOrdemTipo} className="col-md-12">
                                    <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.perfilOrdemTipo.findIndex(i => parseInt(i.idOrdemTipo) === parseInt(item.idOrdemTipo)) !== -1} onChange={(e) => { this.handleChangeOrdemTipos(e, item) }} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="nav-contato-tipo" role="tabpanel" aria-labelledby="nav-contato-tipo-tab">
                        <h6> Tipos de Contato </h6>
                        <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeContatoTiposAll} checked={this.state.model.perfilContatoTipo.length === this.state.contatoTipos.length} />
                        <div className="row mb-3">
                            {this.state.contatoTipos.map((item) => (
                                <div key={item.idContatoTipo} className="col-md-12">
                                    <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.perfilContatoTipo.findIndex(i => parseInt(i.idContatoTipo) === parseInt(item.idContatoTipo)) !== -1} onChange={(e) => { this.handleChangeContatoTipos(e, item) }} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="nav-avisos-tipo" role="tabpanel" aria-labelledby="nav-avisos-tipo-tab">
                        <h6> Avisos Permitidos </h6>
                        <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeAvisosAll} checked={this.state.model.perfilAviso.length === this.state.avisos.length} />
                        <div className="row mb-3">
                            {this.state.avisos.map((item) => (
                                <div key={item.idAviso} className="col-md-12">
                                    <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.perfilAviso.findIndex(i => parseInt(i.idAviso) === parseInt(item.idAviso)) !== -1} onChange={(e) => { this.handleChangeAvisos(e, item) }} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="tab-pane fade" id="nav-usuarios" role="tabpanel" aria-labelledby="nav-usuarios-tab">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center d-none d-md-table-cell"> Código </th>
                                    <th className="col-md-6"> Nome </th>
                                    <th className="col-md-3 d-none d-md-table-cell"> Usuário </th>
                                    <th className="col-md-3 text-center d-none d-md-table-cell"> Último Acesso </th>
                                    <th className="text-center d-none d-md-table-cell"> Online? </th>
                                    <th className="text-center d-none d-md-table-cell"> Ativo? </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.model.perfilUsuario.map((item, index) => (
                                    <tr key={index}>
                                        <td className="text-center d-none d-md-table-cell">{item.idUsuario}</td>
                                        <td>{item.nome}</td>
                                        <td className="d-none d-md-table-cell">{item.usuario}</td>
                                        <td className="text-center d-none d-md-table-cell">
                                            <small>{(item.dataAcesso ? Util.date2Br(item.dataAcesso) + " " + item.horaAcesso : "")}</small>
                                        </td>
                                        <td className="text-center d-none d-md-table-cell">
                                            {item.isOnline ?
                                                <span className="text-success">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                                                        <circle cx="8" cy="8" r="8" />
                                                    </svg>
                                                </span>
                                                :
                                                <span className="text-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                                                        <circle cx="8" cy="8" r="8" />
                                                    </svg>
                                                </span>
                                            }
                                        </td>
                                        <td className="text-center d-none d-md-table-cell">
                                            {item.ativo ? "SIM" : "NÃO"}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormPerfil {...props} navigate={navigate} params={params} />
}

export default With