import Http from "../controllers/Http";

export default class MonitorService {

    static urlBase = "/monitor/";

    static list(flLoading, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson({ showLoadding: flLoading })
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static listMerge(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'merge', data)
            .then(fnSucess)
            .catch(fnError);
    }
}
