import { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Auth from "../../controllers/Auth";
import EventBus from "../../controllers/EventBus";
import eventBus from "../../controllers/EventBus";
import ModalInfo from "./ModalInfo";

class ModalSessaoExpirada extends Component {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);

        this.state = {
            element: {},
            show: false,
            eventBusOpen: "response-error-401"
        };
    }

    componentDidMount() {
        eventBus.on("response-error-401", (element) => {
            var usuario = Auth.getUsuarioDataToken();

            if (Auth.isAuthenticated() && !usuario) {
                this.setState({ show: true, element: element });
                Auth.logout();
            }
        });

        eventBus.on("response-error-426", (element) => {
            this.setState({ show: false });
            this.props.navigate('/login');

            EventBus.dispatch("modal-info-sessao-open", {
                config: {
                    title: "Nova Versão Disponível!",
                    message: "Existe uma nova versão do sistema disponível!",
                    description: "Se o erro persistir, feche o navegador e tente novamente",
                    style: "warning",
                    confirm: () => {
                        this.handleClose();
                    }
                },
            });
        });
    }

    componentWillUnmount() {
        this.removeAllEventBus();
    }

    removeAllEventBus() {
        eventBus.remove(this.state.eventBusOpen);
    }

    handleClose() {
        this.setState({ show: false });
        this.props.navigate('/login');
    }

    render() {

        return (
            <>
                <div className={`fade modal-backdrop show ${this.state.show ? "" : "d-none"}`}> </div>
                <div className={`modal ${this.state.show ? "d-block" : "d-none"}`} id="exampleModal" tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="false" aria-modal="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="fw-bold mb-0 text-center">Sessão expirada!</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleClose}></button>
                            </div>
                            <div className="modal-body text-center m-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-person-x" viewBox="0 0 16 16">
                                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                    <path fillRule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                            <div className="modal-footer">
                                <button className="w-100 mb-2 btn btn-lg rounded-4 btn-secondary" type="button" onClick={this.handleClose}>Realizar Novo Login</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalInfo id="modal-info-sessao" />
            </>
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <ModalSessaoExpirada {...props} navigate={navigate} params={params} />
}

export default With
