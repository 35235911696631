import FormControl from "../base/FormControl";

export default class InputUrl extends FormControl {

    constructor(props) {
        super(props);
        this.state.type = "url";
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return super.render();
    }
}