import React from "react";
import FormField from "./FormField";

export default class FormSwitch extends FormField {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(event) {
        super.handleChange(event);
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    handleBlur(event) {
        super.handleBlur(event);
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    render() {

        var className = `form-check-input ${this.props.className ? this.props.className : ""}`;

        var render =
            <div className={`form-check form-switch ${this.props.show === false ? "d-none" : ""}`}>
                <input type="checkbox"
                    {...this.getProps(this.props)}
                    ref={this.state.ref}
                    className={className}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange} />
                {this.props.label ? <label className={`form-check-label ${this.props.sizing ? "col-form-label-" + this.props.sizing : ""}`} htmlFor={this.props.id}>{this.props.label}</label> : ""}
            </div>;

        return (render);
    }
}