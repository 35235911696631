import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import PlanoPagamentoService from "../../services/PlanoPagamentoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectPlanoRecorrencia from "../../components/app/SelectPlanoRecorrencia";
import InputNumber from "../../components/forms/elements/InputNumber";
import TipoBaixaService from "../../services/TipoBaixaService";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputValorReais from "../../components/forms/custom/InputValorReais";

class FormPlanoPagamento extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeTipoBaixa = this.handleChangeTipoBaixa.bind(this);

        this.state.isCard = true;

        this.state.model = {
            idPlanoPagamento: "",
            nome: "",
            idPlanoRecorrencia: "",
            nrParcelas: 1,
            nrDiasIniciar: 0,
            nrDiasIntervalo: 0,
            flProprietarioSeleciona : false,
            planoPagamentoTipoBaixa: []
        };

        this.state.tipoBaixas = [];

        this.state.crud = {
            service: PlanoPagamentoService,
            labels: {
                title: 'Plano de Pagamento',
            },
            urlList: '/LstPlanoPagamento',
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getListaTipoBaixa();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getListaTipoBaixa() {
        TipoBaixaService.getForCombo((response) => {
            this.setState({ tipoBaixas: response });
        });
    }

    handleChangeTipoBaixa(e, tipoBaixa) {
        this.setState(state => {
            var indexOf = state.model.planoPagamentoTipoBaixa.findIndex(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa));

            if (e.target.checked) {
                if (indexOf !== -1) {
                    state.model.planoPagamentoTipoBaixa.splice(indexOf, 1);
                }
                state.model.planoPagamentoTipoBaixa.push({ idTipoBaixa: tipoBaixa.value, vlMinimoCobranca: 0, vlMinimoFatura: 0 });
            } else {
                if (indexOf !== -1) {
                    state.model.planoPagamentoTipoBaixa.splice(indexOf, 1);
                }
            }
            return state;
        });
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-dados-tab" data-bs-toggle="tab" data-bs-target="#nav-dados" type="button" role="tab" aria-controls="nav-dados" aria-selected="true">
                        Plano de Pagamento
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-meios-tab" data-bs-toggle="tab" data-bs-target="#nav-meios" type="button" role="tab" aria-controls="nav-meios" aria-selected="false">
                        Meios de Pagamento
                    </button>
                </li>
            </ul>
        );
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">
                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={3} value={this.state.model.idPlanoPagamento} name="idPlanoPagamento" readOnly opcionalLabel={false} />
                    </div>
                    <div className="row mb-3 g-3">
                        <InputText label="Nome do Plano de Pagamento" autoFocus={true} required md={6} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                        <SelectPlanoRecorrencia label="Plano de Recorrência" md={6} placeholder="Selecione o Plano de Recorrência" name="idPlanoRecorrencia" required={true} value={this.state.model.idPlanoRecorrencia} onChange={this.handleChange} />
                        <InputNumber className="text-center" min={1} label="Quantidade de parcelas" required md={4} value={this.state.model.nrParcelas} name="nrParcelas" onChange={this.handleChange} />
                        <InputNumber className="text-center" label="Dias para iniciar o vencimento" required md={4} value={this.state.model.nrDiasIniciar} name="nrDiasIniciar" onChange={this.handleChange} />
                        <InputNumber className="text-center" label="Dias Intervalo entre os vencimentos" required md={4} value={this.state.model.nrDiasIntervalo} name="nrDiasIntervalo" onChange={this.handleChange} />
                    </div>
                    <div className={`row mx-1 mb-3 g-1`}>
                        <InputCheckSwitch label="Proprietário pode Selecionar?" name="flProprietarioSeleciona" checked={Boolean(this.state.model.flProprietarioSeleciona)} onChange={this.handleChange} />
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-meios" role="tabpanel" aria-labelledby="nav-meios-tab">
                    <div>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th className="col-6"> Meio de Pagamento </th>
                                    <th className="col-3">Valor Mínimo Cobrança</th>
                                    <th className="col-3">Valor Mínimo Fatura</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.tipoBaixas.filter(t => parseInt(t.flFaturamento) === 1).map((tipoBaixa, indexTipoBaixa) => (
                                    <tr>
                                        <td>
                                            <InputCheckSwitch key={indexTipoBaixa} label={tipoBaixa.label} name="flTipoBaixa" checked={this.state.model.planoPagamentoTipoBaixa.find(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa)) !== undefined} onChange={e => { this.handleChangeTipoBaixa(e, tipoBaixa) }} />
                                        </td>
                                        <td>
                                            {this.state.model.planoPagamentoTipoBaixa.find(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa)) !== undefined ?
                                                <InputValorReais sizing="md" className="text-end" required={true} value={this.state.model.planoPagamentoTipoBaixa.find(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa))?.vlMinimoCobranca} name="vlMinimoCobranca" onChange={(e) => { this.handleChange(e, false, this.state.model.planoPagamentoTipoBaixa.find(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa))) }} />
                                                : <></>}
                                        </td>
                                        <td>
                                            {this.state.model.planoPagamentoTipoBaixa.find(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa)) !== undefined ?
                                                <InputValorReais sizing="md" className="text-end" required={true} value={this.state.model.planoPagamentoTipoBaixa.find(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa))?.vlMinimoFatura} name="vlMinimoFatura" onChange={(e) => { this.handleChange(e, false, this.state.model.planoPagamentoTipoBaixa.find(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa))) }} />
                                                : <></>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormPlanoPagamento {...props} navigate={navigate} params={params} />
}

export default With