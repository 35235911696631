import EventBus from "./EventBus";

export default class Storage {

    static getItem(item, defaultValue = false) {
        var value = localStorage.getItem(item);
        if (value !== null) {
            return value;
        } else {
            this.setItem(item, defaultValue);
            return defaultValue;
        }
    }

    static setItem(item, value) {
        localStorage.setItem(item, value);        
    }

    static clearAll() {
        localStorage.clear();
    }

    static getVisualizacaoValor() {
        return parseInt(this.getItem("visualizacao-valor", 1)) === 1;
    }

    static uptVisualizacaoValor() {
        var flVisualizaoValor = (this.getVisualizacaoValor() ? 0 : 1);
        EventBus.dispatch("atualizar-visualizacao-valor", flVisualizaoValor);
        this.setItem("visualizacao-valor", flVisualizaoValor);
        return parseInt(flVisualizaoValor) === 1;
    }

    //Verifica se o item está expirado, baseado no timestamp, será armazendado o timestamp e comparado em segundos, caso expirou, retorna true
    static hasItemTimeStampExpirado(item, timeSecondExpire) {
        var timeStampAtual = Date.now();
        var timeStampAviso = Storage.getItem(item, (timeStampAtual - ((timeSecondExpire + 1) * 1000)));
        var timeStampDiferenca = Math.floor((timeStampAtual - timeStampAviso) / 1000);

        if (parseInt(timeStampDiferenca) >= timeSecondExpire) {
            Storage.setItem(item, timeStampAtual);
            return true;
        }

        return false;
    }
}