import Config from "../Config";
import Http from "../controllers/Http";

export default class ArquivoService {

    static urlBase = "/arquivo/";

    static upload(file, fnSucess = () => { }, fnError = () => { }) {

        const formData = new FormData();
        formData.append("arquivo", file, file.name);

        Http.getInstanceAppJson({ timeout: 300, apiUrlBase : Config.getUrlArquivo() })
            .post(this.urlBase, formData)
            .then(fnSucess)
            .catch(fnError);
    }
}
