import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ContatoService from "../../services/ContatoService";
import Util from "../../utils/Util";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import SelectPais from "../../components/app/SelectPais";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import SelectServicoContato from "../../components/app/SelectServicoContato";
import RepresentanteService from "../../services/RepresentanteService";
import CidadeService from "../../services/CidadeService";
import InputValorReais from "../../components/forms/custom/InputValorReais";
import InputDate from "../../components/forms/elements/InputDate";
import InputEmail from "../../components/forms/elements/InputEmail"
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import SelectDDI from "../../components/app/SelectDDI";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import FormContatoAtendimento from "../ContatoAtendimento/FormContatoAtendimento";
import EventBus from "../../controllers/EventBus";
import Permissao from "../../controllers/Permissao";
import SelectContatoTipoByUsuario from "../../components/app/SelectContatoTipoByUsuario";
class FormContato extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeCidade = this.handleChangeCidade.bind(this);
        this.handleClickAtendimento = this.handleClickAtendimento.bind(this);

        this.state.isCard = true;

        this.state.model = {
            idContato: "",
            idContatoTipo: "1",
            dataInsert: "",
            horaInsert: "",
            nome: "",
            ddi: "55",
            telefone: "",
            email: "",
            aceitaContato: true,
            idContatoOrigem: 2,
            idRepresentante: "",
            idPais: "",
            idEstado: "",
            idCidade: "",
            idServicoContato: "",
            dsServicoContatoOutro: "",
            valor: "",
            flFechouVenda: false,
            flInvalido: false,
            dtVenda: "",
            contatoAtendimento: []
        };

        this.state.redirectBusca = false;

        this.state.container = false;

        this.state.crud = {
            service: ContatoService,
            labels: {
                title: 'Contato',
            },
            urlList: '/LstContato',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    getById() {
        if (this.props.params?.id) {
            ContatoService.getOneById(this.props.params.id, (response) => {
                response.idServicoContato = response.idServicoContato ? response.idServicoContato : "";
                this.setModel(response);
                ContatoService.updateByVisualizacao(this.props.params.id);
            }, (response) => {
                this.props.navigate(this.state.crud.urlList);
            })
        } else {
            this.setModel({ idPais: 1, idServicoContato: 1 });
        }

        var idCidade = this.props.params?.idCidade || this.props.idCidade;
        var idRepresentante = this.props.params?.idRepresentante || this.props.idRepresentante;

        if (idCidade) {
            CidadeService.getOneById(idCidade, (response) => {
                this.setModel({ idCidade: response.idCidade });
                this.setModel({ idEstado: response.idEstado });
                this.setModel({ idPais: response.idPais });
            });

            var crud = this.state.crud;
            crud.urlList = '/BscRepresentante';
            this.setState({ redirectBusca: true, crud: crud });
        }

        if (idRepresentante) {
            this.setModel({ idRepresentante: idRepresentante });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeCidade(event) {
        if (parseInt(this.state.model.idContatoTipo) === 1) {
            RepresentanteService.getOneByLocalizacaoCidade(this.state.model.idCidade, (response) => {
                this.setModel({ idRepresentante: response.idRepresentante })
            })
        } else {
            this.setModel({ idRepresentante: "" })
        }
    }

    handleClickAtendimento() {
        EventBus.dispatch("modal-add", {
            title: 'Registro de Atendimento',
            sizeModal: 'modal-lg',
            config: {
                isModal: true,
                idContato: this.state.model.idContato,
                idContatoTipo: this.state.model.idContatoTipo,
                idContatoStatus: this.state.model.idContatoStatus,
                idContatoMotivo: this.state.model.idContatoMotivo,
            },
            form: FormContatoAtendimento,
            onClose: (response) => {
            },
            onSave: (response) => {
                this.getById();
            }
        });
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-dados-tab" data-bs-toggle="tab" data-bs-target="#nav-dados" type="button" role="tab" aria-controls="nav-dados" aria-selected="true">
                        Dados do Contato
                    </button>
                </li>
                {this.state.model.idContato ?
                    <li className="nav-item">
                        <button className="nav-link" id="nav-atendimento-tab" data-bs-toggle="tab" data-bs-target="#nav-atendimento" type="button" role="tab" aria-controls="nav-atendimento" aria-selected="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                                <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                            </svg> Atendimento <span className="badge text-bg-primary">{this.state.model.contatoAtendimento.length}</span>
                        </button>
                    </li>
                    : <></>}
            </ul>);
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                {this.state.showBtnSalvar ? <BtnSubmit className="btn-primary"> Salvar </BtnSubmit> : <></>}
                {this.state.showBtnCancelar ? <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton> : <></>}
                {Permissao.getInstance().getPermissao(75) && this.state.model.idContato ?
                    <BtnButton className="btn-outline-success ms-auto" onClick={this.handleClickAtendimento}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                            <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                        </svg> Registrar Atendimento </BtnButton>
                    : <></>}
            </div>);
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">

                    <div className={`pb-3 ${this.state.model.idContato ? '' : 'd-none'}`}>
                        <span className="badge bg-success">{Util.date2Br(this.state.model.dataInsert) + " " + this.state.model.horaInsert}</span>
                        <span className="badge bg-success mx-2">{this.state.model.nomeContatoOrigem}</span>
                        <span className="badge bg-primary mx-2">{this.state.model.idContato}</span>
                    </div>

                    <div className="row mb-3 g-3">
                        <SelectContatoTipoByUsuario showBtns={false} disabled={parseInt(this.state.model.idContato) > 0} sizing="md" label="Tipo do Contato" md={4} required={true} placeholder="Selecione o Tipo" name="idContatoTipo" value={this.state.model.idContatoTipo} onChange={this.handleChange} />
                        <InputText sizing="md" label="Nome do Contato" md={4} name="nome" value={this.state.model.nome} onChange={this.handleChange} autoFocus={true} required />

                        <SelectDDI showBtns={false} md={1} label="DDI" required placeholder="Selecione" name="ddi" value={this.state.model.ddi} onChange={this.handleChange} sizing="md" />

                        {parseInt(this.state.model.ddi) === 55 ?
                            <InputTelCelular label="Celular" required md={3} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} sizing="md" />
                            :
                            <InputText label="Celular" required md={3} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} sizing="md" />
                        }

                        <InputEmail sizing="md" label="E-mail" md={3} name="email" value={this.state.model.email} onChange={this.handleChange} />
                        
                        <SelectPais showBtns={false} sizing="md" label="Nome do País" required placeholder="Selecione o País" name="idPais" md={2} value={this.state.model.idPais} onChange={this.handleChange} />
                        <SelectEstadoByPais showBtnNewForm={false} disabled={!this.state.model.idPais} sizing="md" md={3} required idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" onChange={this.handleChange} />
                        <SelectCidadeByEstado showBtnNewForm={false} disabled={!this.state.model.idEstado} sizing="md" md={4} required idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" onChange={(event) => { this.handleChange(event, this.handleChangeCidade) }} />

                        {parseInt(this.state.model.idContatoTipo) === 1 ?
                            <>
                                <SelectServicoContato showBtns={false} sizing="md" label="Interessado em:" md={3} required={!(parseInt(this.state.model.idContato) > 0)} placeholder="Selecione o Serviço" name="idServicoContato" value={this.state.model.idServicoContato} onChange={this.handleChange} />
                                <InputText sizing="md" label="Qual Serviço?" md={3} name="dsServicoContatoOutro" value={this.state.model.dsServicoContatoOutro} onChange={this.handleChange} disabled={parseInt(this.state.model.idServicoContato) !== parseInt(7)} required={parseInt(this.state.model.idServicoContato) === parseInt(7)} />
                                <SelectRepresentanteByUsuario showBtnNewForm={false} ativo={1} sizing="md" md={6} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={this.handleChange} />
                            </>
                            : <></>}

                        <div className="ps-2">
                            <InputCheckSwitch sizing="md" label="Aceita receber contato por e-mail e telefone?" name="aceitaContato" checked={Boolean(this.state.model.aceitaContato)} onChange={this.handleChange} />
                        </div>

                        {Boolean(this.state.model.flFechouVenda) ?
                            <>
                                <InputDate md={3} sizing="md" disabled label="Data da Venda" value={this.state.model.dtVenda} name="dtVenda" onChange={this.handleChange} opcionalLabel={false} />
                                <InputValorReais md={3} sizing="md" disabled label="Valor" value={this.state.model.valor} name="valor" onChange={this.handleChange} opcionalLabel={false} />
                            </>
                            : <></>
                        }
                    </div>
                </div>
                <div className="tab-pane fade show" id="nav-atendimento" role="tabpanel" aria-labelledby="nav-atendimento-tab">

                    {this.state.model.contatoAtendimento.map((item, index) => (

                        <div key={index} className="card w-100 float-start mb-3">
                            <div className="card-body p-2 pt-1 bg-secondary bg-opacity-25">
                                <span className="card-title">
                                    <div className="hstack gap-3">
                                        <span className="badge fs-7 w-50" style={{ backgroundColor: item.cdBgColor, color: item.cdTxColor }}>
                                            {item.nomeContatoStatus}
                                        </span>
                                        <span className="badge text-secondary border border-secondary fs-7 w-50">
                                            {item.nomeContatoMotivo}
                                        </span>
                                    </div>
                                </span>

                                <small className="text-muted">
                                    <span className="badge bg-dark">
                                        {Util.date2Br(item.dataAtendimento)} - {item.horaAtendimento}
                                    </span>
                                </small>

                                <div className="fw-bold fs-7">
                                    {item.nomeUsuario}
                                </div>

                                <p className="card-text lh-sm" dangerouslySetInnerHTML={{ __html: Util.nl2br(item.dsAtendimento) }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormContato {...props} navigate={navigate} params={params} />
}

export default With