import React from "react";
import ModalidadeFreteService from "../../services/ModalidadeFreteService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaModalidadeFrete extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ModalidadeFreteService,
            labels: {
                title: 'Lista de Modalidade de Frete(s)',
                btnNewRegister: 'Modalidade Frete',
            },
            keyItem: 'idModalidadeFrete',
            urlForm: '/CadModalidadeFrete',
            itensFilter: ["idModalidadeFrete", "nome"],
            searchFormSubmit: false,
            idModuloParametroValor: 66
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-10"> Nome </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idModalidadeFrete}</td>
                <td>{item.nome}</td>
            </>);
    }

    render() {
        return super.render();
    }
}