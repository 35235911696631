export default class EventBus {
    static list = [];
    static waiting = [];

    static on(event, callback) {
        var fn = function (e) { callback(e.detail) };
        this.list[event] = fn;
        document.addEventListener(event, fn);
        this.dispachWaiting(event);
        //console.log(this.waiting);
    }

    static dispatch(event, data = {}, waiting = false) {

        if (waiting) {
            this.addWaiting(event, data);
        }

        document.dispatchEvent(new CustomEvent(event, { detail: data }));
    }

    static remove(event) {
        document.removeEventListener(event, this.list[event]);
    }

    static dispachWaiting(event) {
        if (this.waiting[event]) {
            for (var i = 0; i < this.waiting[event].length; i++) {
                this.dispatch(event, this.waiting[event][i]);
            }
            delete this.waiting[event];
        }
    }

    static addWaiting(event, data) {
        if (!this.list[event] && event) {
            if (!this.waiting[event]) {
                this.waiting[event] = [];
            }
            this.waiting[event].push(data);
        }
    }

    static cleanWaiting(event, data) {
        this.waiting = [];
    }

}