import FormSelect from "../base/FormSelect";

export default class Select extends FormSelect {

    /*constructor(props) {
        super(props);
    }*/

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return super.render();
    }
}