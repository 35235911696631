import React from "react";
import TipoProdutoServicoService from "../../services/TipoProdutoServicoService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaTipoProdutoServico extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: TipoProdutoServicoService,
            labels: {
                title: 'Lista de Tipo de Produto(s) / Serviço(s)',
                btnNewRegister: 'Tipo de Produto Serviço',
            },
            keyItem: 'idTipoProdutoServico',
            urlForm: '/CadTipoProdutoServico',
            itensFilter: ["idTipoProdutoServico", "nome", "nomeCategoriaProdutoServico"],
            searchFormSubmit: false
        };

        this.state.tipos = [
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ];
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-1 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-6"> Nome </th>
                <th className="col-md-4"> Categoria </th>
                <th className="col-md-2 text-center"> Tipo </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idTipoProdutoServico}</td>
                <td>{item.nome}</td>
                <td>{item.nomeCategoriaProdutoServico}</td>
                <td className="text-center">{this.state.tipos.find(tipo => item.cdTipo === tipo.value)?.label}</td>
            </>);
    }

    render() {
        return super.render();
    }
}