import React, { Component } from "react";

export default class Button extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: "button"
        };
    }

    getProps(props, delProps = []) {
        const button = Object.assign({}, props);
        delete button.className;
        delete button.type;

        for (var i = 0; i < delProps.length; i++) {
            delete button[delProps[i]];
        }

        return button;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        var className = `btn ${this.props.className ? this.props.className : ""} ${this.props.sizing ? "btn-" + this.props.sizing : ""}`;

        return (
            <button type={this.state.type} className={className} {...this.getProps(this.props)}>
                {this.props.children}
            </button>
        );

    }
}