import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ContaService from "../../services/ContaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectBanco from "../../components/app/SelectBanco";
import SelectTipoChavePix from "../../components/app/SelectTipoChavePix";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import InputEmail from "../../components/forms/elements/InputEmail";
import InputCpf from "../../components/forms/custom/InputCpf";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import SelectPais from "../../components/app/SelectPais";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputCep from "../../components/forms/custom/InputCep";
import SelectDDI from "../../components/app/SelectDDI";
import Util from "../../utils/Util";
import EnderecoService from "../../services/EnderecoService";

class FormConta extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeCep = this.handleChangeCep.bind(this);

        this.state.model = {
            idConta: "",
            idPais: 1,
            idEstado: "",
            idCidade: "",
            nome: "",
            nomeTitular: "",
            nrConta: "",
            idBanco: "",
            nrAgencia: "",
            idTipoChavePix: "",
            cdChavePix: "",
            flTemBoleto: false,
            flTemPix: false,

            cdTipoPessoa: "F",
            cpf: "",
            cnpj: "",            
            cep: "",
            bairro: "",
            endereco: "",
            numero: "",
            complemento: "",
            ddi: "55",
            telefone: "",            
            email: ""
        };

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
        ];

        this.state.crud = {
            service: ContaService,
            labels: {
                title: 'Conta',
            },
            urlList: '/LstConta',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeCep() {
        if (Util.stringToNumber(this.state.model.cep).length === 8) {
            EnderecoService.getOneByCep(this.state.model.cep, (response) => {
                this.setState((state) => {
                    state.model.endereco = response?.logradouro ? response.logradouro : "";
                    state.model.bairro = response?.bairro ? response.bairro : "";
                    state.model.complemento = response?.complemento ? response.complemento : "";
                    state.model.numero = "";

                    if (response?.idEstado) {
                        state.model.idEstado = response.idEstado;
                    }

                    if (response?.idCidade) {
                        state.model.idCidade = response.idCidade;
                    }

                    return state;
                });
            });

        }
    }

    getFieldChavePix() {
        switch (parseInt(this.state.model.idTipoChavePix)) {
            case 1: //Celular
                return (<InputTelCelular label="Celular" required={true} md={6} value={this.state.model.cdChavePix} name="cdChavePix" onChange={this.handleChange} maxLength={100} />);

            case 2: //E-mail
                return (<InputEmail label="E-mail" required={true} md={6} value={this.state.model.cdChavePix} name="cdChavePix" onChange={this.handleChange} maxLength={100} />);

            case 3: //CPF
                return (<InputCpf label="CPF" required={true} md={6} value={this.state.model.cdChavePix} name="cdChavePix" onChange={this.handleChange} maxLength={100} />);

            case 4: //CPF
                return (<InputCnpj label="CPF" required={true} md={6} value={this.state.model.cdChavePix} name="cdChavePix" onChange={this.handleChange} maxLength={100} />);

            default:
                return (<InputText label="Chave PIX" required={true} md={6} value={this.state.model.cdChavePix} name="cdChavePix" onChange={this.handleChange} maxLength={100} />);
        }
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={2} value={this.state.model.idConta} name="idConta" readOnly opcionalLabel={false} />
                    <InputText label="Nome da Conta" autoFocus={true} required md={5} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <InputText label="Nome do Titular" required md={5} value={this.state.model.nomeTitular} name="nomeTitular" onChange={this.handleChange} maxLength={100} />

                    <SelectPais sizing="md" label="Nome do País" required placeholder="Selecione o País" name="idPais" md={4} value={this.state.model.idPais} onChange={this.handleChange} />
                    <SelectEstadoByPais sizing="md" md={4} required idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" onChange={this.handleChange} />
                    <SelectCidadeByEstado sizing="md" md={4} required idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" onChange={this.handleChange} />

                    <InputCep label="CEP" lg={3} value={this.state.model.cep} name="cep" onChange={e => this.handleChange(e, this.handleChangeCep)} required={true} />
                    <InputText label="Logradouro" lg={6} value={this.state.model.endereco} name="endereco" onChange={this.handleChange} maxLength={100} required={true} />
                    <InputText label="Número" lg={3} value={this.state.model.numero} name="numero" onChange={this.handleChange} maxLength={100} required={true} />

                    <InputText label="Complemento" lg={6} value={this.state.model.complemento} name="complemento" onChange={this.handleChange} maxLength={100} />
                    <InputText label="Bairro" lg={6} value={this.state.model.bairro} name="bairro" onChange={this.handleChange} maxLength={150} required={true} />

                    <SelectBanco label="Nome do Banco" required placeholder="Selecione o Banco" name="idBanco" md={4} value={this.state.model.idBanco} onChange={this.handleChange} />
                    <InputText label="Agência" required md={4} value={this.state.model.nrAgencia} name="nrAgencia" onChange={this.handleChange} maxLength={100} />
                    <InputText label="Conta" required md={4} value={this.state.model.nrConta} name="nrConta" onChange={this.handleChange} maxLength={100} />

                    <BtnGroup required={true} md={3} sizing="sm" className="w-100" name="cdTipoPessoa" value={this.state.model.cdTipoPessoa} label="Tipo de Pessoa" options={this.state.tipoPessoa} onChange={this.handleChange} />

                    {this.state.model.cdTipoPessoa === "F" ?
                        <InputCpf sizing="md" label="CPF" md={3} name="cpf" value={this.state.model.cpf} onChange={this.handleChange} required={true} />
                        : <></>}

                    {this.state.model.cdTipoPessoa === "J" ?
                        <InputCnpj sizing="md" label="CNPJ" md={3} name="cnpj" value={this.state.model.cnpj} onChange={this.handleChange} required={true} />
                        : <></>}

                    <SelectDDI showBtns={false} lg={2} label="DDI" required placeholder="Selecione" name="ddi" value={this.state.model.ddi} onChange={this.handleChange} />
                    {this.state.model.ddi.toString() === "55" ?
                        <InputTelCelular label="Telefone" required lg={3} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} />
                        :
                        <InputText label="Telefone" required lg={3} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} />
                    }

                    <InputEmail label="E-mail" lg={4} name="email" value={this.state.model.email} onChange={this.handleChange} required={true} />
                </div>
                <div className="row px-3 mb-3">
                    <InputCheckSwitch label="Tem Boleto?" name="flTemBoleto" checked={Boolean(this.state.model.flTemBoleto)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Tem Pix?" name="flTemPix" checked={Boolean(this.state.model.flTemPix)} onChange={this.handleChange} />
                </div>
                {Boolean(this.state.model.flTemPix) ?
                    <div className="row mb-3 g-3">
                        <SelectTipoChavePix required={true} label="Tipo Chave Pix" placeholder="Selecione o Tipo da Chave Pix" name="idTipoChavePix" md={6} value={this.state.model.idTipoChavePix} onChange={this.handleChange} />
                        {this.getFieldChavePix()}
                    </div>
                    : <></>}
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormConta {...props} navigate={navigate} params={params} />
}

export default With