import Http from "../controllers/Http";

export default class CronExecucaoService {

    static urlBase = "/crnExecucao/";

    static listForList(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'list', data)
            .then(fnSucess)
            .catch(fnError);
    }
}
