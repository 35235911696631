import Http from "../controllers/Http";

export default class BlocoService {

    static urlBase = "/bloco/";

    static getDados(idBloco, filter = {}, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson({ showLoadding: false })
            .post(this.urlBase + 'dados/' + idBloco, filter)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson({})
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByUserId(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson({ showLoadding: false })
            .get(this.urlBase + 'byUser/' + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static update(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static save(data, fnSucess = () => { }, fnError = () => { }) {
        if (data.idBloco) {
            this.update(data.idBloco, data, fnSucess, fnError);
        } else {
            this.create(data, fnSucess, fnError);
        }
    }

    static getTipos() {
        return [
            { value: "number", label: "Número", flComando: true },
            { value: "table", label: "Tabela", flComando: true },
            {
                value: "grafico", label: "Gráfico", flComando: true, modelos:
                    [
                        { value: "AnnotationChart", label: "AnnotationChart" },
                        { value: "AreaChart", label: "AreaChart" },
                        { value: "BarChart", label: "BarChart" },
                        { value: "BubbleChart", label: "BubbleChart" },
                        { value: "Calendar", label: "Calendar" },
                        { value: "CandlestickChart", label: "CandlestickChart" },
                        { value: "ColumnChart", label: "ColumnChart" },
                        { value: "ComboChart", label: "ComboChart" },
                        { value: "DiffChart", label: "DiffChart" },
                        { value: "DonutChart", label: "DonutChart" },
                        { value: "Gantt", label: "Gantt" },
                        { value: "Gauge", label: "Gauge" },
                        { value: "GeoChart", label: "GeoChart" },
                        { value: "Histogram", label: "Histogram" },
                        { value: "LineChart", label: "LineChart" },
                        { value: "Line", label: "Line" },
                        { value: "Bar", label: "Bar" },
                        { value: "Map", label: "Map" },
                        { value: "OrgChart", label: "OrgChart" },
                        { value: "PieChart", label: "PieChart" },
                        { value: "Sankey", label: "Sankey" },
                        { value: "ScatterChart", label: "ScatterChart" },
                        { value: "Scatter", label: "Scatter" },
                        { value: "SteppedAreaChart", label: "SteppedAreaChart" },
                        { value: "Table", label: "Table" },
                        { value: "Timeline", label: "Timeline" },
                        { value: "TreeMap", label: "TreeMap" },
                        { value: "WaterfallChart", label: "WaterfallChart" },
                        { value: "WordTree", label: "WordTree" },
                    ]
            },
            { value: "list-group-numbered", label: "Lista Enumerada", flComando: true },
            { value: "list-group-custom", label: "Lista Customizada", flComando: true },
            { value: "iframe", label: "Embedar", flComando: false },
        ];
    }

}
