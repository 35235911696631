import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import UsuarioService from "../../services/UsuarioService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputEmail from "../../components/forms/elements/InputEmail";
import SelectDDI from "../../components/app/SelectDDI";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import InputDate from "../../components/forms/elements/InputDate";
import InputPassword from "../../components/forms/elements/InputPassword";
import AvisoService from "../../services/AvisoService";
import InputFile from "../../components/forms/elements/InputFile";
import ArquivoService from "../../services/ArquivoService";
import Config from "../../Config";
import BtnButton from "../../components/forms/elements/BtnButton";

class FormUsuarioAtualizacao extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeAvisos = this.handleChangeAvisos.bind(this);
        this.handleChangeAvisoAll = this.handleChangeAvisoAll.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleFileDelete = this.handleFileDelete.bind(this);

        this.getAvisos = this.getAvisos.bind(this);

        this.state.isCard = true;

        this.state.model = {
            idUsuario: "",
            nome: "",
            usuario: "",
            email: "",
            senha: "",
            confirmacao: "",
            dtNascimento: "",
            ddi: "55",
            telefone: "",
            flExigirSenhaVerValor: false,
            flNotificacaoOrdemSomenteMinha: false,
            flAlterarSenha: false,
            usuarioAviso: [],
            usuarioFoto: {}
        };

        this.state.crud = {
            service: UsuarioService,
            labels: {
                title: 'Usuário',
            },
            urlList: '/LstUsuario',
        };

        this.state.avisos = [];
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getAvisos() {
        if (this.state.model.idPerfil) {
            AvisoService.listByPerfil(this.state.model.idPerfil, response => {
                this.setState({ avisos: response });
            });
        }
    }

    getById() {
        UsuarioService.getOneByUsuario((response) => {
            this.setModel(response, this.getAvisos);
        });
    }

    onSubmit(event) {
        UsuarioService.updateByUsuario(this.state.model, (response) => {
            this.onSave(response);
        });
    }

    handleChangeAvisos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.usuarioAviso.push({
                    idAviso: item.idAviso,
                    flWhatsapp: Boolean(item.flWhatsapp),
                    flSistema: Boolean(item.flSistema),
                    flEmail: Boolean(item.flEmail),
                });
                return state;
            });
        } else {
            var index = this.state.model.usuarioAviso.findIndex(i => parseInt(i.idAviso) === parseInt(item.idAviso));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.usuarioAviso.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeAvisoAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.usuarioAviso = [];

                state.avisos.forEach(function (item) {
                    state.model.usuarioAviso.push({
                        idAviso: item.idAviso,
                        flWhatsapp: Boolean(item.flWhatsapp),
                        flSistema: Boolean(item.flSistema),
                        flEmail: Boolean(item.flEmail),
                    });
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.usuarioAviso = [];

                state.avisos.filter(i => Boolean(i.flBloqueado)).forEach(function (item) {
                    state.model.usuarioAviso.push({
                        idAviso: item.idAviso,
                        flWhatsapp: true,
                        flSistema: true,
                        flEmail: true,
                    });
                });

                return state;
            });
        }
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-usuario-tab" data-bs-toggle="tab" data-bs-target="#nav-usuario" type="button" role="tab" aria-controls="nav-usuario" aria-selected="true">
                        Dados
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-avisos-tab" data-bs-toggle="tab" data-bs-target="#nav-avisos" type="button" role="tab" aria-controls="nav-avisos" aria-selected="false">
                        Avisos
                    </button>
                </li>
            </ul>);
    }

    handleFileChange(event) {

        var files = event.target?.files;

        if (files && files.length === 1) {
            ArquivoService.upload(files[0], (response) => {
                this.setState((state) => {
                    state.model.usuarioFoto = response.data;
                    return state;
                });
            });
        }
    }

    handleFileDelete(event) {
        this.setState((state) => {
            state.model.usuarioFoto = {};
            return state;
        });
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-1" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-usuario" role="tabpanel" aria-labelledby="nav-usuario-tab">
                    <div className="row mb-3 g-3">
                        <InputText disabled={true} label="Nome do Usuário" placeholder="Nome do Usuário" required md={6} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                        <InputEmail disabled={true} label="E-mail" md={6} name="email" value={this.state.model.email} onChange={this.handleChange} required={true} />
                    </div>
                    <div className={`row mx-1 mb-3 g-1`}>
                        <InputCheckSwitch label="Exigir senha para visualizar valor?" name="flExigirSenhaVerValor" checked={Boolean(this.state.model.flExigirSenhaVerValor)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Alterar Senha?" name="flAlterarSenha" checked={Boolean(this.state.model.flAlterarSenha)} onChange={this.handleChange} />
                    </div>

                    <div className="row mx-1 mb-3 g-1">
                        <SelectDDI showBtns={false} md={3} label="DDI" required={true} placeholder="Selecione" name="ddi" value={this.state.model.ddi} onChange={this.handleChange} />
                        {parseInt(this.state.model.ddi) === 55 ?
                            <InputTelCelular label="Celular" required={true} md={6} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} />
                            :
                            <InputText label="Celular" required={true} md={6} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} />
                        }
                        <InputDate label="Data de Nascimento" md={3} name="dtNascimento" value={this.state.model.dtNascimento} onChange={this.handleChange} required={true} />
                    </div>

                    {this.state.model.flAlterarSenha ?
                        <div className={`row mx-1 mb-3 g-1`}>
                            <InputPassword
                                autoComplete="off" label="Senha" placeholder="Senha" col={6} value={this.state.model.senha} name="senha" onChange={this.handleChange}
                                validations={[
                                    {
                                        validation: this.state.model.confirmacao === this.state.model.senha,
                                        message: "Senha e Confirmação devem ser iguais"
                                    }
                                ]}
                                required={true}
                            />
                            <InputPassword autoComplete="off" label="Confirmação" placeholder="Confirmação" col={6} value={this.state.model.confirmacao} name="confirmacao" onChange={this.handleChange}
                                validations={[
                                    {
                                        validation: this.state.model.confirmacao === this.state.model.senha,
                                        message: "Senha e Confirmação devem ser iguais"
                                    }
                                ]}
                                required={true}
                            />
                        </div>
                        : <></>
                    }
                    <div className="row g-2">                        
                        <InputFile col={6} label="Selecione sua foto" name="arquivo" onChange={(event) => { this.handleFileChange(event) }} accept="image/*" />
                        {this.state.model.usuarioFoto?.uid ?
                            <div className="col-md-6">
                                <img style={{ maxHeight: "150px" }} src={Config.getUrlArquivo() + "public/arquivo/inline/" + this.state.model.usuarioFoto.uid} className="img-fluid img-thumbnail" alt="..." />
                                <BtnButton className="btn-link pt-2 text-danger" onClick={this.handleFileDelete}> Excluir Foto </BtnButton>
                            </div>
                            : <></>}
                    </div>
                </div>
                <div className="tab-pane fade" id="nav-avisos" role="tabpanel" aria-labelledby="nav-avisos-tab">
                    <table className="table table-sm table-hover caption-top">
                        <thead>
                            <tr>
                                <th>
                                    <InputCheckSwitch name="todos" onChange={this.handleChangeAvisoAll} checked={this.state.model.usuarioAviso.length === this.state.avisos.length} />
                                </th>
                                <th className="col-md-6"> Aviso </th>
                                <th className="col-md-2"> Whatsapp </th>
                                <th className="col-md-2"> Sistema </th>
                                <th className="col-md-2"> E-mail </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.avisos.map((item, index) => {

                                var flChecked = this.state.model.usuarioAviso.findIndex(i => parseInt(i.idAviso) === parseInt(item.idAviso)) !== -1;
                                var indexUsuarioAviso = this.state.model.usuarioAviso.findIndex(i => parseInt(i.idAviso) === parseInt(item.idAviso));
                                var usuarioAviso = indexUsuarioAviso !== - 1 ? this.state.model.usuarioAviso[indexUsuarioAviso] : {};
                                var flWhatsapp = Boolean(usuarioAviso?.flWhatsapp);
                                var flSistema = Boolean(usuarioAviso?.flSistema);
                                var flEmail = Boolean(usuarioAviso?.flEmail);
                                var flBloqueado = Boolean(item?.flBloqueado);

                                return (
                                    <tr key={index}>
                                        <td>
                                            <InputCheckSwitch disabled={flBloqueado} name="representante" id={`ch-aviso-${item.idAviso}`} checked={flChecked} onChange={(e) => { this.handleChangeAvisos(e, item) }} />
                                        </td>
                                        <td>{item.nome}</td>
                                        <td className="text-center">
                                            <InputCheckSwitch disabled={flBloqueado || !flChecked || !Boolean(item.flWhatsapp)} name="flWhatsapp" id={`ch-whatsapp-${item.idAviso}`} checked={flWhatsapp} onChange={(e) => { this.handleChange(e, false, this.state.model.usuarioAviso[indexUsuarioAviso]) }} />
                                        </td>
                                        <td className="text-center">
                                            <InputCheckSwitch disabled={flBloqueado || !flChecked || !Boolean(item.flSistema)} name="flSistema" id={`ch-sistema-${item.idAviso}`} checked={flSistema} onChange={(e) => { this.handleChange(e, false, this.state.model.usuarioAviso[indexUsuarioAviso]) }} />
                                        </td>
                                        <td className="text-center">
                                            <InputCheckSwitch disabled={flBloqueado || !flChecked || !Boolean(item.flEmail)} name="flEmail" id={`ch-email-${item.idAviso}`} checked={flEmail} onChange={(e) => { this.handleChange(e, false, this.state.model.usuarioAviso[indexUsuarioAviso]) }} />
                                        </td>
                                    </tr>);
                            })}

                            <tr>
                                <td>
                                    <InputCheckSwitch label="" name="flNotificacaoOrdemSomenteMinha" checked={Boolean(this.state.model.flNotificacaoOrdemSomenteMinha)} onChange={this.handleChange} />
                                </td>
                                <td>
                                    Receber avisos somente das minhas ordens
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>

        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormUsuarioAtualizacao {...props} navigate={navigate} params={params} />
}

export default With