import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormCartao from "./FormCartao";

class FormCartaoDebito extends FormCartao {

    constructor(props) {
        super(props);
        this.state.model.ccType = "D";
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return super.render();
    }

    toPagamento() {
        this.props.navigate('/pagamento/' + this.state.transacao.uidCobranca);
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCartaoDebito {...props} navigate={navigate} params={params} />
}

export default With