import React, { Component } from "react";
import Config from "../../../Config";
import EventBus from "../../../controllers/EventBus";
import { Tooltip } from 'react-tooltip'

export default class FormField extends Component {

    constructor(props) {
        super(props);
        this.isComponentMounted = false;
        this.hasError = this.hasError.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.dispachChangeDidMount = this.props.dispachChangeDidMount === undefined ? true : this.props.dispachChangeDidMount;

        this.state = {
            eventsNames: {
                onChange: false,
                onBlur: false,
            },
            error: "",
            ref: props.refElement === undefined ? React.createRef() : props.refElement,
            col: "",
            colSize: "",
            colValue: 0,
            validations: props.validations || [],
        }

        this.state.eventsNames = {
            onChange: "change.field",
            onBlur: "blur.field",
        };

        if (this.props.col) {
            //this.state.col = "col-" + this.props.col
            this.state.colValue = "";
            this.state.colSize = this.props.col;
        } else if (this.props.xs) {
            //this.state.col = "col-" + this.props.xs
            this.state.colValue = this.props.xs;
            this.state.colSize = "xs";
        } else if (this.props.sm) {
            //this.state.col = "col-sm-" + this.props.sm
            this.state.colValue = this.props.sm;
            this.state.colSize = "sm";
        } else if (this.props.md) {
            //this.state.col = "col-md-" + this.props.md
            this.state.colValue = this.props.md;
            this.state.colSize = "md";
        } else if (this.props.lg) {
            //this.state.col = "col-lg-" + this.props.lg
            this.state.colValue = this.props.lg;
            this.state.colSize = "lg";
        } else if (this.props.xl) {
            //this.state.col = "col-xl-" + this.props.xl
            this.state.colValue = this.props.xl;
            this.state.colSize = "xl";
        } else if (this.props.xxl) {
            //this.state.col = "col-xxl-" + this.props.xxl
            this.state.colValue = this.props.xxl;
            this.state.colSize = "xxl";
        }

        if (this.state.colSize) {
            this.state.col = "col-" + this.state.colSize + (this.state.colValue > 0 ? "-" + this.state.colValue : "");
        }

        /*if (this.props["event-bus-dispach"]) {
            EventBus.on(this.props["event-bus-dispach"], (data) => {
                this.receivEventDispach(this.props["event-bus-dispach"], data);
            });
        }*/
    }

    dispachChange() {

    }

    componentDidMount() {
        if (this.state.ref?.current?.validity) {
            this.setState({ isComponentMounted: true, error: this.hasError(this.state.ref.current) });
        }

        if (this.dispachChangeDidMount) {
            if (this.props.value) {
                //this.handleChange({ target: { value: this.props.value, name: this.props.name } });
            }
        }
    }

    componentWillUnmount() {
        /*if (this.props["event-bus-dispach"]) {
            EventBus.remove(this.props["event-bus-dispach"]);
        }*/
        this.setState({ isComponentMounted: false });
    }

    static getDerivedStateFromProps(props, state) { //1
        if (props.validations) {
            //state.validations = props.validations;
            for (const item of props.validations) {
                if (state.ref?.current) {
                    if (!item.validation) {
                        state.ref.current.setCustomValidity(item.message);
                        state.error = item.message;
                    } else {
                        state.ref.current.setCustomValidity("");
                        //state.error = "";
                    }
                }
            }
        }

        if (props.value && props.value !== state.value) {

            if (state.eventsNames?.onChange) {
                var element = { event: "getDerivedStateFromProps" };
                element[props.name] = props.value;
                EventBus.dispatch(state.eventsNames.onChange, element, true);
                EventBus.dispatch(state.eventsNames.onChange + "." + props.name, element, true);
            }

            return {
                value: props.value,
            };
        }
        return null;
    }

    getSnapshotBeforeUpdate(prevProps, prevState) { //2
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) { //3
    }

    getProps(props, delProps = []) {
        const inputProps = Object.assign({}, props);

        inputProps.id = inputProps.id ? inputProps.id : "formField" + (inputProps.name ? inputProps.name : Math.random());

        //placeHolderAutomaticLabel

        if (Config.default.placeHolderDefaultLabelValue && inputProps.label) {
            inputProps.placeholder = inputProps.placeholder ? inputProps.placeholder : inputProps.label;
        }

        if (inputProps.layout === "floating") {
            inputProps.placeholder = inputProps.placeholder ? inputProps.placeholder : "";
        }

        delete inputProps.validations;
        delete inputProps.col;
        delete inputProps.xs;
        delete inputProps.sm;
        delete inputProps.md;
        delete inputProps.lg;
        delete inputProps.xl;
        delete inputProps.xxl;
        delete inputProps.show;
        delete inputProps.sizing;
        delete inputProps.layout;
        delete inputProps.label;
        delete inputProps.options;
        delete inputProps.refElement;
        delete inputProps.opcionalLabel;
        delete inputProps.showBtnNewSearch;
        delete inputProps.showBtnNewForm;
        delete inputProps.showBtnNewSearch;
        delete inputProps.showBtns;
        delete inputProps.dispachChangeDidMount;
        delete inputProps.onValueChange;
        delete inputProps.unidadeMedida;
        delete inputProps.disabledValues;
        delete inputProps.openSearchOnUpdate;
        delete inputProps.onChangeItem;
        delete inputProps.onClickCopy;
        delete inputProps.handleChangeItem;
        delete inputProps.handleChangeAll;
        delete inputProps.rowCols;
        delete inputProps.showFieldSearch;
        delete inputProps.labelStyle;
        delete inputProps.textAlign;

        delete inputProps.idPais;
        delete inputProps.idEstado;
        delete inputProps.idTipoProdutoServico;
        delete inputProps.idCategoriaProdutoServico;
        delete inputProps.idRepresentante;
        delete inputProps.idProprietario;
        delete inputProps.idOrdemTipo;
        delete inputProps.idAreaAtuacao;
        delete inputProps.idGrupoModulo;
        delete inputProps.idContatoStatus;
        delete inputProps.idContatoTipo;
        delete inputProps.idPlanoRecorrencia;
        delete inputProps.idBanco;
        delete inputProps.idMarca;

        delete inputProps.openAll;
        delete inputProps.selectFolder;
        delete inputProps.selectElement;
        delete inputProps.ativo;
        delete inputProps.nrAno;

        //delete inputProps["event-bus-dispach"];

        for (var i = 0; i < delProps.length; i++) {
            delete inputProps[delProps[i]];
        }

        return inputProps;
    }

    getRenderInputGroupRigth() {
        return false;
    }

    getRenderInputGroupLeft() {
        return false;
    }

    getRenderGridItem(render) {
        var _label = false;

        if (this.props.label) {
            _label = this.props.label;
        }

        render =
            <div className={`grid-item ${this.state.col} ${this.props.show === false ? "d-none" : ""} ${this.props.textAlign ? 'text-' + this.props.textAlign : ""}`}>
                {_label ? <label htmlFor={this.props.id} className={`grid-item-label`}>{_label}</label> : ""}                
                {render}
            </div>;

        return render;
    }

    getRenderFormControl(render) {
        var _default = Config.default?.layoutFormControl ? Config.default.layoutFormControl : "grid";
        var _layout = this.props.layout ? this.props.layout : _default;
        var _label = false;
        var _opcionalLabel = this.props.opcionalLabel !== undefined ? this.props.opcionalLabel : true;

        var inputGroupRigth = this.getRenderInputGroupRigth();
        var inputGroupLeft = this.getRenderInputGroupLeft();

        if (this.props.label) {
            _label = this.props.label;

            if (Config.default?.opcionalLabelDefaultNotRequired && !this.props.required && _opcionalLabel) {
                _label = <>{this.props.label} <small className="badge bg-light text-dark">opcional</small></>;
            }
        }

        var renderHelp = <></>;

        if(this.props.help){
            let idHelp = Math.random();
            renderHelp = (
                <span>
                    &nbsp;
                    <span data-tooltip-id={`my-tooltip-styles-${idHelp}`}  data-tooltip-content={this.props.help}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                        </svg>
                    </span> <Tooltip id={`my-tooltip-styles-${idHelp}`} />
                </span>
            );
        }
            



        switch (_layout) {
            case "floating":
                if (inputGroupRigth || inputGroupLeft) {
                    render =
                        <div className={`${this.state.col} ${this.props.show === false ? "d-none" : ""}`}>
                            <div className={`input-group flex-nowrap ${this.props.sizing ? "input-group-" + this.props.sizing : ""}`}>
                                {inputGroupLeft}
                                <div className={`form-floating flex-grow-1`}>
                                    {render}
                                    {_label ? <label htmlFor={this.props.id} className={`form-label ${this.props.sizing ? "form-label-app col-form-label-" + this.props.sizing : ""}`}>{_label}</label> : ""}
                                    {renderHelp}
                                    <div className="invalid-feedback">{this.state.error}</div>
                                </div>
                                {inputGroupRigth}
                            </div>
                        </div>;
                } else {
                    render =
                        <div className={`form-floating flex-nowrap ${this.state.col} ${this.props.show === false ? "d-none" : ""}`}>
                            {render}
                            {_label ? <label htmlFor={this.props.id} className={`form-label ${this.props.sizing ? "form-label-app col-form-label-" + this.props.sizing : ""}`}>{_label}</label> : ""}
                            {renderHelp}
                            <div className="invalid-feedback">{this.state.error}</div>
                        </div>;
                }
                break;

            case "horizontal":
                if (inputGroupRigth || inputGroupLeft) {
                    render =
                        <div className={`row mb-3 ${this.props.show === false ? "d-none" : ""}`}>
                            {_label ? <label htmlFor={this.props.id} className={`col-${this.state.colSize}-${12 - this.state.colValue} col-form-label ${this.props.sizing ? "form-label-app col-form-label-" + this.props.sizing : ""}`}>{_label}</label> : ""}
                            <div className={`${this.state.col}`}>
                                <div className={`input-group ${this.props.sizing ? "input-group-" + this.props.sizing : ""}`}>
                                    {inputGroupLeft}
                                    {render}
                                    {inputGroupRigth}
                                    <div className="invalid-feedback">{this.state.error}</div>
                                </div>
                            </div>

                        </div>;
                    break;
                } else {
                    render =
                        <div className={`row mb-3 ${this.props.show === false ? "d-none" : ""}`}>
                            {_label ? <label htmlFor={this.props.id} className={`col-${this.state.colSize}-${12 - this.state.colValue} col-form-label ${this.props.sizing ? "form-label-app col-form-label-" + this.props.sizing : ""}`}>{_label}</label> : ""}
                            <div className={this.state.col}>
                                {render}
                                <div className="invalid-feedback">{this.state.error}</div>
                            </div>
                        </div>;
                    break;
                }

            default:
                if (inputGroupRigth || inputGroupLeft) {
                    render =
                        <div className={`${this.state.col} ${this.props.show === false ? "d-none" : ""}`}>
                            {_label ? <label htmlFor={this.props.id} className={`form-label ${this.props.sizing ? "form-label-app col-form-label-" + this.props.sizing : ""}`}>{_label}</label> : ""}
                            {renderHelp}
                            <div className={`input-group has-validation ${this.props.sizing ? "input-group-" + this.props.sizing : ""}`}>
                                {inputGroupLeft}
                                {render}
                                {inputGroupRigth}
                                <div className="invalid-feedback">{this.state.error}</div>
                            </div>
                        </div>;
                    break;
                } else {                    
                    render =
                        <div className={`${this.state.col} ${this.props.show === false ? "d-none" : ""}`}>
                            {_label ? <label htmlFor={this.props.id} className={`form-label ${this.props.sizing ? "form-label-app col-form-label-" + this.props.sizing : ""}`}>{_label}</label> : ""}
                            {renderHelp}
                            {render}
                            <div className="invalid-feedback">{this.state.error}</div>
                        </div>;
                }
        }

        return (render);
    }


    handleChange(event) {
    }


    handleBlur(event) {
        this.setState({ error: this.hasError(event.target) });

        if (this.state.eventsNames?.onBlur) {
            var element = {};

            switch (event.target?.type) {
                case "checkbox":
                    if (event.target.name) {
                        element[event.target.name] = event.target.checked;
                        EventBus.dispatch(this.state.eventsNames.onBlur, element);
                        EventBus.dispatch(this.state.eventsNames.onBlur + "." + event.target.name, element);
                    }
                    break;

                default:
                    if (event.target.name) {
                        element[event.target.name] = event.target.value;
                        EventBus.dispatch(this.state.eventsNames.onBlur, element);
                        EventBus.dispatch(this.state.eventsNames.onBlur + "." + event.target.name, element);
                    }
            }
        }
    }

    hasError(field) {

        if (field) {

            // Don't validate submits, buttons, file and reset inputs, and disabled fields
            if (field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') return;

            // Get validity
            var validity = field.validity;

            // If valid, return null
            if (validity.valid) return;

            if (validity.patternMismatch && field.hasAttribute('title')) {
                if (field.hasAttribute('title')) return field.getAttribute('title');
            }

            if (field.validationMessage) return field.validationMessage;

            // If field is required and empty
            if (validity.valueMissing) return 'Please fill out this field.';

            // If not the right type
            if (validity.typeMismatch) {

                // Email
                if (field.type === 'email') return 'Please enter an email address.';

                // URL
                if (field.type === 'url') return 'Please enter a URL.';

            }

            // If too short
            if (validity.tooShort) return 'Please lengthen this text to ' + field.getAttribute('minLength') + ' characters or more. You are currently using ' + field.value.length + ' characters.';

            // If too long
            if (validity.tooLong) return 'Please shorten this text to no more than ' + field.getAttribute('maxLength') + ' characters. You are currently using ' + field.value.length + ' characters.';

            // If number input isn't a number
            if (validity.badInput) return 'Please enter a number.';

            // If a number value doesn't match the step interval
            if (validity.stepMismatch) return 'Please select a valid value.';

            // If a number field is over the max
            if (validity.rangeOverflow) return 'Please select a value that is no more than ' + field.getAttribute('max') + '.';

            // If a number field is below the min
            if (validity.rangeUnderflow) return 'Please select a value that is no less than ' + field.getAttribute('min') + '.';

            // If pattern doesn't match
            if (validity.patternMismatch) {

                // If pattern info is included, return custom error
                if (field.hasAttribute('title')) return field.getAttribute('title');

                // Otherwise, generic error
                return 'Please match the requested format.';

            }
        }

        // If all else fails, return a generic catchall error
        return 'The value you entered for this field is invalid.';

    };

    receivEventDispach(event, data) { }
}