import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import AvisoService from "../../services/AvisoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputEmail from "../../components/forms/elements/InputEmail";
import SelectDDI from "../../components/app/SelectDDI";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import TextArea from "../../components/forms/elements/TextArea";

class FormAviso extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idAviso: "",
            nome: "",
            flWhatsapp: true,
            flSistema: true,
            flEmail: true,
            flAtivo: true,
            flBloqueado: false,
            dsRemetenteEmail: "",
            ddiRemetenteWhatsapp: "55",
            celRemetenteWhatsapp: "",
            flTemplate: false,
            cdTemplateId: "",

            dsTitulo : "",
            dsMensagem : "",
            dsRodape : "",
        };

        this.state.crud = {
            service: AvisoService,
            labels: {
                title: 'Tipo',
            },
            urlList: '/LstAviso',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idAviso} name="idAviso" readOnly opcionalLabel={false} />
                    <InputText label="Nome do Aviso" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                </div>

                <div className="row mb-3 g-3">
                    <InputText label="Título" required md={12} value={this.state.model.dsTitulo} name="dsTitulo" onChange={this.handleChange} maxLength={100} />
                    <TextArea label="Mensagem" required md={12} value={this.state.model.dsMensagem} name="dsMensagem" onChange={this.handleChange} style={{height : "150px"}}/>
                    <InputText label="Rodapé" required md={12} value={this.state.model.dsRodape} name="dsRodape" onChange={this.handleChange} maxLength={100} />
                </div>

                <div className="row mx-1 mb-3">
                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Bloqueado para o usuário alterar?" name="flBloqueado" checked={Boolean(this.state.model.flBloqueado)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Tem Notificação via Whatsapp?" name="flWhatsapp" checked={Boolean(this.state.model.flWhatsapp)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Tem Notificação pelo Sistema?" name="flSistema" checked={Boolean(this.state.model.flSistema)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Tem Notificação via E-mail?" name="flEmail" checked={Boolean(this.state.model.flEmail)} onChange={this.handleChange} />
                    {Boolean(this.state.model.flWhatsapp) ?
                        <InputCheckSwitch label="Tem Template?" name="flTemplate" checked={Boolean(this.state.model.flTemplate)} onChange={this.handleChange} />
                        : <></>}
                </div>

                <div className="row mb-3 g-3">
                    {Boolean(this.state.model.flEmail) ?
                        <InputEmail label="E-mail do Remente do Aviso" required md={5} value={this.state.model.dsRemetenteEmail} name="dsRemetenteEmail" onChange={this.handleChange} maxLength={100} />
                        : <></>}

                    {Boolean(this.state.model.flWhatsapp) ?
                        <>
                            <SelectDDI md={2} label="DDI" required={true} placeholder="Selecione" name="ddiRemetenteWhatsapp" value={this.state.model.ddiRemetenteWhatsapp} onChange={this.handleChange} />
                            {parseInt(this.state.model.ddiRemetenteWhatsapp) === 55 ?
                                <InputTelCelular label="Celular" required={true} md={5} value={this.state.model.celRemetenteWhatsapp} name="celRemetenteWhatsapp" onChange={this.handleChange} />
                                :
                                <InputText label="Celular" required={true} md={5} value={this.state.model.celRemetenteWhatsapp} name="celRemetenteWhatsapp" onChange={this.handleChange} />
                            }
                        </>
                        : <></>}

                    {Boolean(this.state.model.flWhatsapp)  ?
                        <InputText label="Código do Template" required={Boolean(this.state.model.flTemplate)} md={12} value={this.state.model.cdTemplateId} name="cdTemplateId" onChange={this.handleChange} maxLength={100} />
                        : <></>}
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormAviso {...props} navigate={navigate} params={params} />
}

export default With