import Http from "../controllers/Http";

export default class RepresentanteEquipamentoAtualizacaoService {

    static urlBase = "/representanteEquipamentoAtualizacao/";

    static getForCombo(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forCombo')
            .then(fnSucess)
            .catch(fnError);
    }

    static list(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'list', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static criarOrdem(idRepresentanteEquipamentoAtualizacao, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'criarOrdem/' + idRepresentanteEquipamentoAtualizacao, {})
            .then(fnSucess)
            .catch(fnError);
    }

    static cancelarAtualizacao(idRepresentanteEquipamentoAtualizacao, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'cancelarAtualizacao/' + idRepresentanteEquipamentoAtualizacao, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static update(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static save(data, fnSucess = () => { }, fnError = () => { }) {
        if (data.idRepresentanteEquipamentoAtualizacao) {
            this.update(data.idRepresentanteEquipamentoAtualizacao, data, fnSucess, fnError);
        } else {
            this.create(data, fnSucess, fnError);
        }
    }
}
