import React from "react";
import CrudForm from "../../components/forms/custom/CrudForm";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import Util from "../../utils/Util";
import Config from "../../Config";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputDate from "../../components/forms/elements/InputDate";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import SelectBanco from "../../components/app/SelectBanco";
import SelectContaByBanco from "../../components/app/SelectContaByBanco";
import SelectTipoBaixa from "../../components/app/SelectTipoBaixa";

export default class FormRelatorioFinanceiro003 extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeOrdemStatus = this.handleChangeOrdemStatus.bind(this);

        this.state.isModal = true;

        this.urlRelatorio = "relatorio/financeiro-003";

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;

        var dtBaixaEnd = new Date();
        dtBaixaEnd = new Date(dtBaixaEnd - tzoffset).toISOString().slice(0, 10);

        var dtBaixaStart = new Date();
        dtBaixaStart.setDate(dtBaixaStart.getDate() - 7);
        dtBaixaStart = new Date(dtBaixaStart - tzoffset).toISOString().slice(0, 10);

        this.state.model = {
            idRepresentante: "",
            idBanco: "",
            idConta: "",
            idTipoBaixa: "",
            dtVencimentoStart: "",
            dtVencimentoEnd: "",
            dtPagamentoStart: "",
            dtPagamentoEnd: "",
            dtBaixaStart: dtBaixaStart,
            dtBaixaEnd: dtBaixaEnd,
            orientation: "L",
            destination: "I",
            output: "PDF",            
            flMarcaDagua: true,
            flFiltroCdTipo: "T",
            flAgruparPor: "N",
            idOrdensStatus: [1, 2, 4, 5]
        };

        this.state.orientation = [
            { value: 'P', label: 'Vertical' },
            { value: 'L', label: 'Horizontal' },
        ]

        this.state.destination = [
            { value: 'I', label: 'Tela' },
            { value: 'D', label: 'Download' },
        ]

        this.state.output = [
            { value: 'PDF', label: 'PDF' },
            { value: 'XLS', label: 'XLS' },
        ]

        this.state.filtroTipo = [
            { value: "T", label: "Todos" },
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ]

        this.state.filtroAgrupar = [
            { value: "N", label: "Nenhum" },
            { value: "DB", label: "Data de Baixa" },
            { value: "CO", label: "Conta" },
        ]
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(e) {
        Util.openNewWindow(Config.getUrlApi() + this.urlRelatorio, this.state.model);
    }

    handleChangeOrdemStatus(isChecked, item) {
        if (isChecked) {
            this.setState(state => {
                state.model.idOrdensStatus.push(item.value);
                return state;
            });
        } else {
            this.setState(state => {
                var index = this.state.model.idOrdensStatus.findIndex(i => parseInt(i) === parseInt(item.value));
                state.model.idOrdensStatus.splice(index, 1);
                return state;
            });
        }
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <SelectRepresentanteByUsuario showBtnNewForm={false} md={12} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={this.handleChange}  />
                </div>
                <div className="row mb-3 g-3">
                    <SelectTipoBaixa showBtnNewForm={false} md={12} label="Tipo de Baixa" placeholder="Selecione o Tipo de Baixa" name="idTipoBaixa" value={this.state.model.idTipoBaixa} onChange={this.handleChange}  />
                </div>
                <div className="row mb-3 g-3">
                    <SelectBanco showBtnNewForm={false} label="Nome do Banco" required={false} placeholder="Selecione o Banco" name="idBanco" md={6} value={this.state.model.idBanco} onChange={this.handleChange}  />
                    <SelectContaByBanco idBanco={this.state.model.idBanco} showBtnNewForm={false} label="Nome da Conta" required={false} placeholder="Selecione a Conta" name="idConta" md={6} value={this.state.model.idConta} onChange={this.handleChange}  />
                </div>
                <div className="row mb-3 g-3">
                    <InputDate md={6} label="Data Inicial de Vencimento" value={this.state.model.dtVencimentoStart} name="dtVencimentoStart" onChange={this.handleChange} required={false}  />
                    <InputDate md={6} label="Data Final de Vencimento" value={this.state.model.dtVencimentoEnd} name="dtVencimentoEnd" onChange={this.handleChange} min={this.state.model.dtVencimentoStart} required={false}  />
                </div>
                <div className="row mb-3 g-3">
                    <InputDate md={6} label="Data Inicial de Baixa" value={this.state.model.dtBaixaStart} name="dtBaixaStart" onChange={this.handleChange} required={true} />
                    <InputDate md={6} label="Data Final de Baixa" value={this.state.model.dtBaixaEnd} name="dtBaixaEnd" onChange={this.handleChange} min={this.state.model.dtBaixaStart} required={true} />
                </div>                
                <div className="row mb-3 g-3">
                    <InputDate md={6} label="Data Inicial de Pagamento" value={this.state.model.dtPagamentoStart} name="dtPagamentoStart" onChange={this.handleChange} required={false}  />
                    <InputDate md={6} label="Data Final de Pagamento" value={this.state.model.dtPagamentoEnd} name="dtPagamentoEnd" onChange={this.handleChange} min={this.state.model.dtPagamentoStart} required={false}  />
                </div>
                <div className="row mb-3">
                    <BtnGroup md={5} sizing="sm" className="w-100" name="flFiltroCdTipo" value={this.state.model.flFiltroCdTipo} label="Tipo da Fatura" options={this.state.filtroTipo} onChange={this.handleChange} required />
                    <BtnGroup md={7} sizing="sm" className="w-100" name="flAgruparPor" value={this.state.model.flAgruparPor} label="Agrupar por" options={this.state.filtroAgrupar} onChange={this.handleChange} required />
                </div>
                <div className="row mb-3">
                    <BtnGroup md={4} sizing="sm" className="w-100" name="orientation" value={this.state.model.orientation} label="Disposição da Página" options={this.state.orientation} onChange={this.handleChange} required />
                    <BtnGroup md={4} sizing="sm" className="w-100" name="destination" value={this.state.model.destination} label="Destino do Relatório" options={this.state.destination} onChange={this.handleChange} required />
                    <BtnGroup md={4} sizing="sm" className="w-100" name="output" value={this.state.model.output} label="Formato de Saída" options={this.state.output} onChange={this.handleChange} required />
                </div>
                <div className="row px-3 mb-3">                    
                    <InputCheckSwitch label="Mostrar marca d'água" name="flMarcaDagua" checked={Boolean(this.state.model.flMarcaDagua)} onChange={this.handleChange} />
                </div>
            </>
        );
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="ms-auto btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
                <div className="vr"></div>
                <BtnSubmit className="btn-primary"> Emitir Relatório </BtnSubmit>
            </div>);
    }

    render() {
        return super.render();
    }
}