import { Component } from "react";
import Util from "../../../utils/Util";
import FormMask from "../base/FormMask";

export default class InputCnpj extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleChange(event) {        
        if (event.target.value && !Util.CNPJIsValid(event.target.value)) {
            event.target.setCustomValidity("CNPJ Inválido!");
        } else {
            event.target.setCustomValidity("");
        }

        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        return (
            <FormMask {...this.props}
                onChange={this.handleChange}
                mask="99.999.999/9999-99"
                pattern="\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}"
                placeholder="99.999.999/9999-99"
            />);
    }
}