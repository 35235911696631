import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import OrdemService from "../../services/OrdemService";
import CrudForm from "../../components/forms/custom/CrudForm";
import TextArea from "../../components/forms/elements/TextArea";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import Util from "../../utils/Util";
import SelectCategoriaProdutoServicoByUsuario from "../../components/app/SelectCategoriaProdutoServicoByUsuario";
import SelectTipoProdutoServicoByCategoriaComProduto from "../../components/app/SelectTipoProdutoServicoByCategoriaComProduto";

class FormOrdemSuporte extends CrudForm {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state.model = {
            idOrdem: props.ordem.idOrdem,
            idCategoriaProdutoServico: props.ordem.idCategoriaProdutoServico,
            idTipoProdutoServico: props.ordem.idTipoProdutoServico,
            flSuporte: true,
            dsComentario: ""
        };

        this.state.ordem = props.ordem;

        this.state.crud = {
            service: OrdemService,
            labels: {
                title: 'Ordem de Suporte',
            },
            urlList: '/LstModulo',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event) {
        OrdemService.create(this.state.model, (response) => {
            this.onSave(response);
        });
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                {this.state.showBtnSalvar ? <BtnSubmit className="btn-primary"> Abrir Ordem de Suporte </BtnSubmit> : <></>}
                {this.state.showBtnCancelar ? <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton> : <></>}
            </div>);
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText disabled={true} md={4} label="Código da Ordem" value={this.state.ordem.idOrdem} opcionalLabel={false} />
                    <InputText disabled={true} md={4} label="Data da Ordem" value={Util.date2Br(this.state.ordem.dataOrdem) + " " + this.state.ordem.horaOrdem} opcionalLabel={false} />
                    <InputText disabled={true} md={4} label="Data da Alteração" value={Util.date2Br(this.state.ordem.dataUpdate) + " " + this.state.ordem.horaUpdate} opcionalLabel={false} />

                    <SelectCategoriaProdutoServicoByUsuario md={6} label="Categoria de Produto" required placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={this.handleChange} />
                    <SelectTipoProdutoServicoByCategoriaComProduto idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} disabled={!this.state.model.idCategoriaProdutoServico} md={6} label="Tipo de Produto" required placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={this.handleChange} />

                    <InputText disabled={true} label="Cliente" md={6} value={this.state.ordem.ordemCliente?.nome + " - " + this.state.ordem.ordemCliente?.ddi + " " + this.state.ordem.ordemCliente?.celular} readOnly opcionalLabel={false} />
                    <InputText disabled={true} label="Veículo" md={6} value={this.state.ordem.ordemVeiculo?.nomeMarca + " - " + this.state.ordem.ordemVeiculo?.modelo} readOnly opcionalLabel={false} />
                </div>
                <div className="row mb-3 g-3">
                    <TextArea autoFocus={true} col={12} label="Qual o seu Problema?" placeholder="Digite aqui o seu problema com a ordem" required={true} name="dsComentario" value={this.state.model.dsComentario} onChange={this.handleChange} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormOrdemSuporte {...props} navigate={navigate} params={params} />
}

export default With