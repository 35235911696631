import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import PlanoRecorrenciaService from "../../services/PlanoRecorrenciaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputNumber from "../../components/forms/elements/InputNumber";

class FormPlanoRecorrencia extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idPlanoRecorrencia: "",
            nome: "",
            cdTipo: "",
            dsRecorrencia: ""
        };

        this.state.cdParte = [
            { value: "PRIMEIRO_DIA_MES", label: "Primeiro dia do Mês" },
            { value: "ULTIMO_DIA_MES", label: "Último dia do Mês" },
            { value: "QUINZENAL_DIA_01_E_15", label: "Quinzenal 01 e 15" },
            { value: "IMEDIATO", label: "Imediato" },
        ];

        this.state.tipoRecorrencia = [
            { value: "D", label: "Diária" },
            { value: "S", label: "Semanal" },
            { value: "M", label: "Mensal" },
            { value: "P", label: "Parte" },
        ];

        this.state.diaSemana = [
            { value: 1, label: "domingo" },
            { value: 2, label: "segunda-feira" },
            { value: 3, label: "terça-feira" },
            { value: 4, label: "quarta-feira" },
            { value: 5, label: "quinta-feira" },
            { value: 6, label: "sexta-feira" },
            { value: 7, label: "sábado" },
        ];

        this.state.crud = {
            service: PlanoRecorrenciaService,
            labels: {
                title: 'Plano de Recorrência',
            },
            urlList: '/LstPlanoRecorrencia',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={3} value={this.state.model.idPlanoRecorrencia} name="idPlanoRecorrencia" readOnly opcionalLabel={false} />
                <InputText label="Nome do Plano de Recorrência" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                <BtnGroup required={true} md={4} className="w-100" name="cdTipo" value={this.state.model.cdTipo} label="Tipo de Recorrência" options={this.state.tipoRecorrencia} onChange={this.handleChange} />

                {this.state.model.cdTipo === "S" ?
                    <BtnGroup required={true} md={8} className="w-100" name="dsRecorrencia" value={this.state.model.dsRecorrencia} label="Recorrência" options={this.state.diaSemana} onChange={this.handleChange} />
                    : <></>}

                {this.state.model.cdTipo === "M" ?
                    <InputNumber min={1} max={31} className="text-center" label="Recorrência" required md={3} value={this.state.model.dsRecorrencia} name="dsRecorrencia" onChange={this.handleChange} />
                    : <></>}

                {this.state.model.cdTipo === "P" ?
                    <BtnGroup required={true} md={8} className="w-100" name="dsRecorrencia" value={this.state.model.dsRecorrencia} label="Recorrência" options={this.state.cdParte} onChange={this.handleChange} />
                    : <></>}
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormPlanoRecorrencia {...props} navigate={navigate} params={params} />
}

export default With