import React from "react";
import CidadeService from "../../services/CidadeService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaCidade extends CrudList {

    constructor(props) {
        super(props);

        this.getData = this.getData.bind(this);

        this.state.crud = {
            service: CidadeService,
            labels: {
                title: 'Lista de Cidade(es)',
                btnNewRegister: 'Cidade',
            },
            keyItem: 'idCidade',
            urlForm: '/CadCidade',
            itensFilter: ["idCidade", "nome", "nomeEstado", "nomePais"],
            searchFormSubmit: true
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onList() {
        this.state.crud.service.listSearch({
            nomeCidade: this.state.model.busca.trim()
        }, (response) => {
            this.setState({ data: response });
        });
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-6"> Nome </th>
                <th className="col-md-2 text-center"> Estado </th>
                <th className="col-md-2 d-none d-md-table-cell"> País </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idCidade}</td>
                <td>{item.nome}</td>
                <td className="text-center">{item.siglaEstado}</td>
                <td className="d-none d-md-table-cell">{item.nomePais}</td>
            </>);
    }

    render() {
        return super.render();
    }
}