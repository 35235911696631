import { Component } from "react";
import FormMask from "../base/FormMask";

export default class InputCartaoValidade extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    isValid(value) {
        try {
            var hoje, data;
            var mes = parseInt(value.substring(0, 2)) - 1;
            var ano = parseInt(value.substring(3, 7));

            if (mes < 0 || mes > 11) {
                return false;
            }

            hoje = new Date();
            hoje = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
            data = new Date(ano, mes, 1);

            if (data >= hoje) {
                return true;
            }
        } catch (error) {
            return false;
        }
        return false;

    }

    handleChange(event) {
        if (event.target.value && !this.isValid(event.target.value)) {
            event.target.setCustomValidity("Data Inválida!");
        } else {
            event.target.setCustomValidity("");
        }

        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        return (
            <FormMask {...this.props}
                onChange={this.handleChange}
                mask="99/9999"
                maskChar={null}
                pattern="\d{2}/\d{4}"
                placeholder="MM/YYYY"
            />);
    }
}