import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import CronService from "../../services/CronService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import TextArea from "../../components/forms/elements/TextArea";
import Util from "../../utils/Util";

class FormCron extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idCron: "",
            nome: "",
            crontab: "",
            url: "HTTP_SCHEME://HTTP_HOST_API/cron/XYZ",
            flAtivo: true,
            dsOpcoes: "{}"
        };

        this.state.crud = {
            service: CronService,
            keyName: "idCron",
            labels: {
                title: 'Cron',
            },
            urlList: '/LstCron',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idCron} name="idCron" readOnly opcionalLabel={false} />
                </div>
                <div className="row mb-3 g-3">
                    <InputText label="Nome do Cron" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <InputText label="CronTab" required md={3} value={this.state.model.crontab} name="crontab" onChange={this.handleChange} maxLength={100} />
                    <InputText label="URL" required md={12} value={this.state.model.url} name="url" onChange={this.handleChange} maxLength={250} />
                    <TextArea label="Opções" md={12} value={this.state.model.dsOpcoes} name="dsOpcoes" onChange={this.handleChange} style={{ height: "200px", fontFamily: "Courier New" }} required={true} />

                    {!Util.isJsonString(this.state.model.dsOpcoes) ?
                        <div className="alert alert-danger pt-1 pb-1" role="alert">
                            JSON Inválido
                        </div>
                        : <></>}
                </div>
                <div className="row mb-3 g-3 mx-1">
                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCron {...props} navigate={navigate} params={params} />
}

export default With