import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import BaixaService from "../../services/BaixaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputValorReais from "../../components/forms/custom/InputValorReais";
import Util from "../../utils/Util";
import CobrancaService from "../../services/CobrancaService";
import InputDate from "../../components/forms/elements/InputDate";
import SelectTipoBaixa from "../../components/app/SelectTipoBaixa";
import Permissao from "../../controllers/Permissao";
import EventBus from "../../controllers/EventBus";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import TextArea from "../../components/forms/elements/TextArea";
import BtnButton from "../../components/forms/elements/BtnButton";
import GridItem from "../../components/forms/elements/GridItem";
import SelectBanco from "../../components/app/SelectBanco";
import SelectContaByBanco from "../../components/app/SelectContaByBanco";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";

class FormBaixa extends CrudForm {

    constructor(props) {
        super(props);

        this.getById = this.getById.bind(this);
        this.estornar = this.estornar.bind(this);
        this.handleEstornarBaixa = this.handleEstornarBaixa.bind(this);
        this.handleChangeDtPagamento = this.handleChangeDtPagamento.bind(this);
        this.handleClickAbonarEncargos = this.handleClickAbonarEncargos.bind(this);

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtPagamento = new Date();
        //dtPagamento = new Date(dtPagamento - tzoffset).toISOString().slice(0, 10);

        this.state.isCard = true;

        this.state.dtMaxPagamento = new Date(dtPagamento - tzoffset).toISOString().slice(0, 10);



        this.state.model = {
            flPagamentoParcial: false,
            flManual: 1,
            idCobranca: "",
            idBanco: "",
            idConta: "",
            vlCobranca: 0,
            vlPago: 0,
            vlAberto: 0,
            vlCobrado: 0,
            vlBaixa: "",
            vlDesconto: 0,
            vlTaxa: 0,
            vlJuros: 0,
            vlMulta: 0,
            vlEncargos: 0,
            idTipoBaixa: "",
            dtPagamento: new Date(dtPagamento - tzoffset).toISOString().slice(0, 10),
            dsObservacao: "",
            cobranca: {
                nomeProprietario: "",
                dtVencimento: "",
                baixa: []
            }
        };

        this.state.crud = {
            service: BaixaService,
            labels: {
                title: 'Baixa',
            },
            urlList: '/LstBaixa',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    getById() {
        if (this.props.idCobranca) {
            CobrancaService.getOneById(this.props.idCobranca, (data) => {
                var model = {};
                model.idCobranca = data.idCobranca;
                model.cobranca = data;
                model.vlCobranca = data.vlCobranca;
                model.vlPago = data.vlPago;
                model.vlAberto = data.vlAberto;
                model.vlCobrado = data.vlAberto;
                this.setModel(model, this.handleChangeDtPagamento);
            });
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeDtPagamento() {
        if (!isNaN(Date.parse(this.state.model.dtPagamento))) {
            var payload = { idCobranca: this.state.model.idCobranca, dtPagamento: this.state.model.dtPagamento };
            CobrancaService.getEncargosByDtPagamento(this.state.model.idCobranca, payload, (response) => {
                this.setState(state => {
                    state.model.vlJuros = parseFloat(response.vlJuros);
                    state.model.vlMulta = parseFloat(response.vlMulta);
                    state.model.vlEncargos = parseFloat(response.vlJuros) + parseFloat(response.vlMulta);
                    state.model.vlCobrado = parseFloat(state.model.vlAberto) + state.model.vlJuros + state.model.vlMulta;
                    return state;
                });
            });
        } else {
            this.setState(state => {
                state.model.vlJuros = 0;
                state.model.vlMulta = 0;
                state.model.vlCobrado = state.model.vlAberto;
                return state;
            });
        }
    }

    handleEstornarBaixa(item) {
        EventBus.dispatch("modal-baixa-open", {
            id: item.idBaixa,
            config: {
                title: "Estornar Baixa?",
                message: <>Deseja realmente estornar a baixa: <strong>{item.idBaixa} </strong>?</>,
                btnConfirmText: "Sim, estornar a baixa",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: this.estornar
            }
        })
    }

    handleClickAbonarEncargos() {
        this.setState(state => {
            state.model.vlDesconto = state.model.vlEncargos;
            return state;
        });
    }

    estornar(item) {
        BaixaService.estornar(item.id, (response) => {
            this.getById();
        });
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-dados-tab" data-bs-toggle="tab" data-bs-target="#nav-dados" type="button" role="tab" aria-controls="nav-dados" aria-selected="true">
                        Dados da Cobrança
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-baixas-tab" data-bs-toggle="tab" data-bs-target="#nav-baixas" type="button" role="tab" aria-controls="nav-baixas" aria-selected="false">
                        Baixa(s)
                    </button>
                </li>
            </ul>);
    }

    getRenderFields() {
        return (
            <>
                <div className="tab-content mx-2 my-0" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">

                        <div className="row mx-1 mb-3">
                            <div className="col-md-6 ps-0">
                                <InputCheckSwitch label="É um pagamento parcial?" name="flPagamentoParcial" checked={Boolean(this.state.model.flPagamentoParcial)} onChange={this.handleChange} />
                            </div>
                            <div className="col-md-6 text-end">
                                {this.state.model.vlEncargos > 0 && !Boolean(this.state.model.flPagamentoParcial) ?
                                    <BtnButton className="btn-sm btn-outline-primary text-nowrap" onClick={(e) => { this.handleClickAbonarEncargos() }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calculator-fill" viewBox="0 0 16 16">
                                            <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 .5v2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5zm0 4v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM4 12.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM7 9.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM10 6.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-1z" />
                                        </svg> Abonar Encargos
                                    </BtnButton>
                                    : <></>
                                }
                            </div>
                        </div>
                        <div className="row mb-3 g-3">
                            <InputText className="text-end text-primary fw-bold" label="Valor da Cobrança" md={2} value={Util.floatToReais(this.state.model.vlCobranca)} disabled={true} opcionalLabel={false} />
                            <InputText className="text-end text-success fw-bold" label="Valor Pago" md={2} value={Util.floatToReais(this.state.model.vlPago)} disabled={true} opcionalLabel={false} />
                            <InputText className="text-end text-danger fw-bold" label="Valor Aberto" md={2} value={Util.floatToReais(this.state.model.vlAberto)} disabled={true} opcionalLabel={false} />
                            <InputText className="text-end text-danger fw-bold" label="Valor Cobrado" md={2} value={Util.floatToReais(this.state.model.vlCobrado)} disabled={true} opcionalLabel={false} />
                            <InputText className="text-end text-danger fw-bold" label="Encargos" md={2} value={Util.floatToReais(this.state.model.vlEncargos)} disabled={true} opcionalLabel={false} />
                            <InputText label="Data de Vencimento" md={2} value={Util.date2Br(this.state.model.cobranca.dtVencimento)} disabled={true} required={true} />
                        </div>

                        <div className="row mb-3 g-3">
                            <InputDate md={2} required={true} label="Data Pagamento" value={this.state.model.dtPagamento} name="dtPagamento" onChange={e => this.handleChange(e, this.handleChangeDtPagamento)} max={this.state.dtMaxPagamento} />
                            <InputValorReais className="text-end" md={2} label="Valor Pago" required={true} value={this.state.model.vlBaixa} name="vlBaixa" onChange={this.handleChange} autoFocus={true} />
                            <InputValorReais className="text-end" md={2} label="Valor Desconto" required={true} value={this.state.model.vlDesconto} name="vlDesconto" onChange={this.handleChange} />
                            <InputValorReais className="text-end" md={2} label="Multa" value={this.state.model.vlMulta} disabled={true} opcionalLabel={false} />
                            <InputValorReais className="text-end" md={2} label="Juros" value={this.state.model.vlJuros} disabled={true} opcionalLabel={false} />
                            <InputValorReais className="text-end" md={2} label="Taxas" required={true} value={this.state.model.vlTaxa} name="vlTaxa" onChange={this.handleChange} />

                            <SelectTipoBaixa md={4} showBtns={false} label="Tipo de Baixa" required placeholder="Selecione" name="idTipoBaixa" value={this.state.model.idTipoBaixa} onChange={this.handleChange} />
                            <SelectBanco showBtns={false} label="Nome do Banco" required={true} placeholder="Selecione o Banco" name="idBanco" md={4} value={this.state.model.idBanco} onChange={this.handleChange} />
                            <SelectContaByBanco idBanco={this.state.model.idBanco} showBtns={false} label="Nome da Conta" required={true} placeholder="Selecione a Conta" name="idConta" md={4} value={this.state.model.idConta} onChange={this.handleChange} />

                            <TextArea label="Observação" md={12} value={this.state.model.dsObservacao} name="dsObservacao" onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className="tab-pane fade show" id="nav-baixas" role="tabpanel" aria-labelledby="nav-baixas-tab">
                        <ol className="list-group list-group-numbered">
                            {this.state.model.cobranca.baixa.map((item, index) => (
                                <li className={`list-group-item d-flex gap-3 justify-content-between align-items-start ${index % 2 === 0 ? "bg-light " : ""}`} key={index}>
                                    <div className="ms-2 w-100">
                                        <div className="row d-flex g-2">
                                            <GridItem sizing="sm" label="Código" md={2} value={item.idBaixa} />
                                            <GridItem sizing="sm" label="Cobrança" md={2} value={item.idCobranca} />
                                            <GridItem sizing="sm" label="Tipo da Baixa" md={4} value={item.nomeTipoBaixa} />
                                            <GridItem sizing="sm" label="Data da Baixa" md={2} value={Util.date2Br(item.dataBaixa)} />
                                            <GridItem sizing="sm" label="Data do Pagamento" md={2} value={Util.date2Br(item.dtPagamento)} />

                                            <GridItem sizing="sm" label="Usuário" md={4} value={item.nomeUsuario} />
                                            <GridItem className="text-primary fw-bold" sizing="sm" label="Canal de Pagamento" md={4} value={item.nomeConta} />
                                            <GridItem className="text-success fw-bold" sizing="sm" label="Valor da Baixa" md={2} value={Util.floatToReais(item.vlBaixa)} />
                                            <GridItem className="text-danger  fw-bold" sizing="sm" label="Valor do Desconto" md={2} value={Util.floatToReais(item.vlDesconto)} />
                                            {item.dsObservacao ?
                                                <GridItem label="Observação" sizing="sm" md={12} value={item.dsObservacao} />
                                                : <></>}
                                        </div>
                                        <div className="text-end pt-2">
                                            {Permissao.getInstance().getPermissao(34) ?
                                                <BtnButton className="btn-sm btn-outline-danger text-nowrap" onClick={(e) => { this.handleEstornarBaixa(item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eraser-fill" viewBox="0 0 16 16">
                                                        <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm.66 11.34L3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z" />
                                                    </svg> Estornar
                                                </BtnButton>
                                                : <></>
                                            }
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>

                <ModalConfirmacao id="modal-baixa" />
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormBaixa {...props} navigate={navigate} params={params} />
}

export default With