import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import FaturaService from "../../services/FaturaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import BtnButton from "../../components/forms/elements/BtnButton";
import EventBus from "../../controllers/EventBus";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import MostraValor from "../../components/app/MostraValor";
import AcordoService from "../../services/AcordoService";
import SelectRepresentanteByProprietario from "../../components/app/SelectRepresentanteByProprietario";

class FormFaturaAcordo extends CrudForm {

    constructor(props) {
        super(props);

        this.gerarAcordo = this.gerarAcordo.bind(this);
        this.getValorByCobranca = this.getValorByCobranca.bind(this);

        this.state.idFaturas = this.props?.idFaturas;

        this.state.model = {
            idProprietario : this.props?.idProprietario,            
            idOrdemTipo : this.props?.idOrdemTipo,
            idRepresentante : this.props?.idRepresentante || "",
            flMulta : this.props?.flMulta || 'S',
            flJuros : this.props?.flJuros || 'S',
            flVencer : this.props?.flVencer || 'N',
            faturas : []
        };

        this.state.crud = {
            service: FaturaService,
            labels: {
                title: 'Fatura',
            },
            urlList: '/LstFatura',
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.getFaturas();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getFaturas(){
        FaturaService.getOneByIds(this.state.idFaturas, (result) => {
            this.setState(state =>{
                state.model.faturas = result.faturas;
                return state;
            });
        });
    }

    gerarAcordo(){
        AcordoService.create(this.state.model, (response) => {            
            this.onSave(response);
        });
    }

    onSubmit(event) {
        EventBus.dispatch("modal-confirmacao-open", {            
            config: {
                title: "Gerar acordo?",
                message: <>Deseja realmente gerar o acordo da cobranças?</>,
                btnConfirmText: "Sim, gerar acordo",
                description: "Esta é uma ação é irreversível!",
                style: "warning",
                confirm: this.gerarAcordo
            }
        })
    }

    getStatus(item) {
        if (item.flPago) {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-success"> Pago </span>
        }

        if (item.flVencido) {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-danger"> Vencido </span>
        }

        return <span className="rounded-pill badge w-100 fs-7 text-bg-warning"> Aberto </span>
    }


    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnSubmit className="btn-primary"> Gerar Acordo </BtnSubmit>
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
            </div>
        );

    }


    getValorByCobranca(prop) {
        if(this.state.model.flVencer === "S"){
            return this.state.model.faturas.flatMap(fatura => fatura.cobranca).reduce((soma, cobranca) => soma + parseFloat(cobranca[prop]), 0);
        }else{
            return this.state.model.faturas.flatMap(fatura => fatura.cobranca).filter(cobranca => cobranca.flVencido).reduce((soma, cobranca) => soma + parseFloat(cobranca[prop]), 0);
        }
    }

    getValorAcordo() {
        var vlAcordo = 0;

        var cobrancas = this.state.model.faturas.flatMap(fatura => fatura.cobranca);

        cobrancas.forEach(cobranca => {
            if(!cobranca.flPago){
                if(cobranca.flVencido || this.state.model.flVencer === "S"){
                    vlAcordo+=parseFloat(cobranca.vlAberto);
                }

                if(this.state.model.flJuros === "S"){
                    vlAcordo+=parseFloat(cobranca.vlJuros);
                }

                if(this.state.model.flMulta === "S"){
                    vlAcordo+=parseFloat(cobranca.vlMulta);
                }
            }
        });

        

        return vlAcordo;
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">

                <div className="fw-bold mb-3 border-dark border-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                    </svg> Faturas Selecionadas
                </div>

                {this.state.model.faturas?.map((fatura, indexFatura) => (
                    <React.Fragment key={indexFatura}>
                        <div className="table-responsive-lg">
                            <table className="table table-sm table-striped table-bordered caption-top">
                                <thead className="table-light">
                                    <tr>
                                        <th colSpan={12}>
                                           Fatura: {fatura.idFatura}
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="text-center">Cobrança</th>
                                        <th scope="col" className="text-center">Parcela</th>
                                        <th scope="col" className="text-center">Status</th>
                                        <th scope="col" className="text-center">Vencimento</th>
                                        <th scope="col" className="text-center">Pagamento</th>
                                        <th scope="col" className="text-end">Cobrança</th>
                                        <th scope="col" className="text-end">Pago</th>
                                        <th scope="col" className="text-end">Aberto</th>
                                        <th scope="col" className="text-end">Multa</th>
                                        <th scope="col" className="text-end">Juros</th>                                
                                        <th scope="col" className="text-end">Desconto</th>
                                        <th scope="col" className="text-end">Devido</th>                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {fatura?.cobranca?.map((cobranca, indexCobranca) => (
                                        <React.Fragment key={indexCobranca}>
                                            <tr className="tr-borderless text-start">
                                                <td className="align-middle text-center">
                                                    {cobranca.idCobranca}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {cobranca.nrParcela}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {this.getStatus(cobranca)}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {Util.date2Br(cobranca.dtVencimento)}
                                                </td>
                                                <td className="align-middle text-center">
                                                    {Util.date2Br(cobranca.dtPagamento)}
                                                </td>
                                                <td className="align-middle text-end text-end text-primary fw-bold">
                                                    <MostraValor>{Util.floatToReais(cobranca.vlCobranca)}</MostraValor>
                                                </td>
                                                <td className="align-middle text-end text-success fw-bold">
                                                    <MostraValor>{Util.floatToReais(cobranca.vlBaixa)}</MostraValor>
                                                </td>
                                                <td className={`align-middle text-end fw-bold ${(cobranca.vlAberto) > 0 ? "text-danger" : ""}`}>
                                                    <MostraValor>{Util.floatToReais(cobranca.vlAberto)}</MostraValor>
                                                </td>
                                                <td className="align-middle text-end text-danger fw-bold">
                                                    <MostraValor>{cobranca.flVencido && !cobranca.flPago ? Util.floatToReais(cobranca.vlMulta) : ""}</MostraValor>
                                                </td>
                                                <td className="align-middle text-end text-danger fw-bold">
                                                <MostraValor>{cobranca.flVencido && !cobranca.flPago ? Util.floatToReais(cobranca.vlJuros) : ""}</MostraValor>
                                                </td>
                                                <td className="align-middle text-end text-danger fw-bold">
                                                    <MostraValor>{Util.floatToReais(cobranca.vlDesconto)}</MostraValor>
                                                </td>
                                                <td className="align-middle text-end text-danger fw-bold">
                                                    <MostraValor>{Util.floatToReais(cobranca.vlDevido)}</MostraValor>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                    <tr className="tr-borderless text-start table-warning">
                                        <td colSpan={5}></td>
                                        <td className="align-middle text-end text-end text-primary fw-bold">
                                            <MostraValor>{Util.floatToReais(fatura.vlFatura)}</MostraValor>
                                        </td>
                                        <td className="align-middle text-end text-success fw-bold">
                                            <MostraValor>{Util.floatToReais(fatura.vlBaixa)}</MostraValor>
                                        </td>
                                        <td className="align-middle text-end text-danger fw-bold">
                                            <MostraValor>{Util.floatToReais(fatura.cobranca?.reduce((soma, cobranca) => soma + parseFloat(cobranca.flVencido && !cobranca.flPago ? cobranca.vlMulta : 0), 0))}</MostraValor>
                                        </td>
                                        <td className="align-middle text-end text-danger fw-bold">
                                            <MostraValor>{Util.floatToReais(fatura.cobranca?.reduce((soma, cobranca) => soma + parseFloat(cobranca.flVencido && !cobranca.flPago ? cobranca.vlJuros : 0), 0))}</MostraValor>
                                        </td>
                                        <td className="align-middle text-end text-danger fw-bold">
                                            <MostraValor>{Util.floatToReais(fatura.vlDesconto)}</MostraValor>
                                        </td>
                                        <td className={`align-middle text-end fw-bold ${(fatura.vlAberto) > 0 ? "text-danger" : ""}`}>
                                            <MostraValor>{Util.floatToReais(fatura.vlAberto)}</MostraValor>
                                        </td>
                                        <td className="align-middle text-end text-danger fw-bold">
                                            <MostraValor>{Util.floatToReais(fatura.cobranca?.reduce((soma, cobranca) => soma + parseFloat(cobranca.flVencido && !cobranca.flPago ? cobranca.vlDevido : 0), 0))}</MostraValor>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </React.Fragment>
                ))}

                <div className="fw-bold mb-3 border-dark border-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                    </svg> Dados do Acordo
                </div>

                <div className="row mb-1 g-3">
                    <InputText className="text-end text-primary fw-bold" label="Valor Cobranças" md={2} value={Util.floatToReais(this.getValorByCobranca("vlCobranca"))} readOnly opcionalLabel={false} />
                    <InputText className="text-end text-success fw-bold" label="Valor Pago" md={2} value={Util.floatToReais(this.getValorByCobranca("vlBaixa"))} readOnly opcionalLabel={false} />
                    <InputText className="text-end text-danger fw-bold" label="Valor Aberto" md={2} value={Util.floatToReais(this.getValorByCobranca("vlAberto"))} readOnly opcionalLabel={false} />
                    
                    <InputText className="text-end text-danger fw-bold" label="Valor Multa" md={2} value={Util.floatToReais(this.getValorByCobranca("vlMulta"))} readOnly opcionalLabel={false} />                
                    <InputText className="text-end text-danger fw-bold" label="Valor Juros" md={2} value={Util.floatToReais(this.getValorByCobranca("vlJuros"))} readOnly opcionalLabel={false} />                
                    <InputText className="text-end text-danger fw-bold" label="Valor Devido" md={2} value={Util.floatToReais(this.getValorByCobranca("vlDevido"))} readOnly opcionalLabel={false} />                
                </div>

                <div className="row mb-1 g-3">
                    <SelectRepresentanteByProprietario idProprietario={this.state.model.idProprietario} showBtnNewForm={false} required={true} md={12} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={this.handleChange} />
                </div>

                <div className="row mb-1 g-3">
                    <BtnGroup md={3} sizing="sm" className="w-100" name="flMulta" value={this.state.model.flMulta} label="Incluir Multa" options={[{ value: 'S', label: 'Sim' }, { value: 'N', label: 'Não' }]} onChange={this.handleChange} opcionalLabel={false} />
                    <BtnGroup md={3} sizing="sm" className="w-100" name="flJuros" value={this.state.model.flJuros} label="Incluir Juros" options={[{ value: 'S', label: 'Sim' }, { value: 'N', label: 'Não' }]} onChange={this.handleChange} opcionalLabel={false} />
                    <BtnGroup md={3} sizing="sm" className="w-100" name="flVencer" value={this.state.model.flVencer} label="Incluir Parcelas a Vencer" options={[{ value: 'S', label: 'Sim' }, { value: 'N', label: 'Não' }]} onChange={this.handleChange} opcionalLabel={false} />

                    <InputText className="text-end text-danger fw-bold bg-dark" label="Valor Acordo" md={3} value={Util.floatToReais(this.getValorAcordo())} readOnly opcionalLabel={false} />
                </div>           

            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <FormFaturaAcordo {...props} navigate={navigate} params={params} location={location} />
}

export default With