import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import Config from "../../Config";

export default class FormFoto extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.getData = this.getData.bind(this);
        this.state = this.getInitState(props);
    }

    getInitState(props) {
        return {
            inverval: null,
            data: {
                produtoServicoArquivo: []
            },
            model: {
                idProdutoServico: this.props.idProdutoServico
            }
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getData();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.inverval);
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getData();
        });
    }

    getData() {
        ProdutoServicoService.getOneByIdByUsuario(this.state.model.idProdutoServico, (response) => {
            this.setState(state => {
                state.data = response;
                return state;
            });
        });
    }

    render() {
        return (
            <div id="carouselFotosControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {this.state.data.produtoServicoArquivo.map((item, index) => (
                        <React.Fragment key={index}>
                            {index === 0 ?
                                <button type="button" data-bs-target="#carouselFotosControls" data-bs-slide-to={index} className="active" aria-current="true" aria-label="Slide 1"></button>
                                :
                                <button type="button" data-bs-target="#carouselFotosControls" data-bs-slide-to={index} aria-label={`Slide ${index}`}></button>
                            }
                        </React.Fragment>
                    ))}

                </div>
                <div className="carousel-inner">
                    {this.state.data.produtoServicoArquivo.map((item, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                            <img src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uid} className="d-block w-100" alt="..." />
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselFotosControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselFotosControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        )
    }
}