import React from "react";
import PlanoRecorrenciaService from "../../services/PlanoRecorrenciaService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaPlanoRecorrencia extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: PlanoRecorrenciaService,
            labels: {
                title: 'Lista de Plano(s) de Recorrência(s)',
                btnNewRegister: 'Plano de Recorrência',
            },
            keyItem: 'idPlanoRecorrencia',
            urlForm: '/CadPlanoRecorrencia',
            itensFilter: ["idPlanoRecorrencia", "nome"],
            searchFormSubmit: false
        };

        this.state.tipoRecorrencia = [
            { value: "D", label: "Diária" },
            { value: "S", label: "Semanal" },
            { value: "M", label: "Mensal" },
            { value: "P", label: "Parte" },
        ];

    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-4"> Nome </th>
                <th className="col-md-3 text-center"> Tipo </th>
                <th className="col-md-3 text-center"> Recorrência </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idPlanoRecorrencia}</td>
                <td>{item.nome}</td>
                <td className="text-center">{this.state.tipoRecorrencia.find(i => i.value === item.cdTipo).label}</td>
                <td className="text-center">{item.dsRecorrencia}</td>
            </>);
    }

    render() {
        return super.render();
    }
}