import React from "react";
import CrudForm from "../../components/forms/custom/CrudForm";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import SelectCategoriaProdutoServicoByUsuario from "../../components/app/SelectCategoriaProdutoServicoByUsuario";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import Util from "../../utils/Util";
import Config from "../../Config";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import SelectOrdemTipoUsuario from "../../components/app/SelectOrdemTipoUsuario";
import InputDate from "../../components/forms/elements/InputDate";
import SelectProprietarioByUsuario from "../../components/app/SelectProprietarioByUsuario";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import SelectRepresentanteByProprietarioAndUsuario from "../../components/app/SelectRepresentanteByProprietarioAndUsuario";
import DropDownMenuOrdemStatus from "../../components/app/DropDownMenuOrdemStatus";

export default class FormRelatorioOrdem002 extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeOrdemStatus = this.handleChangeOrdemStatus.bind(this);

        this.state.isModal = true;

        this.urlRelatorio = "relatorio/ordem-002";

        this.state.model = {
            idCategoriaProdutoServico: "",
            idTipoProdutoServico: "",
            idProprietario: "",
            idRepresentante: "",
            dtOrdemStart: "",
            dtOrdemEnd: "",
            orientation: "L",
            destination: "I",
            output: "PDF",
            flMarcaDagua: true,
            idOrdemTipo: "",
            flFiltroCdTipo: "T",
            flRemap: false,
            idOrdensStatus: [1, 2, 4, 5]
        };

        this.state.orientation = [
            { value: 'P', label: 'Vertical' },
            { value: 'L', label: 'Horizontal' },
        ]

        this.state.destination = [
            { value: 'I', label: 'Tela' },
            { value: 'D', label: 'Download' },
        ]

        this.state.output = [
            { value: 'PDF', label: 'PDF' },
            { value: 'XLS', label: 'XLS' },
        ]

        this.state.filtroTipo = [
            { value: "T", label: "Todos" },
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ]
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(e) {
        Util.openNewWindow(Config.getUrlApi() + this.urlRelatorio, this.state.model);
    }

    handleChangeOrdemStatus(isChecked, item) {
        if (isChecked) {
            this.setState(state => {
                state.model.idOrdensStatus.push(item.value);
                return state;
            });
        } else {
            this.setState(state => {
                var index = this.state.model.idOrdensStatus.findIndex(i => parseInt(i) === parseInt(item.value));
                state.model.idOrdensStatus.splice(index, 1);
                return state;
            });
        }
    }

    getRenderFields() {
        return (
            <>

                <div className="row mb-3 g-3">
                    <InputDate md={6} label="Data Inicial da Ordem" value={this.state.model.dtOrdemStart} name="dtOrdemStart" onChange={this.handleChange} required={true} />
                    <InputDate md={6} label="Data Final da Ordem" value={this.state.model.dtOrdemEnd} name="dtOrdemEnd" onChange={this.handleChange} min={this.state.model.dtOrdemStart} required={true} />
                </div>
                <div className="row mb-3 g-3">
                    <SelectOrdemTipoUsuario showBtnNewForm={false} md={12} label="Tipo da Ordem" placeholder="Selecione o Tipo da Ordem" name="idOrdemTipo" value={this.state.model.idOrdemTipo} onChange={this.handleChange} />
                    <SelectCategoriaProdutoServicoByUsuario showBtnNewForm={false} md={12} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={this.handleChange} />
                    <SelectTipoProdutoServicoByCategoria showBtnNewForm={false} md={12} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} disabled={!this.state.model.idCategoriaProdutoServico} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={this.handleChange} />
                    <SelectProprietarioByUsuario showBtnNewForm={false} md={12} label="Proprietário" placeholder="Selecione o Proprietario" name="idProprietario" value={this.state.model.idProprietario} onChange={this.handleChange} />
                    {this.state.model.idProprietario ?
                        <SelectRepresentanteByProprietarioAndUsuario idProprietario={this.state.model.idProprietario} showBtnNewForm={false} md={12} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={this.handleChange} />
                        : <SelectRepresentanteByUsuario showBtnNewForm={false} md={12} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={this.handleChange} />
                    }
                    <DropDownMenuOrdemStatus label="Status da Ordem" placeholder="Selecione o(s) statu(s)" className="btn-outline-primary text-nowrap" md={12} values={this.state.model.idOrdensStatus} name="idOrdensStatus" handleChangeItem={this.handleChangeOrdemStatus} />
                </div>
                <div className="row mb-3">
                    <BtnGroup md={6} sizing="sm" className="w-100" name="flFiltroCdTipo" value={this.state.model.flFiltroCdTipo} label="Tipo do Produto/Serviço" options={this.state.filtroTipo} onChange={this.handleChange} required />
                </div>
                <div className="row mb-3">
                    <BtnGroup md={4} sizing="sm" className="w-100" name="orientation" value={this.state.model.orientation} label="Disposição da Página" options={this.state.orientation} onChange={this.handleChange} required />
                    <BtnGroup md={4} sizing="sm" className="w-100" name="destination" value={this.state.model.destination} label="Destino do Relatório" options={this.state.destination} onChange={this.handleChange} required />
                    <BtnGroup md={4} sizing="sm" className="w-100" name="output" value={this.state.model.output} label="Formato de Saída" options={this.state.output} onChange={this.handleChange} required />
                </div>
                <div className="row px-3 mb-3">
                    <InputCheckSwitch label="Somente Remap?" name="flRemap" checked={Boolean(this.state.model.flRemap)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Mostrar marca d'água" name="flMarcaDagua" checked={Boolean(this.state.model.flMarcaDagua)} onChange={this.handleChange} />
                </div>
            </>
        );
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="ms-auto btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
                <div className="vr"></div>
                <BtnSubmit className="btn-primary"> Emitir Relatório </BtnSubmit>
            </div>);
    }

    render() {
        return super.render();
    }
}