import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import AcervoService from "../../services/AcervoService";

class FormAcervoView extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idAcervo : "",
            cdChave : false,
            nome : ""
        };
    }

    componentDidMount() {
        super.componentDidMount();

        AcervoService.getOneById(this.props.idAcervo, response => {
            this.setModel(response);
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getBtns() {
        return (<></>);
    }

    getRenderFields() {
        return (
            <div>
                {this.state.model.cdChave ? 
                    <iframe className="responsive-iframe" 
                        src={`https://drive.google.com/file/d/${this.state.model.cdChave}/preview`}
                        title={this.state.model.nome} frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        ></iframe>
                    : <></>
                }
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormAcervoView {...props} navigate={navigate} params={params} />
}

export default With