import Http from "../controllers/Http";

export default class CotacaoFreteService {

    static urlBase = "/cotacaoFrete/";

    static getCotacao(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getListServico(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'listServico', data)
            .then(fnSucess)
            .catch(fnError);
    }

}
