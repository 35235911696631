import { Component } from "react";
import ReactLoading from 'react-loading';
import EventBus from "../../controllers/EventBus";

export default class LoadingContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            count: 0,
            message: "Carregando"
        };
    }

    componentDidMount() {
        EventBus.on("loading-add", (element) => {
            this.addLoading(element);
        });

        EventBus.on("loading-del", (element) => {
            this.delLoading(element);
        });
    }

    addLoading(element) {
        this.setState({ count: this.state.count + 1 });
    }

    delLoading(element) {
        this.setState({ count: this.state.count - 1 });
    }

    isLoading() {
        return this.state.count > 0;
    }

    render() {
        return (
            this.isLoading() ?
                <div className="loading-container-show">
                    <div className="loading-body">
                        <div className='loading-message'>{this.state.message}</div>
                        <div className="loading-container">
                            <ReactLoading type={'bars'} color={'#000000'} height={'5%'} width={'5%'} />
                        </div>
                    </div>
                </div>
                : <></>
        );
    }
}
