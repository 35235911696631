import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import RepresentanteContratoService from "../../services/RepresentanteContratoService";
import BtnButton from "../../components/forms/elements/BtnButton";
import ArquivoService from "../../services/ArquivoService";
import InputFile from "../../components/forms/elements/InputFile";
import Config from "../../Config";
import RepresentanteContratoArquivoService from "../../services/RepresentanteContratoArquivoService";
import Util from "../../utils/Util";

class FormRepresentanteContratoArquivo extends CrudForm {

    constructor(props) {
        super(props);

        this.handleFileChange  = this.handleFileChange.bind(this);
        this.handleDeleteItemArquivo  = this.handleDeleteItemArquivo.bind(this);
        this.handleRefreshArquivo  = this.handleRefreshArquivo.bind(this);
        
        this.state.model = {
            idRepresentanteContrato : "",
            idContrato: "",
            representanteContratoArquivo : []
        };

        this.state.crud = {
            service: RepresentanteContratoService,
            labels: {
                title: '',
            },
            urlList: '/',
        };
    }

    componentDidMount() {
        super.componentDidMount();        
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleFileChange(event) {
        var files = event.target?.files;
        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                ArquivoService.upload(files[i], (response) => {
                    let data = {
                        idRepresentanteContrato : this.state.model.idRepresentanteContrato,
                        idArquivo : response.data.idArquivo
                    };
                    
                    RepresentanteContratoArquivoService.create(data, (respArquivo)=>{
                        this.setState((state) => {
                            state.model.representanteContratoArquivo.push(respArquivo.data);
                            return state;
                        });
                    })
                    
                });
            }
        }
    }

    handleDeleteItemArquivo(index, item) {
        RepresentanteContratoArquivoService.delete(item.idRepresentanteContratoArquivo, (response)=>{
            this.setState((state) => {
                this.handleRefreshArquivo();
            });
        })
    }

    handleRefreshArquivo() {
        RepresentanteContratoArquivoService.listAllForRepresentanteContrato(this.state.model.idRepresentanteContrato, (response)=>{
            this.setState((state) => {
                state.model.representanteContratoArquivo = response;
                return state;
            });
        });
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputFile label="Selecione o arquivo" name="arquivo" multiple onChange={(event) => { this.handleFileChange(event) }} />

                <div className="table-responsive">
                    <table className="table table-sm caption-top table-bordered">
                        <thead className="table-light">
                            <tr>
                                <th className="col"> Tipo </th>
                                <th className="col-4"> Nome </th>
                                <th className="col-6"> Arquivo </th>
                                <th className="col-2 text-end">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.model.representanteContratoArquivo.map((item, index) => (
                                <tr key={index} className="tr-borderless">
                                    <td className="text-center">
                                        {Util.getIconeTipo(Util.getTipoArquivo(item.tipoArquivo), 32, 32)}
                                    </td>
                                    <td>
                                        {item.nomeArquivo}
                                    </td>
                                    <td className="text-center">
                                        {Util.getTipoArquivo(item.tipoArquivo) === "IMAGEM" ?
                                            <img style={{ maxHeight: "150px" }} src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uid} className="img-fluid img-thumbnail" alt="..." />
                                            : <></>
                                        }
                                    </td>
                                    <td className="align-middle text-center text-nowrap">

                                        <a href={Config.getUrlArquivo() + "public/arquivo/download/" + item.uid} rel="noopener noreferrer" target="_blank" className="btn btn-sm btn-outline-primary"> 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                            </svg> Download 
                                        </a>

                                        <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteItemArquivo(index, item) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg> Excluir Arquivo
                                        </button>

                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
            </div>);
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormRepresentanteContratoArquivo {...props} navigate={navigate} params={params} />
}

export default With