import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import EventBus from "../../controllers/EventBus";

export default class ModalCopy extends MyComponent {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSelectRange = this.handleSelectRange.bind(this);
        this.refTextarea = React.createRef();
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            texto: "",
            show: false,
            close: props.close || (() => { }),
            EventBusOpen: props.id ? props.id + "-open" : "modal-copy-open"
        };
    }

    componentDidMount() {
        EventBus.on(this.state.EventBusOpen, (element) => {
            this.setState({ show: true, texto: element }, () => {
                this.handleSelectRange();
            });
        });
    }

    componentWillUnmount() {
        EventBus.remove(this.state.EventBusOpen);
    }

    handleSelectRange() {
        var el = this.refTextarea.current;
        el.contentEditable = true;
        el.readOnly = false;
        var range = document.createRange();
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, 999999);

        try {
            if (navigator !== undefined && navigator.clipboard !== undefined) {
                navigator.clipboard.writeText(this.state.texto);
            } else if (window.clipboardData !== undefined) {  // IE
                window.clipboardData.setData('text', this.state.texto);
            } else {
                document.execCommand('copy');
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    handleClose() {
        this.setState({ show: false });
        this.state.close(this.state.element);
    }

    render() {
        return (
            <>
                <div className={`fade modal-backdrop show ${this.state.show ? "" : "d-none"}`} onClick={alert}> </div>
                <div className={`modal ${this.state.show ? "d-block" : "d-none"}`} id="exampleModal" tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="false" aria-modal="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Texto para Compartilhar
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleClose}></button>
                            </div>
                            <div className="modal-body p-4">
                                <textarea ref={this.refTextarea} className="w-100 form-control" name="texto" value={this.state.texto} onChange={this.handleChange} rows={10} />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-outline-secondary" onClick={this.handleClose}> Fechar </button>
                                <button type="button" className="btn btn-primary" onClick={this.handleSelectRange}> Selecionar e Copiar </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
