import TipoProdutoServicoService from "../../services/TipoProdutoServicoService";
import FormSelectSearchModal from "../forms/base/FormSelectSearchModal";
import FormTipoProdutoServico from "../../pages/TipoProdutoServico/FormTipoProdutoServico";
import Permissao from "../../controllers/Permissao";

export default class SelectTipoProdutoServicoByCategoria extends FormSelectSearchModal {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);

        this.state.showBtns = this.props.showBtns === undefined ? true : this.props.showBtns;
        this.state.showBtnSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
        this.state.showBtnNewForm = this.state.showBtns && ((this.props.showBtnNewForm === undefined ? true : false) && Permissao.getInstance().getPermissao(14));
        this.state.showBtnNewSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);

        this.state.sizeModal = "modal-xl";
    }

    getConfigFormNew() {
        return {
            title: 'Cadastro de Tipo de Produto',
            sizeModal: 'modal-xl',
            config: {
                isModal: true
            },
            form: FormTipoProdutoServico,
            onSave: (response) => {
                this.getOptions(() => {
                    if (response.data?.idTipoProdutoServico) {
                        this.handleOpenSearch(null, response.data?.idTipoProdutoServico);
                    } else {
                        this.handleOpenSearch();
                    }
                });
            },
            onClose: () => {
                //this.handleOpenSearch();
            }
        };
    };

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.idCategoriaProdutoServico !== this.props.idCategoriaProdutoServico) {
            this.getOptions();
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptions(() => {
            if (this.dispachChangeDidMount && this.props.value) {
                this.handleChange({ target: { value: this.props.value, name: this.props.name } });
            }
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        if (this.props.idCategoriaProdutoServico) {
            TipoProdutoServicoService.getForComboByCategoriaProdutoServico(this.props.idCategoriaProdutoServico, (response) => {
                if (!this.state.isComponentMounted) return;
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();
                    }
                });
            });
        } else {
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    render() {
        return super.render();
    }
}