import React from "react";
import { Link } from "react-router-dom";
import ProdutoServicoClassificacaoService from "../../services/ProdutoServicoClassificacaoService";
import FormTreeView from "../../components/forms/base/FormTreeView";
import MyComponent from "../../components/custom/MyComponent";
import EventBus from "../../controllers/EventBus";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import Util from "../../utils/Util";

export default class ListaProdutoServicoClassificacao extends MyComponent {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.excluir = this.excluir.bind(this);
        this.handleClickExcluir = this.handleClickExcluir.bind(this);
        this.getLista = this.getLista.bind(this);

        this.state.model = {
            idProdutoServicoClassificacao: "",
        };

        this.state.tree = [];

        this.state.crud = {
            service: ProdutoServicoClassificacaoService,
            labels: {
                title: 'ProdutoServicoClassificacao',
            },
            urlList: '/LstProdutoServicoClassificacao',
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();

    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getLista() {
        this.setState({ tree: [] });
        ProdutoServicoClassificacaoService.listForTree((response) => {
            this.setState({ tree: Util.changePropertyRecursive(response, "opened", true, "options") });
        });
    }

    

    handleClickExcluir(id) {
        EventBus.dispatch("modal-delete-open", {
            id: id,
            config: {
                title: "Excluir item?",
                message: <>Deseja realmente excluir o item?</>,
                btnConfirmText: "Sim, excluir o item",
                description: "Esta é uma ação é irreversível!",
                style: "danger"
            }
        })
    }

    excluir(item) {
        ProdutoServicoClassificacaoService.delete(item.id, (response) => {
            this.getLista();
        });
    }

    render() {
        return (
            <div className="container p-3 my-2">
                <div className="card">
                    <div className="card-header">
                        Classificações
                    </div>
                    <div className="card-body">
                        <FormTreeView openAll={true} selectFolder={true} selectElement={true} options={this.state.tree} value={this.state.model.idProdutoServicoClassificacao} name="idProdutoServicoClassificacao" onChange={this.handleChange} />
                    </div>
                    <div className="card-footer hstack gap-2">
                        <Link to="/CadProdutoServicoClassificacao" className="btn btn-outline-primary text-nowrap">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                            </svg> Novo Item
                        </Link>

                        {this.state.model.idProdutoServicoClassificacao ?
                            <Link to={`/CadProdutoServicoClassificacao/${this.state.model.idProdutoServicoClassificacao}`} className="btn btn-outline-primary text-nowrap">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                </svg> Editar Item
                            </Link>
                            : <></>
                        }

                        {this.state.model.idProdutoServicoClassificacao ?
                            <button type="button" disabled={!this.state.model.idProdutoServicoClassificacao} className="btn btn-outline-danger" onClick={() => { this.handleClickExcluir(this.state.model.idProdutoServicoClassificacao) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                </svg> Excluir Item
                            </button>
                            : <></>
                        }
                    </div>
                </div>
                <ModalConfirmacao id="modal-delete" confirm={this.excluir} />
            </div>
        );
    }
}