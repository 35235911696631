import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import TextArea from "../../components/forms/elements/TextArea";
import ConfiguracaoService from "../../services/ConfiguracaoService";
import CrudForm from "../../components/forms/custom/CrudForm";

class FormConfiguracao extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idConfiguracao: "",
            chave: "",
            valor: ""
        };

        this.state.crud = {
            service: ConfiguracaoService,
            labels: {
                title: 'Configuração',
            },
            urlList: '/LstConfiguracao',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={3} value={this.state.model.idConfiguracao} name="idConfiguracao" onChange={this.handleChange} readOnly opcionalLabel={false}/>
                <InputText label="Chave da Configuração" autoFocus={true} required md={12} value={this.state.model.chave} name="chave" onChange={this.handleChange} maxLength={100} />
                <TextArea label="Valor da Configuração" required md={12} value={this.state.model.valor} name="valor" onChange={this.handleChange} className="textarea-configuracao" />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormConfiguracao {...props} navigate={navigate} params={params} />
}

export default With
