import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ProprietarioService from "../../services/ProprietarioService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputEmail from "../../components/forms/elements/InputEmail";
import InputCpf from "../../components/forms/custom/InputCpf";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import SelectDDI from "../../components/app/SelectDDI";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import EventBus from "../../controllers/EventBus";
import FormEndereco from "../Endereco/FormEndereco";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import InputDate from "../../components/forms/elements/InputDate";
import EnderecoService from "../../services/EnderecoService";
import SelectPlanoPagamentoByUsuario from "../../components/app/SelectPlanoPagamentoByUsuario";

class FormProprietario extends CrudForm {

    constructor(props) {
        super(props);

        this.handleAddEndereco = this.handleAddEndereco.bind(this);
        this.handleDeleteEndereco = this.handleDeleteEndereco.bind(this);
        this.handleEditEndereco = this.handleEditEndereco.bind(this);
        this.handleCopyEndereco = this.handleCopyEndereco.bind(this);

        this.handleAddTelefone = this.handleAddTelefone.bind(this);
        this.handleChangePrincipalTelefone = this.handleChangePrincipalTelefone.bind(this);
        this.handleDeleteTelefone = this.handleDeleteTelefone.bind(this);

        this.handleAddEmail = this.handleAddEmail.bind(this);
        this.handleDeleteEmail = this.handleDeleteEmail.bind(this);
        this.handleChangePrincipalEmail = this.handleChangePrincipalEmail.bind(this);

        this.state.isCard = true;
        //this.state.container = false;

        this.state.model = {
            idProprietario: "",
            nome: "",
            cpf: "",
            cnpj: "",
            razaoSocial: "",
            nomeFantasia: "",
            inscricaoEstadual: "",
            flLiberado: false,
            flPagamentoAntecipadoInadimplente: true,
            cdTipoPessoa: "F",
            dtLiberado: "",
            proprietarioEndereco: [],
            proprietarioTelefone: [],
            proprietarioEmail: [],
            proprietarioPlanoPagamento: [
                { cdTipoProduto: "S", idPlanoRecorrencia: "", idPlanoPagamento: "" },
                //{ cdTipoProduto: "P", idPlanoRecorrencia: "", idPlanoPagamento: "" }
            ],
            proprietarioRepresentante: [],
        };

        this.state.crud = {
            service: ProprietarioService,
            labels: {
                title: 'Proprietario',
            },
            urlList: '/LstProprietario',
        };

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];

        this.state.tipoProduto = [
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ];
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleAddEndereco() {
        EventBus.dispatch("modal-add", {
            title: 'Cadastro de Endereço',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                disabledValuesTipoEndereco: [1, 4].concat(this.state.model.proprietarioEndereco.map(o => o.idTipoEndereco))
            },
            form: FormEndereco,
            onSave: (response) => {
                this.setState(state => {
                    state.model.proprietarioEndereco.push({
                        idEndereco: response.data.idEndereco,
                        idTipoEndereco: response.data.idTipoEndereco,
                        nomeTipoEndereco: response.data.nomeTipoEndereco,
                        idPais: response.data.idPais,
                        nomePais: response.data.nomePais,
                        idEstado: response.data.idEstado,
                        nomeEstado: response.data.nomeEstado,
                        idCidade: response.data.idCidade,
                        nomeCidade: response.data.nomeCidade,
                        cep: response.data.cep,
                        endereco: response.data.endereco,
                        bairro: response.data.bairro,
                        numero: response.data.numero,
                        complemento: response.data.complemento
                    });
                    return state;
                })
            },
        });
    }

    handleCopyEndereco(item) {
        EventBus.dispatch("modal-add", {
            title: 'Copiar de Endereço',
            sizeModal: 'modal-xl',
            scrollable: false,            
            config: {
                isModal: true,
                disabledValuesTipoEndereco: [1, 4].concat(this.state.model.proprietarioEndereco.map(o => o.idTipoEndereco)),
                isCopy : true,
                id: item.idEndereco
            },
            form: FormEndereco,
                onSave: (response) => {
                    this.setState(state => {
                        state.model.proprietarioEndereco.push({
                            idEndereco: response.data.idEndereco,
                            idTipoEndereco: response.data.idTipoEndereco,
                            nomeTipoEndereco: response.data.nomeTipoEndereco,
                            idPais: response.data.idPais,
                            nomePais: response.data.nomePais,
                            idEstado: response.data.idEstado,
                            nomeEstado: response.data.nomeEstado,
                            idCidade: response.data.idCidade,
                            nomeCidade: response.data.nomeCidade,
                            cep: response.data.cep,
                            endereco: response.data.endereco,
                            bairro: response.data.bairro,
                            numero: response.data.numero,
                            complemento: response.data.complemento
                        });
                        return state;
                    })
                },
            });
    }

    handleEditEndereco(index, item) {
        EventBus.dispatch("modal-add", {
            title: 'Editar de Endereço',
            sizeModal: 'modal-xl',
            scrollable: false,            
            config: {
                isModal: true,
                id: item.idEndereco
            },
            form: FormEndereco,
            onSave: (response) => {
                EnderecoService.getOneById(item.idEndereco, (response) =>{
                    this.setState(state => {
                        state.model.proprietarioEndereco.splice(index, 1);
                        state.model.proprietarioEndereco.push({
                            idEndereco: response.idEndereco,
                            idTipoEndereco: response.idTipoEndereco,
                            nomeTipoEndereco: response.nomeTipoEndereco,
                            idPais: response.idPais,
                            nomePais: response.nomePais,
                            idEstado: response.idEstado,
                            nomeEstado: response.nomeEstado,
                            idCidade: response.idCidade,
                            nomeCidade: response.nomeCidade,
                            cep: response.cep,
                            endereco: response.endereco,
                            bairro: response.bairro,
                            numero: response.numero,
                            complemento: response.complemento
                        });
                        return state;
                    })
                })
                
            },
        });
    }

    handleDeleteEndereco(index, item) {
        this.setState((state) => {
            state.model.proprietarioEndereco.splice(index, 1);
            return state;
        });
    }

    handleChangePrincipalTelefone(index, item) {
        this.setState(state => {
            for (var i in state.model.proprietarioTelefone) {
                state.model.proprietarioTelefone[i].flPrincipal = false
            }
            state.model.proprietarioTelefone[index].flPrincipal = true;
            return state;
        });
    }

    handleAddTelefone() {
        this.setState(state => {
            state.model.proprietarioTelefone.push({
                idProprietarioTelefone: "",
                ddi: 55,
                telefone: "",
                flWhatsapp: false,
                flPrincipal: state.model.proprietarioTelefone.findIndex(i => i.flPrincipal) === -1
            });
            return state;
        });
    }

    handleDeleteTelefone(index, item) {
        this.setState((state) => {
            state.model.proprietarioTelefone.splice(index, 1);
            return state;
        });
    }

    handleChangePrincipalEmail(index, item) {
        this.setState(state => {
            for (var i in state.model.proprietarioEmail) {
                state.model.proprietarioEmail[i].flPrincipal = false
            }
            state.model.proprietarioEmail[index].flPrincipal = true;
            return state;
        });
    }

    handleAddEmail() {
        this.setState(state => {
            state.model.proprietarioEmail.push({
                idProprietarioEmail: "",
                email: "",
                flPrincipal: state.model.proprietarioEmail.findIndex(i => i.flPrincipal) === -1
            });
            return state;
        });
    }

    handleDeleteEmail(index, item) {
        this.setState((state) => {
            state.model.proprietarioEmail.splice(index, 1);
            return state;
        });
    }

    getCardHeader() {

        var locationHash = this.props.location.hash;

        if(locationHash === ""){
            locationHash = "#nav-cadastrais";
        }

        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <Link to={'#nav-cadastrais'} className={`nav-link ${locationHash === "#nav-cadastrais" ? "active" : ""}`}>
                        Cadastrais
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={'#nav-faturamento'} className={`nav-link ${locationHash === "#nav-faturamento" ? "active" : ""}`}>
                        Faturamento
                    </Link>
                </li>

                {!this.props.flAtualizacao ?
                    <>
                        
                        <li className="nav-item">                            
                            <Link to={'#nav-endereco'} className={`nav-link ${locationHash === "#nav-endereco" ? "active" : ""}`}>
                                Endereços
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'#nav-telefone'} className={`nav-link ${locationHash === "#nav-telefone" ? "active" : ""}`}>
                                Telefones
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'#nav-email'} className={`nav-link ${locationHash === "#nav-email" ? "active" : ""}`}>
                                E-mail
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to={'#nav-representante'} className={`nav-link ${locationHash === "#nav-representante" ? "active" : ""}`}>
                                Representantes
                            </Link>
                        </li>
                    </> : <></>}
            </ul>
        );
    }

    getRenderFields() {

        var locationHash = this.props.location.hash;

        if(locationHash === ""){
            locationHash = "#nav-cadastrais";
        }

        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className={`tab-pane fade ${locationHash === "#nav-cadastrais" ? "show active" : ""}`} id="nav-cadastrais" role="tabpanel" aria-labelledby="nav-cadastrais-tab">

                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={3} value={this.state.model.idProprietario} name="idProprietario" readOnly opcionalLabel={false} />
                        <InputText label="Nome do Proprietario" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />

                        <BtnGroup required={true} md={4} sizing="sm" className="w-100" name="cdTipoPessoa" value={this.state.model.cdTipoPessoa} label="Tipo de Pessoa" options={this.state.tipoPessoa} onChange={this.handleChange} />
                        <InputCpf sizing="md" label="CPF" md={4} name="cpf" value={this.state.model.cpf} onChange={this.handleChange} required={this.state.model.cdTipoPessoa === "F"} />
                        <InputCnpj sizing="md" label="CNPJ" md={4} name="cnpj" value={this.state.model.cnpj} onChange={this.handleChange} required={this.state.model.cdTipoPessoa === "J"} />

                        {this.state.model.cdTipoPessoa === "J" ?
                            <React.Fragment>
                                <InputText label="Razão Social" required={true} md={4} value={this.state.model.razaoSocial} name="razaoSocial" onChange={this.handleChange} maxLength={150} />
                                <InputText label="Nome Fantasia" required={true} md={4} value={this.state.model.nomeFantasia} name="nomeFantasia" onChange={this.handleChange} maxLength={150} />
                                <InputText label="Inscrição Estadual" required={false} md={4} value={this.state.model.inscricaoEstadual} name="inscricaoEstadual" onChange={this.handleChange} maxLength={150} />
                            </React.Fragment>
                            : <></>}
                    </div>

                    <div className="row mx-1 mb-3 g-1">
                    {!this.props.flAtualizacao ?
                        <>
                            <InputCheckSwitch label="Liberar Temporáriamente de Bloqueio por Inadimplência" name="flLiberado" checked={Boolean(this.state.model.flLiberado)} onChange={this.handleChange} />
                            <InputCheckSwitch label="Pagamento antecipado quando inadimplente?" name="flPagamentoAntecipadoInadimplente" checked={Boolean(this.state.model.flPagamentoAntecipadoInadimplente)} onChange={this.handleChange} />

                            {this.state.model.flLiberado ? <InputDate md={2} label="Data Limite do Bloqueio" required value={this.state.model.dtLiberado} name="dtLiberado" onChange={this.handleChange} /> : <></>}
                        </> : <></>
                    }

                    
                </div>

                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-faturamento" ? "show active" : ""}`} id="nav-faturamento" role="tabpanel" aria-labelledby="nav-faturamento-tab">
                    <div className="table-responsive">
                        <table className="table table-sm caption-top">
                            <thead className="table-light">
                                <tr>
                                    <th className="col-2"> Tipo da Fatura </th>
                                    <th className="col-10"> Plano de Pagamento </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.model.proprietarioPlanoPagamento.map((item, index) => (
                                    <tr key={index} className="tr-borderless">
                                        <td className="fw-bold">
                                            {this.state.tipoProduto.find(i => i.value === item.cdTipoProduto).label}
                                        </td>
                                        <td>
                                            <SelectPlanoPagamentoByUsuario showBtnNewForm={false} placeholder="Selecione o Plano de Pagamento" name="idPlanoPagamento" required={item.cdTipoProduto === 'S'} value={item?.idPlanoPagamento} onChange={e => { this.handleChange(e, false, item) }} />
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-endereco" ? "show active" : ""}`} id="nav-endereco" role="tabpanel" aria-labelledby="nav-endereco-tab">
                    <div className="row mx-1 mb-3 g-1">
                        <div className="hstack gap-3 mb-2">
                            <div className="align-bottom fs-5">
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddEndereco}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar Endereço
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col-1"> Tipo </th>
                                        <th className="col-3"> Cidade </th>
                                        <th className="col-3"> Endereço </th>
                                        <th className="col-2"> Bairro </th>
                                        <th className="col-1"> CEP </th>
                                        <th className="col-2"> Complemento </th>
                                        <th className="text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.proprietarioEndereco.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td>{item.nomeTipoEndereco}</td>
                                            <td>{item.nomeCidade}, {item.nomeEstado}, {item.nomePais}</td>
                                            <td>{item.endereco}, {item.numero}</td>
                                            <td>{item.bairro}</td>
                                            <td>{item.cep}</td>
                                            <td>{item.complemento}</td>
                                            <td className="text-end d-flex flex-nowrap gap-2">
                                                <button type="button" className="btn btn-sm btn-outline-success" onClick={() => { this.handleCopyEndereco(item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                    </svg>
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => { this.handleEditEndereco(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                    </svg>
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => { this.handleDeleteEndereco(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>                                                
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-telefone" ? "show active" : ""}`} id="nav-telefone" role="tabpanel" aria-labelledby="nav-telefone-tab">
                    <div className="row mx-1 mb-3 g-1">
                        <div className="hstack gap-3 mb-2">
                            <div className="align-bottom fs-5">
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddTelefone}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar Telefone
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col-2"> DDI </th>
                                        <th className="col-10"> Telefone </th>
                                        <th className="col text-end">&nbsp;</th>
                                        <th className="col text-end">&nbsp;</th>
                                        <th className="col text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.proprietarioTelefone.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td>
                                                <SelectDDI showBtns={false} required={true} placeholder="Selecione" name="ddi" value={item.ddi} onChange={e => { this.handleChange(e, false, item) }} />
                                            </td>
                                            <td>
                                                {parseInt(item.ddi) === 55 ?
                                                    <InputTelCelular required={true} value={item.telefone} name="telefone" onChange={e => { this.handleChange(e, false, item) }} />
                                                    :
                                                    <InputText required={true} value={item.telefone} name="telefone" onChange={e => { this.handleChange(e, false, item) }} />
                                                }
                                            </td>
                                            <td>
                                                <InputCheckSwitch label="Principal?" name="flPrincipal" checked={Boolean(item.flPrincipal)} onChange={e => this.handleChangePrincipalTelefone(index, item)} />
                                            </td>
                                            <td>
                                                <InputCheckSwitch label="Whatsapp?" name="flWhatsapp" checked={Boolean(item.flWhatsapp)} onChange={e => this.handleChange(e, false, item)} />
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteTelefone(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-email" ? "show active" : ""}`} id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
                    <div className="row mx-1 mb-3 g-1">
                        <div className="hstack gap-3 mb-2">
                            <div className="align-bottom fs-5">
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddEmail}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar E-mail
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col-11"> E-mail </th>
                                        <th className="col text-end">&nbsp;</th>
                                        <th className="col text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.proprietarioEmail.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td>
                                                <InputEmail placeholder="email@dominio.com" name="email" required value={item.email} onChange={e => this.handleChange(e, false, item)} />
                                            </td>
                                            <td>
                                                <InputCheckSwitch label="Principal?" name="flPrincipal" checked={Boolean(item.flPrincipal)} onChange={e => this.handleChangePrincipalEmail(index, item)} />
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteEmail(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-representante" ? "show active" : ""}`} id="nav-representante" role="tabpanel" aria-labelledby="nav-representante-tab">
                    <div className="row mx-1 mb-3 g-1">

                        <div className="table-responsive">
                            <table className="table table-sm caption-top">
                                <thead className="table-light">
                                    <tr>
                                        <th className="text-center d-none d-md-table-cell"> Código </th>
                                        <th className="col-md-6"> Empresa </th>
                                        <th className="col-md d-none d-md-table-cell"> País </th>
                                        <th className="col-md d-none d-md-table-cell"> Estado </th>
                                        <th className="col-md d-none d-md-table-cell"> Cidade </th>
                                        <th className="col-md text-center d-none d-md-table-cell"> Ativo? </th>
                                        <th className="col-md"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model?.proprietarioRepresentante.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td className="text-center d-none d-md-table-cell">{item.idRepresentante}</td>
                                            <td>
                                                {item.empresa}
                                                {Boolean(item?.flBloqueado) ? <span className="badge text-bg-danger">BLOQUEADO POR INADIMPLÊNCIA</span> : <></>}
                                            </td>
                                            <td className="d-none d-md-table-cell text-nowrap">{item.nomePais}</td>
                                            <td className="d-none d-md-table-cell text-nowrap">{item.nomeEstado}</td>
                                            <td className="d-none d-md-table-cell text-nowrap">{item.nomeCidade}</td>
                                            <td className="text-center d-none d-md-table-cell">
                                                <span className={`rounded-pill badge w-100 fs-7 ${item.ativo ? 'text-bg-success' : 'text-bg-danger'}`}> {item.ativo ? 'Sim' : 'Não'} </span>
                                            </td>
                                            <td className="text-center">
                                                <Link to={`/CadRepresentante/${item.idRepresentante}`} className="btn btn-sm btn-outline-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                    </svg>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <FormProprietario {...props} navigate={navigate} params={params} location={location}/>
}

export default With