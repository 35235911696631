import ModuloService from "../../services/ModuloService";
import SelectModulo from "./SelectModulo";

export default class SelectModuloByGrupoModulo extends SelectModulo {

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.idGrupoModulo !== this.props.idGrupoModulo) {
            this.getOptions();
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        if (this.props.idGrupoModulo) {
            ModuloService.getForComboByGrupoModulo(this.props.idGrupoModulo, (response) => {
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();
                        if (this.props.openSearchOnUpdate) {
                            this.handleOpenSearch();
                        }
                    }
                });
            });
        } else {
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    render() {
        return super.render();
    }
}