import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import TextArea from "../../components/forms/elements/TextArea";
import InputDate from "../../components/forms/elements/InputDate";
import SelectProdutoServicoByTipoUsuarioOrdemTipo from "../../components/app/SelectProdutoServicoByTipoUsuarioOrdemTipo";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import InputValorReais from "../../components/forms/custom/InputValorReais";
import InputQuantidade from "../../components/forms/custom/InputQuantidade";
import SelectPlanoRecorrencia from "../../components/app/SelectPlanoRecorrencia";
import RepresentanteItemAssociadoService from "../../services/RepresentanteItemAssociadoService";
import SelectCategoriaProdutoServicoByUsuario from "../../components/app/SelectCategoriaProdutoServicoByUsuario";
import SelectTipoProdutoServicoByCategoriaComProduto from "../../components/app/SelectTipoProdutoServicoByCategoriaComProduto";

class FormRepresentanteItemAssociado extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeProdutoServico = this.handleChangeProdutoServico.bind(this);
        this.onEdit = this.onEdit.bind(this);

        this.state.model = {
            
            idOrdemTipo : 1,

            idRepresentanteItemAssociado: props.idRepresentanteItemAssociado || 0,
            idRepresentante: props.idRepresentante || 0,
            
            idCategoriaProdutoServico : parseInt(props?.idCategoriaProdutoServico) > 0 ? props?.idCategoriaProdutoServico:  "",
            idTipoProdutoServico : parseInt(props?.idTipoProdutoServico) > 0 ? props?.idTipoProdutoServico:  "",
            idProdutoServico: "",
            idPlanoRecorrencia : parseInt(props?.idPlanoRecorrencia) > 0 ? props?.idPlanoRecorrencia:  "",
            dsObservacao: "",
            nrQuantidade: 1,
            
            flPrecoFixo : props?.flPrecoFixo ? Boolean(props?.flPrecoFixo) : false,
            vlPreco : 0,

            flVigenciaData : props?.flVigenciaData ? Boolean(props?.flVigenciaData) : false,
            dtInicio : props?.dtInicio ? props?.dtInicio : "",
            dtFim : props?.dtFim ? props?.dtFim : "",
            
            flAtivo : true,
        };

        this.state.produtoServico = {};

        this.state.crud = {
            service: RepresentanteItemAssociadoService,
            labels: {
                title: 'Itens Associados',
            },
            urlList: '/',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onEdit(id) {
        super.onEdit(id, () => { 
            this.handleChangeProdutoServico(null);
        })
    }

    handleChangeProdutoServico(e) {
        if (parseInt(this.state.model.idProdutoServico) > 0) {
            ProdutoServicoService.getOneById(this.state.model.idProdutoServico, (produtoServico) => {
                this.setState((state) => {
                    state.produtoServico = produtoServico;                    
                    return state;
                });
            });            
        }
    }


    getRenderFields() {
        return (
            <div className="mb-3">
                <div className="row mb-3 g-3">
                    {parseInt(this.state.model.idRepresentanteItemAssociado) ===  0 ?
                        <SelectCategoriaProdutoServicoByUsuario showBtnNewForm={false} md={6} label="Categoria de Produto" required placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={(e) => { this.handleChange(e, this.handleChangeCategoriaProdutoServico) }} /> :
                        <InputText disabled={true} md={6} label="Categoria do Produto" value={this.state.model.nomeCategoriaProdutoServico} opcionalLabel={false} />                        
                    }
                    {parseInt(this.state.model.idRepresentanteItemAssociado) ===  0 ?
                        <SelectTipoProdutoServicoByCategoriaComProduto showBtnNewForm={false} md={6} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} disabled={!this.state.model.idCategoriaProdutoServico} label="Tipo de Produto" required placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={(e) => { this.handleChange(e) }} onChangeItem={this.handleChangeTipoProdutoServico} /> :
                        <InputText disabled={true} md="auto" label="Tipo de Produto" value={this.state.model.nomeTipoProdutoServico} opcionalLabel={false} />
                    }
                    {parseInt(this.state.model.idRepresentanteItemAssociado) ===  0 ?
                        <SelectProdutoServicoByTipoUsuarioOrdemTipo label="Produto / Serviço" md={10} idOrdemTipo={this.state.model.idOrdemTipo} showBtnNewForm={false} idTipoProdutoServico={this.state.model.idTipoProdutoServico} required placeholder="Selecione o Produto ou Serviço" name="idProdutoServico" value={this.state.model.idProdutoServico}  onChange={e => this.handleChange(e, this.handleChangeProdutoServico)}/> :
                        <InputText label="Produto / Serviço" md={10} value={this.state.model?.nomeProdutoServico} disabled={true} opcionalLabel={false}/>
                    }
                    <InputQuantidade label="Quantidade" lg={2} className="text-center" placeholder="Digite a quantidade" required value={this.state.model.nrQuantidade} name="nrQuantidade" onChange={this.handleChange} decimalScale={this.state.produtoServico.nrCasasDecimais} unidadeMedida={this.state.produtoServico.siglaUnidadeMedida} />

                    <SelectPlanoRecorrencia showBtnNewForm={false} placeholder="Selecione o Plano de Recorrência" required name="idPlanoRecorrencia" value={this.state.model.idPlanoRecorrencia} onChange={this.handleChange} />

                    <TextArea label="Observações" md={12} value={this.state.model.dsObservacao} name="dsObservacao" onChange={this.handleChange} opcionalLabel={false}/>                    
                </div>    
                <div className="row mx-1">
                    <InputCheckSwitch label="Tem Vigência por Data?" name="flVigenciaData" checked={Boolean(this.state.model.flVigenciaData)} onChange={this.handleChange} />
                </div>
                {Boolean(this.state.model.flVigenciaData) ?
                    <div className="row g-3 pt-1 pb-2">
                        <InputDate md={3} label="Data de Início" value={this.state.model.dtInicio} name="dtInicio" onChange={this.handleChange} required={true}/>
                        <InputDate md={3} label="Data de Fim" value={this.state.model.dtFim} name="dtFim" onChange={this.handleChange} min={this.state.model.dtInicio} required={true}/>
                    </div>
                : <></>}

                <div className="row mx-1">
                    <InputCheckSwitch label="Usar preço fixo?" name="flPrecoFixo" checked={Boolean(this.state.model.flPrecoFixo)} onChange={this.handleChange} />
                </div>

                {Boolean(this.state.model.flPrecoFixo) ?
                    <div className="row g-3 pt-1 pb-1">
                        <InputValorReais className="text-end" md={3} label="Preço Fixo" required={true} value={this.state.model.vlPreco} name="vlPreco" onChange={this.handleChange} />
                    </div>
                : <></>}

                <div className="row mx-1">
                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                </div>
                
            </div>
            
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormRepresentanteItemAssociado {...props} navigate={navigate} params={params} />
}

export default With