import React from "react";
import ContatoOrigemService from "../../services/ContatoOrigemService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaContatoOrigem extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ContatoOrigemService,
            labels: {
                title: 'Lista de Origem(s)',
                btnNewRegister: 'Origem',
            },
            keyItem: 'idContatoOrigem',
            urlForm: '/CadContatoOrigem',
            itensFilter: ["idContatoOrigem", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-10"> Nome </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idContatoOrigem}</td>
                <td>{item.nome}</td>
            </>);
    }

    render() {
        return super.render();
    }
}