export default class Notificacao {

    static instance = null;

    notificacoes = [];

    static getInstance() {
        if (this.instance === null) {
            this.instance = new Notificacao();
        }
        return this.instance;
    }

    getNotificacoes() {
        return this.notificacoes;
    }

    setNotificacoes(notificacoes) {
        this.notificacoes = notificacoes;
    }
}