import ProdutoServicoService from "../../services/ProdutoServicoService";
import SelectProdutoServico from "./SelectProdutoServico";

export default class SelectProdutoServicoAgrupador extends SelectProdutoServico {    

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        ProdutoServicoService.getForComboAgrupador((response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });        
    }

    render() {
        return super.render();
    }
}