import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import { toast } from "react-toastify";
import CobrancaService from "../../services/CobrancaService";
import FaturaService from "../../services/FaturaService";

class FormPagar extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idCobranca: "",
            uidCobranca: this.props.params?.uid,
            cobrancaTipoBaixa: [],
            dtVencimento: "",
            vlCobranca: 0,
            vlAberto: 0,
            flVencido: false,
            nrDiasVencido: 0
        };

        this.state.crud = {
            service: FaturaService,
            labels: {
                title: 'Pagamento',
            },
            urlList: '/LstFatura',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        CobrancaService.getOneByUid(this.state.model.uidCobranca, (response) => {
            this.setState({ model: response });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickCopy(text) {
        if (Util.copyClipboard(text)) {
            toast.success('Dados copiado para a área de transferência!');
        } else {
            toast.error('Erro ao copiar, por favor copie manualmente!');
        }
    }

    getIconTipoBaixa(tipoBaixa) {
        switch (parseInt(tipoBaixa.idTipoBaixa)) {
            case 1: //boleto
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-upc" viewBox="0 0 16 16">
                        <path d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                    </svg>);

            case 3: //pix
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-qr-code" viewBox="0 0 16 16">
                        <path d="M2 2h2v2H2V2Z" />
                        <path d="M6 0v6H0V0h6ZM5 1H1v4h4V1ZM4 12H2v2h2v-2Z" />
                        <path d="M6 10v6H0v-6h6Zm-5 1v4h4v-4H1Zm11-9h2v2h-2V2Z" />
                        <path d="M10 0v6h6V0h-6Zm5 1v4h-4V1h4ZM8 1V0h1v2H8v2H7V1h1Zm0 5V4h1v2H8ZM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8H6Zm0 0v1H2V8H1v1H0V7h3v1h3Zm10 1h-1V7h1v2Zm-1 0h-1v2h2v-1h-1V9Zm-4 0h2v1h-1v1h-1V9Zm2 3v-1h-1v1h-1v1H9v1h3v-2h1Zm0 0h3v1h-2v1h-1v-2Zm-4-1v1h1v-2H7v1h2Z" />
                        <path d="M7 12h1v3h4v1H7v-4Zm9 2v2h-3v-1h2v-1h1Z" />
                    </svg>);

            case 4: //cartão crédito
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                    </svg>);

            case 5: //cartão débito
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-credit-card-2-back-fill" viewBox="0 0 16 16">
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z" />
                    </svg>);
            default:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-cash" viewBox="0 0 16 16">
                        <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                        <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
                    </svg>
                );
        }
    }

    getTitle() {
        return "";

    }

    getBtns() {
        /*if (Auth.isAuthenticated()) {
            return (
                <div className="d-grid gap-2 d-md-flex">
                    <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Voltar </BtnButton>
                </div>);
        }*/
        return <></>;
    }

    getRenderFields() {
        return (
            <div className="col-md-8 mx-auto">
                <header>
                    <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                        <a href="https://bdmperformance.com.br/" className="d-flex align-items-center text-dark text-decoration-none">
                            <img src="./logo-preto.png" alt="Logo BDM" />
                        </a>

                        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://www.instagram.com/bdmperformance/">Nosso Instagram</a>
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://bdmperformance.com.br/">Nosso Site</a>
                        </nav>
                    </div>

                    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                        <h1 className="display-5 fw-normal">Pagamento BDM</h1>
                        <p className="fs-5 text-muted">Pagamento da cobrança código <strong>{this.state.model.idCobranca}</strong>, no valor de <strong>{Util.floatToReais(this.state.model.vlCobranca)}</strong> com vencimento em <strong>{Util.date2Br(this.state.model.dtVencimento)}</strong>, escolha abaixo a sua forma de pagamento referente a essa cobrança:
                        </p>
                    </div>
                </header>


                {parseInt(this.state.model.flVencido) === 1 ?
                    <div className="alert alert-warning" role="alert">
                        <strong>Atenção:</strong> Essa cobrança está vencida há {this.state.model.nrDiasVencido} dia(s)!
                    </div>
                    : <></>}

                {parseInt(this.state.model.flPago) === 1 ?

                    <div className="text-center">
                        <div className="alert alert-success" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                            </svg>
                            <h3>Essa cobrança foi quitada!</h3>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="list-group">
                            {this.state.model.cobrancaTipoBaixa.map((tipoBaixa, indexTipoBaixa) => (
                                <React.Fragment key={indexTipoBaixa}>
                                    {tipoBaixa.transacao.uid ?
                                        <Link to={`/pessoa/${tipoBaixa.transacao.uid}`} type="button" className="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true">
                                            {this.getIconTipoBaixa(tipoBaixa)}
                                            <div className="d-flex gap-2 w-100 justify-content-between">
                                                <div>
                                                    <h6 className="mb-0 fs-5">{tipoBaixa.nomeTipoBaixa}</h6>
                                                    {parseInt(tipoBaixa.transacao.nrParcela) > 0 ?
                                                        <p className="mb-0 opacity-75">{tipoBaixa.transacao.nrParcela} x de <strong>{Util.floatToReais(tipoBaixa.transacao.vlTransacao / parseInt(tipoBaixa.transacao.nrParcela))}</strong></p>
                                                        : <></>}
                                                    {tipoBaixa.transacao.vlMulta > 0 ?
                                                        <p className="mb-0 opacity-75">+ Multa: <strong>{Util.floatToReais(tipoBaixa.transacao.vlMulta)}</strong> <small>(por atraso)</small></p>
                                                        : <></>}

                                                    {tipoBaixa.transacao.vlJuros > 0 ?
                                                        <p className="mb-0 opacity-75">+ Juros: <strong>{Util.floatToReais(tipoBaixa.transacao.vlJuros)}</strong> <small>(por atraso)</small></p>
                                                        : <></>}

                                                    {tipoBaixa.transacao.vlAdicional > 0 ?
                                                        <p className="mb-0 opacity-75">+ Taxas: <strong>{Util.floatToReais(tipoBaixa.transacao.vlAdicional)}</strong></p>
                                                        : <></>}

                                                    {parseFloat(tipoBaixa.transacao.vlMulta) === 0 && parseFloat(tipoBaixa.transacao.vlJuros) === 0 && parseFloat(tipoBaixa.transacao.vlAdicional) === 0 ?
                                                        <p className="mb-0 opacity-75">Sem acréscimo</p>
                                                        : <></>}
                                                </div>
                                                <small className="fs-4 fw-bold text-nowrap text-primary">{Util.floatToReais(tipoBaixa.transacao.vlTransacao)} </small>
                                            </div>
                                        </Link>
                                        :
                                        <div type="button" className="list-group-item list-group-item-action d-flex gap-3 py-3 disabled" aria-current="true">
                                            {this.getIconTipoBaixa(tipoBaixa)}
                                            <div className="d-flex gap-2 w-100 justify-content-between">
                                                <div>
                                                    <h6 className="mb-0 fs-5">{tipoBaixa.nomeTipoBaixa}</h6>
                                                    {parseInt(tipoBaixa.transacao.nrParcela) > 0 ?
                                                        <p className="mb-0 opacity-75">{tipoBaixa.transacao.nrParcela} x de <strong>{Util.floatToReais(tipoBaixa.transacao.vlTransacao / parseInt(tipoBaixa.transacao.nrParcela))}</strong></p>
                                                        : <></>}

                                                    {tipoBaixa.transacao.vlMulta > 0 ?
                                                        <p className="mb-0 opacity-75">+ Multa: <strong>{Util.floatToReais(tipoBaixa.transacao.vlMulta)}</strong> <small>(por atraso)</small></p>
                                                        : <></>}

                                                    {tipoBaixa.transacao.vlJuros > 0 ?
                                                        <p className="mb-0 opacity-75">+ Juros: <strong>{Util.floatToReais(tipoBaixa.transacao.vlJuros)}</strong> <small>(por atraso)</small></p>
                                                        : <></>}

                                                    {tipoBaixa.transacao.vlAdicional > 0 ?
                                                        <p className="mb-0 opacity-75">+ Taxas: <strong>{Util.floatToReais(tipoBaixa.transacao.vlAdicional)}</strong></p>
                                                        : <></>}

                                                    {parseFloat(tipoBaixa.transacao.vlMulta) === 0 && parseFloat(tipoBaixa.transacao.vlJuros) === 0 && parseFloat(tipoBaixa.transacao.vlAdicional) === 0 ?
                                                        <p className="mb-0 opacity-75">Sem acréscimo</p>
                                                        : <></>}
                                                </div>
                                                <small className="fs-4 fw-bold text-nowrap text-secondary">{Util.floatToReais(tipoBaixa.transacao.vlTransacao)} </small>
                                            </div>
                                        </div>
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                }

                <footer className="pt-4 my-md-5 pt-md-5 border-top">
                    <div className="row">
                        <div className="col-12 col-md">
                            BDM Performance
                            <small className="d-block mb-3 text-muted">&copy; 2022</small>
                        </div>
                    </div>
                </footer>

            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormPagar {...props} navigate={navigate} params={params} />
}

export default With