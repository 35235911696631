import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import OrdemService from "../../services/OrdemService";
import SelectMarca from "../../components/app/SelectMarca";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import { toast } from "react-toastify";
import SelectCategoriaProdutoServicoByUsuarioComRemap from "../../components/app/SelectCategoriaProdutoServicoByUsuarioComRemap";
import InputChassis from "../../components/forms/custom/InputChassis";
import InputPlaca from "../../components/forms/custom/InputPlaca";
import InputText from "../../components/forms/elements/InputText";
import InputNumber from "../../components/forms/elements/InputNumber";
import SelectTransmissao from "../../components/app/SelectTransmissao";
import SelectCombustivel from "../../components/app/SelectCombustivel";
import BtnGroup from "../../components/forms/elements/BtnGroup";

export default class ListaRemap extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.clear = this.clear.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLista = this.getLista.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.handleChangeCategoria = this.handleChangeCategoria.bind(this);
        this.state = this.getInitState(props);
    }

    getInitState(props) {

        return {
            data: [],
            model: {
                flFiltroTipo: "S",
                busca: "",
                idMarca: "",
                idCategoriaProdutoServico: "",
                idTipoProdutoServico: "",
                idCombustivel: "",
                idTransmissao: "",
                chassis: "",
                placa: "",
                modelo: "",
                ano: "",
                pagina: 0,
                rowCount: 200
            },

            filtroTipo: [
                { value: 'S', label: 'Simples' },
                { value: 'A', label: 'Avançada' },
            ],
        };

    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    getLista(merge = false) {
        if (merge) {
            OrdemService.listRemap(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                OrdemService.listRemap(this.state.model, (response) => {
                    this.setState({ data: response });
                });
            });
        }
    }

    onSubmit(event) {
        this.getLista();
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    handleChangeCategoria() {
        this.setState(state => {
            state.model.idTipoProdutoServico = "";
            return state;
        }, this.onSubmit);
    }

    render() {
        return (
            <div className="p-4 my-2 py-2 w-100">
                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100">
                        Busca Remap
                    </h4>
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row mb-3">
                        <BtnGroup md={3} className="w-100" name="flFiltroTipo" value={this.state.model.flFiltroTipo} label="Tipo de Busca" options={this.state.filtroTipo} onChange={this.handleChange} opcionalLabel={false} />
                        {this.state.model.flFiltroTipo === "S" ?
                            <InputText label="Busca" md={9} value={this.state.model.busca} placeholder="Digite o modelo ou marca do veículo" name="busca" onChange={this.handleChange} maxLength={100} opcionalLabel={false} />
                            : <></>}

                        {this.state.model.flFiltroTipo === "A" ?
                            <React.Fragment>
                                <SelectCategoriaProdutoServicoByUsuarioComRemap showBtns={false} md={3} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={(e) => { this.handleChange(e, this.handleChangeCategoria) }} opcionalLabel={false} />
                                <SelectTipoProdutoServicoByCategoria showBtns={false} md={3} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                                <SelectMarca showBtns={false} md={3} label="Marca" placeholder="Selecione a Marca" name="idMarca" value={this.state.model.idMarca} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                            </React.Fragment> : <></>}

                    </div>

                    {this.state.model.flFiltroTipo === "A" ?
                        <div className="row mb-3">
                            <SelectCombustivel showBtns={false} md={2} label="Combustível" placeholder="Selecione o Combustível" name="idCombustivel" value={this.state.model.idCombustivel} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                            <SelectTransmissao showBtns={false} md={2} label="Transmissão" placeholder="Selecione a Transmissão" name="idTransmissao" value={this.state.model.idTransmissao} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                            <InputChassis label="Chassis" md={2} value={this.state.model.chassis} name="chassis" onChange={this.handleChange} opcionalLabel={false} />
                            <InputPlaca label="Placa" md={2} value={this.state.model.placa} name="placa" onChange={this.handleChange} opcionalLabel={false} />
                            <InputText label="Modelo" md={2} value={this.state.model.modelo} name="modelo" onChange={this.handleChange} maxLength={100} opcionalLabel={false} />
                            <InputNumber label="Ano" placeholder="9999" md={2} value={this.state.model.ano} name="ano" onChange={this.handleChange} min={1900} step={1} opcionalLabel={false} />
                        </div> : <></>}

                    <div className="mb-3 hstack gap-2">
                        <div className="ms-auto">
                            <BtnSubmit className="btn-outline-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg> Buscar
                            </BtnSubmit>
                        </div>
                        <div>
                            <BtnButton className="btn btn-outline-danger" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </BtnButton>
                        </div>
                    </div>
                </form>

                <div className="table-responsive-sm mb-5">
                    <table className="table table-sm caption-top table-hover table-striped table-bordered" >
                        <caption className="text-end">
                            {this.state.data.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th colSpan={3}></th>
                                <th colSpan={2} className="text-center"> Ganho </th>
                                <th colSpan={5}></th>
                            </tr>
                            <tr>
                                <th className="col"> Marca </th>
                                <th className="col"> Modelo </th>
                                <th className="col text-center"> Ano </th>
                                <th className="col text-center table-success"> CV </th>
                                <th className="col text-center table-primary"> Torque </th>
                                <th className="col"> Combustível </th>
                                <th className="col"> Transmissão </th>

                                <th className="col"> Motor </th>
                                <th className="col"> Potência </th>
                                <th className="col"> Leitura </th>
                                <th className="col"> Composição do Remap</th>
                                <th className="col"> Representante </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.nomeMarca}</td>
                                    <td>{item.modelo}</td>
                                    <td className="text-center">{item.ano}</td>
                                    <td className="text-center table-success">{item.ganhoCv}</td>
                                    <td className="text-center table-primary">{item.ganhoTorque}</td>
                                    <td>{item.nomeCombustivel}</td>
                                    <td>{item.nomeTransmissao}</td>
                                    <td>{item.motor}</td>
                                    <td>{item.potencia}</td>
                                    <td>{item.metodoLeitura} {item.dsFlash ? "/" + item.dsFlash : ""}</td>
                                    <td>
                                        {item.nomesProdutoServico.split(';').map((texto, idx) => (
                                            <div key={idx} className='badge bg-primary me-2'>{texto}</div>
                                        ))}
                                    </td>
                                    <td>{item.nomeRepresentante}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="text-center pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>

            </div>
        )
    }
}