import { Component } from "react";

export default class Progressbar extends Component {

    constructor(props) {
        super(props);

        this.update = this.update.bind(this);

        this.state = {
            timeController: 0,
            timeout: 100,
            percController: 0
        };

        this.timeoutRefresh = null;
    }

    componentDidMount() {
        this.update();
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutRefresh);
    }

    update() {
        var state = this.state;

        clearTimeout(this.timeoutRefresh);

        if (this.props.play === undefined || this.props.play) {

            if (state.timeController > (this.props.timeChange * 1000)) {
                state.timeController = 0;
                if (this.props.callback !== undefined) {
                    this.props.callback();
                }
            } else {
                state.timeController = (state.timeController + state.timeout);
            }

            state.percController = ((parseInt(state.timeController) / parseInt((this.props.timeChange * 1000))) * 100)
        } else {
            state.timeController = 0;
        }

        this.setState({ state }, () => {
            this.timeoutRefresh = setTimeout(this.update, state.timeout);
        });

    }

    render() {
        return (
            <div className="progress" style={{ height: "5px" }}>
                <div className="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: parseInt(this.state.percController) + "%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        );
    }
}