import OrdemTipoService from "../../services/OrdemTipoService";
import SelectOrdemTipo from "./SelectOrdemTipo";

export default class SelectOrdemTipoUsuario extends SelectOrdemTipo {

    componentDidUpdate(nextProps) {
        if (nextProps.idTipoProdutoServico !== this.props.idTipoProdutoServico) {
            this.getOptions();
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        OrdemTipoService.getForComboByUsuario((response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}