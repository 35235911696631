import FormNumber from "../base/FormNumber";

export default class InputQuantidade extends FormNumber {

    constructor(props) {
        super(props);

        this.state.props = {
            thousandSeparator: ".",
            decimalSeparator: ",",
            inputMode: "numeric",
            placeholder: this.props.placeholder ? this.props.placeholder : "",
            fixedDecimalScale: true
        };
    }

    getRenderInputGroupRigth() {
        if (this.props.unidadeMedida) {
            return <span className={`input-group-text fw-bold ${this.props.sizing ? "input-group-" + this.props.sizing : ""}`}>{this.props.unidadeMedida}</span>;
        }
        return (<></>);
    }

    render() {
        return super.render();
    }
}