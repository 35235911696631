import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ContratoStatusService from "../../services/ContratoStatusService";
import CrudForm from "../../components/forms/custom/CrudForm";

class FormContratoStatus extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idContratoStatus: "",
            nome: ""
        };

        this.state.crud = {
            service: ContratoStatusService,
            labels: {
                title: 'Status de Contrato',
            },
            urlList: '/LstContratoStatus',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={3} value={this.state.model.idContratoStatus} name="idContratoStatus" readOnly opcionalLabel={false} />
                <InputText label="Nome do Status do Contrato" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormContratoStatus {...props} navigate={navigate} params={params} />
}

export default With