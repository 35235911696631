import Auth from "./Auth";

export default class Permissao {

    static instance = null;

    modulos = [];

    static getInstance() {
        if (this.instance === null) {
            this.instance = new Permissao();
        }
        return this.instance;
    }

    getPermissao(idModulo) {
        let modulos = Auth.getModulosDataToken();
        if(modulos){
            return modulos.findIndex(i => parseInt(i.idModulo) === parseInt(idModulo)) !== -1;
        }

        return false;
    }

    getPermissoes(arrModulos = []) {

        for (let i = 0; i < arrModulos.length; i++) {
            if (this.getPermissao(arrModulos[i])) {
                return true;
            }
        }
        return false;

    }
}