import Auth from "../controllers/Auth";

export default class Util {

    static isObject = obj => obj === Object(obj);

    static setObject(state, cache, storage = {}) {
        try {
            Object.keys(cache).forEach(key => {
                if (this.isObject(cache[key])) {
                    storage[key] = storage[key] === undefined ? {} : storage[key];
                    this.setObject(state[key], cache[key], storage[key]);
                } else {
                    if (cache[key] && state[key]) {
                        storage[key] = state[key];
                    }
                }
            });
        } catch (error) { }

        return storage;
    }

    static isActive() {
        return !document.hidden;
    }


    static CPFIsValid(cpf) {
        if (typeof cpf !== "string") return false
        cpf = cpf.replace(/[\s.-]*/igm, '')
        if (
            !cpf ||
            cpf.length !== 11 ||
            cpf === "00000000000" ||
            cpf === "11111111111" ||
            cpf === "22222222222" ||
            cpf === "33333333333" ||
            cpf === "44444444444" ||
            cpf === "55555555555" ||
            cpf === "66666666666" ||
            cpf === "77777777777" ||
            cpf === "88888888888" ||
            cpf === "99999999999"
        ) {
            return false
        }
        var soma = 0
        var resto
        for (var i = 1; i <= 9; i++)
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(cpf.substring(9, 10))) return false
        soma = 0
        for (let i = 1; i <= 10; i++) {
            soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        }
        resto = (soma * 10) % 11
        if ((resto === 10) || (resto === 11)) resto = 0
        if (resto !== parseInt(cpf.substring(10, 11))) return false
        return true
    }

    static CNPJIsValid(cnpj) {
        var resultado = 0;
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj === '') return false;

        if (cnpj.length !== 14)
            return false;

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === "00000000000000" ||
            cnpj === "11111111111111" ||
            cnpj === "22222222222222" ||
            cnpj === "33333333333333" ||
            cnpj === "44444444444444" ||
            cnpj === "55555555555555" ||
            cnpj === "66666666666666" ||
            cnpj === "77777777777777" ||
            cnpj === "88888888888888" ||
            cnpj === "99999999999999")
            return false;

        // Valida DVs
        var tamanho = cnpj.length - 2
        var numeros = cnpj.substring(0, tamanho);
        var digitos = cnpj.substring(tamanho);
        var soma = 0;
        var pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (parseInt(resultado) !== parseInt(digitos.charAt(0)))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (parseInt(resultado) !== parseInt(digitos.charAt(1)))
            return false;

        return true;
    }

    static removeSpecialsChars(texto) {
        texto = texto.replace(/[ÀÁÂÃÄÅ]/, "A");
        texto = texto.replace(/[àáâãäå]/, "a");
        texto = texto.replace(/[ÈÉÊË]/, "E");
        texto = texto.replace(/[ÍÌÎÏ]/, "I");
        texto = texto.replace(/[ÓÒÔÖÕ]/, "O");
        texto = texto.replace(/[ÚÙÛÜ]/, "U");
        texto = texto.replace(/[Ç]/, "C");
        texto = texto.replace(/[ç]/, "c");
        return texto.replace(/[^a-z0-9 ]/gi, '');
    }

    static searchTextInObject(data = {}, fields = [], txtSearch = "", searchForSpace = true) {
        txtSearch = txtSearch.trim();
        if (txtSearch === "") {
            return true;
        }

        txtSearch = txtSearch.toLowerCase();
        txtSearch = Util.removeSpecialsChars(txtSearch);

        var value = null;
        var retorno = null;
        var fnValidate = (txtSearch, value) => {
            return txtSearch.split(" ").every(el => {
                return value.includes(el);
            });
        };

        for (const element of fields) {
            if (data[element]) {
                value = data[element];
                value = value.toString();
                value = Util.removeSpecialsChars(value);
                value = value.toLowerCase();

                if (searchForSpace) {
                    /*retorno = txtSearch.split(" ").every(el => {
                        return value.includes(el);
                    });*/
                    retorno = fnValidate(txtSearch, value);
                    if (retorno) return true;
                } else {
                    if (value.includes(txtSearch)) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    static reaisToFloat(value) {
        try {
            if (value) {
                value = value.replace(/[^0-9.,]/g, '');
                value = value.replaceAll(".", "");
                value = value.replace(",", ".");
                return parseFloat(value);
            }
        } catch (error) {
            return value;
        }

        return value;
    }

    static floatToReais(value) {
        if (value) {
            value = parseFloat(value);
        } else {
            value = parseFloat(0);
        }

        return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    static floatToPeso(value) {
        if (value) {
            value = parseFloat(value);
        } else {
            value = parseFloat(0);
        }

        return value.toLocaleString('pt-br', { minimumFractionDigits: 3 });
    }

    static stringToNumber(value, er = /[^0-9]/g) {
        if (value) {
            value = value.toString().replace(er, '');
        }
        return value;
    }

    static getProps(props, prefix = "app-special-") {
        return Object.fromEntries(Object.entries(props).filter(([key, value]) => key.indexOf(prefix) !== 0));
    }

    static date2Br(date) {
        try{
            if (date) {
                return (new Date(date)).toISOString().match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}/)[0].split("-").reverse().join("/");
            }
        } catch (e) {
            return date;
        }

        return date;
    }

    static copyClipboard(str, el = null) {

        try {
            if (navigator !== undefined && navigator.clipboard !== undefined) {
                navigator.clipboard.writeText(str);
            } else if (window.clipboardData !== undefined) {  // IE
                window.clipboardData.setData('text', str);
            } else {  // other browsers, iOS, Mac OS
                return false;
                /*var el = document.createElement('textarea');
                el.value = str;
                el.setAttribute('readonly', '');
                //el.style = { position: 'absolute', left: '-9999px', display: 'none' };
                document.body.appendChild(el);

                el.contentEditable = 'true';
                el.readOnly = false;
                //el.focus();
                var range = document.createRange();
                var selection = window.getSelection();
                range.selectNodeContents(el);
                selection.removeAllRanges();
                selection.addRange(range);
                el.setSelectionRange(0, 999999);
                //el.select();
                document.execCommand('copy');
                document.body.removeChild(el);*/
            }
            return true;
        } catch (e) {
            return false;
        }
    }

    chassiIsValid(vin) {
        const transliterate = (c) => {
            return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf(c) % 10;
        };

        const getCheckDigit = (vin) => {
            const map = '0123456789X';
            const weights = '8765432X098765432';
            let sum = 0;
            for (let i = 0; i < 17; ++i) {
                sum += transliterate(vin.charAt(i)) * map.indexOf(weights.charAt(i));
            }
            return map.charAt(sum % 11);
        };

        if (vin.length !== 17) { return false };
        return getCheckDigit(vin) === vin.charAt(8);


    }

    static formatEstoque(val) {
        // remove sign if negative
        var sign = 1;
        if (val < 0) {
            sign = -1;
            val = -val;
        }

        // trim the number decimal point if it exists
        let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();

        while (/(\d+)(\d{3})/.test(num.toString())) {
            // insert comma to 4th last position to the match number
            num = num.toString().replace(/(\d+)(\d{3})/, '$1'.toString() + '.' + '$2'.toString());
        }

        // add number after decimal point
        if (val.toString().includes('.')) {
            num = num + ',' + val.toString().split('.')[1];
        }

        // return result with - sign if negative
        return sign < 0 ? '-' + num : num;
    }

    static openNewWindow(url, body, verb, target) {
        var form = document.createElement("form");

        verb = verb || "POST";
        target = target || "_blank"; //"_self";

        form.action = url;
        form.method = verb;
        form.target = target;

        if (verb === "POST") {
            var data = {};
            data.formData = true;
            data.authorization = Auth.getToken();
            data.body = body;

            if (data) {
                for (var key in data) {
                    var input = document.createElement("textarea");
                    input.name = key;
                    input.value = typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key];
                    form.appendChild(input);
                }
            }
        }

        form.style.display = 'none';
        form.enctype = 'application/json';
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    static changePropertyRecursive(item, propertityItem, value, propertityRecursive) {
        item?.forEach((option, index) => {
            item[index][propertityItem] = value;
            if (option[propertityRecursive]?.length > 0) {
                item[index][propertityRecursive] = this.changePropertyRecursive(option[propertityRecursive], propertityItem, value, propertityRecursive);
            }
        });
        return item;
    }

    static padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    static convertMsToHM(duration) {
        //var milliseconds = Math.floor((duration % 1000) / 100);
        var seconds = Math.floor((duration / 1000) % 60);
        var minutes = Math.floor((duration / (1000 * 60)) % 60);
        var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;

    }

    static htmlFormatWhatsapp(string) {

        string = string.toString();

        // html formatter
        const htmlFormat = [
            { symbol: '*', tag: 'b' },
            { symbol: '_', tag: 'em' },
            { symbol: '~', tag: 'del' },
            { symbol: '`', tag: 'code' },
        ];

        htmlFormat.forEach(({ symbol, tag }) => {
            if (!string) return;

            const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
            const match = string.match(regex);
            if (!match) return;

            match.forEach(m => {
                let formatted = m;
                for (let i = 0; i < 2; i++) {
                    formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
                }
                string = string.replace(m, formatted);
            });
        });

        string = string.replaceAll("\n", "<br/>");
        string = string.replaceAll(/((http|https|ftp):\/\/[\w?=&./-;#~%-]+(?![\w\s?&./;#~%"=-]*>))/g, "<a href='$1' target='_blank'>$1</a>");

        return string;

    }

    static getValueDecimal(value, fractionDigits = 0) {
        return parseFloat(value).toFixed(fractionDigits);
    }
    static stringCut(string, length) {
        if (string.length > length) {
            return string.substring(0, length) + "...";
        }
        return string;
    }

    static getIconeTipo(tipo, width = 16, height = 16, className = "") {
        switch (tipo) {
            case "IMAGEM":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-file-earmark-image ${className}`} viewBox="0 0 16 16">
                        <path d="M6.502 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                        <path d="M14 14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5V14zM4 1a1 1 0 0 0-1 1v10l2.224-2.224a.5.5 0 0 1 .61-.075L8 11l2.157-3.02a.5.5 0 0 1 .76-.063L13 10V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4z" />
                    </svg>
                );
            case "PDF":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-filetype-pdf ${className}`} viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                    </svg>
                );
            case "VIDEO":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-file-earmark-play ${className}`} viewBox="0 0 16 16">
                        <path d="M6 6.883v4.234a.5.5 0 0 0 .757.429l3.528-2.117a.5.5 0 0 0 0-.858L6.757 6.454a.5.5 0 0 0-.757.43z" />
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                    </svg>
                );
            case "AUDIO":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-file-music ${className}`} viewBox="0 0 16 16">
                        <path d="M10.304 3.13a1 1 0 0 1 1.196.98v1.8l-2.5.5v5.09c0 .495-.301.883-.662 1.123C7.974 12.866 7.499 13 7 13c-.5 0-.974-.134-1.338-.377-.36-.24-.662-.628-.662-1.123s.301-.883.662-1.123C6.026 10.134 6.501 10 7 10c.356 0 .7.068 1 .196V4.41a1 1 0 0 1 .804-.98l1.5-.3z" />
                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                    </svg>
                );
            case "TEXTO":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-file-text ${className}`} viewBox="0 0 16 16">
                        <path d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5zM5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z" />
                    </svg>
                );

            case "HTML":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-filetype-html ${className}`} viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-9.736 7.35v3.999h-.791v-1.714H1.79v1.714H1V11.85h.791v1.626h1.682V11.85h.79Zm2.251.662v3.337h-.794v-3.337H4.588v-.662h3.064v.662H6.515Zm2.176 3.337v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596H9.93L8.79 11.85h-.805v3.999h.706Zm4.71-.674h1.696v.674H12.61V11.85h.79v3.325Z" />
                    </svg>
                );

            case "COMPACTADO":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-file-zip ${className}`} viewBox="0 0 16 16">
                        <path d="M6.5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.109 0l-.93-.62a1 1 0 0 1-.415-1.074l.4-1.599V7.5zm2 0h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243V7.5z" />
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm5.5-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9v1H8v1h1v1H8v1h1v1H7.5V5h-1V4h1V3h-1V2h1V1z" />
                    </svg>
                );
            case "DOC":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-file-word ${className}`} viewBox="0 0 16 16">
                        <path d="M4.879 4.515a.5.5 0 0 1 .606.364l1.036 4.144.997-3.655a.5.5 0 0 1 .964 0l.997 3.655 1.036-4.144a.5.5 0 0 1 .97.242l-1.5 6a.5.5 0 0 1-.967.01L8 7.402l-1.018 3.73a.5.5 0 0 1-.967-.01l-1.5-6a.5.5 0 0 1 .364-.606z" />
                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                    </svg>
                );

            case "XLS":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-file-earmark-spreadsheet ${className}`} viewBox="0 0 16 16">
                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
                    </svg>
                );
            default:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className={`bi bi-file ${className}`} viewBox="0 0 16 16">
                        <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                    </svg>
                );

        }
    }

    static getTipoArquivo(tipo) {
        switch (tipo) {
            case "image/jpeg":
            case "image/png":
            case "image/webp":
            case "jpeg":
            case "jpg":
            case "png":
                return "IMAGEM";

            case "application/pdf":
                return "PDF";

            case "video/mp4":
            case "video/quicktime":
                return "VIDEO";
            case "application/x-zip-compressed":
            case "rar":
            case "zip":
                return "COMPACTADO";
            case "audio/ogg":
            case "audio/mpeg":
            case "mp3":
                return "AUDIO";
            case "docx":
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                return "DOC";
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return "XLS";
            case "txt":
            case "text/plain":
                return "TEXTO";
            case "text/html":
                return "HTML";
            default:
                return "OUTRO";
        }
    }

    static isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    static formataCPF(cpf) {
        if (cpf) {
            cpf = cpf.toString();
            cpf = cpf.replace(/[^\d]/g, "");
            return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        }
        return cpf;

    }

    static formataCnpj(cpf) {
        if (cpf) {
            cpf = cpf.toString();
            cpf = cpf.replace(/[^\d]/g, "");
            return cpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        }
        return cpf;
    }

    static nl2br (str, is_xhtml = true) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }

    static dateFormatYYYYMM(date){
        var yyyy = date.getFullYear();
        var mm = date.getMonth() + 1;
        mm = mm < 10 ? '0' + mm.toString() : mm;
        return yyyy + "-" + mm;
    }

    static dateFormatYYYYMMDD(date){
        return date.toISOString().slice(0, 10);
    }

    static dateDiffNow(date){
        let date1 = (new Date(date)).getTime();
        let date2 = (new Date()).getTime();
        let diffTime = date1 - date2;
        let diffDays = Math.round (diffTime / (1000 * 3600 * 24));
        return diffDays;
    }
}