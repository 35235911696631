import React from "react";
import MuralService from "../../services/MuralService.js";
import CrudList from "../../components/forms/custom/CrudList";
import Util from "../../utils/Util.js";

export default class ListaMural extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: MuralService,
            labels: {
                title: 'Lista de Mural(s)',
                btnNewRegister: 'Mural',
            },
            keyItem: 'idMural',
            urlForm: '/CadMural',
            itensFilter: ["idMural", "dsTitulo"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col text-center">  </th>
                <th className="col text-center d-none d-md-table-cell"> Código </th>
                <th className="col text-center">  </th>
                <th className="col-8"> Título </th>
                <th className="col text-center"> Destinatários </th>
                <th className="col text-center"> Visualizados </th>
                <th className="col-2 text-center"> Data </th>
                <th className="col-2 text-center"> Status </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="col">
                    {Boolean(item.flImportante) ?
                        <span className="fs-6 text-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg>
                        </span>
                        : <></>
                    }
                </td>
                <td className="text-center d-none d-md-table-cell">{item.idMural}</td>
                <td>{Boolean(item.flTemAnexo) ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                    </svg> : <></>}
                </td>
                <td>{item.dsTitulo}</td>
                <td className="text-center">{parseInt(item.nrUsuarios)}</td>
                <td className="text-center">{parseInt(item.nrUsuariosLeitura)}</td>
                <td className="text-center">
                    <small>{Util.date2Br(item.dataMensagem)} {item.horaMensagem}</small>
                </td>
                <td className="text-center">
                    {Boolean(item.flAtivo) ? <span className="badge bg-success">ATIVO</span> : <span className="badge bg-danger">INATIVO</span>}
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}