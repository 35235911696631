import FormSelectSearchModal from "../forms/base/FormSelectSearchModal";

export default class SelectMes extends FormSelectSearchModal {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);

        this.state.showBtns = this.props.showBtns === undefined ? true : this.props.showBtns;
        this.state.showBtnSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
        this.state.options = [
            { value: 1, label: "Janeiro" },
            { value: 2, label: "Feveiro" },
            { value: 3, label: "Março" },
            { value: 4, label: "Abril" },
            { value: 5, label: "Maio" },
            { value: 6, label: "Junho" },
            { value: 7, label: "Julho" },
            { value: 8, label: "Agosto" },
            { value: 9, label: "Setembro" },
            { value: 10, label: "Outubro" },
            { value: 11, label: "Novembro" },
            { value: 12, label: "Dezembro" }
        ];
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.dispachChangeDidMount && this.props.value) {
            this.handleChange({ target: { value: this.props.value, name: this.props.name } });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        if (callBack) {
            callBack();
        }
    }

    render() {
        return super.render();
    }
}