import ProdutoServicoService from "../../services/ProdutoServicoService";
import FormSelectSearchModal from "../forms/base/FormSelectSearchModal";
import FormProdutoServico from "../../pages/ProdutoServico/FormProdutoServico";
import Permissao from "../../controllers/Permissao";
import Util from "../../utils/Util";
import MostraValor from "./MostraValor";

export default class SelectProdutoServico extends FormSelectSearchModal {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);

        this.state.showBtns = this.props.showBtns === undefined ? true : this.props.showBtns;
        this.state.showBtnSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
        this.state.showBtnNewForm = this.state.showBtns && ((this.props.showBtnNewForm === undefined ? true : false) && Permissao.getInstance().getPermissao(15));
        this.state.showBtnNewSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
    }

    getConfigFormNew() {
        return {
            title: 'Cadastro de Produto / Serviço',
            sizeModal: 'modal-xl',
            config: {
                isModal: true
            },
            form: FormProdutoServico,
            onSave: (response) => {
                this.getOptions(() => {
                    if (response.data?.idProdutoServico) {
                        this.handleOpenSearch(null, response.data?.idProdutoServico);
                    } else {
                        this.handleOpenSearch();
                    }
                });
            },
            onClose: () => {
                //this.handleOpenSearch();
            }
        };
    };

    fnPromocao(produtoServicoPromocoes) {
        return (produtoServicoPromocoes > 0 ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-tags-fill text-success" viewBox="0 0 16 16">
                <path d="M2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2zm3.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                <path d="M1.293 7.793A1 1 0 0 1 1 7.086V2a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l.043-.043-7.457-7.457z" />
            </svg>
            : <></>);
    }

    componentDidMount() {
        super.componentDidMount();

        //this.state.sizeModal = "modal-xl";
        this.state.fullScreen = true;
        this.state.headers = [
            { key: "value", label: "Código", className: 'col-auto text-center d-none d-md-table-cell' },
            { key: "label", label: "Item", className : 'col-5'},
            { key: "dsEspecificacao", label: "Descrição", className: 'col-3 fs-6 fst-italic d-none d-md-table-cell'},
            //{ key: "label", label: "Item",  fnData: (value, option) => { return <>{option.label}{option.dsEspecificacao ? <i><br/>{option.dsEspecificacao}</i> : ""}</>}},
            //{ key: "vlEstoqueFormat", label: "Estoque", className: 'col-1 text-center d-none d-md-table-cell' },
            { key: "siglaUnidadeMedida", label: "Unidade", className: 'col-auto text-center d-none d-md-table-cell' },
            { key: "vlPreco", label: "Preço", className: 'col-auto text-end fw-bold', fnData: (value) => { return <MostraValor>{Permissao.getInstance().getPermissao(22) ? Util.floatToReais(value) : "OCULTO"}</MostraValor> } },
            //{ key: "vlPesoBruto", label: "Peso", className: 'text-end fw-bold', fnData: Util.floatToPeso },
            { key: "produtoServicoPromocoes", label: "", className: 'col-auto text-center', fnData: this.fnPromocao },

        ];

        this.getOptions(() => {
            if (this.dispachChangeDidMount && this.props.value) {
                this.handleChange({ target: { value: this.props.value, name: this.props.name } });
            }
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    isDisabled(item) {
        return parseInt(item.flAtivo) === 0 || (parseInt(item.flControlaEstoque) === 1 && parseInt(item.flEstoqueNegativo) === 0 && parseFloat(item.vlEstoque) <= 0) ? true : super.isDisabled(item);
    }

    getClassName(item) {
        return parseInt(item.flAtivo) === 0 || (item.flControlaEstoque && !item.flEstoqueNegativo && item.vlEstoque <= 0) ? 'table-danger' : '';
    }

    getOptions(callBack) {
        ProdutoServicoService.getForCombo((response) => {
            if (!this.state.isComponentMounted) return;
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}