import React from "react";
import FormField from "./FormField";

export default class FormTextArea extends FormField {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(event) {
        super.handleChange(event);
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    handleBlur(event) {
        super.handleBlur(event);
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    render() {

        var className = `form-control ${this.props.className ? this.props.className : ""} ${this.props.sizing ? "form-control-" + this.props.sizing : ""}`;

        var render =
            <textarea
                {...this.getProps(this.props)}
                className={className}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
            />;

        return this.getRenderFormControl(render);
    }
}