import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import TransmissaoService from "../../services/TransmissaoService";
import CrudForm from "../../components/forms/custom/CrudForm";

class FormTransmissao extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idTransmissao: "",
            nome: ""
        };

        this.state.crud = {
            service: TransmissaoService,
            labels: {
                title: 'Transmissão',
            },
            urlList: '/LstTransmissao',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={3} value={this.state.model.idTransmissao} name="idTransmissao" readOnly opcionalLabel={false}/>
                <InputText label="Nome do Transmissão" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormTransmissao {...props} navigate={navigate} params={params} />
}

export default With