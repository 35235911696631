import TipoProdutoServicoService from "../../services/TipoProdutoServicoService";
import SelectTipoProdutoServicoByCategoria from "./SelectTipoProdutoServicoByCategoria";

export default class SelectTipoProdutoServicoByCategoriaComProduto extends SelectTipoProdutoServicoByCategoria {

    getOptions(callBack) {
        if (this.props.idCategoriaProdutoServico) {
            TipoProdutoServicoService.getForComboByCategoriaProdutoServicoComProduto(this.props.idCategoriaProdutoServico, (response) => {
                if (!this.state.isComponentMounted) return;
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();
                    }
                });
            });
        } else {
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    render() {
        return super.render();
    }
}