import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import TipoBaixaService from "../../services/TipoBaixaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputNumber from "../../components/forms/elements/InputNumber";
import SelectBanco from "../../components/app/SelectBanco";
import SelectContaByBanco from "../../components/app/SelectContaByBanco";
import SelectCategoriaBaixa from "../../components/app/SelectCategoriaBaixa";

class FormTipoBaixa extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idTipoBaixa: "",
            idCategoriaBaixa : "",
            nome: "",
            flFaturamento: true,
            nrParcela : 1,

            idBanco: "",
            idConta: "",
        };

        this.state.crud = {
            service: TipoBaixaService,
            labels: {
                title: 'TipoBaixa',
            },
            urlList: '/LstTipoBaixa',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idTipoBaixa} name="idTipoBaixa" readOnly opcionalLabel={false} />
                    <InputText label="Nome do Tipo de Baixa" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <InputNumber className="text-center" label="Número de Parcelas" name="nrParcela" md={3} value={parseInt(this.state.model.nrParcela)} required={true} onChange={this.handleChange} min={1} max={12} />
                    <SelectCategoriaBaixa label="Nome da Categoria" required placeholder="Selecione a Categoria" name="idCategoriaBaixa" md={9} value={this.state.model.idCategoriaBaixa} onChange={this.handleChange} />                    
                </div>
                <div className="row mx-1 mb-3">
                    <InputCheckSwitch label="Faturamento?" name="flFaturamento" checked={Boolean(this.state.model.flFaturamento)} onChange={this.handleChange} />
                </div>
                {Boolean(this.state.model.flFaturamento) ? 
                <div className="row mx-1 mb-3">
                    <SelectBanco label="Nome do Banco" required placeholder="Selecione o Banco" name="idBanco" md={6} value={this.state.model.idBanco} onChange={this.handleChange} />
                    <SelectContaByBanco required idBanco={this.state.model.idBanco} label="Conta" value={this.state.model.idConta} placeholder="Selecione o Conta" name="idConta" md={6} onChange={this.handleChange} />
                </div> : <></>}
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormTipoBaixa {...props} navigate={navigate} params={params} />
}

export default With