import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import ModeloService from "../../services/ModeloService";
import { toast } from "react-toastify";
import BtnButton from "../../components/forms/elements/BtnButton";
import { Link } from "react-router-dom";
import InputNumber from "../../components/forms/elements/InputNumber";
import SelectMarcaByCategoriaProdutoServico from "../../components/app/SelectMarcaByCategoriaProdutoServico";
import SelectCategoriaProdutoServicoByUsuario from "../../components/app/SelectCategoriaProdutoServicoByUsuario";

export default class ListaModelo extends MyComponent {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.clear = this.clear.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.getLista = this.getLista.bind(this);
        this.state = this.getInitState();
    };

    getInitState(props) {
        return {
            data: [],
            model: {
                busca: "",

                idMarca: "",
                idCategoriaProdutoServico: "",
                nrAno: "",

                pagina: 0,
                rowCount: 50,
            },
            filtroLeitura: [
                { value: 'T', label: 'Todos' },
                { value: 'L', label: 'Lidas' },
                { value: 'N', label: 'Não lidas' },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event) {
        this.getLista();
    }

    getLista(merge = false) {
        if (merge) {
            ModeloService.listForList(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                ModeloService.listForList(this.state.model, (response) => {
                    this.setState({ data: response });
                });
            });
        }
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    clear() {
        this.setState(this.getInitState(), () => {
            //this.getLista();
        });
    }


    render() {
        return (
            <div className="p-3 my-2">
                <div className="hstack gap-3 mb-3">
                    <h4 className="mb-3 w-100">Lista de Modelos</h4>
                    <Link to="/CadModelo" className="btn btn-outline-primary text-nowrap">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg> Modelo
                    </Link>
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >

                    <div className="row g-3 mb-3">
                        <SelectCategoriaProdutoServicoByUsuario showBtnNewForm={true} md={6} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <InputNumber className="text-center" label="Ano" placeholder="9999" md={2} value={this.state.model.nrAno} name="nrAno" onChange={this.handleChange} min={1900} step={1} opcionalLabel={false} />
                        <SelectMarcaByCategoriaProdutoServico showBtnNewForm={true} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} md={4} label="Marca" placeholder="Selecione a Marca" name="idMarca" value={this.state.model.idMarca} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />

                        <div className="col">
                            <div className="col-md-12 input-group mb-3">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite o nome de um produto" value={this.state.model.busca} onChange={this.handleChange} />
                                <button className="btn btn-outline-primary" type="submit"> Buscar </button>
                            </div>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-danger" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </button>
                        </div>
                    </div>
                </form>


                <div className="table-responsive">
                    <table className="table table-sm table-hover caption-top">
                        <caption className="text-end">
                            {this.state.data.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th className="text-center d-none d-md-table-cell"> Código </th>
                                <th className="col-md-4"> Nome </th>
                                <th className="col-md-2"> Marca </th>
                                <th className="col-md-1 text-center"> Ano </th>
                                <th className="col-md-1 text-center"> Potência </th>
                                <th className="col-md-1 text-center"> Motor </th>
                                <th className="col-md-2 text-center"> Transmissão </th>
                                <th className="col-md-2 text-center"> Combustível </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item) => (
                                <tr key={item.idModelo}>
                                    <td className="text-center d-none d-md-table-cell">{item.idModelo}</td>
                                    <td> {item.nome}</td>
                                    <td> {item.nomeMarca}</td>
                                    <td className="text-center"> {item.nrAno}</td>
                                    <td className="text-center"> {item.dsPotencia}</td>
                                    <td className="text-center"> {item.dsMotor}</td>
                                    <td className="text-center"> {item.nomeTransmissao}</td>
                                    <td className="text-center"> {item.nomeCombustivel}</td>

                                    <td className="align-middle text-end d-flex flex-nowrap gap-1">
                                        <Link to={`/CadModelo/${item.idModelo}`} className="btn btn-outline-primary" title="Editar item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </Link>
                                        <button type="button" className="btn btn-outline-danger" onClick={() => { this.handleClickExcluir(item) }} title="Excluir item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="text-center pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>

            </div >
        );
    }
}