import Http from "../controllers/Http";

export default class OrdemVeiculoService {

    static urlBase = "/ordemVeiculo/";

    static getOneByChassis(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byChassis', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByPlaca(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byPlaca', data)
            .then(fnSucess)
            .catch(fnError);
    }

}
