import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ProdutoServicoInventarioService from "../../services/ProdutoServicoInventarioService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectProdutoServicoByTipo from "../../components/app/SelectProdutoServicoByTipo";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import InputQuantidade from "../../components/forms/custom/InputQuantidade";
import SelectCategoriaProdutoServico from "../../components/app/SelectCategoriaProdutoServico";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";

class FormProdutoServicoInventario extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeProduto = this.handleChangeProduto.bind(this);

        this.state.model = {
            idCategoriaProdutoServico: "",
            idTipoProdutoServico: "",
            idProdutoServicoInventario: "",
            idProdutoServico: this.props.idProdutoServico || "",
            nrQuantidade: ""
        };

        this.state.produto = {
            nrCasasDecimais: 0,
            siglaUnidadeMedida: ""
        };

        this.state.crud = {
            service: ProdutoServicoInventarioService,
            labels: {
                title: 'Inventário',
            },
            urlList: '/LstProdutoServicoInventario',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.state.model.idProdutoServico) {
            ProdutoServicoService.getOneById(this.state.model.idProdutoServico, (response) => {
                this.setState((state) => {
                    state.model.idCategoriaProdutoServico = response.idCategoriaProdutoServico;
                    state.model.idTipoProdutoServico = response.idTipoProdutoServico;
                    return state;
                });
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeProduto(event) {
        ProdutoServicoService.getOneById(this.state.model.idProdutoServico, (response) => {
            this.setState((state) => {
                state.produto = response;
                return state;
            });
        });
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idProdutoServicoInventario} name="idProdutoServicoInventario" readOnly opcionalLabel={false} />
                </div>
                <div className="row mb-3 g-3">
                    <SelectCategoriaProdutoServico required={true} md={6} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={this.handleChange} />
                    <SelectTipoProdutoServicoByCategoria required={true} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} md={6} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={this.handleChange} />
                    <SelectProdutoServicoByTipo idTipoProdutoServico={this.state.model.idTipoProdutoServico} autoFocus={!this.state.model.idProdutoServico} label="Produto" md={12} required placeholder="Selecione o Produto ou Serviço" name="idProdutoServico" value={this.state.model.idProdutoServico} onChange={e => this.handleChange(e, this.handleChangeProduto)} />
                    <InputQuantidade disabled={true} label="Estoque" md={6} className="text-center" value={this.state.produto.vlEstoque} decimalScale={this.state.produto.nrCasasDecimais} unidadeMedida={this.state.produto.siglaUnidadeMedida} opcionalLabel={false} />
                    <InputQuantidade autoFocus={this.state.model.idProdutoServico} required disabled={!this.state.model.idProdutoServico} label="Quantidade atual do estoque" md={6} className="text-center" placeholder="Digite a quantidade atual do estoque" value={this.state.model.nrQuantidade} name="nrQuantidade" onChange={this.handleChange} decimalScale={this.state.produto.nrCasasDecimais} unidadeMedida={this.state.produto.siglaUnidadeMedida} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormProdutoServicoInventario {...props} navigate={navigate} params={params} />
}

export default With