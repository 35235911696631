import React from "react";
import FormField from "./FormField";

export default class FormBtnGroupRadio extends FormField {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.state.value = null;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(event) {
        super.handleChange(event);
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        var className = `btn-group ${this.props.className ? this.props.className : ""}  ${this.props.sizing ? "btn-group-" + this.props.sizing : ""}`;

        var options = this.props.options || [];

        var render =
            <div className={className} role="group" aria-label="">
                {options.map((item, index) => {
                    let id = this.props.id !== undefined ? this.props.id : this.props.name + '-' + item.value;
                    return (
                        <React.Fragment key={index}>
                            <input
                                className="btn-check"
                                autoComplete="off"
                                name={this.props.name}
                                disabled={this.props.disabled}
                                id={`radio-${id}`}
                                value={item.value}
                                type="radio"
                                checked={this.props.value !== null && this.props.value.toString() === item.value.toString()}
                                onChange={this.handleChange} />
                            <label className={`btn ${item.labelStyle !== undefined ? item.labelStyle : (this.props.labelStyle !== undefined ? this.props.labelStyle : "btn-outline-primary")}`} htmlFor={`radio-${id}`}>{item.label}</label>
                        </React.Fragment>);
                })}
            </div>;

        return this.getRenderFormControl(render);
    }
}