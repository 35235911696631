import React from "react";
import TipoBaixaService from "../../services/TipoBaixaService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaTipoBaixa extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: TipoBaixaService,
            labels: {
                title: 'Lista de Tipo de Baixa(s)',
                btnNewRegister: 'Tipo de Baixa',
            },
            keyItem: 'idTipoBaixa',
            urlForm: '/CadTipoBaixa',
            itensFilter: ["idTipoBaixa", "nome", "nrParcela"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-3"> Nome </th>
                <th className="col-md-3"> Categoria </th>
                <th className="col-md-1 text-center"> Parcelas </th>
                <th className="col-md-1 text-center"> Faturamento? </th>
                <th className="col-md-4"> Conta </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idTipoBaixa}</td>
                <td>{item.nome}</td>
                <td>{item.nomeCategoriaBaixa}</td>
                <td className="text-center">{item.nrParcela}</td>
                <td className="text-center">
                    {Boolean(item.flFaturamento) ? <span className="badge bg-success">SIM</span> : <span className="badge bg-danger">NÃO</span>}
                </td>
                <td>{item.nomeBanco} - {item.nomeConta}</td>
            </>);
    }

    render() {
        return super.render();
    }
}