import React from "react";
import { Link } from "react-router-dom";
import EventBus from "../../controllers/EventBus";
import MyComponent from "../../components/custom/MyComponent";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import Util from "../../utils/Util";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import SelectCategoriaProdutoServico from "../../components/app/SelectCategoriaProdutoServico";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import FormProdutoServicoEntrada from "../ProdutoServicoEntrada/FormProdutoServicoEntrada";
import FormProdutoServicoInventario from "../ProdutoServicoInventario/FormProdutoServicoInventario";
import FormFoto from "./FormFoto";
import Permissao from "../../controllers/Permissao";
import SelectOrdemTipoUsuario from "../../components/app/SelectOrdemTipoUsuario";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import { toast } from "react-toastify";
import Select from "../../components/forms/elements/Select";
import BtnButton from "../../components/forms/elements/BtnButton";
import FormAgrupar from "./FormAgrupar";
import SelectProdutoServicoByTipoAgrupador from "../../components/app/SelectProdutoServicoByTipoAgrupador";
import SelectProdutoServicoAgrupador from "../../components/app/SelectProdutoServicoAgrupador";

export default class ListaProdutoServico extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.excluir = this.excluir.bind(this);
        this.clear = this.clear.bind(this);
        this.handleClickExcluir = this.handleClickExcluir.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickExpand = this.handleClickExpand.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.getLista = this.getLista.bind(this);
        this.state = this.getInitState(props);
        this.handleOpenModalEntrada = this.handleOpenModalEntrada.bind(this);
        this.handleOpenModalInventario = this.handleOpenModalInventario.bind(this);
        this.handleCheckItem = this.handleCheckItem.bind(this);
        this.handleCheckAllItem = this.handleCheckAllItem.bind(this);
        this.podeAgruparItemSelecionados = this.podeAgruparItemSelecionados.bind(this);
        this.podeDesagruparItemSelecionados = this.podeDesagruparItemSelecionados.bind(this);
        this.handleClickAgrupar = this.handleClickAgrupar.bind(this);
        this.handleClickDesagrupar = this.handleClickDesagrupar.bind(this);
        this.handleClickVerAgrupados = this.handleClickVerAgrupados.bind(this);

        this.state.filtroAtivo = [
            { value: 'T', label: 'Todos' },
            { value: 'A', label: 'Ativos' },
            { value: 'I', label: 'Inativo' },
        ];

        this.state.filtroAgrupador = [
            { value: 'T', label: 'Todos' },
            { value: 'A', label: 'Agrupador' },
            { value: 'F', label: 'Agrupados' },
            { value: 'N', label: 'Não Agrupados' },
        ];

        this.cache = {
            active: true,
            id: "cache-lista-produto-servico",
            clear: true,
            state: {
                model: {
                    busca: true,
                    idCategoriaProdutoServico: true,
                    idTipoProdutoServico: true,
                    idProdutoServicoPai: true,
                    idOrdemTipo: true,
                    flAtivo: true,
                    flAgrupador: true
                }
            }
        };
    }

    getInitState(props) {
        return {
            data: [],
            expand: true,
            model: {
                busca: "",
                idCategoriaProdutoServico: "",
                idTipoProdutoServico: "",
                idProdutoServicoPai : "",
                idOrdemTipo: "1",
                flAtivo: 'A',
                flAgrupador: 'T',

                pagina: 0,
                rowCount: 50,
            },
            
            produtoServicos: [],

            filtroRowCount: [
                { value: 50, label: "50 Registros" },
                { value: 100, label: "100 Registros" },
                { value: 200, label: "200 Registros" },
                { value: 500, label: "500 Registros" },
                { value: 1000, label: "1000 Registros" },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount();
        //this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    clear() {
        this.setState(this.getInitState(), () => {
            //this.getLista();
        });
    }

    getLista(merge = false) {
        if (merge) {
            ProdutoServicoService.listForList(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                ProdutoServicoService.listForList(this.state.model, (response) => {
                    this.setState({ data: response, produtoServicos: [] });
                });
            });
        }
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    handleClickExcluir(item) {
        EventBus.dispatch("modal-delete-open", {
            id: item.idProdutoServico,
            config: {
                title: "Excluir produto/serviço?",
                message: <>Deseja realmente excluir o produto/serviço: <strong>{item.nome} </strong>?</>,
                btnConfirmText: "Sim, excluir o produto/serviço",
                description: "Esta é uma ação é irreversível!",
                style: "danger"
            }
        })
    }

    excluir(item) {
        ProdutoServicoService.delete(item.id, (response) => {
            this.getLista();
        });
    }

    handleClickExpand() {
        this.setState({ expand: !this.state.expand })
    }

    onSubmit(event) {
        this.getLista();
    }

    getValueEstoque(item) {
        if (item.flControlaEstoque) {
            var vlEstoque = parseFloat(item.vlEstoque).toFixed(item.nrCasasDecimais);
            if (item.vlEstoque === 0) {
                return (<span className="badge bg-warning">{vlEstoque}</span>);
            } else if (item.vlEstoque < 0) {
                return (<span className="badge bg-danger">{Util.formatEstoque(vlEstoque)}</span>);
            } else {
                return (<span className="badge bg-success">{Util.formatEstoque(vlEstoque)}</span>);
            }
        }
        return (<></>);
    }

    handleOpenModalEntrada(item) {
        EventBus.dispatch("modal-add", {
            title: 'Dados da Entrada',
            sizeModal: 'modal-lg',
            config: {
                isModal: true,
                idProdutoServico: item.idProdutoServico
            },
            form: FormProdutoServicoEntrada,
            onSave: (response) => {
                this.getLista();
            },
            onClose: () => {
            }
        });
    }

    handleOpenModalInventario(item) {
        EventBus.dispatch("modal-add", {
            title: 'Dados do Inventário',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idProdutoServico: item.idProdutoServico
            },
            form: FormProdutoServicoInventario,
            onSave: () => {
                this.getLista();
            },
            onClose: () => {
            }
        });
    }

    handleOpenModalFoto(item) {
        EventBus.dispatch("modal-add", {
            title: 'Fotos do Produto',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                idProdutoServico: item.idProdutoServico
            },
            form: FormFoto
        });
    }

    handleCheckItem(event, item) {
        if (event.target.checked) {
            this.setState(state => {
                state.produtoServicos.push(item.idProdutoServico);
                return state;
            });
        } else {
            this.setState(state => {
                state.produtoServicos.splice(state.produtoServicos.findIndex(idProdutoServico => parseInt(idProdutoServico) === parseInt(item.idProdutoServico)), 1);
                return state;
            });
        }
    }

    handleClickVerAgrupados(idProdutoServico) {
        this.setState(state => {
            state = this.getInitState();
            state.model.idProdutoServicoPai = idProdutoServico;
            return state;
        },this.getLista);
    }

    handleCheckAllItem(event) {
        if (event.target.checked) {
            this.setState(state => {
                state.produtoServicos = [];
                state.data.forEach(item => {
                    state.produtoServicos.push(item.idProdutoServico);
                });
                return state;
            });
        } else {
            this.setState(state => {
                state.produtoServicos = [];
                return state;
            });
        }
    }

    podeAgruparItemSelecionados() {
        if (this.state.produtoServicos.length > 0) {
            var produtoServico = this.state.data.find(o => parseInt(this.state.produtoServicos[0]) === parseInt(o.idProdutoServico));
            if (produtoServico) {
                var idTipoProdutoServico = produtoServico.idTipoProdutoServico;

                return this.state.produtoServicos.length === (this.state.produtoServicos.filter(idProdutoServico => {
                    var item = this.state.data.find(o => parseInt(idProdutoServico) === parseInt(o.idProdutoServico));
                    return (
                        parseInt(item?.idTipoProdutoServico) === parseInt(idTipoProdutoServico)
                        && !Boolean(item.flAgrupador)
                    )
                }).length);
            }
        }
        return false;
    }

    podeDesagruparItemSelecionados() {
        if (this.state.produtoServicos.length > 0) {
            return this.state.produtoServicos.length === (this.state.produtoServicos.filter(idProdutoServico => {
                var item = this.state.data.find(o => parseInt(idProdutoServico) === parseInt(o.idProdutoServico));
                return (
                    item?.idProdutoServicoPai
                    && !Boolean(item.flAgrupador)
                )
            }).length);
        }
        return false;
    }

    handleClickAgrupar() {
        var produtoServico = this.state.data.find(o => parseInt(this.state.produtoServicos[0]) === parseInt(o.idProdutoServico));

        EventBus.dispatch("modal-add", {
            title: 'Agrupar Produtos / Serviço',
            sizeModal: 'modal-lg',
            config: {
                isModal: true,
                idTipoProdutoServico: produtoServico.idTipoProdutoServico,
                itens : this.state.produtoServicos
            },
            form: FormAgrupar,
            onSave: (response) => {
                this.getLista();
            },
            onClose: () => {
            }
        });
    }

    handleClickDesagrupar() {
        EventBus.dispatch("modal-confirmacao-open", {
            config: {
                title: "Confirmar desagrupamento?",
                message: <>Deseja realmente remover o agrupador dos itens selecioados?</>,
                btnConfirmText: "Sim, remover",
                description: "Esta é uma ação é irreversível!",
                style: "warning",
                confirm: () => {                    
                    ProdutoServicoService.desagrupar({itens : this.state.produtoServicos}, (response) => {
                        this.getLista();
                    });

                }
            }
        })
    }

    render() {
        return (
            <div className="p-3 my-2 w-100">
                <div className="hstack gap-3 mb-3">
                    <h4 className="mb-3 w-100">Lista de Produtos / Serviços</h4>

                    <BtnButton disabled={this.state.produtoServicos.length === 0 || !this.podeAgruparItemSelecionados()} className="btn-outline-primary text-nowrap d-none d-sm-block" onClick={this.handleClickAgrupar}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection" viewBox="0 0 16 16">
                            <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm1.5.5A.5.5 0 0 1 1 13V6a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13z"/>
                        </svg> Agrupar
                    </BtnButton>

                    <BtnButton disabled={this.state.produtoServicos.length === 0 || !this.podeDesagruparItemSelecionados()} className="btn-outline-danger text-nowrap d-none d-sm-block" onClick={this.handleClickDesagrupar}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-collection-fill" viewBox="0 0 16 16">
                            <path d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"/>
                        </svg> Desagrupar
                    </BtnButton>
                    
                    <Link to="/CadProdutoServico" className="btn btn-outline-primary text-nowrap">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg> Produto / Serviço
                    </Link>
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row mb-3 g-3">                        
                        <SelectCategoriaProdutoServico showBtnNewForm={false}  md={3} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <SelectTipoProdutoServicoByCategoria showBtnNewForm={false} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} md={4} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        {this.state.model.idTipoProdutoServico ? 
                            <SelectProdutoServicoByTipoAgrupador showBtnNewForm={false} idTipoProdutoServico={this.state.model.idTipoProdutoServico} md={5} label="Agrupador PAI" placeholder="Selecione o Produto Agrupador" name="idProdutoServicoPai" value={this.state.model.idProdutoServicoPai} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false}/>
                            :
                            <SelectProdutoServicoAgrupador showBtnNewForm={false} md={5} label="Agrupador PAI" placeholder="Selecione o Produto Agrupador" name="idProdutoServicoPai" value={this.state.model.idProdutoServicoPai} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false}/>
                        }
                        <SelectOrdemTipoUsuario showBtnNewForm={false} md={3} label="Tipo da Ordem" required placeholder="Selecione o Tipo da Ordem" name="idOrdemTipo" value={this.state.model.idOrdemTipo} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                        <BtnGroup md={3} className="w-100" name="flAtivo" value={this.state.model.flAtivo} label="Status do Item" options={this.state.filtroAtivo} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <BtnGroup md={4} className="w-100" name="flAgrupador" value={this.state.model.flAgrupador} label="Agrupramento" options={this.state.filtroAgrupador} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <Select label="Registros por Página" md={2} value={this.state.model.rowCount} required={true} options={this.state.filtroRowCount} name="rowCount" onChange={(e) => { this.handleChange(e, this.onSubmit) }}></Select>
                        
                    </div>

                    <div className="row gap-2 mb-3">
                        <div className="col">
                            <div className="col-md-12 input-group mb-3">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite o nome de um produto" value={this.state.model.busca} onChange={this.handleChange} />
                                <button className="btn btn-outline-primary" type="submit"> Buscar </button>
                            </div>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-danger" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </button>
                        </div>
                    </div>
                </form>

                <div className="table-responsive">
                    <table className="table table-sm table-hover caption-top">
                        <caption className="text-end">
                            {this.state.data.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th className="col"> 
                                    <input type="checkbox" checked={this.state.produtoServicos.length === this.state.data.length} className="form-check-input form-check-input-size-20" onChange={this.handleCheckAllItem} />
                                </th>
                                <th className="text-center d-none d-md-table-cell"> Código </th>
                                <th className="col-md-6"> Nome </th>
                                <th className="col-md-1 d-none d-md-table-cell text-center text-nowrap"> Fotos </th>
                                <th className="col-md-1 d-none d-md-table-cell text-center text-nowrap"> Peso </th>
                                <th className="col-md-1 d-none d-md-table-cell text-end"> Preço </th>
                                <th className="col-md-1 d-none d-md-table-cell text-center"> Estoque </th>
                                <th className="d-none d-md-table-cell text-center"> Medida </th>
                                <th className="text-end">
                                    <button type="button" className="btn btn-link" onClick={this.handleClickExpand}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-caret-down-fill ${this.state.expand ? 'd-none' : ''}`} viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-caret-left-fill ${this.state.expand ? '' : 'd-none'}`} viewBox="0 0 16 16">
                                            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                                        </svg>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item) => (
                                <tr key={item.idProdutoServico} className={`${Boolean(item.flAtivo) ? '' : 'bg-danger bg-opacity-25'}`}>
                                    <td className="align-middle text-center text-nowrap d-none d-md-table-cell">
                                        <input type="checkbox" className="form-check-input form-check-input-size-20" checked={this.state.produtoServicos.findIndex(o => parseInt(o) === parseInt(item.idProdutoServico)) !== -1} onChange={(e) => { this.handleCheckItem(e, item) }}/>
                                    </td>
                                    <td className="text-center d-none d-md-table-cell">{item.idProdutoServico}</td>
                                    <td>
                                        {item.nome}
                                        {this.state.expand ?
                                        <>
                                            <br />
                                            <small className="text-secondary">
                                                {item.nomeCategoriaProdutoServico} - {item.nomeTipoProdutoServico} {Boolean(item.flAtivo) ? <></> : <span className="badge bg-danger">INATIVO</span>}
                                            </small>
                                            {item.idProdutoServicoPai ? 
                                                <><br />
                                                    <Link to={`/CadProdutoServico/${item.idProdutoServicoPai}`} className="link-underline-light" title="Editar item">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-align-top text-success" viewBox="0 0 16 16">
                                                            <rect width="4" height="12" rx="1" transform="matrix(1 0 0 -1 6 15)"/>
                                                            <path d="M1.5 2a.5.5 0 0 1 0-1v1zm13-1a.5.5 0 0 1 0 1V1zm-13 0h13v1h-13V1z"/>
                                                        </svg> {item.nomeProdutoServicoPai}
                                                    </Link>
                                                </> 
                                            : <></>}
                                        </> : <></>}
                                        {Boolean(item.flAgrupador) ? 
                                            <><br />
                                                <BtnButton className="btn-link" title="Editar item" onClick={e => this.handleClickVerAgrupados(item.idProdutoServico)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-align-top text-success" viewBox="0 0 16 16">
                                                        <rect width="4" height="12" rx="1" transform="matrix(1 0 0 -1 6 15)"/>
                                                        <path d="M1.5 2a.5.5 0 0 1 0-1v1zm13-1a.5.5 0 0 1 0 1V1zm-13 0h13v1h-13V1z"/>
                                                    </svg> {item.produtoServicoFilho} Item(ns) Agrupados
                                                </BtnButton>
                                            </> 
                                        : <></>}
                                    </td>
                                    <td className="d-none d-md-table-cell text-center">{item.produtoServicoArquivos} </td>
                                    <td className="d-none d-md-table-cell text-end">{item.vlPesoBruto > 0 ? Util.floatToPeso(item.vlPesoBruto) + ' KG' : ''} </td>
                                    <td className="d-none d-md-table-cell text-end">{Util.floatToReais(item.vlPreco)}</td>
                                    <td className="d-none d-md-table-cell text-center fs-5">
                                        {this.getValueEstoque(item)}
                                    </td>
                                    <td className="d-none d-md-table-cell text-center">
                                        <span className="badge bg-primary w-100">{item.siglaUnidadeMedida}</span>
                                    </td>
                                    <td className="align-middle text-end d-flex flex-nowrap gap-1">
                                        <Link to={`/CadProdutoServico/copy/${item.idProdutoServico}`} className="btn btn-outline-info" title="Copiar item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                            </svg>
                                        </Link>
                                        <Link to={`/CadProdutoServico/${item.idProdutoServico}`} className="btn btn-outline-primary" title="Editar item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </Link>
                                        <button type="button" className="btn btn-outline-danger" onClick={() => { this.handleClickExcluir(item) }} title="Excluir item">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>
                                        {
                                            Permissao.getInstance().getPermissoes([37, 38]) ?
                                                <div className="dropdown dropstart">
                                                    <button className="btn btn-outline-dark" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={!item.flControlaEstoque}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                        </svg>
                                                    </button>

                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        {Permissao.getInstance().getPermissao(37) ?
                                                            <li>
                                                                <button type="button" className="dropdown-item" onClick={() => { this.handleOpenModalEntrada(item) }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-plus" viewBox="0 0 16 16">
                                                                        <path d="M9 5.5a.5.5 0 0 0-1 0V7H6.5a.5.5 0 0 0 0 1H8v1.5a.5.5 0 0 0 1 0V8h1.5a.5.5 0 0 0 0-1H9V5.5z" />
                                                                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg> Entrada
                                                                </button>
                                                            </li>
                                                            : <></>
                                                        }
                                                        {Permissao.getInstance().getPermissao(38) ?
                                                            <li>
                                                                <button type="button" className="dropdown-item" href="#" onClick={() => { this.handleOpenModalInventario(item) }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-check" viewBox="0 0 16 16">
                                                                        <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                                                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg> Inventário
                                                                </button>
                                                            </li>
                                                            : <></>
                                                        }
                                                    </ul>
                                                </div>
                                                : <></>
                                        }
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="text-center pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>

                <ModalConfirmacao id="modal-delete" confirm={this.excluir} />
            </div >
        )
    }
}