import React from "react";
import UnidadeMedidaService from "../../services/UnidadeMedidaService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaUnidadeMedida extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: UnidadeMedidaService,
            labels: {
                title: 'Lista de Unidade de Medida(s)',
                btnNewRegister: 'UnidadeMedida',
            },
            keyItem: 'idUnidadeMedida',
            urlForm: '/CadUnidadeMedida',
            itensFilter: ["idUnidadeMedida", "nome", "nrCasasDecimais"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-4"> Nome </th>
                <th className="col-md-3"> Sigla </th>
                <th className="col-md-3 text-center"> Casas Decimais </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idUnidadeMedida}</td>
                <td>{item.nome}</td>
                <td>{item.sigla}</td>
                <td className="text-center">{item.nrCasasDecimais}</td>
            </>);
    }

    render() {
        return super.render();
    }
}