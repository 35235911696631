import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import EstadoService from "../../services/EstadoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectPais from "../../components/app/SelectPais";
import SelectRepresentante from "../../components/app/SelectRepresentante";

class FormEstado extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idEstado: "",
            idPais: props.idPais || "",
            nome: "",
            sigla: "",
            idRepresentante : "",
        };

        this.state.crud = {
            service: EstadoService,
            labels: {
                title: 'Estado',
            },
            urlList: '/LstEstado',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.props.idPais) {
            this.setModel({ idPais: this.props.idPais });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={3} value={this.state.model.idEstado} name="idEstado" readOnly opcionalLabel={false}/>
                <SelectPais label="Nome do País" autoFocus required placeholder="Selecione o País" name="idPais" md={9} value={this.state.model.idPais} onChange={this.handleChange} />
                <InputText label="Nome do Estado" required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                <InputText label="Sigla" required md={3} value={this.state.model.sigla} name="sigla" onChange={this.handleChange} maxLength={3} />
                <SelectRepresentante md={12} label="Representante" placeholder="Selecione o Representante da Abrangência" name="idRepresentante" value={this.state.model.idRepresentante} onChange={this.handleChange} />

                {/*<SelectGeneric controller="Pais" label="Nome do País" autoFocus required placeholder="Selecione o País" name="idPais" md={9} value={this.state.model.idPais} onChange={this.handleChange} />
                <SelectGeneric controller="Estado" getOptionsWhen={((f) => parseInt(f.idPais) > 0)} filter={{idPais : parseInt(this.state.model.idPais)}} label="Nome do Estado" required placeholder="Selecione o País" name="idEstado" md={9} value={this.state.model.idEstado} onChange={this.handleChange} />*/}
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormEstado {...props} navigate={navigate} params={params} />
}

export default With