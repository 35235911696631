import { Component } from "react";
import FormMask from "../base/FormMask";

export default class InputCartaoNumero extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        return (
            <FormMask {...this.props}
                style={{ textTransform: "uppercase" }}
                onChange={this.handleChange}
                mask="9999 9999 9999 9999"
                pattern="\d{4} \d{4} \d{4} \d{4}"
                placeholder="0000 0000 0000 0000"
            />);
    }
}