import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import MyComponent from "../../components/custom/MyComponent";
import CronExecucaoService from "../../services/CronExecucaoService";
import Util from "../../utils/Util";


class ListaCronExecucaoHistorico extends MyComponent {

    constructor(props) {
        super(props);

        this.getLista = this.getLista.bind(this);

        this.state.data = [];
        this.state.nrTimeRefresh = props?.nrTimeRefresh ? props.nrTimeRefresh : 60;
        this.state.model = {
            idCron: props.idCron,
            dtExecucaoStart: props.dtExecucaoStart,
            dtExecucaoEnd: props.dtExecucaoEnd
        };

        this.timeout = null;
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearTimeout(this.timeout);
    }

    getLista() {
        CronExecucaoService.listForList(this.state.model, (response) => {
            this.setState({ data: response }, () => {
                this.timeout = setTimeout(() => { this.getLista(false) }, (this.state.nrTimeRefresh * 1000));
            });
        });
    }

    getRenderHeader() {
        return (
            <>
                <th className="col"> </th>
                <th className="col"> # </th>
                <th className="col-md-2"> Nome </th>
                <th className="col-md-1 text-center"> Status </th>
                <th className="col-md-1 text-center"> Execução </th>
                <th className="col-md-1 text-center"> Iniciado </th>
                <th className="col-md-1 text-center"> Finalizado </th>
                <th className="col-md-1 text-center"> Tempo </th>
                <th className="col-md-2"> Mensagem </th>
                <th className="col-md-3"> Body </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td>
                    {Boolean(item.flIniciado) && !Boolean(item.flFinalizado) ?
                        <div className="spinner-grow spinner-grow-sm text-success" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        : <></>}
                </td>
                <td>{item.idCronExecucao}</td>
                <td>
                    <strong>{item.nomeCron}</strong><br />
                    <span className="text-primary d-none d-md-table-cell">{item.url}</span>
                </td>
                <td className="text-center">
                    {!Boolean(item.flIniciado) ? <span className="badge bg-warning w-100">NÃO INICIADO</span> : <></>}
                    {Boolean(item.flIniciado) && Boolean(item.flSucesso) ? <span className="badge bg-success w-100"> {item.nrCodigo} - SUCESSO</span> : <></>}
                    {Boolean(item.flIniciado) && Boolean(item.flErro) ? <span className="badge bg-danger w-100">{item.nrCodigo} - ERRO</span> : <></>}
                </td>
                <td className="text-center">{Util.date2Br(item.dataExecucao)} <br />{item.horaExecucao}</td>
                <td className="text-center">{Util.date2Br(item.dataIniciado)} <br />{item.horaIniciado}</td>
                <td className="text-center">{Util.date2Br(item.dataFinalizado)} <br />{item.horaFinalizado}</td>
                <td className="text-center">{Boolean(item.flIniciado) && Boolean(item.flFinalizado) ? item.nrTempoExecucao + 's' : ''}</td>
                <td>{item.dsErro}</td>
                <td>{item.dsConteudo}</td>
            </>);
    }

    render() {
        return (
            <>
                <div className="table-responsive-sm mt-2">
                    <table className="table table-sm caption-top table-hover table-striped">
                        <caption className="text-end">
                            <div className="hstack d-none d-lg-block">
                                <div className="hstack gap-2 w-100">
                                    <div className="ms-auto">
                                        {this.state.data.length} Registro(s)
                                    </div>
                                </div>
                            </div>
                        </caption>
                        <thead>
                            <tr>
                                {this.getRenderHeader()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <tr key={index}>
                                    {this.getRenderItem(item, index)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <ListaCronExecucaoHistorico {...props} navigate={navigate} params={params} />
}

export default With