import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import AusenciaService from "../../services/AusenciaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import CategoriaProdutoServicoService from "../../services/CategoriaProdutoServicoService";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputDate from "../../components/forms/elements/InputDate";
import InputTime from "../../components/forms/elements/InputTime";
import TextArea from "../../components/forms/elements/TextArea";

class FormAusencia extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeCategoriaProdutoServicos = this.handleChangeCategoriaProdutoServicos.bind(this);
        this.handleChangeCategoriaProdutoServicosAll = this.handleChangeCategoriaProdutoServicosAll.bind(this);

        this.state.model = {
            idAusencia: "",
            nome: "",
            dsMensagem: "",
            dtInicio: "",
            hrInicio: "00:00",
            dtFim: "",
            hrFim: "23:59",
            flIncluirHorarioComercial : true,
            flApresentaVigencia : true,
            ausenciaCategoriaProdutoServico: [],
        };

        this.state.crud = {
            service: AusenciaService,
            labels: {
                title: 'Ausência',
            },
            urlList: '/LstAusencia',
        };

        this.state.categoriaProdutoServicos = [];
    }

    componentDidMount() {
        super.componentDidMount();

        CategoriaProdutoServicoService.list((response) => {
            this.setState({ categoriaProdutoServicos: response });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeCategoriaProdutoServicos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.ausenciaCategoriaProdutoServico.push(item);
                return state;
            });
        } else {
            var index = this.state.model.ausenciaCategoriaProdutoServico.findIndex(i => parseInt(i.idCategoriaProdutoServico) === parseInt(item.idCategoriaProdutoServico));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.ausenciaCategoriaProdutoServico.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeCategoriaProdutoServicosAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.ausenciaCategoriaProdutoServico = [];

                this.state.categoriaProdutoServicos.forEach(function (item) {
                    state.model.ausenciaCategoriaProdutoServico.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.ausenciaCategoriaProdutoServico = [];
                return state;
            });
        }
    }

    getRenderFields() {
        return (
            <div>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idAusencia} name="idAusencia" readOnly opcionalLabel={false} />
                    <InputText label="Nome da Ausência" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <TextArea label="Mensagem" md={12} value={this.state.model.dsMensagem} required name="dsMensagem" onChange={this.handleChange} rows={5}/>
                    <InputDate md={2} label="Data Início" required value={this.state.model.dtInicio} name="dtInicio" onChange={this.handleChange} />
                    <InputTime md={2} label="Hora Início" required value={this.state.model.hrInicio} name="hrInicio" onChange={this.handleChange} />
                    <InputDate md={2} label="Data Fim" required value={this.state.model.dtFim} name="dtFim" onChange={this.handleChange} />
                    <InputTime md={2} label="Hora Fim" required value={this.state.model.hrFim} name="hrFim" onChange={this.handleChange} />
                </div>
                <div className="row mx-1 mb-3">
                    <InputCheckSwitch sizing="md" label="Incluir horário comercial?" name="flIncluirHorarioComercial" checked={Boolean(this.state.model.flIncluirHorarioComercial)} onChange={this.handleChange} />
                    <InputCheckSwitch sizing="md" label="Apresenta Vigência?" name="flApresentaVigencia" checked={Boolean(this.state.model.flApresentaVigencia)} onChange={this.handleChange} />
                </div>
                <hr className="hr" />
                <div>
                    <h6> Categorias que estarão em período de ausência: </h6>
                    <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeCategoriaProdutoServicosAll} checked={this.state.model.ausenciaCategoriaProdutoServico.length === this.state.categoriaProdutoServicos.length} />
                    <div className="row mb-3">
                        {this.state.categoriaProdutoServicos.map((item) => (
                            <div key={item.idCategoriaProdutoServico} className="col-md-6">
                                <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.ausenciaCategoriaProdutoServico.findIndex(i => parseInt(i.idCategoriaProdutoServico) === parseInt(item.idCategoriaProdutoServico)) !== -1} onChange={(e) => { this.handleChangeCategoriaProdutoServicos(e, item) }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormAusencia {...props} navigate={navigate} params={params} />
}

export default With