import Http from "../controllers/Http";

export default class MensagemService {

    static urlBase = "/mensagem/";

    static getMensagemByCobranca(canal, idCobranca, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + canal + '/' + idCobranca)
            .then(fnSucess)
            .catch(fnError);
    }
}
