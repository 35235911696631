import React from "react";
import GrupoModuloService from "../../services/GrupoModuloService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaGrupoModulo extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: GrupoModuloService,
            labels: {
                title: 'Lista de Grupo(s) Módulo(s)',
                btnNewRegister: 'Grupo Módulo',
            },
            keyItem: 'idGrupoModulo',
            urlForm: '/CadGrupoModulo',
            itensFilter: ["idGrupoModulo", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-10"> Nome </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idGrupoModulo}</td>
                <td>{item.nome}</td>
            </>);
    }

    render() {
        return super.render();
    }
}