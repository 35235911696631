import React, { Component } from "react";
import { toast } from "react-toastify";
import EventBus from "../../controllers/EventBus";

export default class ToastBusContainer extends Component {

    componentDidMount() {
        /*EventBus.on("response-error-400", (error) => {
            toast.error('Erro do Servidor:' + (error.response?.data?.message ? error.response.data.message : ""));
        });*/

        EventBus.on("response-error-not-status", (error) => {
            toast.warn('Algo deu errado na sua solicitação, verifique sua conexão de internet!', {
                theme: "colored"
            });
        });

        EventBus.on("response-error-timeout", (error) => {
            toast.error('A solitação demorou muito para responder, o tempo máximo é de: ' + error.timeout + ' segundos', {
                theme: "colored"
            });
        });

        EventBus.on("network-offLine", (error) => {
            toast.warn('Verifique sua conexão de internet!', {
                theme: "colored"
            });
        });
    }

    render() {
        return (<></>)
    }
}