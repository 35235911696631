import RepresentanteService from "../../services/RepresentanteService";
import SelectRepresentante from "./SelectRepresentante";

export default class SelectRepresentanteByProprietarioAndUsuario extends SelectRepresentante {

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.idProprietario !== this.props.idProprietario) {
            this.getOptions();
        }
    }

    getOptions(callBack) {
        if (this.props.idProprietario) {
            var ativo = this.props.ativo === undefined ? null : this.props.ativo;
            RepresentanteService.getForComboByProprietarioAndUsuario(this.props.idProprietario, ativo, (response) => {
                if (!this.state.isComponentMounted) return;
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();
                    }
                });

            });

        } else {
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    render() {
        return super.render();
    }
}