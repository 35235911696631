import CategoriaProdutoServicoService from "../../services/CategoriaProdutoServicoService";
import SelectCategoriaProdutoServico from "./SelectCategoriaProdutoServico";

export default class SelectCategoriaProdutoServicoByUsuarioComRemap extends SelectCategoriaProdutoServico {

    getOptions(callBack) {
        CategoriaProdutoServicoService.getForComboByUsuario((response) => {
            response = response.filter(r => parseInt(r.flTemRemap) === 1);
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}