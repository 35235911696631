import React from "react";
import InputMask from 'react-input-mask';
import FormField from "./FormField";

export default class FormMask extends FormField {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.state.type = "text";
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(event) {
        super.handleChange(event);
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    handleBlur(event) {
        super.handleBlur(event);
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    render() {
        var className = `form-control ${this.props.className ? this.props.className : ""} ${this.props.sizing ? "form-control-" + this.props.sizing : ""}`;

        var render =
            <InputMask
                {...this.getProps(this.props)}
                ref={this.state.ref}
                inputRef={(e) => { this.setState({ error: this.hasError(e) }) }}
                mask={this.props.mask}
                className={className}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
            />;

        return this.getRenderFormControl(render);
    }
}