import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import BancoService from "../../services/BancoService";
import CrudForm from "../../components/forms/custom/CrudForm";

class FormBanco extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idBanco: "",
            nome: "",
            nrBanco: ""
        };

        this.state.crud = {
            service: BancoService,
            labels: {
                title: 'Banco',
            },
            urlList: '/LstBanco',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={3} value={this.state.model.idBanco} name="idBanco" readOnly opcionalLabel={false} />
                <InputText label="Nome da Banco" autoFocus={true} required md={6} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                <InputText label="Número da Banco" required md={3} value={this.state.model.nrBanco} name="nrBanco" onChange={this.handleChange} maxLength={3} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormBanco {...props} navigate={navigate} params={params} />
}

export default With