import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import BaixaService from "../../services/BaixaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import CobrancaService from "../../services/CobrancaService";
import InputDate from "../../components/forms/elements/InputDate";

class FormCobrancaAgendamento extends CrudForm {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.getById = this.getById.bind(this);

        this.state.model = {
            idCobranca: "",
            dtVencimento: "",
            dtAgendamento: "",
            vlCobranca: 0
        };

        this.state.cobranca = {
            idCobranca: "",
            vlCobranca: 0,
            vlPago: 0,
            vlAberto: 0,
            vlBaixa: 0,
            vlDesconto: 0,
            idTipoBaixa: "",
            dtVencimento: "",
            dtAgendamento: "",
        }

        this.state.crud = {
            service: BaixaService,
            labels: {
                title: 'Baixa',
            },
            urlList: '/LstBaixa',
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getById();
    }

    getById() {
        if (this.props.idCobranca) {
            CobrancaService.getOneById(this.props.idCobranca, (data) => {
                this.setState({ cobranca: data });
                this.setState(state => {
                    state.model.idCobranca = data.idCobranca;
                    state.model.vlCobranca = data.vlCobranca;
                    state.model.dtVencimento = data.dtVencimento;
                    state.model.dtAgendamento = (data?.dtAgendamento && data.dtAgendamento?.toString().length > 0) ? data.dtAgendamento : data.dtVencimento;
                    return state;
                });
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit() {
        CobrancaService.createAgendamento(this.props.idCobranca, this.state.model, (response) => {
            this.onSave(response);
        });
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={4} value={this.state.cobranca.idCobranca} name="idCobranca" disabled required={true} />
                    <InputText className="text-end text-primary fw-bold" label="Valor da Cobrança" md={4} value={Util.floatToReais(this.state.cobranca.vlCobranca)} disabled opcionalLabel={false} />
                    <InputText className="text-end text-success fw-bold" label="Valor Pago" md={4} value={Util.floatToReais(this.state.cobranca.vlPago)} disabled opcionalLabel={false} />
                    <InputText className="text-end text-danger fw-bold" label="Valor à Pagar" md={4} value={Util.floatToReais(this.state.cobranca.vlAberto)} disabled opcionalLabel={false} />
                    <InputText label="Data de Vencimento" md={4} value={Util.date2Br(this.state.cobranca.dtVencimento)} disabled required={true} />
                    <InputDate md={4} required label="Data Agendamento" value={this.state.model.dtAgendamento} name="dtAgendamento" onChange={this.handleChange} autoFocus={true} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCobrancaAgendamento {...props} navigate={navigate} params={params} />
}

export default With