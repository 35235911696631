import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home/Home";

import Layout from "../pages/Layout";
import RequireAuth from "./RequireAuth";

import FormRepresentante from "../pages/Representante/FormRepresentante";
import ListaRepresentante from "../pages/Representante/ListaRepresentante";

import Painel from "../pages/Painel/Painel";

import FormLogin from "../pages/Login/FormLogin";
import FormPais from "../pages/Pais/FormPais";
import ListaPais from "../pages/Pais/ListaPais";
import FormEstado from "../pages/Estado/FormEstado";
import ListaEstado from "../pages/Estado/ListaEstado";
import FormCidade from "../pages/Cidade/FormCidade";
import ListaCidade from "../pages/Cidade/ListaCidade";
import FormSenha from "../pages/Usuario/FormSenha";

import FormUsuario from "../pages/Usuario/FormUsuario";
import ListaUsuario from "../pages/Usuario/ListaUsuario";
import FormConfiguracao from "../pages/Configuracao/FormConfiguracao";
import ListaConfiguracao from "../pages/Configuracao/ListaConfiguracao";
import BuscaRepresentante from "../pages/Representante/BuscaRepresentante";
import ListaContato from "../pages/Contato/ListaContato";
import FormContato from "../pages/Contato/FormContato";
import ListaServicoContato from "../pages/ServicoContato/ListaServicoContato";
import FormServicoContato from "../pages/ServicoContato/FormServicoContato";
import FormPerfil from "../pages/Perfil/FormPerfil";
import ListaPerfil from "../pages/Perfil/ListaPerfil";
import FormModulo from "../pages/Modulo/FormModulo";
import ListaModulo from "../pages/Modulo/ListaModulo";
import FormGrupoModulo from "../pages/GrupoModulo/FormGrupoModulo";
import ListaGrupoModulo from "../pages/GrupoModulo/ListaGrupoModulo";
import FormProprietario from "../pages/Proprietario/FormProprietario";
import ListaProprietario from "../pages/Proprietario/ListaProprietario";
import FormOrdem from "../pages/Ordem/FormOrdem";
import ListaOrdem from "../pages/Ordem/ListaOrdem";
import ListaNotificacao from "../pages/Notificacao/ListaNotificacao";
import ListaCategoriaProdutoServico from "../pages/CategoriaProdutoServico/ListaCategoriaProdutoServico";
import FormCategoriaProdutoServico from "../pages/CategoriaProdutoServico/FormCategoriaProdutoServico";
import ListaUnidadeMedida from "../pages/UnidadeMedida/ListaUnidadeMedida";
import FormUnidadeMedida from "../pages/UnidadeMedida/FormUnidadeMedida";
import ListaTipoProdutoServico from "../pages/TipoProdutoServico/ListaTipoProdutoServico";
import FormTipoProdutoServico from "../pages/TipoProdutoServico/FormTipoProdutoServico";
import ListaOrdemStatus from "../pages/OrdemStatus/ListaOrdemStatus";
import FormOrdemStatus from "../pages/OrdemStatus/FormOrdemStatus";
import ListaProdutoServico from "../pages/ProdutoServico/ListaProdutoServico";
import FormProdutoServico from "../pages/ProdutoServico/FormProdutoServico";
import ListaCombustivel from "../pages/Combustivel/ListaCombustivel";
import FormCombustivel from "../pages/Combustivel/FormCombustivel";
import ListaTransmissao from "../pages/Transmissao/ListaTransmissao";
import FormTransmissao from "../pages/Transmissao/FormTransmissao";
import ListaFatura from "../pages/Fatura/ListaFatura";
import ListaAcordo from "../pages/Fatura/ListaAcordo";
import FormFatura from "../pages/Fatura/FormFatura";
import FormFaturar from "../pages/Fatura/FormFaturar";
import FormAcordo from "../pages/Fatura/FormAcordo";
import ListaTipoBaixa from "../pages/TipoBaixa/ListaTipoBaixa";
import FormTipoBaixa from "../pages/TipoBaixa/FormTipoBaixa";
import ListaCategoriaBaixa from "../pages/CategoriaBaixa/ListaCategoriaBaixa";
import FormCategoriaBaixa from "../pages/CategoriaBaixa/FormCategoriaBaixa";
import ListaMarca from "../pages/Marca/ListaMarca";
import FormMarca from "../pages/Marca/FormMarca";
import ListaModelo from "../pages/Modelo/ListaModelo";
import FormModelo from "../pages/Modelo/FormModelo";
import ListaTipoEndereco from "../pages/TipoEndereco/ListaTipoEndereco";
import FormTipoEndereco from "../pages/TipoEndereco/FormTipoEndereco";
import ListaProdutoServicoEntrada from "../pages/ProdutoServicoEntrada/ListaProdutoServicoEntrada";
import FormProdutoServicoEntrada from "../pages/ProdutoServicoEntrada/FormProdutoServicoEntrada";
import ListaProdutoServicoInventario from "../pages/ProdutoServicoInventario/ListaProdutoServicoInventario";
import FormProdutoServicoInventario from "../pages/ProdutoServicoInventario/FormProdutoServicoInventario";
import ListaRemap from "../pages/Ordem/ListaRemap";
import ListaPreco from "../pages/ProdutoServico/ListaPreco";
import ListaBanco from "../pages/Banco/ListaBanco";
import FormBanco from "../pages/Banco/FormBanco";
import ListaConta from "../pages/Conta/ListaConta";
import FormConta from "../pages/Conta/FormConta";
import ListaOrdemTipo from "../pages/OrdemTipo/ListaOrdemTipo";
import FormOrdemTipo from "../pages/OrdemTipo/FormOrdemTipo";
import ListaFornecedor from "../pages/Fornecedor/ListaFornecedor";
import FormFornecedor from "../pages/Fornecedor/FormFornecedor";
import HomeRelatorio from "../pages/Relatorio/HomeRelatorio";
import ListaEnderecoAtualizacao from "../pages/Endereco/ListaEnderecoAtualizacao";
import ListaProdutoServicoClassificacao from "../pages/ProdutoServicoClassificacao/ListaProdutoServicoClassificacao";
import FormProdutoServicoClassificacao from "../pages/ProdutoServicoClassificacao/FormProdutoServicoClassificacao";
import ListaAreaAtuacao from "../pages/AreaAtuacao/ListaAreaAtuacao";
import FormAreaAtuacao from "../pages/AreaAtuacao/FormAreaAtuacao";
import ListaExtrato from "../pages/Extrato/ListaExtrato";
import ListaTipoChavePix from "../pages/TipoChavePix/ListaTipoChavePix";
import FormTipoChavePix from "../pages/TipoChavePix/FormTipoChavePix";
import FormPagar from "../pages/Pagamento/FormPagar";
import FormCobrancaPessoa from "../pages/Pagamento/FormCobrancaPessoa";
import FormCartaoCredito from "../pages/Pagamento/FormCartaoCredito";
import FormPIX from "../pages/Pagamento/FormPIX";
import FormBoleto from "../pages/Pagamento/FormBoleto";
import FormCartaoDebito from "../pages/Pagamento/FormCartaoDebito";
import ListaModalidadeFrete from "../pages/ModalidadeFrete/ListaModalidadeFrete";
import FormModalidadeFrete from "../pages/ModalidadeFrete/FormModalidadeFrete";
import ListaParametro from "../pages/Parametro/ListaParametro";
import FormParametro from "../pages/Parametro/FormParametro";
import ListaLoja from "../pages/ProdutoServico/ListaLoja";
import ListaContatoStatus from "../pages/ContatoStatus/ListaContatoStatus";
import FormContatoStatus from "../pages/ContatoStatus/FormContatoStatus";
import ListaContatoTipo from "../pages/ContatoTipo/ListaContatoTipo";
import FormContatoTipo from "../pages/ContatoTipo/FormContatoTipo";
import ListaContatoMotivo from "../pages/ContatoMotivo/ListaContatoMotivo";
import FormContatoMotivo from "../pages/ContatoMotivo/FormContatoMotivo";
import ListaPromocaoCampanha from "../pages/PromocaoCampanha/ListaPromocaoCampanha";
import FormPromocaoCampanha from "../pages/PromocaoCampanha/FormPromocaoCampanha";
import ListaProdutoServicoTipoObjeto from "../pages/ProdutoServicoTipoObjeto/ListaProdutoServicoTipoObjeto";
import FormProdutoServicoTipoObjeto from "../pages/ProdutoServicoTipoObjeto/FormProdutoServicoTipoObjeto";
import ListaPlanoRecorrencia from "../pages/PlanoRecorrencia/ListaPlanoRecorrencia";
import FormPlanoRecorrencia from "../pages/PlanoRecorrencia/FormPlanoRecorrencia";
import ListaPlanoPagamento from "../pages/PlanoPagamento/ListaPlanoPagamento";
import FormPlanoPagamento from "../pages/PlanoPagamento/FormPlanoPagamento";
import ListaMuralView from "../pages/Mural/ListaMuralView.js";
import ListaMural from "../pages/Mural/ListaMural.js";
import FormMural from "../pages/Mural/FormMural.js";
import ListaFeriado from "../pages/Feriado/ListaFeriado";
import FormFeriado from "../pages/Feriado/FormFeriado";
import ListaPainel from "../pages/Painel/ListaPainel";
import FormPainel from "../pages/Painel/FormPainel";
import ListaContatoOrigem from "../pages/ContatoOrigem/ListaContatoOrigem";
import FormContatoOrigem from "../pages/ContatoOrigem/FormContatoOrigem";
import ListaAviso from "../pages/Aviso/ListaAviso";
import FormAviso from "../pages/Aviso/FormAviso";
import ListaMonitor from "../pages/Monitor/ListaMonitor";
import ListaAusencia from "../pages/Ausencia/ListaAusencia";
import FormAusencia from "../pages/Ausencia/FormAusencia";
import ListaEquipamento from "../pages/Equipamento/ListaEquipamento";
import FormEquipamento from "../pages/Equipamento/FormEquipamento";
import ListaRepresentanteEquipamentoAtualizacao from "../pages/RepresentanteEquipamentoAtualizacao/ListaRepresentanteEquipamentoAtualizacao";
import FormRepresentanteEquipamentoAtualizacao from "../pages/RepresentanteEquipamentoAtualizacao/FormRepresentanteEquipamentoAtualizacao";
import ListaLiberacao from "../pages/Liberacao/ListaLiberacao";
import FormLiberacao from "../pages/Liberacao/FormLiberacao";
import ListaAcervo from "../pages/Acervo/ListaAcervo";
import ListaCron from "../pages/Cron/ListaCron";
import FormCron from "../pages/Cron/FormCron";
import ListaContrato from "../pages/Contrato/ListaContrato";
import FormContrato from "../pages/Contrato/FormContrato";
import FormRepresentanteContratoDados from "../pages/Contrato/FormRepresentanteContratoDados";
import ListaRepresentanteContratoAtualizacao from "../pages/RepresentanteEquipamentoAtualizacao/ListaRepresentanteContratoAtualizacao";
import ListaContratoStatus from "../pages/ContratoStatus/ListaContratoStatus";
import FormContratoStatus from "../pages/ContratoStatus/FormContratoStatus";
import ListaTag from "../pages/Tag/ListaTag";
import FormTag from "../pages/Tag/FormTag";

const AppRoutes = () => {

    return (
        <Routes>
            <Route path="/Login" element={<FormLogin />} />

            <Route path="/pagamento-:uid" element={<FormPagar />} />
            <Route path="/pessoa-:uid" element={<FormCobrancaPessoa />} />
            <Route path="/credito-:uid" element={<FormCartaoCredito />} />
            <Route path="/debito-:uid" element={<FormCartaoDebito />} />
            <Route path="/boleto-:uid" element={<FormBoleto />} />
            <Route path="/pix-:uid" element={<FormPIX />} />

            <Route path="/pagamento/:uid" element={<FormPagar />} />
            <Route path="/pessoa/:uid" element={<FormCobrancaPessoa />} />
            <Route path="/credito/:uid" element={<FormCartaoCredito />} />
            <Route path="/debito/:uid" element={<FormCartaoDebito />} />
            <Route path="/boleto/:uid" element={<FormBoleto />} />
            <Route path="/pix/:uid" element={<FormPIX />} />

            <Route path="Painel" element={<RequireAuth><Painel /></RequireAuth>} />
            <Route path="Painel/:id" element={<RequireAuth><Painel /></RequireAuth>} />

            <Route path="Monitor" element={<RequireAuth><ListaMonitor /></RequireAuth>} />

            <Route path="/" element={<RequireAuth><Layout /></RequireAuth>}>
                <Route index element={<Home />} />

                <Route path="CadUsuario" element={<FormUsuario />} />
                <Route path="CadUsuario/:id" element={<FormUsuario />} />
                <Route path="LstUsuario" element={<ListaUsuario />} />
                <Route path="AlterarSenha" element={<FormSenha />} />

                <Route path="CadPais" element={<FormPais />} />
                <Route path="CadPais/:id" element={<FormPais />} />
                <Route path="LstPais" element={<ListaPais />} />

                <Route path="CadServicoContato" element={<FormServicoContato />} />
                <Route path="CadServicoContato/:id" element={<FormServicoContato />} />
                <Route path="LstServicoContato" element={<ListaServicoContato />} />

                <Route path="CadEstado" element={<FormEstado />} />
                <Route path="CadEstado/:id" element={<FormEstado />} />
                <Route path="LstEstado" element={<ListaEstado />} />

                <Route path="CadCidade" element={<FormCidade />} />
                <Route path="CadCidade/:id" element={<FormCidade />} />
                <Route path="LstCidade" element={<ListaCidade />} />

                <Route path="CadRepresentante" element={<FormRepresentante />} />
                <Route path="CadRepresentante/:id" element={<FormRepresentante />} />
                <Route path="LstRepresentante" element={<ListaRepresentante />} />
                <Route path="BscRepresentante" element={<BuscaRepresentante />} />

                <Route path="CadContato" element={<FormContato />} />
                <Route path="CadContato/:id" element={<FormContato />} />
                <Route path="CadContato/:idRepresentante/:idCidade" element={<FormContato />} />
                <Route path="LstContato" element={<ListaContato />} />

                <Route path="CadConfiguracao" element={<FormConfiguracao />} />
                <Route path="CadConfiguracao/:id" element={<FormConfiguracao />} />
                <Route path="LstConfiguracao" element={<ListaConfiguracao />} />

                <Route path="CadPerfil" element={<FormPerfil />} />
                <Route path="CadPerfil/:id" element={<FormPerfil />} />
                <Route path="LstPerfil" element={<ListaPerfil />} />

                <Route path="CadModulo" element={<FormModulo />} />
                <Route path="CadModulo/:id" element={<FormModulo />} />
                <Route path="LstModulo" element={<ListaModulo />} />

                <Route path="CadGrupoModulo" element={<FormGrupoModulo />} />
                <Route path="CadGrupoModulo/:id" element={<FormGrupoModulo />} />
                <Route path="LstGrupoModulo" element={<ListaGrupoModulo />} />

                <Route path="CadProprietario" element={<FormProprietario />} />
                <Route path="CadProprietario/:id" element={<FormProprietario />} />
                <Route path="LstProprietario" element={<ListaProprietario />} />

                <Route path="CadOrdem" element={<FormOrdem />} />
                <Route path="CadOrdem/copy/:id" element={<FormOrdem isCopy={true} />} />
                <Route path="CadOrdem/:id" element={<FormOrdem />} />
                <Route path="LstOrdem" element={<ListaOrdem />} />
                <Route path="BscRemap" element={<ListaRemap />} />

                <Route path="CadCategoriaProdutoServico" element={<FormCategoriaProdutoServico />} />
                <Route path="CadCategoriaProdutoServico/:id" element={<FormCategoriaProdutoServico />} />
                <Route path="LstCategoriaProdutoServico" element={<ListaCategoriaProdutoServico />} />

                <Route path="CadUnidadeMedida" element={<FormUnidadeMedida />} />
                <Route path="CadUnidadeMedida/:id" element={<FormUnidadeMedida />} />
                <Route path="LstUnidadeMedida" element={<ListaUnidadeMedida />} />

                <Route path="CadTipoProdutoServico" element={<FormTipoProdutoServico />} />
                <Route path="CadTipoProdutoServico/:id" element={<FormTipoProdutoServico />} />
                <Route path="LstTipoProdutoServico" element={<ListaTipoProdutoServico />} />
                <Route path="LstPreco" element={<ListaPreco />} />
                <Route path="LstLoja" element={<ListaLoja />} />

                <Route path="CadProdutoServico" element={<FormProdutoServico />} />
                <Route path="CadProdutoServico/:id" element={<FormProdutoServico />} />
                <Route path="CadProdutoServico/copy/:id" element={<FormProdutoServico isCopy={true} />} />
                <Route path="LstProdutoServico" element={<ListaProdutoServico />} />

                <Route path="CadOrdemStatus" element={<FormOrdemStatus />} />
                <Route path="CadOrdemStatus/:id" element={<FormOrdemStatus />} />
                <Route path="LstOrdemStatus" element={<ListaOrdemStatus />} />

                <Route path="CadCombustivel" element={<FormCombustivel />} />
                <Route path="CadCombustivel/:id" element={<FormCombustivel />} />
                <Route path="LstCombustivel" element={<ListaCombustivel />} />

                <Route path="CadTransmissao" element={<FormTransmissao />} />
                <Route path="CadTransmissao/:id" element={<FormTransmissao />} />
                <Route path="LstTransmissao" element={<ListaTransmissao />} />

                <Route path="CadFatura" element={<FormFatura />} />
                <Route path="CadFatura/:id" element={<FormFatura />} />
                
                <Route path="Faturar" element={<FormFaturar />} />
                <Route path="Faturar/:idOrdem" element={<FormFaturar />} />

                <Route path="LstFatura" element={<ListaFatura />} />
                <Route path="LstAcordo" element={<ListaAcordo />} />
                <Route path="CadAcordo/:id" element={<FormAcordo />} />

                <Route path="CadTipoBaixa" element={<FormTipoBaixa />} />
                <Route path="CadTipoBaixa/:id" element={<FormTipoBaixa />} />
                <Route path="LstTipoBaixa" element={<ListaTipoBaixa />} />

                <Route path="CadCategoriaBaixa" element={<FormCategoriaBaixa />} />
                <Route path="CadCategoriaBaixa/:id" element={<FormCategoriaBaixa />} />
                <Route path="LstCategoriaBaixa" element={<ListaCategoriaBaixa />} />

                <Route path="CadMarca" element={<FormMarca />} />
                <Route path="CadMarca/:id" element={<FormMarca />} />
                <Route path="LstMarca" element={<ListaMarca />} />

                <Route path="CadModelo" element={<FormModelo />} />
                <Route path="CadModelo/:id" element={<FormModelo />} />
                <Route path="LstModelo" element={<ListaModelo />} />

                <Route path="CadProdutoServicoEntrada" element={<FormProdutoServicoEntrada />} />
                <Route path="CadProdutoServicoEntrada/:id" element={<FormProdutoServicoEntrada />} />
                <Route path="LstProdutoServicoEntrada" element={<ListaProdutoServicoEntrada />} />

                <Route path="CadProdutoServicoInventario" element={<FormProdutoServicoInventario />} />
                <Route path="CadProdutoServicoInventario/:id" element={<FormProdutoServicoInventario />} />
                <Route path="LstProdutoServicoInventario" element={<ListaProdutoServicoInventario />} />

                <Route path="CadBanco" element={<FormBanco />} />
                <Route path="CadBanco/:id" element={<FormBanco />} />
                <Route path="LstBanco" element={<ListaBanco />} />

                <Route path="CadConta" element={<FormConta />} />
                <Route path="CadConta/:id" element={<FormConta />} />
                <Route path="LstConta" element={<ListaConta />} />

                <Route path="CadTipoEndereco" element={<FormTipoEndereco />} />
                <Route path="CadTipoEndereco/:id" element={<FormTipoEndereco />} />
                <Route path="LstTipoEndereco" element={<ListaTipoEndereco />} />

                <Route path="CadTipoChavePix" element={<FormTipoChavePix />} />
                <Route path="CadTipoChavePix/:id" element={<FormTipoChavePix />} />
                <Route path="LstTipoChavePix" element={<ListaTipoChavePix />} />

                <Route path="LstEnderecoAtualizacao" element={<ListaEnderecoAtualizacao />} />

                <Route path="CadProdutoServicoClassificacao" element={<FormProdutoServicoClassificacao />} />
                <Route path="CadProdutoServicoClassificacao/:id" element={<FormProdutoServicoClassificacao />} />
                <Route path="LstProdutoServicoClassificacao" element={<ListaProdutoServicoClassificacao />} />

                <Route path="CadOrdemTipo" element={<FormOrdemTipo />} />
                <Route path="CadOrdemTipo/:id" element={<FormOrdemTipo />} />
                <Route path="LstOrdemTipo" element={<ListaOrdemTipo />} />

                <Route path="CadFornecedor" element={<FormFornecedor />} />
                <Route path="CadFornecedor/:id" element={<FormFornecedor />} />
                <Route path="LstFornecedor" element={<ListaFornecedor />} />

                <Route path="CadAreaAtuacao" element={<FormAreaAtuacao />} />
                <Route path="CadAreaAtuacao/:id" element={<FormAreaAtuacao />} />
                <Route path="LstAreaAtuacao" element={<ListaAreaAtuacao />} />

                <Route path="CadModalidadeFrete" element={<FormModalidadeFrete />} />
                <Route path="CadModalidadeFrete/:id" element={<FormModalidadeFrete />} />
                <Route path="LstModalidadeFrete" element={<ListaModalidadeFrete />} />

                <Route path="CadParametro" element={<FormParametro />} />
                <Route path="CadParametro/:id" element={<FormParametro />} />
                <Route path="LstParametro" element={<ListaParametro />} />

                <Route path="LstRelatorio" element={<HomeRelatorio />} />
                <Route path="Notificacoes" element={<ListaNotificacao />} />

                <Route path="LstExtrato" element={<ListaExtrato />} />

                <Route path="CadContatoStatus" element={<FormContatoStatus />} />
                <Route path="CadContatoStatus/:id" element={<FormContatoStatus />} />
                <Route path="LstContatoStatus" element={<ListaContatoStatus />} />

                <Route path="CadContatoTipo" element={<FormContatoTipo />} />
                <Route path="CadContatoTipo/:id" element={<FormContatoTipo />} />
                <Route path="LstContatoTipo" element={<ListaContatoTipo />} />

                <Route path="CadContatoMotivo" element={<FormContatoMotivo />} />
                <Route path="CadContatoMotivo/:id" element={<FormContatoMotivo />} />
                <Route path="LstContatoMotivo" element={<ListaContatoMotivo />} />

                <Route path="CadPromocaoCampanha" element={<FormPromocaoCampanha />} />
                <Route path="CadPromocaoCampanha/:id" element={<FormPromocaoCampanha />} />
                <Route path="LstPromocaoCampanha" element={<ListaPromocaoCampanha />} />

                <Route path="CadProdutoServicoTipoObjeto" element={<FormProdutoServicoTipoObjeto />} />
                <Route path="CadProdutoServicoTipoObjeto/:id" element={<FormProdutoServicoTipoObjeto />} />
                <Route path="LstProdutoServicoTipoObjeto" element={<ListaProdutoServicoTipoObjeto />} />

                <Route path="CadPlanoRecorrencia" element={<FormPlanoRecorrencia />} />
                <Route path="CadPlanoRecorrencia/:id" element={<FormPlanoRecorrencia />} />
                <Route path="LstPlanoRecorrencia" element={<ListaPlanoRecorrencia />} />

                <Route path="CadPlanoPagamento" element={<FormPlanoPagamento />} />
                <Route path="CadPlanoPagamento/:id" element={<FormPlanoPagamento />} />
                <Route path="LstPlanoPagamento" element={<ListaPlanoPagamento />} />

                <Route path="MuralAviso" element={<ListaMuralView />} />

                <Route path="CadMural" element={<FormMural />} />
                <Route path="CadMural/:id" element={<FormMural />} />
                <Route path="LstMural" element={<ListaMural />} />

                <Route path="CadFeriado" element={<FormFeriado />} />
                <Route path="CadFeriado/:id" element={<FormFeriado />} />
                <Route path="LstFeriado" element={<ListaFeriado />} />

                <Route path="CadPainel" element={<FormPainel />} />
                <Route path="CadPainel/:id" element={<FormPainel />} />
                <Route path="LstPainel" element={<ListaPainel />} />

                <Route path="CadContatoOrigem" element={<FormContatoOrigem />} />
                <Route path="CadContatoOrigem/:id" element={<FormContatoOrigem />} />
                <Route path="LstContatoOrigem" element={<ListaContatoOrigem />} />

                <Route path="CadAviso" element={<FormAviso />} />
                <Route path="CadAviso/:id" element={<FormAviso />} />
                <Route path="LstAviso" element={<ListaAviso />} />

                <Route path="CadAusencia" element={<FormAusencia />} />
                <Route path="CadAusencia/:id" element={<FormAusencia />} />
                <Route path="LstAusencia" element={<ListaAusencia />} />

                <Route path="CadEquipamento" element={<FormEquipamento />} />
                <Route path="CadEquipamento/:id" element={<FormEquipamento />} />
                <Route path="LstEquipamento" element={<ListaEquipamento />} />

                <Route path="CadRepresentanteEquipamentoAtualizacao" element={<FormRepresentanteEquipamentoAtualizacao />} />
                <Route path="CadRepresentanteEquipamentoAtualizacao/:id" element={<FormRepresentanteEquipamentoAtualizacao />} />
                <Route path="LstRepresentanteEquipamentoAtualizacao" element={<ListaRepresentanteEquipamentoAtualizacao />} />

                <Route path="LstRepresentanteContratoAtualizacao" element={<ListaRepresentanteContratoAtualizacao />} />

                <Route path="CadLiberacao" element={<FormLiberacao />} />
                <Route path="CadLiberacao/:id" element={<FormLiberacao />} />
                <Route path="LstLiberacao" element={<ListaLiberacao />} />

                
                <Route path="CadContrato" element={<FormContrato />} />
                <Route path="CadContrato/:id" element={<FormContrato />} />
                <Route path="LstContrato" element={<ListaContrato />} />
                
                <Route path="CadRepresentanteContratoDados/:id" element={<FormRepresentanteContratoDados />} />

                <Route path="CadCron" element={<FormCron />} />
                <Route path="CadCron/:id" element={<FormCron />} />
                <Route path="LstCron" element={<ListaCron />} />
                <Route path="CadCron/copy/:id" element={<FormCron isCopy={true} />} />

                <Route path="Biblioteca" element={<ListaAcervo />} />

                <Route path="CadContratoStatus" element={<FormContratoStatus />} />
                <Route path="CadContratoStatus/:id" element={<FormContratoStatus />} />
                <Route path="LstContratoStatus" element={<ListaContratoStatus />} />

                <Route path="CadTag" element={<FormTag />} />
                <Route path="CadTag/:id" element={<FormTag />} />
                <Route path="LstTag" element={<ListaTag />} />
            </Route>
        </Routes>
    )
}

export default AppRoutes;