import Auth from "../controllers/Auth";
import Http from "../controllers/Http";

export default class NotificacaoService {

    static urlBase = "/notificacao/";

    static getUnRead(fnSucess = () => { }, fnError = () => { }) {
        if (Auth.isAuthenticated()) {
            Http.getInstanceAppJson({ showLoadding: false })
                .get(this.urlBase + 'getUnRead')
                .then(fnSucess)
                .catch(fnError);
        }
    }

    static list(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson({ showLoadding: false })
            .get(this.urlBase)
            .then(fnSucess)
            .catch(fnError);
    }

    static changeStatus(idNotificacao, status, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson({ showLoadding: false })
            .post(this.urlBase + 'changeStatus/' + idNotificacao, { status: status })
            .then(fnSucess)
            .catch(fnError);
    }

    static changeAllRead(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson({ showLoadding: false })
            .get(this.urlBase + 'setAllRead')
            .then(fnSucess)
            .catch(fnError);
    }
}
