import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import TextArea from "../../components/forms/elements/TextArea";
import BtnButton from "../../components/forms/elements/BtnButton";
import UsuarioService from "../../services/UsuarioService";
import LoginService from "../../services/LoginService";

class FormToken extends CrudForm {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.getToken = this.getToken.bind(this);

        this.state.model = {
            idUsuario: props.idUsuario,
            token: ""
        };

        this.state.crud = {
            service: UsuarioService,
            labels: {
                title: 'TOken do Usuário',
            },
            urlList: '/LstModulo',
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getToken();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getToken() {
        LoginService.getToken(this.state.model.idUsuario, (response) => {
            this.setModel({ token: response.token });
        });
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Fechar </BtnButton>
            </div>);
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <TextArea autoFocus={true} col={12} label="Token" value={this.state.model.token} style={{ height: "300px" }} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormToken {...props} navigate={navigate} params={params} />
}

export default With