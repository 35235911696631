import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import Util from "../../utils/Util";
import FormMuralView from "./FormMuralView";
import EventBus from "../../controllers/EventBus";
import MuralService from "../../services/MuralService";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import { toast } from "react-toastify";
import BtnButton from "../../components/forms/elements/BtnButton";

export default class ListaMural extends MyComponent {

    constructor(props) {
        super(props);

        this.handleClickMensagem = this.handleClickMensagem.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.getLista = this.getLista.bind(this);
        this.state = this.getInitState();
    };

    getInitState(props) {
        return {
            data: [],
            model: {
                busca: "",
                filtroLeitura: "T",
                pagina: 0,
                rowCount: 50,
            },
            filtroLeitura: [
                { value: 'T', label: 'Todos' },
                { value: 'L', label: 'Lidas' },
                { value: 'N', label: 'Não lidas' },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event) {
        this.getLista();
    }

    getLista(merge = false) {
        if (merge) {
            MuralService.listView(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                MuralService.listView(this.state.model, (response) => {
                    this.setState({ data: response });
                });
            });
        }
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    handleClickMensagem(item) {

        this.setState(state => {
            EventBus.dispatch("modal-add", {
                title: 'Leitura Mural',
                sizeModal: 'modal-xl',
                config: {
                    isModal: true,
                    idMural: item.idMural
                },
                form: FormMuralView,
                onClose: () => {
                    //this.getLista();
                }
            });
        });

        item.flLeitura = 1;
        this.setState(item)
    }


    render() {
        return (
            <div className="p-3 my-2 container">
                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-envelope-paper" viewBox="0 0 16 16">
                            <path d="M4 0a2 2 0 0 0-2 2v1.133l-.941.502A2 2 0 0 0 0 5.4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.4a2 2 0 0 0-1.059-1.765L14 3.133V2a2 2 0 0 0-2-2H4Zm10 4.267.47.25A1 1 0 0 1 15 5.4v.817l-1 .6v-2.55Zm-1 3.15-3.75 2.25L8 8.917l-1.25.75L3 7.417V2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v5.417Zm-11-.6-1-.6V5.4a1 1 0 0 1 .53-.882L2 4.267v2.55Zm13 .566v5.734l-4.778-2.867L15 7.383Zm-.035 6.88A1 1 0 0 1 14 15H2a1 1 0 0 1-.965-.738L8 10.083l6.965 4.18ZM1 13.116V7.383l4.778 2.867L1 13.117Z" />
                        </svg> Mural de Avisos
                    </h4>
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >

                    <div className="row g-3 mb-3">
                        <BtnGroup md={3} sizing="sm" className="w-100" name="filtroLeitura" value={this.state.model.filtroLeitura} label="Status da Mensagem" options={this.state.filtroLeitura} onChange={(e) => { this.handleChange(e, this.getLista) }} opcionalLabel={false} />

                        <div className="col-md-12 input-group">
                            <span className="input-group-text" id="addon-wrapping">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                            <input type="text" className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite sua busca" value={this.state?.model?.busca} onChange={this.handleChange} />
                            <button className="btn btn-outline-primary" type="submit"> Buscar </button>
                        </div>

                    </div>

                </form>


                <div className="table-responsive">
                    <table className="table table-hover">
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <tr className={`${Boolean(item.flLeitura) ? 'table-light' : 'fw-bold'}`} key={index} onClick={e => this.handleClickMensagem(item)} style={{ cursor: "pointer" }}>
                                    <td className="col">
                                        {Boolean(item.flImportante) ?
                                            <span className="fs-6 text-danger">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                </svg>
                                            </span>
                                            : <></>
                                        }
                                    </td>
                                    <td className="col">
                                        {Boolean(item.flLeitura) ?
                                            <span className="fs-6 text-secondary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-open" viewBox="0 0 16 16">
                                                    <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z" />
                                                </svg>
                                            </span>
                                            :
                                            <span className="fs-6">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                                </svg>
                                            </span>
                                        }
                                    </td>
                                    <td className="col-4 text-primary">
                                        {item.nomeUsuario}
                                    </td>
                                    <td className="col-8 text-nowrap">
                                        {item.dsTitulo}
                                        <span className="ps-4 fw-normal fs-7 text-secondary">
                                            {Util.stringCut(item.dsMensagem, 120 - item.dsTitulo.length)}
                                        </span>
                                    </td>
                                    <td className="col text-nowrap">
                                        {Boolean(item.flTemAnexo) ?
                                            <span className="fs-6">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
                                                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                                                </svg>
                                            </span>
                                            : <></>
                                        }
                                    </td>
                                    <td className="col text-nowrap">
                                        <small>{Util.date2Br(item.dataMensagem)} {item.horaMensagem}</small>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="text-center pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>

            </div >
        );
    }
}