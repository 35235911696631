import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import InputNumber from "../../components/forms/elements/InputNumber";
import OrdemStatusService from "../../services/OrdemStatusService";
import CrudForm from "../../components/forms/custom/CrudForm";

class FormOrdemStatus extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idOrdemStatus: "",
            nome: "",
            nrOrdem: 0
        };

        this.state.crud = {
            service: OrdemStatusService,
            labels: {
                title: 'Ordem Status',
            },
            urlList: '/LstOrdemStatus',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={3} value={this.state.model.idOrdemStatus} name="idOrdemStatus" readOnly opcionalLabel={false} />
                <InputText label="Nome do Status" autoFocus={true} required md={6} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                <InputNumber label="Ordem de Apresentação" required md={3} value={this.state.model.nrOrdem} name="nrOrdem" onChange={this.handleChange} min={1} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormOrdemStatus {...props} navigate={navigate} params={params} />
}

export default With