import Http from "../controllers/Http";

export default class TransacaoService {

    static urlBase = "/transacao/";

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByUid(uid, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get('/public' + this.urlBase + uid)
            .then(fnSucess)
            .catch(fnError);
    }

    static getAllByCobranca(idCobranca, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byCobranca/' + idCobranca)
            .then(fnSucess)
            .catch(fnError);
    }

    static getStatus(uid, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson({ showLoadding: false })
            .get('/public' + this.urlBase + 'status/' + uid)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static pagarCartao(uid, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post('/public' + this.urlBase + 'cartao/' + uid, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static pagarPix(uid, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get('/public' + this.urlBase + 'pix/' + uid)
            .then(fnSucess)
            .catch(fnError);
    }

    static pagarBoleto(uid, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get('/public' + this.urlBase + 'boleto/' + uid)
            .then(fnSucess)
            .catch(fnError);
    }

    static salvarPessoa(uid, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post('/public' + this.urlBase + 'pessoa/' + uid, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static expirar(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'expirar/' + id)
            .then(fnSucess)
            .catch(fnError);
    }
}
