import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import DashboardService from "../../services/DashboardService";
import Util from "../../utils/Util";

export default class Top10RankingHeader extends MyComponent {

    constructor(props) {
        super(props);

        this.getLista = this.getLista.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            model: {
                maxMes: 1,
                anoFinal: (new Date()).getFullYear(),
                mesFinal: (new Date()).getMonth() + 1
            },
            data: []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();

        setInterval(this.getLista, (60 * 1000 * 60));
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getLista() {
        if (Util.isActive()) {
            DashboardService.getTop10RankingValor(this.state.model, (response) => {
                this.setState({ data: response });
            });
        }
    }

    render() {
        return (
            <div className="d-none d-lg-block d-print-none">
                {this.state.data.length > 0 ?
                    <table className="p-0 m-0 table table-bordered border-ligth table-sm" style={{ fontSize: "10px" }}>
                        <tbody>
                            <tr>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((indexLinha) => {

                                    var representante = this.state?.data[0]?.dados[indexLinha];

                                    if(representante){
                                        return (
                                            <React.Fragment key={indexLinha}>
                                                <td className={`text-secondary p-1 m-0 ${representante.flRepresentanteUsuario ? "fw-bold" : ""}`}>
                                                    {(indexLinha) + 1}º {representante.nomeRepresentante}
                                                </td>
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <React.Fragment key={indexLinha}>
                                                <td className={`text-secondary p-1 m-0`}>
                                                    {(indexLinha) + 1}º ---------
                                                </td>
                                            </React.Fragment>
                                        );
                                    }
                                })}
                            </tr>
                        </tbody>
                    </table>
                    : <></>
                }
            </div>
        )
    }
}