import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import MuralService from "../../services/MuralService";
import BtnButton from "../../components/forms/elements/BtnButton";
import Util from "../../utils/Util";
import Config from "../../Config";
import EventBus from "../../controllers/EventBus";

class FormMuralView extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            dsMensagem : "",
            muralArquivo: []
        };
    }

    componentDidMount() {
        super.componentDidMount();

        MuralService.getOneByIdView(this.props.idMural, response => {
            this.setModel(response);
            EventBus.dispatch("atualizar-mural");
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Fechar </BtnButton>
            </div>);
    }

    getRenderFields() {
        return (
            <div>
                <div className="row mb-3 g-2">
                    <InputText className="fw-bold" md={8} label="Remetende:" layout="floating" readOnly={true} value={this.state.model.nomeUsuario} opcionalLabel={false} />
                    <InputText className="fw-bold" md={2} label="Data:" layout="floating" readOnly={true} value={Util.date2Br(this.state.model.dataMensagem)} opcionalLabel={false} />
                    <InputText className="fw-bold" md={2} label="Hora:" layout="floating" readOnly={true} value={this.state.model.horaMensagem} opcionalLabel={false} />
                    <InputText className="fw-bold" md={12} label="Título:" layout="floating" readOnly={true} value={this.state.model.dsTitulo} opcionalLabel={false} />

                    <div className="col-lg-12" style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "20em" }} >
                        <div className=" rounded border border-ligth p-2" dangerouslySetInnerHTML={{ __html: Util.htmlFormatWhatsapp(this.state.model.dsMensagem) }} style={{ whiteSpace: "pre-wrap" }} />

                        <div className="row pt-3 g-2">
                            {this.state.model.muralArquivo.map((item, index) => (
                                <div className="col-md-2" key={index}>
                                    <div className="card">
                                        {Util.getTipoArquivo(item.tipoArquivo) === "IMAGEM" ?
                                            <img src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uid} className="card-img-top" alt="..." width="100%" height="150" />
                                            :
                                            <div style={{ height: "150px" }} className="d-flex justify-content-center align-items-center">
                                                {Util.getIconeTipo(Util.getTipoArquivo(item.tipoArquivo), 48, 48)}
                                            </div>
                                        }

                                        <a href={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uid} className="btn btn-link btn-sm fs-7">
                                            {Util.stringCut(item.nomeArquivo, 20)}
                                        </a>

                                        <div className="card-footer text-end">
                                            <span className="text-secondary fs-7 pe-1"> {(item.tamanho / 1024.0).toFixed(2)} KB </span>
                                            <a href={Config.getUrlArquivo() + "public/arquivo/download/" + item.uid} className="btn btn-outline-primary btn-sm">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                                                    <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormMuralView {...props} navigate={navigate} params={params} />
}

export default With