import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import BlocoService from "../../services/BlocoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import TextArea from "../../components/forms/elements/TextArea";
import Select from "../../components/forms/elements/Select";
import InputNumber from "../../components/forms/elements/InputNumber";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import Util from "../../utils/Util";
import InputUrl from "../../components/forms/elements/InputUrl";

class FormBloco extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idBloco: "",
            nome: "",
            dsDetalhe: "",
            dsCabecalho: "",
            dsTitulo: "",
            nrTempoAtualizar: "",
            cdTipo: "number",
            cdModelo: "",
            dsUrl: "",
            nrTamanhoColuna: 3,
            nrOrdem: "",
            flAgrupar: false,
            flAtivo: true,
            cdComando: "S",
            cdTipoFiltro : "N",
            nrIntervaloFiltro : 0,
            dsComando: "",
            dsConfiguracao: "{}"
        };

        this.state.showBtnContinuar = true;

        this.state.tipos = BlocoService.getTipos();

        this.state.comandos = [
            { value: "S", label: "SQL" },
            { value: "P", label: "PHP" },
        ];

        this.state.tipoFiltro = [
            { value: "N", label: "Nenhum" },
            { value: "CD", label: "Campo Individual de Data" },
            { value: "CM", label: "Campo Individual de Mês" },
            { value: "CA", label: "Campo Individual de Ano" },
            { value: "ID", label: "Intervalo de Datas" },
            { value: "IM", label: "Intervalo de Mês" },
            { value: "IA", label: "Intervalo de Anos" },
            { value: "ND", label: "Intervalo de dias" },
        ];

        this.state.crud = {
            service: BlocoService,
            labels: {
                title: 'Bloco',
            },
            urlList: '/LstBloco',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Nome do Bloco" required md={6} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <InputText label="Cabeçalho" required md={3} value={this.state.model.dsCabecalho} name="dsCabecalho" onChange={this.handleChange} maxLength={100} />
                    <InputText label="Título" md={3} value={this.state.model.dsTitulo} name="dsTitulo" onChange={this.handleChange} maxLength={100} />
                    <TextArea label="Detalhes" md={12} value={this.state.model.dsDetalhe} name="dsDetalhe" onChange={this.handleChange} />

                    <Select md={4} required={true} className="w-100" name="cdTipo" value={this.state.model.cdTipo} label="Tipo do Bloco" options={this.state.tipos} onChange={this.handleChange} />

                    {this.state.tipos.find(i => this.state.model.cdTipo.toString() === i.value.toString())?.modelos?.length > 0 ?
                        <Select md={4} required={true} className="w-100" name="cdModelo" value={this.state.model.cdModelo} label="Modelo do Bloco" options={this.state.tipos.find(i => this.state.model.cdTipo.toString() === i.value.toString())?.modelos} onChange={this.handleChange} />
                        : <></>}


                    {this.state.model.cdTipo.toString() === "iframe" ?
                        <InputUrl label="URL Embedar" md={4} value={this.state.model.dsUrl} name="dsUrl" onChange={this.handleChange} maxLength={250} required={true} />
                        : <></>}


                    <InputNumber md={2} min={10} step={1} label='Tempo de Atualização' value={this.state.model.nrTempoAtualizar} name="nrTempoAtualizar" onChange={this.handleChange} required={true} />
                    <InputNumber md={2} min={1} max={12} step={1} label='Tamanho da Coluna' value={this.state.model.nrTamanhoColuna} name="nrTamanhoColuna" onChange={this.handleChange} required={true} />

                    <Select required={true} md={4} name="cdTipoFiltro" value={this.state.model.cdTipoFiltro} label="Tipo de Filtro" options={this.state.tipoFiltro} onChange={this.handleChange} />
                    {this.state.model.cdTipoFiltro !== "N" ?
                        <InputNumber md={2} min={0} step={1} label='Intervalo do Filtro' value={this.state.model.nrIntervaloFiltro} name="nrIntervaloFiltro" onChange={this.handleChange} required={true} />
                    : <></>}
                </div>

                <div className="row mx-1 mb-3">
                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                    <InputCheckSwitch label="Mostrar Barra Agrupada?" name="flAgrupar" checked={Boolean(this.state.model.flAgrupar)} onChange={this.handleChange} />
                </div>

                <div className="row mb-3 g-3">
                    {this.state.tipos.find(i => this.state.model.cdTipo.toString() === i.value.toString())?.flComando ?
                        <>
                            <Select required={true} md={4} name="cdComando" value={this.state.model.cdComando} label="Tipo de Comando" options={this.state.comandos} onChange={this.handleChange} />
                            <TextArea className="text-bg-dark" label="SQL" md={12} value={this.state.model.dsComando} name="dsComando" onChange={this.handleChange} style={{ height: "400px", fontFamily: "Courier New" }} required={true} />
                        </>
                        : <></>}

                    <TextArea className="text-bg-dark" label="Configuração" md={12} value={this.state.model.dsConfiguracao} name="dsConfiguracao" onChange={this.handleChange} style={{ height: "400px", fontFamily: "Courier New" }} required={true} />

                    {!Util.isJsonString(this.state.model.dsConfiguracao) ?
                        <div className="alert alert-danger pt-1 pb-1" role="alert">
                            JSON Inválido
                        </div>
                        : <></>}
                </div>


            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormBloco {...props} navigate={navigate} params={params} />
}

export default With