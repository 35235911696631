import { Component } from "react";
import { toast } from "react-toastify";
import EventBus from "../../controllers/EventBus";
import Util from "../../utils/Util";

export default class MyComponent extends Component {

    constructor(props) {
        super(props);

        this.setStateCache = this.setStateCache.bind(this);
        this.getStateCache = this.getStateCache.bind(this);

        EventBus.cleanWaiting();

        this.cache = {
            active: false,
            id: "cache-" + this.constructor.name,
            state: {},
            clear: true
        };

        this.delays = {};
        this.state = {}
    }

    componentDidMount(fnCallBack) {
        this.getStateCache(fnCallBack);
    }

    componentWillUnmount() {
        this.setStateCache();
    }


    setStateCache() {
        var storage = Util.setObject(this.state, this.cache.state);
        if (this.cache.active) {
            localStorage.setItem(this.cache.id, JSON.stringify(storage));
        }
    }

    getStateCache(fnCallBack) {
        if (this.cache.active && localStorage.getItem(this.cache.id)) {
            try {
                var cache = JSON.parse(localStorage.getItem(this.cache.id));
                this.setState(state => {
                    Util.setObject(cache, this.cache.state, state);
                    return state;
                }, fnCallBack);
            } catch (error) { }
            if (this.cache.clear) {
                this.clearCache();
            }
        } else {
            if (fnCallBack) {
                fnCallBack();
            }
        }
    }

    clearCache() {
        localStorage.removeItem(this.cache.id);
    }


    setModel(values, fnCallBack = () => { }) {

        this.setState((state) => {
            Object.keys(values).forEach(key => {
                if (values[key] !== null) {
                    state.model[key] = values[key];
                }
            });
            return state;
        }, fnCallBack);

    }

    handleChange(e, fnCallBack, state = false, property = false) {

        if (!state) {
            state = this.state;
        }

        var _property = null;

        if(property){
            _property = property;
        }else{
            _property = e.target.name
        }

        switch (e.target?.type) {
            case "checkbox":
                if (state.model && typeof state.model[_property] !== 'undefined') {
                    state.model[_property] = e.target.checked;
                } else if (state && typeof state[_property] !== 'undefined') {
                    state[_property] = e.target.checked;
                }
                break;

            default:
                if (state.model && typeof state.model[_property] !== 'undefined') {
                    state.model[_property] = e.target.value;
                } else if (state && typeof state[_property] !== 'undefined') {
                    state[_property] = e.target.value;
                }
        }

        this.setState(state, fnCallBack ? fnCallBack : () => { });
    }

    handleSubmit(event) {
        event.preventDefault();

        var form = event.currentTarget;

        if (form.checkValidity() === false) {
            this.setState({ validated: true });
            event.stopPropagation();
            toast.warning('Verifique os campos do formulário!');
            return;
        }

        if (this.onSubmit) {
            this.onSubmit(event);
        }
    }

    /*static getDerivedStateFromProps(props, state) {

        if (props.value !== state.value) {
            if (state.eventsBusDispachChangeValue) {
                EventBus.dispatch(state.eventsBusDispachChangeValue, props.value);
            }
            return {
                value: props.value,
            };
        }
        return null;
    }*/


    /*componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(nextProps);
        //console.log(prevProps);
    }*/


    getProps() {
        return Object.fromEntries(Object.entries(this.props).filter(([key, value]) => key.indexOf('app-special-') !== 0));
    }

    receivEventDispach(event, data) {

    }
}