import CategoriaProdutoServicoService from "../../services/CategoriaProdutoServicoService";
import SelectCategoriaProdutoServico from "./SelectCategoriaProdutoServico";

export default class SelectCategoriaProdutoServicoByUsuario extends SelectCategoriaProdutoServico {

    getOptions(callBack) {
        CategoriaProdutoServicoService.getForComboByUsuario((response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}