import React from "react";
import FormField from "./FormField";

export default class FormSelect extends FormField {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.state.options = this.props.options || [];
        this.state.value = null;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(event) {
        super.handleChange(event);
        if (this.props.onChange) {
            this.props.onChange(event);
        }
                
        if (this.props.onChangeItem) {
            var item = this.state.options.find(item => item.value.toString() === event?.target?.value.toString());
            this.props.onChangeItem(item ? item : {})
        }
    }

    isDisabled(f) {
        if (this.props.disabledValues) {
            return this.props.disabledValues.indexOf(f?.value) !== -1;
        }
        return false;
    }

    getOptionLabel(item){
        return item?.label;
    }

    getOptionClass(item){
        return "";
    }

    render() {

        var className = `form-select ${this.props.className ? this.props.className : ""} ${this.props.sizing ? "form-select-" + this.props.sizing : ""}`;

        var options = this.state.options.filter(f => {
            f.disabled = this.isDisabled ? this.isDisabled(f) : false;
            return f;
        });

        var render =
            <select
                {...this.getProps(this.props)}
                ref={this.state.ref}
                className={className}
                onChange={this.handleChange}
            >
                {this.props.placeholder ? <option value="">{this.props.placeholder}</option> : ""}
                {options.map((option, index) => (
                    <option className={this.getOptionClass(option)} key={index} value={option.value} disabled={option.disabled}>{this.getOptionLabel(option)}</option>
                ))}
            </select>;

        return this.getRenderFormControl(render);
    }
}