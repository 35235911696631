import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputNumber from "../../components/forms/elements/InputNumber";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputDecimal from "../../components/forms/elements/InputDecimal";
import TextArea from "../../components/forms/elements/TextArea";
import FormSelect from "../../components/forms/base/FormSelect";
import InputDate from "../../components/forms/elements/InputDate";
import InputTime from "../../components/forms/elements/InputTime";
import ContratoService from "../../services/ContratoService";
import InputCpf from "../../components/forms/custom/InputCpf";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import InputCep from "../../components/forms/custom/InputCep";
import RepresentanteContratoService from "../../services/RepresentanteContratoService";
import Util from "../../utils/Util";
import Config from "../../Config";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import InputColor from "../../components/forms/elements/InputColor";

class FormRepresentanteContratoDados extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeField = this.handleChangeField.bind(this);
        this.getField = this.getField.bind(this);
        this.getValue = this.getValue.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.setDefaultValue = this.setDefaultValue.bind(this);

        this.state.model = {
            idRepresentanteContrato : "",
            idContrato: "",
            contratoCampo : [],
            representanteContratoCampoValor : [],
            cdTxColor : "#000000"
        };

        this.state.crud = {
            service: RepresentanteContratoService,
            labels: {
                title: '',
            },
            urlList: '/',
        };
    }

    componentDidMount() {
        super.componentDidMount();        
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onEdit(id) {
       super.onEdit(id, ()=>{
            ContratoService.getOneById(this.state.model.idContrato, (response) => {
                this.setState(state => {
                    state.model.contratoCampo = response.contratoCampo;
                    return state;
                }, this.setDefaultValue);
            });
       });
    }

    onSubmit(event, fnCallBack = false, flContinue = false) {        
        RepresentanteContratoService.createCampoValor(this.state.model, (response) => {            
            var urlRelatorio = "relatorio/contrato-001";
            Util.openNewWindow(Config.getUrlApi() + urlRelatorio, this.state.model);
            this.onSave(response);
        });
    }

    handleChangeField(e, campo, value = false) {
        
        var index = this.state.model?.representanteContratoCampoValor.findIndex(i => i.cdCampo === campo.cdCampo);

        if(index === -1){
            index = (this.state.model.representanteContratoCampoValor.push({
                cdCampo : campo.cdCampo,
                dsValor : ""
            })) - 1;
        }

        switch (campo.cdTipo) {
            case "BOOLEAN":
                this.setState(state => {
                    state.model.representanteContratoCampoValor[index].dsValor = e.target.checked;
                    return state;
                });
                break;

            case "CHECKBOX":
                this.setState(state => {
                    var valores = state.model.representanteContratoCampoValor[index].dsValor;
                    valores = valores ? valores : "[]";
                    valores = JSON.parse(valores);

                    if (e.target.checked) {
                        valores.push(value);
                    } else {
                        var indexOf = valores.findIndex(i => value === i);
                        if (indexOf !== -1) {
                            valores.splice(indexOf, 1);
                        }
                    }
                    state.model.representanteContratoCampoValor[index].dsValor = JSON.stringify(valores);
                    return state;
                });
                break;

            default:
                this.setState(state => {
                    state.model.representanteContratoCampoValor[index].dsValor = e.target.value;
                    return state;
                });
        }
    }

    setDefaultValue(){
        this.setState(state =>{
            state.model.contratoCampo.forEach(campo => {
                var index = state.model?.representanteContratoCampoValor.findIndex(i => i.cdCampo === campo.cdCampo);

                if(index === -1){
                    state.model.representanteContratoCampoValor.push({
                        cdCampo : campo.cdCampo,
                        dsValor : campo.dsPadrao.length > 0 ? campo.dsPadrao :  ""
                    });
                }
            });

            return state;
        });
    }

    getValue(cdCampo){
        var item = this.state.model?.representanteContratoCampoValor.find(i => i.cdCampo === cdCampo);
        return item ? item?.dsValor : "";
    }

    getField(campo, index) {
        switch (campo.cdTipo) {
            case "VARCHAR":
                return <InputText required={Boolean(campo.flObrigatorio)} md={6} label={campo.dsTitulo} value={this.getValue(campo.cdCampo)} onChange={e => { this.handleChangeField(e, campo) }} maxLength={campo.nrTamanho} />

            case "TEXT":
                return <TextArea required={Boolean(campo.flObrigatorio)} md={12} label={campo.dsTitulo} value={this.getValue(campo.cdCampo)} onChange={e => { this.handleChangeField(e, campo) }} maxLength={campo.nrTamanho} />

            case "INTEGER":
                return <InputNumber required={Boolean(campo.flObrigatorio)} md={3} label={campo.dsTitulo} value={this.getValue(campo.cdCampo)} onChange={e => { this.handleChangeField(e, campo) }} />

            case "DATE":
                return <InputDate required={Boolean(campo.flObrigatorio)} md={3} label={campo.dsTitulo} value={this.getValue(campo.cdCampo)} onChange={e => { this.handleChangeField(e, campo) }} />

            case "TIME":
                return <InputTime required={Boolean(campo.flObrigatorio)} md={3} label={campo.dsTitulo} value={this.getValue(campo.cdCampo)} onChange={e => { this.handleChangeField(e, campo) }} />

            case "DECIMAL":
                return <InputDecimal required={Boolean(campo.flObrigatorio)} md={3} label={campo.dsTitulo} value={this.getValue(campo.cdCampo)} onChange={e => { this.handleChangeField(e, campo) }} decimalScale={parseInt(campo.nrTamanho)} />

            case "BOOLEAN":
                return (
                    <div className="col-md-6">
                        <InputCheckSwitch label={campo.dsTitulo} checked={Boolean(campo.dsValor)} onChange={e => { this.handleChangeField(e, campo) }} />
                    </div>
                );

            case "DROP_DOWN":
                return <FormSelect required={Boolean(campo.flObrigatorio)} md={9} placeholder="Selecione um item" label={campo.dsTitulo} value={this.getValue(campo.cdCampo)} options={JSON.parse(campo.dsJson)} onChange={e => { this.handleChangeField(e, campo) }} />

            case "CHECKBOX":
                return (
                    <div className="col-md-9">
                        {JSON.parse(campo.dsJson).map((item, indexCheck) => (
                            <div className="row" key={indexCheck}>
                                <InputCheckSwitch label={item.label} checked={Boolean((JSON.parse(campo.dsValor)?.find(value => value === item.value)))} onChange={e => { this.handleChangeField(e, campo, item.value) }} />
                            </div>
                        ))}
                    </div>
                );
            case "CPF":
                return <InputCpf label={campo.dsTitulo} md={3} name={campo.cdCampo} value={this.getValue(campo.cdCampo)} onChange={e => { this.handleChangeField(e, campo) }} required={Boolean(campo.flObrigatorio)} />
            
            case "CNPJ":
                return <InputCnpj label={campo.dsTitulo} md={3} name={campo.cdCampo} value={this.getValue(campo.cdCampo)} onChange={e => { this.handleChangeField(e, campo) }} required={Boolean(campo.flObrigatorio)} />    
            
            case "TELEFONE":
                return <InputTelCelular label={campo.dsTitulo} md={3} value={this.getValue(campo.cdCampo)} name={campo.cdCampo} onChange={e => { this.handleChangeField(e, campo) }} required={Boolean(campo.flObrigatorio)}/>

            case "CEP":
                return <InputCep label={campo.dsTitulo} md={3} value={this.getValue(campo.cdCampo)} name={campo.cdCampo} onChange={e => { this.handleChangeField(e, campo) }} required={Boolean(campo.flObrigatorio)} />

            default:
                return <InputText required={Boolean(campo.flObrigatorio)} md={6} label={campo.dsTitulo} value={this.getValue(campo.cdCampo)} onChange={e => { this.handleChangeField(e, campo) }} maxLength={campo.nrTamanho} />

        }
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    {this.state.model.contratoCampo.map((campo, index) => (
                        <React.Fragment key={index}>
                            {this.getField(campo, index)}
                        </React.Fragment>
                    ))}                    
                </div>
                
                <hr/>

                <div className="row mb-3 g-3">
                    <InputColor label="Texto" required md={1} value={this.state.model.cdTxColor} name="cdTxColor" onChange={this.handleChange} maxLength={100} />
                </div>
            </>
        );
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnSubmit className="btn-danger"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                        <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                        <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                    </svg> Salvar e Ver Contrato 
                </BtnSubmit>
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
            </div>);
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormRepresentanteContratoDados {...props} navigate={navigate} params={params} />
}

export default With