import Http from "../controllers/Http";

export default class BoletoService {

    static urlBase = "/boleto/";

    static registrar(idFatura, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + "registrar/" + idFatura)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByUid(uid, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + uid)
            .then(fnSucess)
            .catch(fnError);
    }


}
