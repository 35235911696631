import React from "react";
import EquipamentoService from "../../services/EquipamentoService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaEquipamento extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: EquipamentoService,
            labels: {
                title: 'Lista de Equipamento(s)',
                btnNewRegister: 'Equipamento',
            },
            keyItem: 'idEquipamento',
            urlForm: '/CadEquipamento',
            itensFilter: ["idEquipamento", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-3"> Nome </th>
                <th className="col-md-3"> Marca </th>
                <th className="col-md-3"> Modelo</th>
                <th className="col-md-2 text-center"> Atualização</th>
                <th className="col-md-1 text-center"> Status</th>                
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idEquipamento}</td>
                <td>{item.nome}</td>
                <td>{item.dsMarca}</td>
                <td>{item.dsModelo}</td>
                <td className="text-center">
                    {Boolean(item.flTemAtualizacao) ? <span className="badge bg-primary">A cada {item.nrFrequenciaAtualizacao} mes(es)</span> : <></>}
                </td>
                <td className="text-center">
                    {Boolean(item.flAtivo) ? <span className="badge bg-success">ATIVO</span> : <span className="badge bg-danger">INATIVO</span>}
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}