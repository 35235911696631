import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import FeriadoService from "../../services/FeriadoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputDate from "../../components/forms/elements/InputDate";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import SelectPais from "../../components/app/SelectPais";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";

class FormFeriado extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idFeriado: "",
            nome: "",
            dtFeriado: "",
            cdAbrangencia: "",
            idPais: "",
            idEstado: "",
            idCidade: ""
        };

        this.state.abrangencia = [
            { value: "M", label: "Mundial" },
            { value: "N", label: "Nacional" },
            { value: "E", label: "Estadual" },
            { value: "C", label: "Municipal" }
        ];

        this.state.crud = {
            service: FeriadoService,
            labels: {
                title: 'Feriado',
            },
            urlList: '/LstFeriado',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="">
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idFeriado} name="idFeriado" readOnly opcionalLabel={false} />
                </div>
                <div className="row mb-3 g-3">
                    <InputText label="Nome do Feriado" autoFocus={true} required md={5} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    <InputDate md={3} required label="Data Feriado" value={this.state.model.dtFeriado} name="dtFeriado" onChange={this.handleChange} />
                    <BtnGroup required={true} md={4} className="w-100" name="cdAbrangencia" value={this.state.model.cdAbrangencia} label="Abrangência" options={this.state.abrangencia} onChange={this.handleChange} />

                    {this.state.model.cdAbrangencia === 'N' || this.state.model.cdAbrangencia === 'E' || this.state.model.cdAbrangencia === 'C' ?
                        <SelectPais label="Nome do País" required={true} placeholder="Selecione o País" name="idPais" md={4} value={this.state.model.idPais} onChange={this.handleChange} />
                        : <></>}
                    {this.state.model.cdAbrangencia === 'E' || this.state.model.cdAbrangencia === 'C' ?
                        <SelectEstadoByPais required idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" md={4} onChange={e => this.handleChange(e, this.getCidades)} />
                        : <></>}
                    {this.state.model.cdAbrangencia === 'C' ?
                        <SelectCidadeByEstado required disabled={!this.state.model.idEstado} idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" md={4} onChange={this.handleChange} />
                        : <></>}
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormFeriado {...props} navigate={navigate} params={params} />
}

export default With