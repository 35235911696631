import React from "react";
import CronService from "../../services/CronService";
import Util from "../../utils/Util";
import { Link, useNavigate, useParams } from "react-router-dom";
import MyComponent from "../../components/custom/MyComponent";
import EventBus from "../../controllers/EventBus";
import InputDate from "../../components/forms/elements/InputDate";
import ListaCronExecucaoHistorico from "./ListaCronExecucaoHistorico";
import InputNumber from "../../components/forms/elements/InputNumber";
import BtnButton from "../../components/forms/elements/BtnButton";

class ListaCron extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.clear = this.clear.bind(this);
        this.handleClickExcluir = this.handleClickExcluir.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickExpand = this.handleClickExpand.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClickHistorico = this.handleClickHistorico.bind(this);
        this.handleClickAgendar = this.handleClickAgendar.bind(this);
        this.getLista = this.getLista.bind(this);
        this.state = this.getInitState(props);

        this.state.filtroAtivo = [
            { value: 'T', label: 'Todos' },
            { value: 'A', label: 'Ativos' },
            { value: 'I', label: 'Inativo' },
        ];

        this.cache = {
            active: true,
            id: "cache-lista-cron",
            clear: true,
            state: {
                model: {
                    busca: true,
                    idCategoriaProdutoServico: true,
                    idTipoProdutoServico: true,
                    idOrdemTipo: true,
                    flAtivo: true
                }
            }
        };
    }

    getInitState(props) {

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;

        var dtExecucaoEnd = new Date();
        dtExecucaoEnd = new Date(dtExecucaoEnd - tzoffset).toISOString().slice(0, 10);

        var dtExecucaoStart = new Date();
        dtExecucaoStart.setDate(dtExecucaoStart.getDate());
        dtExecucaoStart = new Date(dtExecucaoStart - tzoffset).toISOString().slice(0, 10);

        this.timeout = null;

        return {
            data: [],
            expand: false,
            model: {
                busca: "",
                dtExecucaoStart: dtExecucaoStart,
                dtExecucaoEnd: dtExecucaoEnd,
                pagina: 0,
                rowCount: 50,
                nrTimeRefresh: 30
            },

            filtroRowCount: [
                { value: 50, label: "50 Registros" },
                { value: 100, label: "100 Registros" },
                { value: 200, label: "200 Registros" },
                { value: 500, label: "500 Registros" },
                { value: 1000, label: "1000 Registros" },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearTimeout(this.timeout);
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    getLista(showLoadding = true) {
        clearTimeout(this.timeout);

        var nrTimeRefresh = parseInt(this.state.model.nrTimeRefresh);
        nrTimeRefresh = nrTimeRefresh < 30 ? 30 : nrTimeRefresh;

        this.setState(state => {
            state.data = [];
            state.model.pagina = 0;
            return state;
        }, () => {
            CronService.listForList(this.state.model, (response) => {
                this.setState({ data: response }, () => {
                    this.timeout = setTimeout(() => { this.getLista(false) }, (nrTimeRefresh * 1000));
                });
            }, () => {
                this.timeout = setTimeout(() => { this.getLista(false) }, (nrTimeRefresh * 1000));
            }, showLoadding);
        });
    }

    handleClickHistorico(item) {
        EventBus.dispatch("modal-add", {
            title: 'Histórico de Execução',
            fullScreen: true,
            scrollable: true,
            config: {
                isModal: true,
                idCron: item?.idCron ? item.idCron : false,
                dtExecucaoStart: this.state.model.dtExecucaoStart,
                dtExecucaoEnd: this.state.model.dtExecucaoEnd,
                nrTimeRefresh: this.state.model.nrTimeRefresh
            },
            form: ListaCronExecucaoHistorico,
        });
    }

    handleClickExcluir(item) {

        EventBus.dispatch("modal-confirmacao-open", {
            id: item.idCron,
            config: {
                title: "Excluir CRON?",
                message: <>Deseja realmente excluir o cron: <strong>{item.idCron} </strong>?</>,
                btnConfirmText: "Sim, excluir o CRON",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: () => {
                    CronService.delete(item.idCron, () => {
                        this.getLista();
                    });
                }
            }
        })
    }

    handleClickAgendar(item) {
        EventBus.dispatch("modal-confirmacao-open", {
            id: item.idCron,
            config: {
                title: "Agendar Cron?",
                message: <>Deseja realmente agendar o cron: <strong>{item.idCron} </strong>?</>,
                btnConfirmText: "Sim, agendar o cron",
                description: "Esta é uma ação é irreversível!",
                style: "success",
                confirm: () => {
                    CronService.agendar(item.idCron, () => {
                        this.getLista();
                    });
                }
            }
        })
    }

    handleClickExpand() {
        this.setState({ expand: !this.state.expand })
    }

    onSubmit(event) {
        this.getLista();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col text-center d-none d-md-table-cell"> Código </th>
                <th className="col"> </th>
                <th className="col-md-7"> Nome </th>
                <th className="col-md-1 d-none d-md-table-cell"> Crontab </th>
                <th className="col-md-1 text-center d-none d-md-table-cell"> Última </th>
                <th className="col-md-1 text-center d-none d-md-table-cell"> Próxima </th>
                <th className="col-md-1 text-center d-none d-md-table-cell"> Ativo </th>
                <th className="col-md-1 text-center d-none d-md-table-cell"> % </th>
            </>
        );
    }

    getRenderItem(item) {

        /*var nrPercentual = null;
        if (item.nrTotal > 0) {
            nrPercentual = parseFloat((item.nrSucesso / item.nrTotal) * 100).toFixed(0);
        }*/

        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idCron}</td>
                <td>
                    {Boolean(item.flExecutando) ?
                        <div className="spinner-grow spinner-grow-sm text-success" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        : <></>}
                </td>
                <td>
                    <strong>{item.nome}</strong><br />
                    <span className="text-primary d-none d-md-table-cell">{item.url}</span>
                </td>
                <td className="fw-bold align-middle d-none d-md-table-cell">{item.crontab}</td>
                <td className="text-center d-none d-md-table-cell">{Util.date2Br(item.dtUltima)} <br /> {item.hrUltima}</td>
                <td className="text-center d-none d-md-table-cell">{Util.date2Br(item.dtProxima)} <br /> {item.hrProxima}</td>
                <td className="text-center d-none d-md-table-cell">
                    {Boolean(item.flAtivo) ? <span className="badge bg-success">ATIVO</span> : <span className="badge bg-danger">INATIVO</span>}
                </td>
                <td className={`text-center d-none d-md-table-cell ${item.nrSucesso === item.nrTotal ? 'table-success' : 'table-danger'}`}>
                    {item.nrSucesso} / {item.nrTotal}
                </td >
                <td className="align-middle text-end d-flex flex-nowrap gap-1">
                    <div className="dropdown dropstart">
                        <button className="btn btn-outline-dark " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li>
                                <Link to={`/CadCron/${item.idCron}`} className="dropdown-item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                    </svg> Editar Cron
                                </Link>
                            </li>
                            <li>
                                <Link to={`/CadCron/copy/${item.idCron}`} className="dropdown-item" title="Copiar item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                    </svg> Copiar Cron
                                </Link>
                            </li>
                            <li>
                                <button type="button" className="dropdown-item" onClick={() => { this.handleClickExcluir(item) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg> Excluir Cron
                                </button>
                            </li>
                            <li>
                                <button type="button" className="dropdown-item" onClick={() => { this.handleClickAgendar(item) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
                                        <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                                    </svg> Agendar Cron
                                </button>
                            </li>
                            <li>
                                <button type="button" className="dropdown-item" onClick={() => { this.handleClickHistorico(item) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
                                        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                    </svg> Ver Histórico
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            </>);
    }

    getRenderFilter() {
        return (
            <div className="row g-2">
                <InputDate md={2} label="Data Inicial" value={this.state.model.dtExecucaoStart} name="dtExecucaoStart" onChange={this.handleChange} required={true} />
                <InputDate md={2} label="Data Final" value={this.state.model.dtExecucaoEnd} name="dtExecucaoEnd" onChange={this.handleChange} min={this.state.model.dtExecucaoStart} required={true} />
                <div className="col-md-6"></div>
                <InputNumber md={2} label='Atualizar' value={this.state.model.nrTimeRefresh} name="nrTimeRefresh" onChange={this.handleChange} required={true} min={30} />

                <div className="col-md-12 mt-3">
                    <div className="row gap-2">
                        <div className="col">
                            <div className="col-md-12 input-group">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite a sua busca" value={this.state.model.busca} onChange={this.handleChange} />
                                <button className="btn btn-outline-primary btn-sm" type="submit"> Buscar </button>
                            </div>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-danger" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="p-4 my-2 py-2 w-100">
                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100">Lista de CRONs</h4>

                    <BtnButton className="btn-outline-primary text-nowrap" onClick={() => { this.handleClickHistorico() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                        </svg> Histórico
                    </BtnButton>

                    <Link to="/CadCron" className="btn btn-outline-primary text-nowrap">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg> Cron
                    </Link>
                </div>
                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    {this.getRenderFilter()}
                </form>
                <div className="table-responsive-sm mt-2">
                    <table className="table table-sm caption-top table-hover table-striped">
                        <caption className="text-end">
                            <div className="hstack d-none d-lg-block">
                                <div className="hstack gap-2 w-100">
                                    <div className="ms-auto">
                                        {this.state.data.length} Registro(s)
                                    </div>
                                </div>
                            </div>
                        </caption>
                        <thead>
                            <tr>
                                {this.getRenderHeader()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <tr key={index}>
                                    {this.getRenderItem(item, index)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <ListaCron {...props} navigate={navigate} params={params} />
}

export default With