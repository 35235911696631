import FormBtnGroupRadio from "../base/FormBtnGroupRadio";

export default class BtnGroup extends FormBtnGroupRadio {

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return super.render();
    }
}