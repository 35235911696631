import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import BtnButton from "../../components/forms/elements/BtnButton";
import DashboardService from "../../services/DashboardService";

export default class Financeiro extends MyComponent {

    constructor(props) {
        super(props);

        this.getLista = this.getLista.bind(this);

        this.state = {
            model: {},
            data: []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getLista() {
        this.setState({ data: [] });
        DashboardService.getFinanceiro(this.state.model, (response) => {
            this.setState({ data: response });
        });
    }

    render() {
        return (
            <>
                <div className="hstack mb-3">
                    <div className="ms-auto  d-flex flex-nowrap">
                        <BtnButton className="btn-outline-primary mx-2" onClick={this.getLista}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                            </svg> Atualizar
                        </BtnButton>
                    </div>
                </div>
                <div className="accordion" id="accordionExample">
                    {this.state.data.map((relatorio, index) => (
                        <div key={index} className="accordion-item">
                            <h2 className="accordion-header" id={`accordion-${index}`}>
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${index}`} aria-expanded="true" aria-controls={`collapse-${index}`}>
                                    {relatorio.label}
                                </button>
                            </h2>
                            <div id={`collapse-${index}`} className="accordion-collapse collapse" aria-labelledby={`accordion-${index}`} data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <img className="img-fluid w-100" src={relatorio.urlImg} alt="Dashboards" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }
}