import InputNumber from "../elements/InputNumber";

export default class InputPercentual extends InputNumber {

    getRenderInputGroupRigth() {
        return <span className={`input-group-text fw-bold ${this.props.sizing ? "input-group-" + this.props.sizing : ""}`}>%</span>
    }

    render() {
        return super.render();
    }
}