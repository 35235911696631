import MarcaService from "../../services/MarcaService";
import SelectMarca from "./SelectMarca";

export default class SelectMarcaByCategoriaProdutoServico extends SelectMarca {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.idCategoriaProdutoServico !== this.props.idCategoriaProdutoServico) {
            this.getOptions();
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        if (this.props.idCategoriaProdutoServico) {
            MarcaService.getForComboByCategoriaProdutoServico(this.props.idCategoriaProdutoServico, (response) => {
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();
                    }
                });
            });
        } else {
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    render() {
        return super.render();
    }
}