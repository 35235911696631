import React from "react";
import { Link } from "react-router-dom";
import EventBus from "../../controllers/EventBus";
import MyComponent from "../../components/custom/MyComponent";
import ContatoService from "../../services/ContatoService";
import Util from "../../utils/Util";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import InputDate from "../../components/forms/elements/InputDate";
import FormRelatorioContato001 from "../Relatorio/FormRelatorioContato001";
import Permissao from "../../controllers/Permissao";
import BtnButton from "../../components/forms/elements/BtnButton";
import FormContatoAtendimento from "../ContatoAtendimento/FormContatoAtendimento";
import Select from "../../components/forms/elements/Select";
import { toast } from "react-toastify";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import SelectContatoTipoByUsuario from "../../components/app/SelectContatoTipoByUsuario";
import SelectContatoStatusByContatoTipo from "../../components/app/SelectContatoStatusByContatoTipo";
import SelectContatoOrigem from "../../components/app/SelectContatoOrigem";
import DropDownMenuContatoMotivoByContatoTipo from "../../components/app/DropDownMenuContatoMotivoByContatoTipo";
import BtnGroup from "../../components/forms/elements/BtnGroup";

export default class ListaContato extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.excluir = this.excluir.bind(this);
        this.clear = this.clear.bind(this);
        this.handleClickExcluir = this.handleClickExcluir.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickCsv = this.handleClickCsv.bind(this);
        this.handleChangeMotivos = this.handleChangeMotivos.bind(this);
        this.clearMotivos = this.clearMotivos.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLista = this.getLista.bind(this);
        this.state = this.getInitState(props);

        this.cache = {
            active: true,
            id: "cache-lista-contato",
            clear: true,
            state: {
                model: {
                    busca: true,
                    dtInsertStart: true,
                    dtInsertEnd: true,
                    idContatoTipo: true,
                    idContatoStatus: true,
                    idContatoMotivo: true,
                    idContatoMotivos: true,
                    pagina: true,
                    rowCount: true,
                    idRepresentante: true,
                    idContatoOrigem: true,                    
                    flStatus: true,
                }
            }
        };
    }

    getInitState(props) {

        /*var tzoffset = (new Date()).getTimezoneOffset() * 60000;

        var dtInsertEnd = new Date();
        dtInsertEnd = new Date(dtInsertEnd - tzoffset).toISOString().slice(0, 10);

        var dtInsertStart = new Date();
        dtInsertStart.setDate(dtInsertStart.getDate() - 30);
        dtInsertStart = new Date(dtInsertStart - tzoffset).toISOString().slice(0, 10);*/

        return {
            data: [],
            model: {
                busca: "",
                dtInsertStart: "",
                dtInsertEnd: "",
                idContatoTipo: "",
                idContatoStatus: "",
                idContatoMotivos : [],
                idContatoMotivo: "",
                idRepresentante: "",
                idContatoOrigem: "",
                pagina: 0,
                rowCount: 100,                
                flStatus : "T"
            },

            filtroRowCount: [
                { value: 50, label: "50 Registros" },
                { value: 100, label: "100 Registros" },
                { value: 200, label: "200 Registros" },
                { value: 500, label: "500 Registros" },
                { value: 1000, label: "1000 Registros" },
            ],

            filtroStatus: [
                { value: 'T', label: 'Todos' },
                { value: 'P', label: 'Pedentes' },
                { value: 'F', label: 'Finalizados' },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    getLista(merge = false) {
        if (merge) {
            ContatoService.list(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                ContatoService.list(this.state.model, (response) => {
                    this.setState({ data: response, ordens: [] });
                });
            });
        }
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    handleClickExcluir(item) {
        EventBus.dispatch("modal-delete-open", {
            id: item.idContato,
            config: {
                title: "Excluir contato?",
                message: <>Deseja realmente excluir o contato: <strong>{item.nome} </strong>?</>,
                btnConfirmText: "Sim, excluir o contato",
                description: "Esta é uma ação é irreversível!",
                style: "danger"
            }
        })
    }

    excluir(item) {
        ContatoService.delete(item.id, (response) => {
            this.getLista();
        });
    }

    handleClickCsv() {
        EventBus.dispatch("modal-add", {
            title: 'Relatorio de Contatos',
            sizeModal: 'modal-lg',
            form: FormRelatorioContato001,
            config: this.state.model
        });
    }

    handleClickAtendimento(item) {
        EventBus.dispatch("modal-add", {
            title: 'Registro de Atendimento',
            sizeModal: 'modal-lg',
            config: {
                isModal: true,
                idContato: item.idContato,
                idContatoStatus: item.idContatoStatus,
                idContatoMotivo: item.idContatoMotivo,
                idContatoTipo: item.idContatoTipo,
            },
            form: FormContatoAtendimento,
            onClose: (response) => {
            },
            onSave: (response) => {
                this.onSubmit();
            }
        });
    }

    clearMotivos() {
        this.setState(state => {
            state.model.idContatoMotivos = [];
            return state;
        }, this.onSubmit);
    }

    handleChangeMotivos(isChecked, item) {
        if (isChecked) {
            this.setState(state => {
                state.model.idContatoMotivos.push(item.value);
                return state;
            }, this.onSubmit);
        } else {
            this.setState(state => {
                var index = this.state.model.idContatoMotivos.findIndex(i => parseInt(i) === parseInt(item.value));
                state.model.idContatoMotivos.splice(index, 1);
                return state;
            }, this.onSubmit);
        }
    }

    onSubmit(event) {
        this.getLista();
    }


    render() {
        return (
            <div className="p-3 my-2 w-100">
                <div className="hstack gap-3 mb-3">
                    <h4 className="mb-3 w-100">Lista de Contatos</h4>
                    {Permissao.getInstance().getPermissao(47) ?
                        <button className="btn btn-outline-success text-nowrap" onClick={this.handleClickCsv}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-xls" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z" />
                            </svg> CSV
                        </button> : <></>
                    }
                    <Link to="/CadContato" className="btn btn-outline-primary text-nowrap">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg> Contato
                    </Link>
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row mb-2 g-3">
                        <InputDate md={2} label="Data Inicial" value={this.state.model.dtInsertStart} name="dtInsertStart" onChange={this.handleChange} opcionalLabel={false} />
                        <InputDate md={2} label="Data Final" value={this.state.model.dtInsertEnd} name="dtInsertEnd" onChange={this.handleChange} min={this.state.model.dtInsertStart} opcionalLabel={false} />
                        <SelectContatoOrigem label="Origem" showBtns={false} md={2} value={this.state.model.idContatoOrigem} placeholder="Selecione a Origem" required={false} name="idContatoOrigem" onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <SelectRepresentanteByUsuario showBtnNewForm={false} md={4} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <Select label="Registros por Página" md={2} value={this.state.model.rowCount} required={true} options={this.state.filtroRowCount} name="rowCount" onChange={(e) => { this.handleChange(e, this.onSubmit) }}></Select>

                        <BtnGroup md={2} className="w-100" name="flStatus" value={this.state.model.flStatus} label="Status Geral" options={this.state.filtroStatus} onChange={(e) => { this.handleChange(e, this.clearMotivos) }} opcionalLabel={false} />
                        <SelectContatoTipoByUsuario showBtns={false} label="Tipo do Contato" md={3} placeholder="Selecione o Tipo" name="idContatoTipo" value={this.state.model.idContatoTipo} onChange={e => { this.handleChange(e, this.clearMotivos) }} opcionalLabel={false} />
                        <SelectContatoStatusByContatoTipo showBtns={false} idContatoTipo={this.state.model.idContatoTipo} label="Nome do Status" placeholder="Selecione o Status" name="idContatoStatus" md={2} value={this.state.model.idContatoStatus} onChange={e => { this.handleChange(e, this.clearMotivos) }} opcionalLabel={false} />
                        <DropDownMenuContatoMotivoByContatoTipo showBtns={false} flFinalizado={(this.state.model.flStatus!== "T" ? (this.state.model.flStatus === "P" ? 0 : 1) : false)} idContatoTipo={this.state.model.idContatoTipo} idContatoStatus={this.state.model.idContatoStatus} label="Nome do Motivo" placeholder="Selecione o Motivo" name="idContatoMotivos" md={5} values={this.state.model.idContatoMotivos} onChange={e => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} handleChangeItem={this.handleChangeMotivos}/>
                        {/*<SelectContatoMotivoByContatoStatus showBtns={false} label="Nome do Motivo" idContatoStatus={this.state.model.idContatoStatus} placeholder="Selecione o Motivo" name="idContatoMotivo" md={4} value={this.state.model.idContatoMotivo} onChange={e => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />*/}
                        
                        <div className="col">
                            <div className="col-md-12 input-group mb-3">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite o nome de um contato" value={this.state.model.busca} onChange={this.handleChange} />
                                <button className="btn btn-outline-primary" type="submit"> Buscar </button>
                            </div>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-danger" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </button>
                        </div>
                    </div>                    
                </form>

                <div className="table-responsive">
                    <table className="table table-sm table-hover caption-top">
                        <caption className="text-end">
                            {this.state.data.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th> ID </th>
                                <th className="col-md-3"> Nome </th>
                                <th className="col-md-4 d-none d-md-table-cell text-center"> Status / Motivo </th>
                                <th className="col-md-4 d-none d-md-table-cell  text-center"> Atendimento </th>
                                <th className="col-md-1 d-none d-md-table-cell text-center"> Data </th>
                                <th className="text-end"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item) => (
                                <tr key={item.idContato}>
                                    <td>
                                        {item.idContato}<br />
                                        <span className={`badge p-1 ${item.aceitaContato ? 'bg-success' : 'bg-danger'}`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                                                <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                                            </svg>
                                        </span>
                                    </td>
                                    <td>
                                        <div className="fw-bold">
                                            {item.nome}
                                        </div>
                                        <div className="d-none d-md-table-cell">{item.nomePais} - {item.nomeCidade} - {item.siglaEstado}</div>
                                        <div>
                                            <a className="fw-bold" href={`https://api.whatsapp.com/send?phone=${item.ddi}${Util.stringToNumber(item.telefone)}`} rel="noopener noreferrer" target="_blank">
                                                {item.ddi} {item.telefone}
                                            </a>
                                        </div>
                                        <a href={`mailto:${item.email}`} rel="noopener noreferrer" target="_blank">{item.email}</a>
                                        <div>{item.empresa}</div>
                                    </td>
                                    <td>
                                        <div className="fw-bold fs-7">{item.nomeContatoTipo}</div>
                                        <span className="badge fs-7 w-100" style={{ backgroundColor: item.cdBgColor, color: item.cdTxColor }}>
                                            {item.nomeContatoStatus}
                                        </span>
                                        <span className="badge text-secondary border border-secondary fs-7 w-100 mt-2">
                                            {item.nomeContatoMotivo}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        {item.nomeUsuario}<br />
                                        <small className="fw-bold">{Util.date2Br(item.dataUltimoContato)} {item.horaUltimoContato}</small>
                                        <div>
                                            {!Boolean(item.flFinalizado) ?
                                                <span className="badge bg-danger">
                                                    PENDENTE
                                                </span> :
                                                <span className="badge bg-success">
                                                    FINALIZADO
                                                </span>
                                            }
                                        </div>
                                    </td>
                                    <td className="text-center text-nowrap d-none d-md-table-cell">
                                        <small>{Util.date2Br(item.dataInsert)} {item.horaInsert}</small>
                                        <div>
                                            <h6>
                                                <span className="w-100 badge rounded-pill bg-primary">
                                                    {item.nomeContatoOrigem}
                                                </span>
                                            </h6>
                                        </div>
                                    </td>
                                    <td className="text-end d-flex flex-nowrap gap-2">
                                        {Permissao.getInstance().getPermissao(75) ?
                                            <BtnButton className="btn-outline-success ms-auto" onClick={e => this.handleClickAtendimento(item)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-headset" viewBox="0 0 16 16">
                                                    <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5z" />
                                                </svg>
                                            </BtnButton>
                                            : <></>}
                                        <Link to={`/CadContato/${item.idContato}`} className="btn btn-outline-primary">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </Link>
                                        {Permissao.getInstance().getPermissao(62) ?
                                            <button type="button" className="btn btn-outline-danger" onClick={() => { this.handleClickExcluir(item) }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                </svg>
                                            </button> : <></>}
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <div className="text-center pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>
                <ModalConfirmacao id="modal-delete" confirm={this.excluir} />
            </div >
        )
    }
}