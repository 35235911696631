import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import InputNumber from "../../components/forms/elements/InputNumber";
import PaisService from "../../services/PaisService";
import SelectRepresentante from "../../components/app/SelectRepresentante";
import CrudForm from "../../components/forms/custom/CrudForm";

class FormPais extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idPais: "",
            nome: "",
            sigla: "",
            ddi: "",
            idRepresentante : ""
        };

        this.state.crud = {
            service: PaisService,
            labels: {
                title: 'País',
            },
            urlList: '/LstPais',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código" md={3} value={this.state.model.idPais} name="idPais" readOnly opcionalLabel={false}/>
                <InputText label="Nome do País" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                <InputText label="Sigla" required md={3} value={this.state.model.sigla} name="sigla" onChange={this.handleChange} maxLength={3} />
                <InputNumber label="DDI" placeholder="55" required md={3} value={this.state.model.ddi} name="ddi" onChange={this.handleChange} min={1} max={999} />
                
                <SelectRepresentante md={12} label="Representante" placeholder="Selecione o Representante da Abrangência" name="idRepresentante" value={this.state.model.idRepresentante} onChange={this.handleChange} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormPais {...props} navigate={navigate} params={params} />
}

export default With