import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import Util from "../../utils/Util";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import EventBus from "../../controllers/EventBus";
import FormFoto from "./FormFoto";
import SelectCategoriaProdutoServicoByUsuario from "../../components/app/SelectCategoriaProdutoServicoByUsuario";
import SelectOrdemTipoUsuario from "../../components/app/SelectOrdemTipoUsuario";
import Storage from "../../controllers/Storage";
import MostraValor from "../../components/app/MostraValor";

export default class ListaPreco extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.clear = this.clear.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLista = this.getLista.bind(this);
        this.handleOpenModalFoto = this.handleOpenModalFoto.bind(this);
        this.build = this.build.bind(this);
        this.state = this.getInitState(props);

        this.state.flVerValor = Storage.getVisualizacaoValor();
    }

    getInitState(props) {

        return {
            inverval: null,
            data: [],
            model: {
                idOrdemTipo: "1",
                idTipoProdutoServico: "",
                idCategoriaProdutoServico: "",
                busca: ""
            }
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.inverval);
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    getLista() {
        this.setState({ data: [] });
        ProdutoServicoService.listPreco(this.state.model, (response) => {
            this.setState({ data: response }, this.build);

        });
    }

    onSubmit(event) {
        this.getLista();
    }

    build() {

        this.setState(state => {

            var idCategoriaProdutoServico = null;
            var indexCategoria = null;

            var idTipoProdutoServico = null;
            var indexTipo = null;

            for (var i = 0; i < state.data.length; i++) {
                if (idCategoriaProdutoServico === state.data[i].idCategoriaProdutoServico) {
                    state.data[indexCategoria].rowSpanCategoria = state.data[indexCategoria].rowSpanCategoria + 1;
                } else {
                    indexCategoria = i;
                    state.data[indexCategoria].rowSpanCategoria = 1;
                }

                if (idTipoProdutoServico === state.data[i].idTipoProdutoServico) {
                    state.data[indexTipo].rowSpanTipo = state.data[indexTipo].rowSpanTipo + 1;
                } else {
                    indexTipo = i;
                    state.data[indexTipo].rowSpanTipo = 1;
                }

                idCategoriaProdutoServico = parseInt(state.data[i].idCategoriaProdutoServico);
                idTipoProdutoServico = parseInt(state.data[i].idTipoProdutoServico);
            }
            return state;
        });
    }

    handleOpenModalFoto(item) {
        EventBus.dispatch("modal-add", {
            title: 'Fotos do Produto',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                idProdutoServico: item.idProdutoServico
            },
            form: FormFoto
        });
    }


    render() {
        return (
            <div className="p-4 my-2 py-2 w-100">
                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100">
                        Lista de Preços
                    </h4>
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row mb-3">
                        <SelectOrdemTipoUsuario md={4} label="Tipo da Ordem" required placeholder="Selecione o Tipo da Ordem" name="idOrdemTipo" value={this.state.model.idOrdemTipo} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                        <SelectCategoriaProdutoServicoByUsuario md={4} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                        <SelectTipoProdutoServicoByCategoria idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} md={4} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={(e) => { this.handleChange(e, this.onSubmit) }} />
                    </div>
                    <div className="row gap-2 mb-3">
                        <div className="col">
                            <div className="col-md-12 input-group mb-3">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite a sua busca" value={this.state.model.busca} onChange={this.handleChange} />
                                <button className="btn btn-outline-primary btn-sm" type="submit"> Buscar </button>
                            </div>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-danger" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </button>
                        </div>
                    </div>
                </form>

                <div className="table-responsive-sm m-3">
                    <table className="table table-sm caption-top table-bordered border-success" >
                        <caption className="text-end">
                            {this.state.data.length} Registro(s)
                        </caption>
                        <thead>
                            <tr className="table-success">
                                <th className="col"> Categoria </th>
                                <th className="col"> Tipo </th>
                                <th className="col"> Produto </th>
                                <th className="col text-center"> Foto </th>
                                <th className="col text-end"> Preço </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <tr key={index}>
                                    {item.rowSpanCategoria ? <td className="align-top" rowSpan={item.rowSpanCategoria}>{item.nomeCategoriaProdutoServico}</td> : <></>}
                                    {item.rowSpanTipo ? <td className="align-middle" rowSpan={item.rowSpanTipo}>{item.nomeTipoProdutoServico}</td> : <></>}
                                    <td>{item.nome}</td>
                                    <td className="text-center">
                                        {
                                            parseInt(item.produtoServicoArquivos) !== 0 ?
                                                <button className="btn btn-sm btn-outline-primary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => { this.handleOpenModalFoto(item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-camera-fill" viewBox="0 0 16 16">
                                                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                        <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z" />
                                                    </svg>
                                                </button>
                                                : <></>
                                        }
                                    </td>
                                    <td className="text-end fw-bold">
                                        <MostraValor value="OCULTO">{Util.floatToReais(item.vlPreco)}</MostraValor>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div >
        )
    }
}