import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import { toast } from "react-toastify";
import TransacaoService from "../../services/TransacaoService";
import InputCopy from "../../components/forms/custom/InputCopy";

class FormPIX extends CrudForm {

    constructor(props) {
        super(props);

        this.getTransacao = this.getTransacao.bind(this);
        this.getStatusTransacao = this.getStatusTransacao.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.startControle = this.startControle.bind(this);
        this.setTempoDecorrido = this.setTempoDecorrido.bind(this);
        this.getPix = this.getPix.bind(this);

        this.state.model = {
            uidTransacao: this.props.params?.uid,
        };

        this.state.transacao = {
            vlTransacao: 0,
            nrParcela: 1,
        };

        this.state.pix = {
            qrCode: "",
            pixCode: ""
        };

        this.state.controle = {
            interval: null,
            decorrido: 0
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getTransacao();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.state.controle.interval);
    }

    startControle() {
        var tempo = 60 * 1000 * 5; // 5 Minutos
        var decorrido = tempo;

        this.setState(state => {
            state.controle.interval = setInterval(() => {
                if (decorrido <= 0) {
                    clearInterval(this.state.controle.interval);
                    this.props.navigate('/pagamento/' + this.state.transacao.uidCobranca);
                    return;
                }

                if (decorrido % 5000 === 0) {
                    this.getStatusTransacao();
                }

                decorrido -= 1000;
                this.setTempoDecorrido(decorrido);
            }, 1000);
            return state;
        });
    }

    setTempoDecorrido(decorrido) {
        this.setState(state => {
            state.controle.decorrido = decorrido;
            return state;
        });
    }

    getStatusTransacao() {
        TransacaoService.getStatus(this.props.params?.uid, (response) => {
            if (parseInt(response.flEfetivada) === 1) {
                this.props.navigate('/pagamento/' + this.state.transacao.uidCobranca);
            }
        });
    }

    getTransacao() {
        TransacaoService.getOneByUid(this.props.params?.uid, (response) => {
            this.setState({ transacao: response }, this.getPix);
        });
    }

    getPix() {
        TransacaoService.pagarPix(this.props.params?.uid, (response) => {
            this.setState({ pix: response });
            this.startControle();
        });
    }

    handleClickCopy(text) {
        if (Util.copyClipboard(text)) {
            toast.success('Dados copiado para a área de transferência!');
        } else {
            toast.error('Erro ao copiar, por favor copie manualmente!');
        }
    }

    getBtns() {
        return (<></>);
    }

    getRenderFields() {
        return (
            <div className="col-xl-8 mx-auto">
                <header>
                    <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                        <a href="https://bdmperformance.com.br/" className="d-flex align-items-center text-dark text-decoration-none">
                            <img src="./logo-preto.png" alt="Logo BDM" />
                        </a>

                        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://www.instagram.com/bdmperformance/">Nosso Instagram</a>
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://bdmperformance.com.br/">Nosso Site</a>
                        </nav>
                    </div>

                    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                        <h1 className="display-5 fw-normal">Pagamento com PIX</h1>
                        <p className="fs-5 text-muted">
                            Pagamento da transação através do PIX, no valor de <strong>{Util.floatToReais(this.state.transacao?.vlTransacao)}</strong>
                        </p>
                        <div className="text-center text-decoration-none">
                            <Link to={`/pagamento/${this.state.transacao.uidCobranca}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                </svg> Escolher outra forma de pagamento
                            </Link>
                        </div>
                    </div>
                </header>

                {this.state.pix.qrCode ?
                    <div>
                        <div className="row mb-3">
                            <InputCopy label="PIX Copia e Cola" style={{ height: "120px" }} md={12} value={this.state.pix.pixCode} readOnly opcionalLabel={false} onClickCopy={() => this.handleClickCopy(this.state.pix.pixCode)} />
                            <div className="col-md-6 pt-2 mx-auto">
                                <img className="img-fluid" src={this.state.pix.qrCode} alt="QR Code PIX" />
                            </div>
                            <div className="col-md-12 pt-2 text-center">
                                <span className="badge text-bg-warning">
                                    <h2 className="fw-bold">{Util.convertMsToHM(this.state.controle.decorrido)}</h2>
                                </span>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
                }

                <footer className="pt-4 my-md-5 pt-md-5 border-top">
                    <div className="row">
                        <div className="col-12 col-md">
                            BDM Performance
                            <small className="d-block mb-3 text-muted">&copy; 2022</small>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormPIX {...props} navigate={navigate} params={params} />
}

export default With