import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputPassword from "../../components/forms/elements/InputPassword";
import UsuarioService from "../../services/UsuarioService";
import CrudForm from "../../components/forms/custom/CrudForm";

class FormSenha extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            senha: "",
            novaSenha: "",
            confirmacao: ""
        };

        this.state.crud = {
            service: UsuarioService,
            labels: {
                title: 'Alteração de Senha',
            },
            urlList: '/',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event) {
        this.state.crud.service.updateSenha(this.state.model, (response) => {
            this.props.navigate('/');
        });
    }

    getTitle() {
        return "Alteração de Senha";

    }
    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputPassword autoFocus={true} autoComplete="off" label="Senha Atual" placeholder="Senha Atual" col={12} required value={this.state.model.senha} name="senha" onChange={this.handleChange} />
                <InputPassword autoComplete="off" label="Nova Senha" placeholder="Nova Senha" col={6} required value={this.state.model.novaSenha} name="novaSenha" onChange={this.handleChange}
                    validations={[
                        {
                            validation: this.state.model.confirmacao === this.state.model.novaSenha,
                            message: "Senha e Confirmação devem ser iguais"
                        }
                    ]} />
                <InputPassword autoComplete="off" label="Confirmação" placeholder="Confirmação" col={6} required value={this.state.model.confirmacao} name="confirmacao" onChange={this.handleChange}
                    validations={[
                        {
                            validation: this.state.model.confirmacao === this.state.model.novaSenha,
                            message: "Senha e Confirmação devem ser iguais"
                        }
                    ]} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormSenha {...props} navigate={navigate} params={params} />
}

export default With