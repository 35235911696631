import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCep from "../../components/forms/custom/InputCep";
import InputValorReais from "../../components/forms/custom/InputValorReais";
import InputPeso from "../../components/forms/custom/InputPeso";
import InputNumber from "../../components/forms/elements/InputNumber";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import CotacaoFreteService from "../../services/CotacaoFreteService";
import Util from "../../utils/Util";
import SelectProdutoServicoTipoObjeto from "../../components/app/SelectProdutoServicoTipoObjeto";
import ProdutoServicoTipoObjetoService from "../../services/ProdutoServicoTipoObjetoService";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputCpf from "../../components/forms/custom/InputCpf";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import RepresentanteService from "../../services/RepresentanteService";
import ProprietarioService from "../../services/ProprietarioService";
import ConfiguracaoService from "../../services/ConfiguracaoService";
//import SelectTipoServicoCorreio from "../../components/app/SelectTipoServicoCorreio";

class FormCotacaoFrete extends CrudForm {

    constructor(props) {
        super(props);

        this.handleClickNovaCotacao = this.handleClickNovaCotacao.bind(this);
        this.handleChangeTipoObjeto = this.handleChangeTipoObjeto.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.state.model = {
            idTipoServico: "",

            idFornecedor: props.idFornecedor !== undefined ? props.idFornecedor : false,
            idModalidadeFrete: props.idModalidadeFrete !== undefined ? props.idModalidadeFrete : false,
            idProprietario: props.idProprietario !== undefined ? props.idProprietario : false,
            idRepresentante: props.idRepresentante !== undefined ? props.idRepresentante : false,

            cdTipoPessoaDestinatario: props.cdTipoPessoaDestinatario !== undefined && props.cdTipoPessoaDestinatario !== "" ? props.cdTipoPessoaDestinatario : "F",
            cdCpfDestinatario: props.cdCpfDestinatario !== undefined ? props.cdCpfDestinatario : "",
            cdCnpjDestinatario: props.cdCnpjDestinatario !== undefined ? props.cdCnpjDestinatario : "",
            cdCnpjRemetente: props.cdCnpjRemetente !== undefined ? props.cdCnpjRemetente : "",
            cdCepOrigem: props.cdCepOrigem !== undefined ? props.cdCepOrigem : "",
            cdCepDestino: props.cdCepDestino !== undefined ? props.cdCepDestino : "",
            idProdutoServicoTipoObjeto: props.idProdutoServicoTipoObjeto !== undefined ? props.idProdutoServicoTipoObjeto : "",
            vlDeclarado: props.vlDeclarado !== undefined ? props.vlDeclarado : "",
            vlPesoBruto: props.vlPesoBruto !== undefined ? props.vlPesoBruto : "",
            nrQuantidade: props.nrQuantidade !== undefined ? props.nrQuantidade : 1,
            vlLargura: props.vlLargura !== undefined ? props.vlLargura : 0,
            vlAltura: props.vlAltura !== undefined ? props.vlAltura : 0,
            vlComprimento: props.vlComprimento !== undefined ? props.vlComprimento : 0,
            vlDiametro: props.vlDiametro !== undefined ? props.vlDiametro : 0,
            flAvisoRecebimento: false,
            flMaoPropria: false,
            cotacoes: []
        };

        this.state.produtoServicoTipoObjeto = {
            flLargura: false,
            flAltura: false,
            flComprimento: false,
            flDiametro: false
        };

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            //{ value: "E", label: "Estrangeiro" }
        ];

        this.state.crud = {
            service: CotacaoFreteService,
            labels: {
                title: 'Estado',
            },
            urlList: '/LstEstado',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        ConfiguracaoService.getOneByChave("COTACAO_CNPJ_REMETENTE", (response) => {
            this.setState(state => {
                state.model.cdCnpjRemetente = response.valor;
                return state;
            });
        });

        ConfiguracaoService.getOneByChave("COTACAO_CEP_REMETENTE", (response) => {
            this.setState(state => {
                state.model.cdCepOrigem = response.valor;
                return state;
            });
        });

        if (parseInt(this.state.model.idRepresentante) > 0) {
            RepresentanteService.getOneById(this.state.model.idRepresentante, (representante) => {
                ProprietarioService.getOneById(representante.idProprietario, (proprietario) => {
                    this.setState(state => {
                        state.model.cdTipoPessoaDestinatario = proprietario.cdTipoPessoa;
                        if (proprietario.cdTipoPessoa === "F") {
                            state.model.cdCpfDestinatario = proprietario.cpf;
                        } else if (proprietario.cdTipoPessoa === "J") {
                            state.model.cdCnpjDestinatario = proprietario.cnpj;
                        }
                        return state;
                    });
                });
            });
        } else if (parseInt(this.state.model.idProprietario) > 0) {
            ProprietarioService.getOneById(this.state.model.i.idProprietario, (proprietario) => {
                this.setState(state => {
                    state.model.cdTipoPessoaDestinatario = proprietario.cdTipoPessoa;
                    if (proprietario.cdTipoPessoa === "F") {
                        state.model.cdCpfDestinatario = proprietario.cpf;
                    } else if (proprietario.cdTipoPessoa === "J") {
                        state.model.cdCnpjDestinatario = proprietario.cnpj;
                    }
                    return state;
                });
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event) {
        CotacaoFreteService.getListServico({
            idFornecedor: this.state.model.idFornecedor,
            idModalidadeFrete: this.state.model.idModalidadeFrete
        }, (servicos) => {
            servicos.forEach((servico, index) => {
                var parametro = this.state.model;
                parametro.idFornecedorServico = servico.idFornecedorServico;
                CotacaoFreteService.getCotacao(parametro, (response) => {
                    this.setState(state => {
                        state.model.cotacoes.push(response);
                        return state;
                    });
                });
            });
        });
    }

    handleClickNovaCotacao() {
        this.setState(state => {
            state.model.cotacoes = [];
            return state;
        });
    }

    handleClickSelecionarCotacao(item) {
        this.onSave(item);
    }

    handleChangeTipoObjeto() {
        ProdutoServicoTipoObjetoService.getOneById(this.state.model.idProdutoServicoTipoObjeto, response => {
            this.setState((state) => {
                state.produtoServicoTipoObjeto = response;
                return state;
            });
        });
    }

    getBtns() {
        if (this.state.model.cotacoes.length === 0) {
            return (
                <div className="d-grid gap-2 d-md-flex">
                    <BtnSubmit className="btn-primary"> Obter Cotação </BtnSubmit>
                    <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
                </div>
            );
        }
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="btn-outline-primary" onClick={this.handleClickNovaCotacao}> Nova Cotação </BtnButton>
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
            </div>
        );
    }


    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputCnpj label="CNPJ Remetente" required={true} md={4} name="cdCnpjRemetente" value={this.state.model.cdCnpjRemetente ? this.state.model.cdCnpjRemetente : ""} onChange={this.handleChange} />

                    <BtnGroup required={true} md={4} className="w-100" name="cdTipoPessoaDestinatario" value={this.state.model.cdTipoPessoaDestinatario} label="Tipo do Cliente" options={this.state.tipoPessoa} onChange={this.handleChange} />
                    {this.state.model.cdTipoPessoaDestinatario === "F" ? <InputCpf label="CPF Destinatário" required={true} md={4} name="cdCpfDestinatario" value={this.state.model.cdCpfDestinatario ? this.state.model.cdCpfDestinatario : ""} onChange={this.handleChange} /> : <></>}
                    {this.state.model.cdTipoPessoaDestinatario === "J" ? <InputCnpj label="CNPJ Destinatário" required={true} md={4} name="cdCnpjDestinatario" value={this.state.model.cdCnpjDestinatario ? this.state.model.cdCnpjDestinatario : ""} onChange={this.handleChange} /> : <></>}

                    <InputCep label="CEP de Origem" autoFocus={true} md={2} value={this.state.model.cdCepOrigem} name="cdCepOrigem" onChange={this.handleChange} required={true} disabled={this.state.model.cotacoes.length !== 0} />
                    <InputCep label="CEP de Destino" md={2} value={this.state.model.cdCepDestino} name="cdCepDestino" onChange={this.handleChange} required={true} disabled={this.state.model.cotacoes.length !== 0} />

                    <SelectProdutoServicoTipoObjeto showBtns={false} md={8} label="Tipo da Objeto" placeholder="Selecione o Tipo de Objeto" name="idProdutoServicoTipoObjeto" value={this.state.model.idProdutoServicoTipoObjeto} onChange={e => this.handleChange(e, this.handleChangeTipoObjeto)} required={true} disabled={this.state.model.cotacoes.length !== 0} />
                    {/*<SelectTipoServicoCorreio md={4} label="Tipo de Serviço" value={this.state.model.idTipoServico} name="idTipoServico" onChange={this.handleChange} required={true} placeholder="Selecione o Tipo de Serviço" disabled={this.state.model.cotacoes.length !== 0} />*/}

                    <InputNumber md={2} label='Quantidade' className="text-center" value={this.state.model.nrQuantidade} name="nrQuantidade" onChange={this.handleChange} required={true} disabled={this.state.model.cotacoes.length !== 0} />
                    <InputValorReais label="Valor Declarado" md={2} className="text-end" value={this.state.model.vlDeclarado} name="vlDeclarado" onChange={this.handleChange} required={true} disabled={this.state.model.cotacoes.length !== 0} />
                    <InputPeso className="text-end" md={2} label='Peso (KG)' value={this.state.model.vlPesoBruto} name="vlPesoBruto" onChange={this.handleChange} required={true} disabled={this.state.model.cotacoes.length !== 0} />

                    {Boolean(this.state.produtoServicoTipoObjeto.flLargura) ?
                        <InputNumber md={2} label='Largura (cm)' value={this.state.model.vlLargura} name="vlLargura" onChange={this.handleChange} required={true} disabled={this.state.model.cotacoes.length !== 0} />
                        : <></>}

                    {Boolean(this.state.produtoServicoTipoObjeto.flAltura) ?
                        <InputNumber md={2} label='Altura (cm)' value={this.state.model.vlAltura} name="vlAltura" onChange={this.handleChange} required={true} disabled={this.state.model.cotacoes.length !== 0} />
                        : <></>}

                    {Boolean(this.state.produtoServicoTipoObjeto.flComprimento) ?
                        <InputNumber md={2} label='Comprimento (cm)' value={this.state.model.vlComprimento} name="vlComprimento" onChange={this.handleChange} required={true} disabled={this.state.model.cotacoes.length !== 0} />
                        : <></>}

                    {Boolean(this.state.produtoServicoTipoObjeto.flDiametro) ?
                        <InputNumber md={2} label='Diâmetro (cm)' value={this.state.model.vlDiametro} name="vlDiametro" onChange={this.handleChange} required={true} disabled={this.state.model.cotacoes.length !== 0} />
                        : <></>}
                </div>
                <div className="row px-3 mb-3">
                    <InputCheckSwitch label="Aviso de recebimento?" name="flAvisoRecebimento" checked={Boolean(this.state.model.flAvisoRecebimento)} onChange={this.handleChange} disabled={this.state.model.cotacoes.length !== 0} />
                    <InputCheckSwitch label="Mão própria?" name="flMaoPropria" checked={Boolean(this.state.model.flMaoPropria)} onChange={this.handleChange} disabled={this.state.model.cotacoes.length !== 0} />
                </div>

                {this.state.model.cotacoes.length !== 0 ?
                    <>
                        <h6 className="border-bottom border-secondary text-secondary"> Cotações  </h6>

                        <div className="table-responsive">
                            <table className="table table-sm">
                                <thead className="table-light">
                                    <tr>
                                        <th> &nbsp; </th>
                                        <th className="col-md-4"> Fornecedor </th>
                                        <th className="col-md-2"> Frete </th>
                                        <th className="col-md-2"> Serviço </th>
                                        <th className="col-md-2"> Transporte </th>
                                        <th className="col-md-2 text-center"> Prazo </th>
                                        <th className="text-end"> Valor </th>
                                        <th className="text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.cotacoes.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <tr className={index % 2 === 0 ? 'bg-light' : ''}>
                                                <td className="text-center align-middle" rowSpan={Boolean(item.flErro) ? 2 : 1}>
                                                    {Boolean(item.flErro) ?
                                                        <span className="text-danger">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                                            </svg>
                                                        </span>
                                                        :
                                                        <span className="text-success">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                            </svg>
                                                        </span>
                                                    }
                                                </td>
                                                <td>{item.nomeFornecedor}</td>
                                                <td>{item.nomeTipoFrete}</td>
                                                <td>{item.nomeFornecedorServico}</td>
                                                <td>{item.nomeModalidadeFrete}</td>
                                                <td className="text-center">
                                                    {Boolean(item.flErro) ? "" : item.nrPrazoEntrega + " dias"}
                                                </td>
                                                <td className="text-end fw-bold text-primary">
                                                    {Boolean(item.flErro) ? "" : Util.floatToReais(item.vlTransporte)}
                                                </td>
                                                <td className="align-middle">
                                                    <BtnButton className="btn-outline-success text-nowrap pt-0 pb-0" onClick={(e) => { this.handleClickSelecionarCotacao(item) }} disabled={Boolean(item.flErro)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2" viewBox="0 0 16 16">
                                                            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                                                        </svg> Selecionar
                                                    </BtnButton>
                                                </td>
                                            </tr>
                                            {Boolean(item.flErro) ?
                                                <tr className={index % 2 === 0 ? 'bg-light' : ''}>
                                                    <td colSpan={7} className="text-danger fs-7">
                                                        {item.dsErro}
                                                    </td>
                                                </tr>
                                                : <></>}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </> : <></>}
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCotacaoFrete {...props} navigate={navigate} params={params} />
}

export default With