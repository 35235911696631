import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import RepresentanteService from "../../services/RepresentanteService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectPais from "../../components/app/SelectPais";
import SelectProprietario from "../../components/app/SelectProprietario";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import InputText from "../../components/forms/elements/InputText";
import InputEmail from "../../components/forms/elements/InputEmail";
import InputUrl from "../../components/forms/elements/InputUrl";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputCpf from "../../components/forms/custom/InputCpf";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import EventBus from "../../controllers/EventBus";
import FormEndereco from "../Endereco/FormEndereco";
import FormEnderecoSelecao from "../Endereco/FormEnderecoSelecao";
import FormRepresentanteEquipamento from "./FormRepresentanteEquipamento";
import FormRepresentanteItemAssociado from "./FormRepresentanteItemAssociado";
import FormRepresentanteContrato from "./FormRepresentanteContrato";
import FormRepresentanteContratoDados from "../Contrato/FormRepresentanteContratoDados";
import FormRepresentanteContratoArquivo from "./FormRepresentanteContratoArquivo";
import SelectRepresentanteByProprietario from "../../components/app/SelectRepresentanteByProprietario";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import SelectDDI from "../../components/app/SelectDDI";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import RepresentanteEquipamentoService from "../../services/RepresentanteEquipamentoService";
import Util from "../../utils/Util";
import RepresentanteItemAssociadoService from "../../services/RepresentanteItemAssociadoService";
import EnderecoService from "../../services/EnderecoService";
import ProprietarioService from "../../services/ProprietarioService";
import BtnButton from "../../components/forms/elements/BtnButton";
import { toast } from "react-toastify";
import RepresentanteContratoService from "../../services/RepresentanteContratoService";
import InputFile from "../../components/forms/elements/InputFile";
import Config from "../../Config";
import ArquivoService from "../../services/ArquivoService";
import RepresentanteDocumentoService from "../../services/RepresentanteDocumentoService";

class FormRepresentante extends CrudForm {

    constructor(props) {
        super(props);

        this.handleAddEndereco = this.handleAddEndereco.bind(this);
        this.handleDeleteEndereco = this.handleDeleteEndereco.bind(this);
        this.handleEditEndereco = this.handleEditEndereco.bind(this);
        this.handleCopyEndereco = this.handleCopyEndereco.bind(this);
        this.handleImportEndereco = this.handleImportEndereco.bind(this);

        this.handleAddTelefone = this.handleAddTelefone.bind(this);
        this.handleChangePrincipalTelefone = this.handleChangePrincipalTelefone.bind(this);
        this.handleDeleteTelefone = this.handleDeleteTelefone.bind(this);

        this.handleAddEmail = this.handleAddEmail.bind(this);
        this.handleDeleteEmail = this.handleDeleteEmail.bind(this);
        this.handleChangePrincipalEmail = this.handleChangePrincipalEmail.bind(this);

        this.handleAddEquipamento = this.handleAddEquipamento.bind(this);
        this.handleEditEquipamento = this.handleEditEquipamento.bind(this);
        this.handleDeleteEquipamento = this.handleDeleteEquipamento.bind(this);
        this.handleRefreshEquipamento = this.handleRefreshEquipamento.bind(this);
        
        this.handleRefreshItemAssociado = this.handleRefreshItemAssociado.bind(this);
        this.handleAddItemAssociado = this.handleAddItemAssociado.bind(this);
        this.handleEditItemAssociado = this.handleEditItemAssociado.bind(this);
        this.handleDeleteItemAssociado = this.handleDeleteItemAssociado.bind(this);

        this.handleRefreshContrato = this.handleRefreshContrato.bind(this);
        this.handleAddContrato = this.handleAddContrato.bind(this);
        this.handleEditContrato = this.handleEditContrato.bind(this);
        this.handleDeleteContrato = this.handleDeleteContrato.bind(this);

        this.handleFileChangeDocumento = this.handleFileChangeDocumento.bind(this);
        this.handleDeleteDocumento = this.handleDeleteDocumento.bind(this);
        this.handleSaveItemDocumento = this.handleSaveItemDocumento.bind(this);
        this.handleRefreshDocumento = this.handleRefreshDocumento.bind(this);

        this.state.isCard = true;
        this.state.container = false;

        this.state.model = {
            idRepresentante: "",
            idProprietario: "",
            empresa: "",
            contato: "",
            idPais: "",
            idEstado: "",
            idCidade: "",
            site: "",
            cpf: "",
            cnpj: "",
            ativo: true,
            flMovel: false,
            flRanking: true,
            flAgrupador: false,
            flLocalizacao : true,
            idRepresentanteAgrupador: "",
            representanteEndereco: [],
            representanteTelefone: [],
            representanteEmail: [],
            representanteEquipamento: [],
            representanteItemAssociado: [],
            representanteContrato: [],
            representanteDocumento : []
        };

        this.state.crud = {
            service: RepresentanteService,
            labels: {
                title: 'Representante',
            },
            urlList: '/LstRepresentante',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleImportEndereco() {
        ProprietarioService.getOneById(this.state.model.idProprietario, (response) =>{
            if(response.proprietarioEndereco.length > 0){
                EventBus.dispatch("modal-add", {
                    title: 'Seleção de Endereço',
                    sizeModal: 'modal-xl',
                    scrollable: false,
                    config: {
                        isModal: true,
                        enderecos:  response.proprietarioEndereco
                    },
                    form: FormEnderecoSelecao,
                        onSave: (response) => {
                            setTimeout(()=>{
                                this.handleCopyEndereco(response);
                            },300)
                        },
                    });
            }else{
                toast.warn("Nenhum endereço encontrado!");
            }

        });
    }

    handleAddEndereco() {
        EventBus.dispatch("modal-add", {
            title: 'Cadastro de Endereço',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                disabledValuesTipoEndereco: [2, 3].concat(this.state.model.representanteEndereco.map(o => o.idTipoEndereco))
            },
            form: FormEndereco,
            onSave: (response) => {
                this.setState(state => {
                    state.model.representanteEndereco.push({
                        idEndereco: response.data.idEndereco,
                        idTipoEndereco: response.data.idTipoEndereco,
                        nomeTipoEndereco: response.data.nomeTipoEndereco,
                        idPais: response.data.idPais,
                        nomePais: response.data.nomePais,
                        idEstado: response.data.idEstado,
                        nomeEstado: response.data.nomeEstado,
                        idCidade: response.data.idCidade,
                        nomeCidade: response.data.nomeCidade,
                        cep: response.data.cep,
                        endereco: response.data.endereco,
                        bairro: response.data.bairro,
                        numero: response.data.numero,
                        complemento: response.data.complemento
                    });
                    return state;
                })
            },
        });
    }

    handleCopyEndereco(item) {
        EventBus.dispatch("modal-add", {
            title: 'Copiar de Endereço',
            sizeModal: 'modal-xl',
            scrollable: false,            
            config: {
                isModal: true,
                disabledValuesTipoEndereco: [2, 3].concat(this.state.model.representanteEndereco.map(o => o.idTipoEndereco)),
                isCopy : true,
                id: item.idEndereco
            },
            form: FormEndereco,
                onSave: (response) => {
                    this.setState(state => {
                        state.model.representanteEndereco.push({
                            idEndereco: response.data.idEndereco,
                            idTipoEndereco: response.data.idTipoEndereco,
                            nomeTipoEndereco: response.data.nomeTipoEndereco,
                            idPais: response.data.idPais,
                            nomePais: response.data.nomePais,
                            idEstado: response.data.idEstado,
                            nomeEstado: response.data.nomeEstado,
                            idCidade: response.data.idCidade,
                            nomeCidade: response.data.nomeCidade,
                            cep: response.data.cep,
                            endereco: response.data.endereco,
                            bairro: response.data.bairro,
                            numero: response.data.numero,
                            complemento: response.data.complemento
                        });
                        return state;
                    })
                },
            });
    }

    handleEditEndereco(index, item) {
        EventBus.dispatch("modal-add", {
            title: 'Editar de Endereço',
            sizeModal: 'modal-xl',
            scrollable: false,            
            config: {
                isModal: true,
                id: item.idEndereco
            },
            form: FormEndereco,
            onSave: (response) => {
                EnderecoService.getOneById(item.idEndereco, (response) =>{
                    this.setState(state => {
                        state.model.representanteEndereco.splice(index, 1);
                        state.model.representanteEndereco.push({
                            idEndereco: response.idEndereco,
                            idTipoEndereco: response.idTipoEndereco,
                            nomeTipoEndereco: response.nomeTipoEndereco,
                            idPais: response.idPais,
                            nomePais: response.nomePais,
                            idEstado: response.idEstado,
                            nomeEstado: response.nomeEstado,
                            idCidade: response.idCidade,
                            nomeCidade: response.nomeCidade,
                            cep: response.cep,
                            endereco: response.endereco,
                            bairro: response.bairro,
                            numero: response.numero,
                            complemento: response.complemento
                        });
                        return state;
                    })
                })
                
            },
        });
    }

    handleDeleteEndereco(index, item) {
        this.setState((state) => {
            state.model.representanteEndereco.splice(index, 1);
            return state;
        });
    }

    handleChangePrincipalTelefone(index, item) {
        this.setState(state => {
            for (var i in state.model.representanteTelefone) {
                state.model.representanteTelefone[i].flPrincipal = false
            }
            state.model.representanteTelefone[index].flPrincipal = true;
            return state;
        });
    }

    handleAddTelefone() {
        this.setState(state => {
            state.model.representanteTelefone.push({
                idProprietarioTelefone: "",
                ddi: 55,
                telefone: "",
                flWhatsapp: false,
                flPrincipal: state.model.representanteTelefone.findIndex(i => i.flPrincipal) === -1
            });
            return state;
        });
    }

    handleDeleteTelefone(index, item) {
        this.setState((state) => {
            state.model.representanteTelefone.splice(index, 1);
            return state;
        });
    }

    handleChangePrincipalEmail(index, item) {
        this.setState(state => {
            for (var i in state.model.representanteEmail) {
                state.model.representanteEmail[i].flPrincipal = false
            }
            state.model.representanteEmail[index].flPrincipal = true;
            return state;
        });
    }

    handleAddEmail() {
        this.setState(state => {
            state.model.representanteEmail.push({
                idProprietarioEmail: "",
                email: "",
                flPrincipal: state.model.representanteEmail.findIndex(i => i.flPrincipal) === -1
            });
            return state;
        });
    }

    handleDeleteEmail(index, item) {
        this.setState((state) => {
            state.model.representanteEmail.splice(index, 1);
            return state;
        });
    }

    handleAddEquipamento() {
        EventBus.dispatch("modal-add", {
            title: 'Equipamentos do Representante',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                idRepresentante : this.state.model.idRepresentante
            },
            form: FormRepresentanteEquipamento,
            onSave: (response) => {
                this.handleRefreshEquipamento();
            },
        });
    }

    handleEditEquipamento(item) {
        EventBus.dispatch("modal-add", {
            title: 'Equipamentos do Representante',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                id : item.idRepresentanteEquipamento
            },
            form: FormRepresentanteEquipamento,
            onSave: (response) => {
                this.handleRefreshEquipamento();
            },
        });
    }

    handleDeleteEquipamento(item) {
        EventBus.dispatch("modal-delete-open", {
            config: {
                title: "Excluir o equipamento?",
                message: <>Deseja realmente excluir o equipamento: <strong>{item.nomeEquipamento} </strong>?</>,
                btnConfirmText: "Sim, excluir o equipamento",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm : () =>{
                    RepresentanteEquipamentoService.delete(item.idRepresentanteEquipamento, ()=>{
                        this.handleRefreshEquipamento();
                    });
                }
            }
        })
    }

    handleRefreshEquipamento() {
        RepresentanteEquipamentoService.listAllForRepresentante(this.state.model.idRepresentante, (response)=>{
            this.setState((state) => {
                state.model.representanteEquipamento = response;
                return state;
            });
        });
    }

    handleAddItemAssociado() {
        
        var itemAssociado;
        var model = {
            isModal: true,
            idRepresentante : this.state.model.idRepresentante
        };

        if(this.state.model.representanteItemAssociado.length >0){
            itemAssociado = this.state.model.representanteItemAssociado[this.state.model.representanteItemAssociado.length - 1]; 
        }

        if(Boolean(itemAssociado?.flAtivo)){            
            model.idPlanoRecorrencia = itemAssociado?.idPlanoRecorrencia;
            model.idCategoriaProdutoServico = itemAssociado?.idCategoriaProdutoServico;
            model.idTipoProdutoServico = itemAssociado?.idTipoProdutoServico;
            model.flPrecoFixo = itemAssociado?.flPrecoFixo;        
            model.flVigenciaData = itemAssociado?.flVigenciaData;
            model.dtInicio = itemAssociado?.dtInicio;
            model.dtFim = itemAssociado?.dtFim;
        }

        EventBus.dispatch("modal-add", {
            title: 'Itens Associados do Representante',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: model,
            form: FormRepresentanteItemAssociado,
            onSave: (response) => {
                this.handleRefreshItemAssociado();
            },
        });      
    }

    handleEditItemAssociado(item) {
        EventBus.dispatch("modal-add", {
            title: 'ItemAssociados do Representante',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                id : item.idRepresentanteItemAssociado
            },
            form: FormRepresentanteItemAssociado,
            onSave: (response) => {
                this.handleRefreshItemAssociado();
            },
        });
    }

    handleDeleteItemAssociado(item) {
        EventBus.dispatch("modal-delete-open", {
            config: {
                title: "Excluir o equipamento?",
                message: <>Deseja realmente excluir o equipamento: <strong>{item.nomeItemAssociado} </strong>?</>,
                btnConfirmText: "Sim, excluir o equipamento",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm : () =>{
                    RepresentanteItemAssociadoService.delete(item.idRepresentanteItemAssociado, ()=>{
                        this.handleRefreshItemAssociado();
                    });
                }
            }
        })
    }

    handleRefreshItemAssociado() {
        RepresentanteItemAssociadoService.listAllForRepresentante(this.state.model.idRepresentante, (response)=>{
            this.setState((state) => {
                state.model.representanteItemAssociado = response;
                return state;
            });
        });
    }

    handleAddContrato() {        
        EventBus.dispatch("modal-add", {
            title: 'Novo Contrato do Representante',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                idRepresentante : this.state.model.idRepresentante
            },
            form: FormRepresentanteContrato,
            onSave: (response) => {
                this.handleRefreshContrato();
            },
        });      
    }

    handleEditContrato(item) {
        EventBus.dispatch("modal-add", {
            title: 'Edição do Contrato do Representante',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                id : item.idRepresentanteContrato
            },
            form: FormRepresentanteContrato,
            onSave: (response) => {
                this.handleRefreshContrato();
            },
        });
    }

    handleDeleteContrato(item) {
        EventBus.dispatch("modal-delete-open", {
            config: {
                title: "Excluir o contrato?",
                message: <>Deseja realmente excluir o contrato: <strong>{item.nomeContrato} </strong>?</>,
                btnConfirmText: "Sim, excluir o contrato",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm : () =>{
                    RepresentanteContratoService.delete(item.idRepresentanteContrato, ()=>{
                        this.handleRefreshContrato();
                    });
                }
            }
        })
    }

    handleRefreshContrato() {
        RepresentanteContratoService.listAllForRepresentante(this.state.model.idRepresentante, (response)=>{
            this.setState((state) => {
                state.model.representanteContrato = response;
                return state;
            });
        });
    }

    handleVerContrato(item) {
        EventBus.dispatch("modal-add", {
            title: 'Dados do Contrato',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                id : item.idRepresentanteContrato
            },
            form: FormRepresentanteContratoDados,
            onSave: (response) => {
                this.handleRefreshContrato();
            },
        });
    }

    handleVerArquivo(item) {
        EventBus.dispatch("modal-add", {
            title: 'Arquivos do Contrato',
            sizeModal: 'modal-xl',
            scrollable : true,
            config: {
                isModal: true,                
                id : item.idRepresentanteContrato
            },
            form: FormRepresentanteContratoArquivo,
            onSave: (response) => {
                this.handleRefreshContrato();
            },
        });
    }

    handleFileChangeDocumento(event) {
        var files = event.target?.files;
        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                ArquivoService.upload(files[i], (response) => {
                    let data = {
                        idRepresentante : this.state.model.idRepresentante,
                        idArquivo : response.data.idArquivo
                    };
                    
                    RepresentanteDocumentoService.create(data, (respArquivo)=>{
                        this.setState((state) => {
                            state.model.representanteDocumento.push(respArquivo.data);
                            return state;
                        });
                    })
                    
                });
            }
        }
    }

    handleDeleteDocumento(index, item) {
        RepresentanteDocumentoService.delete(item.idRepresentanteDocumento, (response)=>{
            this.setState((state) => {
                this.handleRefreshDocumento();
            });
        })
    }

    handleRefreshDocumento() {
        RepresentanteDocumentoService.listAllForRepresentante(this.state.model.idRepresentante, (response)=>{
            this.setState((state) => {
                state.model.representanteDocumento = response;
                return state;
            });
        });
    }

    handleSaveItemDocumento(item) {
        RepresentanteDocumentoService.update(item.idRepresentanteDocumento, item);
    }
    

    getCardHeader() {
        var locationHash = this.props.location.hash;

        if(locationHash === ""){
            locationHash = "#nav-cadastrais";
        }

        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <Link to={'#nav-cadastrais'} className={`nav-link ${locationHash === "#nav-cadastrais" ? "active" : ""}`}>
                        Cadastrais
                    </Link>
                </li>                
                <li className="nav-item">
                    <Link to={'#nav-endereco'} className={`nav-link ${locationHash === "#nav-endereco" ? "active" : ""}`}>
                        Endereços
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={'#nav-telefone'} className={`nav-link ${locationHash === "#nav-telefone" ? "active" : ""}`}>
                        Telefones
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to={'#nav-email'} className={`nav-link ${locationHash === "#nav-email" ? "active" : ""}`}>
                        E-mail
                    </Link>
                </li>
                {parseInt(this.state.model.idRepresentante) > 0 ?
                    <li className="nav-item">
                        <Link to={'#nav-equipamento'} className={`nav-link ${locationHash === "#nav-equipamento" ? "active" : ""}`}>
                            Equipamentos
                        </Link>
                    </li>
                : <></>}
                {parseInt(this.state.model.idRepresentante) > 0 ?
                    <li className="nav-item">
                        <Link to={'#nav-cobranca-extra'} className={`nav-link ${locationHash === "#nav-cobranca-extra" ? "active" : ""}`}>
                            Itens Associados
                        </Link>
                    </li>
                : <></>}
                {parseInt(this.state.model.idRepresentante) > 0 ?
                    <li className="nav-item">
                        <Link to={'#nav-contrato'} className={`nav-link ${locationHash === "#nav-contrato" ? "active" : ""}`}>
                            Contrato
                        </Link>
                    </li>
                : <></>}
                {parseInt(this.state.model.idRepresentante) > 0 ?
                    <li className="nav-item">
                        <Link to={'#nav-documento'} className={`nav-link ${locationHash === "#nav-documento" ? "active" : ""}`}>
                            Documentos
                        </Link>
                    </li>
                : <></>}
            </ul>
        );
    }

    getRenderFields() {

        var locationHash = this.props.location.hash;

        if(locationHash === ""){
            locationHash = "#nav-cadastrais";
        }

        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className={`tab-pane fade ${locationHash === "#nav-cadastrais" ? "show active" : ""}`} id="nav-cadastrais" role="tabpanel" aria-labelledby="nav-cadastrais-tab">
                
                    {!this.props.flAtualizacao ?
                        <div className="row mb-3 g-3">
                            <InputText label="Código" md={3} value={this.state.model.idRepresentante} name="idRepresentante" readOnly opcionalLabel={false} />
                            <SelectProprietario label="Nome do Proprietário" required placeholder="Selecione o Proprietário" name="idProprietario" md={9} value={this.state.model.idProprietario} onChange={this.handleChange} />
                        </div>
                        : <></>
                    }
                    <div className="row mb-3 g-3">
                        <InputText label="Nome da Empresa" required md={12} value={this.state.model.empresa} name="empresa" onChange={this.handleChange} maxLength={100} />
                        <InputText label="Nome do Contato" md={4} value={this.state.model.contato} name="contato" onChange={this.handleChange} maxLength={100} />

                        <InputCpf label="CPF" md={4} value={this.state.model.cpf || ""} name="cpf" onChange={this.handleChange} />
                        <InputCnpj label="CNPJ" md={4} value={this.state.model.cnpj || ""} name="cnpj" onChange={this.handleChange} />

                        <SelectPais label="Nome do País" required placeholder="Selecione o País" name="idPais" md={4} value={this.state.model.idPais} onChange={this.handleChange} />
                        <SelectEstadoByPais required disabled={!this.state.model.idPais} idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" md={4} onChange={this.handleChange} />
                        <SelectCidadeByEstado required disabled={!this.state.model.idEstado} idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" md={4} onChange={this.handleChange} />

                        <InputUrl label="Site" md={6} value={this.state.model.site} name="site" onChange={this.handleChange} maxLength={100} placeholder="https://www.site.com.br" />
                    </div>

                    <div className="row mb-3 mx-1">
                        <InputCheckSwitch label="É uma unidade móvel?" name="flMovel" checked={Boolean(this.state.model.flMovel)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Ativo?" name="ativo" checked={Boolean(this.state.model.ativo)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Aparece no Ranking?" name="flRanking" checked={Boolean(this.state.model.flRanking)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Agrupa Represenante?" name="flAgrupador" checked={Boolean(this.state.model.flAgrupador)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Aparece no Localizador?" name="flLocalizacao" checked={Boolean(this.state.model.flLocalizacao)} onChange={this.handleChange} />
                    </div>
                    {this.state.model.flAgrupador ?
                        <div className="row mb-3">
                            <SelectRepresentanteByProprietario showBtnNewForm={false} required={true} name="idRepresentanteAgrupador" disabledValues={[this.state.model.idRepresentante]} idProprietario={this.state.model.idProprietario}
                                label="Representante Agrupador" value={this.state.model.idRepresentanteAgrupador} placeholder="Selecione o Representante" md={12} onChange={this.handleChange}
                            />
                        </div>
                        : <></>
                    }
                </div>
                <div className={`tab-pane fade ${locationHash === "#nav-endereco" ? "show active" : ""}`} id="nav-endereco" role="tabpanel" aria-labelledby="nav-endereco-tab">
                    <div className="row mx-1 mb-3 g-1">
                        <div className="hstack gap-3 mb-2">
                            <div className="align-bottom fs-5">
                                Endereços
                            </div>
                            <div className="ms-auto">
                                <BtnButton className="ms-2 btn-outline-success text-nowrap" onClick={this.handleImportEndereco}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-reply-all-fill" viewBox="0 0 16 16">
                                            <path d="M8.021 11.9 3.453 8.62a.719.719 0 0 1 0-1.238L8.021 4.1a.716.716 0 0 1 1.079.619V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                                            <path d="M5.232 4.293a.5.5 0 0 1-.106.7L1.114 7.945a.5.5 0 0 1-.042.028.147.147 0 0 0 0 .252.503.503 0 0 1 .042.028l4.012 2.954a.5.5 0 1 1-.593.805L.539 9.073a1.147 1.147 0 0 1 0-1.946l3.994-2.94a.5.5 0 0 1 .699.106z" />
                                        </svg> Importar do Proprietário
                                </BtnButton>

                                <BtnButton className="ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddEndereco}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar Endereço
                                </BtnButton>                                
                            </div>
                        </div>                

                        <div className="table-responsive">
                            <table className="table table-sm caption-top">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col-1"> Tipo </th>
                                        <th className="col-3"> Cidade </th>
                                        <th className="col-3"> Endereço </th>
                                        <th className="col-2"> Bairro </th>
                                        <th className="col-1"> CEP </th>
                                        <th className="col-2"> Complemento </th>
                                        <th className="text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.representanteEndereco.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td>{item.nomeTipoEndereco}</td>
                                            <td>{item.nomeCidade}, {item.nomeEstado}, {item.nomePais}</td>
                                            <td>{item.endereco}, {item.numero}</td>
                                            <td>{item.bairro}</td>
                                            <td>{item.cep}</td>
                                            <td>{item.complemento}</td>
                                            <td className="text-end d-flex flex-nowrap gap-2">
                                                <button type="button" className="btn btn-sm btn-outline-success" onClick={() => { this.handleCopyEndereco(item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                                    </svg>
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => { this.handleEditEndereco(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                    </svg>
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => { this.handleDeleteEndereco(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>                                                
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-telefone" ? "show active" : ""}`} id="nav-telefone" role="tabpanel" aria-labelledby="nav-telefone-tab">
                    <div className="row mx-1 mb-3 g-1">
                        <div className="hstack gap-3 mb-2">
                            <div className="align-bottom fs-5">
                                Telefones
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddTelefone}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar Telefone
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col-2"> DDI </th>
                                        <th className="col-10"> Telefone </th>
                                        <th className="col text-end">&nbsp;</th>
                                        <th className="col text-end">&nbsp;</th>
                                        <th className="col text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.representanteTelefone.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td>
                                                <SelectDDI showBtns={false} required={true} placeholder="Selecione" name="ddi" value={item.ddi} onChange={e => { this.handleChange(e, false, item) }} />
                                            </td>
                                            <td>
                                                {parseInt(item.ddi) === 55 ?
                                                    <InputTelCelular required={true} value={item.telefone} name="telefone" onChange={e => { this.handleChange(e, false, item) }} />
                                                    :
                                                    <InputText required={true} value={item.telefone} name="telefone" onChange={e => { this.handleChange(e, false, item) }} />
                                                }
                                            </td>
                                            <td>
                                                <InputCheckSwitch label="Principal?" name="flPrincipal" checked={Boolean(item.flPrincipal)} onChange={e => this.handleChangePrincipalTelefone(index, item)} />
                                            </td>
                                            <td>
                                                <InputCheckSwitch label="Whatsapp?" name="flWhatsapp" checked={Boolean(item.flWhatsapp)} onChange={e => this.handleChange(e, false, item)} />
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteTelefone(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-email" ? "show active" : ""}`} id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
                    <div className="row mx-1 mb-3 g-1">
                        <div className="hstack gap-3 mb-2">
                            <div className="align-bottom fs-5">
                                E-mails
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddEmail}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar E-mail
                                </button>
                            </div>
                        </div>

                        <hr />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col-11"> E-mail </th>
                                        <th className="col text-end">&nbsp;</th>
                                        <th className="col text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.representanteEmail.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td>
                                                <InputEmail placeholder="email@dominio.com" name="email" required value={item.email} onChange={e => this.handleChange(e, false, item)} />
                                            </td>
                                            <td>
                                                <InputCheckSwitch label="Principal?" name="flPrincipal" checked={Boolean(item.flPrincipal)} onChange={e => this.handleChangePrincipalEmail(index, item)} />
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteEmail(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg>
                                                </button>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-equipamento" ? "show active" : ""}`} id="nav-equipamento" role="tabpanel" aria-labelledby="nav-equipamento-tab">
                    <div className="row mx-1 mb-3 g-1">
                        <div className="hstack gap-3 mb-2">
                            <div className="align-bottom fs-5">
                                Equipamento(s) Vinculado(s)
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddEquipamento}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar Equipamento
                                </button>
                            </div>                            
                        </div>

                        <hr className="pb-3"/>

                        {this.state.model.representanteEquipamento.length === 0 ?
                            <div className="alert alert-warning text-center" role="alert">
                                <strong className="alert-heading">Atenção! </strong>
                                Nenhum equipamento está vinculado para esse representante!
                            </div>                            
                        :
                            <div className="table-responsive">
                                <table className="table table-sm table-striped">
                                    <caption className="text-end me-3">
                                        {this.state.model.representanteEquipamento.length} Registro(s)                                        
                                    </caption>
                                    <thead className="table-light">
                                        <tr>
                                            <th className="col-1 text-center"> Código </th> 
                                            <th className="col-3"> Equipamento </th>
                                            <th className="col-3"> Marca </th>
                                            <th className="col-2"> Modelo </th>
                                            <th className="col-1 text-center"> Atualização </th>
                                            <th className="col-1 text-center">Status</th>
                                            <th className="col-1 text-center">Atualiza?</th>
                                            <th className="text-end">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.model.representanteEquipamento.map((item, index) => (
                                            <tr key={index} className="tr-borderless">
                                                <td className="text-center">{item.idRepresentanteEquipamento}</td>
                                                <td>{item.nomeEquipamento}</td>
                                                <td>{item.dsMarcaEquipamento}</td>
                                                <td>{item.dsModeloEquipamento}</td>
                                                <td className="text-center">{Util.date2Br(item.dtUltimaAtualizacao)}</td>                                                
                                                <td className="text-center">
                                                    {Boolean(item.flAtivo) ? <span className="badge bg-success">ATIVO</span> : <span className="badge bg-danger">INATIVO</span>}
                                                </td>
                                                <td className="text-center">
                                                    {Boolean(item.flControlaAtualizacao) ? <span className="badge bg-success">SIM</span> : <span className="badge bg-danger">NÃO</span>}
                                                </td>                                         
                                                <td className="text-end d-flex flex-nowrap">
                                                    <button type="button" className="btn btn-sm ms-2 btn-outline-primary" onClick={() => { this.handleEditEquipamento(item) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                        </svg>
                                                    </button>

                                                    <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteEquipamento(item) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg>
                                                    </button>
                                                </td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        }

                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-cobranca-extra" ? "show active" : ""}`} id="nav-cobranca-extra" role="tabpanel" aria-labelledby="nav-cobranca-extra-tab">
                    <div className="row mx-1 mb-3 g-1">
                        <div className="hstack gap-3 mb-2">
                            <div className="align-bottom fs-5">
                                Item(ns) Associado(s)
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddItemAssociado}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar Item
                                </button>
                            </div>                            
                        </div>

                        <hr className="pb-3"/>

                        {this.state.model.representanteItemAssociado.length === 0 ?
                            <div className="alert alert-warning text-center" role="alert">
                                <strong className="alert-heading">Atenção! </strong>
                                Nenhum PRODUTO ou SERVIÇO está associado para esse representante!
                            </div>                            
                        :
                            <div className="table-responsive">
                                <table className="table table-sm table-striped">
                                    <caption className="text-end me-3">
                                        {this.state.model.representanteItemAssociado.length} Registro(s)                                        
                                    </caption>
                                    <thead className="table-light">
                                        <tr>
                                            <th className="text-center"> Código </th> 
                                            <th className="col-4"> Item </th>
                                            <th className="col-2"> Recorrência </th>
                                            <th className="col-1 text-end"> Preço </th>
                                            <th className="col-1 text-center"> Quantidade </th>
                                            <th className="col-2 text-center"> Vigência </th>
                                            <th className="col-1 text-center">Status</th>
                                            <th className="col-1 text-center">Ordens</th>
                                            <th className="text-end">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.model.representanteItemAssociado.map((item, index) => {

                                            var objVigencia = <></>;
                                            switch(parseInt(item.cdVigencia)){
                                                case 0:
                                                    objVigencia = <span className="badge bg-warning">SEM VIGÊNCIA CADASTRADA</span>;
                                                    break; 

                                                case 1:
                                                    objVigencia = <span className="badge bg-success">VIGENTE ATÉ {Util.date2Br(item.dtFim)}</span>;
                                                    break;

                                                case 2:
                                                    objVigencia = <span className="badge bg-primary">INICIA EM {Util.date2Br(item.dtInicio)}</span>;
                                                    break;

                                                case 3:
                                                    objVigencia = <span className="badge bg-danger">EXPIRADO EM {Util.date2Br(item.dtFim)}</span>;
                                                    break;
                                                
                                                default:
                                                    objVigencia = <span className="badge bg-warning">SEM VIGÊNCIA CADASTRADA</span>;
                                            }

                                            return (
                                                <tr key={index} className="tr-borderless">
                                                    <td className="text-center">{item.idProdutoServico}</td>
                                                    <td>                                                    
                                                        <strong>{item.nomeProdutoServico}</strong>
                                                        <br/><i>{item.nomeTipoProdutoServico} - {item.nomeCategoriaProdutoServico}</i>
                                                    </td>
                                                    <td>{item.nomePlanoRecorrencia}</td>
                                                    <td className="text-end">
                                                        <strong>{Util.floatToReais(item.vlPreco)}</strong>
                                                        <br/>{Boolean(item.flPrecoFixo) ? <span className="badge bg-danger">FIXO</span> : <span className="badge bg-success">DINÂMICO</span>}
                                                    </td>
                                                    <td className="text-center">{item.nrQuantidade}</td>
                                                    <td className="text-center">
                                                        {objVigencia}
                                                        <br/><span className="fw-bold fs-7">{Boolean(item.flVigenciaData) ? Util.date2Br(item.dtInicio) + " à " + Util.date2Br(item.dtFim)  : <></>}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        {Boolean(item.flAtivo) ? <span className="badge bg-success">ATIVO</span> : <span className="badge bg-danger">INATIVO</span>}
                                                    </td>
                                                    <td className="text-center">{parseInt(item.nrQuantidadeOrdemItem)}</td>
                                                    <td className="text-end d-flex flex-nowrap">
                                                        <button type="button" className="btn btn-sm ms-2 btn-outline-primary" onClick={() => { this.handleEditItemAssociado(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                            </svg>
                                                        </button>
                                                        
                                                        <button type="button" disabled={parseInt(item.nrQuantidadeOrdemItem) > 0} className={`btn btn-sm ms-2 ${parseInt(item.nrQuantidadeOrdemItem) > 0 ? "btn-outline-secondary" : "btn-outline-danger"}`} onClick={() => { this.handleDeleteItemAssociado(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>);
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        }

                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-contrato" ? "show active" : ""}`} id="nav-contrato" role="tabpanel" aria-labelledby="nav-contrato-tab">
                    <div className="row mx-1 mb-3 g-1">
                        <div className="hstack gap-3 mb-2">
                            <div className="align-bottom fs-5">
                                Contratos
                            </div>
                            <div className="ms-auto">
                                <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddContrato}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar Contrato
                                </button>
                            </div>                            
                        </div>

                        <hr className="pb-3"/>

                        {this.state.model.representanteContrato.length === 0 ?
                            <div className="alert alert-warning text-center" role="alert">
                                <strong className="alert-heading">Atenção! </strong>
                                Nenhum Contrato Cadastrado
                            </div>                            
                        :
                            <div className="table-responsive">
                                <table className="table table-sm table-striped">
                                    <caption className="text-end me-3">
                                        {this.state.model.representanteContrato.length} Registro(s)                                        
                                    </caption>
                                    <thead className="table-light">
                                        <tr>
                                            <th className="text-center"> Código </th> 
                                            <th className="col-4"> Contrato </th>
                                            <th className="col-4"> Status </th>
                                            <th className="col-2 text-center"> Vigência </th>
                                            <th className="col-2 text-center">Status</th>                                            
                                            <th className="text-end">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.model.representanteContrato.map((item, index) => {

                                            var objVigencia = <></>;
                                            switch(parseInt(item.cdVigencia)){
                                                case 0:
                                                    objVigencia = <span className="badge bg-warning">SEM VIGÊNCIA CADASTRADA</span>;
                                                    break; 

                                                case 1:
                                                    objVigencia = <span className="badge bg-success">VIGENTE ATÉ {Util.date2Br(item.dtFim)}</span>;
                                                    break;

                                                case 2:
                                                    objVigencia = <span className="badge bg-primary">INICIA EM {Util.date2Br(item.dtInicio)}</span>;
                                                    break;

                                                case 3:
                                                    objVigencia = <span className="badge bg-danger">EXPIRADO EM {Util.date2Br(item.dtFim)}</span>;
                                                    break;
                                                
                                                default:
                                                    objVigencia = <span className="badge bg-warning">SEM VIGÊNCIA CADASTRADA</span>;
                                            }

                                            return (
                                                <tr key={index} className="tr-borderless">
                                                    <td className="text-center">{item.idRepresentanteContrato}</td>
                                                    <td>{item.nomeContrato}</td>
                                                    <td>{item.nomeContratoStatus}</td>
                                                    <td className="text-center">
                                                        {objVigencia}
                                                        <br/><span className="fw-bold fs-7">{Util.date2Br(item.dtInicio) + " à " + Util.date2Br(item.dtFim)}</span>
                                                    </td>
                                                    <td className="text-center">
                                                        {Boolean(item.flAtivo) ? <span className="badge bg-success">ATIVO</span> : <span className="badge bg-danger">INATIVO</span>}
                                                    </td>                                                    
                                                    <td className="text-end d-flex flex-nowrap">
                                                        <button type="button" disabled={!item.idContrato} className="btn ms-2 btn-outline-danger" onClick={() => { this.handleVerContrato(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-pdf-fill" viewBox="0 0 16 16">
                                                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z"/>
                                                                <path fill-rule="evenodd" d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"/>
                                                            </svg>
                                                        </button>

                                                        <button type="button" className="btn ms-2 btn-outline-info" onClick={() => { this.handleVerArquivo(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                                                            </svg>
                                                        </button>

                                                        <button type="button" className="btn ms-2 btn-outline-primary" onClick={() => { this.handleEditContrato(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                                            </svg>
                                                        </button>
                                                        
                                                        <button type="button" disabled={parseInt(item.nrQuantidadeOrdemItem) > 0} className={`btn ms-2 ${parseInt(item.nrQuantidadeOrdemItem) > 0 ? "btn-outline-secondary" : "btn-outline-danger"}`} onClick={() => { this.handleDeleteContrato(item) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>);
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        }

                    </div>
                </div>

                <div className={`tab-pane fade ${locationHash === "#nav-documento" ? "show active" : ""}`} id="nav-documento" role="tabpanel" aria-labelledby="nav-documento-tab">
                    <div className="row mb-3 g-3">
                        <InputFile label="Selecione o arquivo" name="arquivo" multiple onChange={(event) => { this.handleFileChangeDocumento(event) }} accept="*/*" />

                        <div className="table-responsive">
                            <table className="table table-sm caption-top table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th className="col"> Tipo </th>
                                        <th className="col-4"> Descrição </th>
                                        <th className="col-3"> Nome </th>
                                        <th className="col-3"> Arquivo </th>
                                        <th className="col-2 text-end">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.model.representanteDocumento.map((item, index) => (
                                        <tr key={index} className="tr-borderless">
                                            <td className="text-center">
                                                {Util.getIconeTipo(Util.getTipoArquivo(item.tipoArquivo), 32, 32)}
                                            </td>
                                            <td>
                                                <InputText value={item.nome} placeholder="Digite a descrição do documento" name="nome" onChange={e => { this.handleChange(e, false, item) }} onBlur={e=>{this.handleSaveItemDocumento(item)}}/>
                                            </td>
                                            <td>
                                                {item.nomeArquivo}
                                            </td>
                                            <td className="text-center">
                                                {Util.getTipoArquivo(item.tipoArquivo) === "IMAGEM" ?
                                                    <img style={{ maxHeight: "150px" }} src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uid} className="img-fluid img-thumbnail" alt="..." />
                                                    : <></>
                                                }
                                            </td>
                                            <td className="align-middle text-center text-nowrap">
                                                <a href={Config.getUrlArquivo() + "public/arquivo/download/" + item.uid} rel="noopener noreferrer" target="_blank" className="btn btn-sm btn-outline-primary"> 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                    </svg> Download 
                                                </a>
                                                
                                                <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleDeleteDocumento(index, item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg> Excluir Arquivo
                                                </button>

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <ModalConfirmacao id="modal-delete"/>
            </div>
                    
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <FormRepresentante {...props} navigate={navigate} params={params}  location={location}/>
}

export default With