import React from "react";
import ContatoStatusService from "../../services/ContatoStatusService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaContatoStatus extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ContatoStatusService,
            labels: {
                title: 'Lista de Status(s)',
                btnNewRegister: 'Status',
            },
            keyItem: 'idContatoStatus',
            urlForm: '/CadContatoStatus',
            itensFilter: ["idContatoStatus", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-4"> Nome </th>
                <th className="col-md-4"> Tipo </th>
                <th className="col-md-4"> Apresentação </th>
                <th className="col-md text-center"> Status </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idContatoStatus}</td>
                <td>{item.nome}</td>
                <td>{item.nomeContatoTipo}</td>
                <td>
                    <span className="badge fs-7 w-100" style={{ backgroundColor: item.cdBgColor, color: item.cdTxColor }}>
                        {item.nome}
                    </span>
                </td>
                <td className="text-center">
                    {parseInt(item.flAtivo) === 1 ?
                        <span className="badge w-100 text-bg-success">ATIVO</span> :
                        <span className="badge w-100 text-bg-danger">INATIVO</span>
                    }
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}