import { Component } from "react";
import Util from "../../../utils/Util";
import FormMask from "../base/FormMask";

export default class InputTelCelular extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

        if (props.value && Util.stringToNumber(props.value).length === 11) {
            this.state = { mask: "(99)99999-9999" };
        } else {
            this.state = { mask: "(99)9999-9999" };
        }
    }

    componentDidMount() {
        if (this.props.value) {
            //this.handleChange({ target: { value: this.props.value, name: this.props.name } });
        }
    }

    componentWillUnmount() {
    }

    componentDidUpdate(nextProps) {
        if (nextProps.value !== this.props.value) {
            if (Util.stringToNumber(this.props.value).length >= 11) {
                this.setState({ mask: "(99)99999-9999" });
            } else {
                this.setState({ mask: "(99)9999-9999" });
            }
        }
    }

    handleKeyPress(event) {

        if (this.props.onKeyPress) {
            this.props.onKeyPress(event)
        }

        if (Util.stringToNumber(event.target.value).length >= 10) {
            this.setState({ mask: "(99)99999-9999" });
        } else {
            this.setState({ mask: "(99)9999-9999" });
        }

    }

    handleChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event)
        }

        if (Util.stringToNumber(event.target.value).length === 10) {
            this.setState({ mask: "(99)9999-9999" });
        }
    }

    render() {
        return (
            <FormMask {...this.props}
                mask={this.state.mask}
                placeholder="(99)99999-9999"
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
            />);
    }
}