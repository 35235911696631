import { Component } from "react";
import FormMask from "../base/FormMask";

export default class InputCep extends Component {

    render() {
        return (
            <FormMask {...this.props}
                mask="99999-999"
                pattern="\d{5}-?\d{3}"
                placeholder="99999-999"
            />);
    }
}