import Http from "../controllers/Http";

export default class OrdemClienteService {

    static urlBase = "/ordemCliente/";

    static getOneByCelular(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byCelular', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByCpf(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byCpf', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByCnpj(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byCnpj', data)
            .then(fnSucess)
            .catch(fnError);
    }

}
