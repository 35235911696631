import React from "react";
import CrudForm from "../../components/forms/custom/CrudForm";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import Util from "../../utils/Util";
import Config from "../../Config";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnGroup from "../../components/forms/elements/BtnGroup";

export default class FormRelatorioProprietario001 extends CrudForm {

    constructor(props) {
        super(props);

        this.state.isModal = true;

        this.urlRelatorio = "relatorio/proprietario-003";

        this.state.model = {
            orientation: "L",
            destination: "I",
            output: "PDF",
            idOrdemTipo: "",
            flMarcaDagua: true,
        };

        this.state.orientation = [
            { value: 'P', label: 'Vertical' },
            { value: 'L', label: 'Horizontal' },
        ]

        this.state.destination = [
            { value: 'I', label: 'Tela' },
            { value: 'D', label: 'Download' },
        ]

        this.state.output = [
            { value: 'PDF', label: 'PDF' },
            { value: 'XLS', label: 'XLS' },
        ]
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(e) {
        Util.openNewWindow(Config.getUrlApi() + this.urlRelatorio, this.state.model);
    }

    getRenderFields() {
        return (
            <>
                <div className="row px-3 mb-3">
                    <InputCheckSwitch label="Mostrar marca d'água" name="flMarcaDagua" checked={Boolean(this.state.model.flMarcaDagua)} onChange={this.handleChange} />
                </div>
                <div className="row mb-3">
                    <BtnGroup md={4} sizing="sm" className="w-100" name="orientation" value={this.state.model.orientation} label="Disposição da Página" options={this.state.orientation} onChange={this.handleChange} required />
                    <BtnGroup md={4} sizing="sm" className="w-100" name="destination" value={this.state.model.destination} label="Destino do Relatório" options={this.state.destination} onChange={this.handleChange} required />
                    <BtnGroup md={4} sizing="sm" className="w-100" name="output" value={this.state.model.output} label="Formato de Saída" options={this.state.output} onChange={this.handleChange} required />
                </div>
            </>
        );
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex">
                <BtnButton className="ms-auto btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
                <div className="vr"></div>
                <BtnSubmit className="btn-primary"> Emitir Relatório </BtnSubmit>
            </div>);
    }

    render() {
        return super.render();
    }
}