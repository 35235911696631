import { Component } from "react";
import FormNumber from "../base/FormNumber";

export default class InputPeso extends Component {

    render() {
        return (
            <FormNumber {...this.props}
                thousandSeparator="."
                decimalSeparator=","
                inputMode="numeric"
                placeholder="99.999"
                fixedDecimalScale={true}
                decimalScale={3}
            />);
    }
}