import Http from "../controllers/Http";

export default class DashboardService {

    static urlBase = "/dashboard/";

    static getTop10RankingAbsoluto(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'top10RankingAbsoluto', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getTop10RankingValor(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'top10RankingValor', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getTopRankingAbsolutoByUsuario(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'topRankingAbsolutoByUsuario', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getTopRankingValorByUsuario(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'topRankingValorByUsuario', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getFinanceiro(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'financeiro', data)
            .then(fnSucess)
            .catch(fnError);
    }
}
