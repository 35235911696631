import { Component } from "react";
import FormMask from "../base/FormMask";

export default class InputCartaoCVV extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    handleChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        return (
            <FormMask {...this.props}
                style={{ textTransform: "uppercase" }}
                onChange={this.handleChange}
                mask="999"
                maskChar={null}
                pattern="\d{3}"
                placeholder="000"
            />);
    }
}