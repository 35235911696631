import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import AcervoService from "../../services/AcervoService";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import { toast } from "react-toastify";
import InputText from "../../components/forms/elements/InputText";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import Util from "../../utils/Util";
import EventBus from "../../controllers/EventBus";
import FormAcervoView from "./FormAcervoView";
import Permissao from "../../controllers/Permissao";

export default class ListaAcervo extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.clear = this.clear.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLista = this.getLista.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.handleChangeCategoria = this.handleChangeCategoria.bind(this);
        this.handleClickSincronizar = this.handleClickSincronizar.bind(this);
        this.state = this.getInitState(props);

        this.cache = {
            active: true,
            id: "cache-lista-acervo",
            clear: true,
            state: {
                model: {
                    cdFormatView: true,
                }
            }
        };
    }

    getInitState(props) {

        return {
            data: [],
            model: {
                flFiltroTipo: "T",
                cdFormatView : "C",
                busca: "",
                pagina: 0,
                rowCount: 50
            },

            filtroTipo: [
                { value: 'T', label: 'Todos' },                
                { value: 'V', label: 'Vídeo' },
                { value: 'I', label: 'Imagem' },
                { value: 'O', label: 'Outros' },
            ],

            filtroFormatView : [
                { 
                    value: "C", 
                    label: 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-table" viewBox="0 0 16 16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                        </svg>
                },
                { 
                    value: "T", 
                    label: 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-card-list" viewBox="0 0 16 16">
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                        </svg>
                }
                
            ]
        };

    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    clear() {
        this.setState(this.getInitState(), () => {
        });
    }

    handleClickSincronizar() {
        AcervoService.sync((response)=>{
            toast.success("Biblioteca Atualizada com Sucesso!");
            this.getLista();
        });
    }

    getLista(merge = false) {
        if (merge) {
            AcervoService.list(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                AcervoService.list(this.state.model, (response) => {
                    this.setState({ data: response });
                });
            });
        }
    }

    onSubmit(event) {
        this.getLista();
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    handleChangeCategoria() {
        this.setState(state => {
            state.model.idTipoProdutoServico = "";
            return state;
        }, this.onSubmit);
    }

    handleClickView(item) {
        EventBus.dispatch("modal-add", {
            title: 'Visualização',
            sizeModal: 'modal-xl',
            fullScreen : true,
            config: {
                isModal: true,
                idAcervo: item.idAcervo,
            },
            form: FormAcervoView
        });
    }

    render() {
        return (
            <div className="p-4 my-2 py-2 w-100">
                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100">
                        Biblioteca BDM
                    </h4>

                    {Permissao.getInstance().getPermissao(117) ?
                        <BtnButton className="btn-outline-dark text-nowrap d-none d-sm-block" onClick={this.handleClickSincronizar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-download" viewBox="0 0 16 16">
                                <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                                <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                            </svg> Sincronizar Agora
                        </BtnButton> : <></>}

                    
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row mb-3">
                        <InputText autoFocus={true} label="Busca" md={6} value={this.state.model.busca} placeholder="Digite a sua busca" name="busca" onChange={this.handleChange} maxLength={100} opcionalLabel={false} />
                        <BtnGroup md={4} className="w-100" name="flFiltroTipo" value={this.state.model.flFiltroTipo} label="Tipo do Arquivo" options={this.state.filtroTipo} onChange={e => this.handleChange(e, this.onSubmit)} opcionalLabel={false} />                        
                        <BtnGroup md={2} className="w-100" name="cdFormatView" value={this.state.model.cdFormatView} label="Apresentação" options={this.state.filtroFormatView} onChange={this.handleChange} opcionalLabel={false} />
                    </div>

                    <div className="mb-3 hstack gap-2">
                        <div className="ms-auto">
                            <BtnSubmit className="btn-outline-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg> Buscar
                            </BtnSubmit>
                        </div>
                        <div>
                            <BtnButton className="btn btn-outline-danger" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </BtnButton>
                        </div>
                    </div>
                </form>

                {this.state.model.cdFormatView === "C" ?
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-3">
                    {this.state.data.map((item, index) => (
                        <div className="col" key={index}>
                            <div className="card h-100">
                                <img src={item.urlMiniatura ? item.urlMiniatura : "/loja-sem-foto.webp"} className="card-img-top thumb-image-biblioteca-card" alt={item.nome}  onClick={e => this.handleClickView(item)} style={{ cursor: "pointer" }}/>
                                <div className="card-body">
                                    <img src={item.urlIcone} className="float-start pe-1" alt={item.nome}/>
                                    <h6 className="card-title">{item.nome}</h6>
                                    <p className="card-text">{item.descricao}</p>                                    
                                </div>
                                <div className="card-footer text-body-secondary fs-7 text-center">                                    
                                    Criado em {Util.date2Br(item.dataCriacao)} as {item.horaCriacao}
                                    <div className="row g-3 pt-1">
                                        <div className="col-6">
                                            <BtnButton className="btn btn-sm btn-outline-primary w-100" onClick={e => this.handleClickView(item)}> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                </svg> Visualizar 
                                            </BtnButton>
                                        </div>
                                        <div className="col-6">
                                            <a href={item.urlDownload} rel="noopener noreferrer" target="_blank" className="btn btn-sm btn-outline-primary w-100"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                </svg> Download 
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                : <></>}

                {this.state.model.cdFormatView === "T" ?
                    <div className="table-responsive-sm">
                        <table className="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th className="col text-end"> </th>
                                    <th className="col text-end"> </th>
                                    <th className="col-md-5"> Nome </th>
                                    <th className="col-md-5"> Descrição </th>
                                    <th className="col-md-2 text-center"> Data </th>
                                    <th className="col text-end"> &nbsp; </th>
                                    <th className="col text-end"> &nbsp; </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.data.map((item, index) => (
                                    <tr key={index}>
                                        <td className="align-middle">
                                            <img src={item.urlIcone} className="float-start pe-1" alt={item.nome}/>
                                        </td>
                                        <td className="align-middle">
                                            <img src={item.urlMiniatura} alt={item.nome} className="thumb-image-biblioteca-table"  onClick={e => this.handleClickView(item)} style={{ cursor: "pointer" }}/>
                                        </td>
                                        <td className="align-middle">{item.nome}</td>
                                        <td className="align-middle">{item.descricao}</td>
                                        <td className="align-middle text-center">{Util.date2Br(item.dataCriacao)} as {item.horaCriacao}</td>
                                        <td>
                                            <BtnButton className="btn btn-sm btn-outline-primary " onClick={e => this.handleClickView(item)}> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                </svg> 
                                            </BtnButton>
                                        </td>
                                        <td>
                                            <a href={item.urlDownload} rel="noopener noreferrer" target="_blank" className="btn btn-sm btn-outline-primary w-100"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                                </svg> 
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                : <></>}

                <div className="text-center pb-5 pt-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>

            </div>
        )
    }
}