import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContatoAtendimentoService from "../../services/ContatoAtendimentoService.js";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectContatoMotivoByContatoStatus from "../../components/app/SelectContatoMotivoByContatoStatus";
import TextArea from "../../components/forms/elements/TextArea";
import InputDate from "../../components/forms/elements/InputDate";
import InputValorReais from "../../components/forms/custom/InputValorReais";
import ContatoStatusService from "../../services/ContatoStatusService";
import ContatoService from "../../services/ContatoService.js";
import SelectContatoStatusByContatoTipo from "../../components/app/SelectContatoStatusByContatoTipo.js";

class FormContatoAtendimento extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeContatoStatus = this.handleChangeContatoStatus.bind(this);

        this.state.model = {
            idContato: this.props.idContato,
            idContatoAtendimento: "",
            idContatoStatus: this.props.idContatoStatus !== undefined ? this.props.idContatoStatus : "",
            idContatoMotivo: this.props.idContatoMotivo !== undefined ? this.props.idContatoMotivo : "",
            idContatoTipo: this.props.idContatoTipo !== undefined ? this.props.idContatoTipo : "",
            dsAtendimento: "",
            flFechouVenda: false,
            dtVenda: "",
            vlVenda: ""

        };

        this.state.crud = {
            service: ContatoAtendimentoService,
            labels: {
                title: 'Status',
            },
            urlList: '/LstContatoAtendimento',
        };
    }

    componentDidMount() {
        super.componentDidMount();
        ContatoService.updateByVisualizacao(this.props.idContato);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeContatoStatus() {
        if (this.state.model.idContatoStatus) {
            ContatoStatusService.getOneById(this.state.model.idContatoStatus, (response) => {
                this.setState(state => {
                    state.model.flFechouVenda = response.flFechouVenda;
                    return state;
                });
            });
        }
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <SelectContatoStatusByContatoTipo idContatoTipo={this.state.model.idContatoTipo} ativo={1} label="Nome do Status" required placeholder="Selecione o Status" name="idContatoStatus" md={12} value={this.state.model.idContatoStatus} onChange={e => { this.handleChange(e, this.handleChangeContatoStatus) }} />
                <SelectContatoMotivoByContatoStatus ativo={1} label="Nome do Motivo" required idContatoStatus={this.state.model.idContatoStatus} placeholder="Selecione o Motivo" name="idContatoMotivo" md={12} value={this.state.model.idContatoMotivo} onChange={this.handleChange} />

                <TextArea label="Atendimento" md={12} value={this.state.model.dsAtendimento} name="dsAtendimento" onChange={this.handleChange} maxLength={100} />

                <InputDate md={3} sizing="md" show={Boolean(this.state.model.flFechouVenda)} label="Data da Venda" required={Boolean(this.state.model.flFechouVenda)} value={this.state.model.dtVenda} name="dtVenda" onChange={this.handleChange} />
                <InputValorReais md={3} sizing="md" show={Boolean(this.state.model.flFechouVenda)} label="Valor" required={Boolean(this.state.model.flFechouVenda)} value={this.state.model.vlVenda} name="vlVenda" onChange={this.handleChange} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormContatoAtendimento {...props} navigate={navigate} params={params} />
}

export default With