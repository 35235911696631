import React from "react";
import EstadoService from "../../services/EstadoService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaEstado extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: EstadoService,
            labels: {
                title: 'Lista de Estado(s)',
                btnNewRegister: 'Estado',
            },
            keyItem: 'idEstado',
            urlForm: '/CadEstado',
            itensFilter: ["idEstado", "nomePais", "nome", "sigla"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-3 d-none d-md-table-cell"> País </th>
                <th className="col-md-5"> Nome </th>
                <th className="col-md-2 text-center"> Sigla </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idEstado}</td>
                <td className="d-none d-md-table-cell">{item.nomePais}</td>
                <td>{item.nome}</td>
                <td className="text-center">{item.sigla}</td>
            </>);
    }

    render() {
        return super.render();
    }
}