import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ProdutoServicoEntradaService from "../../services/ProdutoServicoEntradaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectProdutoServicoByTipo from "../../components/app/SelectProdutoServicoByTipo";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import InputQuantidade from "../../components/forms/custom/InputQuantidade";
import SelectCategoriaProdutoServico from "../../components/app/SelectCategoriaProdutoServico";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import SelectFornecedorByAreaAtuacao from "../../components/app/SelectFornecedorByAreaAtuacao";
import SelectAreaAtuacao from "../../components/app/SelectAreaAtuacao";
import InputValorReais from "../../components/forms/custom/InputValorReais";

class FormProdutoServicoEntrada extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeProduto = this.handleChangeProduto.bind(this);

        this.state.model = {
            idProdutoServicoEntrada: "",
            idCategoriaProdutoServico: "",
            idTipoProdutoServico: "",
            idAreaAtuacao: "",
            idFornecedor: "",
            idProdutoServico: this.props.idProdutoServico || "",
            nrQuantidade: "",
            vlCusto : 0
        };

        this.state.produto = {
            nrCasasDecimais: 0,
            siglaUnidadeMedida: ""
        };

        this.state.crud = {
            service: ProdutoServicoEntradaService,
            labels: {
                title: 'Entrada',
            },
            urlList: '/LstProdutoServicoEntrada',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        if (this.state.model.idProdutoServico) {
            ProdutoServicoService.getOneById(this.state.model.idProdutoServico, (response) => {
                this.setState((state) => {
                    state.model.idCategoriaProdutoServico = response.idCategoriaProdutoServico;
                    state.model.idTipoProdutoServico = response.idTipoProdutoServico;
                    return state;
                });
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeProduto(event) {
        ProdutoServicoService.getOneById(this.state.model.idProdutoServico, (response) => {
            this.setState((state) => {
                state.produto = response;
                return state;
            });
        });
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idProdutoServicoEntrada} name="idProdutoServicoEntrada" readOnly opcionalLabel={false} />
                </div>
                <div className="row mb-3 g-3">
                    <SelectAreaAtuacao label="Área de Atuação do Fornecedor" required={true} md={6} value={this.state.model.idAreaAtuacao} placeholder="Selecione a Área de Atuação" name="idAreaAtuacao" onChange={this.handleChange} />
                    <SelectFornecedorByAreaAtuacao idAreaAtuacao={this.state.model.idAreaAtuacao} label="Fornecedor" required={true} md={6} value={this.state.model.idFornecedor} placeholder="Selecione o Fornecedor" name="idFornecedor" onChange={this.handleChange} />

                    <SelectCategoriaProdutoServico required={true} md={6} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={this.handleChange} />
                    <SelectTipoProdutoServicoByCategoria required={true} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} md={6} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={this.handleChange} />

                    <SelectProdutoServicoByTipo idTipoProdutoServico={this.state.model.idTipoProdutoServico} autoFocus={!this.state.model.idProdutoServico} label="Produto" md={12} required placeholder="Selecione o Produto ou Serviço" name="idProdutoServico" value={this.state.model.idProdutoServico} onChange={e => this.handleChange(e, this.handleChangeProduto)} />
                    
                    <InputQuantidade disabled={true} label="Estoque" md={4} className="text-center" value={this.state.produto.vlEstoque} decimalScale={this.state.produto.nrCasasDecimais} unidadeMedida={this.state.produto.siglaUnidadeMedida} opcionalLabel={false} />
                    <InputQuantidade required autoFocus={this.state.model.idProdutoServico} disabled={!this.state.model.idProdutoServico} label="Quantidade de Entrada" md={4} className="text-center" placeholder="Digite a quantidade" value={this.state.model.nrQuantidade} name="nrQuantidade" onChange={this.handleChange} decimalScale={this.state.produto.nrCasasDecimais} unidadeMedida={this.state.produto.siglaUnidadeMedida} />
                    <InputValorReais md={4} label="Valor de Custo" required={true} className="text-end text-success fw-bold" value={this.state.model.vlCusto} name="vlCusto" onChange={this.handleChange}/>
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormProdutoServicoEntrada {...props} navigate={navigate} params={params} />
}

export default With