import { Component } from "react";
import FormMask from "../base/FormMask";

export default class InputChassis extends Component {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    

    handleChange(event) {

        /*if (event.target.value && !Util.chassiIsValid(event.target.value)) {
            event.target.setCustomValidity("Chassi Inválido!");
        } else {
            event.target.setCustomValidity("");
        }*/

        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        return (
            <FormMask {...this.props}
                maskChar=" "
                style={{ textTransform: "uppercase" }}
                onChange={this.handleChange}
                mask="*****************"                
                placeholder="XXX XXXXXX XX XXXXXX"
            />);
    }
}

//pattern="[A-Z0-9]{3} [A-Z0-9]{6} [A-Z0-9]{2} \d{6}"