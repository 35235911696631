import React from "react";
import ContaService from "../../services/ContaService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaConta extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ContaService,
            labels: {
                title: 'Lista de Conta(s)',
                btnNewRegister: 'Conta',
            },
            keyItem: 'idConta',
            urlForm: '/CadConta',
            itensFilter: ["idConta", "nome", "nomeBanco", "nrAgencia", "nrConta"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-4"> Nome </th>
                <th className="col-md-4"> Banco </th>
                <th className="col-md-2"> Agência </th>
                <th className="col-md-2"> Número </th>
                <th className="col"> Boleto? </th>
                <th className="col"> PIX? </th>

            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idConta}</td>
                <td>{item.nome}</td>
                <td>{item.nomeBanco}</td>
                <td>{item.nrAgencia}</td>
                <td>{item.nrConta}</td>
                <td className="text-center">
                    {Boolean(item.flTemBoleto) ? <span className="badge bg-success">SIM</span> : <span className="badge bg-danger">NÃO</span>}
                </td>
                <td className="text-center">
                    {Boolean(item.flTemPix) ? <span className="badge bg-success">SIM</span> : <span className="badge bg-danger">NÃO</span>}
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}