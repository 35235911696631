import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import InputNumber from "../../components/forms/elements/InputNumber";
import ServicoContatoService from "../../services/ServicoContatoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";

class FormServicoContato extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idServicoContato: "",
            nmServicoContato: "",
            flAtivo: true,
            nrPrioridade: 0
        };

        this.state.crud = {
            service: ServicoContatoService,
            labels: {
                title: 'Serviço',
            },
            urlList: '/LstServicoContato',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idServicoContato} name="idServicoContato" readOnly opcionalLabel={false}/>
                    <InputText label="Nome do Serviço" autoFocus={true} required md={9} value={this.state.model.nmServicoContato} name="nmServicoContato" onChange={this.handleChange} maxLength={100} />
                    <InputNumber label="Priodade" placeholder="99" required md={3} value={this.state.model.nrPrioridade} name="nrPrioridade" onChange={this.handleChange} min={-100} max={999} />
                </div>
                <div className="row mx-1 mb-3 g-3">
                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormServicoContato {...props} navigate={navigate} params={params} />
}

export default With