import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import InputCep from "../../components/forms/custom/InputCep";
import EnderecoService from "../../services/EnderecoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectPais from "../../components/app/SelectPais";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import SelectTipoEndereco from "../../components/app/SelectTipoEndereco";
import Util from "../../utils/Util";

class FormEndereco extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeCep = this.handleChangeCep.bind(this);

        this.state.model = {
            idEndereco: props.idEndereco || "",
            idTipoEndereco: "",
            idPais: 1,
            idEstado: "",
            idCidade: "",
            cep: "",
            bairro: "",
            endereco: "",
            numero: "",
            complemento: ""
        };

        this.state.crud = {
            service: EnderecoService,
            keyName : "idEndereco",
            labels: {
                title: 'Endereco',
            },
            urlList: '/',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onEdit(id) {
        super.onEdit(id, ()=>{
            if(Boolean(this.state.isCopy)){                
                this.setState(state =>{                    
                    state.model.idTipoEndereco = "";
                    return state;
                });
            }
        })
    }

    handleChangeCep() {
        if (Util.stringToNumber(this.state.model.cep).length === 8) {
            EnderecoService.getOneByCep(this.state.model.cep, (response) => {
                this.setState((state) => {
                    state.model.endereco = response?.logradouro ? response.logradouro : "";
                    state.model.bairro = response?.bairro ? response.bairro : "";
                    state.model.complemento = response?.complemento ? response.complemento : "";
                    state.model.numero = "";

                    if (response?.idEstado) {
                        state.model.idEstado = response.idEstado;
                    }

                    if (response?.idCidade) {
                        state.model.idCidade = response.idCidade;
                    }

                    return state;
                });
            });

        }
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <SelectTipoEndereco disabled={this.state.model.idEndereco} disabledValues={this.props.disabledValuesTipoEndereco || []} label="Tipo de Endereço" required placeholder="Tipo de Endereço" name="idTipoEndereco" md={4} value={this.state.model.idTipoEndereco} onChange={this.handleChange} />
                    {parseInt(this.state.model.idPais) === 1 ?
                        <InputCep label="CEP" md={2} value={this.state.model.cep} name="cep" onChange={e => this.handleChange(e, this.handleChangeCep, this.state.model.ordemEndereco)} required={true} />
                        :
                        <InputText label="CEP" md={2} value={this.state.model.cep} name="cep" onChange={this.handleChange} required={true} maxLength={20} />
                    }
                    <InputText label="Logradouro" md={6} value={this.state.model.endereco} name="endereco" onChange={this.handleChange} maxLength={100} required={true} />
                    
                    <InputText label="Bairro" md={5} value={this.state.model.bairro} name="bairro" onChange={this.handleChange} maxLength={150} required={true} />
                    <InputText label="Número" md={3} value={this.state.model.numero} name="numero" onChange={this.handleChange} maxLength={100} required={true} />
                    <InputText label="Complemento" md={4} value={this.state.model.complemento} name="complemento" onChange={this.handleChange} maxLength={100} />

                    <SelectPais label="Nome do País" required placeholder="Selecione o País" name="idPais" md={3} value={this.state.model.idPais} onChange={this.handleChange} />
                    <SelectEstadoByPais md={4} required idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" onChange={this.handleChange} />
                    <SelectCidadeByEstado md={5} required idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" onChange={this.handleChange} />
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormEndereco {...props} navigate={navigate} params={params} />
}

export default With