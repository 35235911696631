import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import UsuarioService from "../../services/UsuarioService";
import RepresentanteService from "../../services/RepresentanteService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputPassword from "../../components/forms/elements/InputPassword";
import Util from "../../utils/Util";
import SelectPerfil from "../../components/app/SelectPerfil";
import InputEmail from "../../components/forms/elements/InputEmail";
import SelectDDI from "../../components/app/SelectDDI";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import InputDate from "../../components/forms/elements/InputDate";
import AvisoService from "../../services/AvisoService";

class FormUsuario extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeRepresentantes = this.handleChangeRepresentantes.bind(this);
        this.handleChangeRepresentantesAll = this.handleChangeRepresentantesAll.bind(this);
        this.handleClearRepresentante = this.handleClearRepresentante.bind(this);
        this.handleChangeAvisos = this.handleChangeAvisos.bind(this);
        this.handleChangeAvisoAll = this.handleChangeAvisoAll.bind(this);
        this.getRepresentantes = this.getRepresentantes.bind(this);
        this.getAvisos = this.getAvisos.bind(this);
        this.onEdit = this.onEdit.bind(this);

        this.state.isCard = true;

        this.state.model = {
            idUsuario: "",
            idPerfil: "",
            nome: "",
            usuario: "",
            email: "",
            dtNascimento: "",
            senha: "",
            confirmacao: "",
            ativo: true,
            flAlterarSenha: false,
            flNotificacaoOrdemSomenteMinha: false,
            flExigirSenhaVerValor: false,
            flMarcaComentario: true,
            ddi: "55",
            telefone: "",
            dataAcesso: "",
            horaAcesso: "",
            dataValidateToken: "",
            horaValidateToken: "",
            dataRefreshToken: "",
            horaRefreshToken: "",
            usuarioRepresentante: [],
            usuarioAviso: [],
            usuarioFoto : {}    
        };

        this.state.crud = {
            service: UsuarioService,
            labels: {
                title: 'Usuário',
            },
            urlList: '/LstUsuario',
        };

        this.state.representantes = [];
        this.state.avisos = [];
        this.state.buscaRepresentante = "";
    }

    componentDidMount() {
        super.componentDidMount();
        this.getRepresentantes();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onEdit(id) {
        super.onEdit(id, this.getAvisos)
    }


    getRepresentantes() {
        RepresentanteService.list((response) => {
            this.setState({ representantes: response });
        });
    }

    getAvisos() {
        if (this.state.model.idPerfil) {
            AvisoService.listByPerfil(this.state.model.idPerfil, response => {
                this.setState({ avisos: response }, () => {
                    if (!this.state.isEdit) {
                        this.handleChangeAvisoAll()
                    };
                });
            });
        }
    }

    handleChangeRepresentantes(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.usuarioRepresentante.push(item);
                return state;
            });
        } else {
            var index = this.state.model.usuarioRepresentante.findIndex(i => parseInt(i.idRepresentante) === parseInt(item.idRepresentante));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.usuarioRepresentante.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeRepresentantesAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.usuarioRepresentante = [];

                this.filterRepresentantes().forEach(function (item) {
                    state.model.usuarioRepresentante.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.usuarioRepresentante = [];
                return state;
            });
        }
    }

    handleClearRepresentante() {
        this.setState({ buscaRepresentante: "" });
    }

    filterRepresentantes() {
        return this.state.representantes.filter(f =>
            Util.searchTextInObject(f, ["empresa", "nomeProprietario", "nomeCidade", "nomeEstado", "nomePais"], this.state.buscaRepresentante)
        );
    }

    handleChangeAvisos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.usuarioAviso.push({
                    idAviso: item.idAviso,
                    flWhatsapp: Boolean(item.flWhatsapp),
                    flSistema: Boolean(item.flSistema),
                    flEmail: Boolean(item.flEmail),
                });
                return state;
            });
        } else {
            var index = this.state.model.usuarioAviso.findIndex(i => parseInt(i.idAviso) === parseInt(item.idAviso));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.usuarioAviso.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeAvisoAll(event) {

        var checked = true;
        if (event !== undefined) {
            checked = event.target.checked;
        }

        if (checked) {
            this.setState((state) => {
                state.model.usuarioAviso = [];

                this.state.avisos.forEach(function (item) {
                    state.model.usuarioAviso.push({
                        idAviso: item.idAviso,
                        flWhatsapp: Boolean(item.flWhatsapp),
                        flSistema: Boolean(item.flSistema),
                        flEmail: Boolean(item.flEmail),
                    });
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.usuarioAviso = [];
                return state;
            });
        }
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-usuario-tab" data-bs-toggle="tab" data-bs-target="#nav-usuario" type="button" role="tab" aria-controls="nav-usuario" aria-selected="true">
                        Dados
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-representantes-tab" data-bs-toggle="tab" data-bs-target="#nav-representantes" type="button" role="tab" aria-controls="nav-representantes" aria-selected="false">
                        Representantes
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-avisos-tab" data-bs-toggle="tab" data-bs-target="#nav-avisos" type="button" role="tab" aria-controls="nav-avisos" aria-selected="false">
                        Avisos
                    </button>
                </li>
            </ul>);
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-usuario" role="tabpanel" aria-labelledby="nav-usuario-tab">

                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={3} value={this.state.model.idUsuario} name="idRepresentante" readOnly opcionalLabel={false} />

                        <InputText label="Último Acesso" md={3} value={(this.state.model.dataAcesso ? Util.date2Br(this.state.model.dataAcesso) + " " + this.state.model.horaAcesso : " - ")} disabled opcionalLabel={false} />
                        <InputText label="Último Validate" md={3} value={(this.state.model.dataValidateToken ? Util.date2Br(this.state.model.dataValidateToken) + " " + this.state.model.horaValidateToken : " - ")} disabled opcionalLabel={false} />
                        <InputText label="Último Refresh" md={3} value={(this.state.model.dataRefreshToken ? Util.date2Br(this.state.model.dataRefreshToken) + " " + this.state.model.horaRefreshToken : " - ")} disabled opcionalLabel={false} />

                        <InputText autoComplete="off" autoFocus={true} label="Nome do Usuário" placeholder="Nome do Usuário" required md={5} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                        <InputEmail autoComplete="off" label="E-mail" md={4} name="email" value={this.state.model.email} onChange={this.handleChange} required={true} />
                        <InputDate label="Data de Nascimento" md={3} name="dtNascimento" value={this.state.model.dtNascimento} onChange={this.handleChange} />

                        <InputEmail label="Usuário" md={6} name="usuario" value={this.state.model.usuario} onChange={this.handleChange} required={true} role="presentation" autoComplete="off" maxLength={100} />
                        <SelectPerfil label="Nome do Perfil" autoFocus required placeholder="Selecione o Perfil" name="idPerfil" md={6} value={this.state.model.idPerfil} onChange={e => this.handleChange(e, this.getAvisos)} />
                    </div>
                    <div className={`row mx-1 mb-3 g-1`}>
                        <InputCheckSwitch label="Ativo?" name="ativo" checked={Boolean(this.state.model.ativo)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Exigir senha para visualizar valor?" name="flExigirSenhaVerValor" checked={Boolean(this.state.model.flExigirSenhaVerValor)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Permite ser marcado em comentários na ordem?" name="flMarcaComentario" checked={Boolean(this.state.model.flMarcaComentario)} onChange={this.handleChange} />
                        {
                            this.state.model.idUsuario ?
                                <InputCheckSwitch label="Alterar Senha?" name="flAlterarSenha" checked={Boolean(this.state.model.flAlterarSenha)} onChange={this.handleChange} />
                                : <></>
                        }
                    </div>


                    <div className="row mx-1 mb-3 g-1">
                        <SelectDDI md={3} label="DDI" required={true} placeholder="Selecione" name="ddi" value={this.state.model.ddi} onChange={this.handleChange} />
                        {parseInt(this.state.model.ddi) === 55 ?
                            <InputTelCelular label="Celular" required={true} md={6} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} />
                            :
                            <InputText label="Celular" required={true} md={6} value={this.state.model.telefone} name="telefone" onChange={this.handleChange} />
                        }
                    </div>

                    {(!this.state.model.idUsuario || this.state.model.flAlterarSenha) ?
                        <div className={`row mx-1 mb-3 g-1`}>
                            <InputPassword
                                autoComplete="new-password" label="Senha" placeholder="Senha" col={6} value={this.state.model.senha} name="senha" onChange={this.handleChange}
                                validations={[
                                    {
                                        validation: this.state.model.confirmacao === this.state.model.senha,
                                        message: "Senha e Confirmação devem ser iguais"
                                    }
                                ]}
                                required={true}
                            />
                            <InputPassword autoComplete="new-password" label="Confirmação" placeholder="Confirmação" col={6} value={this.state.model.confirmacao} name="confirmacao" onChange={this.handleChange}
                                validations={[
                                    {
                                        validation: this.state.model.confirmacao === this.state.model.senha,
                                        message: "Senha e Confirmação devem ser iguais"
                                    }
                                ]}
                                required={true}
                            />
                        </div>
                        : <></>
                    }
                </div>
                <div className="tab-pane fade" id="nav-representantes" role="tabpanel" aria-labelledby="nav-representantes-tab">
                    <div className="row gap-2 mb-3">
                        <div className="col">
                            <div className="input-group">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control" autoComplete="off" name="buscaRepresentante" placeholder="Busca" value={this.state.buscaRepresentante} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-danger" onClick={this.handleClearRepresentante}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </button>
                        </div>
                    </div>

                    <table className="table table-sm table-hover caption-top">
                        <caption className="text-end">
                            {this.state.representantes.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th>
                                    <InputCheckSwitch name="todos" onChange={this.handleChangeRepresentantesAll} checked={this.state.model.usuarioRepresentante.length === this.state.representantes.length} />
                                </th>
                                <th className="col-md-5"> Representante </th>
                                <th className="col-md-4 d-none d-md-table-cell"> Proprietário </th>
                                <th className="col-md-3 d-none d-md-table-cell"> Cidade </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.filterRepresentantes().map((item) => (
                                <tr key={item.idRepresentante}>
                                    <td>
                                        <InputCheckSwitch name="representante" id={`ch-representante-${item.idRepresentante}`} checked={this.state.model.usuarioRepresentante.findIndex(i => parseInt(i.idRepresentante) === parseInt(item.idRepresentante)) !== -1} onChange={(e) => { this.handleChangeRepresentantes(e, item) }} />
                                    </td>
                                    <td>{item.empresa} {!item.ativo ? <small><span className="badge bg-danger">INATIVO</span></small> : ''}</td>
                                    <td className="d-none d-md-table-cell">{item.nomeProprietario}</td>
                                    <td className="d-none d-md-table-cell">{item.nomeCidade} - {item.siglaEstado} - {item.nomePais}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="tab-pane fade" id="nav-avisos" role="tabpanel" aria-labelledby="nav-avisos-tab">
                    <table className="table table-sm table-hover caption-top">
                        <caption className="text-end">
                            {this.state.avisos.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th>
                                    <InputCheckSwitch name="todos" onChange={this.handleChangeAvisoAll} checked={this.state.model.usuarioAviso.length === this.state.avisos.length} />
                                </th>
                                <th className="col-md-6"> Aviso </th>
                                <th className="col-md-2"> Whatsapp </th>
                                <th className="col-md-2"> Sistema </th>
                                <th className="col-md-2"> E-mail </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.avisos.map((item, index) => {

                                var flChecked = this.state.model.usuarioAviso.findIndex(i => parseInt(i.idAviso) === parseInt(item.idAviso)) !== -1;
                                var indexUsuarioAviso = this.state.model.usuarioAviso.findIndex(i => parseInt(i.idAviso) === parseInt(item.idAviso));
                                var usuarioAviso = indexUsuarioAviso !== - 1 ? this.state.model.usuarioAviso[indexUsuarioAviso] : {};
                                var flWhatsapp = Boolean(usuarioAviso?.flWhatsapp);
                                var flSistema = Boolean(usuarioAviso?.flSistema);
                                var flEmail = Boolean(usuarioAviso?.flEmail);

                                return (
                                    <tr key={index}>
                                        <td>
                                            <InputCheckSwitch name="representante" id={`ch-aviso-${item.idAviso}`} checked={flChecked} onChange={(e) => { this.handleChangeAvisos(e, item) }} />
                                        </td>
                                        <td>{item.nome}</td>
                                        <td className="text-center">
                                            <InputCheckSwitch disabled={!flChecked || !Boolean(item.flWhatsapp)} name="flWhatsapp" id={`ch-whatsapp-${item.idAviso}`} checked={flWhatsapp} onChange={(e) => { this.handleChange(e, false, this.state.model.usuarioAviso[indexUsuarioAviso]) }} />
                                        </td>
                                        <td className="text-center">
                                            <InputCheckSwitch disabled={!flChecked || !Boolean(item.flSistema)} name="flSistema" id={`ch-sistema-${item.idAviso}`} checked={flSistema} onChange={(e) => { this.handleChange(e, false, this.state.model.usuarioAviso[indexUsuarioAviso]) }} />
                                        </td>
                                        <td className="text-center">
                                            <InputCheckSwitch disabled={!flChecked || !Boolean(item.flEmail)} name="flEmail" id={`ch-email-${item.idAviso}`} checked={flEmail} onChange={(e) => { this.handleChange(e, false, this.state.model.usuarioAviso[indexUsuarioAviso]) }} />
                                        </td>
                                    </tr>);
                            })}
                            <tr>
                                <td>
                                    <InputCheckSwitch label="" name="flNotificacaoOrdemSomenteMinha" checked={Boolean(this.state.model.flNotificacaoOrdemSomenteMinha)} onChange={this.handleChange} />
                                </td>
                                <td>
                                    Receber avisos somente das minhas ordens
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormUsuario {...props} navigate={navigate} params={params} />
}

export default With