import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Outlet, Link } from "react-router-dom";
import MyComponent from "../components/custom/MyComponent";
import ModalConfirmacao from "../components/modal/ModalConfirmacao";
import Auth from "../controllers/Auth";
import EventBus from "../controllers/EventBus";
import Permissao from "../controllers/Permissao";
import LoginService from "../services/LoginService";
import NotificacaoService from "../services/NotificacaoService";
import Notificacao from "../controllers/Notificacao";
import Config from "../Config";
import { toast } from "react-toastify";
import VersaoService from "../services/VersaoService";
import FormAviso from "./Fatura/FormAviso";
import FormSenhaConfirmacao from "./Login/FormSenhaConfirmacao";
import FormUsuarioAtualizacao from "./Usuario/FormUsuarioAtualizacao";
import CobrancaService from "../services/CobrancaService";
import Util from "../utils/Util";
import BtnButton from "../components/forms/elements/BtnButton";
import Top10RankingHeader from "./Dashboard/Top10RankingHeader";
import UsuarioService from "../services/UsuarioService";
import Storage from "../controllers/Storage";
import MuralService from "../services/MuralService";

class Layout extends MyComponent {

    constructor(props) {
        super(props)

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.validateOnLine = this.validateOnLine.bind(this);
        this.getNotification = this.getNotification.bind(this);
        this.getMural = this.getMural.bind(this);
        this.getVersao = this.getVersao.bind(this);
        this.setNotification = this.setNotification.bind(this);
        this.verificaFaturaAberta = this.verificaFaturaAberta.bind(this);
        this.setAllRead = this.setAllRead.bind(this);
        this.openNotificacoes = this.openNotificacoes.bind(this);
        this.handleDados = this.handleDados.bind(this);
        this.handleClickVerValor = this.handleClickVerValor.bind(this);

        this.state = {
            usuario: Auth.getDataToken(),
            model: {
                busca: ""
            },
            onLine: true,
            notificacoes: [],
            mural: 0,
            notificacoesDetalhe: [],
            versao: {
                flTemNovaVersao: false
            },
            flVerValor: Storage.getVisualizacaoValor()
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.validateOnLine();

        this.getNotification(false);
        this.getMural();
        this.getVersao();
        this.verificaFaturaAberta();
        this.verificaAtualizacaoUsuario();

        setInterval(this.getNotification, (Config.timeGetNotification * 1000));
        setInterval(this.getMural, (Config.timeGetMural * 1000));
        setInterval(this.getVersao, (Config.timeGetVersao * 1000));

        EventBus.on("atualizar-notificacao", (obj) => {
            this.setNotification(obj.notificacoes, obj.notificar);
        });

        EventBus.on("atualizar-mural", () => {
            this.getMural();
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event) {
        if (this.state.model.busca) {
            this.props.navigate('/', { state: { busca: this.state.model.busca } });
        }
    }

    onLogout() {
        this.props.navigate('/login');
    }

    onLock() {
        Auth.lock();
        LoginService.validateLogged();
    }

    handleClickVerValor() {

        if (!Boolean(this.state.flVerValor) && Boolean(parseInt(this.state.usuario.flExigirSenhaVerValor))) {

            EventBus.dispatch("modal-add", {
                title: 'Confirmação de Senha',
                sizeModal: 'modal-md',
                config: {
                    isModal: true
                },
                form: FormSenhaConfirmacao,
                onClose: (response) => {
                },
                onSave: (response) => {
                    this.setState({ flVerValor: Storage.uptVisualizacaoValor() });
                }
            });
        } else {
            this.setState({ flVerValor: Storage.uptVisualizacaoValor() });
        }
    }

    verificaAtualizacaoUsuario() {
        UsuarioService.getOneByUpdate(response => {
            if (Boolean(response.flAtualizar)) {
                this.handleDados();
            }
        });
    }

    handleDados() {
        EventBus.dispatch("modal-add", {
            title: 'Atualização de Dados do Usuário',
            sizeModal: 'modal-xl',
            config: {
                isModal: true
            },
            form: FormUsuarioAtualizacao,
            onClose: (response) => {
            },
            onSave: (response) => {

            }
        });
    }

    verificaFaturaAberta() {

        var timeSecondExpire = 60 * 60 * 24; //em segundos
        var nameItem = "timestamp-aviso-cobranca-aberta-" + Auth.getIdUsuarioDataToken();
        if (Permissao.getInstance().getPermissao(27)) {
            if (Storage.hasItemTimeStampExpirado(nameItem, timeSecondExpire)) {
                CobrancaService.listAviso((response) => {
                    if (response.length > 0) {
                        EventBus.dispatch("modal-add", {
                            title: 'Aviso de parcelas em aberto',
                            sizeModal: 'modal-lg',
                            config: {
                                isModal: true,
                                cobrancas: response
                            },
                            form: FormAviso,
                        });
                    }
                });
            }
        }
    }

    validateOnLine() {

        window.addEventListener("online", () => {
            let status = (typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true);
            this.setState({ onLine: status });
            if (status === true) {
                if (Util.isActive()) {
                    EventBus.dispatch("network-onLine", {});
                }
            }
        });
        window.addEventListener("offline", () => {
            let status = (typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true);
            this.setState({ onLine: status });
            if (status === false) {
                if (Util.isActive()) {
                    EventBus.dispatch("network-offLine", {});
                }

            }
        });
    }

    setNotification(notificacoes, notificar = true) {

        if (notificar) {
            notificacoes.forEach((item) => {
                if (Notificacao.getInstance().getNotificacoes().findIndex(x => x.idNotificacao === item.idNotificacao) === -1) {
                    toast.success(item.dsTitulo, {
                        theme: "colored",
                        position: "top-left",
                        autoClose: 30000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
        }

        Notificacao.getInstance().setNotificacoes(notificacoes);
        this.setState({ notificacoes: notificacoes });
    }

    getNotification(notificar = true) {
        if (Util.isActive()) {
            NotificacaoService.getUnRead((response) => {
                this.setNotification(response.notificacoes, notificar);
            });


        }
    }

    getMural() {
        if (Util.isActive()) {
            MuralService.getUnRead((response) => {
                this.setState({ mural: response.quantidade });
            });
        }
    }

    setAllRead() {
        NotificacaoService.changeAllRead();
    }

    openNotificacoes() {
        var notificacoes = Object.assign([], this.state.notificacoes);
        this.setState({ notificacoesDetalhe: notificacoes, notificacoes: [] });
        this.setAllRead();
    }

    getVersao() {
        if (Util.isActive()) {
            VersaoService.verificaVersao((response) => {
                this.setState({ versao: response });
            });
        }
    }

    render() {

        return (
            <>
                {Config.getAmbient() === "development" ?
                    <div className="bg-warning text-dark text-center fw-bold fs-7"> Ambiente de Desenvolvimento </div>
                    : <></>}

                {Config.getAmbient() === "homologation" ?
                    <div className="bg-danger text-white text-center fw-bold fs-7"> Ambiente de Homologação </div>
                    : <></>}

                {parseInt(this.state.mural) > 0 ?
                    <div className="fixed-bottom text-end mb-3 me-3">
                        <Link to="/MuralAviso" className={`btn btn-sm  ${parseInt(this.state.mural) > 0 ? 'btn-success' : 'btn-link text-success'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                            </svg>
                            {parseInt(this.state.mural) > 0 ?
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    {parseInt(this.state.mural) > 99 ? '99+' : parseInt(this.state.mural)}
                                </span> : <></>}
                        </Link>
                    </div>
                    : <></>}

                <nav className="navbar navbar-expand-lg navbar-dark bg-dark d-print-none">
                    <div className="container-fluid">
                        <Link className="navbar-brand text-bdm fw-bold" to="/">
                            <img src="logo192_bdm.webp" className="img-fluid" style={{ maxWidth: "40px" }} alt="..." />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house" viewBox="0 0 16 16">
                                            <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
                                        </svg>
                                    </Link>
                                </li>
                                <li className={`nav-item dropdown ${Permissao.getInstance().getPermissoes([4, 5, 6, 94, 7, 10, 18, 23, 24, 48, 57, 64, 65, 66, 67, 73, 74, 88, 96, 98, 106, 109, 111, 114, 118, 122]) ? '' : 'd-none'}`}>
                                    <div className="nav-link dropdown-toggle" role="button" id="navbarDropdownCadastros" data-bs-toggle="dropdown" aria-expanded="false">
                                        Cadastros
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-app" aria-labelledby="navbarDropdownCadastros">
                                        {Permissao.getInstance().getPermissao(4) ? <li><Link className="dropdown-item" to="/LstPais">País</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(5) ? <li><Link className="dropdown-item" to="/LstEstado">Estados</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(6) ? <li><Link className="dropdown-item" to="/LstCidade">Cidades</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(94) ? <li><Link className="dropdown-item" to="/LstFeriado">Feriados</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(7) ? <li><Link className="dropdown-item" to="/LstServicoContato">Serviços de Contato</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(10) ? <li><Link className="dropdown-item" to="/LstConfiguracao">Configurações</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(67) ? <li><Link className="dropdown-item" to="/LstParametro">Parâmetros</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(23) ? <li><Link className="dropdown-item" to="/LstCombustivel">Combustível</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(24) ? <li><Link className="dropdown-item" to="/LstTransmissao">Transmissão</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(25) ? <li><Link className="dropdown-item" to="/LstMarca">Marca</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(106) ? <li><Link className="dropdown-item" to="/LstModelo">Modelo</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(48) ? <li><Link className="dropdown-item" to="/LstTipoEndereco">Tipo de Endereço</Link></li> : <></>}

                                        {Permissao.getInstance().getPermissao(18) ? <li><Link className="dropdown-item" to="/LstOrdemStatus">Ordem Status</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(57) ? <li><Link className="dropdown-item" to="/LstOrdemTipo">Ordem Tipo</Link></li> : <></>}

                                        {Permissao.getInstance().getPermissoes([64, 65, 66]) ? <li><hr className="dropdown-divider" /></li> : <></>}

                                        {Permissao.getInstance().getPermissao(64) ? <li><Link className="dropdown-item" to="/LstFornecedor">Fornecedores</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(65) ? <li><Link className="dropdown-item" to="/LstAreaAtuacao">Área de Atuação</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(66) ? <li><Link className="dropdown-item" to="/LstModalidadeFrete">Modalidade de Frete</Link></li> : <></>}

                                        {Permissao.getInstance().getPermissao(88) ? <li><Link className="dropdown-item" to="/LstContatoTipo">Contato Tipo</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(73) ? <li><Link className="dropdown-item" to="/LstContatoStatus">Contato Status</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(74) ? <li><Link className="dropdown-item" to="/LstContatoMotivo">Contato Motivo</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(98) ? <li><Link className="dropdown-item" to="/LstContatoOrigem">Contato Origem</Link></li> : <></>}

                                        {Permissao.getInstance().getPermissao(96) ? <li><Link className="dropdown-item" to="/LstPainel">Painel</Link></li> : <></>}

                                        {Permissao.getInstance().getPermissao(109) ? <li><Link className="dropdown-item" to="/LstEquipamento">Equipamento</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(111) ? <li><Link className="dropdown-item" to="/LstLiberacao">Liberação</Link></li> : <></>}

                                        {Permissao.getInstance().getPermissao(114) ? <li><Link className="dropdown-item" to="/LstCron">Cron</Link></li> : <></>}

                                        {Permissao.getInstance().getPermissao(118) ? <li><Link className="dropdown-item" to="/LstContrato">Contrato</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(122) ? <li><Link className="dropdown-item" to="/LstContratoStatus">Contrato Status</Link></li> : <></>}

                                        {Permissao.getInstance().getPermissao(124) ? <li><Link className="dropdown-item" to="/LstTag">Tag</Link></li> : <></>}
                                    </ul>
                                </li>
                                <li className={`nav-item dropdown ${Permissao.getInstance().getPermissoes([1, 2, 3, 45, 102]) ? '' : 'd-none'}`}>
                                    <div className="nav-link dropdown-toggle" role="button" id="navbarDropdownCadastros" data-bs-toggle="dropdown" aria-expanded="false">
                                        Usuários
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-app" aria-labelledby="navbarDropdownCadastros">
                                        {Permissao.getInstance().getPermissao(1) ? <li><Link className="dropdown-item" to="/LstUsuario">Usuários</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(2) ? <li><Link className="dropdown-item" to="/LstPerfil">Perfis</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(3) ? <li><Link className="dropdown-item" to="/LstModulo">Módulo</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(45) ? <li><Link className="dropdown-item" to="/LstGrupoModulo">Grupo Módulo</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(102) ? <li><Link className="dropdown-item" to="/LstAviso">Avisos</Link></li> : <></>}
                                    </ul>
                                </li>
                                {Permissao.getInstance().getPermissoes([14, 15, 16, 17, 37, 38, 41, 49, 80, 84]) ?
                                    <li className={`nav-item dropdown`}>
                                        <div className="nav-link dropdown-toggle" role="button" id="navbarDropdownCadastros" data-bs-toggle="dropdown" aria-expanded="false">
                                            Produtos
                                        </div>
                                        <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-app" aria-labelledby="navbarDropdownCadastros">
                                            {Permissao.getInstance().getPermissao(15) ? <li><Link className="dropdown-item" to="/LstProdutoServico">Produto / Serviço</Link></li> : <></>}
                                            {Permissao.getInstance().getPermissao(16) ? <li><Link className="dropdown-item" to="/LstCategoriaProdutoServico">Categoria de Produto </Link></li> : <></>}
                                            {Permissao.getInstance().getPermissao(14) ? <li><Link className="dropdown-item" to="/LstTipoProdutoServico">Tipo de Produto</Link></li> : <></>}
                                            {Permissao.getInstance().getPermissao(84) ? <li><Link className="dropdown-item" to="/LstProdutoServicoTipoObjeto">Tipo de Objeto</Link></li> : <></>}
                                            {Permissao.getInstance().getPermissao(17) ? <li><Link className="dropdown-item" to="/LstUnidadeMedida">Unidade de Medida</Link></li> : <></>}
                                            {Permissao.getInstance().getPermissao(49) ? <li><Link className="dropdown-item" to="/LstProdutoServicoClassificacao">Classificação de Produto</Link></li> : <></>}
                                            {Permissao.getInstance().getPermissao(80) ? <li><Link className="dropdown-item" to="/LstPromocaoCampanha">Campanha de Promoção</Link></li> : <></>}
                                            {Permissao.getInstance().getPermissao(41) ? <li><Link className="dropdown-item" to="/LstPreco">Lista de Preços</Link></li> : <></>}
                                            {Permissao.getInstance().getPermissao(105) ? <li><Link className="dropdown-item" to="/LstAusencia">Ausência Temporária</Link></li> : <></>}

                                            {Permissao.getInstance().getPermissoes([37, 38]) ? <li><hr className="dropdown-divider" /></li> : <></>}

                                            {Permissao.getInstance().getPermissao(37) ? <li><Link className="dropdown-item" to="/LstProdutoServicoEntrada">Entrada de Produto</Link></li> : <></>}
                                            {Permissao.getInstance().getPermissao(38) ? <li><Link className="dropdown-item" to="/LstProdutoServicoInventario">Invetário de Produto</Link></li> : <></>}

                                            
                                        </ul>
                                    </li>
                                    : <></>
                                }
                                <li className={`nav-item dropdown ${Permissao.getInstance().getPermissoes([8, 9]) ? '' : 'd-none'}`}>
                                    <div className="nav-link dropdown-toggle" role="button" id="navbarDropdownCadastros" data-bs-toggle="dropdown" aria-expanded="false">
                                        Representantes
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-app" aria-labelledby="navbarDropdownCadastros">
                                        {Permissao.getInstance().getPermissao(8) ? <li><Link className="dropdown-item" to="/LstRepresentante">Representantes</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(9) ? <li><Link className="dropdown-item" to="/LstProprietario">Proprietários</Link></li> : <></>}
                                    </ul>
                                </li>

                                <li className={`nav-item dropdown ${Permissao.getInstance().getPermissoes([11, 12, 13, 40, 92, 113]) ? '' : 'd-none'}`}>
                                    <div className="nav-link dropdown-toggle" role="button" id="navbarDropdownOperacoes" data-bs-toggle="dropdown" aria-expanded="false">
                                        Processos
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-app" aria-labelledby="navbarDropdownOperacoes">
                                        {Permissao.getInstance().getPermissao(11) ? <li><Link className="dropdown-item" to="/BscRepresentante">Busca de Representantes</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(12) ? <li><Link className="dropdown-item" to="/LstContato">Contatos do Site</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(13) ? <li><Link className="dropdown-item" to="/LstOrdem">Ordem de Serviço / Produto</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(40) ? <li><Link className="dropdown-item" to="/BscRemap">Banco de Remaps</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(92) ? <li><Link className="dropdown-item" to="/LstMural">Mensagens do Mural de Avisos</Link></li> : <></>}                                        
                                        {Permissao.getInstance().getPermissao(113) ? <li><Link className="dropdown-item" to="/Biblioteca">Biblioteca</Link></li> : <></>}
                                    </ul>
                                </li>

                                <li className={`nav-item dropdown ${Permissao.getInstance().getPermissoes([110, 121, 12]) ? '' : 'd-none'}`}>
                                    <div className="nav-link dropdown-toggle" role="button" id="navbarDropdownOperacoes" data-bs-toggle="dropdown" aria-expanded="false">
                                        Gestão
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-app" aria-labelledby="navbarDropdownOperacoes">
                                        {Permissao.getInstance().getPermissao(12) ? <li><Link className="dropdown-item" to="/LstContato">Contatos</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(110) ? <li><Link className="dropdown-item" to="/LstRepresentanteEquipamentoAtualizacao">Equipamentos</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(121) ? <li><Link className="dropdown-item" to="/LstRepresentanteContratoAtualizacao">Contratos</Link></li> : <></>}
                                    </ul>
                                </li>

                                <li className={`nav-item dropdown ${Permissao.getInstance().getPermissoes([27, 42, 43, 54, 55, 33, 123]) ? '' : 'd-none'}`}>
                                    <div className="nav-link dropdown-toggle" role="button" id="navbarDropdownOperacoes" data-bs-toggle="dropdown" aria-expanded="false">
                                        Financeiro
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-app" aria-labelledby="navbarDropdownOperacoes">
                                        {Permissao.getInstance().getPermissao(27) ? <li><Link className="dropdown-item" to="/LstFatura">Faturas</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(130) ? <li><Link className="dropdown-item" to="/LstAcordo">Acordos</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissoes([42, 43, 54, 55, 33]) ? <li><hr className="dropdown-divider" /></li> : <></>}
                                        {Permissao.getInstance().getPermissao(42) ? <li><Link className="dropdown-item" to="/LstBanco">Banco</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(43) ? <li><Link className="dropdown-item" to="/LstConta">Conta Corrente</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(54) ? <li><Link className="dropdown-item" to="/LstTipoChavePix">Tipo de Chave Pix</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(123) ? <li><Link className="dropdown-item" to="/LstCategoriaBaixa">Categoria de Baixa</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(33) ? <li><Link className="dropdown-item" to="/LstTipoBaixa">Tipo de Baixa</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(55) ? <li><Link className="dropdown-item" to="/LstExtrato">Extrato</Link></li> : <></>}

                                        {Permissao.getInstance().getPermissoes([90, 91]) ? <li><hr className="dropdown-divider" /></li> : <></>}

                                        {Permissao.getInstance().getPermissao(90) ? <li><Link className="dropdown-item" to="/LstPlanoRecorrencia">Plano de Recorrência</Link></li> : <></>}
                                        {Permissao.getInstance().getPermissao(91) ? <li><Link className="dropdown-item" to="/LstPlanoPagamento">Plano de Pagamento</Link></li> : <></>}
                                    </ul>
                                </li>
                            </ul>

                            <div className="navbar-text">
                                {this.state.versao?.flTemNovaVersao ?
                                    <button type="button" className="btn btn-warning btn-sm mx-1 fw-bold">
                                        Nova Versão Disponível
                                    </button> : <></>
                                }
                                {!Boolean(this.state.onLine) ?
                                    <svg className="bi bi-wifi-off text-danger" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 16 16" title="OffLine">
                                        <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z" />
                                    </svg>
                                    : <></>}
                            </div>

                            <ul className="d-flex navbar-nav align-items-center">
                                <li className="nav-item">
                                    <BtnButton className="btn-link text-nowrap" onClick={this.handleClickVerValor}>
                                        {Boolean(this.state.flVerValor) ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-fill text-secondary" viewBox="0 0 16 16">
                                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye-slash-fill text-danger" viewBox="0 0 16 16">
                                                <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                            </svg>
                                        }
                                    </BtnButton>
                                </li>
                            </ul>

                            <ul className="d-flex navbar-nav align-items-center">
                                <li className={`nav-item dropdown ${parseInt(this.state.mural) > 0 ? 'spinner-grow' : ''} `}>
                                    <Link to="/MuralAviso" className={`btn btn-sm ${parseInt(this.state.mural) > 0 ? 'btn-success' : 'btn-link text-success'}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                        </svg>
                                        {parseInt(this.state.mural) > 0 ?
                                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                {parseInt(this.state.mural) > 99 ? '99+' : parseInt(this.state.mural)}
                                            </span> : <></>}
                                    </Link>
                                </li>
                            </ul>

                            <ul className="d-flex navbar-nav align-items-center">
                                <li className="nav-item dropdown">
                                    <div className="nav-link" role="button" id="navbarDropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                        <BtnButton className={`btn-sm ${this.state.notificacoes.length > 0 ? 'btn-success' : 'btn-link text-success'} position-relative`} onClick={this.openNotificacoes}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16">
                                                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                                            </svg>
                                            {this.state.notificacoes.length > 0 ?
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                                    {this.state.notificacoes.length > 99 ? '99+' : this.state.notificacoes.length}
                                                </span> : <></>}
                                        </BtnButton>
                                    </div>
                                    <div className="dropdown-menu dropdown-menu-app dropdown-menu-end" aria-labelledby="navbarDropdownUser">
                                        <div className="p-0 m-0 bg-warning text-center fw-bold">
                                            Notificações
                                        </div>
                                        <nav className="d-grid gap-2 overflow-auto" style={{ width: "400px", maxHeight: "350px" }}>
                                            {this.state.notificacoesDetalhe.map((item, index) => (
                                                <Link key={index} to={item.urlNotificacao} className="btn btn-hover-light rounded-2 d-flex align-items-center gap-3 py-2 px-3 lh-sm text-start" >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor" className="bi bi-chat-right-quote" viewBox="0 0 16 16">
                                                        <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z" />
                                                        <path d="M7.066 4.76A1.665 1.665 0 0 0 4 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 5.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 1 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z" />
                                                        <use ></use>
                                                    </svg>
                                                    <div>
                                                        <strong className="d-block text-primary">{item.dsTitulo}</strong>
                                                        <small>
                                                            <div className="mb-1" dangerouslySetInnerHTML={{ __html: Util.nl2br(item.dsNotificacao) }} />
                                                            {Util.date2Br(item.dataNotificacao)} {item.horaNotificacao}
                                                        </small>
                                                    </div>
                                                </Link>
                                            ))}
                                            <Link to="/Notificacoes" className="btn btn-hover-light gap-3 py-2 px-3 lh-sm text-center" >
                                                Ver todas as notificações
                                            </Link>
                                        </nav>
                                    </div>
                                </li>
                            </ul>

                            <ul className="d-flex navbar-nav align-items-center">
                                <li className="nav-item dropdown">
                                    <div className="nav-link dropdown-toggle" role="button" id="navbarDropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                        </svg> {this.state.usuario?.primeiroNome}
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-dark dropdown-menu-app dropdown-menu-end" aria-labelledby="navbarDropdownUser">
                                        <li><span className="dropdown-item-text">Versão: {Config.versao}</span></li>
                                        <li><hr className="dropdown-divider" /></li>
                                        <li><button type="button" className="dropdown-item" to="#" onClick={this.handleDados}>Meus dados</button></li>
                                        <li><Link className="dropdown-item" to="/Notificacoes">Notificações</Link></li>
                                        <li><Link className="dropdown-item" to="/AlterarSenha">Alterar Senha</Link></li>
                                        <li><button type="button" className="dropdown-item" to="#" onClick={this.onLock}>Bloquear</button></li>
                                        <li><button type="button" className="dropdown-item" to="#" onClick={() => { EventBus.dispatch("modal-sair-open"); }}>Sair</button></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                {Permissao.getInstance().getPermissao(35) ? <Top10RankingHeader /> : <></>}

                <Outlet />
                <ModalConfirmacao id="modal-sair" title="Confirmação" message="Deseja sair do sistema?" confirm={this.onLogout} />

            </>
        )
    }
}
function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <Layout {...props} navigate={navigate} params={params} />
}

export default With