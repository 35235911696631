import Http from "../controllers/Http";

export default class RepresentanteContratoArquivoService {

    static urlBase = "/representanteContratoArquivo/";

    static listAllForRepresentanteContrato(idRepresentanteContrato, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byRepresentanteContrato/' + idRepresentanteContrato)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }
}
