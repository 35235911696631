import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import ProdutoServicoService from "../../services/ProdutoServicoService";

export default class FormFotoCopiar extends MyComponent {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clear = this.clear.bind(this);
        this.copy = this.copy.bind(this);

        this.handleCheckItem = this.handleCheckItem.bind(this);
        this.handleCheckAllItem = this.handleCheckAllItem.bind(this);

        this.getInitState = this.getInitState.bind(this);
        this.getLista = this.getLista.bind(this);
        this.state = this.getInitState(props);
    }

    getInitState(props) {
        return {
            inverval: null,
            data: [],
            model: {
                //idProdutoServico: this.props.idProdutoServico,
                idArquivo: this.props.idArquivo,
                busca: ""
            },
            itens: []
        };
    }

    componentDidMount() {
        super.componentDidMount();
        //this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.inverval);
    }

    onSubmit(event) {
        this.getLista();
    }

    clear() {
        this.setState(this.getInitState(), () => { });
    }

    copy() {
        ProdutoServicoService.addArquivo({ idArquivo: this.state.model.idArquivo, produtoServico: this.state.itens }, (response) => {
            this.getLista();
        });

    }

    getLista() {
        ProdutoServicoService.listForList(this.state.model, (response) => {
            this.setState(state => {
                state.data = response;
                state.itens = [];
                return state;
            });
        });
    }

    handleCheckItem(event, item) {
        if (event.target.checked) {
            this.setState(state => {
                state.itens.push(item);
                return state;
            });
        } else {
            this.setState(state => {
                state.itens.splice(state.itens.findIndex(i => parseInt(i.idProdutoServico) === parseInt(item.idProdutoServico)), 1);
                return state;
            });
        }
    }

    handleCheckAllItem(event) {
        if (event.target.checked) {
            this.setState(state => {
                state.itens = [];
                state.data.filter(i => !i.flTemIdArquivo).forEach(item => {
                    state.itens.push(item);
                });
                return state;
            });
        } else {
            this.setState(state => {
                state.itens = [];
                return state;
            });
        }
    }

    render() {
        return (
            <div className="pt-3">
                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row gap-2 mb-1">
                        <div className="col">
                            <div className="col-md-12 input-group mb-3">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" required={true} minLength={3} className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite o nome de um produto" value={this.state.model.busca} onChange={this.handleChange} />
                                <button className="btn btn-outline-primary" type="submit"> Buscar </button>
                            </div>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-danger" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </button>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-info" onClick={this.copy} disabled={this.state.itens.length === 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                    <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                    <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                </svg> Replicar para Selecionados
                            </button>
                        </div>
                    </div>
                </form>
                <table className="table table-sm table-hover">
                    <caption className="text-end">
                        {this.state.data.length} Registro(s)
                    </caption>
                    <thead>
                        <tr>
                            <th className="align-middle text-center text-nowrap">
                                <input type="checkbox" checked={this.state.itens.length === this.state.data.filter(i => !i.flTemIdArquivo).length} className="form-check-input form-check-input-size-20" onChange={this.handleCheckAllItem} />
                            </th>
                            <th className="col-md-1 text-center d-none d-md-table-cell"> Código </th>
                            <th className="col-md-9"> Nome </th>
                            <th className="col-md-1 d-none d-md-table-cell text-center text-nowrap"> Fotos </th>
                            <th className="col-md-1 text-center d-none d-md-table-cell"> Ativo? </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.map((item) => (
                            <tr key={item.idProdutoServico}>
                                <td className="align-middle text-center text-nowrap">
                                    {item.flTemIdArquivo ?
                                        <input type="checkbox" disabled={true} className="form-check-input form-check-input-size-20 bg-success" checked={true} onChange={(e) => { this.handleCheckItem(e, item) }} />
                                        :
                                        <input type="checkbox"  className="form-check-input form-check-input-size-20" checked={this.state.itens.findIndex(o => parseInt(o.idProdutoServico) === parseInt(item.idProdutoServico)) !== -1} onChange={(e) => { this.handleCheckItem(e, item) }} />
                                    }
                                </td>
                                <td className="text-center d-none d-md-table-cell">{item.idProdutoServico}</td>
                                <td>
                                    {item.nome}<br />
                                    <small className="text-secondary">
                                        {item.nomeCategoriaProdutoServico} - {item.nomeTipoProdutoServico}
                                    </small>
                                </td>
                                <td className="d-none d-md-table-cell text-center">{item.produtoServicoArquivos} </td>
                                <td className="text-center d-none d-md-table-cell">{item.flAtivo ? <span className="badge bg-success">SIM</span> : <span className="badge bg-danger">NÃO</span>}</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        )
    }
}