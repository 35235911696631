import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoginService from "../../services/LoginService";
import Auth from "../../controllers/Auth";
import MyComponent from "../../components/custom/MyComponent";
import InputEmail from "../../components/forms/elements/InputEmail";
import InputPassword from "../../components/forms/elements/InputPassword";
import Config from "../../Config";
import { toast } from "react-toastify";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';


class FormLogin extends MyComponent {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSuccessLoginGoogle = this.onSuccessLoginGoogle.bind(this);
        this.onErrorLoginGoogle = this.onErrorLoginGoogle.bind(this);

        this.state = {
            model: {
                usuario: "",
                senha: "",
                google: {}
            },
            validated: false
        };

        this.usuarios = [];

        Auth.logout();
    }

    componentDidMount() {
        super.componentDidMount();
        //Storage.clearAll();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSuccessLoginGoogle(response) {
        this.setState(state => {
            state.model.usuario = "";
            state.model.senha = "";
            state.model.google = response;
            return state;
        }, this.onSubmit);
    }

    onErrorLoginGoogle(response) {
        toast.error('Erro ao realizar login com o Google', {
            theme: "colored"
        });
    }

    onSubmit(event) {
        LoginService.login(this.state.model, (response) => {
            if (parseInt(response.flBloqueado) === 1) {
                toast.error('ATENÇÃO: O seu acesso está BLOQUEADO!', {
                    theme: "colored"
                });
            } else if (parseInt(response.nrDiasBloquear) > 0) {
                toast.error('ATENÇÃO: Faltam ' + response.nrDiasBloquear + ' dia(s) para seu acesso ser bloqueado!', {
                    theme: "colored"
                });
            }

            this.props.navigate('/');
        });
    }

    render() {
        return (
            <div className="text-center page-login w-100">

                <div className="form-signin">
                    <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="currentColor" className={"text-secondary mb-4 bi bi-lock-fill"} viewBox="0 0 16 16">
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                    </svg>

                    <h1 className="h4 mb-3 fw-normal">Acesso Restrito</h1>

                    {/*<GoogleOneTapLogin
                        onError={(error) => this.onErrorLoginGoogle(error)} 
                        onSuccess={(response) => this.onSuccessLoginGoogle(response)} 
                        googleAccountConfigs={{ client_id: Config.clientIdGoogleAuth}} 
                        disableCancelOnUnmount={false}
                        disabled={false}
                    />*/}

                    <form onSubmit={this.handleSubmit} noValidate className={`overflow-hidden needs-validation ${this.state.validated ? "was-validated" : ""} form-login`}>
                        <div className="mb-3">
                            <InputEmail layout="floating" autoComplete="on" label="Usuário" placeholder="Usuário" required md={12} value={this.state.model.usuario} name="usuario" onChange={this.handleChange} />
                            <InputPassword layout="floating" autoComplete="on" label="Senha" placeholder="Digite sua senha" col={12} value={this.state.model.senha} name="senha" onChange={this.handleChange} required />
                        </div>
                        <button className="w-100 btn btn-lg btn-primary" type="submit">Entrar</button>

                        <div className="pt-2">
                            <GoogleOAuthProvider clientId={Config.clientIdGoogleAuth}>
                                <GoogleLogin onSuccess={this.onSuccessLoginGoogle} onError={this.onErrorLoginGoogle} 
                                theme="filled_black"
                                />
                            </GoogleOAuthProvider>
                        </div>
                        
                        <span className="badge rounded-pill bg-primary my-3">Versão: {Config.versao}</span>

                        <p className="mt-2 mb-3 text-muted">
                            <img src={require("../../assets/images/LOGO-VERDE-BDM.png")} alt="Logo" />
                        </p>

                    </form>
                </div>
            </div>
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormLogin {...props} navigate={navigate} params={params} />
}

export default With