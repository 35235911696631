import ServicoContatoService from "../../services/ServicoContatoService";
import FormSelectSearchModal from "../forms/base/FormSelectSearchModal";
import FormServicoContato from "../../pages/ServicoContato/FormServicoContato";
import Permissao from "../../controllers/Permissao";

export default class SelectServicoContato extends FormSelectSearchModal {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);

        this.state.showBtns = this.props.showBtns === undefined ? true : this.props.showBtns;
        this.state.showBtnSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
        this.state.showBtnNewForm = this.state.showBtns && ((this.props.showBtnNewForm === undefined ? true : false) && Permissao.getInstance().getPermissao(7));
        this.state.showBtnNewSearch = this.state.showBtns && (this.props.showBtnSearch === undefined ? true : false);
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptions(() => {
            if (this.dispachChangeDidMount && this.props.value) {
                this.handleChange({ target: { value: this.props.value, name: this.props.name } });
            }
        });

        this.state.sizeModal = "modal-lg";
        this.state.headers = [
            { key: "value", label: "Código", className: 'text-center d-none d-md-table-cell' },
            { key: "label", label: "Serviço" },
            { key: "dsAtivo", label: "Ativo?", className: 'text-center d-none d-md-table-cell' }
        ];
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getConfigFormNew() {
        return {
            title: 'Cadastro de Serviço',
            sizeModal: 'modal-xl',
            config: {
                isModal: true
            },
            form: FormServicoContato,
            onSave: (response) => {
                this.getOptions(() => {
                    if (response.data?.idServicoContato) {
                        this.handleOpenSearch(null, response.data?.idServicoContato);
                    } else {
                        this.handleOpenSearch();
                    }
                });
            },
            onClose: () => {
                //this.handleOpenSearch();
            }
        };
    };

    isDisabled(item) {
        return parseInt(item.flAtivo) === 0 ? true : super.isDisabled();
    }

    getClassName(item) {
        return parseInt(item.flAtivo) === 0 ? 'table-danger' : '';
    }

    getOptions(callBack) {
        ServicoContatoService.getForCombo((response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}