import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import EquipamentoService from "../../services/EquipamentoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import InputNumber from "../../components/forms/elements/InputNumber";
import LiberacaoService from "../../services/LiberacaoService";
import SelectCategoriaProdutoServico from "../../components/app/SelectCategoriaProdutoServico";
import SelectTipoProdutoServicoByCategoria from "../../components/app/SelectTipoProdutoServicoByCategoria";
import SelectProdutoServicoByTipo from "../../components/app/SelectProdutoServicoByTipo";

class FormEquipamento extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeLiberacoes = this.handleChangeLiberacoes.bind(this);
        this.handleChangeLiberacoesAll = this.handleChangeLiberacoesAll.bind(this);

        this.state.model = {
            idEquipamento: "",
            nome: "",
            dsMarca: "",
            dsModelo: "",
            flAtivo : true,
            flTemAtualizacao : false,
            nrFrequenciaAtualizacao : 12,
            nrDiasAbrirOrdem : 30,
            equipamentoLiberacao : [],
            idCategoriaProdutoServico: "",
            idTipoProdutoServico: "",
            idProdutoServico : "",
        };

        this.state.crud = {
            service: EquipamentoService,
            labels: {
                title: 'Equipamento',
            },
            urlList: '/LstEquipamento',
        };

        this.state.isCard = true;

        this.state.liberacoes = [];
    }

    componentDidMount() {
        super.componentDidMount();

        LiberacaoService.list((response) => {
            this.setState({ liberacoes: response });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeLiberacoes(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.equipamentoLiberacao.push(item);
                return state;
            });
        } else {
            var index = this.state.model.equipamentoLiberacao.findIndex(i => parseInt(i.idLiberacao) === parseInt(item.idLiberacao));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.equipamentoLiberacao.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeLiberacoesAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.equipamentoLiberacao = [];

                this.state.liberacoes.forEach(function (item) {
                    state.model.equipamentoLiberacao.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.equipamentoLiberacao = [];
                return state;
            });
        }
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-equipamento-tab" data-bs-toggle="tab" data-bs-target="#nav-equipamento" type="button" role="tab" aria-controls="nav-equipamento" aria-selected="true">
                        Equipamento
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-liberacao-tab" data-bs-toggle="tab" data-bs-target="#nav-liberacao" type="button" role="tab" aria-controls="nav-liberacao" aria-selected="true">
                        Liberações
                    </button>
                </li>
            </ul>
        );
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-equipamento" role="tabpanel" aria-labelledby="nav-equipamento-tab">
                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={3} value={this.state.model.idEquipamento} name="idEquipamento" readOnly opcionalLabel={false}/>
                        <InputText label="Nome do Equipamento" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                        <InputText label="Marca do Equipamento" required md={6} value={this.state.model.dsMarca} name="dsMarca" onChange={this.handleChange} maxLength={100} />
                        <InputText label="Modelo do Equipamento" required md={6} value={this.state.model.dsModelo} name="dsModelo" onChange={this.handleChange} maxLength={100} />
                        
                    </div>
                    <div className="row mb-3 g-2">
                        <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />
                        <InputCheckSwitch label="Tem Atualização?" name="flTemAtualizacao" checked={Boolean(this.state.model.flTemAtualizacao)} onChange={this.handleChange} />
                        {Boolean(this.state.model.flTemAtualizacao) ? 
                            <>
                                <InputNumber md={3} label='Atualizar a cada (meses)' value={this.state.model.nrFrequenciaAtualizacao} name="nrFrequenciaAtualizacao" onChange={this.handleChange} required={true}/>
                                <InputNumber md={3} label='Dias para abrir ordem' value={this.state.model.nrDiasAbrirOrdem} name="nrDiasAbrirOrdem" onChange={this.handleChange} required={true} min={0}/> 
                            </>
                        : <></>}
                    </div>
                    {Boolean(this.state.model.flTemAtualizacao) ? 
                        <>
                            <h6> Produtos para abrir atualização: </h6>
                            <hr/>
                            <div className="row mb-3 g-2">                        
                                <SelectCategoriaProdutoServico showBtns={false} required={true} md={6} label="Categoria de Produto" placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={this.handleChange} />
                                <SelectTipoProdutoServicoByCategoria required={true} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} md={6} label="Tipo de Produto" placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={this.handleChange} />
                                <SelectProdutoServicoByTipo idTipoProdutoServico={this.state.model.idTipoProdutoServico} label="Produto" md={12} required placeholder="Selecione o Produto ou Serviço" name="idProdutoServico" value={this.state.model.idProdutoServico} onChange={this.handleChange} />
                            </div>
                        </>
                    : <></>}
                </div>
                <div className="tab-pane fade" id="nav-liberacao" role="tabpanel" aria-labelledby="nav-liberacao-tab">
                    <h6> Liberações </h6>
                    <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeLiberacoesAll} checked={this.state.model.equipamentoLiberacao.length === this.state.liberacoes.length} />
                    <div className="row mb-3">
                        {this.state.liberacoes.map((item) => (
                            <div key={item.idLiberacao} className="col-md-6">
                                <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.equipamentoLiberacao.findIndex(i => parseInt(i.idLiberacao) === parseInt(item.idLiberacao)) !== -1} onChange={(e) => { this.handleChangeLiberacoes(e, item) }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormEquipamento {...props} navigate={navigate} params={params} />
}

export default With