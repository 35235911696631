import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputDate from "../../components/forms/elements/InputDate";
import RepresentanteEquipamentoAtualizacaoService from "../../services/RepresentanteEquipamentoAtualizacaoService";
import Select from "../../components/forms/elements/Select";

class FormCancelarAtualizacao extends CrudForm {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state.model = {
            idRepresentanteEquipamentoAtualizacao: props.idRepresentanteEquipamentoAtualizacao || false,
            cdAcao : "P",
            dtPrevista : ""
        };

        this.state.acoes = [
            { value: "PR", label: "Escolher uma nova data para atualizar" },
            { value: "DC", label: "Desativar controle de atualização para o equipamento" },
            { value: "DE", label: "Desativar equipamento (não é mais atualizado)" },
        ];
        
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(){
        RepresentanteEquipamentoAtualizacaoService.cancelarAtualizacao(this.state.model.idRepresentanteEquipamentoAtualizacao, this.state.model, (response) =>{
            this.onSave(response);
        });
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <Select md={12} className="w-100" name="cdAcao" value={this.state.model.cdAcao} label="Cancelar a atualização atual e: " options={this.state.acoes} onChange={this.handleChange} required={true} />
                {this.state.model.cdAcao === "PR" ?
                    <InputDate md={6} label="Nova Data de Atualização" value={this.state.model.dtPrevista} name="dtPrevista" onChange={this.handleChange} required={true} 
                        min={new Date((new Date()) - ((new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)}
                    />
                : <></>}
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCancelarAtualizacao {...props} navigate={navigate} params={params} />
}

export default With