
import React, { Component } from "react";
import AppRoutes from "./routes/AppRoutes";
import { ToastContainer } from "react-toastify";
import Auth from "./controllers/Auth";
import LoginService from "./services/LoginService";
import LoadingContainer from "./components/container/LoadingContainer";
import ToastBusContainer from "./components/container/ToastBusContainer";
import BackgroundContainer from "./components/container/BackgroundContainer";
import { BrowserRouter } from "react-router-dom";
import ModalSessaoExpirada from "./components/modal/ModalSessaoExpirada";
import ModalSessaoExpiradaLogin from "./components/modal/ModalSessaoExpiradaLogin";
import ModalSearch from "./components/modal/ModalSearch";
import ModalConfirmacao from "./components/modal/ModalConfirmacao";
import ModalContainer from "./components/container/ModalContainer";
import Config from "./Config";
import Util from "./utils/Util";

export default class App extends Component {

  constructor(props) {
    super(props)

    this.state = { response: null };
    this.validateToken = this.validateToken.bind(this);
    this.validateLogged = this.validateLogged.bind(this);
    this.refreshToken = this.refreshToken.bind(this);
  }

  componentDidMount() {
    this.validateToken();
    this.validateLogged();
    this.refreshToken();
  }

  refreshToken() {
    setInterval(function () {
      if (Util.isActive()) {
        LoginService.refreshToken();
      }
    }, (Config.timeRefreshToken * 1000));
  }

  validateLogged() {
    setInterval(function () {
      LoginService.validateLogged();
    }, (Config.timeValidateLogged * 1000));
  }

  validateToken() {
    if (Auth.getToken()) {
      LoginService.validateToken(() => {
        this.setState({ response: true });
      }, () => {
        this.setState({ response: true });
      });
    } else {
      this.setState({ response: true });
    }
  }

  render() {

    return (
      <BrowserRouter basename="/">
        <ModalContainer />
        <ModalConfirmacao id="modal-confirmacao" />
        <ModalSearch />
        <ModalSessaoExpirada />
        <ModalSessaoExpiradaLogin />
        <LoadingContainer />
        <ToastBusContainer />
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        {this.state.response !== null ? <AppRoutes /> : <BackgroundContainer />}
      </BrowserRouter>
    );
  }
}


