import React from "react";
import FormEndereco from "../Endereco/FormEndereco";
import FormProprietario from "../Proprietario/FormProprietario";
import FormRepresentante from "../Representante/FormRepresentante";
import MyComponent from "../../components/custom/MyComponent";
import EventBus from "../../controllers/EventBus";
import EnderecoService from "../../services/EnderecoService";

export default class ListaEnderecoAtualizacao extends MyComponent {

    constructor(props) {
        super(props);

        this.getLista = this.getLista.bind(this);
        this.handleEditEndereco = this.handleEditEndereco.bind(this);
        this.handleEditProprietario = this.handleEditProprietario.bind(this);
        this.handleEditRepresentante = this.handleEditRepresentante.bind(this);

        this.state = {
            data: {
                representantes: [],
                proprietarios: [],
            }
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getLista() {
        EnderecoService.listAtualizacao(response => {
            this.setState(state => {
                state.data = response;
                return state;
            });
        });
    }

    handleEditProprietario(item) {
        EventBus.dispatch("modal-add", {
            title: 'Cadastro de Proprietário',
            sizeModal: 'modal-xl',
            scrollable: false,
            showBtnClose: false,
            config: {
                isModal: true,
                id: item.idProprietario,
                flAtualizacao: true,
                showBtnCancelar : false
            },
            form: FormProprietario,
            onSave: (response) => {
                this.getLista();
            },
        });
    }

    handleEditRepresentante(item) {
        EventBus.dispatch("modal-add", {
            title: 'Cadastro de Representante',
            sizeModal: 'modal-xl',
            scrollable: false,
            showBtnClose: false,
            config: {
                isModal: true,
                id: item.idRepresentante,
                flAtualizacao: true,
                showBtnCancelar : false
            },
            form: FormRepresentante,
            onSave: (response) => {
                this.getLista();
            },
        });
    }

    handleEditEndereco(item) {
        EventBus.dispatch("modal-add", {
            title: 'Cadastro de Endereço',
            sizeModal: 'modal-xl',
            scrollable: false,
            showBtnClose: false,
            config: {
                isModal: true,
                id: item.idEndereco,
                showBtnCancelar : false
            },
            form: FormEndereco,
            onSave: (response) => {
                this.getLista();
            },
        });
    }

    render() {
        return (
            <div className="m-0 p-3 my-2 row">

                {this.state.data.proprietarios.map((item, index) => (
                    <div className="col-md-6" key={index}>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="card-title">
                                    {item.nome}
                                </h5>
                                <p className="card-text">
                                    {item.email} / {item.ddi} {item.telefone}
                                </p>
                            </div>
                            <ul className="list-group list-group-flush">

                                <li className="list-group-item">
                                    <button type="button" className="btn btn-secondary w-100 text-nowrap" onClick={() => { this.handleEditProprietario(item) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg> Editar Dados do PROPRIETÁRIO
                                    </button>
                                </li>

                                {item.proprietarioEndereco.map((endereco, indexEndereco) => (
                                    <li className="list-group-item" key={indexEndereco} >
                                        <button type="button" className="btn w-100 btn-primary text-nowrap" onClick={() => { this.handleEditEndereco(endereco) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg> Editar Endereço de {endereco.nomeTipoEndereco?.toString().toUpperCase()}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}

                {this.state.data.representantes.map((item, index) => (
                    <div className="col-md-6" key={index}>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="card-title">
                                    BDM {item.empresa}
                                </h5>
                                <p className="card-text">
                                    {item.email} / {item.ddi} {item.telefone}
                                </p>
                            </div>
                            <ul className="list-group list-group-flush">

                                {item.representanteEndereco.map((endereco, indexEndereco) => (
                                    <li className="list-group-item" key={indexEndereco} >
                                        <button type="button" className="btn w-100 btn-success text-nowrap" onClick={() => { this.handleEditEndereco(endereco) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg> Editar Endereço de {endereco.nomeTipoEndereco?.toString().toUpperCase()}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}

            </div>
        );
    }
}

/*


<td className="text-nowrap">
                                    <button type="button" className="btn btn ms-2 btn-secondary text-nowrap" onClick={() => { this.handleEditRepresentante(item) }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg> Editar Dados da Representante
                                    </button>
                                </td>

*/