import ContatoMotivoService from "../../services/ContatoMotivoService";
import FormDropDownCheckBox from "../forms/base/FormDropDownCheckBox";

export default class DropDownMenuContatoMotivoByContatoTipo extends FormDropDownCheckBox {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
        if (nextProps.idContatoTipo !== this.props.idContatoTipo || nextProps.idContatoStatus !== this.props.idContatoStatus || nextProps.flAtivo !== this.props.flAtivo || nextProps.flFinalizado !== this.props.flFinalizado) {
            this.getOptions();
        }
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptionLabel(item){
        if(this.props.idContatoStatus){
            return `${item?.label}`;
        }else{
            return `${item?.nomeContatoStatus} - ${item?.label}`;
        }
    }

    getOptions(callBack) {
        var payload ={
            idContatoTipo : this.props.idContatoTipo,
            idContatoStatus : this.props.idContatoStatus            
        };

        if(this.props.flAtivo !== undefined && this.props.flAtivo !== false){
            payload.flAtivo = this.props.flAtivo;
        }

        if(this.props.flFinalizado !== undefined && this.props.flFinalizado !== false){
            payload.flFinalizado = this.props.flFinalizado;
        }

        if (this.props.idContatoTipo) {
            ContatoMotivoService.getForComboByContatoTipo(payload, (response) => {
                this.setState({ options: response }, () => {
                    if (callBack) {
                        callBack();
                    }
                });
            });
        } else {
            this.setState({ options: [] }, () => {
                if (callBack) {
                    callBack();
                }
            });
        }
    }

    render() {
        return super.render();
    }
}