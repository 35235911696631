import React from "react";
import { Link } from "react-router-dom";
import MyComponent from "../../components/custom/MyComponent";
import Config from "../../Config";
import Auth from "../../controllers/Auth";
import EventBus from "../../controllers/EventBus";
import Permissao from "../../controllers/Permissao";
import UsuarioService from "../../services/UsuarioService";
import ContatoService from "../../services/ContatoService";
import Util from "../../utils/Util";
import FormWhatsapp from "../Whatsapp/FormWhatsapp";

export default class Atalhos extends MyComponent {

    constructor(props) {
        super(props);

        this.getAniversariantes = this.getAniversariantes.bind(this);
        this.getOnline = this.getOnline.bind(this);
        this.getContatos = this.getContatos.bind(this);

        this.state = {
            usuario: {},
            aniversariantes: [],
            online: [],
            qtdeContato: 0
        };

        this.invervalOnline = null;
        this.invervalContato = null;
        this.state.usuario = Auth.getDataToken();
    }

    componentDidMount() {
        super.componentDidMount();

        if (Permissao.getInstance().getPermissao(78)) {
            this.getAniversariantes();
        }

        if (Permissao.getInstance().getPermissao(79)) {
            this.getOnline();
            this.invervalOnline = setInterval(this.getOnline, (Config.timeListUsuarioOnline * 1000));
        }

        if (Permissao.getInstance().getPermissao(12)) {
            this.getContatos();
            this.invervalContato = setInterval(this.getContatos, (Config.timeListContatosAtivos * 1000));
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.invervalOnline);
        clearInterval(this.invervalContato);
    }

    getAniversariantes() {
        UsuarioService.listForAniversariante(response => {
            this.setState({ aniversariantes: response });
        });
    }

    getOnline() {
        UsuarioService.listForOnline(response => {
            this.setState({ online: response });
        });
    }

    getContatos() {
        ContatoService.getContatosAtivos(response => {
            this.setState({ qtdeContato: response.quantidade });
        });
    }

    getDataAniversario(item) {
        if (parseInt(item.nrDiasAniversario) < -1) {
            return <span className="badge bg-info rounded-pill">{item.dataAniversario}</span>;
        }

        switch (parseInt(item.nrDiasAniversario)) {
            case -1: return <span className="badge bg-warning rounded-pill"> ONTEM </span>;
            case 0: return <span className="badge bg-success rounded-pill"> HOJE </span>;
            case 1: return <span className="badge bg-warning rounded-pill"> AMANHÃ </span>;
            default:
                return <span className="badge bg-secondary rounded-pill"> {item.dataAniversario} </span>;
        }
    }

    getColUsuarios() {
        return (Permissao.getInstance().getPermissao(78) || Permissao.getInstance().getPermissao(79));
    }

    handleClickAEnviaWhatsapp(item) {

        this.setState(state => {
            EventBus.dispatch("modal-add", {
                title: 'Envio de Whatsapp',
                sizeModal: 'modal-lg',
                config: {
                    isModal: true,
                    ddi: item.ddi,
                    celular: item.telefone,
                    mensagem: ""
                },
                form: FormWhatsapp,
                onClose: () => {
                },
                onSave: () => {
                }
            });
        });
    }

    /*handleClickWebmail() {        
        EventBus.dispatch("modal-add", {
            title: 'Webmail',
            sizeModal: 'modal-lg',
            config: {
                isModal: true
            },
            form: Webmail,
            onClose: () => {
            },
            onSave: () => {
            }
        });
    }*/

    render() {
        return (
            <>
                <div className="row g-3">
                    <div className={`${this.getColUsuarios() ? 'col-md-9' : 'col-md-12'}`}>
                        <div className="row g-3">
                            {Permissao.getInstance().getPermissao(11) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/BscRepresentante" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-geo-fill" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
                                        </svg>
                                        <h6>Busca</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(12) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstContato" className="btn btn-outline-bdm text-nowrap position-relative">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-person-rolodex" viewBox="0 0 16 16">
                                            <path d="M8 9.05a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                                            <path d="M1 1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h.5a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5.5.5 0 0 1 1 0 .5.5 0 0 0 .5.5h.5a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H6.707L6 1.293A1 1 0 0 0 5.293 1H1Zm0 1h4.293L6 2.707A1 1 0 0 0 6.707 3H15v10h-.085a1.5 1.5 0 0 0-2.4-.63C11.885 11.223 10.554 10 8 10c-2.555 0-3.886 1.224-4.514 2.37a1.5 1.5 0 0 0-2.4.63H1V2Z" />
                                        </svg>
                                        <h6>Contato</h6>
                                        {this.state.qtdeContato > 0 ?
                                            <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger">
                                                {this.state.qtdeContato}
                                            </span>
                                            : <></>}
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(9) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstProprietario" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-person-fill" viewBox="0 0 16 16">
                                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                        </svg>
                                        <h6>Proprietários</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(8) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstRepresentante" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-wrench-adjustable" viewBox="0 0 16 16">
                                            <path d="M16 4.5a4.492 4.492 0 0 1-1.703 3.526L13 5l2.959-1.11c.027.2.041.403.041.61Z" />
                                            <path d="M11.5 9c.653 0 1.273-.139 1.833-.39L12 5.5 11 3l3.826-1.53A4.5 4.5 0 0 0 7.29 6.092l-6.116 5.096a2.583 2.583 0 1 0 3.638 3.638L9.908 8.71A4.49 4.49 0 0 0 11.5 9Zm-1.292-4.361-.596.893.809-.27a.25.25 0 0 1 .287.377l-.596.893.809-.27.158.475-1.5.5a.25.25 0 0 1-.287-.376l.596-.893-.809.27a.25.25 0 0 1-.287-.377l.596-.893-.809.27-.158-.475 1.5-.5a.25.25 0 0 1 .287.376ZM3 14a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <h6>Representantes</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(15) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstProdutoServico" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-upc-scan" viewBox="0 0 16 16">
                                            <path d="M1.5 1a.5.5 0 0 0-.5.5v3a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 1.5 0h3a.5.5 0 0 1 0 1h-3zM11 .5a.5.5 0 0 1 .5-.5h3A1.5 1.5 0 0 1 16 1.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 1-.5-.5zM.5 11a.5.5 0 0 1 .5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 1 0 1h-3A1.5 1.5 0 0 1 0 14.5v-3a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v3a1.5 1.5 0 0 1-1.5 1.5h-3a.5.5 0 0 1 0-1h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 1 .5-.5zM3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                                        </svg>
                                        <h6>Produtos</h6>
                                    </Link>
                                </div> : <></>}

                            {Permissao.getInstance().getPermissao(64) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstFornecedor" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                                            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                        <h6>Fornecedores</h6>
                                    </Link>
                                </div> : <></>}


                            {Permissao.getInstance().getPermissao(41) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstPreco" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-tag" viewBox="0 0 16 16">
                                            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                        </svg>
                                        <h6>Preços</h6>
                                    </Link>
                                </div> : <></>}

                            {Permissao.getInstance().getPermissao(70) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstLoja" className="btn btn-outline-bdm text-nowrap position-relative">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-cart4" viewBox="0 0 16 16">
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                                        </svg>
                                        <h6>Loja</h6>
                                        {/*<span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger">
                                            NOVO
                            </span>*/}
                                    </Link>
                                </div> : <></>}


                            {Permissao.getInstance().getPermissao(40) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/BscRemap" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-battery-charging" viewBox="0 0 16 16">
                                            <path d="M9.585 2.568a.5.5 0 0 1 .226.58L8.677 6.832h1.99a.5.5 0 0 1 .364.843l-5.334 5.667a.5.5 0 0 1-.842-.49L5.99 9.167H4a.5.5 0 0 1-.364-.843l5.333-5.667a.5.5 0 0 1 .616-.09z" />
                                            <path d="M2 4h4.332l-.94 1H2a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h2.38l-.308 1H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2z" />
                                            <path d="M2 6h2.45L2.908 7.639A1.5 1.5 0 0 0 3.313 10H2V6zm8.595-2-.308 1H12a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H9.276l-.942 1H12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.405z" />
                                            <path d="M12 10h-1.783l1.542-1.639c.097-.103.178-.218.241-.34V10zm0-3.354V6h-.646a1.5 1.5 0 0 1 .646.646zM16 8a1.5 1.5 0 0 1-1.5 1.5v-3A1.5 1.5 0 0 1 16 8z" />
                                        </svg>
                                        <h6>Banco Remaps</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(13) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstOrdem" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                        </svg>
                                        <h6>Ordens</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(27) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstFatura" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-coin" viewBox="0 0 16 16">
                                            <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                                        </svg>
                                        <h6>Faturas</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(55) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstExtrato" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-wallet2" viewBox="0 0 16 16">
                                            <path d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z" />
                                        </svg>
                                        <h6>Extrato</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(44) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstRelatorio" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" />
                                        </svg>
                                        <h6>Relatórios</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(10) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstConfiguracao" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
                                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                        </svg>
                                        <h6>Configurações</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(1) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstUsuario" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-people" viewBox="0 0 16 16">
                                            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                                        </svg>
                                        <h6>Usuários</h6>
                                    </Link>
                                </div> : <></>}
                            {Permissao.getInstance().getPermissao(50) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstEnderecoAtualizacao" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg>
                                        <h6>Atualização</h6>
                                    </Link>
                                </div> : <></>}

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <Link to="/Notificacoes" className="btn btn-outline-bdm text-nowrap">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-bell-fill" viewBox="0 0 16 16">
                                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z" />
                                    </svg>
                                    <h6>Notificações</h6>
                                </Link>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <Link to="/MuralAviso" className="btn btn-outline-bdm text-nowrap">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                    </svg>
                                    <h6>Mural de Avisos</h6>
                                </Link>
                            </div>
                            {Permissao.getInstance().getPermissao(95) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/painel" className="btn btn-outline-bdm text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-speedometer" viewBox="0 0 16 16">
                                            <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                                            <path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z" />
                                        </svg>
                                        <h6>Painel</h6>
                                    </Link>
                                </div>
                                : <></>}

                            {Permissao.getInstance().getPermissao(113) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/Biblioteca" className="btn btn-outline-bdm text-nowrap position-relative">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-collection-play-fill" viewBox="0 0 16 16">
                                            <path d="M2.5 3.5a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-11zm2-2a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zm6.258-6.437a.5.5 0 0 1 .507.013l4 2.5a.5.5 0 0 1 0 .848l-4 2.5A.5.5 0 0 1 6 12V7a.5.5 0 0 1 .258-.437z" />
                                        </svg>
                                        <h6>Biblioteca</h6>
                                        <span className="position-absolute top-0 end-0 translate-middle badge rounded-pill bg-danger">
                                            NOVO
                                        </span>
                                    </Link>
                                </div>
                                : <></>}

                            {Permissao.getInstance().getPermissao(100) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/monitor" className="btn btn-outline-primary text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-bug" viewBox="0 0 16 16">
                                            <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z" />
                                        </svg>
                                        <h6>Monitor</h6>
                                    </Link>
                                </div>
                                : <></>}

                            {Permissao.getInstance().getPermissao(114) ?
                                <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                    <Link to="/LstCron" className="btn btn-outline-primary text-nowrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                                        </svg>
                                        <h6>CRONs</h6>
                                    </Link>
                                </div>
                                : <></>}


                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <Link to="/AlterarSenha" className="btn btn-outline-bdm text-nowrap">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                        <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                                        <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                    <h6>Alterar Senha</h6>
                                </Link>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <a href="https://webmail.bdm.email" className="btn btn-outline-bdm text-nowrap" target="_blank" rel="noopener noreferrer">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-envelope-at" viewBox="0 0 16 16">
                                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                                        <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
                                    </svg>
                                    <h6>Webmail</h6>
                                </a>
                            </div>

                            <div className="col-lg-2 col-md-3 col-sm-4 col-6">
                                <Link to="/login" className="btn btn-outline-danger text-nowrap">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25%" height="25%" fill="currentColor" className="bi bi-door-closed" viewBox="0 0 16 16">
                                        <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2zm1 13h8V2H4v13z" />
                                        <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z" />
                                    </svg>
                                    <h6>Sair</h6>
                                </Link>
                            </div>
                        </div>
                    </div>
                    {this.getColUsuarios() ?
                        <div className="col-md-3">

                            <div className="card">
                                <div className="card-header">
                                    <ul className="nav nav-tabs card-header-tabs">
                                        {Permissao.getInstance().getPermissao(78) ?
                                            <li className="nav-item">
                                                <button className="nav-link active" id="nav-aniversariantes-tab" data-bs-toggle="tab" data-bs-target="#nav-aniversariantes" type="button" role="tab" aria-controls="nav-aniversariantes" aria-selected="true">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-balloon" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M8 9.984C10.403 9.506 12 7.48 12 5a4 4 0 0 0-8 0c0 2.48 1.597 4.506 4 4.984ZM13 5c0 2.837-1.789 5.227-4.52 5.901l.244.487a.25.25 0 1 1-.448.224l-.008-.017c.008.11.02.202.037.29.054.27.161.488.419 1.003.288.578.235 1.15.076 1.629-.157.469-.422.867-.588 1.115l-.004.007a.25.25 0 1 1-.416-.278c.168-.252.4-.6.533-1.003.133-.396.163-.824-.049-1.246l-.013-.028c-.24-.48-.38-.758-.448-1.102a3.177 3.177 0 0 1-.052-.45l-.04.08a.25.25 0 1 1-.447-.224l.244-.487C4.789 10.227 3 7.837 3 5a5 5 0 0 1 10 0Zm-6.938-.495a2.003 2.003 0 0 1 1.443-1.443C7.773 2.994 8 2.776 8 2.5c0-.276-.226-.504-.498-.459a3.003 3.003 0 0 0-2.46 2.461c-.046.272.182.498.458.498s.494-.227.562-.495Z" />
                                                    </svg> Aniversariantes
                                                </button>
                                            </li>
                                            : <></>}

                                        {Permissao.getInstance().getPermissao(79) ?
                                            <li className="nav-item">
                                                <button className="nav-link" id="nav-online-tab" data-bs-toggle="tab" data-bs-target="#nav-online" type="button" role="tab" aria-controls="nav-online" aria-selected="false">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-check" viewBox="0 0 16 16">
                                                        <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                                                        <path fillRule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                                    </svg> Online
                                                </button>
                                            </li>
                                            : <></>}
                                    </ul>
                                </div>

                                <div className="card-body">
                                    <div className="tab-content" id="nav-tabContent">
                                        <div className="tab-pane fade show active overflow-auto" id="nav-aniversariantes" role="tabpanel" aria-labelledby="nav-aniversariantes-tab" style={{ maxHeight: "250px" }}>
                                            <ol className="list-group list-group-numbered list-group-item-action">
                                                {this.state.aniversariantes.map((item, index) => (
                                                    <li key={index} className="pointer list-group-item d-flex justify-content-between align-items-start" onClick={e => this.handleClickAEnviaWhatsapp(item)} aria-current="true">
                                                        <div className="ms-2 me-auto fs-8">
                                                            <div className="fs-7 fw-bold">{item.nome}</div>
                                                            {item.nrIdade} Anos - Nasc.: {Util.date2Br(item.dataNascimento)}
                                                        </div>
                                                        {this.getDataAniversario(item)}
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                        <div className="tab-pane fade overflow-auto" id="nav-online" role="tabpanel" aria-labelledby="nav-online-tab" style={{ maxHeight: "250px" }}>
                                            <ol className="list-group list-group-numbered list-group-item-action">
                                                {this.state.online.map((item, index) => (
                                                    <li key={index} className="pointer list-group-item d-flex justify-content-between align-items-start" onClick={e => this.handleClickAEnviaWhatsapp(item)} aria-current="true">
                                                        <div className="ms-2 me-auto fs-8">
                                                            <div className="fs-7 fw-bold">{item.nome}</div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : <></>}
                </div>
                <span className="badge rounded-pill bg-bdm my-3">Versão: {Config.versao}</span>
            </>
        )
    }
}