import React from "react";
import MyComponent from "../../components/custom/MyComponent";
import DashboardService from "../../services/DashboardService";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import Util from "../../utils/Util";
import IsMobile from "is-mobile";
import MostraValor from "../../components/app/MostraValor";

export default class TopByUsuario extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.getLista = this.getLista.bind(this);
        this.getDadosRepresentante = this.getDadosRepresentante.bind(this);
        this.handleClickVoltar = this.handleClickVoltar.bind(this);
        this.handleClickAvancar = this.handleClickAvancar.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.clear = this.clear.bind(this);
        this.getRepresentantes = this.getRepresentantes.bind(this);

        this.state = this.getInitState(props);

        this.arrMaxMes = [{ value: 1, label: 1 }, { value: 3, label: 3 }, { value: 4, label: 4 }, { value: 6, label: 6 }, { value: 12, label: 12 }]
    }

    getInitState(props) {

        return {
            model: {
                flFiltroCdTipoU: "S",
                maxMesFinal: IsMobile() ? 1 : 6,
                anoFinal: (new Date()).getFullYear(),
                mesFinal: (new Date()).getMonth() + 1,
                busca: ""
            },
            dataAbsoluto: {
                representantes: [],
                meses: [],
            },
            dataValor: {
                representantes: [],
                meses: [],
            },

            filtroTipo: [
                { value: "P", label: "Produto" },
                { value: "S", label: "Serviço" },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getLista() {
        DashboardService.getTopRankingAbsolutoByUsuario(this.state.model, (response) => {
            this.setState({ dataAbsoluto: response });
        });

        DashboardService.getTopRankingValorByUsuario(this.state.model, (response) => {
            this.setState({ dataValor: response });
        });
    }

    handleClickVoltar(event) {
        this.setState((state) => {
            state.model.mesFinal = state.model.mesFinal - 1;
            if (state.model.mesFinal === 0) {
                state.model.mesFinal = 12;
                state.model.anoFinal = state.model.anoFinal - 1;
            }
        }, () => {
            this.getLista();
        })
    }

    handleClickAvancar(event) {
        this.setState((state) => {
            state.model.mesFinal = state.model.mesFinal + 1;
            if (state.model.mesFinal === 13) {
                state.model.mesFinal = 1;
                state.model.anoFinal = state.model.anoFinal + 1;
            }
        }, () => {
            this.getLista();
        })
    }

    getHeaderAno() {
        var cells = [];
        var arrAnos = this.state.dataAbsoluto.meses.map(item => item.ano).filter((value, index, self) => self.indexOf(value) === index);
        arrAnos.forEach(ano => {
            let colSpan = this.state.dataAbsoluto.meses.filter(d => d.ano === ano).length;
            cells.push(<th className="text-center" key={ano} colSpan={(2 * colSpan)}> {ano}</th>);
        });

        return <>{cells}</>;
    }

    getDadosRepresentante(mes, idRepresentante, dashboard) {

        var resultado = mes.dados.find(r => parseInt(r.idRepresentante) === parseInt(idRepresentante));

        var posicao = <></>;
        var quantidade = 0;
        var valor = 0;

        if (resultado) {

            quantidade = resultado?.quantidade;
            valor = resultado?.valor;

            if (resultado.statusPosicao === "N") {
                posicao = (<>{resultado.posicao + "º"}</>)
            } else if (resultado.statusPosicao === "U") {
                posicao = (
                    <>
                        {resultado.posicao + "º"} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-up-fill text-success fw-bold" viewBox="0 0 16 16">
                            <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                        </svg>
                    </>);
            } else if (resultado.statusPosicao === "D") {
                posicao = (
                    <>
                        {resultado.posicao + "º"} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill text-danger fw-bold" viewBox="0 0 16 16">
                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                        </svg>
                    </>);
            } else if (resultado.statusPosicao === "I") {
                posicao = (
                    <>
                        {resultado.posicao + "º"} <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stop-fill text-primary" viewBox="0 0 16 16">
                            <path d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5z" />
                        </svg>
                    </>);
            }
        }

        if (dashboard === "absoluto") {
            return (<>
                <td className="text-center fw-bold">{posicao}</td>
                <td className="text-center">{quantidade}</td>
            </>);
        }

        if (dashboard === "valor") {

            return (<>
                <td className="text-center fw-bold">{posicao}</td>
                <td className="text-center">
                    <MostraValor>{Util.floatToReais(valor)}</MostraValor>
                </td>
            </>);
        }



    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    getRepresentantes() {
        return this.state.dataAbsoluto.representantes.filter(f =>
            Util.searchTextInObject(f, ["empresa", "nomeProprietario"], this.state.model.busca)
        );
    }

    render() {
        return (
            <>
                <div className="row gap-2 mb-3">
                    <div className="col">
                        <div className="col-md-12 input-group mb-3">
                            <span className="input-group-text" id="addon-wrapping">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                            <input type="text" className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite o nome de um representante ou proprietário" value={this.state.model.busca} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="col-md-auto text-end d-grid d-md-block">
                        <button type="button" className="btn btn-outline-danger" onClick={this.clear}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                            </svg> Limpar Busca
                        </button>
                    </div>
                </div>
                <div className="hstack mb-3">
                    <div className="d-grid gap-1 d-flex flex-nowrap">
                        <BtnButton className="btn-outline-primary" onClick={this.handleClickVoltar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg>
                        </BtnButton>
                        <BtnButton className="btn-outline-primary" onClick={this.handleClickAvancar}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </BtnButton>
                    </div>
                    <div className="ms-auto  d-flex flex-nowrap">
                        <BtnGroup className="w-100" name="flFiltroCdTipoU" value={this.state.model.flFiltroCdTipoU} options={this.state.filtroTipo} onChange={(e) => { this.handleChange(e, this.getLista) }} opcionalLabel={false} />

                        <BtnButton className="btn-outline-primary mx-2" onClick={this.getLista}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                            </svg>
                        </BtnButton>
                        <BtnGroup md={3} className="w-100" name="maxMesFinal" value={this.state.model.maxMesFinal} options={this.arrMaxMes} onChange={e => this.handleChange(e, this.getLista)} opcionalLabel={false} />
                    </div>
                </div>

                <h5 className="border-bottom border-dark p-2 mb-4"> Ranking por Valor Real </h5>

                <div className="table-responsive">
                    <table className="table table-striped table-bordered border-success table-sm table-hover" style={{ fontSize: "14px" }}>
                        <thead>
                            <tr>
                                <th className="text-center align-middle" rowSpan={2}> REPRESENTANTE </th>
                                {this.getHeaderAno()}
                            </tr>
                            <tr>
                                {this.state.dataAbsoluto.meses.map((mes, indexMes) => (
                                    <React.Fragment key={indexMes}>
                                        <th className="text-center" colSpan={2}>
                                            {mes.nomeMes}
                                        </th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {this.getRepresentantes().map((representante, indexRepresentante) => (
                                <tr key={indexRepresentante}>
                                    <td>
                                        <strong>{representante.empresa}</strong>
                                        <br /><small>{representante.nomeProprietario}</small>
                                    </td>
                                    {this.state.dataValor.meses.map((mes, indexMes) => (
                                        <React.Fragment key={indexMes}>
                                            {this.getDadosRepresentante(mes, representante.idRepresentante, "valor")}
                                        </React.Fragment>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <h5 className="border-bottom border-dark p-2 mb-4"> Ranking por Quantidade Absoluto </h5>

                <div className="table-responsive">
                    <table className="table table-striped table-bordered border-success table-sm table-hover" style={{ fontSize: "14px" }}>
                        <thead>
                            <tr>
                                <th className="text-center align-middle" rowSpan={2}> REPRESENTANTE </th>
                                {this.getHeaderAno()}
                            </tr>
                            <tr>
                                {this.state.dataAbsoluto.meses.map((mes, indexMes) => (
                                    <React.Fragment key={indexMes}>
                                        <th className="text-center" colSpan={2}>
                                            {mes.nomeMes}
                                        </th>
                                    </React.Fragment>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {this.getRepresentantes().map((representante, indexRepresentante) => (
                                <tr key={indexRepresentante}>
                                    <td>
                                        <strong>{representante.empresa}</strong>
                                        <br /><small>{representante.nomeProprietario}</small>
                                    </td>
                                    {this.state.dataAbsoluto.meses.map((mes, indexMes) => (
                                        <React.Fragment key={indexMes}>
                                            {this.getDadosRepresentante(mes, representante.idRepresentante, "absoluto")}
                                        </React.Fragment>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}