import Button from "../base/Button";

export default class BtnButton extends Button {

    constructor(props) {
        super(props);
        this.state.type = "submit";
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return super.render();
    }
}