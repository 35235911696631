import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import InputNumber from "../../components/forms/elements/InputNumber";
import OrdemService from "../../services/OrdemService";
import CrudForm from "../../components/forms/custom/CrudForm";

import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import InputValorReais from "../../components/forms/custom/InputValorReais";
import Util from "../../utils/Util";
import ProdutoServicoService from "../../services/ProdutoServicoService";
import Permissao from "../../controllers/Permissao";
import TextArea from "../../components/forms/elements/TextArea";
import InputFile from "../../components/forms/elements/InputFile";
import ArquivoService from "../../services/ArquivoService";
import Config from "../../Config";
import Auth from "../../controllers/Auth";
import SelectTransmissao from "../../components/app/SelectTransmissao";
import SelectCombustivel from "../../components/app/SelectCombustivel";
import TipoProdutoServicoService from "../../services/TipoProdutoServicoService";
import InputPlaca from "../../components/forms/custom/InputPlaca";
import InputChassis from "../../components/forms/custom/InputChassis";
import InputQuilometro from "../../components/forms/custom/InputQuilometro";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import SelectDDI from "../../components/app/SelectDDI";
import InputEmail from "../../components/forms/elements/InputEmail";
import InputCpf from "../../components/forms/custom/InputCpf";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import RepresentanteService from "../../services/RepresentanteService";
import InputQuantidade from "../../components/forms/custom/InputQuantidade";
import EventBus from "../../controllers/EventBus";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import OrdemClienteService from "../../services/OrdemCliente";
import OrdemVeiculoService from "../../services/OrdemVeiculo";
import FormFoto from "../ProdutoServico/FormFoto";
import FormOrdemSuporte from "./FormOrdemSuporte";
import FormOrdemAlterar from "./FormOrdemAlterar";
import SelectCategoriaProdutoServicoByUsuario from "../../components/app/SelectCategoriaProdutoServicoByUsuario";
import SelectTipoProdutoServicoByCategoriaComProduto from "../../components/app/SelectTipoProdutoServicoByCategoriaComProduto";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import BtnButton from "../../components/forms/elements/BtnButton";
import SelectPais from "../../components/app/SelectPais";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
//import SelectEnderecoByRepresentante from "../../components/app/SelectEnderecoByRepresentante";
import EnderecoService from "../../services/EnderecoService";
import InputCep from "../../components/forms/custom/InputCep";
import SelectOrdemStatus from "../../components/app/SelectOrdemStatus";
import FormRelatorioOrdem001 from "../Relatorio/FormRelatorioOrdem001";
import SelectOrdemTipoUsuario from "../../components/app/SelectOrdemTipoUsuario";
import SelectProdutoServicoByTipoUsuarioOrdemTipo from "../../components/app/SelectProdutoServicoByTipoUsuarioOrdemTipo";
import InputPercentual from "../../components/forms/custom/InputPercentual";
import InputPeso from "../../components/forms/custom/InputPeso";
import FormCotacaoFrete from "../Fatura/FormCotacaoFrete"
import ProprietarioService from "../../services/ProprietarioService";
import MostraValor from "../../components/app/MostraValor";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import UsuarioService from "../../services/UsuarioService";
import AusenciaService from "../../services/AusenciaService";
import SelectMarcaByCategoriaProdutoServico from "../../components/app/SelectMarcaByCategoriaProdutoServico";
import SelectModeloByMarcaAndAno from "../../components/app/SelectModeloByMarcaAndAno";
import ModeloService from "../../services/ModeloService";
import ListGroupProdutoServicoByTipoUsuarioOrdemTipo from "../../components/app/ListGroupProdutoServicoByTipoUsuarioOrdemTipo";
import FormEnderecoSelecao from "../Endereco/FormEnderecoSelecao";
import { toast } from "react-toastify";
import DropDownMenuTag from "../../components/app/DropDownMenuTag";
import FormRelatorioOrdem003 from "../Relatorio/FormRelatorioOrdem003";

class FormOrdem extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeItemProdutoServico = this.handleChangeItemProdutoServico.bind(this);
        this.handleClickItemProdutoServico = this.handleClickItemProdutoServico.bind(this);
        this.handleChangeItemQuantidade = this.handleChangeItemQuantidade.bind(this);
        this.handleDeleteItem = this.handleDeleteItem.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleChangeUsuarioComentario = this.handleChangeUsuarioComentario.bind(this);
        this.handleAddComentario = this.handleAddComentario.bind(this);
        this.handleChangeFlPrivado = this.handleChangeFlPrivado.bind(this);
        this.handleAddOrdemSuporte = this.handleAddOrdemSuporte.bind(this);
        this.handleAddOrdemNova = this.handleAddOrdemNova.bind(this);
        this.handleOrdemAlterarTipo = this.handleOrdemAlterarTipo.bind(this);
        this.handleChangeTipoProdutoServico = this.handleChangeTipoProdutoServico.bind(this);
        this.handleChangeOrdemTipo = this.handleChangeOrdemTipo.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleDeleteArquivo = this.handleDeleteArquivo.bind(this);
        this.handleChangeModelo = this.handleChangeModelo.bind(this);
        this.handleChangeMarca = this.handleChangeMarca.bind(this);
        this.handleChangeChassi = this.handleChangeChassi.bind(this);
        this.handleChangePlaca = this.handleChangePlaca.bind(this);
        this.handleChangeRepresentante = this.handleChangeRepresentante.bind(this);
        this.handleChangeCategoriaProdutoServico = this.handleChangeCategoriaProdutoServico.bind(this);
        this.handleChangeCelular = this.handleChangeCelular.bind(this);
        this.handleChangeCpf = this.handleChangeCpf.bind(this);
        this.handleChangeCnpj = this.handleChangeCnpj.bind(this);
        this.handleChangeCep = this.handleChangeCep.bind(this);
        this.handleChangeEndereco = this.handleChangeEndereco.bind(this);
        this.handleOpenModalFoto = this.handleOpenModalFoto.bind(this);
        this.handleImprimirIndividual = this.handleImprimirIndividual.bind(this);
        this.handleImprimirTermo = this.handleImprimirTermo.bind(this);
        this.handleChangeTipoClienteTipoProduto = this.handleChangeTipoClienteTipoProduto.bind(this);
        this.handleAddItensCarrinho = this.handleAddItensCarrinho.bind(this);
        this.handleClickCotacao = this.handleClickCotacao.bind(this);
        this.handleClickPreencherPeso = this.handleClickPreencherPeso.bind(this);
        this.handleClickExcluirFornecedorServico = this.handleClickExcluirFornecedorServico.bind(this);
        this.handleClickDadosProprietario = this.handleClickDadosProprietario.bind(this);
        this.handleClickCopiarClienteFinal = this.handleClickCopiarClienteFinal.bind(this);
        this.handleClickExpand = this.handleClickExpand.bind(this);
        this.handleImportEndereco = this.handleImportEndereco.bind(this);        
        this.handleClickAddEmoji = this.handleClickAddEmoji.bind(this);
        this.handleSetCursorComentario = this.handleSetCursorComentario.bind(this);
        this.handleChangeOrdemTag = this.handleChangeOrdemTag.bind(this);

        this.podeEditarOrdem = this.podeEditarOrdem.bind(this);
        this.podeEditarCliente = this.podeEditarCliente.bind(this);
        this.podeEditarVeiculo = this.podeEditarVeiculo.bind(this);
        this.podeEditarEndereco = this.podeEditarEndereco.bind(this);
        this.podeVerValorProduto = this.podeVerValorProduto.bind(this);
        this.podeAlterarStatusOrdem = this.podeAlterarStatusOrdem.bind(this);
        this.podeAlterarValorItem = this.podeAlterarValorItem.bind(this);
        this.podeAlterarDescontoItem = this.podeAlterarDescontoItem.bind(this);

        this.getHistoricoVeiculo = this.getHistoricoVeiculo.bind(this);
        this.getOrdemUsuario = this.getOrdemUsuario.bind(this);
        this.getAusenciaTemporariaByCategoria = this.getAusenciaTemporariaByCategoria.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.onSave = this.onSave.bind(this);

        this.state = this.getInitState();

        this.references = {
            refOrdem: React.createRef(),
            refItens: React.createRef(),
            refCliente: React.createRef(),
            refClienteFatura: React.createRef(),
            refVeiculo: React.createRef(),
            refEndereco: React.createRef(),
            refComentario: React.createRef(),
            refBotoes: React.createRef(),
        };

        this.state.crud = {
            service: OrdemService,
            keyName : "idOrdem",
            labels: {
                title: 'Ordem ',
            },
            urlList: '/LstOrdem',
        };        
    }

    getInitState() {
        let state = {            
            arrEmoji : [
                "😀","😃","😄","😁","😆","😅","😂","🤣","😊","😇","🙂","🙃","😉","😌","😍","🥰","😘","😗","😙","😚","😋","😛","😝","😜","🤪","🤨","🧐","🤓","😎","🤩","🥳","😏","😒","😞","😔","😟","😕","🙁","☹️","😣","😖","😫","😩","🥺","😢","😭","😤","😠","😡","🤬","🤯","😳","🥵","🥶","😱","😨","😰","😥","😓","🤗","🤔","🤭","🤫","🤥","😶","😐","😑","😬","🙄","😯","😦","😧","😮","😲","🥱","😴","🤤","😪","😵","🤐","🥴","🤢","🤮","🤧","😷","🤒","🤕","🤑","🤠",
                "👋","🤚","🖐","✋","🖖","👌","🤏","✌️","🤞","🤟","🤘","🤙","👈","👉","👆","🖕","👇","☝️","👍","👎","✊","👊","🤛","🤜","👏","🙌","👐","🤲","🤝","🙏",
                "❤️","🧡","💛","💚","💙","💜","🖤","🤍","🤎","💔","❣️",
            ], //https://www.blogson.com.br/lista-de-emojis-html-icones-simbolos/
            container : false,
            showComentario : false,
            idUsuario : Auth.getIdUsuarioDataToken(),
            expand : true,
            usuarioOrdem : [],
            ausencias : [],
            tipoPessoa : [
                { value: "F", label: "Física" },
                { value: "J", label: "Jurídica" },
                { value: "E", label: "Estrangeiro" }
            ],
            model : {
                idOrdem: "",
                idAcordo : 0,
                ordemCarrinho: this.props.ordemCarrinho !== undefined ? this.props.ordemCarrinho : null,
                idOrdemTipo: this.props.idOrdemTipo !== undefined ? this.props.idOrdemTipo : 1,
                idOrdemPai: "",
                dataOrdem: "",
                horaOrdem: "",
                dataUpdate: "",
                horaUpdate: "",
                idOrdemStatus: 1,
                idRepresentante: "",
                idCategoriaProdutoServico: "",
                idTipoProdutoServico: "",
                idMarca: "",
                pcDesconto: 0,
                ordemItem: [],
                ordemItemExcluir: [],
                ordemComentario: [],
                ordemComentarioExcluir: [],
                ordemTag: [],
                flFaturado: false,
                flTemSuporte: false,
                flAlterarTipoProdutoServico : false,

                flDadosCliente: true,
                flDadosVeiculo: false,
                flDadosEndereco: false,

                tipoProdutoServico: {},

                ordemVeiculo: {
                    idOrdemVeiculo: "",
                    idMarca: "",
                    idModelo: "",
                    idCombustivel: "",
                    idTransmissao: "",
                    idOrdem: "",
                    ano: "",
                    chassis: "",
                    motor: "",
                    potencia: "",
                    flTemPlaca: true,
                    idPaisPlaca: 1,
                    flDadosVeiculoPlaca: false,
                    placa: "",
                    quilometragem: "",
                    horas: "",
                    ganhoCv: "",
                    ganhoTorque: "",
                    modelo: "",
                    metodoLeitura: "",
                    dsFlash: "",
                    dsParticularidades: ""
                },

                ordemCliente: {
                    idOrdemCliente: "",
                    ddi: "55",
                    celular: "",
                    nome: "",
                    email: "",
                    cdTipoPessoa: "F",
                    cpf: "",
                    cnpj: ""
                },

                ordemClienteFatura: {
                    idOrdemClienteFatura: "",
                    ddi: "55",
                    celular: "",
                    nome: "",
                    email: "",
                    cdTipoPessoa: "F",
                    cpf: "",
                    cnpj: ""
                },

                ordemEndereco: {
                    idEndereco: "",
                    idTipoEndereco: "",
                    idPais: 1,
                    idEstado: "",
                    idCidade: "",
                    cep: "",
                    bairro: "",
                    endereco: "",
                    numero: "",
                    complemento: ""
                },

                ordemVeiculoHistorico: []
            }
        };

        return {
            ...this.state,
            ...state
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {        
        if (prevProps?.location !== this.props?.location) {
            this.setState(state =>{
                state = this.getInitState();
                return state;
            }, ()=>{
                this.componentDidMount();
            });
        }
    }
      

    componentDidMount() {
        super.componentDidMount();

        if (!this.props.params?.id) {
            if (this.props.idOrdem) {
                this.onEdit(this.props.idOrdem);
            } else if (this.state.model.ordemCarrinho && this.state.model.ordemCarrinho.length > 0) {

                var idCategoriaProdutoServico = this.state.model.ordemCarrinho[0].idCategoriaProdutoServico;
                var idTipoProdutoServico = this.state.model.ordemCarrinho[0].idTipoProdutoServico;

                this.setState(state => {
                    state.model.idCategoriaProdutoServico = idCategoriaProdutoServico;
                    state.model.idTipoProdutoServico = idTipoProdutoServico;
                    return state;
                });

            } else {
                this.setModel({ idCategoriaProdutoServico: "" });
            }
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onEdit(id) {
        super.onEdit(id, () => {
            this.getHistoricoVeiculo();
            this.getOrdemUsuario();
            OrdemService.markReadComentario(id);
            if(Boolean(this.state.isCopy)){                
                this.setState(state =>{                    
                    state.model.idOrdemStatus = 1;
                    state.model.flFaturado = false;
                    state.model.ordemComentario = [];
                    state.model.ordemItem = [];
                    return state;
                });
            }
        });
    }

    getOrdemUsuario() {
        if (parseInt(this.state.model.idRepresentante) > 0) {
            UsuarioService.listForOrdem(this.state.model.idRepresentante, (response) => {
                this.setState({ usuarioOrdem: response })
            });
        } else {
            this.setState({ usuarioOrdem: [] })
        }

    }

    handleClickExpand() {
        this.setState({ expand: !this.state.expand })
    }

    handleAddItensCarrinho(index) {
        if (this.state.model.ordemCarrinho) {
            this.state.model.ordemCarrinho.forEach((item, index) => {
                this.handleChangeItemProdutoServico(item, -1);
            });

            this.handleChangeItemQuantidade();

            this.setState(state => {
                state.model.ordemCarrinho = [];
                return state;
            });
        }
    }

    podeEditarOrdem() {
        if(parseInt(this.state.model.idAcordo) > 0){
            return false;
        }
        
        if (!this.state.model.idOrdem) {
            return true;
        }

        if (this.state.model.flFaturado) {
            return false;
        }

        return Permissao.getInstance().getPermissao(26);
    }

    podeEditarValorRevenda() {
        if (this.state.model.flFaturado) {
            return false;
        }

        return true;
    }

    podeEditarTipo(){
        if (!this.state.model.idOrdem) {
            return false;
        }

        if(!(parseInt(this.state.model.idOrdemStatus) === 1 || parseInt(this.state.model.idOrdemStatus) === 4)){
            return false;
        }

        return Permissao.getInstance().getPermissao(116);
    }

    podeEditarCliente() {
        if (!this.state.model.idOrdem) {
            return true;
        }
        return Permissao.getInstance().getPermissao(26);
    }

    podeEditarVeiculo() {
        if (!this.state.model.idOrdem) {
            return true;
        }
        return Permissao.getInstance().getPermissao(26);
    }

    podeEditarEndereco() {
        if (!this.state.model.idOrdem) {
            return true;
        }
        return Permissao.getInstance().getPermissao(26);
    }

    podeVerValorProduto() {
        return Permissao.getInstance().getPermissao(22);
    }

    podeAlterarStatusOrdem() {
        return Permissao.getInstance().getPermissao(21);
    }

    podeAlterarValorItem() {
        return Permissao.getInstance().getPermissao(19);
    }

    podeAlterarPesoItem() {
        return Permissao.getInstance().getPermissao(83);
    }

    podeAlterarDescontoItem() {
        return Permissao.getInstance().getPermissao(20);
    }

    podeAlterarDescontoOrdem() {
        return Permissao.getInstance().getPermissao(76);
    }

    podeAlterarTags() {
        return Permissao.getInstance().getPermissao(125);
    }

    podeCadatrarAlterarGanho() {
        return Permissao.getInstance().getPermissao(28);
    }

    podeCadatrarAlterarTorque() {
        return Permissao.getInstance().getPermissao(28);
    }

    podeInserirComentario() {
        return !(Boolean(this.state.model.flTemSuporte) && parseInt(this.state.model.idOrdemStatus) === 5);
    }

    getAusenciaTemporariaByCategoria() {
        if (this.state.model.idCategoriaProdutoServico) {
            AusenciaService.listVigenteByCategoriaProdutoServico(this.state.model.idCategoriaProdutoServico, response => {
                this.setState({ ausencias: response });
            });
        } else {
            this.setState({ ausencias: [] });
        }
    }

    handleClickAddEmoji(emojie, item) {
        item.selectionStart = parseInt(item?.selectionStart ? item?.selectionStart : 0);
        item.selectionEnd = parseInt(item?.selectionEnd ? item?.selectionEnd : 0);

        var dsComentario = "";
        if(item.selectionStart > 0){
            dsComentario+= item.dsComentario.substring(0, item.selectionStart);
        }
        dsComentario+=emojie 
        if(item.selectionStart < item.dsComentario.length){
            dsComentario+= item.dsComentario.substring(item.selectionStart, item.dsComentario.length)
        }

        item.dsComentario = dsComentario;
        item.selectionStart = parseInt(item.selectionStart) + emojie.length;
        item.selectionEnd = parseInt(item.selectionEnd) + emojie.length;
        this.setState(item);
        
    }

    handleSetCursorComentario(e, item){        
        item.selectionStart = e.target.selectionStart;
        item.selectionEnd = e.target.selectionEnd;
        this.setState(item);
        
    }

    handleChangeCategoriaProdutoServico(event) {
        this.setModel({ ordemItem: [] });
        this.getAusenciaTemporariaByCategoria();
    }

    handleChangeOrdemTipo(event) {
        this.setState((state) => {
            state.model.ordemItem = [];
            return state;
        }, () => {
            this.handleChangeTipoClienteTipoProduto();
            this.handleAddItensCarrinho();
        });
    }

    handleChangeTipoClienteTipoProduto(event) {
        this.setState((state) => {
            state.model.flDadosVeiculo = state.model.tipoProdutoServico.flDadosVeiculo;
            state.model.ordemVeiculo.flDadosVeiculoPlaca = state.model.tipoProdutoServico.flDadosVeiculoPlaca;
            state.model.flDadosEndereco = state.model.tipoProdutoServico.flDadosEndereco;
            state.model.flDadosCliente = true;

            if (parseInt(state.model.idOrdemTipo) === 2) {
                state.model.flDadosEndereco = true;
            } else if (parseInt(state.model.idOrdemTipo) === 3) {
                state.model.flDadosEndereco = false;
            }

            return state;
        });
    }

    handleChangeTipoProdutoServico(event) {

        if (this.state.model.idTipoProdutoServico) {
            TipoProdutoServicoService.getOneById(this.state.model.idTipoProdutoServico, (response) => {
                this.setState((state) => {
                    state.model.tipoProdutoServico = response;
                    return state;
                }, this.handleChangeTipoClienteTipoProduto);
            });
        } else {
            this.setState((state) => {
                state.model.tipoProdutoServico = {};
                return state;
            }, this.handleChangeTipoClienteTipoProduto);
        }

        this.setModel({ ordemItem: [] }, () => {
            this.handleAddItem();
        });
    }

    getValueEstoque(item) {
        if (item.flControlaEstoque) {
            var vlEstoque = parseFloat(item.vlEstoque).toFixed(item.nrCasasDecimais);
            if (item.vlEstoque === 0) {
                return vlEstoque;
            } else if (item.vlEstoque < 0) {
                return (Util.formatEstoque(vlEstoque));
            } else {
                return (Util.formatEstoque(vlEstoque));
            }
        }
        return "";
    }

    getNrQuantidadeItem(idProdutoServico) {
        return parseFloat(this.state.model.ordemItem.reduce(
            (soma, item) => soma + (parseInt(idProdutoServico) === parseInt(item.idProdutoServico) ? (item.nrQuantidade || 0) : 0
            ), 0).toFixed(2)
        );
    }

    handleClickItemProdutoServico(item){
        this.handleChangeItemProdutoServico(item);
        toast.success(item.label + " adicionado no carrinho!");
    }

    handleChangeItemProdutoServico(item, index = -1, callback = false) {
        if (item.idProdutoServico) {
            ProdutoServicoService.getOneByIdByUsuarioOrdemTipo(item.idProdutoServico, this.state.model.idOrdemTipo, (response) => {
                this.setState((state) => {

                    if (index === -1) {
                        state.model.ordemItem.push({
                            idOrdemItem: "",
                            idProdutoServico: item.idProdutoServico !== undefined ? item.idProdutoServico : "",
                            nrQuantidade: item.nrQuantidade !== undefined ? item.nrQuantidade : 1,
                            vlPreco: 0,
                            vlPrecoPromocao: 0,
                            vlPrecoRevenda : 0, //Permissao.getInstance().getPermissao(129) ? 0 : "",
                            flPrecoRevenda: false,
                            vlDesconto: 0,
                            nrCasasDecimais: 0,
                            vlEstoque: 0,
                            produtoServicoPromocao: [],
                            uidProdutoServicoArquivo: "",
                            flEmbalagem: item.flEmbalagem !== undefined ? item.flEmbalagem : false,
                            vlPesoBrutoConteudo: 0,
                            vlPesoLiquidoConteudo: 0,
                            vlTransporte: 0,
                            vlLargura: 0,
                            vlAltura: 0,
                            vlComprimento: 0,
                            vlDiametro: 0,
                            idProdutoServicoTipoObjeto: "",
                            idFornecedorServico: "",
                            flAvisoRecebimento: false,
                            flMaoPropria: false,
                            vlDeclarado: 0,
                            dsObservacaoCompra: ""
                        });
                        index = state.model.ordemItem.length - 1;
                    }

                    state.model.ordemItem[index].vlPreco = response.vlPreco;
                    
                    if(item.flAtualizarPreco !== undefined && !Boolean(item.flAtualizarPreco)){
                        state.model.ordemItem[index].vlPreco = item.vlPreco;
                    }                    

                    state.model.ordemItem[index].nomeProdutoServico = response.nome;
                    state.model.ordemItem[index].dsEspecificacaoProdutoServico = response.dsEspecificacao;
                    state.model.ordemItem[index].vlPrecoRevenda = response?.vlPrecoRevenda ? response?.vlPrecoRevenda : 0;
                    state.model.ordemItem[index].vlPrecoPromocao = 0;
                    state.model.ordemItem[index].nrCasasDecimais = response.nrCasasDecimais;
                    state.model.ordemItem[index].siglaUnidadeMedida = response.siglaUnidadeMedida;
                    state.model.ordemItem[index].nomeUnidadeMedida = response.nomeUnidadeMedida;
                    state.model.ordemItem[index].vlEstoque = response.vlEstoque;
                    state.model.ordemItem[index].flControlaEstoque = response.flControlaEstoque;
                    state.model.ordemItem[index].produtoServicoArquivos = response.produtoServicoArquivos;
                    state.model.ordemItem[index].produtoServicoPromocao = response.produtoServicoPromocao;
                    state.model.ordemItem[index].uidProdutoServicoArquivo = response.uidProdutoServicoArquivo;
                    state.model.ordemItem[index].flEmbalagem = response.flEmbalagem;
                    state.model.ordemItem[index].flPrecoRevenda = response?.flPrecoRevenda;
                    state.model.ordemItem[index].vlPesoBruto = response.vlPesoBruto;
                    state.model.ordemItem[index].vlPesoLiquido = response.vlPesoLiquido;

                    state.model.ordemItem[index].vlLargura = response.vlLargura;
                    state.model.ordemItem[index].vlAltura = response.vlAltura;
                    state.model.ordemItem[index].vlComprimento = response.vlComprimento;
                    state.model.ordemItem[index].vlDiametro = response.vlDiametro;
                    state.model.ordemItem[index].idProdutoServicoTipoObjeto = response.idProdutoServicoTipoObjeto;

                    state.model.ordemItem[index].dsObservacaoCompra = response.dsObservacaoCompra;

                    return state;
                }, () => {
                    if (callback) {
                        callback();
                    } else {
                        this.handleChangeItemQuantidade();
                    }
                });
            });
        }
    }

    handleChangeItemQuantidade() {
        this.setState((state) => {
            state.model.ordemItem.forEach((ordemItem, indexOrdemItem) => {
                if (ordemItem.produtoServicoPromocao) {
                    var nrQuantidadeTotal = this.getNrQuantidadeItem(ordemItem.idProdutoServico);

                    var vlDesconto = ordemItem.produtoServicoPromocao?.reduce((soma, promocao) => {
                        return soma += (nrQuantidadeTotal >= promocao.nrQuantidadeInicial && nrQuantidadeTotal <= promocao.nrQuantidadeFinal) ? promocao.vlDesconto : 0;
                    }, 0);
                    vlDesconto = parseFloat(vlDesconto).toFixed(2);
                    state.model.ordemItem[indexOrdemItem].vlPrecoPromocao = ordemItem.vlPreco - vlDesconto;
                    state.model.ordemItem[indexOrdemItem].vlDesconto = ordemItem.nrQuantidade * vlDesconto;
                }
            });
            return state;
        });

    }

    handleAddItem() {
        this.setState((state) => {
            state.model.ordemItem.push({
                idOrdemItem: "",
                idProdutoServico: "",
                nrQuantidade: 1,
                vlPreco: 0,
                vlPrecoPromocao: 0,
                vlPrecoRevenda : 0,
                flPrecoRevenda: false,
                vlDesconto: 0,
                nrCasasDecimais: 0,
                vlEstoque: 0,
                flEmbalagem: false,
                vlPesoBrutoConteudo: 0,
                vlPesoLiquidoConteudo: 0,
                vlTransporte: 0,
                vlLargura: 0,
                vlAltura: 0,
                vlComprimento: 0,
                vlDiametro: 0,
                idProdutoServicoTipoObjeto: "",
                idFornecedorServico: "",
                flAvisoRecebimento: false,
                flMaoPropria: false,
                vlDeclarado: 0,
                produtoServicoPromocao: [],
                dsObservacaoCompra: ""
            });
            return state;
        });
    }

    handleDeleteItem(index, item) {

        if (item.idOrdemItem) {
            this.setState((state) => {
                state.model.ordemItemExcluir.push(item);
                return state;
            });
        }

        this.setState((state) => {
            state.model.ordemItem.splice(index, 1);
            return state;
        }, this.handleChangeItemQuantidade);
    }

    getTotalQtdeItem() {
        return parseFloat(this.state.model.ordemItem.reduce((soma, item) => soma + (item.nrQuantidade || 0), 0)).toFixed(2);
    }

    getTotalValorItem() {
        return this.state.model.ordemItem.reduce((soma, item) => soma + (item.vlPreco || 0), 0);
    }

    getTotalDescontoItem() {
        return this.state.model.ordemItem.reduce((soma, item) => soma + (item.vlDesconto || 0), 0);
    }

    getTotalOrdem() {
        var vlOrdem = this.state.model.ordemItem.reduce((soma, item) => soma + (Number(parseFloat(item.vlPreco * item.nrQuantidade).toFixed(2)) || 0), 0);
        return vlOrdem;
    }

    getTotalPesoBruto() {
        var vlPesoBruto = this.state.model.ordemItem.reduce((soma, item) => soma + ((item.vlPesoBruto * item.nrQuantidade) || 0), 0);
        return vlPesoBruto;
    }

    getTotalPesoLiquido() {
        var vlPesoLiquido = this.state.model.ordemItem.reduce((soma, item) => soma + ((item.vlPesoLiquido * item.nrQuantidade) || 0), 0);
        return vlPesoLiquido;
    }

    getDescontoPercentual() {
        var vlOrdem = this.getTotalValorItem() - this.getTotalDescontoItem();
        var vlDesconto = (vlOrdem * this.state.model.pcDesconto) / 100;
        return vlDesconto;
    }

    getTotalDesconto() {
        return this.getDescontoPercentual() + this.getTotalDescontoItem();
    }

    getTotalValorPagar() {
        var vlOrdem = this.state.model.ordemItem.reduce((soma, item) => soma + (Number(parseFloat(item.vlPreco * item.nrQuantidade).toFixed(2)) - item.vlDesconto || 0), 0);
        var vlDesconto = (vlOrdem * this.state.model.pcDesconto) / 100;
        return vlOrdem - vlDesconto;
    }

    getHistoricoVeiculo() {
        var filtro = {
            idOrdem: this.state.model.idOrdem,
            placa: this.state.model.ordemVeiculo.placa,
            chassis: this.state.model.ordemVeiculo.chassis,
        };

        if (this.state.model.ordemVeiculo.placa || this.state.model.ordemVeiculo.chassis) {
            OrdemService.getAllForHistorico(filtro, (response) => {
                this.setModel({ ordemVeiculoHistorico: response });
            });
        } else {
            this.setModel({ ordemVeiculoHistorico: [] });
        }
    }

    handleAddComentario() {
        this.setState((state) => {

            var ordemComentarioUsuario = [];
            //if (Permissao.getInstance().getPermissao(104)) {
                state.usuarioOrdem.forEach(function (usuario) {
                    if (state.model.ordemComentario.findIndex(u => parseInt(u.idUsuario) === parseInt(usuario.idUsuario)) !== -1) {
                        ordemComentarioUsuario.push(usuario);
                    }
                });
            //}

            state.model.ordemComentario.push({
                idOrdemComentario: "",
                dsComentario: "",
                idArquivo: "",
                nomeArquivo: "",
                uid: "",
                ordemComentarioArquivo: [],
                ordemComentarioUsuario: ordemComentarioUsuario,
                flPrivado: false
            });
            return state;
        });
    }

    handleChangeFlPrivado(index) {
        this.setState((state) => {
            state.model.ordemComentario[index].ordemComentarioUsuario = [];
            return state;
        });
    }

    handleDeleteComentario(index, item) {
        this.setState((state) => {
            state.model.ordemComentario.splice(index, 1);
            return state;
        });
    }

    handleFileChange(event, index, item) {

        var files = event.target?.files;

        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                ArquivoService.upload(files[i], (response) => {
                    this.setState((state) => {

                        state.model.ordemComentario[index].ordemComentarioArquivo.push({
                            idArquivo: response.data.idArquivo,
                            nomeArquivo: response.data.nome,
                            uid: response.data.uid,
                        });

                        return state;
                    });
                });
            }
        }
    }

    handleDeleteArquivo(indexComentario, indexArquivo) {
        this.setState((state) => {
            state.model.ordemComentario[indexComentario].ordemComentarioArquivo.splice(indexArquivo, 1);
            return state;
        });
    }

    handleChangeUsuarioComentario(indexComentario, indexUsuarioComentario, usuario) {
        this.setState((state) => {
            if (indexUsuarioComentario >= 0) {
                state.model.ordemComentario[indexComentario].ordemComentarioUsuario.splice(indexUsuarioComentario, 1);
            } else {
                state.model.ordemComentario[indexComentario].ordemComentarioUsuario.push(usuario);
            }
            return state;
        });
    }

    handleChangeCelular(event, property) {
        if (this.state.model[property].ddi && this.state.model[property]?.celular) {
            var celular = Util.stringToNumber(this.state.model[property].celular);

            if (this.state.model[property].ddi && celular.length >= 10) {
                OrdemClienteService.getOneByCelular({ ddi: this.state.model[property].ddi, celular: celular }, (response) => {
                    if (response.idOrdemCliente) {
                        EventBus.dispatch("modal-confirmacao-open", {
                            config: {
                                title: "Cliente encontrado através do CELULAR?",
                                message: <span className="fw-bold">Quer utilizar esses dados na sua ordem?</span>,
                                btnConfirmText: "Sim, preencher com os dados acima",
                                description:
                                    (
                                        <div className="pt-2">
                                            <div><strong> NOME: </strong> {response.nome} </div>
                                            <div><strong> E-MAIL: </strong> {response.email} </div>
                                            <div><strong> TIPO: </strong>
                                                {response.cdTipoPessoa === "F" ? "FÍSICA" : ""}
                                                {response.cdTipoPessoa === "J" ? "JURÍDICA" : ""}
                                                {response.cdTipoPessoa === "E" ? "ESTRANGERIA" : ""}
                                            </div>
                                            {response.cdTipoPessoa === "F" ? <div><strong> CPF: </strong> {response.cpf} </div> : <></>}
                                            {response.cdTipoPessoa === "J" ? <div><strong> CNPJ: </strong> {response.cnpj} </div> : <></>}
                                        </div>
                                    ),
                                style: "warning",
                                confirm: () => {
                                    this.setState((state) => {
                                        if (response.nome) {
                                            state.model[property].nome = response.nome;
                                        }
                                        if (response.email) {
                                            state.model[property].email = response.email;
                                        }
                                        if (response.cdTipoPessoa) {
                                            state.model[property].cdTipoPessoa = response.cdTipoPessoa;
                                        }
                                        if (state.model[property].cdTipoPessoa === "F") {
                                            state.model[property].cpf = response.cpf;
                                        } else if (state.model[property].cdTipoPessoa === "J") {
                                            state.model[property].cnpj = response.cnpj;
                                        }
                                        return state;
                                    });
                                }
                            }
                        });
                    }
                });
            }
        }
    }

    handleChangeCpf(event) {
        var cpf = this.state.model.ordemCliente.cpf.replaceAll("_", "").replaceAll(" ", "");

        if (cpf.length === 14) {
            OrdemClienteService.getOneByCpf(this.state.model.ordemCliente, (response) => {
                if (response.idOrdemCliente) {

                    EventBus.dispatch("modal-confirmacao-open", {
                        config: {
                            title: "Dados do cliente encontrado através do CPF?",
                            message: <span className="fw-bold">Quer utilizar esses dados na sua ordem?</span>,
                            btnConfirmText: "Sim, preencher com os dados acima",
                            description: <>
                                <div><strong> NOME: </strong> {response.nome} </div>
                                <div><strong> DDI: </strong> {response.ddi} </div>
                                <div><strong> CELULAR: </strong> {response.celular} </div>
                                <div><strong> E-MAIL: </strong> {response.email} </div>
                            </>,
                            style: "warning",
                            confirm: () => {
                                this.setState((state) => {
                                    if (response.nome) {
                                        state.model.ordemCliente.nome = response.nome;
                                    }
                                    if (response.ddi) {
                                        state.model.ordemCliente.ddi = response.ddi;
                                    }
                                    if (response.celular) {
                                        state.model.ordemCliente.celular = response.celular;
                                    }
                                    if (response.email) {
                                        state.model.ordemCliente.email = response.email;
                                    }
                                    return state;
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    handleChangeCnpj(event) {
        var cnpj = this.state.model.ordemCliente.cnpj.replaceAll("_", "").replaceAll(" ", "");

        if (cnpj.length === 18) {
            OrdemClienteService.getOneByCnpj(this.state.model.ordemCliente, (response) => {
                if (response.idOrdemCliente) {

                    EventBus.dispatch("modal-confirmacao-open", {
                        config: {
                            title: "Dados do cliente encontrado através do CNPJ?",
                            message: <span className="fw-bold">Quer utilizar esses dados na sua ordem?</span>,
                            btnConfirmText: "Sim, preencher com os dados acima",
                            description: <>
                                <div><strong> NOME: </strong> {response.nome} </div>
                                <div><strong> DDI: </strong> {response.ddi} </div>
                                <div><strong> CELULAR: </strong> {response.celular} </div>
                                <div><strong> E-MAIL: </strong> {response.email} </div>
                            </>,
                            style: "warning",
                            confirm: () => {
                                this.setState((state) => {
                                    if (response.nome) {
                                        state.model.ordemCliente.nome = response.nome;
                                    }
                                    if (response.ddi) {
                                        state.model.ordemCliente.ddi = response.ddi;
                                    }
                                    if (response.celular) {
                                        state.model.ordemCliente.celular = response.celular;
                                    }
                                    if (response.email) {
                                        state.model.ordemCliente.email = response.email;
                                    }
                                    return state;
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    handleChangeChassi(e) {
        var chassis = this.state.model.ordemVeiculo.chassis.replaceAll("_", "").replaceAll(" ", "");

        if (chassis.length === 17) {
            OrdemVeiculoService.getOneByChassis(this.state.model.ordemVeiculo, (response) => {

                if (response.idOrdemVeiculo) {

                    EventBus.dispatch("modal-confirmacao-open", {
                        config: {
                            title: "Dados do veículo encontrado através do CHASSIS?",
                            message: <span className="fw-bold">Quer utilizar esses dados na sua ordem?</span>,
                            btnConfirmText: "Sim, preencher com os dados acima",
                            description: <>
                                <div><strong> CHASSIS: </strong> {response.chassis} </div>
                                <div><strong> PLACA: </strong> {response.placa} </div>
                                <div><strong> MARCA: </strong> {response.nomeMarca} </div>
                                <div><strong> MODELO: </strong> {response.modelo} </div>
                                <div><strong> TRANSMISSÃO: </strong> {response.nomeTransmissao} </div>
                                <div><strong> COMBUSTÍVEL: </strong> {response.nomeCombustivel} </div>
                                <div><strong> ANO: </strong> {response.ano} </div>
                                <div><strong> MOTOR: </strong> {response.motor} </div>
                            </>,
                            style: "warning",
                            confirm: () => {
                                this.setState((state) => {
                                    state.model.ordemVeiculo.idMarca = parseInt(response.idMarca);
                                    state.model.ordemVeiculo.idModelo = parseInt(response.idModelo);
                                    state.model.ordemVeiculo.idCombustivel = parseInt(response.idCombustivel);
                                    state.model.ordemVeiculo.idTransmissao = parseInt(response.idTransmissao);
                                    state.model.ordemVeiculo.idOrdem = parseInt(response.idOrdem);
                                    state.model.ordemVeiculo.ano = response.ano;
                                    if (Boolean(this.state.model?.ordemVeiculo.flDadosVeiculoPlaca) && Boolean(this.state.model.ordemVeiculo.flTemPlaca)) {
                                        state.model.ordemVeiculo.placa = response.placa;
                                    }
                                    state.model.ordemVeiculo.motor = response.motor;
                                    state.model.ordemVeiculo.potencia = response.potencia;
                                    state.model.ordemVeiculo.quilometragem = response.quilometragem;
                                    state.model.ordemVeiculo.horas = response.horas;
                                    state.model.ordemVeiculo.modelo = response.modelo;
                                    state.model.ordemVeiculo.metodoLeitura = response.metodoLeitura;
                                }, this.getHistoricoVeiculo);
                            }
                        }
                    });

                } else {

                    EventBus.dispatch("modal-confirmacao-open", {
                        config: {
                            title: "Dados do veículo encontrado através do CHASSIS?",
                            message: <span className="fw-bold">Quer utilizar esses dados na sua ordem?</span>,
                            btnConfirmText: "Sim, preencher com os dados acima",
                            description: <>
                                <div><strong> CHASSIS: </strong> {response.chassis} </div>
                                <div><strong> MARCA: </strong> {response.nomeMarca} </div>
                                <div><strong> ANO: </strong> {response.ano} </div>
                            </>,
                            style: "warning",
                            confirm: () => {
                                this.setState((state) => {
                                    if (response.ano) {
                                        state.model.ordemVeiculo.ano = response.ano;
                                    }
                                    if (response.marca && response.marca.idMarca) {
                                        state.model.ordemVeiculo.idMarca = response.marca.idMarca;
                                    }
                                    if (response.marca && response.marca.idModelo) {
                                        state.model.ordemVeiculo.idModelo = response.marca.idModelo;
                                    }
                                }, this.getHistoricoVeiculo);
                            }
                        }
                    });


                }
            });
        }
    }

    handleChangePlaca(e) {

        var placa = this.state.model.ordemVeiculo.placa.replaceAll("_", "").replaceAll(" ", "");

        if (placa.length >= 8) {
            OrdemVeiculoService.getOneByPlaca(this.state.model.ordemVeiculo, (response) => {

                if (response.idOrdemVeiculo) {
                    EventBus.dispatch("modal-confirmacao-open", {
                        config: {
                            title: "Dados do veículo encontrado através da PLACA?",
                            message: <span className="fw-bold">Quer utilizar esses dados na sua ordem?</span>,
                            btnConfirmText: "Sim, preencher com os dados acima",
                            description: <>
                                <div><strong> CHASSIS: </strong> {response.chassis} </div>
                                <div><strong> PLACA: </strong> {response.placa} </div>
                                <div><strong> MARCA: </strong> {response.nomeMarca} </div>
                                <div><strong> MODELO: </strong> {response.modelo} </div>
                                <div><strong> TRANSMISSÃO: </strong> {response.nomeTransmissao} </div>
                                <div><strong> COMBUSTÍVEL: </strong> {response.nomeCombustivel} </div>
                                <div><strong> ANO: </strong> {response.ano} </div>
                                <div><strong> MOTOR: </strong> {response.motor} </div>
                            </>,
                            style: "warning",
                            confirm: () => {
                                this.setState((state) => {
                                    state.model.ordemVeiculo.idMarca = parseInt(response.idMarca);
                                    state.model.ordemVeiculo.idCombustivel = parseInt(response.idCombustivel);
                                    state.model.ordemVeiculo.idTransmissao = parseInt(response.idTransmissao);
                                    state.model.ordemVeiculo.idOrdem = parseInt(response.idOrdem);
                                    state.model.ordemVeiculo.ano = response.ano;
                                    state.model.ordemVeiculo.chassis = response.chassis;
                                    state.model.ordemVeiculo.motor = response.motor;
                                    state.model.ordemVeiculo.potencia = response.potencia;
                                    state.model.ordemVeiculo.quilometragem = response.quilometragem;
                                    state.model.ordemVeiculo.horas = response.horas;
                                    state.model.ordemVeiculo.modelo = response.modelo;
                                    state.model.ordemVeiculo.metodoLeitura = response.metodoLeitura;
                                }, this.getHistoricoVeiculo);
                            }
                        }
                    });
                }
            });
        }
    }

    handleChangeMarca(e) {
        this.setState((state) => {
            state.model.ordemVeiculo.idModelo = "";
            state.model.ordemVeiculo.modelo = "";
            return state;
        });
    }

    handleChangeModelo(e) {
        if (parseInt(this.state.model.ordemVeiculo.idModelo) > 0) {
            ModeloService.getOneById(this.state.model.ordemVeiculo.idModelo, (response) => {
                this.setState((state) => {
                    if (parseInt(response.idCombustivel) > 0) {
                        state.model.ordemVeiculo.idCombustivel = parseInt(response.idCombustivel);
                    }

                    if (parseInt(response.idTransmissao) > 0) {
                        state.model.ordemVeiculo.idTransmissao = parseInt(response.idTransmissao);
                    }
                    if (response.dsMotor) {
                        state.model.ordemVeiculo.motor = response.dsMotor;
                    }
                    if (response.dsPotencia) {
                        state.model.ordemVeiculo.potencia = response.dsPotencia;
                    }
                    if (response.dsModelo) {
                        state.model.ordemVeiculo.modelo = response.dsModelo;
                    }
                    return state;
                });
            });
        }
    }

    handleChangeRepresentante(e) {
        this.getOrdemUsuario();

        if (parseInt(this.state.model.idRepresentante) > 0) {
            RepresentanteService.getOneByIdByUsuario(this.state.model.idRepresentante, (representante) => {
                this.setState((state) => {
                    state.model.ordemEndereco = {
                        idEndereco: "",
                        idTipoEndereco: "",
                        idPais: 1,
                        idEstado: "",
                        idCidade: "",
                        cep: "",
                        bairro: "",
                        endereco: "",
                        numero: "",
                        complemento: ""
                    };
                    state.model.ordemCliente.ddi = representante.ddiPais;
                    state.model.ordemEndereco.idEndereco = representante.idEnderecoEntrega ? representante.idEnderecoEntrega : "";
                    return state;
                }, this.handleChangeEndereco);
            });
        }
    }

    handleClickDadosProprietario(property) {
        if (parseInt(this.state.model.idRepresentante) > 0) {
            RepresentanteService.getOneByIdByUsuario(this.state.model.idRepresentante, (representante) => {
                if (parseInt(representante?.idProprietario) > 0) {
                    ProprietarioService.getOneByIdByUsuario(representante.idProprietario, proprietario => {
                        this.setState((state) => {
                            var proprietarioTelefone = proprietario.proprietarioTelefone.find(p => Boolean(p.flPrincipal));
                            var proprietarioEmail = proprietario.proprietarioEmail.find(p => Boolean(p.flPrincipal));

                            state.model[property].ddi = proprietarioTelefone?.ddi ? proprietarioTelefone.ddi : "";
                            state.model[property].celular = proprietarioTelefone?.telefone ? proprietarioTelefone.telefone : "";
                            state.model[property].nome = proprietario.nome;
                            state.model[property].email = proprietarioEmail?.email ? proprietarioEmail.email : "";
                            state.model[property].cdTipoPessoa = proprietario.cdTipoPessoa;
                            state.model[property].cpf = proprietario.cdTipoPessoa === "F" ? proprietario.cpf : "";
                            state.model[property].cnpj = proprietario.cdTipoPessoa === "J" ? proprietario.cnpj : "";

                            return state;
                        });
                    });
                }
            });
        }
    }

    handleClickCopiarClienteFinal() {
        this.setState((state) => {
            state.model.ordemClienteFatura.ddi = state.model.ordemCliente.ddi;
            state.model.ordemClienteFatura.celular = state.model.ordemCliente.celular;
            state.model.ordemClienteFatura.nome = state.model.ordemCliente.nome;
            state.model.ordemClienteFatura.email = state.model.ordemCliente.email;
            state.model.ordemClienteFatura.cdTipoPessoa = state.model.ordemCliente.cdTipoPessoa;
            state.model.ordemClienteFatura.cpf = state.model.ordemCliente.cpf;
            state.model.ordemClienteFatura.cnpj = state.model.ordemCliente.cnpj;
            return state;
        });
    }

    handleImportEndereco() {
        EnderecoService.getForComboByRepresentante(this.state.model.idRepresentante, (response) => {
            if(response.length > 0){
                EventBus.dispatch("modal-add", {
                    title: 'Seleção de Endereço',
                    sizeModal: 'modal-xl',
                    scrollable: false,
                    config: {
                        isModal: true,
                        enderecos:  response
                    },
                    form: FormEnderecoSelecao,
                        onSave: (endereco) => {
                            console.log(endereco);
                            this.setState(state => {
                                state.model.ordemEndereco.idEndereco = endereco.idEndereco;
                                return state;
                            }, this.handleChangeEndereco);
                            
                        },
                    });
                } else {
                    toast.warn("Nenhum endereço encontrado!");
                }

        });
    }

    handleChangeEndereco(e) {
        if (this.state.model.ordemEndereco.idEndereco) {
            EnderecoService.getOneById(this.state.model.ordemEndereco.idEndereco, (response) => {
                this.setState((state) => {
                    state.model.ordemEndereco.idPais = response.idPais;
                    state.model.ordemEndereco.idEstado = response.idEstado;
                    state.model.ordemEndereco.idCidade = response.idCidade;
                    state.model.ordemEndereco.bairro = response.bairro;
                    state.model.ordemEndereco.cep = response.cep;
                    state.model.ordemEndereco.numero = response.numero;
                    state.model.ordemEndereco.complemento = response.complemento;
                    state.model.ordemEndereco.endereco = response.endereco;
                    return state;
                });
            });
        }
    }

    handleOpenModalOrdem(ordem) {
        EventBus.dispatch("modal-add", {
            title: 'Dados da Ordem',
            sizeModal: 'modal-xl',
            fullScreen : true,
            config: {
                isModal: true,
                idOrdem: ordem.idOrdem
            },
            form: FormOrdem,
            onClose: () => {
            }
        });
    }



    onSubmit(e) {
        if (e?.nativeEvent?.submitter?.id === "btnSalvarContinuar") {
            super.onSubmit(e, (response) => {
                if (this.state.model.idOrdem) {
                    this.getById(this.state.model.idOrdem)
                } else {
                    this.props.navigate('/CadOrdem/' + response.data.idOrdem);
                    this.getById(response.data.idOrdem)
                }
                return;
            });
            return;
        }

        if (this.state.model.idOrdem && parseInt(this.state.modelOld.idOrdemStatus) === parseInt(this.state.model.idOrdemStatus) && this.podeEditarOrdem() && this.podeAlterarStatusOrdem() && (parseInt(this.state.model.idOrdemStatus) === 1 || parseInt(this.state.model.idOrdemStatus) === 4)) {
            EventBus.dispatch("modal-confirmacao-status-open", {
                config: {
                    title: "Desejar alterar o status da Ordem para Concluído?",
                    message: <>Desejar alterar o status da Ordem para <strong>CONCLUÍDO</strong>?</>,
                    btnConfirmText: "Sim, alterar o status",
                    btnCancelText: "Não, manter o status atual",
                    style: "success",
                    confirm: () => {
                        this.setModel({ idOrdemStatus: 2 }, super.onSubmit);
                    },
                    close: () => {
                        super.onSubmit(e);
                    }
                }
            })
        } else {
            super.onSubmit(e);
        }
    }

    handleOpenModalFoto(item) {
        EventBus.dispatch("modal-add", {
            title: 'Fotos do Produto',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                idProdutoServico: item.idProdutoServico
            },
            form: FormFoto
        });
    }

    getClassStatus(idOrdemStatus) {
        switch (idOrdemStatus) {
            case 1:
                return "text-bg-warning";
            case 2:
                return "text-bg-success";
            case 3:
                return "text-bg-danger";
            case 4:
                return "text-bg-primary";
            case 5:
                return "text-bg-dark";
            default:
                return "text-bg-light";
        }
    }

    handleAddOrdemNova() {
        this.props.navigate("/CadOrdem/copy/" + this.state.model.idOrdem);
    }
    
    handleAddOrdemSuporte() {
        EventBus.dispatch("modal-add", {
            title: 'Nova Ordem de Suporte',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                ordem: this.state.model,
            },
            onSave: (response) => {
                this.props.navigate('/LstOrdem');
            },
            form: FormOrdemSuporte
        });
    }

    handleOrdemAlterarTipo() {
        
        EventBus.dispatch("modal-add", {
            title: 'Alterar Tipo da Ordem',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                ordem: this.state.model,
            },
            onSave: (response) => {

                this.setState(state =>{
                    state.model.idTipoProdutoServico = response.idTipoProdutoServico;
                    state.model.nomeTipoProdutoServico = response.nomeTipoProdutoServico;
                    state.model.idCategoriaProdutoServico = response.idCategoriaProdutoServico;
                    state.model.nomeCategoriaProdutoServico = response.nomeCategoriaProdutoServico;
                    state.model.flAlterarTipoProdutoServico = true;
                    state.model.ordemItem.forEach(item =>{
                        state.model.ordemItemExcluir.push(item);
                    });

                    state.model.ordemItem = [];

                    return state;
                }, () =>{
                    if(response.ordemItem.length > 0){
                        response.ordemItem.forEach(item =>{
                            this.handleChangeItemProdutoServico(item, -1);                            
                        });
                    }else{
                        this.handleAddItem();
                    }
                });

                //this.getById(this.state.model.idOrdem);
            },
            form: FormOrdemAlterar
        });
    }

    handleImprimirIndividual() {
        EventBus.dispatch("modal-add", {
            title: 'Impressão da Ordem',
            sizeModal: 'modal-lg',
            scrollable: false,
            config: {
                isModal: true,
                idOrdens: [this.state.model.idOrdem]
            },
            form: FormRelatorioOrdem001
        });
    }

    handleImprimirTermo() {
        EventBus.dispatch("modal-add", {
            title: 'Impressão do Termo',
            sizeModal: 'modal-lg',
            scrollable: false,
            config: {
                isModal: true,
                idOrdens: [this.state.model.idOrdem]
            },
            form: FormRelatorioOrdem003
        });
    }

    handleClickCotacao(item, index) {
        EventBus.dispatch("modal-add", {
            title: 'Cotação de Frete',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idRepresentante: parseInt(this.state.model.idOrdemTipo) === 1 ? this.state.model.idRepresentante : false,
                cdTipoPessoaDestinatario: this.state.model.ordemCliente.cdTipoPessoa,
                cdCpfDestinatario: this.state.model.ordemCliente.cpf,
                cdCnpjDestinatario: this.state.model.ordemCliente.cnpj,
                idProdutoServicoTipoObjeto: item.idProdutoServicoTipoObjeto,
                vlPesoBruto: item.vlPesoBrutoConteudo > 0 ? item.vlPesoBrutoConteudo : this.getTotalPesoBruto(),
                vlPesoLiquido: item.vlPesoLiquidoConteudo > 0 ? item.vlPesoLiquidoConteudo : this.getTotalPesoLiquido(),
                nrQuantidade: item.nrQuantidade,
                vlLargura: item.vlLargura,
                vlAltura: item.vlAltura,
                vlComprimento: item.vlComprimento,
                vlDiametro: item.vlDiametro,
                vlDeclarado: this.getTotalValorPagar(),
                cdCepDestino: this.state.model.ordemEndereco.cep
            },
            form: FormCotacaoFrete,
            onClose: () => {

            },
            onSave: (retorno) => {
                this.setState((state) => {
                    state.model.ordemItem[index].vlTransporte = parseFloat(retorno.vlTransporte);
                    state.model.ordemItem[index].idFornecedorServico = parseInt(retorno.idFornecedorServico);
                    state.model.ordemItem[index].nomeFornecedor = retorno.nomeFornecedor;
                    state.model.ordemItem[index].nomeTipoFrete = retorno.nomeTipoFrete;
                    state.model.ordemItem[index].nomeFornecedorServico = retorno.nomeFornecedorServico;
                    state.model.ordemItem[index].nomeModalidadeFrete = retorno.nomeModalidadeFrete;
                    state.model.ordemItem[index].flAvisoRecebimento = retorno.flAvisoRecebimento;
                    state.model.ordemItem[index].flMaoPropria = retorno.flMaoPropria;
                    state.model.ordemItem[index].vlDeclarado = retorno.vlDeclarado;
                    return state;
                });
            }
        });
    }

    handleClickExcluirFornecedorServico(index) {
        this.setState((state) => {
            state.model.ordemItem[index].vlTransporte = 0;
            state.model.ordemItem[index].idFornecedorServico = "";
            state.model.ordemItem[index].nomeFornecedor = "";
            state.model.ordemItem[index].nomeTipoFrete = "";
            state.model.ordemItem[index].nomeFornecedorServico = "";
            state.model.ordemItem[index].nomeModalidadeFrete = "";
            state.model.ordemItem[index].flAvisoRecebimento = false;
            state.model.ordemItem[index].flMaoPropria = false;
            state.model.ordemItem[index].vlDeclarado = 0;
            return state;
        });
    }

    handleClickPreencherPeso(item, index) {
        this.setState((state) => {
            state.model.ordemItem[index].vlPesoBrutoConteudo = this.getTotalPesoBruto();
            state.model.ordemItem[index].vlPesoLiquidoConteudo = this.getTotalPesoLiquido();
            return state;
        });
    }

    handleChangeCep() {
        if (Util.stringToNumber(this.state.model.ordemEndereco.cep).length === 8) {
            EnderecoService.getOneByCep(this.state.model.ordemEndereco.cep, (response) => {
                this.setState((state) => {
                    state.model.ordemEndereco.endereco = response?.logradouro ? response.logradouro : "";
                    state.model.ordemEndereco.bairro = response?.bairro ? response.bairro : "";
                    state.model.ordemEndereco.complemento = response?.complemento ? response.complemento : "";
                    state.model.ordemEndereco.numero = "";

                    if (response?.idEstado) {
                        state.model.ordemEndereco.idEstado = response.idEstado;
                    }

                    if (response?.idCidade) {
                        state.model.ordemEndereco.idCidade = response.idCidade;
                    }

                    return state;
                });
            });

        }
    }

    handleChangeOrdemTag(isChecked, item) {
        
        if (isChecked) {
            this.setState(state => {
                state.model.ordemTag.push({idTag : item.value});
                return state;
            });
        } else {
            this.setState(state => {
                var index = this.state.model.ordemTag.findIndex(i => parseInt(i.idTag) === parseInt(item.value));
                state.model.ordemTag.splice(index, 1);
                return state;
            });
        }
    }

    toScroll(ref) {
        setTimeout(() => {
            ref.current.scrollIntoView();
        }, 300);
    }

    getBtnScroll(item){       
        return ( 
            <div className="d-flex">
                <div className="pe-2">
                    <BtnButton disabled={item === 0} onClick={e => { this.toScroll(this.references.refOrdem) }} className={`${item === 0 ? 'btn-primary' : 'btn-outline-primary'} btn-lateral rounded-pill btn-sm`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg> <span className="texto">Dados da Ordem</span>
                    </BtnButton>
                </div>
                <div className="pe-2">
                    <BtnButton disabled={item === 1} onClick={e => { this.toScroll(this.references.refItens) }} className={`${item === 1 ? 'btn-primary' : 'btn-outline-primary'} btn-lateral rounded-pill btn-sm`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ol" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
                            <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z" />
                        </svg> <span className="texto">Itens da Ordem</span>
                    </BtnButton>
                </div>
                <div className="pe-2">
                    <BtnButton disabled={item === 2} onClick={e => { this.toScroll(this.references.refCliente) }} className={`${item === 2 ? 'btn-primary' : 'btn-outline-primary'} btn-lateral rounded-pill btn-sm`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                        </svg> <span className="texto">Dados do Cliente</span>
                    </BtnButton>
                </div>
                {Boolean(this.state.model?.flDadosVeiculo) ?
                    <div className="pe-2">
                        <BtnButton disabled={item === 3} onClick={e => { this.toScroll(this.references.refVeiculo) }} className={`${item === 3 ? 'btn-primary' : 'btn-outline-primary'} btn-lateral rounded-pill btn-sm`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-car-front" viewBox="0 0 16 16">
                                <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z" />
                                <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z" />
                            </svg> <span className="texto">Dados do Veículo</span>
                        </BtnButton>
                    </div>
                    : <></>}

                {Boolean(this.state.model?.flDadosEndereco) ?
                    <div className="pe-2">
                        <BtnButton disabled={item === 4} onClick={e => { this.toScroll(this.references.refEndereco) }} className={`${item === 4 ? 'btn-primary' : 'btn-outline-primary'} btn-lateral rounded-pill btn-sm`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mailbox" viewBox="0 0 16 16">
                                <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                                <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
                            </svg> <span className="texto">Dados do Endereço</span>
                        </BtnButton>
                    </div>
                    : <></>}
                <div className="pe-2">
                    <BtnButton disabled={item === 5} onClick={e => { this.toScroll(this.references.refComentario) }} className={`${item === 5 ? 'btn-primary' : 'btn-outline-primary'} btn-lateral rounded-pill btn-sm`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-quote" viewBox="0 0 16 16">
                            <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                            <path d="M7.066 6.76A1.665 1.665 0 0 0 4 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z" />
                        </svg> <span className="texto">Comentários</span>
                    </BtnButton>
                </div>
                {this.podeInserirComentario() ?
                    <div className="pe-2">
                        <BtnButton disabled={item === 5} className={`${item === 5 ? 'btn-primary' : 'btn-outline-primary'} btn-lateral rounded-pill btn-sm`} onClick={this.handleAddComentario}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-dots" viewBox="0 0 16 16">
                                <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                            </svg> <span className="texto">Adicionar Comentário</span>
                        </BtnButton>
                    </div>
                    : <></>}

                <div className="pe-2">
                    <BtnButton onClick={e => { this.toScroll(this.references.refBotoes) }} className={`btn-outline-success btn-lateral rounded-pill btn-sm`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-menu-button" viewBox="0 0 16 16">
                            <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8A1.5 1.5 0 0 1 11 1.5v2A1.5 1.5 0 0 1 9.5 5h-8A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-8z"/>
                            <path d="m7.823 2.823-.396-.396A.25.25 0 0 1 7.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                        </svg> <span className="texto"> Ir para Comandos </span>
                    </BtnButton>
                </div>
            </div>
        );
                            
    }

    getRenderFields() {        
        return (
            <>
                {/*!Boolean(this.state.isModal) ?
                    <div className="position-fixed top-0 end-0" style={{ zIndex: 99999 }}>
                        <div className="pt-5 mt-5 me-3 text-end">
                            <div className="pb-2">
                                <BtnButton onClick={e => { this.toScroll(this.references.refOrdem) }} className="btn-primary btn-lateral rounded-pill btn-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                    </svg> <span className="texto">Dados da Ordem</span>
                                </BtnButton>
                            </div>
                            <div className="pb-2">
                                <BtnButton onClick={e => { this.toScroll(this.references.refItens) }} className="btn-primary btn-lateral rounded-pill btn-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ol" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
                                        <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z" />
                                    </svg> <span className="texto">Itens da Ordem</span>
                                </BtnButton>
                            </div>
                            <div className="pb-2">
                                <BtnButton onClick={e => { this.toScroll(this.references.refCliente) }} className="btn-primary btn-lateral rounded-pill btn-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                    </svg> <span className="texto">Dados do Cliente</span>
                                </BtnButton>
                            </div>
                            {Boolean(this.state.model?.flDadosVeiculo) ?
                                <div className="pb-2">
                                    <BtnButton onClick={e => { this.toScroll(this.references.refVeiculo) }} className="btn-primary btn-lateral rounded-pill btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-car-front" viewBox="0 0 16 16">
                                            <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z" />
                                            <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z" />
                                        </svg> <span className="texto">Dados do Veículo</span>
                                    </BtnButton>
                                </div>
                                : <></>}

                            {Boolean(this.state.model?.flDadosEndereco) ?
                                <div className="pb-2">
                                    <BtnButton onClick={e => { this.toScroll(this.references.refEndereco) }} className="btn-primary btn-lateral rounded-pill btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mailbox" viewBox="0 0 16 16">
                                            <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                                            <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
                                        </svg> <span className="texto">Dados do Endereço</span>
                                    </BtnButton>
                                </div>
                                : <></>}
                            <div className="pb-2">
                                <BtnButton onClick={e => { this.toScroll(this.references.refComentario) }} className="btn-primary btn-lateral rounded-pill btn-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-quote" viewBox="0 0 16 16">
                                        <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                                        <path d="M7.066 6.76A1.665 1.665 0 0 0 4 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z" />
                                    </svg> <span className="texto">Comentários</span>
                                </BtnButton>
                            </div>
                            {this.podeInserirComentario() ?
                                <div className="pb-2">
                                    <BtnButton className="btn-primary btn-lateral rounded-pill btn-sm" onClick={this.handleAddComentario}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-dots" viewBox="0 0 16 16">
                                            <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                            <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                        </svg> <span className="texto">Adicionar Comentário</span>
                                    </BtnButton>
                                </div>
                                : <></>}

                            <div className="pb-2">
                                <BtnButton onClick={e => { this.toScroll(this.references.refBotoes) }} className="btn-success btn-lateral rounded-pill btn-sm">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-menu-button" viewBox="0 0 16 16">
                                        <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h8A1.5 1.5 0 0 1 11 1.5v2A1.5 1.5 0 0 1 9.5 5h-8A1.5 1.5 0 0 1 0 3.5v-2zM1.5 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-8z"/>
                                        <path d="m7.823 2.823-.396-.396A.25.25 0 0 1 7.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                                    </svg> <span className="texto"> Ir para Comandos </span>
                                </BtnButton>
                            </div>
                        </div>
                    </div>
                            : <></>*/}



                {this.state.model.idOrdemPai ?
                    <div className="alert alert-danger" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>&nbsp;
                        <strong>ATENÇÃO:</strong> Esta solicitação está vinculada a ordem <strong>#{this.state.model.idOrdemPai}</strong>, os dados da solicitação anterior foram copiados para essa ordem  &nbsp;
                        <button type="button" className="btn btn-danger btn-sm" onClick={e => this.handleOpenModalOrdem({ idOrdem: this.state.model.idOrdemPai })}>
                            visualizar os dados da ordem anterior
                        </button>
                    </div>
                    : <></>
                }

                <div className="card mt-3 border-secondary" ref={this.references.refOrdem} >
                    <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">
                        <div className="row">
                            <div className="col">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart3" viewBox="0 0 16 16">
                                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </svg> Dados da Ordem
                            </div>
                            <div className="col-auto d-flex justify-content-end d-none d-lg-block">
                                {this.getBtnScroll(0)}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row mb-3 g-3">
                            {this.state.model.idOrdem ?
                                <>
                                    <InputText disabled={true} md={3} label="Código da Ordem" value={this.state.model.idOrdem} opcionalLabel={false} />
                                    <InputText disabled={true} md={3} label="Data da Ordem" value={Util.date2Br(this.state.model.dataOrdem) + " " + this.state.model.horaOrdem} opcionalLabel={false} />
                                    <InputText disabled={true} md={3} label="Data da Alteração" value={Util.date2Br(this.state.model.dataUpdate) + " " + this.state.model.horaUpdate} opcionalLabel={false} />
                                </> :
                                <>
                                    <InputText disabled={true} md={3} label="Código da Ordem" value={this.state.model.idOrdem} opcionalLabel={false} />
                                    <InputText disabled={true} md={3} label="Data da Ordem" value={Util.date2Br(this.state.model.dataOrdem) + " " + this.state.model.horaOrdem} opcionalLabel={false} />
                                    <InputText disabled={true} md={3} label="Data da Alteração" value={Util.date2Br(this.state.model.dataUpdate) + " " + this.state.model.horaUpdate} opcionalLabel={false} />
                                </>
                            }

                            {this.state.model.idOrdem ?
                                <InputText disabled={true} showBtns={false} md={3} label="Tipo da Ordem" value={this.state.model.nomeOrdemTipo} opcionalLabel={false} /> :
                                <SelectOrdemTipoUsuario showBtns={false} md={3} label="Destino da Ordem" required placeholder="Selecione o Destino da Ordem" name="idOrdemTipo" value={this.state.model.idOrdemTipo} onChange={e => this.handleChange(e, this.handleChangeOrdemTipo)} />
                            }

                            {this.state.model.idOrdem ?
                                <InputText disabled={true} md={5} label="Representante" value={this.state.model.nomeRepresentante} opcionalLabel={false} /> :
                                <SelectRepresentanteByUsuario showBtnNewForm={false} ativo={1} md={5} label="Representante" required placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={e => this.handleChange(e, this.handleChangeRepresentante)} />
                            }

                            <DropDownMenuTag label="Tags" placeholder="Selecione a(s) Tags" className="btn-outline-primary text-nowrap" md={4} values={this.state.model.ordemTag.reduce((result, item) => [...result, item.idTag], [])} name="ordemTag" handleChangeItem={this.handleChangeOrdemTag} disabled={!this.podeEditarOrdem() || !this.podeAlterarTags()}/>

                            {this.state.model.idOrdem ?
                                <SelectOrdemStatus showBtns={false} md={3} disabledValues={[5]} disabled={!this.podeEditarOrdem() || !this.podeAlterarStatusOrdem()} label="Status da Ordem" required placeholder="Selecione o Status da Ordem" name="idOrdemStatus" value={this.state.model.idOrdemStatus} onChange={this.handleChange} />
                                : <InputText disabled={true} md={3} label="Status da Ordem" value="Nova Ordem" opcionalLabel={false} />
                            }                            

                            {this.state.model.idOrdem ?
                                <InputText disabled={true} md={6} label="Categoria do Produto" value={this.state.model.nomeCategoriaProdutoServico} opcionalLabel={false} /> :
                                <SelectCategoriaProdutoServicoByUsuario showBtnNewForm={false}  md={6} label="Categoria de Produto" required placeholder="Selecione a Categoria do Produto" name="idCategoriaProdutoServico" value={this.state.model.idCategoriaProdutoServico} onChange={(e) => { this.handleChange(e, this.handleChangeCategoriaProdutoServico) }} />
                            }
                            {this.state.model.idOrdem ?
                                <InputText disabled={true} md="auto" label="Tipo de Produto" value={this.state.model.nomeTipoProdutoServico} opcionalLabel={false} /> :
                                <SelectTipoProdutoServicoByCategoriaComProduto showBtnNewForm={false} md={6} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} disabled={!this.state.model.idCategoriaProdutoServico} label="Tipo de Produto" required placeholder="Selecione o Tipo do Produto" name="idTipoProdutoServico" value={this.state.model.idTipoProdutoServico} onChange={(e) => { this.handleChange(e) }} onChangeItem={this.handleChangeTipoProdutoServico} />
                            }
                            {this.podeEditarTipo() ?
                                <div className="col-md-auto d-flex align-items-end justify-content-end">
                                    <BtnButton className="btn-outline-primary" onClick={this.handleOrdemAlterarTipo}> 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                            <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                            <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                        </svg> Alterar Tipo                                   
                                    </BtnButton>
                                </div>
                                :<></>}
                        </div>
                        <div>
                            {this.state.ausencias.map((item, index) => (
                                <div className="alert alert-danger" role="alert" key={index}>
                                    <strong>Atenção: </strong>
                                    <div className="lh-sm" dangerouslySetInnerHTML={{ __html: Util.htmlFormatWhatsapp(item.dsMensagem) }} />
                                    {Boolean(item.flApresentaVigencia) ?
                                        <>
                                            <br /><strong>Período: </strong>: {Util.date2Br(item.dtInicio)} {item.hrInicio} à {Util.date2Br(item.dtFim)} {item.hrFim}
                                        </> : <></>}

                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="row">
                    {this.podeEditarOrdem() ? 
                    <div className="col-3 d-none d-xxl-block">
                        <div className="card mt-3 border-secondary" >
                            <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">                                                                
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ol" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
                                    <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z" />
                                </svg> Escolha o Produtos / Serviço                                
                            </div>
                            <div className="card-body">
                                <ListGroupProdutoServicoByTipoUsuarioOrdemTipo key={this.state.model.idOrdemTipo} disabled={!this.podeEditarOrdem()} onClickItem={this.handleClickItemProdutoServico} idOrdemTipo={this.state.model.idOrdemTipo} idTipoProdutoServico={this.state.model.idTipoProdutoServico}/>
                            </div>
                        </div>
                    </div>
                    : <></>}
                    <div className="col">
                        <div className="card mt-3 border-secondary" ref={this.references.refItens} >
                            <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">
                                <div className="row">
                                    <div className="col">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ol" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
                                            <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z" />
                                        </svg> Itens da Ordem
                                    </div>
                                    <div className="col-auto d-flex justify-content-end d-none d-lg-block">
                                        {this.getBtnScroll(1)}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="text-end mb-2">
                                    {this.podeEditarOrdem() ?
                                        <button type="button" className="btn btn-outline-primary text-nowrap" onClick={this.handleAddItem}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                            </svg> Adicionar Item
                                        </button>
                                        : <></>
                                    }

                                    <button type="button" className="btn btn-link ms-2" onClick={this.handleClickExpand}>
                                        {this.state.expand ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                            </svg> :

                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16">
                                                <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                                            </svg>
                                        }
                                    </button>
                                </div>

                                {Boolean(this.state.expand) ?
                                    <ol className="list-group list-group-numbered overflow-auto">
                                        {this.state.model.ordemItem.map((item, index) => (
                                            <li className={`list-group-item d-flex justify-content-between align-items-start ${index % 2 === 0 ? "bg-light " : ""}`} key={index}>
                                                <div className="ms-2 w-100">
                                                    <div className="row">
                                                        {!isNaN(parseInt(item.idProdutoServico)) ?
                                                            <>
                                                                <div className="fw-bold col-lg-12">
                                                                    <>{item.nomeProdutoServico}  {item.flEmbalagem ? <span className="badge text-bg-primary">EMBALAGEM</span> : ""} </>                                                                    
                                                                </div>
                                                                {item.dsEspecificacaoProdutoServico && item.dsEspecificacaoProdutoServico.toString().trim().length > 0 ? 
                                                                    <div className="fs-7 fst-italic">{item.dsEspecificacaoProdutoServico}</div>
                                                                : <></>}
                                                            </> :
                                                            <SelectProdutoServicoByTipoUsuarioOrdemTipo lg={12} idOrdemTipo={this.state.model.idOrdemTipo} openSearchOnUpdate={true} showBtnNewForm={false} idTipoProdutoServico={this.state.model.idTipoProdutoServico}
                                                                required placeholder="Selecione o Produto ou Serviço" name="idProdutoServico" value={item.idProdutoServico} onChange={(e) => { this.handleChange(e, (e) => { this.handleChangeItemProdutoServico(item, index) }, this.state.model.ordemItem[index]) }} />
                                                        }

                                                        <InputQuantidade label="Quantidade" lg={2} sizing="sm" disabled={Boolean(item.flEmbalagem) || !this.podeEditarOrdem() || !item.idProdutoServico} className="text-center" placeholder="Digite a quantidade" required value={item.nrQuantidade} name="nrQuantidade" onChange={(e) => { this.handleChange(e, (e) => { this.handleChangeItemQuantidade() }, this.state.model.ordemItem[index]) }} decimalScale={item.nrCasasDecimais} unidadeMedida={item.siglaUnidadeMedida} />

                                                        {item.flControlaEstoque ?
                                                            <InputQuantidade label="Estoque" lg={2} sizing="sm" disabled={true} value={this.getValueEstoque(item)} className={`fw-bold text-center ${item.vlEstoque <= 0 ? 'text-danger' : 'text-success'}`} decimalScale={item.nrCasasDecimais} unidadeMedida={item.siglaUnidadeMedida} opcionalLabel={false} />
                                                            : <InputText label="Estoque" lg={2} className="text-center text-secondary" sizing="sm" value="SEM CONTROLE" opcionalLabel={false} disabled={true} />
                                                        }

                                                        {this.podeVerValorProduto() ?
                                                            <MostraValor label="Valor" format="InputText" lg={2} className="text-center" sizing="sm" disabled opcionalLabel={false}>
                                                                <InputValorReais label="Valor" lg={2} className="text-end" sizing="sm" disabled={!this.podeEditarOrdem() || !item.idProdutoServico || !this.podeAlterarValorItem()} required value={item.vlPreco} name="vlPreco" onChange={(e) => { this.handleChange(e, false, this.state.model.ordemItem[index]) }} />
                                                            </MostraValor>
                                                            : <InputText label="Valor" lg={2} className="text-center text-secondary" sizing="sm" opcionalLabel={false} disabled={true} />
                                                        }
                                                        
                                                        {Boolean(item.flPrecoRevenda) ? 
                                                        <>
                                                            <InputValorReais help="Valor final praticado para cliente" label="Valor de Revenda" lg={2} className="text-end" sizing="sm" disabled={!this.podeEditarValorRevenda() || !item.idProdutoServico} required value={item.vlPrecoRevenda} name="vlPrecoRevenda" onChange={(e) => { this.handleChange(e, false, this.state.model.ordemItem[index]) }} />
                                                        </> : 
                                                             <InputText label="Valor de Revenda" value="N/D" lg={2} className="text-center text-secondary" sizing="sm" opcionalLabel={false} disabled={true} />
                                                        }

                                                        {this.podeVerValorProduto() ?
                                                            <MostraValor label="Desconto" format="InputText" lg={2} className="text-center" sizing="sm" disabled opcionalLabel={false}>
                                                                <InputValorReais label="Desconto" lg={2} className="text-end" sizing="sm" disabled={!this.podeEditarOrdem() || !item.idProdutoServico || !this.podeAlterarDescontoItem()} required value={item.vlDesconto} name="vlDesconto" onChange={(e) => { this.handleChange(e, false, this.state.model.ordemItem[index]) }} max={item.vlPreco * item.nrQuantidade} />
                                                            </MostraValor>
                                                            : <InputText label="Desconto" lg={2} className="text-center text-secondary" sizing="sm" opcionalLabel={false} disabled={true} />
                                                        }
                                                        {this.podeVerValorProduto() ?
                                                            <MostraValor label="Total" format="InputText" lg={2} className="text-center" sizing="sm" disabled opcionalLabel={false}>
                                                                <InputValorReais label="Total" lg={2} className="text-end" sizing="sm" disabled={true} value={Number(parseFloat((item.vlPreco * item.nrQuantidade) - item.vlDesconto).toFixed(2))} opcionalLabel={false} />
                                                            </MostraValor>
                                                            : <InputText label="Total" lg={2} className="text-center text-secondary" sizing="sm" opcionalLabel={false} disabled={true} />
                                                        }

                                                        {item.dsObservacaoCompra?.length > 0 ?
                                                            <div className="col-lg-12">
                                                                <div className="alert alert-danger pt-1 pb-1 mt-1" role="alert">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                                                                        <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                                                    </svg> {item.dsObservacaoCompra}
                                                                </div>
                                                            </div>
                                                            : <></>
                                                        }

                                                        {item.flEmbalagem && this.podeAlterarPesoItem() ?
                                                            <>
                                                                <div className="row">
                                                                    <InputPeso className="text-end" lg={2} sizing="sm" label='Peso Bruto' disabled={!this.podeEditarOrdem() || !item.idProdutoServico} value={item.vlPesoBrutoConteudo} name="vlPesoBrutoConteudo" onChange={(e) => { this.handleChange(e, (e) => { this.handleChangeItemQuantidade() }, this.state.model.ordemItem[index]) }} required={true} />
                                                                    <InputPeso className="text-end" lg={2} sizing="sm" label='Peso Líquido' disabled={!this.podeEditarOrdem() || !item.idProdutoServico} value={item.vlPesoLiquidoConteudo} name="vlPesoLiquidoConteudo" onChange={(e) => { this.handleChange(e, (e) => { this.handleChangeItemQuantidade() }, this.state.model.ordemItem[index]) }} required={true} />

                                                                    <div className="col d-flex align-items-end bd-highlight">
                                                                        <BtnButton className="btn-outline-success text-nowrap btn-sm" onClick={(e) => { this.handleClickPreencherPeso(item, index) }} disabled={!this.podeEditarOrdem()}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-reply-all-fill" viewBox="0 0 16 16">
                                                                                <path d="M8.021 11.9 3.453 8.62a.719.719 0 0 1 0-1.238L8.021 4.1a.716.716 0 0 1 1.079.619V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                                                                                <path d="M5.232 4.293a.5.5 0 0 1-.106.7L1.114 7.945a.5.5 0 0 1-.042.028.147.147 0 0 0 0 .252.503.503 0 0 1 .042.028l4.012 2.954a.5.5 0 1 1-.593.805L.539 9.073a1.147 1.147 0 0 1 0-1.946l3.994-2.94a.5.5 0 0 1 .699.106z" />
                                                                            </svg> Preencher Peso
                                                                        </BtnButton>
                                                                    </div>
                                                                </div>

                                                                <div className="row pt-2">
                                                                    <InputText disabled={true} sizing="sm" label="Fornecedor" lg={3} value={item.nomeFornecedor ? item.nomeFornecedor : " "} opcionalLabel={false} />
                                                                    <InputText disabled={true} sizing="sm" label="Frete" lg={2} value={item.nomeTipoFrete ? item.nomeTipoFrete : " "} opcionalLabel={false} />
                                                                    <InputText disabled={true} sizing="sm" label="Serviço" lg={2} value={item.nomeFornecedorServico ? item.nomeFornecedorServico : " "} opcionalLabel={false} />
                                                                    <InputText disabled={true} sizing="sm" label="Transporte" lg={2} value={item.nomeModalidadeFrete ? item.nomeModalidadeFrete : " "} opcionalLabel={false} />
                                                                    <InputValorReais label="Valor de Transporte" lg={2} className="text-end" sizing="sm" disabled={!this.podeEditarOrdem() || !item.idProdutoServico || !this.podeAlterarValorItem()} required value={item.vlTransporte} name="vlTransporte" onChange={(e) => { this.handleChange(e, false, this.state.model.ordemItem[index]) }} />

                                                                    <div className="col-lg-1 d-flex align-items-end bd-highlight">
                                                                        {Permissao.getInstance().getPermissao(82) ?
                                                                            <React.Fragment>
                                                                                {parseInt(item.idFornecedorServico) > 0 ?
                                                                                    <BtnButton className="btn-outline-danger text-nowrap btn-sm" onClick={(e) => { this.handleClickExcluirFornecedorServico(index) }} disabled={!this.podeEditarOrdem()}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                                                                                            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                                                        </svg> Limpar
                                                                                    </BtnButton>
                                                                                    :
                                                                                    <BtnButton className="btn-outline-success text-nowrap btn-sm" onClick={(e) => { this.handleClickCotacao(item, index) }} disabled={!this.podeEditarOrdem()}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                                                                                            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                                                        </svg> Cotar
                                                                                    </BtnButton>
                                                                                }
                                                                            </React.Fragment>

                                                                            : <></>}
                                                                    </div>
                                                                </div>

                                                            </>
                                                            : <></>
                                                        }

                                                    </div>
                                                </div>

                                                <div>
                                                    {this.podeEditarOrdem() ?
                                                        <div className="text-center">
                                                            <button type="button" className="btn btn-link text-danger" onClick={() => { this.handleDeleteItem(index, item) }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                        : <></>
                                                    }
                                                </div>

                                                {Boolean(this.state.expand) ?
                                                    <div className="d-none d-lg-table-cell">
                                                        <img className="img-thumbnail" style={{ width: "120px", height: "100px" }} src={item.uidProdutoServicoArquivo ? Config.getUrlArquivo() + "public/arquivo/inline/" + item.uidProdutoServicoArquivo : "/loja-sem-foto.webp"} alt="..." />
                                                    </div>
                                                    : <></>}
                                            </li>
                                        ))}
                                    </ol>
                                    :

                                    <div className="table-responsive">
                                        <table className="table table-hover table-striped">
                                            <thead>
                                                <tr>
                                                    <th> </th>
                                                    <th className="col-4"> Produto / Serviço </th>
                                                    <th className="col-md-2 text-center"> Quantidade </th>
                                                    <th className="col-md-2 text-end"> Valor Unitário</th>
                                                    <th className="col-md-2 text-end"> Desconto </th>
                                                    <th className="col-md-2 text-end"> Total </th>
                                                    <th> &nbsp; </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.model.ordemItem.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {index + 1}.
                                                        </td>
                                                        <td>
                                                            {!isNaN(parseInt(item.idProdutoServico)) ?
                                                                <>{item.nomeProdutoServico}  {item.flEmbalagem ? <span className="badge text-bg-primary">EMBALAGEM</span> : ""} </>
                                                                :
                                                                <SelectProdutoServicoByTipoUsuarioOrdemTipo idOrdemTipo={this.state.model.idOrdemTipo} openSearchOnUpdate={true} showBtnNewForm={false} idTipoProdutoServico={this.state.model.idTipoProdutoServico}
                                                                    required placeholder="Selecione o Produto ou Serviço" name="idProdutoServico" value={item.idProdutoServico} onChange={(e) => { this.handleChange(e, (e) => { this.handleChangeItemProdutoServico(item, index) }, this.state.model.ordemItem[index]) }} />
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {item.nrQuantidade}
                                                        </td>
                                                        <td className="text-end fw-bold text-primary">
                                                            {this.podeVerValorProduto() ? <MostraValor>{Util.floatToReais(item.vlPreco)}</MostraValor> : "OCULTO"}
                                                        </td>
                                                        <td className="text-end fw-bold text-danger">
                                                            {this.podeVerValorProduto() ? <MostraValor>{Util.floatToReais(item.vlDesconto)}</MostraValor> : "OCULTO"}
                                                        </td>
                                                        <td className="text-end fw-bold text-success">
                                                            {this.podeVerValorProduto() ? <MostraValor>{Util.floatToReais(Number(parseFloat((item.vlPreco * item.nrQuantidade) - item.vlDesconto).toFixed(2)))}</MostraValor> : "OCULTO"}
                                                        </td>
                                                        <td>
                                                            {this.podeEditarOrdem() ?
                                                                <button type="button" className="btn btn-link text-danger p-0 m-0" onClick={() => { this.handleDeleteItem(index, item) }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                    </svg>
                                                                </button>
                                                                : <></>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                }

                                <div className="text-end mb-2 mt-2">
                                    {this.podeEditarOrdem() && this.state.model.ordemItem.length > 5 ?
                                        <button type="button" className="btn btn-outline-primary text-nowrap" onClick={this.handleAddItem}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                            </svg> Adicionar Item
                                        </button>
                                        : <></>
                                    }
                                </div>

                                {this.podeVerValorProduto() ?
                                    <>
                                        <hr />
                                        <div className="row justify-content-end fw-bold">
                                            <MostraValor label="Desconto" format="InputText" lg={2} className="text-center" sizing="sm" disabled opcionalLabel={false}>
                                                <InputPercentual label="Desconto" disabled={!this.podeEditarOrdem() || !this.podeAlterarDescontoOrdem()} className="text-center" md={2} required={true} name="pcDesconto" value={this.state.model.pcDesconto} max={100} min={0} step={0.01} onChange={this.handleChange} />
                                            </MostraValor>

                                            <MostraValor label="Total" format="InputText" lg={2} className="text-center" sizing="sm" disabled opcionalLabel={false}>
                                                <InputValorReais label="Total" disabled={true} className="text-primary fw-bold text-end" md={2} value={parseFloat(this.getTotalOrdem())} opcionalLabel={false} />
                                            </MostraValor>

                                            <MostraValor label="Desconto" format="InputText" lg={2} className="text-center" sizing="sm" disabled opcionalLabel={false}>
                                                <InputValorReais label="Desconto" disabled={true} className="text-danger fw-bold text-end" md={2} value={parseFloat(this.getTotalDesconto())} opcionalLabel={false} />
                                            </MostraValor>

                                            <MostraValor label="à Pagar" format="InputText" lg={2} className="text-center" sizing="sm" disabled opcionalLabel={false}>
                                                <InputValorReais label="à Pagar" disabled={true} className="text-success fw-bold text-end" md={2} value={parseFloat(this.getTotalValorPagar())} opcionalLabel={false} />
                                            </MostraValor>
                                        </div>
                                    </> : <></>}

                            </div>
                        </div>                
                    </div>
                </div>

                <div className="card mt-3 border-secondary" ref={this.references.refCliente} >
                    <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">
                        <div className="row">
                            <div className="col">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                </svg> Dados do Cliente Final
                            </div>
                            <div className="col-auto d-flex justify-content-end d-none d-lg-block">
                                {this.getBtnScroll(2)}
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {(this.state.model.tipoProdutoServico?.cdTipo === "P" || parseInt(this.state.model.idOrdemTipo) === 2) ?
                            <div className="row mx-1 mb-3">
                                <InputCheckSwitch disabled={!this.podeEditarCliente()} label="Tem Cliente Final?" name="flDadosCliente" checked={Boolean(this.state.model.flDadosCliente)} onChange={e => this.handleChange(e, false, this.state.model)} />
                            </div>
                            : <></>}
                        {Boolean(this.state.model?.flDadosCliente) ?
                        <>
                            <div className="alert alert-warning pt-1 pb-1" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                </svg> Preencha aqui os dados do <strong>CLIENTE FINAL</strong>
                            </div>
                            <div className="row mb-3 g-3">
                                <SelectDDI disabled={!this.podeEditarCliente()} md={2} label="DDI" required placeholder="Selecione" name="ddi" value={this.state.model.ordemCliente.ddi} onChange={e => this.handleChange(e, false, this.state.model.ordemCliente)} />
                                {this.state.model.ordemCliente.ddi.toString() === "55" ?
                                    <InputTelCelular disabled={!this.podeEditarCliente()} label="Celular" required md={3} value={this.state.model.ordemCliente.celular} name="celular" onChange={e => this.handleChange(e, e => this.handleChangeCelular(e, "ordemCliente"), this.state.model.ordemCliente)} />
                                    :
                                    <InputText disabled={!this.podeEditarCliente()} label="Celular" required md={3} value={this.state.model.ordemCliente.celular} name="celular" onChange={e => this.handleChange(e, false, this.state.model.ordemCliente)} />
                                }
                                <BtnGroup disabled={!this.podeEditarCliente()} required={true} md={3} className="w-100" name="cdTipoPessoa" value={this.state.model.ordemCliente.cdTipoPessoa} label="Tipo do Cliente" options={this.state.tipoPessoa} onChange={e => this.handleChange(e, false, this.state.model.ordemCliente)} />
                                {this.state.model.ordemCliente.cdTipoPessoa === "F" ? <InputCpf disabled={!this.podeEditarCliente()} label="CPF" md={4} name="cpf" value={this.state.model.ordemCliente.cpf ? this.state.model.ordemCliente.cpf : ""} onChange={e => this.handleChange(e, this.handleChangeCpf, this.state.model.ordemCliente)} /> : <></>}
                                {this.state.model.ordemCliente.cdTipoPessoa === "J" ? <InputCnpj disabled={!this.podeEditarCliente()} label="CNPJ" md={4} name="cnpj" value={this.state.model.ordemCliente.cnpj ? this.state.model.ordemCliente.cnpj : ""} onChange={e => this.handleChange(e, this.handleChangeCnpj, this.state.model.ordemCliente)} /> : <></>}

                                <InputText disabled={!this.podeEditarCliente()} label="Nome do Cliente" required md={6} value={this.state.model.ordemCliente.nome} name="nome" onChange={e => this.handleChange(e, false, this.state.model.ordemCliente)} />
                                <InputEmail disabled={!this.podeEditarCliente()} label="E-mail" md={6} name="email" value={this.state.model.ordemCliente.email} onChange={e => this.handleChange(e, false, this.state.model.ordemCliente)} />
                            </div>
                            </>
                        : <></>}
                    </div>
                </div>

                {(this.state.model.tipoProdutoServico?.cdTipo === "P" || parseInt(this.state.model.idOrdemTipo) === 2) ?
                    <div className="card mt-3 border-secondary" ref={this.references.refClienteFatura} >
                        <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">
                            <div className="row">
                                <div className="col">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                    </svg> Dados para Fatura
                                </div>
                                <div className="col-auto d-flex justify-content-end d-none d-lg-block">
                                    {this.getBtnScroll(2)}
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="alert alert-warning pt-1 pb-1" role="alert">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                                    <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
                                    <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
                                </svg> Preencha aqui os dados que serão utilizado para emissão da <strong>FATURA e NOTA FISCAL</strong>
                            </div>
                            <div className="row mb-3 g-3">
                                <SelectDDI disabled={!this.podeEditarCliente()} md={2} label="DDI" required placeholder="Selecione" name="ddi" value={this.state.model.ordemClienteFatura.ddi} onChange={e => this.handleChange(e, false, this.state.model.ordemClienteFatura)} />
                                {this.state.model.ordemClienteFatura.ddi.toString() === "55" ?
                                    <InputTelCelular disabled={!this.podeEditarCliente()} label="Celular" required md={3} value={this.state.model.ordemClienteFatura.celular} name="celular" onChange={e => this.handleChange(e, e => this.handleChangeCelular(e, "ordemClienteFatura"), this.state.model.ordemClienteFatura)} />
                                    :
                                    <InputText disabled={!this.podeEditarCliente()} label="Celular" required md={3} value={this.state.model.ordemClienteFatura.celular} name="celular" onChange={e => this.handleChange(e, false, this.state.model.ordemClienteFatura)} />
                                }
                                <BtnGroup disabled={!this.podeEditarCliente()} required={true} md={3} className="w-100" name="cdTipoPessoaFinal" value={this.state.model.ordemClienteFatura.cdTipoPessoa} label="Tipo do Cliente" options={this.state.tipoPessoa} onChange={e => this.handleChange(e, false, this.state.model.ordemClienteFatura, "cdTipoPessoa")} />
                                {this.state.model.ordemClienteFatura.cdTipoPessoa === "F" ? <InputCpf disabled={!this.podeEditarCliente()} label="CPF" md={4} name="cpf" value={this.state.model.ordemClienteFatura.cpf ? this.state.model.ordemClienteFatura.cpf : ""} onChange={e => this.handleChange(e, this.handleChangeCpf, this.state.model.ordemClienteFatura)} /> : <></>}
                                {this.state.model.ordemClienteFatura.cdTipoPessoa === "J" ? <InputCnpj disabled={!this.podeEditarCliente()} label="CNPJ" md={4} name="cnpj" value={this.state.model.ordemClienteFatura.cnpj ? this.state.model.ordemClienteFatura.cnpj : ""} onChange={e => this.handleChange(e, this.handleChangeCnpj, this.state.model.ordemClienteFatura)} /> : <></>}

                                <InputText disabled={!this.podeEditarCliente()} label="Nome do Cliente" required md={6} value={this.state.model.ordemClienteFatura.nome} name="nome" onChange={e => this.handleChange(e, false, this.state.model.ordemClienteFatura)} />
                                <InputEmail disabled={!this.podeEditarCliente()} label="E-mail" md={6} name="email" value={this.state.model.ordemClienteFatura.email} onChange={e => this.handleChange(e, false, this.state.model.ordemClienteFatura)} />
                            </div>
                            <div className="row mb-3 g-3">
                                <div className="col d-flex align-items-end bd-highlight">
                                    {(this.state.model.tipoProdutoServico?.cdTipo === "P" || parseInt(this.state.model.idOrdemTipo) === 2) && this.podeEditarCliente() ?
                                        <BtnButton className="btn-outline-success text-nowrap" onClick={(e) => { this.handleClickDadosProprietario("ordemClienteFatura") }} disabled={isNaN(parseInt(this.state.model?.idRepresentante))}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-reply-all-fill" viewBox="0 0 16 16">
                                                <path d="M8.021 11.9 3.453 8.62a.719.719 0 0 1 0-1.238L8.021 4.1a.716.716 0 0 1 1.079.619V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                                                <path d="M5.232 4.293a.5.5 0 0 1-.106.7L1.114 7.945a.5.5 0 0 1-.042.028.147.147 0 0 0 0 .252.503.503 0 0 1 .042.028l4.012 2.954a.5.5 0 1 1-.593.805L.539 9.073a1.147 1.147 0 0 1 0-1.946l3.994-2.94a.5.5 0 0 1 .699.106z" />
                                            </svg> Usar Dados do Proprietário
                                        </BtnButton>
                                        : <></>}

                                    {Boolean(this.state.model.flDadosCliente)  && this.podeEditarCliente()?
                                        <BtnButton className="btn-outline-primary text-nowrap ms-2" onClick={(e) => { this.handleClickCopiarClienteFinal() }} disabled={isNaN(parseInt(this.state.model?.idRepresentante))}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                                                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                                                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                                            </svg> Copiar Dados do Cliente Final
                                        </BtnButton>
                                        : <></>}
                                </div>
                            </div>
                        </div>
                    </div>
                : <></>}

                {Boolean(this.state.model?.flDadosVeiculo) ?
                        <div className="card mt-3 border-secondary" ref={this.references.refVeiculo} >
                            <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient">
                                <div className="row">
                                    <div className="col">
                                        <ul className="nav nav-tabs card-header-tabs">
                                            <li className="nav-item">
                                                <button className="nav-link active" id="nav-dados-veiculo-tab" data-bs-toggle="tab" data-bs-target="#nav-dados-veiculo" type="button" role="tab" aria-controls="nav-dados-veiculo" aria-selected="true">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-car-front" viewBox="0 0 16 16">
                                                        <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17 1.247 0 2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276Z" />
                                                        <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.807.807 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155 1.806 0 4.037-.084 5.592-.155A1.479 1.479 0 0 0 15 9.611v-.413c0-.099-.01-.197-.03-.294l-.335-1.68a.807.807 0 0 0-.43-.563 1.807 1.807 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3H4.82Z" />
                                                    </svg> Dados do Veículo
                                                </button>
                                            </li>
                                            {this.state.model.ordemVeiculoHistorico.length > 0 ?
                                                <li className="nav-item">
                                                    <button className="nav-link" id="nav-historico-veiculo-tab" data-bs-toggle="tab" data-bs-target="#nav-historico-veiculo" type="button" role="tab" aria-controls="nav-historico-veiculo" aria-selected="false">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
                                                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                                                        </svg> Histórico do Veículo <small>({this.state.model.ordemVeiculoHistorico.length})</small>
                                                    </button>
                                                </li> : <></>
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-auto d-flex justify-content-end d-none d-lg-block">
                                        {this.getBtnScroll(3)}
                                    </div>
                                </div>                               
                            </div>
                            <div className="card-body">
                                <div className="tab-content mx-2 my-3" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-dados-veiculo" role="tabpanel" aria-labelledby="nav-dados-veiculo-tab">
                                        {Boolean(this.state.model?.ordemVeiculo.flDadosVeiculoPlaca) ?
                                            <div className="row mx-1 mb-3">
                                                <InputCheckSwitch label="Veículo tem informação da Placa?" name="flTemPlaca" checked={Boolean(this.state.model.ordemVeiculo.flTemPlaca)} onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} />
                                            </div>
                                            : <></>}
                                        <div className="row mb-3 g-3">
                                            <InputChassis disabled={!this.podeEditarVeiculo()} label="Chassis" required md={2} value={this.state.model.ordemVeiculo.chassis} name="chassis" onChange={e => this.handleChange(e, this.handleChangeChassi, this.state.model.ordemVeiculo)} />
                                            <SelectPais md={2} showBtns={false} label="Pais de Emplacamento" disabled={!Boolean(this.state.model?.ordemVeiculo.flDadosVeiculoPlaca) || !Boolean(this.state.model.ordemVeiculo.flTemPlaca) || !this.podeEditarVeiculo()} required={Boolean(this.state.model.ordemVeiculo.flTemPlaca)} placeholder="Selecione o País" name="idPaisPlaca" value={parseInt(this.state.model.ordemVeiculo.idPaisPlaca) > 0 ? this.state.model.ordemVeiculo.idPaisPlaca : ""} onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} opcionalLabel={false} />

                                            {parseInt(this.state.model.ordemVeiculo.idPaisPlaca) !== 1 ?
                                                <InputText required={Boolean(this.state.model?.ordemVeiculo.flDadosVeiculoPlaca) && Boolean(this.state.model.ordemVeiculo.flTemPlaca)} disabled={!Boolean(this.state.model?.ordemVeiculo.flDadosVeiculoPlaca) || !(parseInt(this.state.model.ordemVeiculo.idPaisPlaca) > 0) || !Boolean(this.state.model.ordemVeiculo.flTemPlaca) || !this.podeEditarVeiculo()} label="Placa" md={2} value={this.state.model.ordemVeiculo.placa ? this.state.model.ordemVeiculo.placa : ""} name="placa" onChange={e => this.handleChange(e, this.handleChangePlaca, this.state.model.ordemVeiculo)} opcionalLabel={false} />
                                                :
                                                <InputPlaca required={Boolean(this.state.model?.ordemVeiculo.flDadosVeiculoPlaca) && Boolean(this.state.model.ordemVeiculo.flTemPlaca)} disabled={!Boolean(this.state.model?.ordemVeiculo.flDadosVeiculoPlaca) || !(parseInt(this.state.model.ordemVeiculo.idPaisPlaca) > 0) || !Boolean(this.state.model.ordemVeiculo.flTemPlaca) || !this.podeEditarVeiculo()} label="Placa" md={2} value={this.state.model.ordemVeiculo.placa ? this.state.model.ordemVeiculo.placa : ""} name="placa" onChange={e => this.handleChange(e, this.handleChangePlaca, this.state.model.ordemVeiculo)} opcionalLabel={false} />
                                            }

                                            <InputNumber className="text-center" disabled={!this.podeEditarVeiculo()} label="Ano" placeholder="9999" required md={2} value={this.state.model.ordemVeiculo.ano} name="ano" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} min={1900} step={1} />
                                            <SelectMarcaByCategoriaProdutoServico dispachChangeDidMount={false} showBtnNewForm={true} idCategoriaProdutoServico={this.state.model.idCategoriaProdutoServico} disabled={!this.podeEditarVeiculo()} md={4} label="Marca" required placeholder="Selecione a Marca" name="idMarca" value={this.state.model.ordemVeiculo.idMarca} onChange={e => this.handleChange(e, this.handleChangeMarca, this.state.model.ordemVeiculo)} />
                                            <SelectModeloByMarcaAndAno dispachChangeDidMount={false} showBtnNewForm={true} nrAno={this.state.model.ordemVeiculo.ano} idMarca={this.state.model.ordemVeiculo.idMarca} disabled={!this.podeEditarVeiculo()} md={5} label="Modelo" placeholder="Selecione um Modelo ou Descreva o Modelo" name="idModelo" value={this.state.model.ordemVeiculo.idModelo} onChange={(e) => this.handleChange(e, this.handleChangeModelo, this.state.model.ordemVeiculo)} />

                                            <InputText disabled={(parseInt(this.state.model.ordemVeiculo.idModelo) > 0) || !this.podeEditarVeiculo()} label="Qual Modelo?" required={!(parseInt(this.state.model.ordemVeiculo.idModelo) > 0)} md={3} value={this.state.model.ordemVeiculo.modelo} name="modelo" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} maxLength={100} />

                                            <InputText disabled={!this.podeEditarVeiculo()} label="Potência" required md={2} value={this.state.model.ordemVeiculo.potencia} name="potencia" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} maxLength={100} />
                                            <SelectTransmissao showBtns={false} disabled={!this.podeEditarVeiculo()} md={2} label="Transmissão" required placeholder="Selecione a Transmissão" name="idTransmissao" value={this.state.model.ordemVeiculo.idTransmissao} onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} />
                                            <SelectCombustivel showBtns={false} disabled={!this.podeEditarVeiculo()} md={3} label="Combustível" required placeholder="Selecione o Combustível" name="idCombustivel" value={this.state.model.ordemVeiculo.idCombustivel} onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} />
                                            <InputText disabled={!this.podeEditarVeiculo()} label="Motor" required md={2} value={this.state.model.ordemVeiculo.motor} name="motor" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} maxLength={100} />

                                            <InputText disabled={!this.podeEditarVeiculo()} label="Método de Leitura" md={3} value={this.state.model.ordemVeiculo.metodoLeitura} name="metodoLeitura" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} maxLength={100} opcionalLabel={false} />
                                            <InputText disabled={!this.podeEditarVeiculo()} label="Flash" md={3} value={this.state.model.ordemVeiculo.dsFlash} name="dsFlash" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} maxLength={100} opcionalLabel={false} />
                                            <InputQuilometro required={this.state.model.ordemVeiculo.horas.length === 0} disabled={!this.podeEditarVeiculo()} label="Quilometragem" placeholder="99999" md={2} value={this.state.model.ordemVeiculo.quilometragem} name="quilometragem" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} min={0} max={9999999999} step={1} opcionalLabel={false} />
                                            <InputQuilometro required={this.state.model.ordemVeiculo.quilometragem.length === 0} disabled={!this.podeEditarVeiculo()} label="Horas" placeholder="99999" md={2} value={this.state.model.ordemVeiculo.horas} name="horas" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} min={0} max={9999999999} step={1} opcionalLabel={false} />
                                            <InputNumber disabled={!this.podeEditarVeiculo() || !this.podeCadatrarAlterarGanho()} label="Ganho (CV)" placeholder="99999" md={2} value={this.state.model.ordemVeiculo.ganhoCv} name="ganhoCv" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} min={0} max={9999999999} step={1} opcionalLabel={false} />
                                            <InputNumber disabled={!this.podeEditarVeiculo() || !this.podeCadatrarAlterarTorque()} label="Torque (Kgfm)" placeholder="99999" md={2} value={this.state.model.ordemVeiculo.ganhoTorque} name="ganhoTorque" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} min={0} max={9999999999} step={1} opcionalLabel={false} />
                                            <TextArea disabled={!this.podeEditarVeiculo()} label="Particularidades" placeholder="Descreva as particularidades do veículo" md={12} value={this.state.model.ordemVeiculo.dsParticularidades} name="dsParticularidades" onChange={e => this.handleChange(e, false, this.state.model.ordemVeiculo)} opcionalLabel={false} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-historico-veiculo" role="tabpanel" aria-labelledby="nav-historico-veiculo-tab">
                                        <div className="table-responsive">
                                            <table className="table table-sm table-striped">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="text-center"> Ordem </th>
                                                        <th className="col-1 text-center"> Data </th>
                                                        <th className="col-2"> Tipo </th>
                                                        <th className="col-3"> Cliente </th>
                                                        <th className="col-3"> Representante </th>
                                                        <th className="col-3"> Usuário </th>
                                                        <th className="col-2"> Status </th>
                                                        <th className="text-end">&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.model.ordemVeiculoHistorico.map((item, index) => (
                                                        <tr key={index} className="tr-borderless">
                                                            <td className="text-center">
                                                                <span className="rounded-pill badge w-100 fs-7 text-bg-secondary"> #{item.idOrdem} </span>
                                                            </td>
                                                            <td className="text-center"> {Util.date2Br(item.dataOrdem)} </td>
                                                            <td> {item.nomeTipoProdutoServico} </td>
                                                            <td> {item.nomeCliente} </td>
                                                            <td> {item.nomeRepresentante} </td>
                                                            <td> {item.nomeUsuario} </td>
                                                            <td>
                                                                <span className={`rounded-pill badge w-100 fs-7 ${this.getClassStatus(item.idOrdemStatus)}`}> {item.nomeOrdemStatus} </span>
                                                            </td>
                                                            <td>
                                                                <BtnButton className="btn-primary btn-sm text-nowrap" onClick={(e) => { this.handleOpenModalOrdem(item) }}> Ver Ordem </BtnButton>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }

                {Boolean(this.state.model?.flDadosEndereco) ?
                        <div className="card mt-3 border-secondary" ref={this.references.refEndereco} >
                            <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">                                
                                <div className="row">
                                    <div className="col">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mailbox" viewBox="0 0 16 16">
                                            <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                                            <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
                                        </svg> Dados de Endereço
                                    </div>
                                    <div className="col-auto d-flex justify-content-end d-none d-lg-block">
                                        {this.getBtnScroll(4)}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body row mb-3 g-3">
                                <div className="col-12">
                                    <BtnButton disabled={!this.podeEditarEndereco() || isNaN(parseInt(this.state.model?.idRepresentante))} className="btn-outline-success text-nowrap" onClick={this.handleImportEndereco}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-reply-all-fill" viewBox="0 0 16 16">
                                            <path d="M8.021 11.9 3.453 8.62a.719.719 0 0 1 0-1.238L8.021 4.1a.716.716 0 0 1 1.079.619V6c1.5 0 6 0 7 8-2.5-4.5-7-4-7-4v1.281c0 .56-.606.898-1.079.62z" />
                                            <path d="M5.232 4.293a.5.5 0 0 1-.106.7L1.114 7.945a.5.5 0 0 1-.042.028.147.147 0 0 0 0 .252.503.503 0 0 1 .042.028l4.012 2.954a.5.5 0 1 1-.593.805L.539 9.073a1.147 1.147 0 0 1 0-1.946l3.994-2.94a.5.5 0 0 1 .699.106z" />
                                        </svg> Importar Endereço do Proprietário
                                    </BtnButton>
                                </div>
                                {/*<SelectEnderecoByRepresentante disabled={!this.podeEditarEndereco()} idRepresentante={this.state.model.idRepresentante} sizing="md" label="Endereço" placeholder="Selecione o Endereço" name="idEndereco" md={12} value={this.state.model.ordemEndereco.idEndereco} onChange={e => this.handleChange(e, this.handleChangeEndereco, this.state.model.ordemEndereco)} opcionalLabel={false} />*/}


                                {parseInt(this.state.model.ordemEndereco.idPais) === 1 ?
                                    <InputCep disabled={!this.podeEditarEndereco()} label="CEP" md={3} value={this.state.model.ordemEndereco.cep} name="cep" onChange={e => this.handleChange(e, this.handleChangeCep, this.state.model.ordemEndereco)} required={true} />
                                    :
                                    <InputText disabled={!this.podeEditarEndereco()} label="CEP" md={3} value={this.state.model.ordemEndereco.cep} name="cep" onChange={e => this.handleChange(e, false, this.state.model.ordemEndereco)} maxLength={20} required={true} />
                                }

                                <InputText disabled={!this.podeEditarEndereco()} label="Logradouro" md={9} value={this.state.model.ordemEndereco.endereco} name="endereco" onChange={e => this.handleChange(e, false, this.state.model.ordemEndereco)} maxLength={100} required={true} />

                                <InputText disabled={!this.podeEditarEndereco()} label="Bairro" md={5} value={this.state.model.ordemEndereco.bairro} name="bairro" onChange={e => this.handleChange(e, false, this.state.model.ordemEndereco)} maxLength={150} required={true} />
                                <InputText disabled={!this.podeEditarEndereco()} label="Número" md={2} value={this.state.model.ordemEndereco.numero} name="numero" onChange={e => this.handleChange(e, false, this.state.model.ordemEndereco)} maxLength={100} required={true} />
                                <InputText disabled={!this.podeEditarEndereco()} label="Complemento" md={5} value={this.state.model.ordemEndereco.complemento} name="complemento" onChange={e => this.handleChange(e, false, this.state.model.ordemEndereco)} maxLength={100} />

                                <SelectPais disabled={!this.podeEditarEndereco()} sizing="md" label="Nome do País" required placeholder="Selecione o País" name="idPais" md={3} value={this.state.model.ordemEndereco.idPais} onChange={e => this.handleChange(e, false, this.state.model.ordemEndereco)} />
                                <SelectEstadoByPais disabled={!this.podeEditarEndereco()} sizing="md" md={4} required idPais={this.state.model.ordemEndereco.idPais} label="Estado" value={this.state.model.ordemEndereco.idEstado} placeholder="Selecione o Estado" name="idEstado" onChange={e => this.handleChange(e, false, this.state.model.ordemEndereco)} />
                                <SelectCidadeByEstado disabled={!this.podeEditarEndereco()} sizing="md" md={5} required idPais={this.state.model.ordemEndereco.idPais} idEstado={this.state.model.ordemEndereco.idEstado} label="Cidade" value={this.state.model.ordemEndereco.idCidade} placeholder="Selecione a Cidade" name="idCidade" onChange={e => this.handleChange(e, false, this.state.model.ordemEndereco)} />
                            </div>
                        </div>
                        :
                        <></>
                }


                <div className="card mt-3 mb-3 border-secondary" ref={this.references.refComentario} >
                    <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">                        
                        <div className="row">
                            <div className="col">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-quote" viewBox="0 0 16 16">
                                    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                                    <path d="M7.066 6.76A1.665 1.665 0 0 0 4 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z" />
                                </svg> Comentários
                            </div>
                            <div className="col-auto d-flex justify-content-end d-none d-lg-block">
                                {this.getBtnScroll(5)}
                            </div>
                        </div>
                    </div>
                    <div className="card-body p-1">

                        <div className="row g-1 pb-1 px-2">
                            {this.state.model.ordemComentario.map((item, indexComentario) => (                               
                                <div key={indexComentario}>
                                    {item.idOrdemComentario ?

                                        <div className="row g-1">
                                            {parseInt(item.idUsuario) !== parseInt(this.state.idUsuario) ?
                                                <div className="col-auto">
                                                    <img className="bd-placeholder-img flex-shrink-0 me-2 rounded img-fluid" style={{ maxWidth: "64px", maxHeight: "48px" }} src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uriFotoUsuario} alt="..." />
                                                </div>
                                                : <></>}
                                            <div className="col">
                                                <div className={`card w-75 ${parseInt(item.idUsuario) === parseInt(this.state.idUsuario) ? " float-end bg-primary bg-opacity-25" : "float-start"}`}>
                                                    <div className="card-body p-2 pt-1  bg-secondary bg-opacity-25">
                                                        <span className="card-title">
                                                            <div className="hstack gap-3">
                                                                <div className="fw-bold fs-7">
                                                                    {item.nomeUsuario}
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <small className="text-muted">
                                                                        {Boolean(item.flPrivado) ? <span className="badge bg-danger me-1 fst-italic"> PRIVADO </span> : <></>}
                                                                        <span className="badge bg-dark">
                                                                            {Util.date2Br(item.dataComentario)} - {item.horaComentario}
                                                                        </span>
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </span>

                                                        <p className="card-text lh-sm fs-5" dangerouslySetInnerHTML={{ __html: Util.htmlFormatWhatsapp(item.dsComentario) }} />

                                                        <div className="d-flex flex-row mb-3 gap-1">
                                                            {item.ordemComentarioUsuario.map((usuario, indexUsuario) => (
                                                                <span className="badge fw-normal bg-success" key={indexUsuario}>
                                                                    @{usuario.nomeUsuario}
                                                                </span>
                                                            ))}
                                                        </div>

                                                        {item.ordemComentarioArquivo.map((arquivo, indexArquivo) => (
                                                            <span className="" key={indexArquivo}>
                                                                {item.flPodeRealizarDownload ? 
                                                                    <a className="m-1 btn btn-primary btn-sm" rel="opener" href={Config.getUrlArquivo() + "public/arquivo/download/" + arquivo.uid}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                                        </svg> {arquivo.nomeArquivo}
                                                                    </a>
                                                                : 
                                                                <button className="m-1 btn btn-outline-danger btn-sm" disabled>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-ban" viewBox="0 0 16 16">
                                                                        <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0"/>
                                                                    </svg> {arquivo.nomeArquivo}
                                                                </button>
                                                                }
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            {parseInt(item.idUsuario) === parseInt(this.state.idUsuario) ?
                                                <div className="col-auto">
                                                    <img className="bd-placeholder-img flex-shrink-0 me-2 rounded img-fluid" style={{ maxWidth: "64px", maxHeight: "48px" }} src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uriFotoUsuario} alt="..." />
                                                </div>
                                                : <></>}
                                        </div>

                                        :

                                        <div className="card w-75 float-end">
                                            <div className="card-body row gap-3">
                                                <span className="card-title fw-bold">
                                                    {Auth.getDataToken().nome}
                                                </span>

                                                <TextArea className="fs-5" autoFocus={true} col={12} label="Comentário" required={item.ordemComentarioArquivo.length === 0} name="dsComentario" value={item.dsComentario} onChange={e => this.handleChange(e, false, item)} onKeyUp={e => {this.handleSetCursorComentario(e, item)}} onClick={e => {this.handleSetCursorComentario(e, item)}}/>

                                                <div className="d-flex flex-wrap">
                                                    {this.state.arrEmoji.map((emoji, indexEmoji) => (
                                                        <BtnButton className="p-0 m-0 fs-4" key={indexEmoji} onClick={e=>this.handleClickAddEmoji(emoji, item)}>{emoji}</BtnButton>
                                                    ))}
                                                </div>

                                                {Permissao.getInstance().getPermissao(108) ?
                                                    <div className="mb-2">
                                                        <InputCheckSwitch label="Comentário Oculto?" name="flPrivado" checked={Boolean(item.flPrivado)} onChange={e => this.handleChange(e, () => { this.handleChangeFlPrivado(indexComentario) }, item)} />
                                                    </div>
                                                    : <></>}

                                                    <div className="mb-2">
                                                        {this.state.usuarioOrdem.filter(u => { return (Boolean(item.flPrivado) && Boolean(u.flPrivado)) || !(Boolean(item.flPrivado)) ? u : false }).map((usuario, index) => {
                                                            var indexUsuarioComentario = this.state.model.ordemComentario[indexComentario].ordemComentarioUsuario.findIndex(u => parseInt(u.idUsuario) === parseInt(usuario.idUsuario));
                                                            return (
                                                                <BtnButton disabled={!Permissao.getInstance().getPermissao(104)} key={index} className={`btn-sm m-1 ${indexUsuarioComentario >= 0 ? 'btn-success' : 'btn-outline-secondary'}`} onClick={e => this.handleChangeUsuarioComentario(indexComentario, indexUsuarioComentario, usuario)}>@{usuario.nome}</BtnButton>
                                                            );
                                                        })}
                                                    </div>

                                                {item.ordemComentarioArquivo.map((arquivo, indexArquivo) => (
                                                    <div className="col-12" key={indexArquivo}>
                                                        <a rel="opener" href={Config.getUrlApi() + "arquivo/" + arquivo.uid + "/?t=" + Auth.getToken()} className="btn btn-link"> {arquivo.nomeArquivo} </a>
                                                        <button type="button" className="btn btn-sm ms-2 btn-link link-danger" onClick={() => { this.handleDeleteArquivo(indexComentario, indexArquivo) }}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg> Remover arquivo
                                                        </button>
                                                    </div>
                                                ))}

                                                <InputFile label="Selecione o arquivo" name="arquivo" onChange={(event) => { this.handleFileChange(event, indexComentario, item) }} multiple />

                                                <div className="col-12">
                                                    <button type="button" className="btn btn-sm btn-link link-danger" onClick={() => { this.handleDeleteComentario(indexComentario, item) }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg> Remover comentário
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>


                        {this.podeInserirComentario() ?
                            <div className="card-footer text-end">
                                <button type="button" className="btn ms-2 btn-outline-primary text-nowrap" onClick={this.handleAddComentario}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                    </svg> Adicionar Comentário
                                </button>
                            </div>
                            : <></>}
                    </div>
                </div>

                {
                    Boolean(this.state.model.flTemSuporte) && parseInt(this.state.model.idOrdemStatus) === 5 ?
                        <div className="alert alert-warning border border-warning" role="alert">
                            <h5 className="alert-heading">
                                Tem um problema com essa ordem?
                            </h5>
                            <p>
                                Essa ordem já está com status <strong>FATURADA</strong> e por isso não pode ser editada:
                                <br/> - Caso tenha um problema ou necessita de algum suporte, você deve criar uma nova ordem de <strong>SUPORTE</strong>, faça isso através do botão abaixo!:
                            </p>
                            <hr />
                            <div className="row">                                
                                <div className="col-md-6 mb-0 text-center">
                                    <button type="button" className="btn ms-2 btn-primary" onClick={this.handleAddOrdemNova}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                        </svg> Quero abrir uma NOVA ORDEM para o veículo
                                    </button>                                    
                                </div>
                                <div className="col-md-6 mb-0  text-center">
                                    <button type="button" className="btn ms-2 btn-danger" onClick={this.handleAddOrdemSuporte}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                        </svg> Tenho um PROBLEMA e quero abrir uma ordem de SUPORTE
                                    </button>
                                    <br/> Utilize somente esse recurso quando você teve um problema com a ordem acima, caso você queira realizar uma alteração você deve abrir uma NOVA ORDEM.
                                </div>
                            </div>
                        </div>
                        : <></>
                }

                < ModalConfirmacao id="modal-confirmacao-status" />
            </>
        );
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex" ref={this.references.refBotoes}>
                <BtnSubmit className="btn-primary" id="btnSalvarFechar"> Salvar e Fechar </BtnSubmit>
                <BtnSubmit className="btn-outline-primary" id="btnSalvarContinuar"> Salvar e Continuar </BtnSubmit>
                <div className="vr"></div>
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>

                {Permissao.getInstance().getPermissoes([126, 51, 30]) ?
                    <div className="btn-group dropup ms-auto">
                        <button type="button" className="btn btn-outline-dark dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            Mais Ações
                        </button>
                        <ul className="dropdown-menu">
                            {Permissao.getInstance().getPermissao(126) && this.state.model.tipoProdutoServico.flRemap ?
                                <li>
                                    <button type="button" className="dropdown-item" to="#" onClick={this.handleImprimirTermo} disabled={isNaN(parseInt(this.state.model.idOrdem))}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                        </svg> Imprimir Termo de Responsabilidade
                                    </button>
                                </li> : <></>
                            }

                            {Permissao.getInstance().getPermissao(51) ?
                                <li>
                                    <button type="button" className="dropdown-item" to="#" onClick={this.handleImprimirIndividual} disabled={isNaN(parseInt(this.state.model.idOrdem))}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-filetype-pdf" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z" />
                                        </svg> Imprimir Ordem
                                    </button>
                                </li> : <></>
                            }

                            {Permissao.getInstance().getPermissao(30) ?
                                <li>
                                    <Link to={`/Faturar/${this.state.model.idOrdem}`} className={`dropdown-item ${this.state.model.flFaturado || parseInt(this.state.model.idOrdemStatus) !== 2 ? "disabled" : ""}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-journal-plus" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z" />
                                            <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                                            <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                                        </svg> Faturar Ordem
                                    </Link>
                                </li>
                                : <></>
                            }
                        </ul>
                    </div>
                    : <></>}

            </div>);
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <FormOrdem {...props} navigate={navigate} params={params} location={location}/>
}

export default With