import React from "react";
import PainelService from "../../services/PainelService";
import CrudList from "../../components/forms/custom/CrudList";
import BtnButton from "../../components/forms/elements/BtnButton";
import { Link } from "react-router-dom";

export default class ListaPainel extends CrudList {

    constructor(props) {
        super(props);

        this.handleClickUpItem = this.handleClickUpItem.bind(this);
        this.handleClickDownItem = this.handleClickDownItem.bind(this);
        this.salvarOrdem = this.salvarOrdem.bind(this);

        this.state.crud = {
            service: PainelService,
            labels: {
                title: 'Lista de Painel',
                btnNewRegister: 'Painel',
            },
            keyItem: 'idPainel',
            urlForm: '/CadPainel',
            itensFilter: ["idPainel", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-6"> Nome </th>
                <th className="col-md-1 text-center"> Blocos </th>
                <th className="col-md-1 text-center"> Colunas </th>
                <th className="col"> </th>
                <th className="col"> </th>
            </>
        );
    }

    salvarOrdem() {
        PainelService.saveOrdem(this.state.data, response => {
            this.onList();
        });
    }

    handleClickUpItem(index) {
        this.setState(state => {
            var painel1 = this.state.data[index];
            var bloco2 = this.state.data[index - 1];

            this.state.data[index] = bloco2;
            this.state.data[index - 1] = painel1;

            return state;
        }, this.salvarOrdem);
    }

    handleClickDownItem(index) {
        this.setState(state => {
            var painel1 = this.state.data[index];
            var painel2 = this.state.data[index + 1];

            this.state.data[index] = painel2;
            this.state.data[index + 1] = painel1;

            return state;
        }, this.salvarOrdem);
    }

    getRenderItem(item, index) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idPainel}</td>
                <td>{item.nome}</td>
                <td className="text-center">{item.nrBlocos}</td>
                <td className="text-center">{item.nrColunas}</td>
                <td>
                    <BtnButton onClick={e => this.handleClickUpItem(index)} className="btn-outline-primary rounded-pill btn-sm" disabled={index === 0}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                        </svg>
                    </BtnButton>
                </td>
                <td>
                    <BtnButton onClick={e => this.handleClickDownItem(index)} className="btn-outline-primary rounded-pill btn-sm" disabled={index === (this.state.data.length - 1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </BtnButton>
                </td>
                <td>
                    <Link to={`/Painel/${item.idPainel}`} className="btn btn-sm btn-outline-dark">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-speedometer" viewBox="0 0 16 16">
                            <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                            <path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z" />
                        </svg>
                    </Link>
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}