import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import MarcaService from "../../services/MarcaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import CategoriaProdutoServicoService from "../../services/CategoriaProdutoServicoService";

class FormMarca extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeCategoriaProdutoServicos = this.handleChangeCategoriaProdutoServicos.bind(this);
        this.handleChangeCategoriaProdutoServicosAll = this.handleChangeCategoriaProdutoServicosAll.bind(this);

        this.state.model = {
            idMarca: "",
            nome: "",
            marcaCategoriaProdutoServico: []
        };

        this.state.crud = {
            service: MarcaService,
            labels: {
                title: 'Marca',
            },
            urlList: '/LstMarca',
        };
        
        this.state.categoriaProdutoServicos = [];
    }

    componentDidMount() {
        super.componentDidMount();

        CategoriaProdutoServicoService.list((response) => {
            this.setState({ categoriaProdutoServicos: response });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChangeCategoriaProdutoServicos(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.marcaCategoriaProdutoServico.push(item);
                return state;
            });
        } else {
            var index = this.state.model.marcaCategoriaProdutoServico.findIndex(i => parseInt(i.idCategoriaProdutoServico) === parseInt(item.idCategoriaProdutoServico));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.marcaCategoriaProdutoServico.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangeCategoriaProdutoServicosAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.marcaCategoriaProdutoServico = [];

                this.state.categoriaProdutoServicos.forEach(function (item) {
                    state.model.marcaCategoriaProdutoServico.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.marcaCategoriaProdutoServico = [];
                return state;
            });
        }
    }

    getRenderFields() {
        return (
            <div>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={3} value={this.state.model.idMarca} name="idMarca" readOnly opcionalLabel={false} />
                    <InputText label="Nome da Marca" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                </div>
                <hr className="hr" />
                <div>
                    <h6> Categorias vinculadas: </h6>
                    <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangeCategoriaProdutoServicosAll} checked={this.state.model.marcaCategoriaProdutoServico.length === this.state.categoriaProdutoServicos.length} />
                    <div className="row mb-3">
                        {this.state.categoriaProdutoServicos.map((item) => (
                            <div key={item.idCategoriaProdutoServico} className="col-md-6">
                                <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.marcaCategoriaProdutoServico.findIndex(i => parseInt(i.idCategoriaProdutoServico) === parseInt(item.idCategoriaProdutoServico)) !== -1} onChange={(e) => { this.handleChangeCategoriaProdutoServicos(e, item) }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormMarca {...props} navigate={navigate} params={params} />
}

export default With