import React, { Component } from "react";
import EventBus from "../../controllers/EventBus";

export default class ModalInfo extends Component {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleKeyUpModal = this.handleKeyUpModal.bind(this);

        this.state = {
            element: {},
            show: false,
            title: props.title || "",
            message: props.message || "",
            description: props.description || "",
            confirm: props.confirm || (() => { }),
            close: props.close || (() => { }),
            EventBusOpen: props.id ? props.id + "-open" : "modal-info-open",

            btnConfirmText: props["btn-confirm-text"] || "OK",
            style: "primary",
            ref: React.createRef()
        };
    }

    componentDidMount() {
        EventBus.on(this.state.EventBusOpen, (element) => {

            if (element.config) {
                this.setState((state) => {
                    Object.keys(element.config).forEach(key => {
                        state[key] = element.config[key];
                    });
                    return state;
                });
            }

            this.setState({ show: true, element: element }, () => {
                if (this.state.ref.current) {
                    this.state.ref.current.focus();
                }
            });
        });
    }

    componentWillUnmount() {
        EventBus.remove(this.state.EventBusOpen);
    }

    handleClose() {
        this.setState({ show: false });
        this.state.close(this.state.element);
    }

    handleConfirm() {
        this.setState({ show: false });
        this.state.confirm(this.state.element);
    }

    handleKeyUpModal(event) {
        if (event.keyCode === 27) {
            this.handleClose(event);
        }
    }

    render() {
        return (
            <>
                <div className={`fade modal-backdrop show ${this.state.show ? "" : "d-none"}`} onClick={alert}> </div>
                <div className={`modal ${this.state.show ? "d-block" : "d-none"}`} id="exampleModal" tabIndex="-1"
                    ref={this.state.ref}
                    onKeyUp={this.handleKeyUpModal}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="false" aria-modal="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {this.state.title}
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleClose}></button>
                            </div>
                            <div className="modal-body p-4">
                                <div className="row">
                                    <div className="col-auto">
                                        <svg className={`text-${this.state.style} bi bi-info-circle-fill`} xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                        </svg>
                                    </div>
                                    <div className="col">
                                        <span className="fs-6">{this.state.message}</span>
                                        <div className="fs-6 fw-light fst-italic">{this.state.description}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-${this.state.style}`} onClick={this.handleConfirm}>
                                    {this.state.btnConfirmText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
