import React from "react";
import OrdemStatusService from "../../services/OrdemStatusService";
import CrudList from "../../components/forms/custom/CrudList";

export default class ListaOrdemStatus extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: OrdemStatusService,
            labels: {
                title: 'Lista de Ordem Status(s)',
                btnNewRegister: 'Ordem Status',
            },
            keyItem: 'idOrdemStatus',
            urlForm: '/CadOrdemStatus',
            itensFilter: ["idOrdemStatus", "nome"],
            searchFormSubmit: false
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-8"> Nome </th>
                <th className="col-md-2 text-center"> Ordem de Apresentação </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idOrdemStatus}</td>
                <td>{item.nome}</td>
                <td className="text-center">{item.nrOrdem}</td>
            </>);
    }

    render() {
        return super.render();
    }
}