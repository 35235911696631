import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import MyComponent from "../../components/custom/MyComponent";
import EventBus from "../../controllers/EventBus";
import NotificacaoService from "../../services/NotificacaoService";
import Util from "../../utils/Util";

export default class ListaNotificacao extends MyComponent {

    constructor(props) {
        super(props);

        this.handleChangeStatusVisualizacacao = this.handleChangeStatusVisualizacacao.bind(this);
        this.getListaNaoVisualizada = this.getListaNaoVisualizada.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getLista = this.getLista.bind(this);
        this.getListaFiltrada = this.getListaFiltrada.bind(this);

        this.state.model = {
            filtro: "N"
        };
        this.state.data = [];
        this.state.filtro = [
            { value: 'T', label: 'Todos' },
            { value: 'L', label: 'Lidas' },
            { value: 'N', label: 'Não lidas' },
        ];

        this.interval = null;
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista(false);
        this.interval = setInterval(this.getLista, (60 * 1000));
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        clearInterval(this.interval);
    }

    getLista(dispachEvent = true, notificar = true) {
        NotificacaoService.list((response) => {
            this.setState({ data: response }, () => {
                if (dispachEvent) {
                    EventBus.dispatch("atualizar-notificacao", {
                        notificacoes: this.getListaNaoVisualizada(),
                        notificar: notificar
                    });
                }
            });
        });
    }

    getListaFiltrada() {
        if (this.state.model.filtro === "N") {
            return this.state.data.filter(o => !o.flVisualizada);
        } else if (this.state.model.filtro === "L") {
            return this.state.data.filter(o => o.flVisualizada);
        }
        return this.state.data;
    }

    getListaNaoVisualizada() {
        return this.state.data.filter(o => !o.flVisualizada);
    }

    handleChangeStatusVisualizacacao(item) {
        if (!item.flVisualizada) {
            NotificacaoService.changeStatus(item.idNotificacao, true, (response) => {
                this.getLista();
                toast.success("Notificação marcada como lida!");
            });
        } else {
            NotificacaoService.changeStatus(item.idNotificacao, false, (response) => {
                this.getLista(true, false);
                toast.success("Notificação marcada como não lida!");
            });
        }
    }

    render() {
        return (
            <div className="container p-3 my-2">
                <div className="hstack gap-3 mb-3">
                    <h4 className="mb-3 w-100">Notificações ({this.getListaFiltrada().length}) </h4>
                    <button type="button" className="btn btn-outline-primary text-nowrap" onClick={this.getLista}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg> Atualizar
                    </button>
                </div>

                <div className="col-md-12 mb-3 text-center">
                    <div>
                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                            {this.state.filtro.map((item) => (
                                <React.Fragment key={item.value}>
                                    <input
                                        className="btn-check"
                                        autoComplete="off"
                                        name="filtro"
                                        id={`radio-${item.value}`}
                                        value={item.value}
                                        type="radio"
                                        checked={this.state.model.filtro === item.value}
                                        onChange={this.handleChange} />
                                    <label className="btn btn-outline-primary" htmlFor={`radio-${item.value}`}>{item.label}</label>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>

                {this.getListaFiltrada().length === 0 ?
                    <div className="text-center py-3">
                        <h5>Nenhuma notificação encontrada!</h5>
                    </div>
                    : ""
                }

                <div className="list-group py-3">

                    {this.getListaFiltrada().map((item) => (
                        <div key={item.idNotificacao} className={`list-group-item list-group-item-action ${item.flVisualizada ? 'list-group-item-light' : ''}`} aria-current="true" onClick={() => { this.handleChangeStatusVisualizacacao(item) }}>
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">
                                    {item.flVisualizada ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-envelope-open" viewBox="0 0 16 16">
                                            <path d="M8.47 1.318a1 1 0 0 0-.94 0l-6 3.2A1 1 0 0 0 1 5.4v.817l5.75 3.45L8 8.917l1.25.75L15 6.217V5.4a1 1 0 0 0-.53-.882l-6-3.2ZM15 7.383l-4.778 2.867L15 13.117V7.383Zm-.035 6.88L8 10.082l-6.965 4.18A1 1 0 0 0 2 15h12a1 1 0 0 0 .965-.738ZM1 13.116l4.778-2.867L1 7.383v5.734ZM7.059.435a2 2 0 0 1 1.882 0l6 3.2A2 2 0 0 1 16 5.4V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5.4a2 2 0 0 1 1.059-1.765l6-3.2Z" />
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                        </svg>
                                    } {item.dsTitulo}</h5>
                                <small>{Util.date2Br(item.dataNotificacao)} {item.horaNotificacao}</small>
                            </div>
                            <div className="mb-1" dangerouslySetInnerHTML={{ __html: Util.nl2br(item.dsNotificacao) }} />
                            <div className="py-1 gap-2 hstack">
                                {item.urlNotificacao ? <Link to={item.urlNotificacao} className="btn btn-warning">{item.dsLabel}</Link> : ""}
                            </div>
                            <small>{item.flVisualizada ? 'MENSAGEM LIDA' : 'MENSAGEM NÃO LIDA'}</small>
                        </div>
                    ))}

                </div>
            </div>
        )
    }
}