import React from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import OrdemService from "../../services/OrdemService";
import FaturaService from "../../services/FaturaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import InputDate from "../../components/forms/elements/InputDate";
import Permissao from "../../controllers/Permissao";
import InputNumber from "../../components/forms/elements/InputNumber";
import InputValorReais from "../../components/forms/custom/InputValorReais";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnButton from "../../components/forms/elements/BtnButton";
import FormOrdem from "../Ordem/FormOrdem"
import FormCotacaoFrete from "./FormCotacaoFrete"
import EventBus from "../../controllers/EventBus";
import TipoBaixaService from "../../services/TipoBaixaService";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import SelectFornecedorByAreaAtuacao from "../../components/app/SelectFornecedorByAreaAtuacao";
import InputPeso from "../../components/forms/custom/InputPeso";
import SelectModalidadeFrete from "../../components/app/SelectModalidadeFrete";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import { toast } from "react-toastify";
import FornecedorService from "../../services/FornecedorService";
import InputCpf from "../../components/forms/custom/InputCpf";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import SelectDDI from "../../components/app/SelectDDI";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import InputEmail from "../../components/forms/elements/InputEmail";
import InputCep from "../../components/forms/custom/InputCep";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import SelectPais from "../../components/app/SelectPais";
import EnderecoService from "../../services/EnderecoService";
import AcordoService from "../../services/AcordoService";

class FormFaturar extends CrudForm {

    constructor(props) {
        super(props);

        this.getListaTipoBaixa = this.getListaTipoBaixa.bind(this);
        this.getPessoa = this.getPessoa.bind(this);
        this.getAcordo = this.getAcordo.bind(this);
        this.getVlFaturaComJuros = this.getVlFaturaComJuros.bind(this);
        this.handleListOrdem = this.handleListOrdem.bind(this);
        this.handleChangeTipoBaixa = this.handleChangeTipoBaixa.bind(this);
        this.handleChangeOrdensAll = this.handleChangeOrdensAll.bind(this);
        this.handleChangeCobrancaAll = this.handleChangeCobrancaAll.bind(this);
        this.handleChangeParcelas = this.handleChangeParcelas.bind(this);
        this.handleChangeOrdemItem = this.handleChangeOrdemItem.bind(this);
        this.handleOpenModalFatura = this.handleOpenModalFatura.bind(this);
        this.handleChangeValorParcela = this.handleChangeValorParcela.bind(this);
        this.handleChangeVencimentoParcela = this.handleChangeVencimentoParcela.bind(this);
        this.handleChangeFornecedor = this.handleChangeFornecedor.bind(this);
        this.handleClickDMais = this.handleClickDMais.bind(this);
        this.handleClickCotacao = this.handleClickCotacao.bind(this);
        this.buildParcelas = this.buildParcelas.bind(this);
        this.buildTransporte = this.buildTransporte.bind(this);
        this.buildTipoBaixa = this.buildTipoBaixa.bind(this);
        this.handleClickExcluirFornecedorServico = this.handleClickExcluirFornecedorServico.bind(this);
        this.handleChangeCep = this.handleChangeCep.bind(this);        
        this.faturar = this.faturar.bind(this);

        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtVencimentoStart = new Date();
        dtVencimentoStart.setDate(dtVencimentoStart.getDate() + 1);
        dtVencimentoStart = new Date(dtVencimentoStart - tzoffset).toISOString().slice(0, 10);

        this.state.idOrdem = false;
        this.state.idOrdens = [];

        if (this.props.params?.idOrdem) {
            this.state.idOrdem = this.props.params?.idOrdem;
        } else if (parseInt(this.props.idOrdem) > 0) {
            this.state.idOrdem = this.props.idOrdem;
        }

        if (this.props?.location?.state?.idOrdens) {
            this.state.idOrdens = this.props.location.state.idOrdens;
        }

        this.state.model = {
            nrParcelas: 1,
            nrIntervalo: 30,
            dtVencimentoStart: dtVencimentoStart,
            cobrancas: [],
            ordens: [],
            cdTipo: "S",
            flTransporte: false,
            flParcelamento: false,
            flJurosFuturo: false,
            flTaxaCartao: true,
            pcJurosFuturo: 1,
            vlJurosFuturo : 0,
            cdCalculoFrente: "P",
            cdComposicaoParcela: "V",
            faturaPessoa: {
                ddi: "55",
                celular: "",
                nome: "",
                email: "",
                cdTipoPessoa: "F",
                cpf: "",
                cnpj: "",
                inscricaoEstadual: "",

                idPais: 1,
                idEstado: "",
                idCidade: "",
                cep: "",
                bairro: "",
                endereco: "",
                numero: "",
                complemento: ""
            },
            faturaTransporte: {
                idFornecedor: "",
                idModalidadeFrete: 1,
                vlTransporte: 0,
                vlPesoBruto: 0,
                vlPesoLiquido: 0,
                flGerarRastreamento: false
            }
        };

        this.state.calculoFrete = [
            { value: 'P', label: 'Na Primeira Parcela' },
            { value: 'D', label: 'Diluir nas Parcelas' },
        ];

        this.state.composicaoParcela = [
            { value: 'V', label: 'Valor + Frente' },
            { value: 'F', label: 'Somente Frete' }
        ];

        this.state.tipos = [
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ];

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];

        this.state.tipoBaixas = [];
        this.state.tipoBaixasSelecao = [];
        this.state.ordens = [];
        this.state.embalagens = [];
        this.state.fornecedor = {};
        this.state.acordo = {};
        this.state.isAcordo = false;

        this.state.crud = {
            service: FaturaService,
            labels: {
                title: 'Fatura',
            },
            urlList: '/LstFatura',
        };

        this.state.isCard = true;
        this.state.container = false;
    }

    componentDidMount() {
        super.componentDidMount();
        this.getListaTipoBaixa();
        this.handleListOrdem();
        this.getPessoa();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getAcordo(idAcordo) {
        AcordoService.getOneById(idAcordo, response =>{
            this.setState({acordo : response});
        });
    }

    getPessoa() {
        var idOrdem = this.state.idOrdem;
        var idOrdens = this.state.idOrdens;

        var payload = {
            idOrdem: idOrdens.length > 0 ? idOrdens[0] : idOrdem
        };

        FaturaService.getPessoaForFatura(payload, (response) => {
            this.setState(state => {
                state.model.faturaPessoa = response;
                return state;
            });
        });
    }

    getListaTipoBaixa() {
        TipoBaixaService.getForCombo((response) => {
            this.setState({ tipoBaixas: response }, this.buildParcelas);
        });
    }

    faturar(){
        FaturaService.faturar(this.state.model, (response) => {
            this.onSave(response);
            if (!this.state.isModal) {
                if (Permissao.getInstance().getPermissao(27) && response?.data?.idFatura) {
                    this.props.navigate('/CadFatura/' + response.data.idFatura);
                } else {
                    this.props.navigate(this.state.crud.urlList);
                }

            }
        });
    }

    onSubmit(event) {

        var cobrancaSemTipoBaixa = (this.state.model.cobrancas.filter(cobranca => cobranca.cobrancaTipoBaixa.length === 0));

        if(cobrancaSemTipoBaixa.length > 0){

            var strParcelas = cobrancaSemTipoBaixa.map(cobranca => cobranca.nrParcela).join(", ");

            EventBus.dispatch("modal-confirmacao-open", {            
                config: {
                    title: "Atenção?",
                    message: <>Existe(s) Cobrança(s) sem nenhum meio de pagamento selecionado: <br/><strong>Deseja faturar sem essa opção selecionada?</strong><br/><strong>Parcela(s): {strParcelas}</strong></>,
                    btnConfirmText: "Sim, faturar agora",
                    description: "Caso seja faturado sem ao menos um meio de pagamento selecionado, o usuário não coseguirá realizar o pagamento online.",
                    style: "warning",
                    confirm: this.faturar
                }
            })
        }else{
            this.faturar()
        }
    }

    handleListOrdem(e) {

        if (parseInt(this.state.idOrdem) > 0 || this.state.idOrdens.length > 0) {

            OrdemService.getAllForFatura({
                idOrdem: this.state.idOrdem,
                idOrdens: this.state.idOrdens
            }, (response) => {
                this.setState(state => {
                    state.ordens = response;

                    state.isAcordo = state.ordens.filter(o => parseInt(o.idAcordo) > 0).length > 0
                    state.model.flJurosFuturo = state.isAcordo;

                    if(state.isAcordo && state.ordens.length > 1){
                        toast.warn("Não é possível faturar ordens de acordo em conjunto!");
                        state.ordens = [];
                        this.props.navigate("/LstOrdem");
                        return;
                    }

                    if (state.ordens.length === 0) {
                        toast.warn("Nenhuma ordem encontrada!");
                        //this.props.navigate("/LstOrdem");
                        return;
                    }

                    for (var index = 0; index < state.ordens.length; index++) {
                        state.ordens[index].flFaturar = true;
                        if (state.ordens[index].embalagens) {
                            state.embalagens = state.embalagens.concat(state.ordens[index].embalagens);
                        }
                    }

                    if (state.embalagens.length > 0) {
                        state.model.faturaTransporte.idModalidadeFrete = parseInt(state.embalagens[0]?.idModalidadeFrete) > 0 ? state.embalagens[0]?.idModalidadeFrete : state.model.faturaTransporte.idModalidadeFrete;
                        state.model.faturaTransporte.idFornecedor = parseInt(state.embalagens[0]?.idFornecedor) > 0 ? state.embalagens[0]?.idFornecedor : state.model.faturaTransporte.idFornecedor;
                    }

                    if(state.isAcordo){                        
                        let idAcordo = state.ordens.find(o => parseInt(o.idAcordo) > 0)?.idAcordo;
                        this.getAcordo(idAcordo);
                    }

                    return state;
                }, this.handleChangeOrdemItem);
            });
        } else {
            toast.warn("Nenhuma ordem enviada por parâmetro!!");
            //this.props.navigate("/LstOrdem");
            return;
        }

        this.buildParcelas();
    }

    handleChangeTipoBaixa(e, tipoBaixa, cobranca) {
        var indexOf = cobranca.cobrancaTipoBaixa.findIndex(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa));

        if (e.target.checked) {
            if (indexOf !== -1) {
                cobranca.cobrancaTipoBaixa.splice(indexOf, 1);
            }
            cobranca.cobrancaTipoBaixa.push({ idTipoBaixa: tipoBaixa.value });
        } else {
            if (indexOf !== -1) {
                cobranca.cobrancaTipoBaixa.splice(indexOf, 1);
            }
        }

        this.setState({ cobranca })
    }

    handleChangeOrdensAll(event) {
        this.setState((state) => {
            state.model.ordens = [];
            state.ordens.forEach((item, index) => {
                state.ordens[index].flFaturar = event.target.checked;
            });
            state.model.ordens = state.ordens.filter(o => o.flFaturar);
            return state;
        }, (e) => { this.buildParcelas(); this.buildTransporte() });
    }

    handleChangeOrdemItem(e) {
        this.setState((state) => {
            state.model.ordens = state.ordens.filter(o => o.flFaturar);
            return state;
        }, (e) => { this.buildParcelas(); this.buildTransporte() });
    }

    handleChangeParcelas(e) {
        this.buildParcelas();
    }

    getVlTransporte() {
        if (Boolean(this.state.model.flTransporte)) {
            var vlTransporte = parseFloat(this.state.model.faturaTransporte.vlTransporte);
            vlTransporte = vlTransporte ? vlTransporte : 0;
            return vlTransporte;
        }
        return 0;
    }

    getVlOrdens() {
        var vlOrdem = this.state.model.ordens.reduce((soma, ordem) => soma + parseFloat(ordem.vlOrdem), 0);
        vlOrdem = vlOrdem ? vlOrdem : 0;
        return vlOrdem;
    }

    getVlFatura() {
        var vlOrdem = this.getVlOrdens();
        var vlTransporte = this.getVlTransporte();
        return (vlOrdem + vlTransporte).toFixed(2);
    }

    getVlFaturaComJuros() {
        var vlFatura = parseFloat(this.getVlFatura());
        var vlJuros = parseFloat(this.state.model.vlJurosFuturo);
        return (vlFatura + vlJuros).toFixed(2);
    }

    getVlPesoBruto() {
        var vlPesoBruto = this.state.model.ordens.reduce((soma, ordem) => soma + parseFloat(ordem.vlPesoBruto), 0);
        return parseFloat(vlPesoBruto);
    }

    getVlPesoLiquido() {
        var vlPesoLiquido = this.state.model.ordens.reduce((soma, ordem) => soma + parseFloat(ordem.vlPesoLiquido), 0);
        return parseFloat(vlPesoLiquido);
    }

    getVlCobrancas() {
        return this.state.model.cobrancas.reduce((soma, cobranca) => soma + parseFloat(cobranca.vlCobranca), 0).toFixed(2);
    }

    getVlJuros() {
        return this.state.model.cobrancas.reduce((soma, cobranca) => soma + parseFloat(cobranca.vlJuros), 0).toFixed(2);
    }

    getVlTransporteOrdens() {
        var vlTransporte = this.state.model.ordens.reduce((soma, ordem) => soma + parseFloat(ordem.vlTransporte), 0);
        return parseFloat(vlTransporte);
    }

    getVlEmbalagens() {
        var vlTransporte = this.state.embalagens.reduce((soma, ordem) => soma + parseFloat(ordem.vlTransporte), 0);
        return parseFloat(vlTransporte);
    }

    getVlPesoBrutoEmbalagens() {
        var vlPesoBruto = this.state.embalagens.reduce((soma, ordem) => soma + parseFloat(ordem.vlPesoBrutoConteudo), 0);
        return parseFloat(vlPesoBruto);
    }

    getVlPesoLiquidoEmbalagens() {
        var vlPesoBruto = this.state.embalagens.reduce((soma, ordem) => soma + parseFloat(ordem.vlPesoBrutoLiquido), 0);
        return parseFloat(vlPesoBruto);
    }

    buildTransporte() {
        this.setState(state => {
            state.model.faturaTransporte.vlPesoBruto = this.getVlPesoBruto();
            state.model.faturaTransporte.vlPesoLiquido = this.getVlPesoLiquido();
            state.model.faturaTransporte.vlTransporte = this.getVlTransporteOrdens();
            return state;
        });
    }

    buildTipoBaixa(e, tipoBaixa) {
        this.state.model.cobrancas.forEach(cobranca => {
            this.handleChangeTipoBaixa(e, tipoBaixa, cobranca);
        });

        this.setState(state => {
            var indexOf = state.tipoBaixasSelecao.findIndex(i => parseInt(tipoBaixa.value) === parseInt(i.value));

            if (e.target.checked) {
                if (indexOf !== -1) {
                    state.tipoBaixasSelecao.splice(indexOf, 1);
                }
                state.tipoBaixasSelecao.push(tipoBaixa);
            } else {
                if (indexOf !== -1) {
                    state.tipoBaixasSelecao.splice(indexOf, 1);
                }
            }
            return state;
        });
    }

    getJurosFuturos(){
        
    }

    getJurosParcela(vlFatura, dtVencimento){
        let nrDiasJuros = Util.dateDiffNow(dtVencimento);
        nrDiasJuros = nrDiasJuros > 0 ? nrDiasJuros : 0;        
        let vlJuros = (((this.state.model.pcJurosFuturo / 30)/100) * nrDiasJuros) * vlFatura;
        vlJuros = parseFloat(vlJuros.toFixed(2));
        return vlJuros;
    }

    buildParcelas(buildVencimento = true) {
        this.setState(state => {
            state.tipoBaixasSelecao = [];
            state.model.vlJurosFuturo = 0.0;
            
            var vlFatura = parseFloat(this.getVlFatura());
            var vlOrdens = parseFloat(this.getVlOrdens());
            
            if(buildVencimento){
                state.model.cobrancas = [];

                var dtVencimento = null;
                for (let nrParcela = 1; nrParcela <= state.model.nrParcelas; nrParcela++) {
                    try {
                        dtVencimento = new Date(state.model.dtVencimentoStart);
                        dtVencimento.setDate(dtVencimento.getDate() + (state.model.nrIntervalo * (nrParcela - 1)));
                        dtVencimento = dtVencimento.toISOString().slice(0, 10);
                    } catch (e) {
                        dtVencimento = new Date();
                        dtVencimento.setDate(dtVencimento.getDate() + (state.model.nrIntervalo * (nrParcela - 1)));
                        dtVencimento = dtVencimento.toISOString().slice(0, 10);
                    }

                    state.model.cobrancas.push({
                        dtVencimento: dtVencimento,
                        vlJuros : 0
                    });
                }
            }

            var vlJuros = 0.0;
            if(this.state.model.flJurosFuturo){
                for (let nrParcela = 1; nrParcela <= state.model.nrParcelas; nrParcela++) {                
                    vlJuros = this.getJurosParcela(vlFatura, state.model.cobrancas[nrParcela - 1].dtVencimento);
                    state.model.cobrancas[nrParcela - 1].vlJuros =vlJuros;
                    state.model.vlJurosFuturo+=vlJuros;
                }
            }

            vlFatura+= parseFloat(state.model.vlJurosFuturo);
            vlOrdens+= parseFloat(state.model.vlJurosFuturo);

            if (!parseInt(state.model.nrParcelas) > 0) {
                return state;
            }
            
            var vlCobranca = 0.0;
            var vlValor = 0.0;
            var difValorCobranca = 0.0;

            if (Boolean(state.model.flTransporte) && parseInt(state.model.nrParcelas) > 1 && state.model.cdCalculoFrente === "P") {

                if (state.model.cdComposicaoParcela === "F") {
                    vlCobranca = (vlOrdens / (parseInt(state.model.nrParcelas) - 1));
                } else {
                    vlCobranca = (vlOrdens / parseInt(state.model.nrParcelas));
                }

            } else {
                vlCobranca = (vlFatura / parseInt(state.model.nrParcelas));
            }

            vlCobranca = parseFloat(vlCobranca.toFixed(2));

            var vlTotal = 0.0;
            for (var nrParcela = 1; nrParcela <= state.model.nrParcelas; nrParcela++) {
                
                if (Boolean(state.model.flTransporte) && parseInt(state.model.nrParcelas) > 1 && parseInt(nrParcela) === 1 && state.model.cdCalculoFrente === "P") {
                    if (state.model.cdComposicaoParcela === "F") {
                        vlValor = this.getVlTransporte();
                    } else {
                        vlValor = vlCobranca + this.getVlTransporte();
                    }
                } else {
                    vlValor = vlCobranca;
                }

                vlValor = parseFloat(vlValor.toFixed(2));
                vlTotal += vlValor;
                
                state.model.cobrancas[nrParcela - 1].nrParcela = nrParcela;                
                state.model.cobrancas[nrParcela - 1].vlCobranca = vlValor;
                state.model.cobrancas[nrParcela - 1].flMulta = true;
                state.model.cobrancas[nrParcela - 1].flJuros = true;
                state.model.cobrancas[nrParcela - 1].cobrancaTipoBaixa = [];
            }
            
            difValorCobranca = vlFatura - parseFloat(vlTotal.toFixed(2));
            difValorCobranca = parseFloat(difValorCobranca.toFixed(2));
            state.model.cobrancas[state.model.cobrancas.length - 1].vlCobranca = state.model.cobrancas[state.model.cobrancas.length - 1].vlCobranca + difValorCobranca;

            return state;
        });
    }

    handleChangeValorParcela(nrParcelaLiha) {

        if (nrParcelaLiha < this.state.model.nrParcelas) {

            this.setState(state => {
                var vlSomaCobranca = 0;
                var vlCobranca = 0;
                var nrParcelasRestantes = (this.state.model.nrParcelas - nrParcelaLiha);

                for (let nrParcela = 1; nrParcela <= nrParcelaLiha; nrParcela++) {
                    vlSomaCobranca += parseFloat(state.model.cobrancas[nrParcela - 1].vlCobranca);
                }

                vlCobranca = parseFloat(((this.getVlFaturaComJuros() - vlSomaCobranca) / nrParcelasRestantes).toFixed(2));

                var difValorCobranca = this.getVlFaturaComJuros() - (vlSomaCobranca + parseFloat((vlCobranca * nrParcelasRestantes).toFixed(2)));
                difValorCobranca = parseFloat(difValorCobranca.toFixed(2));

                for (let nrParcela = (nrParcelaLiha + 1); nrParcela < this.state.model.nrParcelas; nrParcela++) {
                    state.model.cobrancas[nrParcela - 1].vlCobranca = vlCobranca;
                }

                state.model.cobrancas[this.state.model.nrParcelas - 1].vlCobranca = vlCobranca + difValorCobranca;

                return state;
            });
        }

    }

    handleChangeVencimentoParcela(index) {        
        this.buildParcelas(false);        
    }   

    handleChangeCobrancaAll(event, prop) {
        this.setState((state) => {
            this.state.model.cobrancas.forEach((item, index) => {
                state.model.cobrancas[index][prop] = event.target.checked;
            });
            return state;
        });
    }

    handleOpenModalFatura(ordem) {
        EventBus.dispatch("modal-add", {
            title: 'Dados da Ordem',
            sizeModal: 'modal-xl',
            fullScreen : true,
            config: {
                isModal: true,
                idOrdem: ordem.idOrdem
            },
            form: FormOrdem,
            onClose: () => {
                this.handleListOrdem();
            },
            onSave: () => {
                this.handleListOrdem();
            }
        });
    }

    handleClickDMais(dias) {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000;
        var dtVencimentoStart = new Date();
        dtVencimentoStart.setDate(dtVencimentoStart.getDate() + dias);

        this.setState((state) => {
            state.model.dtVencimentoStart = new Date(dtVencimentoStart - tzoffset).toISOString().slice(0, 10);
            return state;
        }, this.handleChangeParcelas);
    }

    handleClickCotacao(item, index) {

        var ordemCliente = this.state.model.ordens[0];

        EventBus.dispatch("modal-add", {
            title: 'Cotação de Frete',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                cdTipoPessoaDestinatario: ordemCliente?.cdTipoPessoaCliente,
                cdCpfDestinatario: ordemCliente?.cpfCliente,
                cdCnpjDestinatario: ordemCliente?.cnpjCliente,
                idProdutoServicoTipoObjeto: item.idProdutoServicoTipoObjeto,
                idFornecedor: this.state.model.faturaTransporte.idFornecedor,
                idModalidadeFrete: this.state.model.faturaTransporte.idModalidadeFrete,
                vlPesoBruto: item.vlPesoBrutoConteudo > 0 ? item.vlPesoBrutoConteudo : this.state.model.faturaTransporte.vlPesoBruto,
                vlPesoLiquido: item.vlPesoLiquidoConteudo > 0 ? item.vlPesoLiquidoConteudo : this.state.model.faturaTransporte.vlPesoLiquido,
                nrQuantidade: item.nrQuantidade,
                vlLargura: item.vlLargura,
                vlAltura: item.vlAltura,
                vlComprimento: item.vlComprimento,
                vlDiametro: item.vlDiametro,
                vlDeclarado: this.getVlOrdens(),
                cdCepDestino: this.state.model.ordens.length > 0 ? this.state.model.ordens[0].cdCepOrigem : ""
            },
            form: FormCotacaoFrete,
            onClose: () => {

            },
            onSave: (cotacao) => {
                OrdemService.updateItem(item.idOrdemItem, {
                    idOrdemItem: item.idOrdemItem,
                    vlTransporte: cotacao.vlTransporte,
                    idFornecedorServico: cotacao.idFornecedorServico,
                    flAvisoRecebimento: cotacao.flAvisoRecebimento,
                    flMaoPropria: cotacao.flMaoPropria,
                    vlDeclarado: cotacao.vlDeclarado
                }, response => {
                    this.setState((state) => {
                        state.embalagens[index].idFornecedorServico = cotacao.idFornecedorServico;
                        state.embalagens[index].nomeFornecedorServico = cotacao.nomeFornecedorServico;
                        state.embalagens[index].nomeFornecedor = cotacao.nomeFornecedor;
                        state.embalagens[index].nomeTipoFrete = cotacao.nomeTipoFrete;
                        state.embalagens[index].nomeModalidadeFrete = cotacao.nomeModalidadeFrete;
                        state.embalagens[index].vlTransporte = cotacao.vlTransporte;
                        state.embalagens[index].flAvisoRecebimento = cotacao.flAvisoRecebimento;
                        state.embalagens[index].flMaoPropria = cotacao.flMaoPropria;
                        state.embalagens[index].vlDeclarado = cotacao.vlDeclarado;

                        state.model.faturaTransporte.idFornecedor = cotacao.idFornecedor;
                        state.model.faturaTransporte.idModalidadeFrete = cotacao.idModalidadeFrete;
                        state.model.faturaTransporte.vlTransporte = this.getVlEmbalagens();
                        return state;
                    }, this.handleChangeParcelas);
                })
            }
        });
    }

    handleClickExcluirFornecedorServico(item, index) {
        OrdemService.updateItem(item.idOrdemItem, { idOrdemItem: item.idOrdemItem, vlTransporte: 0, idFornecedorServico: null }, response => {
            this.setState((state) => {
                state.embalagens[index].vlTransporte = 0;
                state.embalagens[index].idFornecedorServico = "";
                state.embalagens[index].nomeFornecedor = "";
                state.embalagens[index].nomeTipoFrete = "";
                state.embalagens[index].nomeFornecedorServico = "";
                state.embalagens[index].nomeModalidadeFrete = "";
                state.embalagens[index].flAvisoRecebimento = false;
                state.embalagens[index].flMaoPropria = false;
                state.embalagens[index].vlDeclarado = 0;

                state.model.faturaTransporte.vlTransporte = this.getVlEmbalagens();
                //state.model.faturaTransporte.vlPesoBruto = this.getVlPesoBrutoEmbalagens();
                //state.model.faturaTransporte.vlPesoLiquido = this.getVlPesoBrutoEmbalagens();
                return state;
            });
        })

    }

    handleChangeFornecedor() {
        FornecedorService.getOneById(this.state.model.faturaTransporte.idFornecedor, response => {
            this.setState({ fornecedor: response });
        })


    }

    handleChangeCep() {
        if (Util.stringToNumber(this.state.model.faturaPessoa.cep).length === 8) {
            EnderecoService.getOneByCep(this.state.model.faturaPessoa.cep, (response) => {
                this.setState((state) => {
                    state.model.faturaPessoa.endereco = response?.logradouro ? response.logradouro : "";
                    state.model.faturaPessoa.bairro = response?.bairro ? response.bairro : "";
                    state.model.faturaPessoa.complemento = response?.complemento ? response.complemento : "";
                    state.model.faturaPessoa.numero = "";

                    if (response?.idEstado) {
                        state.model.faturaPessoa.idEstado = response.idEstado;
                    }

                    if (response?.idCidade) {
                        state.model.faturaPessoa.idCidade = response.idCidade;
                    }

                    return state;
                });
            });

        }
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex d-print-none">
                <BtnSubmit className="btn-primary"> Faturar </BtnSubmit>
                <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton>
            </div>
        );

    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link" id="nav-dados-tab" data-bs-toggle="tab" data-bs-target="#nav-dados" type="button" role="tab" aria-controls="nav-dados" aria-selected="true">
                        Orden(s)
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-pessoa-tab" data-bs-toggle="tab" data-bs-target="#nav-pessoa" type="button" role="tab" aria-controls="nav-pessoa" aria-selected="false">
                        Pessoa
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link active" id="nav-parcelas-tab" data-bs-toggle="tab" data-bs-target="#nav-parcelas" type="button" role="tab" aria-controls="nav-parcelas" aria-selected="false">
                        Parcelas
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-transporte-tab" data-bs-toggle="tab" data-bs-target="#nav-transporte" type="button" role="tab" aria-controls="nav-transporte" aria-selected="false">
                        Transporte
                    </button>
                </li>
            </ul>
        );
    }

    getRenderFields() {
        return (
            <>
                <div className="tab-content mx-2 my-3" id="nav-tabContent">

                    <div className="tab-pane fade" id="nav-dados" role="tabpanel" aria-labelledby="nav-dados-tab">
                        <div className="row mb-2 g-3">
                            <div className="table-responsive">
                                <table className="table table-sm table-striped">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="text-center">
                                                <InputCheckSwitch name="todos" onChange={this.handleChangeOrdensAll} checked={this.state.model.ordens.length === this.state.ordens.length} />
                                            </th>
                                            <th className="text-center"> Ordem </th>
                                            <th className="col-1 text-center"> Data </th>
                                            <th className="col-2"> Tipo </th>
                                            <th className="col-4"> Representante </th>
                                            <th className="col-4"> Usuário </th>
                                            <th className="col-1 text-end"> Valor </th>
                                            <th className="text-end">&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.ordens.map((item, index) => (
                                            <tr key={index} className="tr-borderless">
                                                <td>
                                                    <InputCheckSwitch sizing="md" name="flFaturar" checked={Boolean(item.flFaturar)} onChange={e => { this.handleChange(e, this.handleChangeOrdemItem, this.state.ordens[index]) }} />
                                                </td>
                                                <td className="text-center"> #{item.idOrdem} </td>
                                                <td className="text-center"> {Util.date2Br(item.dataOrdem)} </td>
                                                <td> {item.nomeTipoProdutoServico} </td>
                                                <td> {item.nomeRepresentante} </td>
                                                <td> {item.nomeUsuario} </td>
                                                <td className="text-end"> {Util.floatToReais(item.vlOrdem)} </td>
                                                <td>
                                                    <BtnButton className="btn-primary btn-sm text-nowrap" onClick={(e) => { this.handleOpenModalFatura(item) }}> Ver Ordem </BtnButton>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="nav-pessoa" role="tabpanel" aria-labelledby="nav-pessoa-tab">

                        <div className="fw-bold mb-3 border-dark border-bottom">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                            </svg> Dados do Cliente
                        </div>

                        <div className="row mb-3 g-3">
                            <BtnGroup required={true} lg={4} className="w-100" name="cdTipoPessoa" value={this.state.model.faturaPessoa.cdTipoPessoa} label="Tipo Pessoa" options={this.state.tipoPessoa} onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} />

                            {this.state.model.faturaPessoa.cdTipoPessoa === "F" ? <InputCpf label="CPF" required={true} lg={3} name="cpf" value={this.state.model.faturaPessoa.cpf ? this.state.model.faturaPessoa.cpf : ""} onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} /> : <></>}
                            {this.state.model.faturaPessoa.cdTipoPessoa === "J" ? <InputCnpj label="CNPJ" required={true} lg={3} name="cnpj" value={this.state.model.faturaPessoa.cnpj ? this.state.model.faturaPessoa.cnpj : ""} onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} /> : <></>}

                            <InputText label={this.state.model.faturaPessoa.cdTipoPessoa === "J" ? "Razão Social" : "Nome completo"} required lg={5} value={this.state.model.faturaPessoa.nome} name="nome" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} />

                            {this.state.model.faturaPessoa.cdTipoPessoa === "J" ?
                                <InputText label="Inscrição Estadual" md={3} value={this.state.model.faturaPessoa.inscricaoEstadual} name="inscricaoEstadual" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} maxLength={150} />
                                : <></>}

                            <SelectDDI showBtns={false} lg={2} label="DDI" required placeholder="Selecione" name="ddi" value={this.state.model.faturaPessoa.ddi} onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} />
                            {this.state.model.faturaPessoa.ddi.toString() === "55" ?
                                <InputTelCelular label="Celular" required lg={3} value={this.state.model.faturaPessoa.celular} name="celular" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} />
                                :
                                <InputText label="Celular" required lg={3} value={this.state.model.faturaPessoa.celular} name="celular" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} />
                            }

                            <InputEmail label="E-mail" lg={4} name="email" value={this.state.model.faturaPessoa.email} onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} required={true} />
                        </div>

                        <div className="fw-bold mb-3 border-dark border-bottom pt-3">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mailbox" viewBox="0 0 16 16">
                                <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                                <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
                            </svg> Dados de Endereço
                        </div>

                        <div className="row mb-3 g-3">
                            <InputCep label="CEP" lg={3} value={this.state.model.faturaPessoa.cep} name="cep" onChange={e => this.handleChange(e, this.handleChangeCep, this.state.model.faturaPessoa)} required={true} />
                            <InputText label="Logradouro" lg={6} value={this.state.model.faturaPessoa.endereco} name="endereco" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} maxLength={100} required={true} />
                            <InputText label="Número" lg={3} value={this.state.model.faturaPessoa.numero} name="numero" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} maxLength={100} required={true} />

                            <InputText label="Complemento" lg={6} value={this.state.model.faturaPessoa.complemento} name="complemento" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} maxLength={100} />
                            <InputText label="Bairro" lg={6} value={this.state.model.faturaPessoa.bairro} name="bairro" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} maxLength={150} required={true} />

                            <SelectPais label="Nome do País" required placeholder="Selecione o País" name="idPais" md={3} value={this.state.model.faturaPessoa.idPais} onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} />
                            <SelectEstadoByPais showBtnNewForm={false} lg={4} required idPais={this.state.model.faturaPessoa.idPais} label="Estado" value={this.state.model.faturaPessoa.idEstado} placeholder="Selecione o Estado" name="idEstado" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} />
                            <SelectCidadeByEstado showBtnNewForm={false} lg={5} required idPais={this.state.model.faturaPessoa.idPais} idEstado={this.state.model.faturaPessoa.idEstado} label="Cidade" value={this.state.model.faturaPessoa.idCidade} placeholder="Selecione a Cidade" name="idCidade" onChange={e => this.handleChange(e, false, this.state.model.faturaPessoa)} />
                        </div>

                    </div>

                    <div className="tab-pane fade show active" id="nav-parcelas" role="tabpanel" aria-labelledby="nav-parcelas-tab">

                        <div className="d-print-none pb-3">
                            <InputCheckSwitch label="Permitir parcelamento no cartão em cobranças parcelas?" name="flParcelamento" checked={Boolean(this.state.model.flParcelamento)} onChange={this.handleChange} />
                            <InputCheckSwitch label="Calcular Juros Futuros?" name="flJurosFuturo" checked={Boolean(this.state.model.flJurosFuturo)} onChange={e => this.handleChange(e, this.buildParcelas)} />
                            <InputCheckSwitch label="Cobrar Taxas do Cartão de Crédito?" name="flTaxaCartao" checked={Boolean(this.state.model.flTaxaCartao)} onChange={this.handleChange} />
                        </div>

                        <h6 className="border-dark border-bottom mb-2 pb-1 fw-bold"> Configuração do Pagamento </h6>

                        {Boolean(this.state.model.flTransporte) && parseInt(this.state.model.nrParcelas) > 1 ?
                            <div className="row mb-3 mt-0">
                                <BtnGroup required={true} md={4} sizing="sm" className="w-100" name="cdCalculoFrente" value={this.state.model.cdCalculoFrente} label="Calculo do Frete" options={this.state.calculoFrete} onChange={e => this.handleChange(e, this.handleChangeParcelas)} />
                                {this.state.model.cdCalculoFrente === "P" ?
                                    <BtnGroup required={true} md={4} sizing="sm" className="w-100" name="cdComposicaoParcela" value={this.state.model.cdComposicaoParcela} label="Composição Parcela 1" options={this.state.composicaoParcela} onChange={e => this.handleChange(e, this.handleChangeParcelas)} />
                                    : <></>}
                            </div>
                            : <></>}


                        <div className="row mb-1 g-3">
                            <InputNumber className="text-center" label="Número de Parcelas" name="nrParcelas" md={2} value={this.state.model.nrParcelas} required={true} onChange={e => this.handleChange(e, this.handleChangeParcelas)} min={1} max={12} />
                            <InputNumber className="text-center" label="Intervalo de Dias" name="nrIntervalo" md={2} value={this.state.model.nrIntervalo} required={true} onChange={e => this.handleChange(e, this.handleChangeParcelas)} min={0} max={90} />
                            <InputDate className="text-center" required label="Vencimento Inicial" md={2} value={this.state.model.dtVencimentoStart} name="dtVencimentoStart" onChange={e => { this.handleChange(e, this.handleChangeParcelas) }} />
                            <InputText className="text-end text-primary fw-bold" label="Valor Total" md={2} value={Util.floatToReais(this.getVlFatura())} readOnly opcionalLabel={false} />
                            <InputNumber className="text-center" disabled={!this.state.model.flJurosFuturo} label="Juros ao Mês" name="pcJurosFuturo" md={2} value={this.state.model.pcJurosFuturo} required={true} onChange={e => this.handleChange(e, this.buildParcelas)} min={1} max={100} />
                            <InputText className="text-end text-danger fw-bold" label="Valor dos Juros" md={2} value={Util.floatToReais(this.state.model.vlJurosFuturo)} readOnly opcionalLabel={false} />
                        </div>

                        <div className="d-flex gap-2 justify-content-center pt-2 d-print-none">
                            <BtnButton className="btn-success fw-bold fs-7kkk btn-sm pt-0 pb-0" onClick={(e) => { this.handleClickDMais(1) }}> D+1 </BtnButton>
                            <BtnButton className="btn-success fw-bold fs-7kkk btn-sm pt-0 pb-0" onClick={(e) => { this.handleClickDMais(2) }}> D+2 </BtnButton>
                            <BtnButton className="btn-warning fw-bold fs-7kkk btn-sm pt-0 pb-0" onClick={(e) => { this.handleClickDMais(5) }}> D+5 </BtnButton>
                            <BtnButton className="btn-warning fw-bold fs-7kkk btn-sm pt-0 pb-0" onClick={(e) => { this.handleClickDMais(10) }}> D+10 </BtnButton>
                            <BtnButton className="btn-danger fw-bold fs-7kkk btn-sm pt-0 pb-0" onClick={(e) => { this.handleClickDMais(15) }}> D+15 </BtnButton>
                            <BtnButton className="btn-danger fw-bold fs-7kkk btn-sm pt-0 pb-0" onClick={(e) => { this.handleClickDMais(30) }}> D+30 </BtnButton>
                        </div>

                        { parseInt(this.state.model.nrParcelas) > 1 ?
                        <div className="d-print-none">
                            <h6 className="border-dark border-bottom pt-0 mb-2 pb-1 fw-bold"> Opções Pagamento <small className="fw-normal fst-italic"> (marcar em todas as parcelas) </small> </h6>

                            <div className="pb-2">
                                {this.state.tipoBaixas.filter(t => parseInt(t.flFaturamento) === 1 &&
                                    (                                                            
                                        (parseInt(this.state.model.nrParcelas) === 1)
                                        ||
                                        (Boolean(this.state.model.flParcelamento) || (parseInt(this.state.model.nrParcelas) > 1 && parseInt(t.nrParcela) === 1))
                                    )
                                ).map((tipoBaixa, indexTipoBaixa) => (
                                    <InputCheckSwitch key={indexTipoBaixa} sizing="md" label={tipoBaixa.label} checked={this.state.tipoBaixasSelecao.find(i => parseInt(tipoBaixa.value) === parseInt(i.value)) !== undefined} name="flTipoBaixa" onChange={e => { this.buildTipoBaixa(e, tipoBaixa) }} />
                                ))}
                            </div>
                        </div> : <></>}

                        <h6 className="border-dark border-bottom pt-0 mb-2 pb-1 fw-bold"> Cobranças </h6>

                        <div className="row mb-1 g-3">
                            <div className="table-responsive">
                                <table className="table table-sm caption-top table-striped">
                                    <caption className="text-end">
                                    </caption>
                                    <thead className="table-light">
                                        <tr className="border-bottom border-dark">
                                            <th className="col-2"> Pagamento </th>
                                            <th className="col-2">
                                                {Permissao.getInstance().getPermissao(59) ?
                                                    <InputCheckSwitch name="todos" onChange={e => this.handleChangeCobrancaAll(e, 'flMulta')} checked={this.state.model.cobrancas.filter(c => c.flMulta).length === this.state.model.cobrancas.length} />
                                                    : <></>}
                                            </th>
                                            <th className="col-2">
                                                {Permissao.getInstance().getPermissao(59) ?
                                                    <InputCheckSwitch name="todos" onChange={e => this.handleChangeCobrancaAll(e, 'flJuros')} checked={this.state.model.cobrancas.filter(c => c.flJuros).length === this.state.model.cobrancas.length} />
                                                    : <></>}
                                            </th>
                                            <th> Parcela </th>
                                            <th className="col-2 text-center"> Vencimento </th>
                                            <th className="col-2 text-center"> Juros </th>
                                            <th className="col-2 text-center"> Valor </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.model.cobrancas.map((cobranca, index) => (
                                            <tr key={index} className="tr-borderless border-bottom border-dark">
                                                <td>
                                                    <div className="d-print-none">
                                                        {this.state.tipoBaixas.filter(t => parseInt(t.flFaturamento) === 1 &&
                                                            (                                                            
                                                                (parseInt(this.state.model.nrParcelas) === 1)
                                                                ||
                                                                (Boolean(this.state.model.flParcelamento) || (parseInt(this.state.model.nrParcelas) > 1 && parseInt(t.nrParcela) === 1))
                                                            )
                                                        ).map((tipoBaixa, indexTipoBaixa) => (
                                                            <InputCheckSwitch key={indexTipoBaixa} sizing="md" label={tipoBaixa.label} name="flTipoBaixa" checked={cobranca.cobrancaTipoBaixa.find(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa)) !== undefined} onChange={e => { this.handleChangeTipoBaixa(e, tipoBaixa, cobranca) }} />
                                                        ))}
                                                    </div>
                                                </td>
                                                <td className="align-middle">
                                                    {Permissao.getInstance().getPermissao(59) ?
                                                        <InputCheckSwitch sizing="md" label="Multa?" name="flMulta" checked={Boolean(cobranca.flMulta)} onChange={e => { this.handleChange(e, false, this.state.model.cobrancas[index]) }} />
                                                        : <></>}
                                                </td>
                                                <td className="align-middle">
                                                    {Permissao.getInstance().getPermissao(59) ?
                                                        <InputCheckSwitch sizing="md" label="Juros?" name="flJuros" checked={Boolean(cobranca.flJuros)} onChange={e => { this.handleChange(e, false, this.state.model.cobrancas[index]) }} />
                                                        : <></>}
                                                </td>
                                                <td className="text-center align-middle">
                                                    {cobranca.nrParcela}
                                                </td>
                                                <td className="align-middle">
                                                    <InputDate className="text-center" required value={cobranca.dtVencimento} name="dtVencimento" onChange={e => { this.handleChange(e, this.handleChangeVencimentoParcela(index), this.state.model.cobrancas[index]) }} />
                                                </td>
                                                <td className="align-middle">
                                                    <InputValorReais disabled className="text-end text-danger fw-bold" required value={cobranca.vlJuros} name="vlJuros" />
                                                </td>
                                                <td className="align-middle">
                                                    <InputValorReais className="text-end text-primary fw-bold" required value={cobranca.vlCobranca} name="vlCobranca" onKeyUp={e => this.handleChangeValorParcela(cobranca.nrParcela)} onChange={e => { this.handleChange(e, false, this.state.model.cobrancas[index]) }} />
                                                </td>
                                            </tr>
                                        ))}
                                    
                                    
                                        <tr>
                                            <td colSpan={5}>
                                            </td>
                                            <td className="text-end text-danger fw-bold">
                                                {Util.floatToReais(this.getVlJuros())}
                                            </td>
                                            <td className="text-end text-primary fw-bold">
                                                {Util.floatToReais(this.getVlCobrancas())}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={6}>
                                            </td>
                                            <td className="text-end text-danger fw-bold">
                                                {Util.floatToReais(this.getVlCobrancas() - this.getVlFatura())}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    <div className="tab-pane fade" id="nav-transporte" role="tabpanel" aria-labelledby="nav-transporte-tab">
                        <div className={`row mx-1 mb-3 g-1`}>
                            <InputCheckSwitch label="Registrar Transporte?" name="flTransporte" checked={Boolean(this.state.model.flTransporte)} onChange={e => { this.handleChange(e, this.buildParcelas) }} />
                        </div>
                        {Boolean(this.state.model.flTransporte) ?
                            <>
                                <div className="row mb-2 g-3">
                                    <SelectFornecedorByAreaAtuacao label="Transportadora" required={true} showBtnNewForm={false} md={6} idAreaAtuacao={1} value={this.state.model.faturaTransporte.idFornecedor} placeholder="Selecione a Transportadora" name="idFornecedor" onChange={e => { this.handleChange(e, this.handleChangeFornecedor, this.state.model.faturaTransporte) }} />
                                    <SelectModalidadeFrete label="Modalidade do Frete" required={true} showBtnNewForm={false} md={6} value={this.state.model.faturaTransporte.idModalidadeFrete} placeholder="Selecione a Modalidade do Frete" name="idModalidadeFrete" onChange={e => { this.handleChange(e, false, this.state.model.faturaTransporte) }} />

                                    <InputValorReais label="Valor do Transporte" md={4} className="text-end fw-bold text-success" required value={this.state.model.faturaTransporte.vlTransporte} name="vlTransporte" onChange={e => { this.handleChange(e, this.buildParcelas, this.state.model.faturaTransporte) }} />
                                    <InputPeso className="text-end" md={4} label='Peso Bruto em KG' required value={this.state.model.faturaTransporte.vlPesoBruto} name="vlPesoBruto" onChange={e => { this.handleChange(e, false, this.state.model.faturaTransporte) }} />
                                    <InputPeso className="text-end" md={4} label='Peso Líquido em KG' required value={this.state.model.faturaTransporte.vlPesoLiquido} name="vlPesoLiquido" onChange={e => { this.handleChange(e, false, this.state.model.faturaTransporte) }} />
                                </div>
                                {parseInt(this.state.fornecedor.flTemRastreamento) === 1 ?
                                    <div className="row mb-3 g-1">
                                        <InputCheckSwitch label="Gerar código de rastreio?" name="flGerarRastreamento" checked={Boolean(this.state.model.faturaTransporte.flGerarRastreamento)} onChange={e => { this.handleChange(e, false, this.state.model.faturaTransporte) }} />
                                    </div>
                                    : <></>}

                                {this.state.embalagens.length > 0 ?
                                    <>
                                        <h6 className="border-bottom border-secondary text-secondary"> Embalagens  </h6>

                                        <ol className="list-group">
                                            {this.state.embalagens.map((item, index) => (
                                                <li className={`list-group-item d-flex justify-content-between align-items-start ${index % 2 === 0 ? "bg-light " : ""}`} key={index}>
                                                    <div className="row pt-2">
                                                        <InputText className="fw-bold" disabled={true} sizing="sm" label="Embalagem" lg={9} value={item.nomeProdutoServico} opcionalLabel={false} />
                                                        <InputText className="text-center" disabled={true} sizing="sm" label="Quantidade" lg={3} value={item.nrQuantidade} opcionalLabel={false} />

                                                        <InputPeso disabled={true} className="text-end" lg={2} sizing="sm" label='Peso Bruto' value={item.vlPesoBrutoConteudo} opcionalLabel={false} />
                                                        <InputPeso disabled={true} className="text-end" lg={2} sizing="sm" label='Peso Líquido' value={item.vlPesoLiquidoConteudo} opcionalLabel={false} />
                                                        <InputText className="text-center" disabled={true} sizing="sm" label="Largura (cm)" lg={2} value={item.vlLargura} opcionalLabel={false} />
                                                        <InputText className="text-center" disabled={true} sizing="sm" label="Altura (cm)" lg={2} value={item.vlAltura} opcionalLabel={false} />
                                                        <InputText className="text-center" disabled={true} sizing="sm" label="Comprimento (cm)" lg={2} value={item.vlComprimento} opcionalLabel={false} />
                                                        <InputText className="text-center" disabled={true} sizing="sm" label="Diâmetro (cm)" lg={2} value={item.vlDiametro} opcionalLabel={false} />

                                                        <InputText className={`${Boolean(item.flAvisoRecebimento) ? "text-success" : "text-danger"} text-center`} disabled={true} sizing="sm" label="Aviso Recebimento" lg={2} value={Boolean(item.flAvisoRecebimento) ? "SIM" : "NÃO"} opcionalLabel={false} />
                                                        <InputText className={`${Boolean(item.flMaoPropria) ? "text-success" : "text-danger"} text-center`} disabled={true} sizing="sm" label="Mão própria" lg={2} value={Boolean(item.flMaoPropria) ? "SIM" : "NÃO"} opcionalLabel={false} />
                                                        <InputText className="text-success" disabled={true} sizing="sm" label="Frete" lg={4} value={item.nomeTipoFrete ? item.nomeTipoFrete : " "} opcionalLabel={false} />
                                                        <InputValorReais label="Valor Declarado" lg={2} className="text-end fw-bold text-success" sizing="sm" disabled={true} value={item.vlDeclarado} name="vlDeclarado" opcionalLabel={false} />
                                                        <InputValorReais label="Valor de Transporte" lg={2} className="text-end fw-bold text-success" sizing="sm" disabled={true} value={item.vlTransporte} name="vlTransporte" opcionalLabel={false} />

                                                        <InputText className="text-success fw-bold" disabled={true} sizing="sm" label="Fornecedor" lg={4} value={item.nomeFornecedor ? item.nomeFornecedor : " "} opcionalLabel={false} />
                                                        <InputText className="text-success" disabled={true} sizing="sm" label="Serviço" lg={3} value={item.nomeFornecedorServico ? item.nomeFornecedorServico : " "} opcionalLabel={false} />
                                                        <InputText className="text-success" disabled={true} sizing="sm" label="Transporte" lg={4} value={item.nomeModalidadeFrete ? item.nomeModalidadeFrete : " "} opcionalLabel={false} />

                                                        <div className="col-lg-1 d-flex align-items-end bd-highlight">
                                                            {Permissao.getInstance().getPermissao(82) ?
                                                                <React.Fragment>
                                                                    {parseInt(item.idFornecedorServico) > 0 ?
                                                                        <BtnButton className="btn-outline-danger text-nowrap btn-sm" onClick={(e) => { this.handleClickExcluirFornecedorServico(item, index) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                                                                                <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                                            </svg> Limpar
                                                                        </BtnButton>
                                                                        :
                                                                        <BtnButton className="btn-outline-success text-nowrap btn-sm" onClick={(e) => { this.handleClickCotacao(item, index) }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                                                                                <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                                            </svg> Cotar
                                                                        </BtnButton>
                                                                    }
                                                                </React.Fragment>

                                                                : <></>}
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ol>

                                    </> : <></>}
                            </> : <></>}

                    </div>

                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <FormFaturar {...props} navigate={navigate} params={params} location={location} />
}

export default With