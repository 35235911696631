import React from "react";
import { Link } from "react-router-dom";
import MyComponent from "../../custom/MyComponent";
import BtnButton from "../elements/BtnButton";
import BtnSubmit from "../elements/BtnSubmit";

export default class CrudForm extends MyComponent {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getBtns = this.getBtns.bind(this);
        this.getCardFooter = this.getCardFooter.bind(this);

        this.getRenderFields = this.getRenderFields.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getById = this.getById.bind(this);

        this.onSubmit = this.onSubmit.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onSave = props.onSave || (() => { });
        this.onClose = props.onClose || (() => { });

        this.state.modelOld = {};

        this.state.model = {};
        this.state.crud = {
            service: null,            
            labels: {
                title: '',
            },
            urlList: '',
        };
        this.state.validated = false;
        this.state.isModal = props.isModal || false;
        this.state.isCard = false;

        this.state.isEdit = false;
        this.state.isLoading = false;
        
        this.state.isCopy = false;
        this.state.container = true;

        this.state.showBtnSalvar = props.showBtnSalvar === undefined ? true : props.showBtnSalvar;
        this.state.showBtnCancelar = props.showBtnCancelar === undefined ? true : props.showBtnCancelar;
        this.state.showBtnContinuar = props.showBtnContinuar === undefined ? false : props.showBtnContinuar;

        this.btnContinuarId = "btnSalvarContinuar" + (Math.random(10000) * 10000).toFixed(0);
    }

    componentDidMount() {
        super.componentDidMount();
        this.getById();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getById() {        
        if (this.props.params?.id) {
            this.setState({ isEdit: true, isLoading : true, isCopy: ((this.props.isCopy !== undefined && this.props.isCopy)) });
            this.onEdit(this.props.params?.id);
        } else if (this.props.id) {
            this.setState({ isEdit: true, isLoading : true, isCopy: ((this.props.isCopy !== undefined && this.props.isCopy)) });
            this.onEdit(this.props.id);
        } else {
            this.setState({ modelOld: Object.assign({}, this.state.model) });
        }
    }

    onEdit(id, fnCallBack = () => { }) {
        if (this.state.crud?.service?.getOneById) {
            this.state.crud.service.getOneById(id, (response) => {
                if (this.state.isCopy) {
                    response[this.state.crud.keyName] = "";
                }                
                this.setState({ isLoading: false, modelOld: Object.assign({}, response) });
                this.setModel(response, fnCallBack);
            }, (response) => {
                this.props.navigate(this.state?.crud?.urlList);
            })
        }
    }

    onSubmit(event, fnCallBack = false, flContinue = false) {
        if (this.state.crud?.service?.save) {
            this.state.crud.service.save(this.state.model, (response) => {
                this.onSave(response);

                if (fnCallBack) {
                    fnCallBack(response);
                } else {
                    if (!this.state.isModal) {
                        if (event?.nativeEvent?.submitter?.id === this.btnContinuarId) {
                            this.getById();
                        } else {
                            this.props.navigate(this.state?.crud?.urlList);
                        }
                    }
                }
            });
        }
    }

    handleClose(event) {
        this.onClose(event);
        if (!this.state.isModal && this.state?.crud?.urlList) {        
            this.props.navigate(this.state?.crud?.urlList);
        }
    }

    getTitle() {
        if (this.state.isModal) {
            return "";
        } else if (this.state.crud?.labels?.title) {
            return (this.state.model[this.state.crud.keyName] ? "Alteração" : "Cadastro") + " de " + this.state.crud.labels.title;
        } else {
            return "";
        }
    }

    getBtns() {
        return (
            <div className="d-grid gap-2 d-md-flex d-print-none">
                {this.state.showBtnSalvar ? <BtnSubmit className="btn-primary"> Salvar e Fechar </BtnSubmit> : <></>}
                {Boolean(this.state.showBtnContinuar) && Boolean(this.state.isEdit) && !Boolean(this.state.isModal) ? <BtnSubmit className="btn-outline-primary" id={this.btnContinuarId}> Salvar e Continuar </BtnSubmit> : <></>}
                {this.state.showBtnCancelar ? <BtnButton className="btn-outline-secondary" onClick={this.handleClose}> Cancelar </BtnButton> : <></>}
            </div>);
    }

    getCardHeader() {
        return this.getTitle() ? this.getTitle() : "";
    }

    getCardFooter() {
        return this.getBtns();
    }

    render() {
        if (this.state.isEdit && this.state.isLoading) {
            return <></>;
        }

        if (this.state.isCard) {
            return (
                <form onSubmit={this.handleSubmit} noValidate className={`pb-3 needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className={`${this.state.isModal ? "" : "p-2 my-2"} ${this.state.container ? 'container' : 'px-4'}`}>
                        <div className="d-flex flex-row w-100 mb-3 d-print-none">
                            {this.getTitle() ? <h4>{this.getTitle()}</h4> : ""}
                            {!this.state.isModal && this.state?.crud?.urlList ?
                                <div className="ms-auto">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item" aria-current="page">
                                                <Link to={this.state?.crud?.urlList}>Lista</Link>
                                            </li>
                                            <li className="breadcrumb-item active" aria-current="page">{this.getTitle()}</li>
                                        </ol>
                                    </nav>
                                </div>
                                : <></>}
                        </div>
                        <div className="card">
                            <div className="card-header d-print-none">
                                {this.getCardHeader()}
                            </div>
                            <div className="card-body">
                                {this.getRenderFields()}
                            </div>
                            <div className="card-footer text-muted bg-white d-print-none">
                                {this.getCardFooter()}
                            </div>
                        </div>
                    </div>
                </form >
            );
        } else {
            return (
                <div className={`${this.state.isModal ? "" : "p-2 my-1"} ${this.state.container ? 'container' : ''}`}>
                    <div className="d-flex flex-row w-100 mb-3">
                        {this.getTitle() ? <h4 className="d-print-none">{this.getTitle()}</h4> : ""}
                        {!this.state.isModal ?
                            <div className="ms-auto">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item" aria-current="page">
                                            <Link to={this.state?.crud?.urlList}>Lista</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">{this.getTitle()}</li>
                                    </ol>
                                </nav>
                            </div>
                            : <></>}
                    </div>
                    <form onSubmit={this.handleSubmit} noValidate className={`pb-3 needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                        {this.getRenderFields()}
                        {this.getBtns()}
                    </form>
                </div>
            );
        }


    }
}