import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import BaixaService from "../../services/BaixaService";
import CrudForm from "../../components/forms/custom/CrudForm";
import Util from "../../utils/Util";
import CobrancaService from "../../services/CobrancaService";
import TipoBaixaService from "../../services/TipoBaixaService";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";

class FormCobrancaTipoBaixa extends CrudForm {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.getListaTipoBaixa = this.getListaTipoBaixa.bind(this);
        this.handleChangeTipoBaixa = this.handleChangeTipoBaixa.bind(this);

        this.getById = this.getById.bind(this);

        this.state.model = {
            idCobranca: "",
            vlCobranca: 0,
            vlPago: 0,
            vlAberto: 0,
            vlBaixa: 0,
            vlDesconto: 0,
            idTipoBaixa: "",
            dtVencimento: "",
            cobrancaTipoBaixa: []
        }

        this.state.crud = {
            service: BaixaService,
            labels: {
                title: 'Baixa',
            },
            urlList: '/LstBaixa',
        };

        this.state.tipoBaixas = [];
    }

    componentDidMount() {
        super.componentDidMount();        
        this.getListaTipoBaixa();
    }

    getById() {
        if (this.props.idCobranca) {
            CobrancaService.getOneById(this.props.idCobranca, (data) => {
                this.setState({ model: data });
            });
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getListaTipoBaixa() {
        TipoBaixaService.getForCombo((response) => {
            this.setState({ tipoBaixas: response.filter(t => parseInt(t.flFaturamento) === 1) });
        });
    }

    handleChangeTipoBaixa(e, tipoBaixa) {
        this.setState(state => {
            var indexOf = state.model.cobrancaTipoBaixa.findIndex(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa));

            if (e.target.checked) {
                if (indexOf !== -1) {
                    state.model.cobrancaTipoBaixa.splice(indexOf, 1);
                }
                state.model.cobrancaTipoBaixa.push({ idTipoBaixa: tipoBaixa.value });
            } else {
                if (indexOf !== -1) {
                    state.model.cobrancaTipoBaixa.splice(indexOf, 1);
                }
            }
            return state;
        });
    }

    onSubmit() {
        CobrancaService.updateTipoBaixa(this.props.idCobranca, this.state.model, (response) => {
            this.onSave(response);
        });
    }

    getRenderFields() {
        return (
            <>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={4} value={this.state.model.idCobranca} name="idCobranca" readOnly required={true} />
                    <InputText label="Data de Vencimento" md={4} value={Util.date2Br(this.state.model.dtVencimento)} readOnly required={true} />
                    <InputText className="text-end text-primary fw-bold" label="Valor da Cobrança" md={4} value={Util.floatToReais(this.state.model.vlCobranca)} readOnly opcionalLabel={false} />
                </div>
                <div className="row mb-3 p-3">
                    {this.state.tipoBaixas.map((tipoBaixa, indexTipoBaixa) => (
                        <InputCheckSwitch key={indexTipoBaixa} sizing="md" label={tipoBaixa.label} name="flTipoBaixa" checked={this.state.model.cobrancaTipoBaixa.find(i => parseInt(tipoBaixa.value) === parseInt(i.idTipoBaixa)) !== undefined} onChange={e => { this.handleChangeTipoBaixa(e, tipoBaixa) }} />
                    ))}
                </div>
            </>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCobrancaTipoBaixa {...props} navigate={navigate} params={params} />
}

export default With