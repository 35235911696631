import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectContrato from "../../components/app/SelectContrato";
import SelectContratoStatus from "../../components/app/SelectContratoStatus";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import TextArea from "../../components/forms/elements/TextArea";
import RepresentanteContratoService from "../../services/RepresentanteContratoService";
import InputDate from "../../components/forms/elements/InputDate";
import ContratoService from "../../services/ContratoService";

class FormRepresentanteContrato extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChangeContrato = this.handleChangeContrato.bind(this);
        this.onEdit = this.onEdit.bind(this);

        this.state.model = {
            idRepresentanteContrato: props.idRepresentanteContrato || 0,
            idRepresentante: props.idRepresentante || 0,
            idContrato: "",
            idContratoStatus : 1,
            dsObservacao: "",
            dtInicio : "",
            dtFim : "",
            flAtivo : 1            
        };

        this.state.contrato = {};

        this.state.crud = {
            service: RepresentanteContratoService,
            labels: {
                title: 'Contratos',
            },
            urlList: '/',
        };
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onEdit(id) {
        super.onEdit(id, () => { 
            this.handleChangeContrato(null);
        })
    }

    handleChangeContrato(e) {
        if (parseInt(this.state.model.idContrato) > 0) {
            ContratoService.getOneById(this.state.model.idContrato, (contrato) => {
                this.setState((state) => {
                    state.contrato = contrato;                    
                    return state;
                });
            });
        }else{
            this.setState({ contrato: {} });
        }
    }

    getRenderFields() {
        return (
            <div>
                <div className="row mb-3 g-3">                    
                    {parseInt(this.state.model.idRepresentanteContrato) ===  0 ? 
                        <SelectContrato label="Contrato" required={false} placeholder="Selecione um Contrato" name="idContrato" md={12} value={this.state.model.idContrato} onChange={e => this.handleChange(e, this.handleChangeContrato)}/>
                    :
                        <InputText label="Contrato" md={12} value={this.state.model.nomeContrato} disabled={true} opcionalLabel={false}/>
                    }
                    
                    <SelectContratoStatus label="Status do Contrato" placeholder="Selecione o Status" name="idContratoStatus" md={12} value={this.state.model.idContratoStatus} onChange={this.handleChange} required />

                    <TextArea label="Observações" md={12} value={this.state.model.dsObservacao} name="dsObservacao" onChange={this.handleChange} opcionalLabel={false}/>
                    
                    <InputDate md={4} label="Data de Início" value={this.state.model.dtInicio} name="dtInicio" onChange={this.handleChange} required/>
                    <InputDate md={4} label="Data de Término" value={this.state.model.dtFim} name="dtFim" onChange={this.handleChange} required/>
                </div>

                <div className="row mx-1 mb-3">
                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(this.state.model.flAtivo)} onChange={this.handleChange} />                    
                </div>
            </div>            
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormRepresentanteContrato {...props} navigate={navigate} params={params} />
}

export default With