import React from "react";
import UsuarioService from "../../services/UsuarioService";
import CrudList from "../../components/forms/custom/CrudList";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import Util from "../../utils/Util";
import EventBus from "../../controllers/EventBus";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import SelectPerfil from "../../components/app/SelectPerfil";
import FormToken from "./FormToken";
import Permissao from "../../controllers/Permissao";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import Config from "../../Config";

export default class ListaUsuario extends CrudList {

    constructor(props) {
        super(props);

        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.getData = this.getData.bind(this);

        this.state.crud = {
            service: UsuarioService,
            labels: {
                title: 'Lista de Usuário(s)',
                btnNewRegister: 'Usuário',
            },
            keyItem: 'idUsuario',
            urlForm: '/CadUsuario',
            itensFilter: ["idUsuario", "nome", "nomePerfil", "usuario", "ativo", "email"],
            searchFormSubmit: true
        };

        this.state.container = false;
    }

    getState(props) {
        return {
            data: [],
            model: {
                busca: "",
                flStatus: "T",
                flOnline: "T",
                flBloqueado: "T",
                idPerfil: "",
                idRepresentante: ""
            },
            expand: true,
            filtroStatus: [
                { value: "T", label: "Todos" },
                { value: "A", label: "Ativos" },
                { value: "I", label: "Inativo" },
            ],
            filtroOnline: [
                { value: "T", label: "Todos" },
                { value: "O", label: "Online" },
                { value: "F", label: "Offline" },
            ],
            filtroBloqueado: [
                { value: "T", label: "Todos" },
                { value: "B", label: "Bloqueado" },
                { value: "L", label: "Liberado" },
            ]
        };
    }

    onList() {
        UsuarioService.listForList(this.state.model, (response) => {
            this.setState({ data: response });
        });
    }

    onClear() {
        this.setState(this.getState(), this.onList);
    }

    componentDidMount() {
        super.componentDidMount();
        this.onList();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleOpenModalToken(usuario) {
        EventBus.dispatch("modal-add", {
            title: 'Dados da Ordem',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idUsuario: usuario.idUsuario
            },
            form: FormToken,
            onClose: () => {
            }
        });
    }

    handleStatus(item) {
        EventBus.dispatch("modal-confirmacao-open", {
            id: item.idUsuario,
            config: {
                title: "Alterar o Status?",
                message:
                    <span>
                        Deseja <span className={`badge bg-${item.ativo ? "danger" : "success"}`}>{item.ativo ? "DESATIVAR" : "ATIVAR"}</span>  o usuário <strong>{item.nome}</strong>?
                    </span>,
                description: item.ativo ? "Esse usuário não terá mais acesso ao sistema!" : "Esse usuário voltará a ter acesso ao sistema!",
                btnConfirmText: "Sim, alterar o status",
                style: item.ativo ? "danger" : "success",
                confirm: this.onChangeStatus
            }
        });
    }

    onChangeStatus(item) {
        this.state.crud.service.setStatus(item.id, (response) => {
            this.onList();
        });
    }

    getFormFilter() {
        return (
            <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                <div className="row mb-3">
                    <SelectPerfil label="Nome do Perfil" showBtnNewForm={false} placeholder="Selecione o Perfil" name="idPerfil" md={3} value={this.state.model.idPerfil} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <SelectRepresentanteByUsuario showBtnNewForm={false} md={3} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <BtnGroup md={2} sizing="sm" className="w-100" name="flStatus" value={this.state.model.flStatus} label="Status" options={this.state.filtroStatus} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <BtnGroup md={2} sizing="sm" className="w-100" name="flOnline" value={this.state.model.flOnline} label="Online" options={this.state.filtroOnline} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <BtnGroup md={2} sizing="sm" className="w-100" name="flBloqueado" value={this.state.model.flBloqueado} label="Online" options={this.state.filtroBloqueado} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                </div>
                <div className="row gap-2 mb-3">
                    <div className="col">
                        <div className="col-md-12 input-group mb-3">
                            <span className="input-group-text" id="addon-wrapping">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                            <input type="text" className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite sua busca" value={this.state.model.busca} onChange={this.handleChange} />
                            <button className="btn btn-outline-primary" type="submit"> Buscar </button>
                        </div>
                    </div>
                    <div className="col-md-auto text-end d-grid d-md-block">
                        <button type="button" className="btn btn-outline-danger" onClick={this.onClear}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                            </svg> Limpar Busca
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    getRenderHeader() {
        return (
            <>
                <th className="text-center d-none d-md-table-cell">  </th>
                <th className="text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-4"> Nome </th>
                <th className="col-md-3"> Perfil </th>
                <th className="col-md-2 d-none d-md-table-cell"> Usuário </th>
                <th className="col-md-2 text-center d-none d-md-table-cell"> Último Acesso </th>
                <th className="text-center d-none d-md-table-cell"> Online? </th>
                <th className="text-center d-none d-md-table-cell"> Ativo? </th>
            </>
        );
    }

    getButtons(item) {
        return (<>
            {Permissao.getInstance().getPermissao(52) ?
                <button type="button" className="btn btn-sm btn-outline-success" onClick={() => { this.handleOpenModalToken(item) }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-door-open-fill" viewBox="0 0 16 16">
                        <path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
                    </svg>
                </button> : <></>
            }
            {this.getButtonEdit(item)}
            {this.getButtonDelete(item)}
        </>);
    }

    getRenderItem(item) {
        return (
            <>
                <td>
                    <img className="bd-placeholder-img flex-shrink-0 me-2 rounded img-fluid" style={{maxWidth : "64px", maxHeight : "48px"}} src={Config.getUrlArquivo() + "public/arquivo/inline/" + item.uriFotoUsuario} alt="..."/>
                </td>
                <td className="text-center d-none d-md-table-cell">{item.idUsuario}</td>
                <td>
                    {item.nome} {Boolean(item.ativo) ? <></> : <span className="badge bg-danger">INATIVO</span>} {Boolean(item?.flBloqueado) ? <span className="badge text-bg-danger">BLOQUEADO POR INADIMPLÊNCIA</span> : <></>}
                </td>
                <td>{item.nomePerfil}</td>
                <td className="d-none d-md-table-cell">{item.usuario}</td>
                <td className="text-center d-none d-md-table-cell">
                    <small>{(item.dataAcesso ? Util.date2Br(item.dataAcesso) + " " + item.horaAcesso : "")}</small>
                </td>
                
                <td className="text-center d-none d-md-table-cell">
                    {item.isOnline ?
                        <span className="text-success">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                                <circle cx="8" cy="8" r="8" />
                            </svg>
                        </span>
                        :
                        <span className="text-danger">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                                <circle cx="8" cy="8" r="8" />
                            </svg>
                        </span>
                    }
                </td>
                <td className="text-center d-none d-md-table-cell">
                    <InputCheckSwitch name="ativo" checked={Boolean(item.ativo)} onChange={(e) => { this.handleStatus(item) }} />
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}