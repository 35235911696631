import TagService from "../../services/TagService";
import FormDropDownCheckBox from "../forms/base/FormDropDownCheckBox";

export default class DropDownMenuTag extends FormDropDownCheckBox {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.getOptions(() => {
            if (this.dispachChangeDidMount && this.props.value) {
                this.handleChange({ target: { value: this.props.value, name: this.props.name } });
            }
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        TagService.getForCombo((response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });
    }

    render() {
        return super.render();
    }
}