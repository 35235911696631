import Http from "../controllers/Http";

export default class AcordoService {

    static urlBase = "/acordo/";

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static estornar(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .put(this.urlBase + 'estornar/' + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static list(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "list", data)
            .then(fnSucess)
            .catch(fnError);
    }
}
