import Http from "../controllers/Http";

export default class OrdemService {

    static urlBase = "/ordem/";

    static getForCombo(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forCombo')
            .then(fnSucess)
            .catch(fnError);
    }

    static list(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'list', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getAllForFatura(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'forFatura', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getAllForFaturaByProprietario(idProprietario, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forFaturaByProprietario/' + idProprietario)
            .then(fnSucess)
            .catch(fnError);
    }

    static getAllForFaturaByProprietarioOrdem(idProprietario, idOrdem, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forFaturaByProprietarioOrdem/' + idProprietario + '/' + idOrdem)
            .then(fnSucess)
            .catch(fnError);
    }

    static getAllForHistorico(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'forHistorico', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static update(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static updateItem(idOrdemItem, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'item/' +  idOrdemItem, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static updateTipoProdutoServico(idOrdem, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'byChangeTipoProdutoServico/' +  idOrdem, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static markReadComentario(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'marcarComentariosLido/' + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static markAllReadComentario(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'marcarComentariosLido', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static save(data, fnSucess = () => { }, fnError = () => { }) {
        if (data.idOrdem) {
            this.update(data.idOrdem, data, fnSucess, fnError);
        } else {
            this.create(data, fnSucess, fnError);
        }
    }

    static listRemap(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'listRemap', data)
            .then(fnSucess)
            .catch(fnError);
    }
}
