import React from "react";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import TransacaoService from "../../services/TransacaoService";
import SelectDDI from "../../components/app/SelectDDI";
import InputCpf from "../../components/forms/custom/InputCpf";
import InputCnpj from "../../components/forms/custom/InputCnpj";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputTelCelular from "../../components/forms/custom/InputTelCelular";
import InputEmail from "../../components/forms/elements/InputEmail";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import InputCep from "../../components/forms/custom/InputCep";
import { Link, useNavigate, useParams } from "react-router-dom";
import Util from "../../utils/Util";
import EnderecoService from "../../services/EnderecoService";

class FormCobrancaPessoa extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.getTransacao = this.getTransacao.bind(this);
        this.handleChangeCep = this.handleChangeCep.bind(this);

        this.state.model = {
            uidTransacao: this.props.params?.uid,

            ddi: "55",
            celular: "",
            nome: "",
            email: "",
            cdTipoPessoa: "F",
            cpf: "",
            cnpj: "",

            idPais: 1,
            idEstado: "",
            idCidade: "",
            cep: "",
            bairro: "",
            endereco: "",
            numero: "",
            complemento: ""
        };

        this.state.transacao = {
            vlTransacao: 0,
            nrParcela: 1
        };

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];
    }

    componentDidMount() {
        super.componentDidMount();
        this.getTransacao();
    }

    getTransacao() {
        TransacaoService.getOneByUid(this.props.params?.uid, (response) => {
            this.setState({ transacao: response });

            try {
                if (response.pessoa) {
                    this.setState(state => {
                        state.model.ddi = response.pessoa.ddi ? response.pessoa.ddi : state.model.ddi;
                        state.model.celular = response.pessoa.celular ? response.pessoa.celular : state.model.celular;
                        state.model.nome = response.pessoa.nome ? response.pessoa.nome : state.model.nome;
                        state.model.email = response.pessoa.email ? response.pessoa.email : state.model.email;
                        state.model.cdTipoPessoa = response.pessoa.cdTipoPessoa ? response.pessoa.cdTipoPessoa : state.model.cdTipoPessoa;
                        state.model.cpf = response.pessoa.cpf ? response.pessoa.cpf : state.model.cpf;
                        state.model.cnpj = response.pessoa.cnpj ? response.pessoa.cnpj : state.model.cnpj;
                        state.model.idPais = response.pessoa.idPais ? response.pessoa.idPais : state.model.idPais;
                        state.model.idEstado = response.pessoa.idEstado ? response.pessoa.idEstado : state.model.idEstado;
                        state.model.idCidade = response.pessoa.idCidade ? response.pessoa.idCidade : state.model.idCidade;
                        state.model.cep = response.pessoa.cep ? response.pessoa.cep : state.model.cep;
                        state.model.bairro = response.pessoa.bairro ? response.pessoa.bairro : state.model.bairro;
                        state.model.endereco = response.pessoa.endereco ? response.pessoa.endereco : state.model.endereco;
                        state.model.numero = response.pessoa.numero ? response.pessoa.numero : state.model.numero;
                        state.model.complemento = response.pessoa.complemento ? response.pessoa.complemento : state.model.complemento;
                        return state;
                    });
                }
            } catch (error) {

            }
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getBtns() {
        return (<></>);
    }

    getUrlTransacao() {

        switch (parseInt(this.state.transacao.idTipoBaixa)) {
            case 1: //boleto
                return `/boleto/${this.state.transacao.uid}`;

            case 3: //pix
                return `/pix/${this.state.transacao.uid}`;

            case 4: //cartão crédito
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 16:
                return `/credito/${this.state.transacao.uid}`;

            case 5: //cartão débito
                return `/debito/${this.state.transacao.uid}`;

            default:
                return '';
        }
    }

    handleChangeCep() {
        if (Util.stringToNumber(this.state.model.cep).length === 8) {
            EnderecoService.getOneByCep(this.state.model.cep, (response) => {
                this.setState((state) => {
                    state.model.endereco = response?.logradouro ? response.logradouro : "";
                    state.model.bairro = response?.bairro ? response.bairro : "";
                    state.model.complemento = response?.complemento ? response.complemento : "";
                    state.model.numero = "";

                    if (response?.idEstado) {
                        state.model.idEstado = response.idEstado;
                    }

                    if (response?.idCidade) {
                        state.model.idCidade = response.idCidade;
                    }

                    return state;
                });
            });

        }
    }

    onSubmit(event) {
        TransacaoService.salvarPessoa(this.props.params?.uid, this.state.model, (response) => {
            this.props.navigate(this.getUrlTransacao());
        });
    }

    getRenderFields() {
        return (
            <div className="col-xl-8 mx-auto">
                <header>
                    <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                        <a href="https://bdmperformance.com.br/" className="d-flex align-items-center text-dark text-decoration-none">
                            <img src="./logo-preto.png" alt="Logo BDM" />
                        </a>

                        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://www.instagram.com/bdmperformance/">Nosso Instagram</a>
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://bdmperformance.com.br/">Nosso Site</a>
                        </nav>
                    </div>

                    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                        <h1 className="display-5 fw-normal"> Dados do Pagador </h1>
                        <p className="fs-5 text-muted">
                            Preenchar os dados abaixo para dar sequência no seu pagamento
                        </p>
                        <div className="text-center text-decoration-none">
                            <Link to={`/pagamento/${this.state.transacao.uidCobranca}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                </svg> Escolher outra forma de pagamento
                            </Link>
                        </div>
                    </div>
                </header>

                <div className="card mt-4 border-secondary">
                    <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">
                        Dados do Pagador
                    </div>
                    <div className="card-body">
                        <div className="row mb-3 g-3">
                            <BtnGroup required={true} lg={4} className="w-100" name="cdTipoPessoa" value={this.state.model.cdTipoPessoa} label="Tipo Pessoa" options={this.state.tipoPessoa} onChange={this.handleChange} />

                            {this.state.model.cdTipoPessoa === "F" ? <InputCpf label="CPF" required={true} lg={3} name="cpf" value={this.state.model.cpf ? this.state.model.cpf : ""} onChange={this.handleChange} /> : <></>}
                            {this.state.model.cdTipoPessoa === "J" ? <InputCnpj label="CNPJ" required={true} lg={3} name="cnpj" value={this.state.model.cnpj ? this.state.model.cnpj : ""} onChange={this.handleChange} /> : <></>}

                            <InputText label={this.state.model.cdTipoPessoa === "J" ? "Razão Social" : "Nome completo"} required lg={5} value={this.state.model.nome} name="nome" onChange={this.handleChange} />

                            <SelectDDI showBtns={false} lg={2} label="DDI" required placeholder="Selecione" name="ddi" value={this.state.model.ddi} onChange={this.handleChange} />
                            {this.state.model.ddi.toString() === "55" ?
                                <InputTelCelular label="Celular" required lg={3} value={this.state.model.celular} name="celular" onChange={this.handleChange} />
                                :
                                <InputText label="Celular" required lg={3} value={this.state.model.celular} name="celular" onChange={this.handleChange} />
                            }

                            <InputEmail label="E-mail" lg={7} name="email" value={this.state.model.email} onChange={this.handleChange} required={true} />
                        </div>
                    </div>
                </div>

                <div className="card mt-4 border-secondary">
                    <div className="card-header fs-6 fw-bold bg-primary bg-opacity-25 bg-gradient border-secondary">
                        Endereço do Titular
                    </div>
                    <div className="card-body">
                        <div className="row mb-3 g-3">
                            <InputCep label="CEP" lg={3} value={this.state.model.cep} name="cep" onChange={e => this.handleChange(e, this.handleChangeCep)} required={true} />
                            <InputText label="Logradouro" lg={6} value={this.state.model.endereco} name="endereco" onChange={this.handleChange} maxLength={100} required={true} />
                            <InputText label="Número" lg={3} value={this.state.model.numero} name="numero" onChange={this.handleChange} maxLength={100} required={true} />

                            <InputText label="Complemento" lg={6} value={this.state.model.complemento} name="complemento" onChange={this.handleChange} maxLength={100} />
                            <InputText label="Bairro" lg={6} value={this.state.model.bairro} name="bairro" onChange={this.handleChange} maxLength={150} required={true} />

                            <SelectEstadoByPais showBtnNewForm={false} sizing="md" lg={6} required idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" onChange={this.handleChange} />
                            <SelectCidadeByEstado showBtnNewForm={false} sizing="md" lg={6} required idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" onChange={this.handleChange} />
                        </div>
                    </div>
                </div>

                <div className="hstack gap-2 mt-4 mb-4">
                    <BtnSubmit className="btn-primary"> Continuar </BtnSubmit>
                    <Link to={`/pagamento/${this.state.transacao.uidCobranca}`} className="btn btn-outline-secondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                        </svg> Voltar
                    </Link>
                </div>

            </div>

        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormCobrancaPessoa {...props} navigate={navigate} params={params} />
}

export default With