import React from "react";
import { Link } from "react-router-dom";
import EventBus from "../../controllers/EventBus";
import MyComponent from "../../components/custom/MyComponent";
import RepresentanteService from "../../services/RepresentanteService";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import SelectPais from "../../components/app/SelectPais";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import Permissao from "../../controllers/Permissao";
import BtnGroup from "../../components/forms/elements/BtnGroup";

export default class ListaRepresentante extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.excluir = this.excluir.bind(this);
        this.clear = this.clear.bind(this);
        this.handleClickExcluir = this.handleClickExcluir.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickExpand = this.handleClickExpand.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLista = this.getLista.bind(this);
        this.state = this.getInitState(props);
        this.onChangeStatus = this.onChangeStatus.bind(this);

        this.state.filtroStatus = [
            { value: "T", label: "Todos" },
            { value: "A", label: "Ativos" },
            { value: "I", label: "Inativo" },
        ];

        this.state.filtroBloqueado = [
            { value: "T", label: "Todos" },
            { value: "B", label: "Bloqueado" },
            { value: "L", label: "Liberado" },
        ];
    }

    getInitState(props) {

        return {
            data: [],
            expand: false,
            model: {
                busca: "",
                idPais: "",
                idEstado: "",
                idCidade: "",
                flStatus: "T",
                flBloqueado: "T",
            }
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getLista();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    getLista() {
        this.setState({ data: [] });
        RepresentanteService.listForList(this.state.model, (response) => {
            this.setState({ data: response });
        });
    }

    handleClickExcluir(item) {
        EventBus.dispatch("modal-delete-open", {
            id: item.idRepresentante,
            config: {
                title: "Excluir representante?",
                message: <>Deseja realmente excluir o representante: <strong>{item.empresa} </strong>?</>,
                btnConfirmText: "Sim, excluir o representante",
                description: "Esta é uma ação é irreversível!",
                style: "danger"
            }
        })
    }

    excluir(item) {
        RepresentanteService.delete(item.id, (response) => {
            this.getLista();
        });
    }

    handleClickExpand() {
        this.setState({ expand: !this.state.expand })
    }

    onSubmit(event) {
        this.getLista();
    }

    handleStatus(item) {
        EventBus.dispatch("modal-confirmacao-open", {
            id: item.idRepresentante,
            config: {
                title: "Alterar o Status?",
                message:
                    <span>
                        Deseja <span className={`badge bg-${item.ativo ? "danger" : "success"}`}>{item.ativo ? "DESATIVAR" : "ATIVAR"}</span>  o representante <strong>{item.empresa}</strong>?
                    </span>,
                description: item.ativo ? "Esse representante não será apresentado como empresa ativa!" : "",
                btnConfirmText: "Sim, alterar o status",
                style: item.ativo ? "danger" : "success",
                confirm: this.onChangeStatus
            }
        });
    }

    onChangeStatus(item) {
        RepresentanteService.setStatus(item.id, (response) => {
            this.getLista();
        });
    }

    render() {
        return (
            <div className="p-3 my-2 w-100">
                <div className="hstack gap-3 mb-3">
                    <h4 className="mb-3 w-100">Lista de Representantes</h4>
                    <Link to="/CadRepresentante" className="btn btn-outline-primary text-nowrap">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                        </svg> Representante
                    </Link>
                </div>

                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row mb-3">
                        <SelectPais label="País" placeholder="Selecione o País" name="idPais" md={2} value={this.state.model.idPais} onChange={(e) => { this.handleChange(e, this.onSubmit) }} showBtnNewForm={false} opcionalLabel={false} />
                        <SelectEstadoByPais idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" md={3} onChange={(e) => { this.handleChange(e, this.onSubmit) }} showBtnNewForm={false} opcionalLabel={false} />
                        <SelectCidadeByEstado idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" md={3} onChange={(e) => { this.handleChange(e, this.onSubmit) }} showBtnNewForm={false} opcionalLabel={false} />
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flStatus" value={this.state.model.flStatus} label="Status" options={this.state.filtroStatus} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flBloqueado" value={this.state.model.flBloqueado} label="Online" options={this.state.filtroBloqueado} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    </div>

                    <div className="row gap-2 mb-3">
                        <div className="col">
                            <div className="col-md-12 input-group mb-3">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite o nome de um representante ou proprietário" value={this.state.model.busca} onChange={this.handleChange} />
                                <button className="btn btn-outline-primary" type="submit"> Buscar </button>
                            </div>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-danger" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </button>
                        </div>
                    </div>
                </form>

                <div className="table-responsive">
                    <table className="table table-sm table-hover caption-top">
                        <caption className="text-end">
                            {this.state.data.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th className="text-center d-none d-md-table-cell"> Código </th>
                                <th className="col-md-6"> Empresa </th>
                                <th className="col-md-6"> Proprietário </th>
                                <th className="col-md d-none d-md-table-cell"> País </th>
                                <th className="col-md d-none d-md-table-cell"> Estado </th>
                                <th className="col-md d-none d-md-table-cell"> Cidade </th>
                                <th className="col-md text-center d-none d-md-table-cell"> Ativo? </th>
                                <th className="text-end">
                                    <button type="button" className="btn btn-link" onClick={this.handleClickExpand}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-caret-down-fill ${this.state.expand ? 'd-none' : ''}`} viewBox="0 0 16 16">
                                            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-caret-left-fill ${this.state.expand ? '' : 'd-none'}`} viewBox="0 0 16 16">
                                            <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                                        </svg>
                                    </button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item) => (
                                <tr key={item.idRepresentante}>
                                    <td className="text-center d-none d-md-table-cell">{item.idRepresentante}</td>
                                    <td>
                                        {item.empresa} {Boolean(item?.flBloqueado) ? <span className="badge text-bg-danger">BLOQUEADO POR INADIMPLÊNCIA</span> : <></>}
                                    </td>
                                    <td>
                                        {Permissao.getInstance().getPermissao(9) ?
                                            <Link to={`/CadProprietario/${item.idProprietario}`}>{item.nomeProprietario}</Link>
                                            : item.nomeProprietario}
                                    </td>
                                    <td className="d-none d-md-table-cell text-nowrap">{item.nomePais}</td>
                                    <td className="d-none d-md-table-cell text-nowrap">{item.nomeEstado}</td>
                                    <td className="d-none d-md-table-cell text-nowrap">{item.nomeCidade}</td>
                                    <td className="text-center d-none d-md-table-cell">
                                        <InputCheckSwitch name="ativo" checked={Boolean(item.ativo)} onChange={(e) => { this.handleStatus(item) }} />
                                    </td>

                                    <td className="text-end text-nowrap">
                                        <Link to={`/CadRepresentante/${item.idRepresentante}`} className="btn btn-outline-primary btn-sm">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                            </svg>
                                        </Link>
                                        <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleClickExcluir(item) }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>
                <ModalConfirmacao id="modal-delete" confirm={this.excluir} />
            </div >
        )
    }
}