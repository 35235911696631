import ContatoTipoService from "../../services/ContatoTipoService";
import SelectContatoTipo from "./SelectContatoTipo";

export default class SelectContatoTipoByUsuario extends SelectContatoTipo {

    constructor(props) {
        super(props);
        this.getOptions = this.getOptions.bind(this);
    }

    componentDidUpdate(nextProps) {
        super.componentDidUpdate(nextProps);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getOptions(callBack) {
        ContatoTipoService.getForComboByUsuario((response) => {
            this.setState({ options: response }, () => {
                if (callBack) {
                    callBack();
                }
            });
        });

    }

    render() {
        return super.render();
    }
}