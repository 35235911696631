import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import CrudForm from "../../components/forms/custom/CrudForm";
import Config from "../../Config";
import Util from "../../utils/Util";
import TransacaoService from "../../services/TransacaoService";
import InputCopy from "../../components/forms/custom/InputCopy";
import { toast } from "react-toastify";

class FormBoleto extends CrudForm {

    constructor(props) {
        super(props);

        this.handleClickCopy = this.handleClickCopy.bind(this);

        this.state.model = {
            uidTransacao: this.props.params?.uid,
        };

        this.state.transacao = {
            vlTransacao: 0,
            nrParcela: 1
        };

        this.state.boleto = {
            linhaDigitavel: "",
            dtVencimento: "",
            vlBoleto: 0,
        }

    }

    componentDidMount() {
        super.componentDidMount();
        this.getTransacao();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getTransacao() {
        TransacaoService.getOneByUid(this.props.params?.uid, (response) => {
            this.setState({ transacao: response }, this.getBoleto);
        });
    }

    getBoleto() {
        TransacaoService.pagarBoleto(this.props.params?.uid, (response) => {
            this.setState({ boleto: response });
        });
    }

    getUrlBoleto() {
        return Config.getUrlApi() + "public/transacao/boleto/pdf/" + this.state.model.uidTransacao;
    }

    handleClickCopy() {
        if (Util.copyClipboard(this.state.boleto.linhaDigitavel)) {
            toast.success('Dados copiado para a área de transferência!');
        } else {
            toast.error('Erro ao copiar, por favor copie manualmente!');
        }
    }

    getBtns() {
        return (<></>);
    }

    getRenderFields() {
        return (
            <div className="col-xl-8 mx-auto">
                <header>
                    <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
                        <a href="https://bdmperformance.com.br/" className="d-flex align-items-center text-dark text-decoration-none">
                            <img src="./logo-preto.png" alt="Logo BDM" />
                        </a>

                        <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://www.instagram.com/bdmperformance/">Nosso Instagram</a>
                            <a className="me-3 py-2 text-dark text-decoration-none" href="https://bdmperformance.com.br/">Nosso Site</a>
                        </nav>
                    </div>

                    <div className="pricing-header p-3 pb-md-4 mx-auto text-center">
                        <h1 className="display-5 fw-normal">Pagamento com boleto bancário</h1>
                        <p className="fs-5 text-muted">
                            Pagamento da transação através do boleto, no valor de <strong>{Util.floatToReais(this.state.transacao?.vlTransacao)}</strong> com vencimento para <strong>{Util.date2Br(this.state.boleto.dtVencimento)}</strong>
                        </p>
                        <div className="text-center text-decoration-none">
                            <Link to={`/pagamento/${this.state.transacao.uidCobranca}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
                                </svg> Escolher outra forma de pagamento
                            </Link>
                        </div>
                    </div>
                </header>

                <div className="row mb-3 g-3">
                    <InputCopy label="Código de Barras" md={12} sizing="lg" value={this.state.boleto.linhaDigitavel} readOnly opcionalLabel={false} onClickCopy={() => this.handleClickCopy()} />
                    <a href={this.getUrlBoleto()} rel="opener noreferrer" target="_blank" className="btn btn-lg btn-success btn-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upc" viewBox="0 0 16 16">
                            <path d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z" />
                        </svg> Visualizar Boleto
                    </a>
                </div>

                <footer className="pt-4 my-md-5 pt-md-5 border-top">
                    <div className="row">
                        <div className="col-12 col-md">
                            BDM Performance
                            <small className="d-block mb-3 text-muted">&copy; 2022</small>
                        </div>
                    </div>
                </footer>
            </div>

        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormBoleto {...props} navigate={navigate} params={params} />
}

export default With