import React from "react";
import ProdutoServicoEntradaService from "../../services/ProdutoServicoEntradaService";
import CrudList from "../../components/forms/custom/CrudList";
import Util from "../../utils/Util";

export default class ListaProdutoServicoEntrada extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: ProdutoServicoEntradaService,
            labels: {
                title: 'Lista de Entrada(s)',
                btnNewRegister: 'Entrada',
            },
            keyItem: 'idProdutoServicoEntrada',
            urlForm: '/CadProdutoServicoEntrada',
            itensFilter: ["idProdutoServicoEntrada", "nomeProduto", "nomeUsuario", "nomeFornecedor"],
            searchFormSubmit: false
        };

        this.state.container = false;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderHeader() {
        return (
            <>
                <th className="text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-5"> Produto </th>
                <th className="col-md-3"> Fornecedor </th>
                <th className="col-md-3"> Usuário </th>
                <th className="col-md-1 text-center d-none d-md-table-cell"> Data </th>
                <th className="text-center"> Custo</th>
                <th className="text-center"> Estoque Atual</th>
                <th className="text-center"> Estoque Antigo </th>
                <th className="text-center"> Entrada </th>
                <th className="text-center"> UN </th>
            </>
        );
    }

    getRenderItem(item) {
        return (
            <>
                <td className="text-center d-none d-md-table-cell text-nowrap">{item.idProdutoServicoEntrada}</td>
                <td>{item.nomeProduto}</td>
                <td>{item.nomeFornecedor}</td>
                <td>{item.nomeUsuario}</td>
                <td className="text-center d-none d-md-table-cell text-nowrap">{Util.date2Br(item.dataEntrada)} {item.horaEntrada}</td>
                <td className="d-none d-md-table-cell text-end">{Util.floatToReais(item.vlCusto)}</td>
                <td className="text-center">{parseFloat(item.vlEstoqueAtual).toFixed(item.nrCasasDecimais)}</td>
                <td className="text-center">{parseFloat(item.vlEstoque).toFixed(item.nrCasasDecimais)}</td>
                <td className="text-center">{parseFloat(item.nrQuantidade).toFixed(item.nrCasasDecimais)}</td>
                <td className="text-center">{item.siglaUnidadeMedida}</td>
            </>);
    }

    getButtons(item) {
        return (<>
            <button type="button" className="btn btn-sm ms-2 btn-outline-danger" onClick={() => { this.handleClickExcluir(item) }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                </svg>
            </button>
        </>);
    }

    render() {
        return super.render();
    }
}