import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MyComponent from "../../components/custom/MyComponent";
import Permissao from "../../controllers/Permissao";
import Financeiro from "../Dashboard/Financeiro";
import Top10Ranking from "../Dashboard/Top10Ranking";
import TopByUsuario from "../Dashboard/TopByUsuario";
import Atalhos from "./Atalhos";

class Home extends MyComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        super.componentDidMount();

    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    render() {
        return (
            <div className="p-3 my-2">
                <nav>
                    <div className="nav nav-pills nav-pills-bdm" id="nav-tab" role="tablist">
                        <button className="nav-link nav-link-success active" id="nav-atalhos-tab" data-bs-toggle="tab" data-bs-target="#nav-atalhos" type="button" role="tab" aria-controls="nav-atalhos" aria-selected="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link-45deg" viewBox="0 0 16 16">
                                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                            </svg> Atalhos
                        </button>
                        {Permissao.getInstance().getPermissao(35) ?
                            <button className="nav-link" id="nav-dashboard-top10-tab" data-bs-toggle="tab" data-bs-target="#nav-dashboard-top10" type="button" role="tab" aria-controls="nav-dashboard-top10" aria-selected="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list-ol" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5z" />
                                    <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338v.041zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635V5z" />
                                </svg> Ranking
                            </button>
                            : <></>
                        }
                        {Permissao.getInstance().getPermissao(35) ?
                            <button className="nav-link" id="nav-dashboard-topByUsuario-tab" data-bs-toggle="tab" data-bs-target="#nav-dashboard-topByUsuario" type="button" role="tab" aria-controls="nav-dashboard-topByUsuario" aria-selected="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-speedometer" viewBox="0 0 16 16">
                                    <path d="M8 2a.5.5 0 0 1 .5.5V4a.5.5 0 0 1-1 0V2.5A.5.5 0 0 1 8 2zM3.732 3.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 8a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 8zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 7.31A.91.91 0 1 0 8.85 8.569l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                                    <path fillRule="evenodd" d="M6.664 15.889A8 8 0 1 1 9.336.11a8 8 0 0 1-2.672 15.78zm-4.665-4.283A11.945 11.945 0 0 1 8 10c2.186 0 4.236.585 6.001 1.606a7 7 0 1 0-12.002 0z" />
                                </svg> Minha Performance
                            </button>
                            : <></>
                        }
                        {Permissao.getInstance().getPermissao(39) ?
                            <button className="nav-link" id="nav-dashboard-financeiro-tab" data-bs-toggle="tab" data-bs-target="#nav-dashboard-financeiro" type="button" role="tab" aria-controls="nav-dashboard-topByUsuario" aria-selected="false">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cash-stack" viewBox="0 0 16 16">
                                    <path d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                    <path d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z" />
                                </svg> Financeiro
                            </button>
                            : <></>
                        }
                    </div>
                </nav>

                <hr />

                <div className="tab-content mx-2 my-3" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-atalhos" role="tabpanel" aria-labelledby="nav-atalhos-tab">
                        <Atalhos />
                    </div>

                    {Permissao.getInstance().getPermissao(35) ?
                        <div className="tab-pane fade show" id="nav-dashboard-top10" role="tabpanel" aria-labelledby="nav-dashboard-top10-tab">
                            <Top10Ranking />
                        </div>
                        : <></>
                    }

                    {Permissao.getInstance().getPermissao(35) ?
                        <div className="tab-pane fade show" id="nav-dashboard-topByUsuario" role="tabpanel" aria-labelledby="nav-dashboard-topByUsuario-tab">
                            <TopByUsuario />
                        </div>
                        : <></>
                    }

                    {Permissao.getInstance().getPermissao(39) ?
                        <div className="tab-pane fade show" id="nav-dashboard-financeiro" role="tabpanel" aria-labelledby="nav-dashboard-financeiro-tab">
                            <Financeiro />
                        </div>
                        : <></>
                    }
                </div>
            </div>
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    let location = useLocation();
    return <Home {...props} navigate={navigate} params={params} location={location} />
}

export default With