import Http from "../controllers/Http";

export default class ProdutoServicoInventarioService {

    static urlBase = "/produtoServicoInventario/";

    static getForCombo(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forCombo')
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboByTipo(idTipoProdutoServicoInventario, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboByTipo/' + idTipoProdutoServicoInventario)
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboByTipoUsuario(idTipoProdutoServicoInventario, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboByTipoUsuario/' + idTipoProdutoServicoInventario)
            .then(fnSucess)
            .catch(fnError);
    }

    static list(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByIdByUsuario(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byUsuario/' +  id)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static update(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static save(data, fnSucess = () => { }, fnError = () => { }) {
        if (data.idProdutoServicoInventario) {
            this.update(data.idProdutoServicoInventario, data, fnSucess, fnError);
        } else {
            this.create(data, fnSucess, fnError);
        }
    }
}
