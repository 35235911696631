import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ParametroService from "../../services/ParametroService";
import CrudForm from "../../components/forms/custom/CrudForm";
import SelectModuloByGrupoModulo from "../../components/app/SelectModuloByGrupoModulo";
import SelectGrupoModulo from "../../components/app/SelectGrupoModulo";
import InputNumber from "../../components/forms/elements/InputNumber";
import TextArea from "../../components/forms/elements/TextArea";
import FormSelect from "../../components/forms/base/FormSelect";
import BtnButton from "../../components/forms/elements/BtnButton";

class FormParametro extends CrudForm {

    constructor(props) {
        super(props);

        this.handleClickModelo = this.handleClickModelo.bind(this);

        this.state.model = {
            idParametro: "",
            idGrupoModulo: "",
            idModulo: "",
            nome: "",
            cdTipo: "BOOLEAN",
            nrTamanho: 0,
            dsJson: ""
        };

        this.state.crud = {
            service: ParametroService,
            labels: {
                title: 'Parâmetro',
            },
            urlList: '/LstParametro',
        };

        this.state.cdTipos = [
            { value: "BOOLEAN", label: "Boleano", flSize: false, flJson: false },
            { value: "VARCHAR", label: "Resposta Curta", flSize: true, flJson: false },
            { value: "TEXT", label: "Parágrafo", flSize: true, flJson: false },
            { value: "INTEGER", label: "Número Inteiro", flSize: false, flJson: false },
            { value: "DECIMAL", label: "Número Decimal", flSize: true, flJson: false },
            { value: "DROP_DOWN", label: "Lista Suspensa", flSize: false, flJson: true },
            { value: "CHECKBOX", label: "Caixas de Seleção", flSize: false, flJson: true },
            { value: "DATE", label: "Data", flSize: false, flJson: false },
            { value: "TIME", label: "Hora", flSize: false, flJson: false }
        ];
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleClickModelo() {
        this.setState(state => {
            state.model.dsJson = '[\n\t{ "value": "1", "label": "Teste 1" },\n\t{ "value": "2", "label": "Teste 2" },\n\t{ "value": "3", "label": "Teste 3" }\n]';
            return state;
        });
    }

    getRenderFields() {
        return (
            <div>
                <div className="row mb-3 g-3">
                    <InputText label="Código" md={2} value={this.state.model.idParametro} name="idParametro" readOnly opcionalLabel={false} />
                    <InputText md={4} label="Nome da Parâmetro" autoFocus={true} required value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />

                    <FormSelect md={4} name="cdTipo" value={this.state.model.cdTipo} label="Tipo do Campo" options={this.state.cdTipos} onChange={this.handleChange} opcionalLabel={false} />
                    <InputNumber md={2} disabled={!(this.state.cdTipos.find(i => this.state.model.cdTipo === i.value)?.flSize)} required={(this.state.cdTipos.find(i => this.state.model.cdTipo === i.value)?.flSize)} label="Tamanho" value={this.state.model.nrTamanho} name="nrTamanho" onChange={this.handleChange} />

                    <SelectGrupoModulo md={6} label="Grupo do Módulo" required={true} placeholder="Selecione o Grupo do Módulo" name="idGrupoModulo" value={this.state.model.idGrupoModulo} onChange={this.handleChange} />
                    <SelectModuloByGrupoModulo idGrupoModulo={this.state.model.idGrupoModulo} md={6} label="Módulo" required={true} placeholder="Selecione o Módulo" name="idModulo" value={this.state.model.idModulo} onChange={this.handleChange} />

                    {this.state.cdTipos.find(i => this.state.model.cdTipo === i.value)?.flJson ?
                        <TextArea className="bg-dark text-white" style={{ height: "250px", fontFamily: "Courier New" }} required={true} md={12} label="Json" name="dsJson" value={this.state.model.dsJson} onChange={this.handleChange} />
                        : <></>}
                    <div className="col-md-12">
                        <BtnButton className="btn-link" onClick={this.handleClickModelo}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard2-check" viewBox="0 0 16 16">
                                <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
                                <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
                                <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z" />
                            </svg> Copiar Modelo de Lista
                        </BtnButton>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormParametro {...props} navigate={navigate} params={params} />
}

export default With