import EventBus from "../../controllers/EventBus";
import Storage from "../../controllers/Storage";
import MyComponent from "../custom/MyComponent";
import GridItem from "../forms/elements/GridItem";
import InputText from "../forms/elements/InputText";

export default class MostraValor extends MyComponent {

    constructor(props) {
        super(props);

        this.state.flVerValor = Storage.getVisualizacaoValor();
    }

    componentDidMount() {
        super.componentDidMount();

        EventBus.on("atualizar-visualizacao-valor", (value) => {
            this.setState({ flVerValor: value });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        EventBus.remove("atualizar-visualizacao-valor");
    }


    render() {
        var flVerValor = this.state.flVerValor;

        if (flVerValor && this.props.children !== undefined) {
            return this.props.children;
        }

        var cdFormat = this.props.format !== undefined ? this.props.format : false;
        const inputProps = Object.assign({}, this.props);
        delete inputProps["children"];

        inputProps["value"] = this.props.value !== undefined ? this.props.value : "OCULTO";

        switch (cdFormat) {
            case "InputText":
                return (
                    <InputText {...inputProps} />
                );
            case "GridItem":
                return (
                    <GridItem {...inputProps} />
                );
            default:
                return this.props.value !== undefined ? this.props.value : "OCULTO";
        }


    }
}