import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import PainelService from "../../services/PainelService";
import CrudForm from "../../components/forms/custom/CrudForm";
import TextArea from "../../components/forms/elements/TextArea";
import InputNumber from "../../components/forms/elements/InputNumber";
import InputCheckSwitch from "../../components/forms/elements/InputCheckSwitch";
import BtnButton from "../../components/forms/elements/BtnButton";
import BtnSubmit from "../../components/forms/elements/BtnSubmit";
import Select from "../../components/forms/elements/Select";
import Util from "../../utils/Util";
import EventBus from "../../controllers/EventBus";
import BlocoService from "../../services/BlocoService";
import Bloco from "../Painel/Bloco";
import InputUrl from "../../components/forms/elements/InputUrl";
import PerfilService from "../../services/PerfilService";

class FormPainel extends CrudForm {

    constructor(props) {
        super(props);

        this.handleClickAdicionarBloco = this.handleClickAdicionarBloco.bind(this);
        this.handleClickItem = this.handleClickItem.bind(this);
        this.handleClickUpItem = this.handleClickUpItem.bind(this);
        this.handleClickDownItem = this.handleClickDownItem.bind(this);
        this.handleClickDeleteItem = this.handleClickDeleteItem.bind(this);
        this.onEdit = this.onEdit.bind(this);

        this.handleChangePerfis = this.handleChangePerfis.bind(this);
        this.handleChangePerfisAll = this.handleChangePerfisAll.bind(this);

        this.state.model = {
            idPainel: "",
            nome: "",
            nrOrdem: 1,
            blocos: [],
            painelPerfil: []
        };

        this.state.isCard = true;
        this.state.flVerResultado = false;
        this.state.showBtnContinuar = true;
        this.state.blocoSelecionado = null;

        this.state.perfis = [];

        this.state.crud = {
            service: PainelService,
            labels: {
                title: 'Painel',
            },
            urlList: '/LstPainel',
        };

        this.state.tipos = BlocoService.getTipos();

        this.state.comandos = [
            { value: "S", label: "SQL" },
            { value: "P", label: "PHP" },
        ];

        this.state.tipoFiltro = [
            { value: "N", label: "Nenhum" },
            { value: "CD", label: "Campo Individual de Data" },
            { value: "CM", label: "Campo Individual de Mês" },
            { value: "CA", label: "Campo Individual de Ano" },
            { value: "ID", label: "Intervalo de Datas" },
            { value: "IM", label: "Intervalo de Mês" },
            { value: "IA", label: "Intervalo de Anos" },
            { value: "ND", label: "Intervalo de dias" },
        ];
    }

    componentDidMount() {
        super.componentDidMount();

        PerfilService.list((response) => {
            this.setState({ perfis: response });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onEdit(id) {
        this.setState({ flVerResultado: false }, () => {
            super.onEdit(id, () => {
                this.setState({ flVerResultado: true });
            });
        });
    }

    handleClickAdicionarBloco() {
        this.setState(state => {
            state.model.blocos.push({
                idBloco: "",
                flNovo: true,
                nome: "",
                dsDetalhe: "",
                dsCabecalho: "",
                dsTitulo: "",
                nrTempoAtualizar: "",
                cdTipo: "number",
                cdModelo: "",
                dsUrl: "",
                nrTamanhoColuna: 3,
                nrOrdem: "",
                flAgrupar: false,
                flAtivo: true,
                cdComando: "S",
                cdTipoFiltro : "N",
                nrIntervaloFiltro: 0,
                dsComando: "",
                dsConfiguracao: "{}"
            });
            return state;
        });
    }

    getIndexItemSelecionado() {
        var index = -1;
        if (parseInt(this.state.blocoSelecionado?.idBloco) > 0) {
            index = this.state.model.blocos.findIndex(i => parseInt(i.idBloco) === parseInt(this.state.blocoSelecionado?.idBloco));
        } else {
            index = this.state.blocoSelecionado?.index;
        }
        return index;
    }

    handleClickItem(bloco = null, index = null) {
        if (bloco) {
            bloco.index = index;
        }
        this.setState({ blocoSelecionado: bloco })
    }

    handleClickUpItem() {
        this.setState(state => {
            var index = this.getIndexItemSelecionado();
            var bloco1 = this.state.model.blocos[index];
            var bloco2 = this.state.model.blocos[index - 1];

            this.state.model.blocos[index] = bloco2;
            this.state.model.blocos[index - 1] = bloco1;

            return state;
        })
    }

    handleClickDownItem() {
        this.setState(state => {
            var index = this.getIndexItemSelecionado();
            var bloco1 = this.state.model.blocos[index];
            var bloco2 = this.state.model.blocos[index + 1];

            this.state.model.blocos[index] = bloco2;
            this.state.model.blocos[index + 1] = bloco1;

            return state;
        })
    }

    handleClickDeleteItem() {
        EventBus.dispatch("modal-confirmacao-open", {
            config: {
                title: "Excluir Ordem?",
                message: <>Deseja realmente excluir o bloco selecionado?</>,
                btnConfirmText: "Sim, excluir o bloco",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: () => {
                    if (parseInt(this.state.blocoSelecionado?.idBloco) > 0) {
                        BlocoService.delete(this.state.blocoSelecionado?.idBloco, () => {
                            this.getById();
                            this.handleClickItem(null);
                        });
                    } else {
                        this.setState(state => {
                            state.model.blocos.splice(this.state.blocoSelecionado.index, 1);
                            return state;
                        })

                    }
                }
            }
        })
    }

    handleChangePerfis(event, item) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.painelPerfil.push(item);
                return state;
            });
        } else {
            var index = this.state.model.painelPerfil.findIndex(i => parseInt(i.idPerfil) === parseInt(item.idPerfil));
            if (index >= 0) {
                this.setState((state) => {
                    state.model.painelPerfil.splice(index, 1);
                    return state;
                });
            }
        }
    }

    handleChangePerfisAll(event) {
        if (event.target.checked) {
            this.setState((state) => {
                state.model.painelPerfil = [];

                this.state.perfis.forEach(function (item) {
                    state.model.painelPerfil.push(item);
                });

                return state;
            });
        } else {
            this.setState((state) => {
                state.model.painelPerfil = [];
                return state;
            });
        }
    }

    getCardHeader() {
        return (
            <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                    <button className="nav-link active" id="nav-painel-tab" data-bs-toggle="tab" data-bs-target="#nav-painel" type="button" role="tab" aria-controls="nav-painel" aria-selected="true">
                        Dados
                    </button>
                </li>
                <li className="nav-item">
                    <button className="nav-link" id="nav-perfis-tab" data-bs-toggle="tab" data-bs-target="#nav-perfis" type="button" role="tab" aria-controls="nav-perfis" aria-selected="false">
                        Perfis
                    </button>
                </li>
            </ul>);
    }

    getRenderFields() {
        return (
            <div className="tab-content mx-2 my-3" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-painel" role="tabpanel" aria-labelledby="nav-painel-tab">
                    <div className="position-fixed top-0 end-0" style={{ zIndex: 99999 }}>
                        <div className="pt-5 mt-5 me-3 text-end">
                            <div className="pb-3">
                                <BtnButton onClick={this.handleClickUpItem} className="btn-primary btn-lateral rounded-pill btn-sm" disabled={!(this.getIndexItemSelecionado() > 1 && parseInt(this.state.blocoSelecionado?.idBloco) > 0)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" />
                                    </svg> <span className="texto">Promover</span>
                                </BtnButton>
                            </div>
                            <div className="pb-3">
                                <BtnButton onClick={this.handleClickDownItem} className="btn-primary btn-lateral rounded-pill btn-sm" disabled={!(this.state.blocoSelecionado !== null && parseInt(this.state.blocoSelecionado?.idBloco) > 0 && this.getIndexItemSelecionado() < (this.state.model.blocos.length - 1))}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                    </svg> <span className="texto"> Rebaixar </span>
                                </BtnButton>
                            </div>
                            <div className="pb-3">
                                <BtnButton onClick={this.handleClickDeleteItem} className="btn-danger btn-lateral rounded-pill btn-sm" disabled={!(this.state.blocoSelecionado !== null)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                    </svg> <span className="texto"> Excluir </span>
                                </BtnButton>
                            </div>
                            <hr className="hr" />
                            {Boolean(this.state.showBtnContinuar) && Boolean(this.state.isEdit) ?
                                <div className="pb-3">
                                    <BtnSubmit className="btn-success btn-lateral rounded-pill btn-sm" id={this.btnContinuarId}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-save" viewBox="0 0 16 16">
                                            <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                                        </svg> <span className="texto"> Salvar e Continuar</span>
                                    </BtnSubmit>
                                </div>
                                : <></>}
                        </div>
                    </div>

                    <div className="mb-3">
                        <div>
                            <div className="row mb-3 g-3">
                                <InputText label="Código" md={3} value={this.state.model.idPainel} name="idPainel" readOnly opcionalLabel={false} />
                                <InputText label="Nome do Painel" autoFocus={true} required md={9} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                            </div>
                        </div>

                        <div>
                            <div className="text-end mb-2">
                                <BtnButton className="btn-outline-primary btn-sm mb-3" onClick={this.handleClickAdicionarBloco}> Adicionar Bloco </BtnButton>
                            </div>

                            <div className="accordion" id="accordionBloco">
                                {this.state.model.blocos.map((bloco, index) => (
                                    <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={`heading-${index}`}>
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${index}`} aria-expanded="true" aria-controls="collapseOne" onClick={e => this.handleClickItem(bloco, index)}>
                                                {parseInt(index) + 1} - {bloco.nome || "Digite o nome do Bloco"}
                                                &nbsp;[&nbsp;<span className="fw-bold"> {this.state.tipos.find(i => bloco.cdTipo.toString() === i.value.toString())?.label}</span>&nbsp;]

                                                &nbsp; <span className="ms-3 badge text-bg-dark">{bloco.nrTamanhoColuna}</span>

                                                {Boolean(bloco.flAtivo) ? <></> : <span className="ms-3 badge text-bg-danger">INATIVO</span>}

                                                {this.getIndexItemSelecionado() === index ? <span className="ms-3 badge text-bg-primary">Selecionado</span> : <></>}


                                            </button>
                                        </h2>
                                        <div id={`collapse-${index}`} className="accordion-collapse collapse" aria-labelledby={`heading-${index}`} data-bs-parent="#accordionBloco">
                                            <div className="accordion-body">

                                                <div className="row mb-3 g-3">
                                                    <InputText label="Nome do Bloco" required md={6} value={bloco.nome} name="nome" onChange={e => (this.handleChange(e, false, bloco))} maxLength={100} />
                                                    <InputText label="Cabeçalho" required md={3} value={bloco.dsCabecalho} name="dsCabecalho" onChange={e => (this.handleChange(e, false, bloco))} maxLength={100} />
                                                    <InputText label="Título" md={3} value={bloco.dsTitulo} name="dsTitulo" onChange={e => (this.handleChange(e, false, bloco))} maxLength={100} />

                                                    <TextArea label="Detalhes" md={12} value={bloco.dsDetalhe} name="dsDetalhe" onChange={e => (this.handleChange(e, false, bloco))} />

                                                    <Select md={4} required={true} className="w-100" name="cdTipo" value={bloco.cdTipo} label="Tipo do Bloco" options={this.state.tipos} onChange={e => (this.handleChange(e, false, bloco))} />
                                                    {this.state.tipos.find(i => bloco.cdTipo.toString() === i.value.toString())?.modelos?.length > 0 ?
                                                        <Select md={4} required={true} className="w-100" name="cdModelo" value={bloco.cdModelo} label="Modelo do Bloco" options={this.state.tipos.find(i => bloco.cdTipo.toString() === i.value.toString())?.modelos} onChange={e => (this.handleChange(e, false, bloco))} />
                                                        : <></>}

                                                    {bloco.cdTipo.toString() === "iframe" ?
                                                        <InputUrl label="URL Embedar" md={4} value={bloco.dsUrl} name="dsUrl" onChange={e => (this.handleChange(e, false, bloco))} maxLength={250} required={true} />
                                                        : <></>}

                                                    <InputNumber md={2} min={10} step={1} label='Tempo de Atualização' value={bloco.nrTempoAtualizar} name="nrTempoAtualizar" onChange={e => (this.handleChange(e, false, bloco))} required={true} />
                                                    <InputNumber md={2} min={1} max={12} step={1} label='Tamanho da Coluna' value={bloco.nrTamanhoColuna} name="nrTamanhoColuna" onChange={e => (this.handleChange(e, false, bloco))} required={true} />

                                                    <Select required={true} md={4} name="cdTipoFiltro" value={bloco.cdTipoFiltro} label="Tipo de Filtro" options={this.state.tipoFiltro} onChange={e => (this.handleChange(e, false, bloco))} />
                                                    {bloco.cdTipoFiltro !== "N" ?
                                                        <InputNumber md={2} min={0} step={1} label='Intervalo do Filtro' value={bloco.nrIntervaloFiltro} name="nrIntervaloFiltro" onChange={e => (this.handleChange(e, false, bloco))} required={true} />
                                                    : <></>}
                                                </div>

                                                <div className="row mx-1 mb-3">
                                                    <InputCheckSwitch label="Ativo?" name="flAtivo" checked={Boolean(bloco.flAtivo)} onChange={e => (this.handleChange(e, false, bloco))} />
                                                    <InputCheckSwitch label="Mostrar Barra Agrupada?" name="flAgrupar" checked={Boolean(bloco.flAgrupar)} onChange={e => (this.handleChange(e, false, bloco))} />
                                                </div>

                                                <div className="row mb-3 g-3">

                                                    {this.state.tipos.find(i => bloco.cdTipo.toString() === i.value.toString())?.flComando ?
                                                        <>
                                                            <Select required={true} md={4} name="cdComando" value={bloco.cdComando} label="Tipo de Comando" options={this.state.comandos} onChange={e => (this.handleChange(e, false, bloco))} />
                                                            <TextArea className="text-bg-dark" label="Comando" md={12} value={bloco.dsComando} name="dsComando" onChange={e => (this.handleChange(e, false, bloco))} style={{ height: "400px", fontFamily: "Courier New" }} required={true} />
                                                        </>
                                                        : <></>}

                                                    <TextArea className="text-bg-dark" label="Configuração" md={12} value={bloco.dsConfiguracao} name="dsConfiguracao" onChange={e => (this.handleChange(e, false, bloco))} style={{ height: "400px", fontFamily: "Courier New" }} required={true} />

                                                    {!Util.isJsonString(bloco.dsConfiguracao) ?
                                                        <div className="alert alert-danger pt-1 pb-1" role="alert">
                                                            JSON Inválido
                                                        </div>
                                                        : <></>}
                                                </div>

                                                {Boolean(bloco.flAtivo) && parseInt(bloco.idBloco) > 0 && this.state.flVerResultado && this.getIndexItemSelecionado() === index ?
                                                    <div className="row mb-3 g-3">
                                                        <Bloco idBloco={this.state.blocoSelecionado.idBloco} autoUpdate={false} />
                                                    </div>
                                                    : <></>}

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="tab-pane fade" id="nav-perfis" role="tabpanel" aria-labelledby="nav-perfis-tab">
                    <h6> Perfis Liberadas </h6>
                    <InputCheckSwitch label="Marcar Todos" name="todos" onChange={this.handleChangePerfisAll} checked={this.state.model.painelPerfil.length === this.state.perfis.length} />
                    <div className="row mb-3">
                        {this.state.perfis.map((item) => (
                            <div key={item.idPerfil} className="col-md-6">
                                <InputCheckSwitch label={item.nome} name="modulo" checked={this.state.model.painelPerfil.findIndex(i => parseInt(i.idPerfil) === parseInt(item.idPerfil)) !== -1} onChange={(e) => { this.handleChangePerfis(e, item) }} />
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormPainel {...props} navigate={navigate} params={params} />
}

export default With