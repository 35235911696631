import Http from "../controllers/Http";

export default class ProdutoServicoService {

    static urlBase = "/produtoServico/";

    static getForCombo(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forCombo')
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboByTipo(idTipoProdutoServico, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboByTipo/' + idTipoProdutoServico)
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboByTipoAgrupador(idTipoProdutoServico, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboByTipoAgrupador/' + idTipoProdutoServico)
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboAgrupador(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboAgrupador')
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboByTipoUsuario(idTipoProdutoServico, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboByTipoUsuario/' + idTipoProdutoServico)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByIdByUsuario(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byUsuario/' + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getForComboByTipoUsuarioOrdemTipo(idTipoProdutoServico, idOrdemTipo, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'forComboByTipoUsuarioOrdemTipo/' + idTipoProdutoServico + '/' + idOrdemTipo)
            .then(fnSucess)
            .catch(fnError);
    }

    static list(fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase)
            .then(fnSucess)
            .catch(fnError);
    }

    static listForList(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'list', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static listPreco(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'listPreco', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static listLoja(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'listLoja', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneById(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static getOneByIdByUsuarioOrdemTipo(idTipoProdutoServico, idOrdemTipo, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .get(this.urlBase + 'byUsuarioOrdemTipo/' + idTipoProdutoServico + '/' + idOrdemTipo)
            .then(fnSucess)
            .catch(fnError);
    }

    static create(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static update(id, data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + id, data)
            .then(fnSucess)
            .catch(fnError);
    }

    static agrupar(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'agrupar', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static desagrupar(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'desagrupar', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static delete(id, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + "delete/" + id)
            .then(fnSucess)
            .catch(fnError);
    }

    static addArquivo(data, fnSucess = () => { }, fnError = () => { }) {
        Http.getInstanceAppJson()
            .post(this.urlBase + 'addArquivo', data)
            .then(fnSucess)
            .catch(fnError);
    }

    static save(data, fnSucess = () => { }, fnError = () => { }) {
        if (data.idProdutoServico) {
            this.update(data.idProdutoServico, data, fnSucess, fnError);
        } else {
            this.create(data, fnSucess, fnError);
        }
    }
}
