import { Component } from "react";
import FormNumber from "../base/FormNumber";

export default class InputValorReais extends Component {

    render() {
        return (
            <FormNumber {...this.props}
                thousandSeparator="."
                decimalSeparator=","
                inputMode="numeric"
                prefix={'R$ '}
                placeholder="R$ 9.999,99"
                fixedDecimalScale={true}
                decimalScale={2}
            />);
    }
}