import React from "react";
import RepresentanteEquipamentoAtualizacaoService from "../../services/RepresentanteEquipamentoAtualizacaoService";
import Util from "../../utils/Util";
import MyComponent from "../../components/custom/MyComponent";
import { toast } from "react-toastify";
import BtnButton from "../../components/forms/elements/BtnButton";
import InputNumber from "../../components/forms/elements/InputNumber";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputDate from "../../components/forms/elements/InputDate";
import DropDownMenuOrdemStatus from "../../components/app/DropDownMenuOrdemStatus";
import SelectProprietarioByUsuario from "../../components/app/SelectProprietarioByUsuario";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import Select from "../../components/forms/elements/Select";
import FormOrdem from "../Ordem/FormOrdem"
import EventBus from "../../controllers/EventBus";
import SelectEquipamento from "../../components/app/SelectEquipamento";
import FormRepresentanteEquipamento from "../Representante/FormRepresentanteEquipamento";
import Permissao from "../../controllers/Permissao";
import FormCancelarAtualizacao from "./FormCancelarAtualizacao";

export default class ListaRepresentanteEquipamentoAtualizacao extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);
        this.clear = this.clear.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);        
        this.getLista = this.getLista.bind(this);
        this.state = this.getInitState(props);
        this.handleChangeOrdemStatus = this.handleChangeOrdemStatus.bind(this);
        this.handleEditEquipamento = this.handleEditEquipamento.bind(this);
        this.handleClickAbrirOrdem = this.handleClickAbrirOrdem.bind(this);
        this.handleClickCancelarAtualizacao = this.handleClickCancelarAtualizacao.bind(this);
    }

    getInitState(props) {
        return {
            data: [],
            model: {
                pagina: 0,
                rowCount: 50,
                flFiltroData: "P",
                flFiltroSituacao : "T",
                busca: "",
                idOrdem: "",                
                dtOStart: "",
                dtOEnd: "",
                idProprietario: "",
                idRepresentante: "",
                idOrdemStatus: "",
                idEquipamento : "",
                idOrdensStatus: [1,2,3,4,5],
            },
            filtroData: [
                { value: 'P', label: 'Previsão' },
                { value: 'O', label: 'Ordem' },
            ],

            ordemStatus: [
                { idOrdemStatus: 1, nome: 'Aguardando' },
                { idOrdemStatus: 2, nome: 'Concluído' },
                { idOrdemStatus: 3, nome: 'Cancelado' },
                { idOrdemStatus: 4, nome: 'Processando' },
                { idOrdemStatus: 5, nome: 'Faturado' },
            ],

            filtroRowCount: [
                { value: 50, label: "50 Registros" },
                { value: 100, label: "100 Registros" },
                { value: 200, label: "200 Registros" },
                { value: 500, label: "500 Registros" },
                { value: 1000, label: "1000 Registros" },
            ],

            filtroSituacao: [
                { value: "T", label: "Todas" },
                { value: "PE", label: "Pendente" },
                { value: "PV", label: "Vencida" },
                { value: "SO", label: "Sem Ordem" },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount(this.getLista);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    onSubmit(event) {
        this.getLista();
    }

    getLista(merge = false) {
        if (merge) {
            RepresentanteEquipamentoAtualizacaoService.list(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                RepresentanteEquipamentoAtualizacaoService.list(this.state.model, (response) => {
                    this.setState({ data: response });
                });
            });
        }
    }

    handleChangeOrdemStatus(isChecked, item) {
        if (isChecked) {
            this.setState(state => {
                state.model.idOrdensStatus.push(item.value);
                return state;
            });
        } else {
            this.setState(state => {
                var index = this.state.model.idOrdensStatus.findIndex(i => parseInt(i) === parseInt(item.value));
                state.model.idOrdensStatus.splice(index, 1);
                return state;
            });
        }
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    handleOpenModalOrdem(item) {
        EventBus.dispatch("modal-add", {
            title: 'Dados da Ordem',
            sizeModal: 'modal-xl',
            fullScreen : true,
            config: {
                isModal: true,
                idOrdem: item.idOrdem
            },
            form: FormOrdem,
            onClose: () => {
                this.getLista();
            },
            onSave: (response) => {
                this.getLista();
            }
        });
    }

    handleEditEquipamento(item) {
        EventBus.dispatch("modal-add", {
            title: 'Equipamentos do Representante',
            sizeModal: 'modal-xl',
            scrollable: false,
            config: {
                isModal: true,
                id : item.idRepresentanteEquipamento
            },
            form: FormRepresentanteEquipamento,
            onSave: (response) => {
                this.getLista();
            },
        });
    }

    handleClickCancelarAtualizacao(item) {
            EventBus.dispatch("modal-add", {
                title: 'Cancelar Atualização',
                sizeModal: 'modal-lg',
                scrollable: false,
                config: {
                    isModal: true,
                    idRepresentanteEquipamentoAtualizacao: item.idRepresentanteEquipamentoAtualizacao
                },
                form: FormCancelarAtualizacao,
                onSave: (response) => {
                    this.getLista();
                },
            });

        /*EventBus.dispatch("modal-confirmacao-open", {
            config: {
                title: "Cancelar atualização?",
                message: <>Deseja realmente cajcelar a atualização para o equipamento selecionado?</>,
                btnConfirmText: "Sim, cancelar a atualização",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: () => {                    
                    RepresentanteEquipamentoAtualizacaoService.cancelarAtualizacao(item.idRepresentanteEquipamentoAtualizacao, () =>{
                        this.getLista();
                    });
                }
            },
        })*/
    }

    getClassStatus(idOrdemStatus) {
        switch (parseInt(idOrdemStatus)) {
            case 1:
                return "text-bg-warning";
            case 2:
                return "text-bg-success";
            case 3:
                return "text-bg-danger";
            case 4:
                return "text-bg-primary";
            case 5:
                return "text-bg-dark";
            default:
                return "text-bg-light";
        }
    }

    getRenderFilter(){
        return (
            <div className="row g-2">
                <InputNumber md={1} sizing="sm" label="Ordem" placeholder="9999" value={this.state.model.idOrdem} name="idOrdem" onChange={this.handleChange} min={1} opcionalLabel={false} />                        
                <BtnGroup md={2} sizing="sm" className="w-100" name="flFiltroData" value={this.state.model.flFiltroData} label="Data da" options={this.state.filtroData} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />                        
                <InputDate md={2} sizing="sm" label="Data Inicial" value={this.state.model.dtOStart} name="dtOStart" onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                <InputDate md={2} sizing="sm" label="Data Final" value={this.state.model.dtOEnd} name="dtOEnd" onChange={(e) => { this.handleChange(e, this.onSubmit) }} min={this.state.model.dtOStart} opcionalLabel={false} />
                <BtnGroup md={5} sizing="sm" className="w-100" name="flFiltroSituacao" value={this.state.model.flFiltroSituacao} label="Situação" options={this.state.filtroSituacao} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />                        
                
                <DropDownMenuOrdemStatus label="Status da Ordem" sizing="sm" placeholder="Selecione o(s) status" className="btn-outline-primary text-nowrap" md={5} values={this.state.model.idOrdensStatus} name="idOrdensStatus" handleChangeItem={this.handleChangeOrdemStatus} opcionalLabel={false}/>
                <SelectProprietarioByUsuario showBtnNewForm={false} sizing="sm" md={7} label="Proprietário" placeholder="Selecione o Proprietario" name="idProprietario" value={this.state.model.idProprietario} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                
                <SelectRepresentanteByUsuario showBtnNewForm={false} sizing="sm" md={5} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                <SelectEquipamento showBtnNewForm={false} sizing="sm" md={5} label="Equipamento" placeholder="Selecione o Equipamento" name="idEquipamento" value={this.state.model.idEquipamento} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                <Select label="Registros por Página" md={2} sizing="sm" value={this.state.model.rowCount} required={true} options={this.state.filtroRowCount} name="rowCount" onChange={(e) => { this.handleChange(e, this.onSubmit) }}></Select>

                <div className="col-md-12 mt-3">
                    <div className="row gap-2">
                        <div className="col">
                            <div className="col-md-12 input-group">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control form-control-sm" autoFocus autoComplete="off" name="busca" placeholder="Digite a sua busca" value={this.state.model.busca} onChange={this.handleChange} />
                                <button className="btn btn-outline-primary btn-sm" type="submit"> Buscar </button>
                            </div>
                        </div>
                        <div className="col-md-auto text-end d-grid d-md-block">
                            <button type="button" className="btn btn-outline-danger btn-sm" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar Busca
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleClickAbrirOrdem(item) {
        EventBus.dispatch("modal-confirmacao-open", {
            config: {
                title: "Abrir ordem para esse equipamento?",
                message: <>Deseja realmente abrir a ordem para o equipamento selecionado?</>,
                btnConfirmText: "Sim, abrir a ordem",
                description: "Esta é uma ação é irreversível!",
                style: "warning",
                confirm: () => {                    
                    RepresentanteEquipamentoAtualizacaoService.criarOrdem(item.idRepresentanteEquipamentoAtualizacao, () =>{
                        this.getLista();
                    });
                }
            },

        })
    }

    getRenderHeader() {
        return (
            <>
                <th className="text-center d-none d-md-table-cell"> # </th>
                <th className="col-md-3"> Representante </th>
                <th className="col-md-5"> Equipamento </th>
                <th className="col-md-1 text-center"> Previsão </th>
                <th className="col-md-3 text-center"> Ordem </th>
                <th className="col-md text-center"> Status</th>
                <th></th>
            </>
        );
    }

    getRenderItem(item, index) {

        var colunaPrevisao = (<td className="align-middle text-center"> {Util.date2Br(item.dtPrevista)}</td>);

        if(Boolean(item.flVencida)){
            colunaPrevisao = (
                <td className="align-middle text-center bg-danger text-bg-danger"> 
                    {Util.date2Br(item.dtPrevista)} <br/> <span className="fst-italic fs-6">  à {parseInt(item.nrDiasVencer) * -1} dias</span> 
                </td>);
        }else if(parseInt(item.nrDiasVencer) >= 0){
            colunaPrevisao = (
                <td className="align-middle text-center"> 
                    {Util.date2Br(item.dtPrevista)} <br/> <span className="fst-italic fs-6"> em {item.nrDiasVencer} dia(s)</span> 
                </td>);
         }else if(parseInt(item.nrDiasVencer) < 0){
            colunaPrevisao = (
                <td className="align-middle text-center"> 
                    {Util.date2Br(item.dtPrevista)} <br/> <span className="fst-italic fs-6"> à {parseInt(item.nrDiasVencer) * -1} dias</span> 
                </td>);
        }

        return (
            <>
                <td className="align-middle text-center d-none d-md-table-cell">{item.idRepresentanteEquipamentoAtualizacao}</td>
                <td className="align-middle">{item.nomeRepresentante}</td>
                <td>
                    <strong>{item.nomeEquipamento}</strong>                    
                    <br/>{item.dsMarcaEquipamento} - {item.dsModeloEquipamento}
                    <br/>{item.dsChaveEquipamento} - {item.nrSerieEquipamento}
                    <br/><span className="badge bg-primary">atualização a cada {item.nrFrequenciaAtualizacao} mes(es)</span>
                    <br/><span className="fst-italic fs-7"><strong>Última atualização em:</strong> {Util.date2Br(item.dtUltimaAtualizacao)}</span>                        
                </td>
                {colunaPrevisao}
                <td className="align-middle border-start text-center">
                    {parseInt(item.idOrdem) > 0 ?
                    <>
                        <BtnButton className="btn-secondary btn-sm pt-0 pb-0 w-100" onClick={() => { this.handleOpenModalOrdem(item) }}> #{item.idOrdem} </BtnButton>
                        <br/>{Util.date2Br(item.dataOrdem)} {item.horaOrdem}
                        <br/>{item.nomeCategoriaProdutoServico}
                        <br/>{item.nomeTipoProdutoServico}
                    </>  : 
                    <span className="badge w-100 fs-7 text-bg-info">
                        Ordem não criada
                    </span>
                    }
                </td>
                <td className="align-middle text-center">
                    <span className={`rounded-pill badge w-100 fs-7 ${this.getClassStatus(item.idOrdemStatus)}`}> {item.nomeOrdemStatus} </span>
                </td>

                <td className="align-middle">
                    {Permissao.getInstance().getPermissoes([8,112,13]) ? 
                        <div className="dropdown dropstart">
                                <button className="btn btn-outline-dark " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                    </svg>
                                </button>                       
                                

                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        {Permissao.getInstance().getPermissao(13) ?
                                            <li>                                
                                                <button type="button" className="dropdown-item"  onClick={() => { this.handleOpenModalOrdem(item) }} disabled={!(parseInt(item.idOrdem) > 0)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                                    </svg> Ver Ordem
                                                </button>
                                            </li> : <></>}
                                        {Permissao.getInstance().getPermissao(8) ? 
                                            <li>
                                                <button type="button" className="dropdown-item" onClick={() => { this.handleEditEquipamento(item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-card-list" viewBox="0 0 16 16">
                                                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                                                        <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                                    </svg> Detalhe do Equipamento
                                                </button>
                                            </li> : <></>
                                        }
                                        {Permissao.getInstance().getPermissao(112) ? 
                                        <>
                                            <li>
                                                <button type="button" className={`dropdown-item ${parseInt(item.idOrdem) > 0 || parseInt(item.idOrdemStatus) === 3 ? "disabled": ""}`} onClick={() => { this.handleClickAbrirOrdem(item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                    </svg> Abrir Ordem
                                                </button>
                                            </li>
                                            <li>
                                                <button type="button" className={`dropdown-item ${parseInt(item.idOrdem) > 0 || parseInt(item.idOrdemStatus) === 3 ? "disabled": ""}`} onClick={() => { this.handleClickCancelarAtualizacao(item) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                    </svg> Cancelar Atualização
                                                </button>
                                            </li>                                    
                                        </> : <></>}
                                </ul>                        
                        </div>
                    : <></>}
                </td>
            </>);
    }

    render() {
        return (
            <div className="p-4 my-2 py-2 w-100">
                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100">Gestão de Atualização dos Equipamentos dos Representantes</h4>
                </div>
                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    {this.getRenderFilter()}
                </form>
                <div className="table-responsive-sm mt-2">
                    <table className="table table-sm caption-top table-hover table-striped">
                        <caption className="text-end">
                            <div className="hstack d-none d-lg-block">
                                <div className="hstack gap-2 w-100">
                                    <div className="ms-auto">
                                        {this.state.data.length} Registro(s)
                                    </div>
                                </div>
                            </div>
                        </caption>
                        <thead>
                            <tr>
                                {this.getRenderHeader()}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <tr key={index}>
                                    {this.getRenderItem(item, index)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="text-center pt-3 pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>
                
            </div>
        );
    }
}