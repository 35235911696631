export default class Modal {

    count = 0;
    static instance = null;

    static getInstance() {
        if (this.instance === null) {
            this.instance = new Modal();
        }
        return this.instance;
    }

    addModal() {
        this.count = this.count + 1;
        return this.count;
    }

    delModal() {
        this.count = this.count - 1;
        return this.count;
    }

    getCount() {
        return this.count;
    }
}