import React, { Component } from "react";
import EventBus from "../../controllers/EventBus";

export default class ModalConfirmacao extends Component {

    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleKeyUpModal = this.handleKeyUpModal.bind(this);

        this.state = {
            element: {},
            show: false,
            title: props.title || "",
            sizing: props.sizing !== undefined ? props.sizing : false,
            message: props.message || "",
            description: props.description || "",
            confirm: props.confirm || (() => { }),
            close: props.close || (() => { }),
            EventBusOpen: props.id ? props.id + "-open" : "modal-confirmacao-open",

            btnConfirmText: props["btn-confirm-text"] || "Confirmar",
            btnCancelText: "Cancelar",
            style: "primary",
            ref: React.createRef(),
            refBtnConfirm: React.createRef()
        };
    }

    componentDidMount() {
        EventBus.on(this.state.EventBusOpen, (element) => {

            if (element.config) {
                element.config.sizing = element.config.sizing !== undefined ? element.config.sizing : false;

                this.setState((state) => {
                    Object.keys(element.config).forEach(key => {
                        state[key] = element.config[key];
                    });
                    return state;
                });
            }

            this.setState({ show: true, element: element }, () => {
                if (this.state.refBtnConfirm.current) {
                    this.state.refBtnConfirm.current.focus();
                }
            });
        });
    }

    componentWillUnmount() {
        EventBus.remove(this.state.EventBusOpen);
    }

    handleClose() {
        this.setState({ show: false });
        this.state.close(this.state.element);
    }

    handleConfirm() {
        this.setState({ show: false });
        this.state.confirm(this.state.element);
    }

    handleKeyUpModal(event) {
        if (event.keyCode === 27) {
            this.handleClose(event);
        }
    }

    render() {
        return (
            <>
                <div className={`fade modal-backdrop show ${this.state.show ? "" : "d-none"}`}> </div>
                <div className={`modal ${this.state.show ? "d-block" : "d-none"}`} id="exampleModal" tabIndex="-1"
                    ref={this.state.ref}
                    onKeyUp={this.handleKeyUpModal}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="false" aria-modal="true">
                    <div className={`modal-dialog ${this.state.sizing ? "modal-" + this.state.sizing : ""}`}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {this.state.title}
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleClose}></button>
                            </div>
                            <div className={`modal-confirmacao-${this.state.style} modal-body p-4`}>
                                <div className="row">
                                    <div className="col-auto d-flex align-items-center justify-content-center">
                                        <svg className={`text-${this.state.style} bi bi-question-diamond-fill`} xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM5.495 6.033a.237.237 0 0 1-.24-.247C5.35 4.091 6.737 3.5 8.005 3.5c1.396 0 2.672.73 2.672 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.105a.25.25 0 0 1-.25.25h-.81a.25.25 0 0 1-.25-.246l-.004-.217c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.803 0-1.253.478-1.342 1.134-.018.137-.128.25-.266.25h-.825zm2.325 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z" />
                                        </svg>
                                    </div>
                                    <div className="col">
                                        <span className="fs-6">{this.state.message}</span>
                                        <div className="fs-6 fw-light fst-italic">{this.state.description}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className={`btn btn-outline-secondary`} data-bs-dismiss="modal" onClick={this.handleClose}>
                                    {this.state.btnCancelText}
                                </button>
                                <button ref={this.state.refBtnConfirm} type="button" className={`btn btn-${this.state.style}`} onClick={this.handleConfirm}>
                                    {this.state.btnConfirmText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}