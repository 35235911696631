import React from "react";
import NumberFormat from 'react-number-format';
import FormField from "./FormField";

export default class FormNumber extends FormField {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.state.props = {};
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    handleChange(values, sourceInfo) {
        const { floatValue } = values;

        var event = {
            target: {
                value: floatValue === undefined ? "" : floatValue,
                name: this.props.name
            }
        };

        super.handleChange(event);
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    handleBlur(event) {
        super.handleBlur(event);
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }
    }

    render() {
        var className = `form-control ${this.props.className ? this.props.className : ""} ${this.props.sizing ? "form-control-" + this.props.sizing : ""}`;

        var render =
            <NumberFormat
                {...this.getProps({ ...this.props, ...this.state.props }, ["onChange"])}
                ref={this.state.ref}
                className={className}
                onValueChange={this.handleChange}
                onBlur={this.handleBlur}
            />;

        return this.getRenderFormControl(render);
    }
}