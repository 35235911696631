import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputText from "../../components/forms/elements/InputText";
import ProdutoServicoClassificacaoService from "../../services/ProdutoServicoClassificacaoService";
import CrudForm from "../../components/forms/custom/CrudForm";
import FormTreeView from "../../components/forms/base/FormTreeView";
import Util from "../../utils/Util";

class FormProdutoServicoClassificacao extends CrudForm {

    constructor(props) {
        super(props);

        this.state.model = {
            idProdutoServicoClassificacao: "",
            nome: "",
            idProdutoServicoClassificacaoPai: ""
        };

        this.state.tree = [];

        this.state.crud = {
            service: ProdutoServicoClassificacaoService,
            labels: {
                title: 'Classificação',
            },
            urlList: '/LstProdutoServicoClassificacao',
        };
    }

    componentDidMount() {
        super.componentDidMount();

        ProdutoServicoClassificacaoService.listForTree((response) => {
            this.setState({ tree: Util.changePropertyRecursive(response, "opened", true, "options") });
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    getRenderFields() {
        return (
            <div className="row mb-2">
                <div className="col-6">
                    <div className="card">
                        <div className="card-header">
                            Item Superior
                        </div>
                        <div className="card-body">
                            <FormTreeView openAll={true} options={this.state.tree} value={this.state.model.idProdutoServicoClassificacaoPai} name="idProdutoServicoClassificacaoPai" onChange={this.handleChange} />
                        </div>
                    </div>

                </div>
                <div className="col-6">
                    <div className="row mb-3 g-3">
                        <InputText label="Código" md={4} value={this.state.model.idProdutoServicoClassificacao} name="idProdutoServicoClassificacao" readOnly opcionalLabel={false} />
                        <InputText label="Nome da Classificação" autoFocus={true} required md={12} value={this.state.model.nome} name="nome" onChange={this.handleChange} maxLength={100} />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormProdutoServicoClassificacao {...props} navigate={navigate} params={params} />
}

export default With