import React from "react";
import InputText from "../../components/forms/elements/InputText";
import CrudForm from "../../components/forms/custom/CrudForm";
import { useNavigate, useParams } from "react-router-dom";
import FaturaEmbalagemService from "../../services/FaturaEmbalagemService";
import InputUrl from "../../components/forms/elements/InputUrl";

class FormEmbalagem extends CrudForm {

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.getFaturaEmabalagem = this.getFaturaEmabalagem.bind(this);

        this.state.model = {
            idFaturaEmbalagem: this.props.idFaturaEmbalagem,
            urlRastreamento: "",
            cdRastreamento: "",
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.getFaturaEmabalagem();
    }

    getFaturaEmabalagem() {
        FaturaEmbalagemService.getOneById(this.props.idFaturaEmbalagem, (response) => {
            this.setModel(response);
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onSubmit(event) {
        FaturaEmbalagemService.update(this.state.model.idFaturaEmbalagem, this.state.model, (response) => {
            this.onSave(response);
        });
    }

    getRenderFields() {
        return (
            <div className="row mb-3 g-3">
                <InputText label="Código de Rastreio" lg={12} value={this.state.model.cdRastreamento} name="cdRastreamento" onChange={this.handleChange} maxLength={100} />
                <InputUrl label="URL de Rastreio" lg={12} value={this.state.model.urlRastreamento} name="urlRastreamento" onChange={this.handleChange} maxLength={250} />
            </div>
        );
    }

    render() {
        return super.render();
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <FormEmbalagem {...props} navigate={navigate} params={params} />
}

export default With