import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EventBus from "../../controllers/EventBus";
import MyComponent from "../../components/custom/MyComponent";
import Util from "../../utils/Util";
import ModalConfirmacao from "../../components/modal/ModalConfirmacao";
import InputDate from "../../components/forms/elements/InputDate";
import SelectRepresentanteByUsuario from "../../components/app/SelectRepresentanteByUsuario";
import InputNumber from "../../components/forms/elements/InputNumber";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import SelectProprietarioByUsuario from "../../components/app/SelectProprietarioByUsuario";
import BtnButton from "../../components/forms/elements/BtnButton";
import MostraValor from "../../components/app/MostraValor";
import { toast } from "react-toastify";
import Select from "../../components/forms/elements/Select";
import AcordoService from "../../services/AcordoService";
import FormOrdem from "../Ordem/FormOrdem";
import FormFatura from "./FormFatura";
import FormFaturaAcordo from "./FormFaturaAcordo";

class ListaAcordo extends MyComponent {

    constructor(props) {
        super(props);

        this.getInitState = this.getInitState.bind(this);        
        this.clear = this.clear.bind(this);
        this.handleClickEstornar = this.handleClickEstornar.bind(this);
        this.handleClickMaisRegistros = this.handleClickMaisRegistros.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getLista = this.getLista.bind(this);

        this.state = this.getInitState(props);

        this.state.filtroPagamento = [
            { value: -1, label: 'Todos' },
            { value: 1, label: 'Pagos' },
            { value: 0, label: 'Não Pagos' },
        ];

        this.state.filtroVencimento = [
            { value: -1, label: 'Todos' },
            { value: 1, label: 'Vencido' },
            { value: 0, label: 'Em Dia' },
        ];

        this.state.filtroTipo = [
            { value: "T", label: "Todos" },
            { value: "P", label: "Produto" },
            { value: "S", label: "Serviço" },
        ];

        this.state.tipoPessoa = [
            { value: "F", label: "Física" },
            { value: "J", label: "Jurídica" },
            { value: "E", label: "Estrangeiro" },
        ];

        this.cache = {
            active: true,
            id: "cache-lista-acordo",
            clear: true,
            state: {
                model: {
                    busca: true,
                    idFatura: true,
                    idCobranca: true,
                    idOrdem: true,
                    nrNota: true,
                    dtStart: true,
                    dtEnd: true,
                    idRepresentante: true,
                    idProprietario: true,
                    idTipoProdutoServico: true,

                    pagina: true,
                    rowCount: true,

                    flPago: true,
                    flVencido: true,
                    anoReferencia: true,
                    mesReferencia: true,
                    flFiltroCdTipo: true,
                    flFiltroData: true,
                    flFiltroOrdem: true,
                    flFiltroTipoOrdem: true,
                }
            }
        };
    }

    getInitState(props) {

        return {
            data: [],
            model: {
                busca: "",
                idAcordo: "",
                idFatura: "",
                idCobranca: "",
                idOrdem: "",
                nrNota: "",
                dtStart: "",
                dtEnd: "",
                idRepresentante: "",
                idProprietario: "",

                pagina: 0,
                rowCount: 50,

                flPago: -1,
                flVencido: -1,
                flFiltroCdTipo: "T",
                flFiltroData: "V",
                flFiltroOrdem: "V",
                flFiltroTipoOrdem: "DESC",
            },
            tipos: [
                { value: "P", label: "PRODUTO" },
                { value: "S", label: "SERVIÇO" },
            ],

            filtroData: [
                { value: 'V', label: 'Vencimento' },
                { value: 'F', label: 'Fatura' },
            ],

            filtroOrdem: [
                { value: 'V', label: 'Próx. Vencimento' },
                { value: 'F', label: 'Fatura' },
            ],
            
            filtroTipoOrdem: [
                { value: 'ASC', label: 'Crescente' },
                { value: 'DESC', label: 'Decrescente' },
            ],

            filtroRowCount: [
                { value: 50, label: "50 Registros" },
                { value: 100, label: "100 Registros" },
                { value: 200, label: "200 Registros" },
                { value: 500, label: "500 Registros" },
                { value: 1000, label: "1000 Registros" },
            ],
        };
    }

    componentDidMount() {
        super.componentDidMount(this.getLista);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    clear() {
        this.setState(this.getInitState(), () => {
            this.getLista();
        });
    }

    getLista(merge = false) {
        if (merge) {
            AcordoService.list(this.state.model, (response) => {
                this.setState({ data: this.state.data.concat(response) });
                if (response.length === 0) {
                    toast.warning("Nenhum registro encontrado!");
                    this.setModel({ pagina: -1 });
                }
            });
        } else {
            this.setState(state => {
                state.data = [];
                state.model.pagina = 0;
                return state;
            }, () => {
                AcordoService.list(this.state.model, (response) => {
                    this.setState({ data: response });
                });
            });
        }
    }

    handleClickMaisRegistros() {
        if (this.state.model.pagina >= 0 && (this.state.data.length % this.state.model.rowCount) === 0) {
            this.setModel({ pagina: this.state.model.pagina + 1 }, () => { this.getLista(true) });
        }
    }

    handleClickCopiar(item) {
        AcordoService.getOneById(item.idAcordo, (acordo)=> {

            var faturas = acordo?.acordoCobranca?.map(item => item.idFatura)?.filter((value, index, self) => self.indexOf(value) === index);

            EventBus.dispatch("modal-add", {
                title: "Formulário de Acordo",
                sizeModal: 'modal-xl',
                fullScreen : true,
                config: {
                    isModal: true,
                    idProprietario : acordo.idProprietario,
                    idRepresentante : acordo.idRepresentante,
                    idOrdemTipo : acordo.idOrdemTipo,
                    flMulta: acordo.flMulta ? "S" : "N",
                    flJuros: acordo.flJuros ? "S" : "N",
                    flVencer: acordo.flVencer ? "S" : "N",
                    idFaturas: faturas
                },            
                form: FormFaturaAcordo,
                onSave: (response) => {
                    this.props.navigate('/Faturar/' + response.data.idOrdem);
                }
            });
        })
    }

    handleClickEstornar(item) {
        EventBus.dispatch("modal-confirmacao-open", {
            id: item.idFatura,
            config: {
                title: "Estornar Acordo?",
                message: <>Deseja realmente estornar a acordo: <strong>{item.idAcordo} </strong>?</>,
                btnConfirmText: "Sim, estornar o acordo",
                description: "Esta é uma ação é irreversível!",
                style: "danger",
                confirm: () => {
                    AcordoService.estornar(item.idAcordo, () => {
                        this.getLista();
                    });
                }
            }
        })
    }

    handleClickVerOrdem(item) {
        EventBus.dispatch("modal-add", {
            title: 'Ordem',
            sizeModal: 'modal-xl',
            config: {
                isModal: true,
                idOrdem: item.idOrdem
            },
            form: FormOrdem
        });
    }

    handleClickVerFatura(item) {
        EventBus.dispatch("modal-add", {
            title: 'Ordem',
            sizeModal: 'modal-xl',
            fullScreen : true,
            config: {
                isModal: true,
                idFatura: item.idFatura
            },
            form: FormFatura
        });
    }

    onSubmit(event) {
        this.getLista();
    }

    getStatus(item) {       

        if (parseInt(item.flEstornado) === 1) {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-danger "> Estornado </span>
        }

        if (parseInt(item.flExpirado) === 1) {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-dark"> Expirado </span>
        }

        if (parseInt(item.flEfetivado) === 1) {
            return <span className="rounded-pill badge w-100 fs-7 text-bg-success"> Efetivado </span>
        }

        return <span className="rounded-pill badge w-100 fs-7 text-bg-warning"> Pré-Acordo </span>
    }

    render() {
        return (
            <div className="p-4 my-2 py-2 w-100">
                <div className="hstack gap-3 mb-1">
                    <h4 className="mb-3 w-100">Lista de Acordo(s)</h4>
                </div>
                <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                    <div className="row mb-3 g-2">
                        <InputNumber md={1} sizing="sm" label="Acordo" placeholder="9999" value={this.state.model.idAcordo} name="idAcordo" onChange={this.handleChange} min={1} opcionalLabel={false} />
                        <InputNumber md={1} sizing="sm" label="Fatura" placeholder="9999" value={this.state.model.idFatura} name="idFatura" onChange={this.handleChange} min={1} opcionalLabel={false} />
                        <InputNumber md={1} sizing="sm" label="Cobrança" placeholder="9999" value={this.state.model.idCobranca} name="idCobranca" onChange={this.handleChange} min={1} opcionalLabel={false} />
                        
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flPago" value={this.state.model.flPago} label="Status Pagamento" options={this.state.filtroPagamento} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flVencido" value={this.state.model.flVencido} label="Status Vencimento" options={this.state.filtroVencimento} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <BtnGroup md={2} sizing="sm" className="w-100" name="flFiltroCdTipo" value={this.state.model.flFiltroCdTipo} label="Tipo da Ordem" options={this.state.filtroTipo} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />

                        <InputDate md={2} sizing="sm" label={`Data Acordo Inicial`} value={this.state.model.dtStart} name="dtStart" onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <InputDate md={2} sizing="sm" label={`Data Acordo Final`} value={this.state.model.dtEnd} name="dtEnd" onChange={(e) => { this.handleChange(e, this.onSubmit) }} min={this.state.model.dtStart} opcionalLabel={false} />
                        
                        <SelectRepresentanteByUsuario showBtnNewForm={false} sizing="sm" md={3} label="Representante" placeholder="Selecione o Representante" name="idRepresentante" value={this.state.model.idRepresentante} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        <SelectProprietarioByUsuario showBtnNewForm={false} sizing="sm" md={3} label="Proprietário" placeholder="Selecione o Proprietario" name="idProprietario" value={this.state.model.idProprietario} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                        
                        <Select label="Registros por Página" md={2} sizing="sm" value={this.state.model.rowCount} required={true} options={this.state.filtroRowCount} name="rowCount" onChange={(e) => { this.handleChange(e, this.onSubmit) }}></Select>                        
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-10">
                            <div className=" input-group">
                                <span className="input-group-text" id="addon-wrapping">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                                <input type="text" className="form-control form-control-sm" autoFocus autoComplete="off" name="busca" placeholder="Digite a sua busca" value={this.state.model.busca} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="col d-flex align-items-end bd-highlight">
                            <button className="ms-auto btn btn-outline-primary btn-sm mx-2 text-nowrap" type="submit"> Buscar </button>
                            <button type="button" className="btn btn-outline-danger btn-sm text-nowrap" onClick={this.clear}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                    <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                                </svg> Limpar
                            </button>
                        </div>
                    </div>
                </form>

                <div className="table-responsive-sm">
                    <table className="table table-sm caption-top table-striped" style={{ fontSize: ".850em" }}>
                        <caption className="text-end">
                            {this.state.data.length} Registro(s)
                        </caption>
                        <thead>
                            <tr>
                                <th className="d-none d-md-table-cell"></th>
                                <th className="d-none d-md-table-cell"> Acordo </th>
                                <th className="text-center d-none d-md-table-cell"> Ordem </th>
                                <th className="text-center d-none d-md-table-cell"> Fatura </th>
                                <th className="col-md-3"> Proprietário </th>
                                <th className="col-md-3"> Tipo </th>
                                <th className="col-md-3"> Usuário </th>
                                <th className="text-center text-nowrap"> Juros </th>
                                <th className="text-center text-nowrap"> Multa </th>
                                <th className="text-center text-nowrap"> À Vencer </th>
                                <th className="text-center d-none d-md-table-cell text-nowrap"> Dt. Acordo </th>
                                <th className="text-center d-none d-md-table-cell"> Parcelas </th>
                                <th className="col-md-1 text-end"> Valor </th>
                                <th className="col-md-1 text-center d-none d-md-table-cell"> Status </th>
                                <th className="text-end">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td className="fs-9 text-end text-secondary align-middle d-none d-md-table-cell">
                                            {index + 1}.
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            {item.idAcordo}
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            <BtnButton className="btn-sm btn-outline-success" onClick={e => { this.handleClickVerOrdem(item, index) }}>
                                                {item.idOrdem}
                                            </BtnButton>
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">                                            
                                            {parseInt(item.idFatura) > 0 ? 
                                                <BtnButton className="btn-sm btn-outline-success" onClick={e => { this.handleClickVerFatura(item, index) }}>
                                                    {item.idFatura}
                                                </BtnButton>
                                            : <></>}
                                        </td>
                                        <td className="align-middle text-uppercase">
                                            {item.nomeProprietario}
                                        </td>
                                        <td className="align-middle text-uppercase">
                                            {item.nomeOrdemTipo}
                                        </td>
                                        <td className="align-middle text-uppercase">
                                            {item.nomeUsuario}
                                        </td>
                                        <td className="text-center text-nowrap">
                                            {item.flJuros ? 
                                                <span className="rounded-pill badge fs-7 text-bg-success p-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                                                    </svg>
                                                </span> : 
                                                <span className="rounded-pill badge fs-7 text-bg-danger p-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                                    </svg>
                                                </span>
                                            }
                                        </td>
                                        <td className="text-center text-nowrap">
                                            {item.flMulta ? 
                                                <span className="rounded-pill badge fs-7 text-bg-success p-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                                                    </svg>
                                                </span> : 
                                                <span className="rounded-pill badge fs-7 text-bg-danger p-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                                    </svg>
                                                </span>
                                            }
                                        </td>
                                        <td className="text-center text-nowrap">
                                            {item.flVencer ? 
                                                <span className="rounded-pill badge fs-7 text-bg-success p-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                                        <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
                                                    </svg>
                                                </span> : 
                                                <span className="rounded-pill badge fs-7 text-bg-danger p-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                                    </svg>
                                                </span>
                                            }
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            {Util.date2Br(item.dhInsert)}
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            {parseInt(item.idFatura) > 0 ? `${item.nrParcelas}x` : ""}
                                        </td>
                                        <td className="align-middle text-end text-end text-primary fw-bold">
                                            <MostraValor>{Util.floatToReais(item.vlAcordo)}</MostraValor>
                                        </td>
                                        <td className="align-middle text-center d-none d-md-table-cell">
                                            {this.getStatus(item)}
                                        </td>
                                        <td className="hstack gap-2 text-nowrap">

                                            <Link to={`/CadAcordo/${item.idAcordo}`} className="btn btn-sm btn-outline-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                </svg>
                                            </Link>

                                            {(parseInt(item.flEstornado) === 1 || parseInt(item.flExpirado) === 1) && parseInt(item.flEfetivado) === 0 ?
                                                <BtnButton className="btn btn-sm btn-outline-success" onClick={e => { this.handleClickCopiar(item, index) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                                                    </svg>
                                                </BtnButton>
                                            : 
                                                <BtnButton disabled className="btn btn-sm btn-outline-secondary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                                                    </svg>
                                                </BtnButton>  
                                            }

                                            {parseInt(item.flEstornado) === 0 && parseInt(item.flExpirado) === 0 && parseInt(item.flEfetivado) === 0 ?
                                                <Link to={`/Faturar/${item.idOrdem}`}  className="btn btn-sm btn-outline-success">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
                                                        <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.2 2.2 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
                                                    </svg>
                                                </Link>
                                            : 
                                                <BtnButton disabled className="btn btn-sm btn-outline-secondary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-thumbs-up" viewBox="0 0 16 16">
                                                        <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.2 2.2 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
                                                    </svg>
                                                </BtnButton>  
                                            }

                                            {parseInt(item.flEstornado) === 0 && parseInt(item.flEfetivado) === 0 ?
                                                <BtnButton className="btn-sm btn-outline-danger" onClick={e => { this.handleClickEstornar(item, index) }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-thumbs-down" viewBox="0 0 16 16">
                                                        <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856s-.036.586-.113.856c-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a10 10 0 0 1-.443-.05 9.36 9.36 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a9 9 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581s-.027-.414-.075-.581c-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.2 2.2 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.9.9 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
                                                    </svg>
                                                </BtnButton>
                                            : <></>  }

                                            {parseInt(item.flEstornado) === 0 && parseInt(item.flEfetivado) === 1 ?
                                                <Link to={`/CadFatura/${item.idFatura}`}  className="btn btn-sm btn-outline-danger">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-thumbs-down" viewBox="0 0 16 16">
                                                        <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856s-.036.586-.113.856c-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a10 10 0 0 1-.443-.05 9.36 9.36 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a9 9 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581s-.027-.414-.075-.581c-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.2 2.2 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.9.9 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
                                                    </svg>
                                                </Link>
                                            : <></>  }

                                            {parseInt(item.flEstornado) === 1 ?
                                                <BtnButton className="btn-sm btn-outline-secondary" disabled>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-thumbs-down" viewBox="0 0 16 16">
                                                        <path d="M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856s-.036.586-.113.856c-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a10 10 0 0 1-.443-.05 9.36 9.36 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a9 9 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581s-.027-.414-.075-.581c-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.2 2.2 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.9.9 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1"/>
                                                    </svg>
                                                </BtnButton>
                                            : <></>  }
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="text-center pb-5">
                    {this.state.data.length > 0 ?
                        <div className="d-grid col-md-6 mx-auto">
                            <BtnButton className="btn-primary" onClick={this.handleClickMaisRegistros} disabled={this.state.model.pagina === -1 || (this.state.data.length % this.state.model.rowCount) !== 0}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                </svg> Carregar Mais Registros
                            </BtnButton>
                        </div>
                        : <></>
                    }
                </div>

                <ModalConfirmacao id="modal-fatura" />
            </div >
        )
    }
}

function With(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <ListaAcordo {...props} navigate={navigate} params={params} />
}

export default With