import React from "react";
import FeriadoService from "../../services/FeriadoService";
import CrudList from "../../components/forms/custom/CrudList";
import Util from "../../utils/Util";
import SelectPais from "../../components/app/SelectPais";
import SelectEstadoByPais from "../../components/app/SelectEstadoByPais";
import SelectCidadeByEstado from "../../components/app/SelectCidadeByEstado";
import BtnGroup from "../../components/forms/elements/BtnGroup";
import InputNumber from "../../components/forms/elements/InputNumber";

export default class ListaFeriado extends CrudList {

    constructor(props) {
        super(props);

        this.state.crud = {
            service: FeriadoService,
            labels: {
                title: 'Lista de Feriado(s)',
                btnNewRegister: 'Feriado',
            },
            keyItem: 'idFeriado',
            urlForm: '/CadFeriado',
            itensFilter: ["idFeriado", "nome", 'nomePais', 'nomeEstado', 'nomeCidade', 'dtFeriado'],
            searchFormSubmit: true
        };
    }

    getState(props) {
        return {
            data: [],
            model: {
                busca: "",
                idPais: "",
                idEstado: "",
                idCidade: "",
                cdAbrangencia: "T",
                cdStatus: "A",
                nrAno: ""
            },
            abrangencia: [
                { value: "T", label: "TODOS", badge: "primary" },
                { value: "M", label: "MUNDIAL", badge: "primary" },
                { value: "N", label: "NACIONAL", badge: "success" },
                { value: "E", label: "ESTADUAL", badge: "secondary" },
                { value: "C", label: "MUNICIPAL", badge: "warning" }
            ],
            status: [
                { value: "T", label: "TODOS" },
                { value: "A", label: "ATIVOS" },
                { value: "E", label: "EXPIRADOS" },
            ],
            expand: true
        };
    }

    componentDidMount() {
        super.componentDidMount();
        this.onList();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    onClear() {
        this.setState(this.getState(), this.onList);
    }

    onList() {
        FeriadoService.listForList(this.state.model, (response) => {
            this.setState({ data: response });
        });
    }

    getFormFilter() {
        return (
            <form onSubmit={this.handleSubmit} noValidate className={`needs-validation ${this.state.validated ? "was-validated" : ""}`} >
                <div className="row mb-3 g-2">
                    <BtnGroup md={6} className="w-100" name="cdAbrangencia" value={this.state.model.cdAbrangencia} label="Abrangência" options={this.state.abrangencia} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <BtnGroup md={3} className="w-100" name="cdStatus" value={this.state.model.cdStatus} label="Status" options={this.state.status} onChange={(e) => { this.handleChange(e, this.onSubmit) }} opcionalLabel={false} />
                    <InputNumber md={3} label='Ano' value={this.state.model.nrAno} name="nrAno" onChange={this.handleChange} opcionalLabel={false} />
                </div>
                <div className="row mb-3 g-2">
                    <SelectPais label="Nome do País" placeholder="Selecione o País" name="idPais" md={4} value={this.state.model.idPais} onChange={(e) => { this.handleChange(e, this.onSubmit) }} showBtnNewForm={false} opcionalLabel={false} />
                    <SelectEstadoByPais idPais={this.state.model.idPais} label="Estado" value={this.state.model.idEstado} placeholder="Selecione o Estado" name="idEstado" md={4} onChange={(e) => { this.handleChange(e, this.onSubmit) }} showBtnNewForm={false} opcionalLabel={false} />
                    <SelectCidadeByEstado idPais={this.state.model.idPais} idEstado={this.state.model.idEstado} label="Cidade" value={this.state.model.idCidade} placeholder="Selecione a Cidade" name="idCidade" md={4} onChange={(e) => { this.handleChange(e, this.onSubmit) }} showBtnNewForm={false} opcionalLabel={false} />

                </div>
                <div className="row gap-2 mb-3">
                    <div className="col">
                        <div className="col-md-12 input-group mb-3">
                            <span className="input-group-text" id="addon-wrapping">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                            <input type="text" className="form-control" autoFocus autoComplete="off" name="busca" placeholder="Digite sua busca" value={this.state.model.busca} onChange={this.handleChange} />
                            <button className="btn btn-outline-primary" type="submit"> Buscar </button>
                        </div>
                    </div>
                    <div className="col-md-auto text-end d-grid d-md-block">
                        <button type="button" className="btn btn-outline-danger" onClick={this.onClear}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                                <path fillRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                            </svg> Limpar Busca
                        </button>
                    </div>
                </div>
            </form>
        );
    }

    getRenderHeader() {
        return (
            <>
                <th className="col-md text-center d-none d-md-table-cell"> Código </th>
                <th className="col-md-5"> Nome </th>
                <th className="col-md-2 text-center"> Data </th>
                <th className="col-md-1 text-center"> Status </th>
                <th className="col-md-1 text-center"> Abrangência </th>
                <th className="col-md-3"> Local </th>
            </>
        );
    }

    getRenderItem(item) {

        var abrangencia = this.state.abrangencia.find(i => item.cdAbrangencia === i.value);

        return (
            <>
                <td className="text-center d-none d-md-table-cell">{item.idFeriado}</td>
                <td>
                    {item.nome}
                </td>
                <td className="text-center">
                    {Util.date2Br(item.dtFeriado)}
                </td>
                <td className="text-center">
                    {Boolean(item.flExpirado) ?
                        <span className={`badge text-bg-secondary`}>
                            EXPIRADO
                        </span> :
                        <span className={`badge text-bg-primary`}>
                            ATIVO
                        </span>}
                </td>
                <td className="text-center">
                    <span className={`badge text-bg-${abrangencia?.badge}`}>
                        {abrangencia?.label}
                    </span>
                </td>
                <td>
                    {item.cdAbrangencia === "N" ? <>{item.nomePais}</> : <></>}
                    {item.cdAbrangencia === "E" ? <>{item.nomePais} / {item.nomeEstado} </> : <></>}
                    {item.cdAbrangencia === "C" ? <>{item.nomePais} / {item.nomeEstado} / {item.nomeCidade} </> : <></>}
                </td>
            </>);
    }

    render() {
        return super.render();
    }
}