import { Navigate } from "react-router-dom";
import useAuth from "../controllers/Auth";

function RequireAuth({ children }) {

    return useAuth.isAuthenticated() === true
        ? children
        : <Navigate to="/login" replace />;
}

export default RequireAuth;


